import * as React from 'react';
import { ListTable } from 'view/components/table';
import { Card } from 'view/components/card';
import { ListScreen } from 'view/components/list-screen/list-screen';
import { StatusBadTag, StatusGoodTag } from '@rexlabs/tag';
import { TagCell } from '@rexlabs/table';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { useDialog } from '@rexlabs/dialog';
import { EditMetricWriteRuleDialog } from 'src/modules/metric-write-rules/dialogs/edit-metric-write-rule-dialog';
import { useMetricWriteRules } from '../hooks/use-metric-write-rules';
import { MetricWriteRule } from '../types/MetricWriteRule';
import { useGetDeleteMetricWriteRuleAction } from '../hooks/action-declarations/use-get-delete-metric-write-rule-action';
import { useGetToggleEnabledMetricWriteRuleAction } from '../hooks/action-declarations/use-get-toggle-enabled-metric-write-rule-action';
import { useGetCreateMetricWriteRuleAction } from '../hooks/action-declarations/use-get-create-metric-write-rule-action';
import { useGetEditMetricWriteRuleAction } from '../hooks/action-declarations/use-get-edit-metric-write-rule-action';

export function MetricWriteRuleList() {
  const { open: openEditDialog } = useDialog(EditMetricWriteRuleDialog);
  const { data: metricWriteRules, isLoading } = useMetricWriteRules();
  const getDeleteMetricWriteRuleAction = useGetDeleteMetricWriteRuleAction();
  const getToggleEnabledMetricWriteRuleAction = useGetToggleEnabledMetricWriteRuleAction();
  const getCreateMetricWriteRuleAction = useGetCreateMetricWriteRuleAction();
  const getEditMetricWriteRuleAction = useGetEditMetricWriteRuleAction();

  const getRowLinkProps = ({ item }: { item: MetricWriteRule }) => {
    return {
      onClick: () =>
        openEditDialog({
          metricWriteRule: item
        })
    };
  };

  const getActionMenuItems = React.useCallback(
    ({ item }: { item: MetricWriteRule }) =>
      transformActionDeclarationsToActionMenuItems([
        getEditMetricWriteRuleAction(item),
        getToggleEnabledMetricWriteRuleAction(item),
        getDeleteMetricWriteRuleAction(item)
      ]),
    [
      getEditMetricWriteRuleAction,
      getToggleEnabledMetricWriteRuleAction,
      getDeleteMetricWriteRuleAction
    ]
  );

  const columns = React.useMemo(
    () => [
      {
        id: 'name',
        Header: 'Name',
        accessor: 'name'
      },
      {
        id: 'service',
        Header: 'Service',
        accessor: (item: MetricWriteRule) => item.record_type.label
      },
      {
        id: 'is_enabled',
        Header: 'Enabled',
        accessor: (item: MetricWriteRule) => (item.is_enabled ? 'Yes' : 'No'),
        Cell: ({ row }) => {
          return (
            <TagCell>
              {row.original.is_enabled ? (
                <StatusGoodTag>Yes</StatusGoodTag>
              ) : (
                <StatusBadTag>No</StatusBadTag>
              )}
            </TagCell>
          );
        }
      }
    ],
    []
  );

  return (
    <ListScreen
      // TODO: needs privs
      title='Metric Write Rules'
      actions={[getCreateMetricWriteRuleAction()]}
    >
      <Card>
        <ListTable
          columns={columns}
          items={metricWriteRules || []}
          isLoading={isLoading}
          getRowLinkProps={getRowLinkProps}
          getActionMenuItems={getActionMenuItems}
        />
      </Card>
    </ListScreen>
  );
}
