import { Select } from '@rexlabs/select';
import { InputProps } from '@rexlabs/form';
import { useCachedItems } from 'view/hooks/api/cached-items';
import { api } from 'utils/api/api-client';
import React from 'react';
import {
  normaliseValueListItem,
  ValueListItem
} from 'utils/normaliser/value-list-item';
import { useItems } from 'view/hooks/use-items';

interface RateTypeProps extends InputProps {
  triggerType?: string;
}

export function RateType({ triggerType, ...props }: RateTypeProps) {
  const disabled = !triggerType;
  const rateTypeUrl = `/financials/agency-fees/meta/rate-types?trigger=${triggerType}`;

  const { loadItems } = useCachedItems(
    rateTypeUrl,
    async () => {
      const rateTypeRequest = await api.get(rateTypeUrl);
      return rateTypeRequest.data.rate_types as ValueListItem[];
    },
    { disabled, prefetch: true }
  );

  const { getSelectProps } = useItems({
    getItems: loadItems,
    getSuggestedItems: loadItems
  });

  return (
    <Select
      {...props}
      {...getSelectProps()}
      searchable={false}
      normaliser={normaliseValueListItem}
    />
  );
}
