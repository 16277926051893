import { useQuery } from 'react-query';

import { api } from 'utils/api/api-client';

import { AgencyFee } from '../types/agency-fee-types';
import { EntityFeeType } from '../types/EntityFeeType';

export type UseInactiveAgencyFeeTemplatesForRecordQueryArgs = {
  entityFeeType: EntityFeeType;
  recordId: string;
};

export function getInactiveAgencyFeeTemplatesForRecordQueryKey(
  entityFeeType: EntityFeeType,
  recordId: string
): [EntityFeeType, string] {
  return [entityFeeType, recordId];
}

export function UseInactiveAgencyFeeTemplatesForRecordQuery({
  entityFeeType,
  recordId
}: UseInactiveAgencyFeeTemplatesForRecordQueryArgs) {
  const queryKey = getInactiveAgencyFeeTemplatesForRecordQueryKey(
    entityFeeType,
    recordId
  );
  const { data = [] } = useQuery<
    AgencyFee[],
    unknown,
    AgencyFee[],
    typeof queryKey
  >(
    [entityFeeType, recordId],
    async ({ queryKey }) => {
      const [entityTypeId, recordId] = queryKey;
      const { data } = await api.get<AgencyFee[]>(
        `${getEntityPath(entityTypeId)}/${recordId}/fees/inactive-templates`
      );
      return data;
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true
    }
  );

  return data;
}

function getEntityPath(entityTypeId: EntityFeeType) {
  switch (entityTypeId) {
    case 'property_ownership':
      return 'property-ownerships';
    case 'ownership':
      return 'ownerships';
    default:
      throw new Error(`Unknown entity type: ${entityTypeId}`);
  }
}
