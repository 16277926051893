import React from 'react';

import {
  AnyModel,
  EntityModel,
  ModelTypeFromAnyModel
} from '@rexlabs/model-generator';
import { useTableQuery, UseTableQueryArgs } from '@rexlabs/table';
import {
  ListTableInner,
  PAGE_SIZES,
  ListTableCommonProps
} from './list-table-common';
import { useListTableLogic } from './hooks/use-list-table-logic';

interface ListTableModelGeneratorProps<
  Model extends AnyModel<any, any> = EntityModel<any>
> extends ListTableCommonProps<ModelTypeFromAnyModel<Model>>,
    UseTableQueryArgs<Model> {
  //
}

export type { ListTableModelGeneratorProps as ListTableProps };

/**
 * This list table uses @rexlabs/model-generator to fetch data from backend.
 * The prop `getQuery` is used to fire the api request internally from the table
 * component to get the list data.
 */
export function ListTable<Model extends AnyModel<any, any>>({
  hashParamKey,

  pageSizes = PAGE_SIZES,

  initialSortBy: propInitialSortBy,
  initialGlobalFilter: propInitialGlobalFilter,
  initialPageSize: propInitialPageSize,
  initialPage: propInitialPage,
  initialHiddenColumns: propInitialHiddenColumns,

  getQuery: propGetQuery,
  normaliseGlobalFilter,

  droppableId,
  striped,
  suggestedFilters,
  getBulkActions,
  getActionMenuItems,

  emptyWithoutTable = false,
  Empty,
  Loading,

  hideToolbar,
  hidePagination,

  alwaysExpandSearch = false,
  columns: propColumns,

  shouldPersistTableSettings = true,
  shouldUseAppWideFilter = false,
  blacklistTableSettingKeys = [],

  ...props
}: ListTableModelGeneratorProps<Model>) {
  const {
    useControlledState,
    columns,
    appWideFilterParams,
    urlStateProps,
    initialHiddenColumns
  } = useListTableLogic({
    id: props.id,
    pageSizes,
    hashParamKey,
    columns: propColumns,
    shouldPersistTableSettings,
    blacklistTableSettingKeys,
    initialSortBy: propInitialSortBy,
    initialGlobalFilter: propInitialGlobalFilter,
    initialPageSize: propInitialPageSize,
    initialPage: propInitialPage,
    initialHiddenColumns: propInitialHiddenColumns
  });

  let getQuery = propGetQuery;

  if (shouldUseAppWideFilter && propGetQuery) {
    getQuery = (args) => {
      const query = propGetQuery(args);
      query.args = {
        ...query.args,
        global_filter_type: appWideFilterParams.type,
        global_filter_value: appWideFilterParams.value
      };
      return query;
    };
  }

  const { entity, getTableProps: getTableQueryProps } = useTableQuery({
    getQuery,
    normaliseGlobalFilter
  });

  const tableStateProps = getTableQueryProps();

  return (
    <ListTableInner
      {...props}
      entity={entity}
      hashParamKey={hashParamKey}
      columns={columns}
      droppableId={droppableId}
      Loading={Loading}
      Empty={Empty}
      striped={striped}
      hidePagination={hidePagination}
      hideToolbar={hideToolbar}
      getActionMenuItems={getActionMenuItems}
      getBulkActions={getBulkActions}
      emptyWithoutTable={emptyWithoutTable}
      suggestedFilters={suggestedFilters}
      alwaysExpandSearch={alwaysExpandSearch}
      tableStateProps={tableStateProps}
      initialHiddenColumns={initialHiddenColumns}
      urlStateProps={urlStateProps}
      useControlledState={useControlledState}
      initialGlobalFilter={propInitialGlobalFilter}
    />
  );
}
