import React, { useMemo } from 'react';
import { query, useEntityQuery } from '@rexlabs/model-generator';

import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { RecordScreen } from 'view/components/record-screen';

import { useRecordScreenSubmitHandler } from 'view/hooks/use-record-screen-submit-handler';
import { TaskRightBarOverride } from 'src/modules/tasks/common/components/right-bar-override';
import { content } from '../data/content';
import { LeaseReviewTitleBlock } from '../components/lease-review-title-block';
import { tasksModel } from '../../common/models/tasks-model';
import { LeaseReviewFormData } from '../types/LeaseReviewFormData';
import { mapLeaseReviewFormDataToTaskCreateRequest } from '../mappers/map-lease-review-form-data-to-task-create-request';
import { mapLeaseReviewTaskToLeaseReviewFormData } from '../mappers/map-lease-review-task-to-lease-review-form-data';

const getLeaseReviewQuery = (id: string) => query`{
  ${tasksModel} (id: ${id}) {
    id
    property {
      active_property_ownership {
        ownership {
          id
          owners
        }
      }
    }
    managed_by
    leaseReviewDetails
    task_links
    created_by
    updated_by
  }
}`;

interface LeaseReviewDetailsProps {
  leaseReviewId?: string;
}

export function LeaseReviewDetails({ leaseReviewId }: LeaseReviewDetailsProps) {
  const breadcrumbs = [{ type: BREADCRUMBS.LEASE_REVIEW }];
  const query = useMemo(() => getLeaseReviewQuery(leaseReviewId!), [
    leaseReviewId
  ]);

  const { status, data, actions } = useEntityQuery(query, {
    throwOnError: false
  });

  const handleSubmit = useRecordScreenSubmitHandler(
    async ({ changedValues }) => {
      const dataToSubmit = mapLeaseReviewFormDataToTaskCreateRequest(
        changedValues
      );
      await actions.updateItem({
        id: leaseReviewId,
        data: dataToSubmit
      });
    }
  );

  const titleBlock = <LeaseReviewTitleBlock leaseReview={data} />;

  const initialValues: LeaseReviewFormData | undefined = data
    ? mapLeaseReviewTaskToLeaseReviewFormData(data)
    : undefined;

  return (
    <RecordScreen
      privilege={'tasks.lease-review.read'}
      isLoading={status === 'loading'}
      data={data}
      handleSubmit={handleSubmit}
      content={content}
      initialValues={initialValues}
      titleBlock={titleBlock}
      breadcrumbs={breadcrumbs}
      RightBarOverride={TaskRightBarOverride}
    />
  );
}
