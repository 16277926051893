import React, { useCallback, useRef, useMemo } from 'react';

import { DialogProps } from '@rexlabs/dialog';

import { InvoiceBlockProps } from 'src/modules/invoices/types/invoice-block';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';

import { getUploadBillsBlock } from '../blocks/upload-bills-block';
import { UploadBillsDialogButtonGroup } from '../components/upload-bills-dialog-button-group';
import { useUploadBillSubmitHandlers } from '../hooks/use-upload-bills-submit-handlers';
import { UploadBillsFormValues } from '../types/UploadBillsFormValues';
import { InitialInvoiceValues } from '../types/InitialInvoiceValues';

const PROCESS_NOW = Symbol('PROCESS_NOW');
const PROCESS_LATER = Symbol('PROCESS_LATER');

export type UploadBillsDialogProps = DialogProps & {
  allowMultipleBillUploads?: boolean;
  // Use this to prefill the invoice dialog, when the user clicks on the process now button
  initialInvoiceValues?: InitialInvoiceValues;
  invoiceSuggestedContacts?: InvoiceBlockProps['suggestions'];
  afterBillsUpload?: (args?: any) => any;
};

export function UploadBillsDialog({
  afterBillsUpload,
  onClose,
  allowMultipleBillUploads = true,
  initialInvoiceValues,
  invoiceSuggestedContacts
}: UploadBillsDialogProps) {
  // Start defining conditional attributes
  const title = allowMultipleBillUploads ? 'Upload bills' : 'Upload bill';

  const initialValues = allowMultipleBillUploads
    ? { bills: [] }
    : { bill: undefined };

  const content = useMemo(
    () => [
      {
        id: 'upload-bills',
        label: 'Upload bills',
        blocks: [getUploadBillsBlock(allowMultipleBillUploads)]
      }
    ],
    [allowMultipleBillUploads]
  );
  // End defining conditional attributes

  const clickedButtonRef = useRef<symbol | null>(null);

  const {
    processNowSubmitHandler,
    processLaterSubmitHandler
  } = useUploadBillSubmitHandlers({
    initialInvoiceValues,
    invoiceSuggestedContacts
  });

  const handleSubmit: RecordSubmitHandler<UploadBillsFormValues> = useCallback(
    async (formValues) => {
      const type = clickedButtonRef.current;

      switch (type) {
        case PROCESS_NOW: {
          await processNowSubmitHandler(formValues);
          break;
        }
        case PROCESS_LATER: {
          await processLaterSubmitHandler(formValues);
          break;
        }
        default: {
          break;
        }
      }

      afterBillsUpload?.();
      return true;
    },
    [processNowSubmitHandler, processLaterSubmitHandler]
  );

  const handleProcessLater = React.useCallback(() => {
    clickedButtonRef.current = PROCESS_LATER;
  }, []);

  const handleProcessNow = React.useCallback(() => {
    clickedButtonRef.current = PROCESS_NOW;
  }, []);

  return (
    <RecordDialog
      title={title}
      content={content}
      onClose={onClose}
      submitLabel='Process later'
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      ButtonGroup={UploadBillsDialogButtonGroup}
      buttonGroupProps={{ handleProcessLater, handleProcessNow }}
    />
  );
}
