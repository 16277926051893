import React from 'react';

import { RecordScreen } from 'view/components/record-screen';
import { TitleBlock } from 'view/components/@luna/title-block';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useSettings } from 'src/modules/settings/hooks/useSettings';
import { usePutSettingsMutation } from 'src/modules/settings/hooks/usePutSettingsMutation';
import { useToast } from 'view/components/@luna/notifications/toast';
import { supplierCommissionSettingsBlock } from '../blocks/supplier-commission-settings';
import { supplierCommissionTemplatesBlock } from '../blocks/supplier-commission-templates';
import { supplierCommissionChargeBlock } from '../blocks/supplier-commission-charge';

const content = [
  {
    label: 'General',
    items: [
      {
        id: 'settings',
        label: 'Settings',
        blocks: [supplierCommissionSettingsBlock]
      },
      {
        id: 'templates',
        label: 'Templates',
        blocks: [
          supplierCommissionChargeBlock,
          supplierCommissionTemplatesBlock
        ]
      }
    ]
  }
];

export function SupplierCommissionSettings() {
  const { addToast } = useToast();

  const settings = useSettings();
  const supplierCommission = settings['supplier-commissions'];

  const putSettingsMutation = usePutSettingsMutation();

  const handleSubmit: RecordSubmitHandler = React.useCallback(
    async ({ values }) => {
      await putSettingsMutation.mutateAsync([
        {
          key: 'supplier-commissions',
          value: values
        }
      ]);

      addToast({
        description: 'Supplier commission settings updated successfully',
        color: 'success'
      });

      return true;
    },
    []
  );

  const initialValues = supplierCommission;

  return (
    <RecordScreen
      // TODO: does this need a priv?
      titleBlock={<TitleBlock title='Supplier commission' />}
      content={content}
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      data={supplierCommission}
    />
  );
}
