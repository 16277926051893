import React from 'react';
import { TableProps, ToolbarProps, UseUrlStateArgs } from '@rexlabs/table';
import { TableProvider, Columns, TableProviderProps } from './provider';
import { useListTableActions } from './hooks/use-list-table-actions';
import { Table } from './table';

export interface ListTableCommonProps<T extends any>
  extends ToolbarProps,
    Omit<TableProps, 'children'>,
    Omit<UseUrlStateArgs, 'initialGlobalFilter' | 'initialSortBy'>,
    Partial<Omit<TableProviderProps<T>, 'columns'>> {
  columns?: Columns;
  hideToolbar?: boolean;
  hidePagination?: boolean;
  hideBulkActions?: boolean;
  alwaysExpandSearch?: boolean;
  emptyWithoutTable?: boolean;
  shouldPersistTableSettings?: boolean;
  shouldUseAppWideFilter?: boolean;
  blacklistTableSettingKeys?: Array<string>;
}

export function ListTableInner<T>({
  emptyWithoutTable = false,
  hashParamKey,
  Empty,
  initialHiddenColumns,
  useControlledState,
  Loading,
  tableStateProps,
  urlStateProps,
  entity,
  columns,
  forcedGlobalFilter,
  hideToolbar,
  hidePagination,
  hideBulkActions,
  droppableId,
  striped,
  suggestedFilters,
  alwaysExpandSearch = false,
  getBulkActions: propGetBulkActions,
  getActionMenuItems: propGetActionMenuItems,
  ...props
}: ListTableCommonProps<T> & {
  entity: any;
  tableStateProps: any;
  urlStateProps: any;
}) {
  const { getActionMenuItems, getBulkActions } = useListTableActions({
    entity,
    columns,
    getBulkActions: propGetBulkActions,
    getActionMenuItems: propGetActionMenuItems
  });

  const hiddenFilters =
    forcedGlobalFilter?.map((forcedFilter) => forcedFilter.field) ?? [];

  return (
    <TableProvider
      {...props}
      {...urlStateProps}
      {...(props.items
        ? {
            allItems: props.items
          }
        : tableStateProps)}
      hashParamKey={hashParamKey}
      columns={columns}
      forcedGlobalFilter={forcedGlobalFilter}
      initialHiddenColumns={initialHiddenColumns}
      useControlledState={useControlledState}
      getBulkActions={hideBulkActions ? undefined : getBulkActions}
      getActionMenuItems={
        propGetActionMenuItems ? getActionMenuItems : undefined
      }
    >
      <Table
        tableProps={{
          ...props,
          droppableId,
          striped,
          Loading,
          Empty
        }}
        toolbarProps={{
          alwaysExpandSearch,
          suggestedFilters,
          hiddenFilters
        }}
        withToolbar={!hideToolbar}
        withPagination={!hidePagination}
        tableStateProps={tableStateProps}
        emptyWithoutTable={emptyWithoutTable}
      />
    </TableProvider>
  );
}

export const PAGE_SIZES = [20, 50, 100];
