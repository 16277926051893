import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { UserGroup } from '../../types/UserGroup';
import { useGetDeleteUserGroupAction } from '../action-declarations/use-get-delete-user-group-action';

export function useGetUserGroupActions() {
  const getDeleteUserGroupAction = useGetDeleteUserGroupAction();
  return (userGroup?: UserGroup): ActionDeclaration[] => {
    if (!userGroup) return [];

    const actions = [getDeleteUserGroupAction(userGroup)];

    return actions;
  };
}
