import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid, Column } from '@rexlabs/grid';
import { Value } from 'view/components/values';
import { Field } from '@rexlabs/form';
import { InfoBanner } from 'view/components/@luna/notifications/banner';
import InfoCircleIcon from 'view/components/icons/info';
import { RadioGroupInput } from '@rexlabs/radio-input';
import { formatCurrency } from 'utils/formatters';

const BANNER_MAP = {
  true: <IncludingTaxInfo />,
  false: <ExcludingTaxInfo />
};

const INCLUDING_TAX = 'Including tax';
const EXCLUDING_TAX = 'Excluding tax';

export const SUPPLIER_COMMISSION_CHARGE_BLOCK_ID = 'supplier-commission-charge';

export const supplierCommissionChargeBlock: BlockConfig = {
  id: SUPPLIER_COMMISSION_CHARGE_BLOCK_ID,
  title: 'Supplier commission charge',
  validate: {
    definitions: {
      charge_commission_on_tax_inclusive_total: { rules: 'required' }
    }
  },
  View: ({ data }) => {
    return (
      <Grid columns={4}>
        <Column width={2}>
          <Value
            label='Charge Supplier Commission on bill amount (default charge to any new template that is created)'
            value={
              data.charge_commission_on_tax_inclusive_total
                ? INCLUDING_TAX
                : EXCLUDING_TAX
            }
          />
        </Column>

        {BANNER_MAP[data.charge_commission_on_tax_inclusive_total]}
      </Grid>
    );
  },
  Edit: ({ values }) => {
    return (
      <Grid columns={4}>
        <Column width={4}>
          <Field
            id='charge_commission_on_tax_inclusive_total'
            label='Charge Supplier Commission on bill amount (default charge to any new template that is created)'
            name='charge_commission_on_tax_inclusive_total'
            Input={RadioGroupInput}
            inputProps={{
              options: [
                {
                  label: INCLUDING_TAX,
                  value: true
                },
                {
                  label: EXCLUDING_TAX,
                  value: false
                }
              ],
              orientation: 'horizontal'
            }}
          />
        </Column>

        {BANNER_MAP[values.charge_commission_on_tax_inclusive_total]}
      </Grid>
    );
  }
};

// Helpers

function IncludingTaxInfo() {
  return (
    <InfoBanner
      Icon={InfoCircleIcon}
      title={
        'Example: Agency commission is calculated on the supplier invoice total including tax.'
      }
      description={
        <ul>
          <li>Supplier Bill Total (incl. VAT): {formatCurrency(10000)}</li>
          <li>Supplier Bill Total (excl. VAT): {formatCurrency(8000)}</li>
          <li>Commission Charged (10%): {formatCurrency(1000)}</li>
        </ul>
      }
    />
  );
}

function ExcludingTaxInfo() {
  return (
    <InfoBanner
      Icon={InfoCircleIcon}
      title={
        'Example: Agency commission is calculated on the supplier invoice total excluding tax.'
      }
      description={
        <ul>
          <li>Supplier Bill Total (incl. VAT): {formatCurrency(10000)}</li>
          <li>Supplier Bill Total (excl. VAT): {formatCurrency(8000)}</li>
          <li>Commission Charged (10%): {formatCurrency(800)}</li>
        </ul>
      }
    />
  );
}
