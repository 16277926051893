import { useDialog } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import { isString } from 'lodash';
import { invokeActionDeclaration } from 'src/modules/common/actions/utils/invoke-action-declaration';
import { useGetAddDocumentsAction } from 'src/modules/documents/actions/use-get-add-documents-action';
import { ChecklistItemWithId } from '../../common/blocks/create-checklists-block';
import { tasksModel } from '../../common/models/tasks-model';
import { ChecklistItemActionType } from '../../settings/types/ChecklistTemplate';
import {
  GetNotesActionArgs,
  useGetAddNotesAction
} from '../../common/actions/get-add-notes-action';
import { LinkTasksDialog } from '../../common/dialogs/link-tasks-dialog';
import { CaptureOutcomeDialog } from '../dialogs/capture-outcome-dialog';
import { ActionTriggerDialog } from '../dialogs/action-trigger-dialog';
import { useChecklistItemMutation } from './use-checklist-item-mutation';

const frontendActions: ChecklistItemActionType[] = [
  'create_note',
  'upload_document',
  'add_linked_task'
];

// If we need any side effects triggered in response to the completion of a task, we can add them here
function useOnAfterSubmit(item: ChecklistItemWithId) {
  const { refreshItem } = useModelActions(tasksModel);
  const handleFrontendAction = useHandleFrontendAction(item);
  const actionsToRefreshAfter: ChecklistItemActionType[] = [
    'close_task',
    'set_status'
  ];

  if (!isString(item.action_type?.id)) {
    return;
  }

  return () => {
    if (actionsToRefreshAfter.includes(item.action_type!.id)) {
      return refreshItem(item.task);
    }

    if (frontendActions.includes(item.action_type!.id)) {
      return handleFrontendAction();
    }
  };
}

function useHandleFrontendAction(item: ChecklistItemWithId) {
  const getAddNotesAction = useGetAddNotesAction();
  const getAddDocumentsAction = useGetAddDocumentsAction();
  const { open: openLinkTasksDialog } = useDialog(LinkTasksDialog);

  // This is where we would handle any frontend actions that need to be triggered
  // in response to the completion of a task
  return () => {
    switch (item.action_type?.id) {
      case 'create_note':
        return invokeActionDeclaration<[GetNotesActionArgs]>(
          getAddNotesAction,
          {
            data: item.task
          }
        );
      case 'upload_document':
        return invokeActionDeclaration(getAddDocumentsAction, {
          data: item.task
        });
      case 'add_linked_task':
        return openLinkTasksDialog({ task: item.task });
      default:
        break;
    }
  };
}

// NOTE: this is mostly taken from the existing implementation at: frontend/src/modules/tasks/common/utils/checklists-columns.tsx
export function useGetOnChangeHandlerForItem(
  item: ChecklistItemWithId,
  setSubmittingAt: React.Dispatch<React.SetStateAction<Date | null>>
) {
  const onAfterSubmit = useOnAfterSubmit(item);
  const actionTriggerDialog = useDialog(ActionTriggerDialog);

  const captureOutcomeDialog = useDialog(CaptureOutcomeDialog);
  const checklistItemMutation = useChecklistItemMutation();

  const { completed_at, triggered_at, trigger_type, has_outcome } = item;

  const isChecked = Boolean(completed_at);
  const wasCompleted = Boolean(triggered_at);

  const onChange = async () => {
    setSubmittingAt(new Date());

    const onDiscard = async () => {
      const result = await checklistItemMutation.mutateAsync({
        skip_triggered_actions: true, // in this case, we are skipping and discarding the action
        item
      });
      setSubmittingAt(null);
      return result;
    };

    const onTrigger = async () => {
      const result = await checklistItemMutation.mutateAsync({
        skip_triggered_actions: false, // in this case, we want to trigger the action when we complete the task
        item
      });
      await onAfterSubmit?.();
      setSubmittingAt(null);
      return result;
    };

    const onClose = async () => {
      setSubmittingAt(null);
    };

    const isDueDateTrigger = trigger_type?.id === 'on_due_date';
    const isCompletionTrigger = trigger_type?.id === 'on_completion';

    // We allow the user to select an outcome on each completion, even if they already have one. this way they can change outcome if they need to.
    if (has_outcome && !isChecked) {
      // Because we need to capture additional data when an outcome is required, we need to open a dialog
      // and it will supply its own onCancel and onConfirm functions, because it needs to include the outcome data
      return captureOutcomeDialog.open({
        checklistItem: item,
        onAfterSubmit: () => {
          onAfterSubmit?.();
          setSubmittingAt(null);
        },
        onClose: () => {
          setSubmittingAt(null);
        }
      });
    } else if (isDueDateTrigger && !wasCompleted && !isChecked) {
      return actionTriggerDialog.open({
        checklistItem: item,
        onTrigger: onTrigger,
        onDiscard: onDiscard,
        onCancel: onClose,
        onClose
      });
    } else if (isCompletionTrigger && !wasCompleted && !isChecked) {
      // If it is a frontend driven action, we don't need to show the confirmation dialog, as they can just abort if they want to.
      if (
        item.action_type?.id &&
        frontendActions.includes(item.action_type?.id)
      ) {
        return onTrigger();
      }

      return actionTriggerDialog.open({
        checklistItem: item,
        onTrigger: onTrigger,
        onDiscard: onDiscard,
        onCancel: onClose,
        onClose
      });
    }

    const result = await checklistItemMutation.mutateAsync({ item });

    setSubmittingAt(null);

    return result;
  };

  return onChange;
}
