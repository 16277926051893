import { activityBlock } from 'view/components/blocks/activity';
import { FLAGS } from 'utils/feature-flags';
import { noteTableBlock } from 'src/modules/note/blocks/note-table-block';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { propertyTasksBlock } from 'src/modules/properties/blocks/property-tasks-block';
import { applianceTableBlock } from 'src/modules/appliances/blocks/appliance-table-block';
import { keySetBlock } from 'src/modules/properties/blocks/keys-block';
import { addPropertyComplianceCategoryBlock } from 'src/modules/properties/blocks/add-property-compliance-category-block';
import { useMessageTableBlock } from 'src/modules/communications/messages/blocks/message-table-block';
import { propertiesModel } from 'data/models/entities/properties';
import { useTranslation } from 'react-i18next';
import { inspectionAreasBlock } from '../blocks/inspection-areas-block';
import { useGetAgreementDetailsBlock } from '../../property-tenancies/blocks/property-tenancy-agreement-details';
import { detailsBlock } from '../blocks/details';
import { propertyTypeBlock } from '../blocks/property-type';
import { documentsBlock } from '../blocks/documents';
import { complianceEntriesBlock } from '../blocks/compliance-entries';
import { marketingFeaturesBlock } from '../blocks/marketing-features';
import { marketingViewsBlock } from '../blocks/marketing-views';
import { portfolioBlock } from '../blocks/portfolio';
import { propertyTenancySummaryBlock } from '../../property-tenancies/blocks/property-tenancy-summary';
import { rentScheduleBlock } from '../../rent/blocks/property-tenancy-rent-schedule';
import { securityDepositsBlock } from '../../property-tenancies/blocks/property-tenancy-security-deposits';
import { propertyManagementDetailsBlock } from '../../property-ownerships/blocks/property-ownership-details';
import { usePropertyOwnershipFeesBlock } from '../../property-ownerships/blocks/property-ownership-fees';
import { FlattenedProperty } from '../types/property-types';
import { propertyOwnershipSummaryBlock } from '../../property-ownerships/blocks/property-ownership-summary';
import { propertyOwnershipMaintenanceInstructionsBlock } from '../blocks/maintenance';
import { routineInspectionsBlock } from '../blocks/routine-inspections';
import { leaseReviewsBlock } from '../blocks/lease-reviews';
import { tenantPaymentHistoryBlock } from '../blocks/tenant-payment-history';

export const usePropertyContent = (data?: FlattenedProperty) => {
  const { t } = useTranslation();
  const { hasFeature } = useFeatureFlags();

  const feesBlock = usePropertyOwnershipFeesBlock(
    data?.selected_property_ownership
  );
  const messageTableBlock = useMessageTableBlock(propertiesModel);

  const hasComplianceEntries = !!data?.compliance_entries?.length;
  const hasComplianceCategories = !!data?.compliance_categories?.length;

  const agreementDetailsBlock = useGetAgreementDetailsBlock();

  return [
    {
      label: 'General',
      items: [
        {
          id: 'overview',
          label: 'Overview',
          blocks: [propertyOwnershipSummaryBlock, propertyTenancySummaryBlock]
        },
        {
          id: 'activity',
          label: 'Activity',
          blocks: [activityBlock],
          workInProgress: true
        },
        {
          id: 'documents',
          label: 'Documents',
          blocks: [documentsBlock]
        },
        {
          id: 'notes',
          label: 'Notes',
          blocks: [noteTableBlock]
        },
        { id: 'messages', label: 'Messages', blocks: [messageTableBlock] },
        {
          id: 'tasks',
          label: 'Tasks',
          flag: FLAGS.TODOS,
          blocks: [propertyTasksBlock]
        }
      ]
    },
    {
      label: 'Property',
      items: [
        {
          id: 'details',
          label: 'Details',
          blocks: [
            propertyTypeBlock,
            detailsBlock,
            marketingFeaturesBlock,
            marketingViewsBlock
          ]
        },
        {
          id: 'inspection-areas',
          label: 'Inspection areas',
          flag: FLAGS.PROPERTY_AREAS,
          blocks: [inspectionAreasBlock]
        },
        {
          id: 'appliances',
          label: 'Appliances',
          blocks: [applianceTableBlock],
          flag: FLAGS.APPLIANCES
        },

        {
          id: 'compliance',
          label: 'Insurance & compliance',
          blocks: [
            addPropertyComplianceCategoryBlock,
            // we want to show compliance entries if there are any OR ALSO if there are unfulfilled compliance categories
            ...(hasComplianceEntries || hasComplianceCategories
              ? [complianceEntriesBlock]
              : [])
          ],
          flag: FLAGS.PROPERTY_COMPLIANCE_SETTINGS
        },
        {
          id: 'keys',
          label: 'Keys',
          blocks: [keySetBlock],
          flag: FLAGS.KEY_SETS
        },
        {
          id: 'portfolio',
          label: 'Portfolio',
          blocks: [portfolioBlock]
        }
      ]
    },
    {
      label: 'Management & Tenancy',
      items: [
        {
          id: 'lease-agreements',
          label: t('lease-agreements.label.plural'),
          blocks: [
            propertyTenancySummaryBlock,
            ...(data?.selected_property_tenancy
              ? [
                  agreementDetailsBlock,
                  rentScheduleBlock,
                  securityDepositsBlock,
                  ...(hasFeature(FLAGS.TASK_TRIGGERS)
                    ? [routineInspectionsBlock, leaseReviewsBlock]
                    : []),
                  tenantPaymentHistoryBlock
                ]
              : [])
          ]
        },
        {
          id: 'management-agreements',
          label: t('property-ownerships.management-agreement.label.plural'),
          blocks: [
            propertyOwnershipSummaryBlock,
            ...(data?.selected_property_ownership
              ? [
                  propertyManagementDetailsBlock,
                  feesBlock,
                  propertyOwnershipMaintenanceInstructionsBlock
                ]
              : [])
          ]
        }
      ]
    }
  ];
};
