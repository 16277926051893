import React, { useCallback } from 'react';
import { query } from '@rexlabs/model-generator';

import { Card } from 'view/components/card';
import ROUTES from 'routes/app';
import { useMediaQuery } from '@rexlabs/breakpoints';
import { Tenancy, tenancyModel } from 'data/models/entities/tenancies';
import { Columns, ListTable } from 'view/components/table';
import { ListScreen } from 'view/components/list-screen/list-screen';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { auditableColumns } from 'src/modules/common/auditable/auditable-columns';
import { useGetBulkMessageActions } from 'src/modules/communications/messages/hooks/action-declarations/use-get-bulk-message-actions';
import { BulkAction } from '@rexlabs/table/lib/types/bulk-actions/simple';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { FLAGS } from 'utils/feature-flags';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { TwoTierCell } from 'view/components/cells/two-tier-cell';
import { useTranslation } from 'react-i18next';
import { useGetTenancyActions } from '../actions/use-get-tenancy-actions';

const useColumns = (): Columns => {
  const { t } = useTranslation();

  return [
    {
      id: 'display_name',
      Header: 'Name',
      width: 300,
      accessor: (item) => item,
      Cell: ({ value }) => (
        <TwoTierCell
          text={value.display_name}
          subtext={value.record_reference}
        />
      ),
      toCsv: (item) => `${item.display_name} ${item.record_reference}`
    },
    {
      id: 'financial_summary.bills_and_fees_payable_total_amount_owing',
      type: 'currency',
      Header: 'Bills payable',
      cellProps: {
        align: 'right'
      },
      width: 160
    },
    {
      Header: t(
        'financials.trust.available-trust-balance.label.singular'
      ) as string,
      accessor: (item) => item.trust_summary?.available_balance_amount,
      type: 'currency',
      width: 180,
      cellProps: {
        align: 'right'
      }
    },
    {
      id: 'financial_summary.invoices_receivable_total_amount_owing',
      type: 'currency',
      Header: 'Invoices receivable',
      cellProps: {
        align: 'right'
      },
      width: 180
    },
    ...auditableColumns
  ];
};

const getRowLinkProps = ({ item }) => ({
  to: ROUTES.TENANCY,
  params: { tenancyId: item.id }
});

/*
 * Ask Julian about this.
 * If all fields required by the details screen are not fetched here, it blows up later because the id exists but fields don't.
 */
const getTenanciesListQuery = () => query`{
  ${tenancyModel} {
    created_by{
      user
    }
    updated_by{
      user
    }
    id
    display_name
    created_at
    updated_at
    rent_amount
    frequency {
      id
      label
    }
    agreement_start_date
    agreement_end_date
    agreement_vacate_date
    agreement_signed_date
    paid_to_date
    surplus_amount
    contacts {
      id
      contact {
        id
        name
      }
    }
    properties {
      id
      address
      display_name
    }
    financial_summary
    trust_summary
  }
}`;

export function TenanciesListScreen() {
  const { hasFeature } = useFeatureFlags();
  const isSmallScreen = useMediaQuery({ maxWidth: 'xl' });
  const { getSort, getFilters } = useTableFilters('tenancies');
  const getTenancyActions = useGetTenancyActions();
  const getBulkMessageActions = useGetBulkMessageActions(tenancyModel);
  const columns = useColumns();

  const getBulkActions = useCallback(
    (args) => {
      const actions: ActionDeclaration[] = [];
      if (hasFeature(FLAGS.BULK_MESSAGES)) {
        actions.push(...getBulkMessageActions(args.selectedItems));
      }
      // This assertion is needed because this table passes values as props,
      // whereas the other ones do it by spreading, and the underlying types are slightly different
      return actions as BulkAction[];
    },
    [getBulkMessageActions, hasFeature]
  );

  const getTenancyActionMenuItems = useCallback(
    ({ item }) =>
      transformActionDeclarationsToActionMenuItems(
        getTenancyActions(item as Tenancy),
        [
          ['primary-record', 'lease-agreement'],
          ['maintainable-entity', 'inspectable-entity']
        ]
      ),
    []
  );

  return (
    <ListScreen
      privilege={'tenancies.read'}
      title='Tenancies'
      recordType='tenancy'
      createLabel='Create tenancy'
      createLinkProps={{ to: ROUTES.TENANCIES_LIST.CREATE }}
    >
      <Card>
        <ListTable
          id='tenancy-list'
          columns={columns}
          getBulkActions={getBulkActions}
          getQuery={getTenanciesListQuery}
          getRowLinkProps={getRowLinkProps}
          getSort={getSort}
          getFilters={getFilters}
          getActionMenuItems={getTenancyActionMenuItems}
          alwaysExpandSearch={true}
          suggestedFilters={[
            'display_name',
            'summary_bills_and_fees_payable_total_amount_owing',
            'summary_invoices_receivable_total_amount_owing'
          ]}
          initialHiddenColumns={[
            'created_by',
            'created_at',
            'updated_at',
            'updated_by',
            'financial_summary.invoices_receivable_total_amount_owing',
            ...(isSmallScreen
              ? ['financial_summary.bills_and_fees_payable_total_amount_owing']
              : [])
          ]}
        />
      </Card>
    </ListScreen>
  );
}
