import * as React from 'react';

import { query } from '@rexlabs/model-generator';

import { useSettings } from 'src/modules/settings/hooks/useSettings';

import { pluralize } from 'utils/formatters';

import { ListTable } from 'view/components/table';
import { Card } from 'view/components/card';
import { ListScreen } from 'view/components/list-screen/list-screen';
import { useTableFilters } from 'view/hooks/use-table-filters';
import InfoCircleIcon from 'view/components/icons/info';
import { InfoBanner } from 'view/components/@luna/notifications/banner';

import {
  auditLogColumns,
  initialHiddenColumns
} from '../utils/audit-log-columns';
import { auditLogsModel } from '../models/audit-logs-model';

const auditLogQuery = query`{
  ${auditLogsModel} {
    id
  }
}`;

const initialSortBy = [{ id: 'time', label: 'Time', desc: true }];

export function AuditLogList() {
  const { getSort, getFilters } = useTableFilters('audit-logs');
  const settings = useSettings();

  return (
    <ListScreen privilege={'audit-logs.read'} title='Change log'>
      <Card>
        <InfoBanner
          Icon={InfoCircleIcon}
          description={`The change log is limited to the last ${pluralize(
            'months',
            settings['audit-log-access-limit'],
            true
          )}.`}
        />
        <ListTable
          id='audit-logs'
          columns={auditLogColumns}
          getQuery={() => auditLogQuery}
          getSort={getSort}
          getFilters={getFilters}
          initialSortBy={initialSortBy}
          initialHiddenColumns={initialHiddenColumns}
        />
      </Card>
    </ListScreen>
  );
}
