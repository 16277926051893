module.exports = {
  API_URL: "//testing-alfie.dev.rexlabs.io/api/v1",

  BUGSNAG_API_KEY: "e6627cf16bb26b7de4d725efa2f273a2",
  SEGMENT_ID: "4w2xBGkc8bztTehKP3gQ4DCcMpQSor4K",
  FLAGSMITH_ENV_ID: "a2vnCZknpNMTFcD9PXZMza",
  SPRIG_ENV_ID: "rrghj_t7E",
  MAPBOX_API_KEY:
    "pk.eyJ1IjoicmV4c29mdHdhcmUiLCJhIjoiY2xwaXlnMm9lMDNjNjJscG51c2N1OTBzcyJ9.7v95qS5y6xrwyhKhFHVDog",
  ANNOUNCEKIT_URL: "https://announcekit.co/widgets/v2/2O0EPC",

  FROALA_KEY:
    "hWA2C-7I2C6C1C4B2D2B1wd1DBKSPF1WKTUCQOa1OURPJ1KDe2F-11D2C2D2E2C3B3D2D6D1A1==",

  PUSHER: {
    KEY: "3c605390467de14a47c6",
    CLUSTER: "ap4",
    AUTH_URL: "/broadcasting/auth",
  },

  RELEASE: {
    STAGE: "testing",
    VERSION: "0.0.0-testing.dcc15a1cf36008def94c0aaf3c8b4ca853d4615d",
    HASH: "dcc15a1cf36008def94c0aaf3c8b4ca853d4615d",
  },

  AUTHENTICATION_SERVICE_APP_ID: "rex_pm",
  AUTHENTICATION_SERVICE_BACKEND_URL: "https://auth.rexsoftware.com/",
  AUTHENTICATION_SERVICE_FRONTEND_URL: "https://auth.rexsoftware.com/",
  AUTHENTICATION_SERVICE_PROFILE_INFO_URL:
    "https://auth.rexsoftware.com/profile/personal-info/",
  AUTHENTICATION_SERVICE_PROFILE_SECURITY_URL:
    "https://auth.rexsoftware.com/profile/security/",
  AUTHENTICATION_SERVICE_ENABLE_INTEGRATION: false,
};
