import { DialogProps } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import { propertiesModel } from 'data/models/entities/properties';
import React, { useCallback } from 'react';
import { useRecordCreatedToast } from 'src/modules/common/toasts/hooks/use-record-created-toast';
import { DeepPartial } from 'utils/types';
import { CreateRecordDialog } from 'view/components/dialogs/create-record-dialog/create-record-dialog';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useDefaultBankAccount } from 'view/hooks/api/use-default-bank-account';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { pick } from 'lodash';
import { detailsBlock } from '../blocks/details';
import {
  getAddressFromPropertyFormValues,
  PropertyFormData
} from '../utils/get-address-from-property-form-values';
import { propertyDetailsBlock } from '../blocks/property-details';
import { propertyComplianceBlock } from '../blocks/property-compliance';
import { usePropertyPostcodeFinderFlag } from '../hooks/use-property-postcode-finder-flag';

interface CreatePropertyDialogProps extends DialogProps {
  portfolioId?: string;
  isFixture?: boolean;
}

export function CreatePropertyDialog({
  portfolioId,
  ...dialogProps
}: CreatePropertyDialogProps) {
  const { defaultBankAccount } = useDefaultBankAccount();
  const { createItem, refreshLists } = useModelActions(propertiesModel);
  const addToast = useRecordCreatedToast(propertiesModel);

  const { hasFeature } = useFeatureFlags();
  const hasPropertyAreas = hasFeature(FLAGS.PROPERTY_AREAS);

  const { isLoading, isActive } = usePropertyPostcodeFinderFlag();

  const handleRecordCreate: RecordSubmitHandler<PropertyFormData> = useCallback(
    async ({ values }) => {
      const address = getAddressFromPropertyFormValues(values);

      const complianceCategories = values.compliance_categories;

      const { data: property } = await createItem({
        data: {
          address,
          ...(complianceCategories
            ? { compliance_categories: complianceCategories }
            : {}),
          ...pick(values, [
            'bathrooms_count',
            'bedrooms_count',
            'ensuites_count'
          ])
        }
      });

      addToast(property);

      refreshLists();

      return property;
    },
    [addToast, createItem, portfolioId]
  );

  const content = React.useMemo(() => {
    return [
      {
        id: 'details',
        label: 'Details',
        blocks: [
          detailsBlock,
          ...(hasPropertyAreas ? [propertyDetailsBlock] : []),
          propertyComplianceBlock
        ]
      }
    ];
  }, [hasPropertyAreas]);

  // Set the default country to the default bank account country, so that the country specific fields show up
  const initialValues: DeepPartial<PropertyFormData> = {
    address: {
      country: defaultBankAccount?.country || { id: 'AUS', label: 'Australia' }
    }
  };

  return (
    <CreateRecordDialog
      {...dialogProps}
      autofocusIndex={isActive ? 1 : 0}
      isLoading={isLoading}
      recordType='property'
      content={content}
      initialValues={initialValues}
      onSubmit={handleRecordCreate}
      blockProps={{
        allowPostcodeFinder: true
      }}
    />
  );
}
