import React from 'react';
import { Message } from '@rexlabs/notifications';
import WarningIcon from 'view/components/icons/warning';
import { EmptyCard } from './empty-card';

export function ErrorCard() {
  return (
    <EmptyCard hasGreyBackground>
      <Message
        title='Something went wrong'
        Icon={() => <WarningIcon size={'l'} />}
      >
        <span>
          Please try refreshing the page. If the error persists, get in touch
          with our support team.
        </span>
      </Message>
    </EmptyCard>
  );
}
