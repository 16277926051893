import { ValueListValue } from 'data/models/types';
import { isString } from 'lodash';
import { useQuery } from 'react-query';
import { Privilege as GeneratedPrivilege } from 'src/generated/privileges';
import { useSessionState } from 'src/modules/common/hooks/session-state';
import { api } from 'utils/api/api-client';

const privilegesQueryKey = ['privileges'];

export type PrivilegeCategory =
  | 'admin_resources'
  | 'basic_resources'
  | 'complex_resources'
  | 'task_resources'
  | 'financial_resources';

type PrivilegeSet = {
  id: string;
  label: string;
  description: string;
  privileges: string[];
};

type IndividualPrivilege = {
  id: string;
  label: string;
  description: string;
};

export type PrivilegeDescriber = {
  id: string;
  label: string;
  category: ValueListValue<PrivilegeCategory>;
  privilege_sets: {
    data: PrivilegeSet[];
  };
  individual_privileges: {
    data: IndividualPrivilege[];
  };
};

export function usePrivilegeList() {
  return useQuery({
    queryKey: privilegesQueryKey,
    queryFn: async () => {
      return api.get<PrivilegeDescriber[]>(`/roles/privileges`);
    },
    select: (data) => data.data,
    staleTime: Infinity
  });
}

export type SinglePrivilege = GeneratedPrivilege;

export type Privilege =
  | SinglePrivilege
  | {
      mode: 'any' | 'all' | 'none';
      privileges: SinglePrivilege[];
    };

export function usePrivileges() {
  const { role } = useSessionState();
  const privileges = role?.privileges ?? [];

  const hasGeneratedPrivilege = (privilege: SinglePrivilege) =>
    privileges.includes(privilege);

  function hasPrivilege(priv: Privilege): boolean {
    if (isString(priv)) {
      return hasGeneratedPrivilege(priv);
    }

    switch (priv.mode) {
      case 'any':
        return priv.privileges.some(hasGeneratedPrivilege);
      case 'all':
        return priv.privileges.every(hasGeneratedPrivilege);
      case 'none':
        return !priv.privileges.some(hasGeneratedPrivilege);
    }
  }

  return { hasPrivilege };
}

const taskPrivPrefixes = [
  'tasks.todos',
  'tasks.quotes',
  'tasks.work-orders',
  'tasks.move-in',
  'tasks.move-out',
  'tasks.inspections',
  'tasks.arrears-jobs',
  'tasks.lease-review',
  'tasks.maintenances',
  'tasks.supplier-compliance',
  'tasks.property-compliance'
];

export function makeAllTaskPrivilegesArray(action: Action): SinglePrivilege[] {
  return taskPrivPrefixes.map(
    (priv) => `${priv}.${action}`
  ) as SinglePrivilege[];
}

type Action = 'create' | 'update' | 'delete' | 'read';
