import * as React from 'react';

import EmptyRecord from 'src/assets/illustrations/empty-record.svg';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

import { Card } from 'view/components/card';
import { Message } from 'view/components/@luna/message';

type BillProcessingListEmptyStateProps = {
  children: React.ReactNode;
  actions: ActionDeclaration[];
};

export function BillProcessingListEmptyState({
  children,
  actions
}: BillProcessingListEmptyStateProps) {
  return (
    <Card>
      <Message
        title='Bills to process'
        Illustration={EmptyRecord}
        actions={actions}
      >
        {children}
      </Message>
    </Card>
  );
}
