import { ColDef, ColGroupDef } from 'ag-grid-community';
import { RecordType, ValueListValue } from 'data/models/types';
import { useQuery } from 'react-query';
import { api } from 'utils/api/api-client';
import { ReportingFieldType } from '../types/CustomReport';

// We need to inject our own data to the ColDef type so that we can do some condition stuff depending on the backend field type
export type RexPmColDef<TData = any> = ColDef<TData> & {
  __rex_pm_type: ReportingFieldType;
};

export type RexPmColGroupDef<TData = any> = ColGroupDef<TData> & {
  children: (RexPmColDef<TData> | RexPmColGroupDef<TData>)[];
};

export type CustomReportDescriber = {
  id: string;
  label: string;
  record_type: ValueListValue<RecordType>;
  column_definitions: (RexPmColDef | RexPmColGroupDef)[];
};

export const CUSTOM_REPORT_DESCRIBERS_QUERY_KEY = ['custom-report-describers'];

export function useCustomReportDescribers() {
  const query = useQuery({
    queryKey: CUSTOM_REPORT_DESCRIBERS_QUERY_KEY,
    queryFn: async () =>
      api.get<CustomReportDescriber[]>('/custom-reports/describers'),
    select: (data) => data.data,
    staleTime: Infinity
  });

  return query;
}
