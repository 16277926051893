import React, { useCallback } from 'react';

import { BlockConfig } from 'view/components/record-screen/types';
import { Columns, RecordTable } from 'view/components/table';
import { User } from 'src/modules/users/types/user-types';
import { useDialog } from '@rexlabs/dialog';
import { ActionButtons } from 'view/components/@luna/action-buttons';
import AddIcon from 'view/components/icons/add';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { AddMemberDialog } from '../dialogs/add-member-dialog';
import { useGetRemoveMemberAction } from '../hooks/action-declarations/use-get-remove-member-action';
import { UserGroup } from '../types/UserGroup';

const columns: Columns<User> = [
  {
    id: 'name',
    accessor: (item) => item?.display_name,
    type: 'text',
    Header: 'User name'
  },
  {
    id: 'role',
    accessor: (item) => item?.role?.name,
    type: 'text',
    Header: 'Role'
  }
];

export const userGroupMembersBlock: BlockConfig<UserGroup> = {
  id: 'members',
  title: 'Members',
  Actions: ({ data }) => {
    const { open } = useDialog(AddMemberDialog);

    return (
      <ActionButtons
        actions={
          data
            ? [
                {
                  label: 'Add member',
                  handleAction: () =>
                    open({
                      userGroup: data
                    }),
                  Icon: AddIcon
                }
              ]
            : []
        }
      />
    );
  },
  View: ({ data }) => {
    const getRemoveMemberAction = useGetRemoveMemberAction();

    const getMemberActionMenuItems = useCallback(
      ({ item }) =>
        transformActionDeclarationsToActionMenuItems([
          getRemoveMemberAction(data!, item)
        ]),
      [useGetRemoveMemberAction, data]
    );

    return (
      <RecordTable
        columns={columns}
        isLoading={false}
        items={data?.users?.data || []}
        getActionMenuItems={getMemberActionMenuItems}
      />
    );
  }
};
