import React, { useContext } from 'react';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { Draggable } from 'react-beautiful-dnd';

import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { useChecklistTemplateItemState } from '../../hooks/smart-checklists/use-checklist-template-item-state';
import {
  ChecklistTemplateItemGroup,
  ChecklistTemplateItem as ChecklistTemplateItemType
} from '../../types/ChecklistTemplate';
import { useGetChecklistTemplateItemActions } from '../../hooks/action-menu-items/use-get-checklist-template-item-actions';
import { useTriggerActions } from '../../hooks/action-menu-items/use-trigger-actions';
import { ChecklistTemplateItem } from './checklist-template-item';
import { DragHandle } from './drag-handle';
import { fieldArrayContext } from './field-array-context';
import { smartChecklistContext } from './smart-checklist-context';

type ChecklistItemWrapperProps = {
  item: ChecklistTemplateItemType;
  index: number;
  globalIndex: number;
  draggableId?: string;
  checklistTemplateItemGroup?: ChecklistTemplateItemGroup;
};

export const ChecklistTemplateItemWrapper: React.FC<ChecklistItemWrapperProps> = ({
  item,
  index,
  globalIndex,
  children,
  draggableId
}) => {
  const { hasFeature } = useFeatureFlags();
  const { fieldArrayProps, setFieldValue, values, taskType } = useContext(
    fieldArrayContext
  );

  const { isEditing } = useContext(smartChecklistContext);
  const { checklist_template_items: otherItems } = values;

  const getChecklistTemplateItemActions = useGetChecklistTemplateItemActions({
    taskType,
    fieldArrayProps: fieldArrayProps,
    index: globalIndex,
    setFieldValue,
    values
  });

  const triggerActions = useTriggerActions({
    index: globalIndex,
    fieldArrayProps: fieldArrayProps,
    taskType,
    checklistTemplateItem: item
  });

  // Originally these were split over 2 action menus, but with the new UI, we can combine them into a single menu.
  const templateItemActions = transformActionDeclarationsToActionMenuItems([
    ...getChecklistTemplateItemActions(item),
    // Don't show trigger actions if we are using the combined dialog
    // TODO: remove this once feature flag is removed
    ...(!hasFeature(FLAGS.SMART_CHECKLISTS_COMBINED_DIALOG) && item.trigger_type
      ? triggerActions
      : [])
  ]);

  const state = useChecklistTemplateItemState(item, otherItems ?? []);

  return (
    <Draggable
      draggableId={draggableId ?? item.id}
      index={index}
      isDragDisabled={!isEditing}
    >
      {(provided) => {
        return (
          <div ref={provided.innerRef}>
            <ChecklistTemplateItem
              {...state}
              actions={templateItemActions}
              renderDragHandle={() => <DragHandle />}
              draggableProvided={provided}
            >
              {children}
            </ChecklistTemplateItem>
          </div>
        );
      }}
    </Draggable>
  );
};
