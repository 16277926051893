import { useModelActions } from '@rexlabs/model-generator';
import { AxiosResponse } from 'axios';
import { propertiesModel } from 'data/models/entities/properties';
import { useContext } from 'react';
import { propertyOwnershipModel } from 'src/modules/property-ownerships/models/property-ownership-model';
import { PropertyOwnership } from 'src/modules/property-ownerships/types/property-ownership-types';
import { propertyTenancyModel } from 'src/modules/property-tenancies/models/property-tenancy-model';
import { PropertyTenancy } from 'src/modules/property-tenancies/types/property-tenancy-types';
import { getUploadedFileMeta } from 'utils/files';
import { useRecordScreenSubmitHandler } from 'view/hooks/use-record-screen-submit-handler';
import { useConfirmationDialog, useDialog } from '@rexlabs/dialog';
import { createDefered } from 'utils/create-defered';
import { useTranslation } from 'react-i18next';
import { without } from 'lodash';
import { propertyOwnershipsFeesModel } from 'data/models/entities/financials/property-ownerships/property-ownership-fee';
import { propertyDetailContext } from '../screens/property-details';
import {
  getAddressFromPropertyFormValues,
  PropertyFormData
} from '../utils/get-address-from-property-form-values';
import { ReasonForChangeDialog } from '../dialogs/reason-for-change-dialog';
import { useHandleSubmitValidation } from './use-handle-submit-validation';

const propertyIncludes = [
  'address',
  'compliance_categories',
  'compliance_categories.category_requirements',
  'images.file',
  'features',
  'links',
  'views',
  'portfolio',
  'portfolio.users',
  'portfolio.users.contact.primary_phone',
  'active_property_tenancy.rent_schedule.line_items',
  'active_property_ownership.service_package'
].join(',');

export function useHandlePropertyDetailsSubmit(propertyId: string) {
  const { t } = useTranslation();

  const {
    setSelectedPropertyOwnership,
    setSelectedPropertyTenancy
  } = useContext(propertyDetailContext);

  const confirmationDialog = useConfirmationDialog();
  const reasonForChangeDialog = useDialog(ReasonForChangeDialog);

  const handleSubmitValidation = useHandleSubmitValidation();

  const { updateItem: updatePropertyTenancyItem } = useModelActions(
    propertyTenancyModel
  );
  const { updateItem: updatePropertyOwnershipItem } = useModelActions(
    propertyOwnershipModel
  );
  const { refreshLists: refreshListsPropertyOwnershipFees } = useModelActions(
    propertyOwnershipsFeesModel
  );

  const { updateItem: updateProperty } = useModelActions(propertiesModel);

  const propertyTenancyKey = 'selected_property_tenancy';
  const propertyOwnershipKey = 'selected_property_ownership';

  return useRecordScreenSubmitHandler<PropertyFormData>(
    async ({ values, changedValues }) => {
      const deferred = createDefered();

      const {
        [propertyTenancyKey]: changedPropertyTenancyValues,
        [propertyOwnershipKey]: changedPropertyOwnershipValues,
        ...changedPropertyValues
      } = changedValues;

      const address = getAddressFromPropertyFormValues(values);

      if (changedPropertyTenancyValues) {
        await handleSubmitValidation({
          values,
          changedValues,
          prefix: propertyTenancyKey
        });
        if (changedPropertyTenancyValues?.term_length?.id === 'custom') {
          changedPropertyTenancyValues.term_length = {
            // TODO: not sure why this is being null'd need to investigate
            // @ts-ignore
            id: null
          };
        }
      }

      if (
        changedPropertyOwnershipValues &&
        (changedPropertyOwnershipValues.service_package ||
          changedPropertyOwnershipValues.service_package === null)
      ) {
        const message = values.previous_service_package_name
          ? t(
              `property-ownerships.management-agreement.service-package.confirmation-message`,
              {
                servicePackage: values.previous_service_package_name
              }
            )
          : t(
              `property-ownerships.management-agreement.service-package.default-confirmation-message`
            );

        confirmationDialog.open({
          title: 'Changing service package will reset fees',
          message,
          onConfirm() {
            // If we previously had a service package set, prompt for a reason
            if (values.previous_service_package_name) {
              reasonForChangeDialog.open({
                title: 'Change service package',
                handleSubmit({ values }) {
                  Object.assign(changedPropertyOwnershipValues, values);

                  deferred.resolve();

                  return true;
                },
                handleClose() {
                  deferred.reject();
                }
              });
            } else {
              deferred.resolve();

              return true;
            }
          },
          onCancel() {
            deferred.reject();
          }
        });
      } else {
        deferred.resolve();
      }

      try {
        await deferred.promise;
      } catch (err) {
        // ignore err

        return true;
      }

      // Only make requests to the endpoints for the data that has changed
      const [
        propertyTenancyResponse,
        propertyOwnershipResponse
      ] = await Promise.all([
        changedPropertyTenancyValues &&
          updatePropertyTenancyItem({
            id: values[propertyTenancyKey]!.id,
            data: changedPropertyTenancyValues,
            args: {
              include:
                'property,tenancy,rent_schedule,rent_schedule.line_items,rent_schedule.line_items.payable_to_chart_of_accounts_account'
            }
          }),
        changedPropertyOwnershipValues &&
          updatePropertyOwnershipItem({
            id: values[propertyOwnershipKey]!.id,
            data: changedPropertyOwnershipValues,
            args: {
              include: 'maintenance_instructions,ownership,service_package'
            }
          })
      ]);

      if (propertyOwnershipResponse) {
        // update state hook with new value.
        const updatedSelectedPropertyOwnership = (propertyOwnershipResponse as AxiosResponse<PropertyOwnership>)
          .data;
        if (updatedSelectedPropertyOwnership) {
          setSelectedPropertyOwnership?.(updatedSelectedPropertyOwnership);
        }

        await refreshListsPropertyOwnershipFees();
      }

      if (propertyTenancyResponse) {
        // update state hook with new value.
        const updatedSelectedPropertyTenancy = (propertyTenancyResponse as AxiosResponse<PropertyTenancy>)
          .data;
        if (updatedSelectedPropertyTenancy) {
          setSelectedPropertyTenancy?.(updatedSelectedPropertyTenancy);
        }
      }

      const exludeFieldNames = ['previous_service_package_name'];

      // filter out the other models from the changed values. anything left over should belong to the property itself
      const changedFields = without(
        Object.keys(changedPropertyValues),
        ...exludeFieldNames
      );

      const hasPropertyChanged = changedFields.length > 0;
      const hasPropertyTenancyChanged = changedPropertyTenancyValues != null;

      // If the property hasn't changed and no rent charge changes, we don't need to make this final request
      if (!hasPropertyChanged && !hasPropertyTenancyChanged) {
        return true;
      }

      const hasAddressChanged = !!changedFields.find((field) =>
        field.includes('address')
      );

      // TODO: we are removing this values here so that they don't get written back, but in the future when they are removed from the model, we can remove references to them in the frontend as well
      ['address_line_1', 'address_line_2', 'city', 'state', 'postcode'].forEach(
        (field) => {
          delete changedPropertyValues[field];
        }
      );

      return updateProperty({
        id: propertyId,
        data: {
          ...changedPropertyValues,
          ...(changedPropertyValues?.adverts
            ? {
                adverts: [
                  { ...changedPropertyValues?.adverts, type: { id: 'default' } }
                ]
              }
            : {}),
          images: values.images ? await getUploadedFileMeta(values.images) : [],
          ...(changedPropertyValues?.links
            ? // @ts-ignore - not sure why this type error is here, was hidden by `any` type on `values`
              { links: values.links?.data }
            : {}),
          ...(hasAddressChanged ? { address } : {})
        },
        args: {
          include: propertyIncludes
        }
      });
    }
  );
}
