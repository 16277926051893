import React, { useCallback } from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Columns, RecordTable } from 'view/components/table';
import { ActionButtons } from 'view/components/@luna/action-buttons';
import _ from 'lodash';
import { TagCell } from '@rexlabs/table';
import { NeutralTag } from '@rexlabs/tag';
import AddIcon from 'view/components/icons/add';
import { useDialog } from '@rexlabs/dialog';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { AddMemberDialog } from '../dialogs/add-member-dialog';
import { PortfolioMember } from '../models/portfolio-members-model';
import { Portfolio } from '../models/portfolios-model';
import { useGetPortfolioMemberActions } from '../hooks/action-menu-items/use-get-portfolio-member-actions';

const columns: Columns<PortfolioMember> = [
  {
    id: 'name',
    accessor: (item) => item?.user?.display_name,
    type: 'text',
    Header: 'User name'
  },
  {
    id: 'role',
    type: 'text',
    Header: 'Role',
    accessor: (item) => _.capitalize(item.portfolio_role?.label),
    width: 180
  },
  {
    id: 'is_primary',
    accessor: (item) => item,
    Cell: ({ value }) => {
      return value.is_primary ? (
        <TagCell>
          <NeutralTag>{'Primary'}</NeutralTag>
        </TagCell>
      ) : (
        <></>
      );
    },
    width: 120
  }
];

export const membersBlock: BlockConfig<Portfolio> = {
  id: 'members',
  title: 'Members',
  Actions: ({ data }) => {
    const { open } = useDialog(AddMemberDialog);

    return (
      <ActionButtons
        actions={[
          {
            label: 'Add member',
            handleAction: () => open({ portfolioId: data!.id }),
            Icon: AddIcon
          }
        ]}
      />
    );
  },
  View: ({ data }) => {
    const getPortfolioMemberActions = useGetPortfolioMemberActions({
      portfolio: data!
    });

    const getPortfolioMemberActionMenuItems = useCallback(
      ({ item }) =>
        transformActionDeclarationsToActionMenuItems(
          getPortfolioMemberActions(item)
        ),
      [getPortfolioMemberActions]
    );

    return (
      <RecordTable
        columns={columns}
        isLoading={false}
        items={data?.members?.data || []}
        getActionMenuItems={getPortfolioMemberActionMenuItems}
      />
    );
  }
};
