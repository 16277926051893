import * as React from 'react';
import {
  DialogProps,
  useErrorDialog,
  useConfirmationDialog,
  useDialog
} from '@rexlabs/dialog';
import { OutlineButton } from '@rexlabs/button';
import { omit } from 'lodash';
import {
  useEntityQuery,
  query,
  useModelActions
} from '@rexlabs/model-generator';
import { useQueryClient } from 'react-query';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { AccessDeniedDialog } from 'view/components/dialogs/access-denied-dialog';
import { usePermission } from 'src/modules/authorization/roles/hooks/use-permission';
import { parentTransactionBlock } from '../blocks/parent-transaction';
import { childTransactionWithStatusBlock } from '../blocks/child-transaction-with-status';
import { bankStatementTransactionsModel } from '../models/bank-statement-transaction-model';
import {
  BankStatementChildTransaction,
  BatchReceiptingItem,
  NewSplitTransactionFormValue
} from '../types';
import { bankStatementTransactionsQueryKey } from '../hooks/use-bank-statement-transactions-query';
import { checkTransactionState } from '../utils/check-transaction-state';

export interface SplitBankTransactionViewEditDialogProps extends DialogProps {
  parentTransactionId: string;
}

const content: DialogContentConfig = [
  {
    id: 'split-bank-transaction',
    label: 'Split Bank Transaction',
    blocks: [parentTransactionBlock, childTransactionWithStatusBlock]
  }
];

const getBankStatementTransactionQuery = (
  parentTransactionId: string
) => query`{
  ${bankStatementTransactionsModel} (id: ${parentTransactionId}) {
    id
    child_transactions
    bank_account
    paid_by
  }
}`;

export function SplitBankTransactionViewEditDialog({
  onClose,
  parentTransactionId
}: SplitBankTransactionViewEditDialogProps) {
  const { isStandardUser, roleName } = usePermission();

  const { open: openErrorDialog } = useErrorDialog();
  const { open: openConfirmationDialog } = useConfirmationDialog();
  const accessDeniedDialog = useDialog(AccessDeniedDialog);

  const queryClient = useQueryClient();

  const {
    updateSubBankTransactions,
    deleteSubBankTransactions
  } = useModelActions(bankStatementTransactionsModel);

  const query = React.useMemo(
    () => getBankStatementTransactionQuery(parentTransactionId),
    [parentTransactionId]
  );
  const { data: parentTransactionData, status } = useEntityQuery(query, {
    throwOnError: false
  });

  const isLoading = status === 'loading';

  const [mode, setMode] = React.useState<'view' | 'edit'>('view');

  const isEditing = mode === 'edit';
  const submitLabel = isEditing ? 'Save' : 'Edit';

  const handleView = React.useCallback(() => {
    if (isStandardUser) {
      return accessDeniedDialog.open({
        role: roleName
      });
    }

    setMode('edit');
  }, [isStandardUser, roleName, accessDeniedDialog]);

  const handleEdit: RecordSubmitHandler<{
    childTransactions: Array<
      NewSplitTransactionFormValue | BankStatementChildTransaction
    >;
  }> = React.useCallback(
    async ({ changedValues }) => {
      try {
        const result = changedValues.childTransactions!.map((transaction) => {
          // Remove id if it is a newly added transaction
          if (transaction.id?.startsWith('new')) {
            return omit(transaction, ['id']);
          }

          return transaction;
        });

        await updateSubBankTransactions({
          bankStatementTransactionId: parentTransactionId,
          subTransactions: result
        });

        await queryClient.refetchQueries({
          queryKey: [bankStatementTransactionsQueryKey],
          refetchPage: () => true
        });

        return true;
      } catch (error) {
        console.error(error);
        openErrorDialog(error);
      }
    },
    [
      queryClient,
      openErrorDialog,
      parentTransactionId,
      updateSubBankTransactions
    ]
  );

  const handleDeleteSubTransactions = React.useCallback(async () => {
    try {
      await deleteSubBankTransactions({
        bankStatementTransactionId: parentTransactionId
      });

      await queryClient.refetchQueries({
        queryKey: [bankStatementTransactionsQueryKey],
        refetchPage: () => true
      });

      onClose?.();
    } catch (error) {
      console.error(error);
      openErrorDialog(error);
    }
  }, [parentTransactionId, onClose, queryClient, deleteSubBankTransactions]);

  const handleRestoreToParent = React.useCallback(() => {
    if (isStandardUser) {
      return accessDeniedDialog.open({
        role: roleName
      });
    }

    const hasMatchedTransactions = checkMatchedTransactions(
      parentTransactionData!
    );

    if (hasMatchedTransactions) {
      openConfirmationDialog({
        title: 'Restore to parent',
        message: `One or more child transactions have already been matched. Are you sure you wish to restore all transactions`,
        onConfirm: handleDeleteSubTransactions
      });

      return;
    }

    return handleDeleteSubTransactions();
  }, [
    isStandardUser,
    roleName,
    accessDeniedDialog,
    parentTransactionData,
    openConfirmationDialog,
    handleDeleteSubTransactions
  ]);

  return (
    <RecordDialog
      title='Split bank transaction'
      isLoading={isLoading}
      content={content}
      onClose={onClose}
      submitLabel={submitLabel}
      handleSubmit={isEditing ? handleEdit : handleView}
      initialValues={{
        childTransactions: parentTransactionData?.child_transactions?.data ?? []
      }}
      footerLeft={
        <OutlineButton onClick={handleRestoreToParent}>
          Restore to parent
        </OutlineButton>
      }
      blockProps={{
        batchReceiptingItem: parentTransactionData,
        mode
      }}
    />
  );
}

function checkMatchedTransactions(bankTransaction: BatchReceiptingItem) {
  return bankTransaction.child_transactions!.data.some((transaction) => {
    const state = checkTransactionState(transaction);

    return state === 'receipted' || state === 'matched';
  });
}
