import * as React from 'react';

import { usePutSettingsMutation } from 'src/modules/settings/hooks/usePutSettingsMutation';
import {
  AutomaticTaskCreationValues,
  useSettings
} from 'src/modules/settings/hooks/useSettings';

import { TitleBlock } from 'view/components/@luna/title-block';
import { RecordScreen } from 'view/components/record-screen';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';

import {
  mapTaskSettingsFormToApi,
  TaskSettingKey
} from 'src/modules/tasks/settings/utils/map-task-settings-form-to-api';
import { ServicePackage } from 'src/modules/service-packages/types/service-package';
import { useMapInspectionSettings } from 'src/modules/tasks/settings/mappers/use-map-inspection-settings';
import { useMapLeaseReviewSettings } from 'src/modules/tasks/settings/mappers/use-map-lease-review-settings';
import { useMapPropertyComplianceSettings } from 'src/modules/tasks/settings/mappers/use-map-property-compliance-settings';
import { useModelActions } from '@rexlabs/model-generator';
import { useQueryClient } from 'react-query';
import { useContent } from '../data/content';
import { CustomTaskTemplate } from '../types/CustomTaskTemplate';
import { customTaskTemplatesModel } from '../models/custom-task-templates-model';
import { customTaskTemplateQueryKey } from '../hooks/custom-task-templates/use-custom-task-templates';

type Count = number | null;

export type BaseTaskSettingFormValue = {
  count: Count;
  unit: string;
};

export interface TaskSettingsFormValues {
  custom_task_template?: CustomTaskTemplate;
  custom_field_values?: Record<string, any>;
  'property-compliance-task': BaseTaskSettingFormValue & {
    automatic_task_creation: AutomaticTaskCreationValues;
    service_packages: ServicePackage[];
  };
  'supplier-compliance-task': BaseTaskSettingFormValue;
  first_inspection: BaseTaskSettingFormValue;
  routine_inspection: {
    count: Count;
    unit: string;
    is_active: boolean;
  };
  notice_period: BaseTaskSettingFormValue;
  periodic_agreement_review: BaseTaskSettingFormValue;
  periodic_agreement_notice: BaseTaskSettingFormValue;
  fixed_agreement_notice: BaseTaskSettingFormValue;
  inspection_automatic_task_creation: AutomaticTaskCreationValues;
  inspection_service_packages: ServicePackage[];
  lease_review_automatic_task_creation: AutomaticTaskCreationValues;
  lease_review_service_packages: ServicePackage[];
}

export function TaskSettingsDetail() {
  const content = useContent();
  const putSettingsMutation = usePutSettingsMutation();
  const { updateItem } = useModelActions(customTaskTemplatesModel);
  const queryClient = useQueryClient();

  const settings = useSettings();
  const {
    isLoading: isLoadingPropertyCompliance,
    propertyComplianceSettings
  } = useMapPropertyComplianceSettings(settings['property-compliance-task']);
  const supplierComplianceSettings = settings['supplier-compliance-task'];
  const {
    isLoading: isLoadingInspection,
    inspectionSettings
  } = useMapInspectionSettings(settings['inspection-task-interval']);
  const {
    isLoading: isLoadingLeaseReview,
    leaseReviewSettings
  } = useMapLeaseReviewSettings(settings['lease-review-task-interval']);

  const handleSubmit: RecordSubmitHandler = async ({
    values,
    changedBlockIds
  }: {
    values: TaskSettingsFormValues;
    changedBlockIds: string[];
  }) => {
    const mappedValues = mapTaskSettingsFormToApi(
      values,
      changedBlockIds as TaskSettingKey[]
    );

    await putSettingsMutation.mutateAsync(mappedValues);

    // Determine if any custom tasks have been updated, and if so, handle the requests here.
    if (
      changedBlockIds.some((id) =>
        id.startsWith('custom-task-template-details-')
      )
    ) {
      // TODO: need to handle adding other fields later, but for now, we only care about capturing and persisting the values
      const template = values.custom_task_template;
      const fields = template?.custom_fields?.data ?? [];
      // TODO: id would be null if the field was newly added in the future, but we don't allow adding fields manually yet
      const fieldIds = fields
        .map((field) => field.id)
        .filter(Boolean) as string[];
      const customFieldValues = fieldIds.reduce<Record<string, any>>(
        (acc, fieldId) => {
          acc[fieldId] = values.custom_field_values?.[fieldId];
          return acc;
        },
        {}
      );

      await updateItem({
        id: template?.id,
        data: {
          label: template?.label,
          custom_field_values: customFieldValues
        },
        args: {
          include: ['custom_fields,custom_field_values,type'].join(',')
        }
      });

      // Invalidate the react-query cache so the dynamic blocks are updated
      await queryClient.invalidateQueries(customTaskTemplateQueryKey);
    }
  };

  return (
    <RecordScreen
      privilege={{
        mode: 'all',
        privileges: [
          'settings.read',
          'checklist-templates.update',
          'checklist-template-items.read'
        ]
      }}
      data={{
        'property-compliance-task': propertyComplianceSettings,
        'supplier-compliance-task': supplierComplianceSettings,
        ...inspectionSettings,
        ...leaseReviewSettings
      }}
      isLoading={
        isLoadingInspection &&
        isLoadingLeaseReview &&
        isLoadingPropertyCompliance
      }
      content={content}
      handleSubmit={handleSubmit}
      titleBlock={<TitleBlock title='Task settings' />}
    />
  );
}
