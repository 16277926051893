import { NormalisedItem, Select } from '@rexlabs/select';
import React from 'react';
import { PortfolioRole } from 'src/modules/portfolio-roles/types/PortfolioRole';
import { usePortfolioRoles } from 'src/modules/portfolios/hooks/use-portfolio-roles';

export function PortfolioRoleSelect(props) {
  const { data: roles } = usePortfolioRoles();

  return <Select normaliser={normaliser} items={roles} {...props} />;
}

const normaliser = (item: PortfolioRole): NormalisedItem => ({
  id: item.id,
  label: item.label
});
