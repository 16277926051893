import React, { useContext } from 'react';
import { isNumber } from 'lodash';

import { ReactTableContext, TableContext } from '@rexlabs/table';

import { EmptyState } from '../states/compact/empty';
import { ActionButtons } from '../action-buttons';

// Use this component to show an empty state inside the table. It renders a reset filters action, if
// the table is not using the default filters.
export function TableEmptyState() {
  const {
    setGlobalFilter,
    pagination: { totalItems = 0 } = {},
    previousPage,
    initialState: { globalFilter }
  } = useContext(ReactTableContext);
  const { isUsingDefaultFilters } = useContext(TableContext);

  return (
    <EmptyState
      action={
        <ActionButtons
          actions={[
            ...(!isUsingDefaultFilters && globalFilter
              ? [
                  {
                    label: 'Reset filters',
                    onClick: () => setGlobalFilter(globalFilter)
                  }
                ]
              : []),
            ...(isNumber(totalItems) && totalItems !== 0
              ? [
                  {
                    label: 'Go back a page',
                    onClick: () => previousPage()
                  }
                ]
              : [])
          ]}
        />
      }
    >
      No items found.
    </EmptyState>
  );
}
