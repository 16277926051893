import React, { FC, useCallback, useMemo } from 'react';
import {
  FreeformDialogProps,
  Select,
  SelectProps,
  useFreeformDialog,
  UseFreeformDialogArgs,
  UseItemsArgs
} from '@rexlabs/select';

import { CreateContactDialog } from 'src/modules/contacts/dialogs/create-contact-dialog';

import { normaliseContact } from 'utils/normaliser/contact';
import { getContacts } from 'utils/api/get-contacts';
import { Contact, ContactRole } from 'src/modules/contacts/types/contact-types';
import { merge } from 'lodash';
import { useItems } from 'view/hooks/use-items';

function FreeformContactDialog({
  contact: propContact,
  inputValue,
  selectItem,
  onCreate: propOnCreate,
  ...props
}: any & FreeformDialogProps<Partial<Contact>>) {
  const names = inputValue.split(' ');
  const contact = useMemo(
    () =>
      merge(
        {
          given_name: names?.[0],
          middle_name:
            names?.length > 2 ? names.slice(1, -1)?.join(' ') : undefined,
          family_name: names?.length > 1 ? names[names.length - 1] : undefined
        },
        propContact
      ),
    [names, propContact]
  );

  const onCreate = useCallback(
    (data) => {
      propOnCreate?.(data);
      selectItem(data);
    },
    [propOnCreate, selectItem]
  );

  return (
    <CreateContactDialog
      {...props}
      initialValues={contact}
      onCreate={onCreate}
    />
  );
}

export type ContactSelectProps = SelectProps<Contact> &
  Pick<UseItemsArgs<Contact>, 'getSuggestedItems'> & {
    dialogProps?: UseFreeformDialogArgs<Contact>['props'];
    filterByRoles?: ContactRole[];
    disableFixture?: boolean;
    customFilter?: (item: Contact) => Contact[];
    includes?: Array<string>;
  };

export const ContactSelect: FC<ContactSelectProps> = ({
  dialogProps,
  getSuggestedItems,
  filterByRoles = [],
  disableFixture = false,
  customFilter,
  includes,
  ...props
}) => {
  const getItems = useCallback(
    async (searchTerm) => {
      return getContacts({
        searchTerm,
        filterByRoles,
        includes
      }).then((contacts) =>
        contacts
          .filter((item: Contact) => {
            if (customFilter) {
              return customFilter?.(item);
            }
            return true;
          })
          .map((contact) => {
            return {
              ...contact,
              value: contact.id,
              label: contact.display_name
            };
          })
      );
    },
    [customFilter]
  );

  const { getSelectProps: useItemsGetSelectProps } = useItems<Contact>({
    getItems,
    getSuggestedItems
  });
  const {
    getSelectProps: useFreeformDialogGetSelectProps
  } = useFreeformDialog<Contact>({
    props: dialogProps,
    Dialog: FreeformContactDialog
  });

  const selectProps = useItemsGetSelectProps();
  const selectPropsWithFixture = useItemsGetSelectProps(
    useFreeformDialogGetSelectProps()
  );

  return (
    <Select
      {...props}
      {...(disableFixture ? selectProps : selectPropsWithFixture)}
      normaliser={normaliseContact}
    />
  );
};
