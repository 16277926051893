import React, { useMemo } from 'react';
import { query, useEntityQuery } from '@rexlabs/model-generator';

import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { RecordScreen } from 'view/components/record-screen';

import { useRecordScreenSubmitHandler } from 'view/hooks/use-record-screen-submit-handler';
import { TaskRightBarOverride } from 'src/modules/tasks/common/components/right-bar-override';
import { mapTaskFormDataToTaskCreateRequest } from 'src/modules/tasks/common/mappers/map-task-form-data-to-task-create-request';
import { useSettingsQuery } from 'src/modules/settings/hooks/useSettingsQuery';
import { content } from '../data/content';
import { ArrearsTitleBlock } from '../components/arrears-title-block';
import { tasksModel } from '../../common/models/tasks-model';
import { ArrearsTask } from '../types/ArrearsTask';
import { getSearchResultItemFromManagedBy } from '../../common/utils/get-search-result-item-from-managed-by';

// note the property comes back on the property_tenancy for the arrears task
const getArrearsQuery = (id: string) => query`{
  ${tasksModel} (id: ${id}) {
    id
    managed_by
    task_links
    arrearsDetails
    created_by
    updated_by
  }
}`;

interface ArrearsDetailsProps {
  arrearsId?: string;
}

export function ArrearsDetails({ arrearsId }: ArrearsDetailsProps) {
  const breadcrumbs = [{ type: BREADCRUMBS.ARREARS }];
  const query = useMemo(() => getArrearsQuery(arrearsId!), [arrearsId]);
  const {
    data: settingsData,
    isLoading: isSettingsLoading
  } = useSettingsQuery();

  const { status, data: rawData, actions } = useEntityQuery(query, {
    throwOnError: false
  });

  const data: ArrearsTask | undefined = rawData;
  const initialValues = data
    ? { ...data, managed_by: getSearchResultItemFromManagedBy(data.managed_by) }
    : undefined;
  const titleBlock = (
    <ArrearsTitleBlock arrears={data} settings={settingsData} />
  );

  const handleSubmit = useRecordScreenSubmitHandler(
    async ({ changedValues }) => {
      const dataToSubmit = mapTaskFormDataToTaskCreateRequest(changedValues);
      await actions.updateItem({
        id: arrearsId,
        data: dataToSubmit
      });
    }
  );

  return (
    <RecordScreen
      privilege={'tasks.arrears-jobs.read'}
      isLoading={isSettingsLoading || status === 'loading'}
      data={data}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      content={content}
      titleBlock={titleBlock}
      breadcrumbs={breadcrumbs}
      RightBarOverride={TaskRightBarOverride}
    />
  );
}
