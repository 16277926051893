import { useMutation, useQueryClient } from 'react-query';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { api } from 'utils/api/api-client';
import { MetricWriteRule } from 'src/modules/metric-write-rules/types/MetricWriteRule';
import { METRIC_WRITE_RULES_QUERY_KEY } from 'src/modules/metric-write-rules/hooks/use-metric-write-rules';

export function useGetToggleEnabledMetricWriteRuleAction() {
  const queryClient = useQueryClient();

  const toggleEnabledMutation = useMutation(
    (metricWriteRule: MetricWriteRule) =>
      api.put<MetricWriteRule>(`/metric-write-rules/${metricWriteRule.id}`, {
        ...metricWriteRule,
        is_enabled: !metricWriteRule.is_enabled
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(METRIC_WRITE_RULES_QUERY_KEY);
      }
    }
  );

  return (metricWriteRule: MetricWriteRule): ActionDeclaration => {
    return {
      id: 'toggle-enabled-metric-write-rule',
      group: 'metric-write-rules',
      label: metricWriteRule.is_enabled ? 'Disable' : 'Enable',
      handleAction: async () => {
        await toggleEnabledMutation.mutateAsync(metricWriteRule);
      }
    };
  };
}
