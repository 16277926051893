import * as React from 'react';

import { Card } from 'view/components/card';
import { ListScreen } from 'view/components/list-screen/list-screen';

import { WorkOrdersTabbedTable } from '../components/work-orders-tabbed-table';
import { useGetCreateWorkOrderAction } from '../hooks/action-declarations/use-get-create-work-order-action';

export function WorkOrdersList() {
  const getCreateWorkOrderAction = useGetCreateWorkOrderAction();

  return (
    <ListScreen
      privilege={'tasks.work-orders.read'}
      title='Work orders'
      actions={[getCreateWorkOrderAction()]}
    >
      <Card>
        <WorkOrdersTabbedTable />
      </Card>
    </ListScreen>
  );
}
