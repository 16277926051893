/**
 * TODO: revisit this, this is just a temporary implementation using an action menu
 * because overriding select styles still made dealing with spacing a bit dodgy
 */

import React, { forwardRef } from 'react';
import ActionMenu from '@rexlabs/action-menu';
import Box from '@rexlabs/box';

import { ArrowDownLabelButton } from 'src/modules/invoices/components/arrow-down-label-button';

interface TaxIncludedInlineSelectProps {
  prefix?: string;
  value: boolean;
  onChange: (e: any) => void;
}

export function TaxIncludedInlineSelect({
  value,
  onChange,
  prefix
}: TaxIncludedInlineSelectProps) {
  const includeTaxLabel = prefix
    ? `${prefix} subtotal incl. tax`
    : 'Subtotal incl. tax';
  const excludeTaxLabel = prefix
    ? `${prefix} subtotal excl. tax`
    : 'Subtotal excl. tax';

  return (
    <ActionMenu
      items={[
        {
          label: includeTaxLabel,
          onClick: () => onChange({ target: { value: true } })
        },
        {
          label: excludeTaxLabel,
          onClick: () => onChange({ target: { value: false } })
        }
      ]}
      Button={forwardRef<HTMLDivElement, any>((props, ref) => (
        <Box ref={ref} {...props}>
          <ArrowDownLabelButton
            label={value ? includeTaxLabel : excludeTaxLabel}
          />
        </Box>
      ))}
    />
  );
}
