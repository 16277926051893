import { valueListFactory } from 'src/lib/testing/factories/value-list-factory';
import {
  FolioCommissionTemplate,
  FolioCommissionTemplateTier
} from '../types/FolioCommissionTemplate';
import { FolioCommissionTemplateFormData } from '../types/FolioCommissionTemplateFormData';
import { mapTierFormDataToRequest } from './map-tier-form-data-to-request';

export function mapUpsertSupplierCommissionTemplatePayload(
  values: FolioCommissionTemplateFormData
): Partial<Omit<FolioCommissionTemplate, 'tiers'>> & {
  tiers?: Partial<FolioCommissionTemplateTier[]>;
} {
  const {
    structure_type,
    commission_amount_currency,
    commission_amount_percent,
    tiers,
    ...rest
  } = values;

  const structureTypeId = structure_type?.id;

  const common = {
    structure_type: valueListFactory(structureTypeId),
    ...rest
  };

  switch (structureTypeId) {
    case 'flat_fee':
      return {
        ...common,
        commission_amount: commission_amount_currency
      };
    case 'percentage':
      return {
        ...common,
        commission_amount: commission_amount_percent
      };
    case 'tiered':
    case 'progressive':
      return {
        ...common,
        tiers: mapTierFormDataToRequest(tiers || [])
      };
  }
}
