import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';
import { Value } from 'view/components/values';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import { Grid } from 'view/components/@luna/form/grid';
import { Select } from '@rexlabs/select';
import { CustomReport } from '../types/CustomReport';
import {
  CustomReportDescriber,
  useCustomReportDescribers
} from '../hooks/use-custom-report-describers';

const validate = {
  definitions: {
    label: { rules: 'required' },
    description: { rules: 'required' },
    resource: { rules: 'required' }
  }
};

export const customReportDetailsBlock: BlockConfig<CustomReport> = {
  id: 'details',
  title: 'Details',
  validate,
  View: ({ data }) => {
    return (
      <Grid columns={1}>
        <Grid columns={2}>
          <Value label='Label' value={data?.label} />
          <Value label='Resource' value={data?.resource.label} />
        </Grid>
        <Value label='Description' value={data?.description} />
      </Grid>
    );
  },
  Edit: (_props) => {
    const { data } = useCustomReportDescribers();
    return (
      <Grid columns={1}>
        <Grid columns={2}>
          <Field name='label' label='Label' Input={TextInput} />
          <Field
            name='resource'
            label='Resource'
            Input={Select}
            inputProps={{
              items: data,
              normaliser: (item: CustomReportDescriber) => ({
                id: item.id,
                label: item.label
              })
            }}
          />
        </Grid>

        <Field name='description' label='Description' Input={TextInput} />
      </Grid>
    );
  }
};
