import { useDialog } from '@rexlabs/dialog';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { FolioCommissionTemplate } from '../../types/FolioCommissionTemplate';
import { UpdateSupplierCommissionTemplateDialog } from '../../dialogs/update-supplier-commission-template-dialog';

export function useGetEditSupplierCommissionTemplateAction() {
  const { open } = useDialog(UpdateSupplierCommissionTemplateDialog);

  return (item: FolioCommissionTemplate): SingleActionDeclaration => {
    return {
      label: 'Edit',
      handleAction: () =>
        open({
          supplierCommissionTemplate: item
        })
    };
  };
}
