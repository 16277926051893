import React from 'react';
import { useDialog } from '@rexlabs/dialog';
import { PrimaryButton } from '@rexlabs/button';
import { Columns, TableProvider } from 'view/components/table';
import { ActionMenuItem } from '@rexlabs/action-menu';
import Box from '@rexlabs/box';
import { useToken } from '@rexlabs/styling';
import { AddEditMetricDialog } from 'src/modules/metric-write-rules/dialogs/add-edit-metric-dialog';
import { BlockViewProps } from 'view/components/record-screen/types';
import { DumbTable } from 'view/components/table/dumb-table';
import { MetricConfig } from 'src/modules/metric-write-rules/types/Metric';
import { Heading } from '@rexlabs/text';
import { MetricWriteRuleFormData } from '../types/MetricWriteRule';

interface MetricsTableProps {
  setFieldValue: BlockViewProps['setFieldValue'];
  values: MetricWriteRuleFormData | undefined;
}

const metricColumns: Columns = [
  {
    id: 'identifier',
    Header: 'Identifier',
    width: 120
  },
  {
    id: 'name',
    Header: 'Name'
  },
  {
    id: 'type',
    width: 120,
    Header: 'Type',
    accessor: (item) => (item.is_reversal ? 'Reversal' : 'Metric')
  }
];

export function MetricsTable({ setFieldValue, values }: MetricsTableProps) {
  const token = useToken();
  const {
    open: openAddEditMetricDialog,
    close: closeAddEditMetricDialog
  } = useDialog(AddEditMetricDialog);

  const handleAddMetric = () => {
    openAddEditMetricDialog({
      onClose: closeAddEditMetricDialog,
      onSave: (newMetric: MetricConfig) => {
        setFieldValue?.('metrics', [...(values?.metrics || []), newMetric]);
      }
    });
  };

  const handleEditMetric = (metric: MetricConfig) => {
    openAddEditMetricDialog({
      onClose: closeAddEditMetricDialog,
      onSave: (updatedMetric: MetricConfig) => {
        setFieldValue?.(
          'metrics',
          values?.metrics?.map((m) => (m.id === metric.id ? updatedMetric : m))
        );
      },
      initialValues: metric
    });
  };

  const handleDeleteMetric = (metric: MetricConfig) => {
    setFieldValue?.(
      'metrics',
      values?.metrics?.filter((m) => m.id !== metric.id)
    );
  };

  return (
    <Box
      marginBottom={token('spacing.m')}
      style={{
        zIndex: 0
      }}
    >
      <Heading level={4}>Metrics</Heading>
      <TableProvider
        id='metrics'
        columns={metricColumns}
        isLoading={false}
        items={values?.metrics}
        getActionMenuItems={({ item }): ActionMenuItem[] => [
          {
            label: 'Edit',
            onClick: () => handleEditMetric(item)
          },
          {
            label: 'Delete',
            intent: 'danger',
            onClick: () => handleDeleteMetric(item)
          }
        ]}
      >
        <DumbTable
          Empty={() => (
            <Box
              alignItems={'center'}
              justifyContent={'center'}
              pt={token('spacing.xl')}
            >
              No metrics added
            </Box>
          )}
          withPagination={false}
        />
      </TableProvider>
      <Box marginTop={token('spacing.s')}>
        <PrimaryButton onClick={handleAddMetric}>Add Metric</PrimaryButton>
      </Box>
    </Box>
  );
}
