import { useCallback } from 'react';
import { useErrorDialog } from '@rexlabs/dialog';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';

export function useRecordScreenSubmitHandler<TValueType = any>(
  submitHandler: RecordSubmitHandler<TValueType>
) {
  const { open } = useErrorDialog();

  return useCallback(
    async (args) => {
      let isSuccess = true;
      try {
        await submitHandler(args);
      } catch (e) {
        isSuccess = false;
        open(e);
      }
      return isSuccess;
    },
    [open, submitHandler]
  );
}
