import { usePostAPI } from 'view/hooks/api/use-post-api';
import { useMemo } from 'react';
import { SecurityDepositAmount } from 'data/models/entities/financials/security-deposit-types';

type CalculatedRent = {
  amount: number;
};

type CalculatedRentRequestBody = {
  frequency: {
    type: {
      id: 'weeks';
    };
    amount: number;
  };
};

type RoundingMode =
  | 'round_half_up'
  | 'round_half_down'
  | 'round_ceiling'
  | 'round_floor';

export function useGetCalculatedRent(
  propertyTenancyId: string,
  frequencyType?: SecurityDepositAmount['type']['id'],
  frequencyAmount?: number,
  frequencyRoundingMode?: RoundingMode
) {
  const url = `/property-tenancies/${propertyTenancyId}/rent-calculation`;
  const requestBody: CalculatedRentRequestBody | undefined = useMemo(() => {
    if (!frequencyType || frequencyType === 'amount') return undefined;

    return {
      frequency: {
        type: {
          id: 'weeks'
        },
        amount: frequencyAmount ?? 0,
        ...(frequencyRoundingMode
          ? {
              rounding_mode: {
                id: frequencyRoundingMode
              }
            }
          : {})
      }
    };
  }, [frequencyType, frequencyAmount]);

  const { loadedData, isLoading, error } = usePostAPI<
    CalculatedRent,
    CalculatedRentRequestBody
  >(url, requestBody);

  return {
    calculatedRent: loadedData,
    isLoading,
    error: frequencyType === 'amount' ? undefined : error
  };
}
