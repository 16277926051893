import React from 'react';

import { RecordCell } from '@rexlabs/table';

import ROUTES from 'routes/app';

import { Invoice } from 'data/models/entities/financials/invoices';

export function InvoiceCell(props) {
  const { value } = props;

  return (
    <RecordCell
      value={`${value?.record_reference} ${value?.description ?? ''}`}
      cell={{ value }}
      column={{
        cellProps: {
          getCellLinkProps: (value: Invoice) => {
            return {
              to: ROUTES.INVOICE,
              params: {
                invoiceId: value?.id
              }
            };
          }
        }
      }}
    />
  );
}
