import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import { propertyTenancyModel } from 'src/modules/property-tenancies/models/property-tenancy-model';
import { useStatusChangeAction } from 'src/modules/common/actions/status/use-status-change-action';
import {
  PropertyTenancy,
  PropertyTenancyStatusType
} from 'src/modules/property-tenancies/types/property-tenancy-types';
import { useDeleteAction } from 'src/modules/common/actions/delete/use-delete-action';
import { useModelActions } from '@rexlabs/model-generator';
import { propertiesModel } from 'data/models/entities/properties';
import { useEditRecordDetailsAction } from 'src/modules/common/actions/record-details/hooks/use-edit-record-details-action';
import { useSetPropertyTenancyStatusAction } from 'src/modules/property-tenancies/actions/use-set-property-tenancy-status-action';
import { useGetOpenRegenerateInvoicesDialogAction } from './use-get-open-regenerate-invoices-dialog-action';

export function usePropertyTenancyActions() {
  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();
  const setStatusAction = useStatusChangeAction(propertyTenancyModel);
  const deleteAction = useDeleteAction(propertyTenancyModel);
  const { refreshItem: refreshProperty } = useModelActions(propertiesModel);
  const getEditRecordAction = useEditRecordDetailsAction(propertyTenancyModel);
  const getOpenRegenerateInvoicesDialogAction = useGetOpenRegenerateInvoicesDialogAction();
  const setPropertyTenancyStatusAction = useSetPropertyTenancyStatusAction();

  return (propertyTenancy?: PropertyTenancy, title?: string) => {
    if (!propertyTenancy) return [];

    const afterStatusChange = () => {
      refreshProperty({
        id: propertyTenancy.property_id,
        args: {
          include:
            'active_property_ownership,active_property_ownership.ownership,active_property_ownership.ownership.owners.contact,active_property_ownership.ownership.financial_summary,active_property_tenancy,active_property_tenancy.tenancy.tenants.contact,compliance_categories,features,images.file,incoming_property_tenancy,incoming_property_tenancy.tenancy.tenants.contact,incoming_property_ownership,incoming_property_ownership.ownership,incoming_property_ownership.ownership.owners.contact,links,views,portfolio.users,portfolio.users.contact,created_by,updated_by'
        }
      });
    };

    const regenInvoicesAction = getOpenRegenerateInvoicesDialogAction(
      propertyTenancy
    );

    const activateAction = setPropertyTenancyStatusAction({
      propertyTenancy,
      status: 'active',
      title: title || propertyTenancy.display_name,
      afterStatusChange
    });

    const outgoingAction = setPropertyTenancyStatusAction({
      propertyTenancy,
      status: 'outgoing',
      title: title || propertyTenancy.display_name,
      afterStatusChange
    });

    const incomingAction = setPropertyTenancyStatusAction({
      propertyTenancy,
      status: 'incoming',
      title: title || propertyTenancy.display_name,
      afterStatusChange
    });

    const archiveAction = setStatusAction<PropertyTenancyStatusType>({
      record: propertyTenancy,
      status: 'archived',
      dialogOptions: {
        afterAction: afterStatusChange
      }
    });

    const draftAction = setStatusAction<PropertyTenancyStatusType>({
      record: propertyTenancy,
      status: 'draft',
      dialogOptions: {
        type: 'reason',
        afterAction: afterStatusChange,
        shouldRequireReason: false
      }
    });

    const statusActionMap = {
      active: [
        regenInvoicesAction,
        draftAction,
        incomingAction,
        outgoingAction,
        archiveAction
      ],
      draft: [
        activateAction,
        incomingAction,
        outgoingAction,
        archiveAction,
        deleteAction(propertyTenancy, {
          afterAction: afterStatusChange
        })
      ],
      incoming: [
        regenInvoicesAction,
        draftAction,
        activateAction,
        outgoingAction,
        archiveAction
      ],
      outgoing: [
        regenInvoicesAction,
        draftAction,
        activateAction,
        incomingAction,
        archiveAction
      ],
      archived: [draftAction, activateAction, incomingAction, outgoingAction]
    };

    return [
      ...getPrimaryRecordActionGroup(
        'property_tenancy',
        propertyTenancy.id,
        'View',
        propertyTenancy.property_id
      ),
      getEditRecordAction(propertyTenancy),
      ...(statusActionMap[propertyTenancy.status.id] ?? [])
    ];
  };
}
