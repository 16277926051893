import React from 'react';
import { DialogProps, useErrorDialog } from '@rexlabs/dialog';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { useRecordUpdatedToast } from 'src/modules/common/toasts/hooks/use-record-updated-toast';
import { useMutation, useQueryClient } from 'react-query';
import { api } from 'utils/api/api-client';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';
import { METRIC_WRITE_RULES_QUERY_KEY } from '../hooks/use-metric-write-rules';
import { MetricWriteRule } from '../types/MetricWriteRule';
import { metricWriteRuleDetailsBlock } from '../blocks/metric-write-rule-details-block';

export interface EditMetricWriteRuleDialogProps extends DialogProps {
  onClose?: () => void;
  metricWriteRule: MetricWriteRule;
}

const content: DialogContentConfig = [
  {
    id: 'add-metric-write-rule',
    label: 'Add Metric Write Rule',
    blocks: [metricWriteRuleDetailsBlock]
  }
];

export function EditMetricWriteRuleDialog({
  onClose,
  metricWriteRule
}: EditMetricWriteRuleDialogProps) {
  const queryClient = useQueryClient();
  const { open: openErrorDialog } = useErrorDialog();
  const addToast = useRecordUpdatedToast('metric_write_rule');

  const updateMetricWriteRule = useMutation(
    (updatedRule: MetricWriteRule) =>
      api.put<MetricWriteRule>(
        `/metric-write-rules/${updatedRule.id}`,
        updatedRule
      ),
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(METRIC_WRITE_RULES_QUERY_KEY);
        const updatedRule = response.data;
        addToast(updatedRule);
        onClose?.();
      },
      onError: (error) => {
        openErrorDialog(error);
      }
    }
  );

  return (
    <RecordDialog
      title={`Edit Metric Write Rule: ${metricWriteRule.name}`}
      content={content}
      onClose={onClose}
      submitLabel='Update Metric Write Rule'
      handleSubmit={({ values }) => {
        updateMetricWriteRule.mutateAsync(values);
      }}
      initialValues={metricWriteRule}
      data={metricWriteRule}
    />
  );
}
