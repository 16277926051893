import { DialogProps } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import * as React from 'react';
import { useRecordCreatedToast } from 'src/modules/common/toasts/hooks/use-record-created-toast';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { userGroupDetailsBlock } from '../blocks/user-group-details-block';
import { userGroupsModel } from '../models/user-groups-model';

export type CreateUserGroupRecordDialogProps = DialogProps;

const content = [
  {
    id: 'add-user-group',
    label: 'Add User group',
    blocks: [userGroupDetailsBlock]
  }
];

export function CreateUserGroupRecordDialog({
  onClose
}: CreateUserGroupRecordDialogProps) {
  const { createItem, refreshLists } = useModelActions(userGroupsModel);
  const addToast = useRecordCreatedToast(userGroupsModel, { actions: [] });

  const handleSubmit = async ({ values }) => {
    const dataToSubmit = { ...values };

    const { data: userGroup } = await createItem({ data: dataToSubmit });

    addToast(userGroup);

    return refreshLists();
  };

  return (
    <RecordDialog
      title='Create User group'
      content={content}
      onClose={onClose}
      handleSubmit={handleSubmit}
    />
  );
}
