import React, { ComponentProps } from 'react';

import Box from '@rexlabs/box';
import {
  useStyles,
  useToken,
  StyleSheet,
  margin,
  padding,
  border
} from '@rexlabs/styling';
import { Small } from '@rexlabs/text';

import { StatusIndicator } from '../components/status-indicator';
import { RecordStatus } from '../types';

const defaultStyles = StyleSheet({
  container: {
    '&:not(:last-of-type)': {
      ...border.styles({
        bottom: {
          width: 'thin',
          color: 'container.static.light'
        }
      })
    },

    '&:not(:first-of-type)': {
      ...padding.styles({
        top: 'm'
      })
    }
  },

  headline: {
    textTransform: 'uppercase',

    color: ({ token }) => token('color.textStyle.body.subtext')
  },

  indent: {
    ...margin.styles({
      // Indicator size + indicator margin
      left: 'xl'
    })
  },

  indicator: {
    ...margin.styles({
      right: 'xs'
    })
  },

  list: {
    ...padding.styles({
      bottom: 'm'
    }),

    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: ({ token }) => token('spacing.xs'),

    '& > a': {
      ...margin.styles({
        left: 'm'
      })
    },

    '& > a > * ': {
      transition: 'background 100ms ease-out',

      padding: ({ token }) => token('spacing.xs'),
      borderRadius: ({ token }) => token('border.radius.l'),

      '&:hover': {
        background: ({ token }) => token('color.container.interactive.hover')
      }
    }
  }
});

export interface EntryListProps extends ComponentProps<'div'> {
  status?: RecordStatus;
  headline?: React.ReactNode;
}

export function EntryList({
  status,
  headline,
  children,
  ...props
}: EntryListProps) {
  const s = useStyles(defaultStyles);
  const token = useToken();

  return (
    <div {...s('container')} {...props}>
      {headline && (
        <Box alignItems='center' mb={token('spacing.xs')}>
          {status && <StatusIndicator {...s('indicator')} status={status} />}
          <Small {...s('headline', { indent: !status })} bold as='h5'>
            {headline}
          </Small>
        </Box>
      )}
      <div {...s('list')}>{children}</div>
    </div>
  );
}
