import * as React from 'react';
import { ListTable } from 'view/components/table';
import { Card } from 'view/components/card';
import { ListScreen } from 'view/components/list-screen/list-screen';
import { query } from '@rexlabs/model-generator';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { PORTFOLIO_ROLE_ROUTES } from '../routes';

import { portfolioRoleColumns } from '../utils/portfolio-role-columns';
import { useGetPortfolioRoleActions } from '../hooks/action-menu-items/use-get-portfolio-role-actions';
import { portfolioRolesModel } from '../models/portfolio-roles-model';
import { useGetCreatePortfolioRoleAction } from '../hooks/action-declarations/use-get-create-portfolio-role-action';

const portfolioRoleQuery = query`{
  ${portfolioRolesModel} {
    id
  }
}`;

/**
 * Tabbed List Table
 * Model Generator Variant
 */
export function PortfolioRoleList() {
  const getPortfolioRoleActions = useGetPortfolioRoleActions();
  const getCreatePortfolioRoleAction = useGetCreatePortfolioRoleAction();

  const { getSort, getFilters } = useTableFilters('portfolio-roles');

  const getRowLinkProps = ({ item }) => {
    return {
      to: PORTFOLIO_ROLE_ROUTES.PORTFOLIO_ROLE_DETAILS,
      params: { portfolioRoleId: item.id }
    };
  };

  const getActionMenuItems = React.useCallback(
    ({ item }) => {
      const actions = getPortfolioRoleActions(item);
      return transformActionDeclarationsToActionMenuItems(actions);
    },
    [getPortfolioRoleActions]
  );

  return (
    <ListScreen
      privilege={'portfolio-roles.read'}
      title='Portfolio roles'
      actions={[getCreatePortfolioRoleAction()]}
    >
      <Card>
        <ListTable
          id='portfolio-roles'
          columns={portfolioRoleColumns}
          getQuery={() => portfolioRoleQuery}
          getRowLinkProps={getRowLinkProps}
          getActionMenuItems={getActionMenuItems}
          getFilters={getFilters}
          getSort={getSort}
        />
      </Card>
    </ListScreen>
  );
}
