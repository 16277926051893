import { Generator, GeneratorConfig } from 'data/models/generator';
import { DeferredFeeLineItem } from '../types/DeferredFeeLineItem';

const config: GeneratorConfig = {
  entities: {
    related: {
      payable_to_chart_of_accounts_account: {
        include: 'payable_to_chart_of_accounts_account'
      },
      payable_by_chart_of_accounts_account: {
        include: 'payable_by_chart_of_accounts_account'
      },
      folio: {
        include: 'folio'
      },
      tax_type: {
        include: 'tax_type'
      },
      source_object: {
        include: 'source_object'
      },
      payment_invoice: {
        include: 'payment_invoice'
      },
      payable_by_property: {
        include: 'payable_by_property'
      }
    }
  }
};

export const deferredFeeLineItemModel = new Generator<DeferredFeeLineItem>(
  'financials/deferred-fee-line-items',
  config
).createEntityModel();
