import React, { useMemo } from 'react';
import { query, useEntityQuery } from '@rexlabs/model-generator';

import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { RecordScreen } from 'view/components/record-screen';

import { useRecordScreenSubmitHandler } from 'view/hooks/use-record-screen-submit-handler';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { content } from '../data/content';
import { MessageTemplateTitleBlock } from '../components/message-template-title-block';
import { messageTemplatesModel } from '../models/message-templates-model';
import { CreateTemplateFormData } from '../types/create-template-form-data';
import { useGetMapCreateTemplateFormDataToCreateTemplateRequest } from '../mappers/map-create-template-form-data-to-create-template-request';
import { mapTemplateToCreateMessageTemplateFormData } from '../mappers/map-template-to-create-template-form-data';
import { messageTemplateQueryFragment } from '../data/message-template-query-fragment';

const getMessageTemplateQuery = (id: string) => query`{
  ${messageTemplatesModel} (id: ${id}) {
    ${messageTemplateQueryFragment}
  }
}`;

interface MessageTemplateDetailsProps {
  messageTemplateId?: string;
}

export function MessageTemplateDetails({
  messageTemplateId
}: MessageTemplateDetailsProps) {
  const breadcrumbs = [{ type: BREADCRUMBS.MESSAGE_TEMPLATE }];
  const query = useMemo(() => getMessageTemplateQuery(messageTemplateId!), [
    messageTemplateId
  ]);

  const { status, data, actions } = useEntityQuery(query);

  const mapCreateTemplateFormDataToCreateTemplateRequest = useGetMapCreateTemplateFormDataToCreateTemplateRequest();

  const handleSubmit: RecordSubmitHandler<CreateTemplateFormData> = useRecordScreenSubmitHandler(
    async ({ values }) => {
      const dataToSubmit = await mapCreateTemplateFormDataToCreateTemplateRequest(
        values
      );

      await actions.updateItem({
        id: messageTemplateId,
        data: dataToSubmit,
        args: {
          include: [
            'channels',
            'send_from_author',
            'inline_attachments',
            'document_types',
            'compliance_types'
          ].join(',')
        }
      });

      await actions.refreshItem({ id: messageTemplateId });
    }
  );

  const titleBlock = <MessageTemplateTitleBlock messageTemplate={data} />;

  // Map our model data to the form data
  const initialValues = data
    ? mapTemplateToCreateMessageTemplateFormData(data)
    : {};

  return (
    <RecordScreen
      privilege={'message-templates.update'}
      isLoading={status === 'loading'}
      data={data}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      content={content}
      titleBlock={titleBlock}
      breadcrumbs={breadcrumbs}
    />
  );
}
