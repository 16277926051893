import React, { useContext, useMemo } from 'react';

import {
  SimplePagination,
  SimplePaginationProps,
  TableContext,
  Table as VividTable,
  TableProps
} from '@rexlabs/table';

import { Toolbar, ToolbarProps } from './toolbar';
import { TableEmptyState } from './table-empty-state';
import { ReplaceTableStatesComponent } from './replace-table-states-component';
import { TableLoadingState } from './table-loading-state';

type NewTableProps = {
  tableProps?: TableProps;
  toolbarProps?: ToolbarProps;
  paginationProps?: SimplePaginationProps;
  withToolbar?: boolean;
  withPagination?: boolean;
  tableStateProps?: any;
  emptyWithoutTable?: boolean;
};

export function Table({
  tableProps = {},
  toolbarProps = {},
  paginationProps = {},
  withToolbar,
  withPagination,
  tableStateProps = {},
  emptyWithoutTable
}: NewTableProps) {
  const { isUsingDefaultFilters } = useContext(TableContext);

  const {
    isLoading = false,
    pagination: { totalItems = 0 } = {},
    items = []
  } = tableStateProps;

  const paginationTotalItems = totalItems || 0;
  const itemsLength = items.length;

  // Get the Empty and Loading components from the tableProps - or use the defaults
  const { Empty = TableEmptyState, Loading = TableLoadingState } = tableProps;

  // If we show the empty State without the table, we need to check if the table is loading
  // as it makes sense to show the loading state outside of the table
  const showLoadingWithoutTable = Boolean(
    // If we have items and we're jumping between pages, we don't want replace the table with the loading state
    Loading != undefined &&
      !paginationTotalItems &&
      isLoading &&
      emptyWithoutTable &&
      !withToolbar
  );

  // If we show the empty State without the table, we need to check if the table is loading
  const showEmptyWithoutTable = Boolean(
    Empty != undefined &&
      isUsingDefaultFilters &&
      !isLoading &&
      !itemsLength &&
      !paginationTotalItems &&
      emptyWithoutTable
  );

  // While we have the empty state from above, we don't want to show the custom empty state if the user
  // has applied filters. This is because the custom empty state is designed to show when there are no items,
  // and the default empty state lets the user reset the filters.
  const EmptyState = useMemo(() => {
    return !isUsingDefaultFilters || paginationTotalItems
      ? TableEmptyState
      : Empty;
  }, [Empty, paginationTotalItems, isUsingDefaultFilters]);

  return (
    <ReplaceTableStatesComponent
      showLoadingWithoutTable={showLoadingWithoutTable}
      showEmptyWithoutTable={showEmptyWithoutTable}
      Loading={Loading}
      Empty={Empty}
    >
      {withToolbar && <Toolbar {...toolbarProps} />}

      <VividTable {...tableProps} Empty={EmptyState} />

      {withPagination && <SimplePagination {...paginationProps} />}
    </ReplaceTableStatesComponent>
  );
}
