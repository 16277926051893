import { TodoTask } from 'src/modules/tasks/todos/types/TodoTask';
import { Task } from 'src/modules/tasks/common/types/Task';
import { Columns } from 'view/components/table';
import { TaskTypeIconCell } from 'src/modules/tasks/common/components/task-type-icon-cell';
import { AnyTaskProgressCell } from 'src/modules/tasks/common/components/any-task-progress-cell';
import { AnyTaskSummaryCell } from 'src/modules/tasks/common/components/any-task-summary-cell';
import { getLabelForManagedBy } from '../utils/get-label-for-managed-by';

const managedBy = {
  id: 'managed_by',
  Header: 'Managed by',
  type: 'text',
  accessor: (item: Task<any>) => getLabelForManagedBy(item.managed_by)
} as const;

const status = {
  id: 'status',
  Header: 'Task progress',
  Cell: AnyTaskProgressCell,
  accessor: (item: TodoTask) => item,
  width: 200,
  toCsv: (item: Task<any>) =>
    item?.details?.status?.label || item?.status?.label
};

const followUpDate = {
  id: 'follow_up_date',
  Header: 'Follow up date',
  accessor: (item: Task<any>) => item.follow_up_date,
  type: 'date',
  width: 150
} as const;

const closedDate = {
  id: 'closed_date',
  Header: 'Closed date',
  accessor: (item: Task<any>) => item.closed_at,
  type: 'date',
  width: 150
} as const;

const summary = {
  id: 'summary',
  Header: 'Task',
  Cell: AnyTaskSummaryCell,
  accessor: (item) => item,
  width: 200,
  toCsv: (item: Task<any>) => item.summary
};

const type = {
  id: 'type',
  Header: 'Type',
  accessor: (item: Task<any>) => item?.type,
  Cell: TaskTypeIconCell,
  width: 70,
  toCsv: (item: Task<any>) => item?.type?.label
};

const property: Columns[0] = {
  id: 'property',
  Header: 'Property',
  type: 'property',
  accessor: (item: Task<any>) => item?.property,
  width: 120
};

export function useTaskColumns(tab: string): Columns {
  if (tab === 'closed') {
    return [type, summary, closedDate, managedBy];
  }

  return [type, summary, followUpDate, status, managedBy];
}

export function useTaskColumnsForSupplier(): Columns {
  return [type, summary, property, status];
}
