import React from 'react';
import { startCase } from 'lodash';
import { Trans } from 'react-i18next';

import { formatCurrency } from 'utils/formatters';

import {
  FinancialSummary,
  PendingDisbursement
} from 'data/models/entities/financials/pending-disbursements';

import { Column, Columns } from 'view/components/table';
import { TwoTierHeader } from 'view/components/column-headers/two-tier-column-header';

import { DisbursementOwnershipCell } from '../components/ownership-cell';
import { PendingDisbursementStatusCell } from '../components/status-cell';
import { DisbursementContactCell } from '../components/contact-cell';

type PendingDisbursementWithFinancialSummary = PendingDisbursement & {
  financial_summary: FinancialSummary;
};

export function getPendingDisbursementColumns(
  typeOfDisbursement: 'contact' | 'ownership'
): Columns<PendingDisbursementWithFinancialSummary> {
  return [
    {
      id: 'status',
      Header: 'Status',
      Cell: PendingDisbursementStatusCell,
      width: 120,
      toCsv: (item) => item?.label
    },
    {
      id: typeOfDisbursement,
      Header:
        typeOfDisbursement === 'ownership'
          ? startCase(typeOfDisbursement)
          : 'Creditor',
      accessor: (item) => item,
      Cell:
        typeOfDisbursement === 'contact'
          ? DisbursementContactCell
          : DisbursementOwnershipCell,
      toCsv: (item) => item?.object.display_name
    },
    {
      id: 'due_date',
      type: 'date',
      Header: 'Due date',
      width: 120
    },
    {
      id: 'last_disbursement.created_at',
      type: 'date',
      Header: () => <Trans i18nKey='disbursements.last-disbursement.label' />,
      width: 165,
      headerString: 'Last disbursement',
      toCsv: (item) => item?.last_disbursement?.created_at || ''
    },
    {
      id: 'bill_payments',
      headerString: 'Bill payments',
      Header: () => (
        <TwoTierHeader
          align='right'
          header='Bill payments'
          subheader='Outstanding'
        />
      ),
      type: 'two_tier',
      accessor: (item) => {
        const billsPaidInDisbursement =
          item.disburse_summary.recommended_bill_payment_allocation_amount;
        const totalBillsPayableNow =
          item.financial_summary.bills_payable_total_amount_owing -
          item.financial_summary.bills_not_yet_payable_total_amount_owing;

        return {
          text: formatCurrency(billsPaidInDisbursement),
          subtext: formatCurrency(
            totalBillsPayableNow - billsPaidInDisbursement
          )
        };
      },
      cellProps: {
        align: 'right'
      },
      width: 180
    },
    ...(typeOfDisbursement === 'contact'
      ? [
          {
            id: 'pending_commissions',
            headerString: 'Pending commissions',
            Header: () => (
              <TwoTierHeader
                align='right'
                header='Pending commissions'
                subheader='Outstanding'
              />
            ),
            type: 'two_tier',
            accessor: (item) => {
              return {
                text: formatCurrency(
                  item.disburse_summary
                    .recommended_deferred_fees_allocation_amount
                ),
                subtext: formatCurrency(
                  item.financial_summary.deferred_fees_total_amount_owing -
                    item.disburse_summary
                      .recommended_deferred_fees_allocation_amount
                )
              };
            },
            cellProps: {
              align: 'right'
            },
            width: 190
          } as Column<PendingDisbursementWithFinancialSummary>
        ]
      : []),
    ...(typeOfDisbursement === 'ownership'
      ? [
          {
            id: 'fees_payments',
            Header: () => (
              <TwoTierHeader
                align='right'
                header='Fees payments'
                subheader='Outstanding'
              />
            ),
            headerString: 'Fees payments',
            type: 'two_tier',
            accessor: (item) => {
              const feesPaidInDisbursement =
                item.disburse_summary.recommended_fee_payment_allocation_amount;
              const totalFeesPayableNow =
                item.financial_summary.fees_payable_total_amount_owing -
                item.financial_summary.fees_not_yet_payable_total_amount_owing;

              return {
                text: formatCurrency(feesPaidInDisbursement),
                subtext: formatCurrency(
                  totalFeesPayableNow - feesPaidInDisbursement
                )
              };
            },
            cellProps: {
              align: 'right'
            },
            width: 180
          } as Column<PendingDisbursementWithFinancialSummary>,
          {
            id: 'pending_agency_fees',
            Header: () => (
              <TwoTierHeader
                align='right'
                header='Pending agency fees'
                subheader='Outstanding'
              />
            ),
            headerString: 'Pending agency fees',
            type: 'two_tier',
            accessor: (item) => {
              return {
                text: formatCurrency(
                  item.disburse_summary
                    .recommended_deferred_fees_allocation_amount
                ),
                subtext: formatCurrency(
                  item.financial_summary.deferred_fees_total_amount_owing -
                    item.disburse_summary
                      .recommended_deferred_fees_allocation_amount
                )
              };
            },
            cellProps: {
              align: 'right'
            },
            width: 180
          } as Column<PendingDisbursementWithFinancialSummary>
        ]
      : []),
    {
      id: 'specific_payments',
      headerString: 'Specific payments',
      Header: () => (
        <TwoTierHeader
          align='right'
          header='Specific payments'
          subheader='Outstanding'
        />
      ),
      type: 'two_tier',
      accessor: (item) => {
        return {
          text: formatCurrency(
            item.disburse_summary.allocated_for_specific_payout_ready_amount
          ),
          subtext: formatCurrency(
            item.disburse_summary.allocated_for_specific_payout_pending_amount
          )
        };
      },
      cellProps: {
        align: 'right'
      },
      width: 180
    },
    {
      id: 'disburse_summary.recommended_payout_allocation_amount',
      type: 'currency',
      Header: `Payout to ${typeOfDisbursement}`,
      cellProps: {
        align: 'right'
      },
      width: 180
    }
  ];
}
