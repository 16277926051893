import { GlobalFilter } from '@rexlabs/table/lib/types/context';

export function getStaticReportForcedGlobalFilters({
  reportId,
  initialGlobalFilter
}: {
  reportId: string;
  initialGlobalFilter: { [key: string]: [{ value: any }] };
}): GlobalFilter[] {
  switch (reportId) {
    case 'cash-ledger':
    case 'payables-ledger':
    case 'receivables-ledger':
      return [
        {
          field: 'object_id',
          op: 'eq',
          value: initialGlobalFilter?.object_id?.[0]?.value
        },
        {
          field: 'object_type',
          op: 'eq',
          value: initialGlobalFilter?.object_type?.[0]?.value
        }
      ];
    default:
      return [];
  }
}
