import { CustomAction, touchEtag } from '@rexlabs/model-generator';
import { Generator, GeneratorConfig } from 'data/models/generator';
import { api } from 'utils/api/api-client';
import {
  ReverseTrustJournalEntryPayload,
  TrustJournalEntry
} from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';
import { trustJournalEntryLineItemModel } from 'src/modules/trust-journal-entry-line-items/models/trust-journal-entry-line-item-model';
import { contactsModel } from 'src/modules/contacts/models/contacts';
import { auditableConfig } from 'src/modules/common/auditable/auditable-config';
import { usersModel } from 'src/modules/users/models/users';
import _ from 'lodash';

const config: GeneratorConfig = {
  entities: {
    related: {
      payment_information: {
        include: 'payment_information'
      },
      bank_account: {
        include: 'bank_account.id,bank_account.name'
      },
      contact: {
        include: 'contact',
        model: contactsModel
      },
      contacts: {
        include: 'contacts'
      },
      line_items: {
        include: 'line_items.trust_ledger'
      },
      reversed_by: {
        include: 'reversed_by,reversed_by.created_by' // we cannot do this with a proper model as it is recursive. This model doesn't exist yet
      },
      reverses: {
        include: 'reverses'
      },
      transfer_source: {
        include: 'transfer_source',
        model: trustJournalEntryLineItemModel
      },
      withdrawal_source: {
        include: 'withdrawal_source',
        model: trustJournalEntryLineItemModel
      },
      ledger_line_items: {
        include: 'ledger_line_items',
        model: [trustJournalEntryLineItemModel]
      },
      voided_by: {
        include: 'voided_by',
        model: usersModel
      },
      reconciliation_status: {
        include: 'reconciliation_status'
      },
      refunded_by: {
        include: 'refunded_by,refunded_by.created_by'
      },
      ...auditableConfig
    }
  }
};

const actionCreators = {
  reverse: {
    request: (
      payload: ReverseTrustJournalEntryPayload,
      _actions,
      _dispatch,
      _getState
    ) =>
      api.post(`/financials/trust-journal-entries/${payload.id}/reverse`, {
        reason: payload.reason,
        date_of: payload.date_of
      }),
    reduce: {
      success: (state, action) => {
        const originalId = action.meta.originalPayload.id;
        return {
          ...state,
          items: {
            ...state.items,
            [originalId]: touchEtag({
              ...state.items[originalId],
              data: {
                ...state.items[originalId].data,
                reversed_by: action.payload.data
              }
            })
          }
        };
      }
    }
  } as CustomAction<ReverseTrustJournalEntryPayload, any>,
  downloadItem: {
    request: (trustJournalEntryId: string) =>
      api.post(`/reporting/trust-receipt`, {
        id: trustJournalEntryId,
        format: 'pdf',
        disposition: 'download_link'
      }),
    reduce: _.identity
  }
};

export const trustJournalEntryModel = new Generator<
  TrustJournalEntry,
  typeof actionCreators
>('financials/trust-journal-entries', config).createEntityModel({
  actionCreators
});
