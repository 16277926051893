import { Generator } from 'data/models/generator';
import { User } from 'src/modules/users/types/user-types';
import { PortfolioRole } from 'src/modules/portfolio-roles/types/PortfolioRole';
import { TimestampAttributes } from '../../../data/models/types';

export type PortfolioMember = {
  id: string;
  user?: User;
  portfolio_role?: PortfolioRole;
  is_primary: boolean;
} & Pick<TimestampAttributes, 'created_at' | 'updated_at'>;

const config = {
  entities: {
    related: {
      user: {
        include: 'user'
      }
    }
  }
};

export const portfolioMembersModel = new Generator<PortfolioMember>(
  'portfolio-members',
  config
).createEntityModel();
