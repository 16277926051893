import * as React from 'react';

import { ListTable, TabbedTable } from 'view/components/table';
import { Card } from 'view/components/card';
import { ListScreen } from 'view/components/list-screen/list-screen';
import { Tab } from 'view/components/table/tabbed';
import { query } from '@rexlabs/model-generator';

import { getArrearsColumns } from 'src/modules/tasks/arrears/utils/arrears-columns';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { useSettingsQuery } from 'src/modules/settings/hooks/useSettingsQuery';
import { useAppWideFilterContext } from 'src/modules/app-wide-filters/contexts/app-wide-filter-context';
import { ARREARS_ROUTES } from '../routes';

import { useGetArrearsActions } from '../hooks/action-menu-items/use-get-arrears-actions';
import { getArrearsTabbedTableTabs } from '../utils/get-arrears-tabbed-table-tabs';
import { tasksModel } from '../../common/models/tasks-model';
import { initialHiddenColumns } from '../../common/utils/initial-hidden-columns';

const getArrearsQuery = () => query`{
  ${tasksModel} {
    arrearsDetails,
    property,
    managed_by
    created_by
  }
}`;

export function ArrearsList() {
  const { appWideFilterKey } = useAppWideFilterContext();

  const { getSort, getFilters } = useTableFilters('tasks', {
    type_id: 'task_arrears'
  });
  const { data: settingsData } = useSettingsQuery();

  const getArrearsActions = useGetArrearsActions();

  const getRowLinkProps = ({ item }) => {
    return {
      to: ARREARS_ROUTES.ARREARS_DETAILS,
      params: { arrearsId: item.id }
    };
  };

  const getActionMenuItems = React.useCallback(
    ({ item }) => getArrearsActions(item),
    [getArrearsActions]
  );

  const commonTabProps = React.useMemo(
    () => ({
      id: 'arrears',
      columns: getArrearsColumns({
        resolved: false,
        paidToDateSetting: settingsData?.['rent-paid-to-date']?.id
      }),
      getQuery: getArrearsQuery,
      Table: ListTable,
      getRowLinkProps,
      getActionMenuItems,
      forcedGlobalFilter: [
        {
          field: 'type_id',
          op: 'eq',
          value: { value: 'task_arrears', label: 'Rent Arrears' }
        }
      ],
      getFilters,
      getSort,
      shouldUseAppWideFilter: true,
      initialHiddenColumns: initialHiddenColumns
    }),
    [getFilters]
  );

  const tabs = React.useMemo<Tab[]>(
    () => getArrearsTabbedTableTabs(commonTabProps),
    [commonTabProps]
  );

  return (
    <ListScreen
      privilege={'tasks.arrears-jobs.read'}
      title='Rent arrears'
      key={appWideFilterKey}
    >
      <Card>
        <TabbedTable tabs={tabs} />
      </Card>
    </ListScreen>
  );
}
