import React from 'react';
import { Columns, RecordTable } from 'view/components/table';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { query } from '@rexlabs/model-generator';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { FolioCommissionTemplate } from '../types/FolioCommissionTemplate';
import { useGetSupplierCommissionTemplateActions } from '../hooks/action-menu-items/use-get-supplier-commission-template-actions';
import { useGetTemplateListLinkProps } from '../hooks/use-get-template-list-link-props';
import { commissionTemplatesModel } from '../models/commission-templates-model';
import { SupplierCommissionTemplatesEmptyState } from './supplier-commission-templates-empty-state';

const columns: Columns<FolioCommissionTemplate> = [
  {
    id: 'name',
    Header: 'Commission template name'
  },
  {
    id: 'structure_type',
    Header: 'Structure',
    type: 'value'
  }
];

const supplierCommissionTemplatesQuery = query`{
  ${commissionTemplatesModel} {
    id
  }
}`;

export function SupplierCommissionTemplatesTable() {
  const getSupplierCommissionTemplateActions = useGetSupplierCommissionTemplateActions();
  const getTemplateListLinkProps = useGetTemplateListLinkProps();

  const { getFilters, getSort } = useTableFilters('commission-templates');

  return (
    <RecordTable
      id='supplier-commission-templates-table'
      emptyWithoutTable={true}
      columns={columns}
      getFilters={getFilters}
      getSort={getSort}
      getQuery={() => supplierCommissionTemplatesQuery}
      Empty={SupplierCommissionTemplatesEmptyState}
      getRowLinkProps={getTemplateListLinkProps}
      getActionMenuItems={({ item }) =>
        transformActionDeclarationsToActionMenuItems(
          getSupplierCommissionTemplateActions(item)
        )
      }
      withToolbar={true}
      withPagination={true}
    />
  );
}
