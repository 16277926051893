import React, { useCallback } from 'react';
import {
  FreeformDialogProps,
  RecordOption,
  Select,
  SelectProps,
  useFreeformDialog,
  UseFreeformDialogArgs
} from '@rexlabs/select';

import { PropertyTenancy } from 'src/modules/property-tenancies/types/property-tenancy-types';
import { CreatePropertyTenancyDialog } from 'src/modules/property-tenancies/dialogs/create-property-tenancy-dialog';
import { normalisePropertyTenancyForProperty } from 'utils/normaliser/v4-select-normalisers/property-tenancy';
import { useItems } from 'view/hooks/use-items';

export type PropertyTenancySelectProps = SelectProps<PropertyTenancy> & {
  dialogProps?: UseFreeformDialogArgs<PropertyTenancy>['props'];
  getItems: () => PropertyTenancy[];
};

function FreeformOwnershipDialog({
  selectItem,
  onCreate: propOnCreate,
  ...props
}: any & FreeformDialogProps<Partial<PropertyTenancy>>) {
  const onCreate = useCallback(
    (data: PropertyTenancy) => {
      propOnCreate?.(data);
      selectItem(data);
    },
    [propOnCreate, selectItem]
  );

  return <CreatePropertyTenancyDialog {...props} onCreate={onCreate} />;
}

export const PropertyTenancySelect: React.FC<PropertyTenancySelectProps> = ({
  dialogProps,
  getItems,
  normaliser = normalisePropertyTenancyForProperty,
  ...props
}) => {
  const { getSelectProps: useItemsGetSelectProps } = useItems<PropertyTenancy>({
    getItems,
    getSuggestedItems: getItems
  });
  const {
    getSelectProps: useFreeformDialogGetSelectProps
  } = useFreeformDialog<PropertyTenancy>({
    props: dialogProps,
    Dialog: FreeformOwnershipDialog
  });

  const selectPropsWithFixture = useItemsGetSelectProps(
    useFreeformDialogGetSelectProps()
  );

  return (
    <Select
      id='property-tenancy-select'
      {...props}
      {...selectPropsWithFixture}
      normaliser={normaliser}
      Option={RecordOption}
    />
  );
};
