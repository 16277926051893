import { Generator, GeneratorConfig } from 'data/models/generator';
import {
  BankWithdrawal,
  BankWithdrawalStatus
} from 'src/modules/banking/bank-withdrawals/types/bank-withdrawal-types';
import { StatusTagMap } from 'src/modules/common/components/status-tag';
import { bankWithdrawalLineItemModel } from 'src/modules/banking/bank-withdrawals/models/bank-withdrawal-line-item-model';
import { auditableConfig } from 'src/modules/common/auditable/auditable-config';

export const config: GeneratorConfig = {
  entities: {
    related: {
      bank_account: {
        include: 'bank_account'
      },
      totals: {
        include: 'totals'
      },
      line_items: {
        include: 'line_items',
        model: [bankWithdrawalLineItemModel]
      },
      reconciliation_status: {
        include: 'reconciliation_status'
      },
      supported_formats: {
        include: 'supported_formats'
      },
      ...auditableConfig
    }
  }
};

export const bankWithdrawalStatusMap: StatusTagMap<BankWithdrawalStatus> = {
  void: 'bad',
  pending_withdrawal: 'average',
  withdrawn: 'good'
};

export type VoidBankWithdrawalPayload = {
  id: string;
  reason: string;
};

export const bankWithdrawalModel = new Generator<BankWithdrawal>(
  'financials/bank-withdrawals',
  config
).createEntityModel();
