import { Columns } from 'view/components/table';
import React from 'react';
import {
  StatusTag,
  StatusTagMap
} from 'src/modules/common/components/status-tag';
import { TagCell } from '@rexlabs/table';
import { TwoTierCell } from 'view/components/cells/two-tier-cell';
import { formatCurrency } from 'utils/formatters';
import { formatDate } from 'utils/dates/format';
import { Bold } from '@rexlabs/text';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { columnTypes } from 'view/components/table/columns/column-types';
import {
  BankStatementTransactionImport,
  BankStatementTransactionImportStatus
} from '../types/bank-statement-transaction-import';
import { BATCH_RECEIPTING_FLAGS } from '../feature-flags';

export const bankStatementTransactionImportStatusMap: StatusTagMap<BankStatementTransactionImportStatus> = {
  in_progress: 'average',
  completed: 'good'
};

const customWidth = 160;

function FileCell({ value }: { value: BankStatementTransactionImport }) {
  return (
    <TwoTierCell text={value.name} subtext={formatDate(value.uploaded_date)} />
  );
}

function StatsCell({ count, amount }: { count: number; amount: number }) {
  return <TwoTierCell text={count} subtext={formatCurrency(amount)} />;
}

function getStatColumnConfig({
  header,
  countKey,
  amountKey
}: {
  header: string;
  countKey: keyof Pick<
    BankStatementTransactionImport,
    | 'not_receipted_count'
    | 'total_count'
    | 'receipted_count'
    | 'suspended_count'
    | 'removed_count'
  >;
  amountKey: keyof Pick<
    BankStatementTransactionImport,
    | 'not_receipted_amount'
    | 'total_amount'
    | 'receipted_amount'
    | 'removed_amount'
    | 'suspended_amount'
  >;
}) {
  return {
    Header: header,
    accessor: (item) => item,
    Cell: ({ value }: { value: BankStatementTransactionImport }) => (
      <StatsCell count={value[countKey]} amount={value[amountKey]} />
    ),
    cellProps: {
      align: 'right'
    },
    width: customWidth,
    toCsv: (item) => `${item[countKey]} - ${formatCurrency(item[amountKey])}`
  };
}

export const useUploadedBankStatementTransactionsColumns = (): Columns<BankStatementTransactionImport> => {
  const { hasFeature } = useFeatureFlags();

  const hasPaymentGatewaysFeature = hasFeature(
    BATCH_RECEIPTING_FLAGS.PAYMENT_GATEWAYS
  );

  const twoTierType: keyof typeof columnTypes = 'two_tier';

  return [
    {
      accessor: (item) => item,
      id: 'name',
      Header: () => (
        <TwoTierCell text={<Bold>File name</Bold>} subtext={'Date uploaded'} />
      ),
      Cell: FileCell,
      headerString: 'File name - Date uploaded',
      toCsv: (item) => `${item.name} - ${formatDate(item.uploaded_date)}`
    },
    ...(hasPaymentGatewaysFeature
      ? [
          {
            id: 'type',
            Header: 'Type',
            accessor: (item: BankStatementTransactionImport) => {
              return {
                text: item.type?.label,
                subtext: item?.payment_gateway?.label ?? ''
              };
            },
            type: twoTierType,
            width: 160
          }
        ]
      : []),
    {
      type: 'date_range',
      Header: 'Transaction date range',
      accessor: (item) => {
        return {
          from: item.transactions_from_date,
          to: item.transactions_to_date
        };
      }
    },
    getStatColumnConfig({
      header: 'Total transactions',
      countKey: 'total_count',
      amountKey: 'total_amount'
    }),
    getStatColumnConfig({
      header: 'Not receipted',
      countKey: 'not_receipted_count',
      amountKey: 'not_receipted_amount'
    }),
    getStatColumnConfig({
      header: 'Receipted',
      countKey: 'receipted_count',
      amountKey: 'receipted_amount'
    }),
    getStatColumnConfig({
      header: 'Suspended',
      countKey: 'suspended_count',
      amountKey: 'suspended_amount'
    }),
    getStatColumnConfig({
      header: 'Removed',
      countKey: 'removed_count',
      amountKey: 'removed_amount'
    }),
    {
      id: 'status',
      Header: 'Status',
      width: 130,
      Cell: ({ value }) => (
        <TagCell>
          <StatusTag
            status={value}
            map={bankStatementTransactionImportStatusMap}
          />
        </TagCell>
      ),
      toCsv: (item) => item?.label
    }
  ];
};
