import React from 'react';

import { StyleSheet, useStyles } from '@rexlabs/styling';
import { Small } from '@rexlabs/text';
import { TagCell } from '@rexlabs/table';

import { TrustJournalEntry } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';
import { auditableColumns } from 'src/modules/common/auditable/auditable-columns';
import { StatusTag } from 'src/modules/common/components/status-tag';
import { trustJournalEntryStatusMap } from 'src/modules/trust-journal-entries/maps/trust-journal-entry-status-map';
import { Contact } from 'src/modules/contacts/types/contact-types';

import { Column, Columns } from 'view/components/table';
import { TwoTierCell } from 'view/components/cells/two-tier-cell';
import { LargeDescriptionCell } from 'view/components/cells/large-description-cell';
import { t } from 'i18next';
import { TwoTierHeader } from 'view/components/column-headers/two-tier-column-header';

const styles = StyleSheet({
  subText: {
    color: ({ token }) => token('color.textStyle.body.subtext')
  }
});

const columnIds = [
  'contact',
  'status_id',
  'description',
  'account_number',
  'date_of',
  'type_id',
  'amount',
  'payment_method_id'
] as const;
type ColumnId = typeof columnIds[number];

const trustJournalEntryColumnMap: Record<
  ColumnId,
  Column<TrustJournalEntry>
> = {
  contact: {
    id: 'contact',
    accessor: (item) => item,
    width: 280,
    Header: () => {
      const s = useStyles(styles);

      return (
        <div>
          <div>Received from / Paid to / Transferred from</div>
          <div {...s('subText')}>
            <Small semibold as='div'>
              Reference
            </Small>
          </div>
        </div>
      );
    },
    toCsv: (item) => {
      const recordText = getRecordText(item);

      return `${recordText} ${item.record_reference}`;
    },
    Cell: ({ value }) => {
      const recordText = getRecordText(value);

      return (
        <TwoTierCell text={recordText} subtext={value?.record_reference} />
      );
    }
  },
  status_id: {
    id: 'status_id',
    Header: 'Status',
    toCsv: (item) => {
      return item.label;
    },
    accessor: (item) => item.status,
    Cell: (props) => {
      return (
        <TagCell>
          <StatusTag status={props.value} map={trustJournalEntryStatusMap} />
        </TagCell>
      );
    },
    width: 140
  },
  description: {
    id: 'description',
    Header: 'Description',
    accessor: (item) => item.description,
    Cell: ({ value }) => {
      return <LargeDescriptionCell text={value} lineClamp={2} />;
    },
    width: 350
  },
  account_number: {
    id: 'account_number',
    Header: () => (
      <TwoTierHeader
        header='Account number'
        subheader={t('bank-accounts.branch-code.label.singular')}
      />
    ),
    accessor: (item) => item.payment_information,
    toCsv: (item) => {
      if (!(item.account_number || item.branch_code)) {
        return '-';
      }

      return `${item.account_number} - ${item.branch_code}`;
    },
    Cell: ({ value }: { value: TrustJournalEntry['payment_information'] }) => {
      if (!(value?.account_number || value?.branch_code)) {
        return <>-</>;
      }

      return (
        <TwoTierCell text={value.account_number} subtext={value.branch_code} />
      );
    }
  },
  date_of: {
    id: 'date_of',
    type: 'date',
    Header: 'Date',
    width: 120,
    accessor: (item) => item.date_of
  },
  type_id: {
    id: 'type_id',
    type: 'value',
    Header: 'Type',
    width: 120,
    accessor: (item) => item.type
  },
  amount: {
    id: 'amount',
    type: 'currency',
    cellProps: {
      align: 'right'
    },
    width: 120,
    Header: 'Amount',
    accessor: (item) => item.amount
  },
  payment_method_id: {
    id: 'payment_method_id',
    type: 'value',
    Header: 'Method',
    accessor: (item) => item.payment_information?.method,
    width: 120
  }
};

function getRecordText(item: TrustJournalEntry) {
  let result = item.contact?.display_name;

  if (item.contacts?.data?.length) {
    result = item.contacts.data
      .map((contact: Contact) => contact.display_name)
      .join(', ');
  }

  if (item.transfer_source?.trust_ledger) {
    result = item.transfer_source.trust_ledger.name;
  }

  return result;
}
/**
 * This function returns the columns for the trust journal entry table. /By default it returns a default set of columns, but you can pass
 * in an optional array of column ids to get specific columns.
 */
export function getTrustJournalEntryColumns(
  columns: ColumnId[] = columnIds.filter(
    (columnId) => columnId !== 'account_number'
  )
): Columns<TrustJournalEntry> {
  return [
    ...columns.map((columnId) => trustJournalEntryColumnMap[columnId]),
    ...auditableColumns
  ];
}
