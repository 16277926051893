import React, { useEffect } from 'react';
import { BlockConfig } from 'view/components/record-screen/types';

import { Message } from '@rexlabs/notifications';
import { EmptyCard } from 'view/components/record-screen/cards/empty-card';
import EmptyTable from 'src/assets/illustrations/empty-table.svg';
import { TrustJournalEntry } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';
import { SelectedMatchesTable } from '../components/selected-matches-table';

export const selectedMatchesBlock: BlockConfig<TrustJournalEntry[]> = {
  id: 'selected-matches',
  title: 'Selected Matches',
  Edit: ({ blockProps, setFieldValue }) => {
    const { selectedMatches, bankStatementTransactionId } = blockProps;

    // Set the selected receipts into the form values
    // so we can access them on form submit
    useEffect(
      () => setFieldValue?.('selected_receipts', selectedMatches || []),
      [selectedMatches, setFieldValue]
    );

    // Set the bank statement transaction id into the form values
    // so we can access it on form submit
    useEffect(
      () =>
        setFieldValue?.(
          'bank_statement_transaction_id',
          bankStatementTransactionId
        ),
      [bankStatementTransactionId, setFieldValue]
    );

    return !selectedMatches?.length ? (
      <EmptyCard hasGreyBackground={true}>
        <Message title='No matches selected' Illustration={EmptyTable}>
          Select a match in the table above or create a new receipt below
        </Message>
      </EmptyCard>
    ) : (
      <SelectedMatchesTable data={selectedMatches} blockProps={blockProps} />
    );
  }
};
