import React from 'react';
import {
  SecurityDepositAmount,
  SecurityDepositTypeHeldBy
} from 'data/models/entities/financials/security-deposit-types';
import { CurrencySummary } from 'view/components/currency-summary';
import { SummaryRow } from 'view/components/summary';
import { ErrorBanner } from '@rexlabs/notifications';
import { WarningCircleIcon } from 'view/components/icons/warning-circle';
import LoadingState from 'view/components/states/compact/loading';
import { useSettings } from 'src/modules/settings/hooks/useSettings';
import { useGetCalculatedRent } from '../utils/use-get-calculated-rent';

interface SecurityDepositSummaryProps {
  recordId: string;
  securityDepositTypeHeldBy: SecurityDepositTypeHeldBy;
  amount: Partial<SecurityDepositAmount>;
  amountPaid: number | undefined;
}

export function SecurityDepositSummary({
  amount: SecurityDepositAmount,
  amountPaid: amountToBondAuthority,
  securityDepositTypeHeldBy,
  recordId
}: SecurityDepositSummaryProps) {
  const settings = useSettings();

  const {
    type,
    amount_expected,
    amount_expected_as_weeks_of_rent
  } = SecurityDepositAmount;

  const {
    calculatedRent,
    isLoading: isLoadingCalculatedRent,
    error
  } = useGetCalculatedRent(
    recordId,
    type?.id,
    amount_expected_as_weeks_of_rent,
    settings['security-deposit-rounding-mode-setting'].id
  );

  const totalAmount =
    type?.id === 'amount' ? amount_expected : calculatedRent?.amount;
  const amountOwed =
    type?.id === 'amount' ? amount_expected : calculatedRent?.amount;
  const isLoading = type?.id === 'amount' ? false : isLoadingCalculatedRent;

  const totalRow: SummaryRow<number> = {
    label: 'Amount required',
    value: totalAmount || 0,
    isLoading: isLoading
  };

  const receiptedAmountRow: SummaryRow<number> = {
    label: 'Amount paid direct',
    value: amountToBondAuthority || 0
  };

  const owedRow: SummaryRow<number> = {
    bold: true,
    large: true,
    label: 'Amount due',
    value: (amountOwed || 0) - (amountToBondAuthority || 0),
    isLoading: isLoading
  };

  let summaryRows;

  if (securityDepositTypeHeldBy === 'held_by_agency') {
    summaryRows = [totalRow, owedRow];
  } else {
    summaryRows = [totalRow, receiptedAmountRow, owedRow];
  }

  if (isLoading) {
    return <LoadingState>Calculating deposit amount required...</LoadingState>;
  }

  if (error) {
    return (
      <ErrorBanner
        Icon={WarningCircleIcon}
        title='Unable to calculate deposit amount required'
        description='Please add the rent schedule amount first, or choose a fixed amount for this security deposit. You can continue to create this deposit.'
      />
    );
  }

  return <CurrencySummary rows={summaryRows} />;
}
