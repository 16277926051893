import React from 'react';

import { TagCell } from '@rexlabs/table';

import { formatDate } from 'utils/dates/format';

import { StatusTag } from 'src/modules/common/components/status-tag';
import { DeferredFeeLineItem } from 'src/modules/deferred-fees/types/DeferredFeeLineItem';
import { deferredFeeStatusMap } from 'src/modules/deferred-fees/utils/deferred-fee-status-map';

import { RecordLink } from 'view/components/record-link/record-link';
import { TwoTierCell } from 'view/components/cells/two-tier-cell';

import { DeferredFeeLineItemColumn } from '../types/DeferredFeeLineItemColumn';

const columnIds = [
  'creation',
  'billed_on',
  'status',
  'summary_description',
  'payable_by_property',
  'amount',
  'ref_invoice',
  'commission'
] as const;

type ColumnId = typeof columnIds[number];

const creationColumn: DeferredFeeLineItemColumn = {
  id: 'creation',
  Header: 'Creation',
  accessor: (item) => item.date_of,
  type: 'date',
  width: 150
};

const billedOnColumn: DeferredFeeLineItemColumn = {
  id: 'billed_on',
  Header: 'Billed on',
  accessor: (item) => item.payment_invoice,
  width: 150,
  Cell: ({ value }: { value: DeferredFeeLineItem['payment_invoice'] }) => {
    if (!value?.invoice_date) {
      return <>-</>;
    }

    return (
      <TwoTierCell
        text={formatDate(value.invoice_date)}
        subtext={
          <RecordLink type='invoice' id={value.id}>
            {value.record_reference}
          </RecordLink>
        }
      />
    );
  }
};

const statusColumn: DeferredFeeLineItemColumn = {
  id: 'status',
  Header: 'Status',
  accessor: (item) => item.status,
  width: 150,
  Cell: (table) => {
    const {
      cell: { value }
    } = table;
    return (
      <TagCell>
        <StatusTag status={value} map={deferredFeeStatusMap} />
      </TagCell>
    );
  }
};

const summaryDescription: DeferredFeeLineItemColumn = {
  id: 'summary_description',
  Header: 'Fee',
  width: 200
};

const payableByPropertyColumn: DeferredFeeLineItemColumn = {
  id: 'payable_by_property',
  Header: 'Property',
  type: 'property',
  width: 200
};

const amountColumn: DeferredFeeLineItemColumn = {
  id: 'amount',
  Header: 'Amount',
  type: 'currency',
  cellProps: {
    align: 'right'
  },
  width: 150
};

const refInvoiceColumn: DeferredFeeLineItemColumn = {
  id: 'ref_invoice',
  Header: 'Ref invoice',
  type: 'invoice',
  accessor: (item) => item.source_object,
  width: 150
};

const commissionColumn: DeferredFeeLineItemColumn = {
  id: 'commission',
  Header: 'Commission',
  accessor: (item) => item.amount,
  type: 'currency',
  cellProps: {
    align: 'right'
  },
  width: 150
};

const deferredFeeColumnMap: Record<ColumnId, DeferredFeeLineItemColumn> = {
  creation: creationColumn,
  billed_on: billedOnColumn,
  status: statusColumn,
  summary_description: summaryDescription,
  payable_by_property: payableByPropertyColumn,
  amount: amountColumn,
  ref_invoice: refInvoiceColumn,
  commission: commissionColumn
};

export function getDeferredFeesColumns(
  columnIds: ColumnId[] = []
): DeferredFeeLineItemColumn[] {
  return columnIds.map((columnId) => deferredFeeColumnMap[columnId]);
}
