import React, {
  MutableRefObject,
  useCallback,
  useContext,
  useMemo
} from 'react';

import 'ag-grid-enterprise';

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-material.css'; // Optional theme CSS
import '../../styles/rex-pm-theme.css';

import { BlockConfig } from 'view/components/record-screen/types';
import Box from '@rexlabs/box';
import { Grid } from 'view/components/@luna/form/grid';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { Toolbar } from 'view/components/table/toolbar';
import { OutlineButton } from '@rexlabs/button';
import { AgGridReact } from 'ag-grid-react';
import { useToast } from 'view/components/@luna/notifications/toast';
import { ReactTableContext } from '@rexlabs/table';
import { Body } from '@rexlabs/text';
import { ColumnVisibleEvent } from 'ag-grid-community';
import { usePrivileges } from 'src/modules/authorization/roles/hooks/use-privileges';
import { UnauthorizedScreen } from 'src/modules/system/screens/unauthorized';
import { Privilege } from 'src/generated/privileges';
import { useCustomReportDescribers } from '../hooks/use-custom-report-describers';
import { GridRefContext } from '../../screens/table-report-record';
import { CustomReport } from '../types/CustomReport';
import { CustomReportContext } from '../screens/custom-report-details';
import { mapColumnDefinition } from '../utils/map-column-definition';
import {
  getIncludeFromColumnId,
  getIncludesFromColumnIds
} from '../utils/get-includes-from-column-ids';
import { getVisibleColumnIds } from '../utils/get-visible-column-ids';
import { useDatasource } from '../hooks/use-datasource';
import { customReportFilterInputs } from '../../data/filter-inputs';

const validate = {
  definitions: {
    'payable_to.object': { name: 'payable to', rules: 'required' },
    'payable_by.object': { name: 'payable by', rules: 'required' },
    description: { rules: 'required' },
    due_date: { rules: 'required' },
    is_tax_included: { rules: 'required' },
    bank_account: { rules: 'required' }
  }
};

const defaultStyles = StyleSheet({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%'
  }
});

export const customReportGridBlock: BlockConfig<CustomReport> = {
  id: 'report',
  title: 'Report',
  validate,
  isEditable: () => false,
  Edit: () => {
    return (
      <Grid columns={1}>
        <Body>Report not available while editing...</Body>
      </Grid>
    );
  },
  View: ({ data }) => {
    const { hasPrivilege } = usePrivileges();
    const { handleSaveTableState } = useContext(CustomReportContext);

    const { data: describers } = useCustomReportDescribers();
    const gridRef = React.useContext(GridRefContext) as MutableRefObject<
      AgGridReact<any>
    >;
    const tableContext = React.useContext(ReactTableContext);

    const describer =
      data?.resource && describers
        ? describers.find((describer) => describer.id === data.resource.id)
        : null;

    const datasource = useDatasource(
      describer?.id ?? null,
      gridRef,
      tableContext
    );

    const { addToast } = useToast();
    const s = useStyles(defaultStyles);

    function handleColumnVisibleChange(e: ColumnVisibleEvent) {
      const colId = e.column?.getColId();
      const isHidden = e.column?.isVisible() === false;

      if (gridRef?.current && colId) {
        const visibleColumns = getVisibleColumnIds(gridRef.current.api as any);
        const expectedIncludes = getIncludesFromColumnIds(visibleColumns);
        const include = getIncludeFromColumnId(colId);

        const shouldRefresh =
          include &&
          ((isHidden &&
            datasource?.fetchedIncludes.indexOf(include) !== -1 &&
            !expectedIncludes.includes(include)) ||
            (!isHidden && datasource?.fetchedIncludes.indexOf(include) === -1));

        // If this is a new include that we haven't already fetched, we need to refetch the data
        if (shouldRefresh) {
          gridRef.current.api.refreshServerSide();
        }
      }
    }

    const handleReportSave = useCallback(() => {
      const columnState = gridRef?.current?.api.getColumnState();
      const filters = tableContext.state.globalFilter ?? null;
      const sorts = tableContext.state.sortBy ?? null;
      handleSaveTableState(columnState, filters, sorts);

      addToast({
        type: 'success',
        description: <>The report layout and filters have been saved.</>
      });
    }, [
      addToast,
      gridRef,
      handleSaveTableState,
      tableContext.state.globalFilter,
      tableContext.state.sortBy
    ]);

    // TODO: load report is iffy due to filters. Modifying the filters externally isn't reflected in real time. Disabled for now.
    // const handleLoadReport = useCallback(() => {
    //   gridRef?.current?.api.applyColumnState({
    //     state: data?.table_state,
    //     applyOrder: true
    //   });

    //   // TODO: do we want to load filters?
    //   tableContext.state.globalFilter = data?.filters;
    //   gridRef?.current?.api.refreshServerSide();

    //   addToast({
    //     type: 'success',
    //     description: <>The report layout and filters have been loaded.</>
    //   });
    // }, [
    //   addToast,
    //   data?.filters,
    //   data?.table_state,
    //   gridRef,
    //   tableContext.state
    // ]);

    const handleResetReport = useCallback(() => {
      gridRef?.current?.api?.resetColumnState();
      // TODO: do we want to reset filters?
      // tableContext.state.globalFilter = {};
      // gridRef?.current?.api.refreshServerSide();

      addToast({
        type: 'success',
        description: <>The report layout has been reset.</>
      });
    }, [addToast, gridRef]);

    function handleFirstDataRendered() {
      if (data?.table_state) {
        gridRef?.current?.api.applyColumnState({
          state: data.table_state,
          applyOrder: true,
          defaultState: {
            hide: true
          }
        });

        const visibleColumns = getVisibleColumnIds(gridRef.current.api as any);
        const expectedIncludes = getIncludesFromColumnIds(visibleColumns);
        const shouldRefresh = expectedIncludes.length > 0;

        if (shouldRefresh) {
          gridRef.current.api.refreshServerSide();
        }
      }
    }

    const colDefs = useMemo(() => {
      if (!describer) {
        return [];
      }

      return describer.column_definitions.map(mapColumnDefinition);
    }, [describer]);

    if (data?.required_privileges) {
      if (
        !hasPrivilege({
          mode: 'all',
          privileges: data?.required_privileges as Privilege[]
        })
      ) {
        return (
          <UnauthorizedScreen
            title="You don't have permission to view this report."
            message={
              <>
                <p>This report requires the following privileges:</p>
                <ul>
                  {data?.required_privileges.map((privilege) => (
                    <li
                      style={{ listStyle: 'initial', marginLeft: 20 }}
                      key={privilege}
                    >
                      {privilege}
                    </li>
                  ))}
                </ul>
              </>
            }
          />
        );
      }
    }

    return (
      <div {...s('container')}>
        <Box
          alignSelf='start'
          justifyContent='space-between'
          style={{
            flex: 1,
            width: '100%'
          }}
        >
          <Toolbar
            hasColumnSelection={false}
            hasSearch={false}
            filterInputs={customReportFilterInputs}
          />
          <Box gap={8}>
            <OutlineButton
              onClick={handleReportSave}
              title='Saves current layout and filters'
            >
              Save report
            </OutlineButton>
            <OutlineButton
              onClick={handleResetReport}
              title='Resets layout and filters to default state'
            >
              Reset report
            </OutlineButton>
          </Box>
        </Box>
        {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}
        <div
          className='ag-theme-material'
          style={{ width: '100%', height: '100%' }}
        >
          {datasource && (
            <AgGridReact
              ref={gridRef}
              rowModelType='serverSide'
              serverSideDatasource={datasource}
              getRowId={(row) => row.data.id ?? row.data.key}
              columnDefs={colDefs}
              onColumnVisible={handleColumnVisibleChange}
              animateRows={true}
              rowSelection='single'
              containerStyle={{ height: '900px' }}
              onFirstDataRendered={handleFirstDataRendered}
              sideBar={{
                toolPanels: [
                  {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    width: 350,
                    toolPanelParams: {
                      suppressPivots: true,
                      suppressPivotMode: true
                    }
                  }
                ]
              }}
            />
          )}
        </div>
      </div>
    );
  }
};
