import React, { useEffect } from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { ValueListValue } from 'data/models/types';
import { TaskType } from 'src/modules/tasks/common/types/TaskType';
import { Field, HiddenField } from '@rexlabs/form';
import { ChecklistItemTriggerInput } from 'view/components/inputs/checklist-item-trigger-input/checklist-item-trigger-input';
import invariant from 'invariant';
import { ValueListSelect } from 'view/components/table/toolbar/inputs/value-list-select';
import { GenericTaskStatusSelect } from 'src/modules/tasks/common/components/generic-task-status-select';
import { Grid } from 'view/components/@luna/form/grid';
import Box from '@rexlabs/box';
import {
  ChecklistItemActionType,
  ChecklistTemplateItem
} from '../../types/ChecklistTemplate';
import {
  ChecklistTemplateItemEditForm,
  ChecklistTemplateItemEditFormDueDate,
  ChecklistTemplatesForm
} from '../../types/ChecklistTemplatesForm';
import { MessageTemplateContent } from '../../components/message-template-content';

export const checklistTemplateItemTriggerActionsBlock: BlockConfig<
  ChecklistTemplateItem,
  {
    taskType: ValueListValue<TaskType>;
    mainValues: Partial<ChecklistTemplatesForm>;
    dueDate: ChecklistTemplateItemEditFormDueDate;
  },
  ChecklistTemplateItemEditForm & { new_outcome_option: string }
> = {
  id: 'checklist-template-item-trigger-actions',
  title: 'Trigger details',
  validate: {
    definitions: {
      trigger: { rules: 'cannotBeOnDueDateIfDueDateIsNone' }
    }
  },
  Edit({ blockProps, values, setFieldValue }) {
    const actionId = values?.action?.id;
    const triggerValue = values?.trigger;

    invariant(blockProps, 'blockProps is required');

    // If the due date has changed in the details block, update it here in this block's local version of the field, so validation will work properly
    useEffect(() => {
      if (
        values?.due_date.due_date_type !== blockProps.dueDate?.due_date_type
      ) {
        setFieldValue?.('due_date', blockProps.dueDate);
      }
    }, [
      values?.due_date.due_date_type,
      values?.due_date,
      setFieldValue,
      blockProps.dueDate
    ]);

    return (
      <>
        <Grid columns={2}>
          <HiddenField name='due_date' />
          <Field
            name='trigger'
            label='Trigger'
            Input={ChecklistItemTriggerInput}
            optional={false}
            HelpTooltipContent={
              triggerValue?.trigger_type?.id === 'on_due_date'
                ? () =>
                    'If the subtask due date and time has already passed when the task is created, then the action will trigger immediately.'
                : undefined
            }
          />
          <Field
            id='action'
            name='action'
            label='Action'
            Input={ValueListSelect}
            onChange={() => {
              setFieldValue?.('template', null);
            }}
            inputProps={{
              // These have their own special handling on the "send message" block, so remove them from the list
              filter: (items: ValueListValue<ChecklistItemActionType>[]) =>
                items.filter(
                  (item) =>
                    !['send_sms', 'send_email', 'send_message'].includes(
                      item.id
                    )
                ),
              type: 'checklist_item_action_type',
              searchable: false,
              placeholder: 'Select a action',
              disabled: !(
                (triggerValue?.trigger_type?.id === 'on_due_date' &&
                  triggerValue?.time) ||
                triggerValue?.trigger_type?.id === 'on_completion'
              )
            }}
          />
        </Grid>
        {actionId === 'set_status' && (
          <>
            <Box paddingTop={12}>
              <Grid columns={1}>
                <Field
                  optional={false}
                  id='task_status'
                  name='task_status'
                  label='Task status'
                  Input={GenericTaskStatusSelect}
                  inputProps={{
                    taskType: blockProps?.taskType
                  }}
                />
              </Grid>
            </Box>
          </>
        )}
        {(actionId === 'send_email' || actionId === 'send_sms') && (
          <MessageTemplateContent
            checklistItemActionType={actionId as ChecklistItemActionType}
            taskType={blockProps?.taskType.id}
          />
        )}
      </>
    );
  }
};
