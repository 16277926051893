import { Select } from '@rexlabs/select';
import { RecordType } from 'data/models/types';
import React from 'react';
import { useItems } from 'view/hooks/use-items';
import {
  BulkEditField,
  useBulkEditFields
} from '../hooks/use-bulk-edit-fields';

export type BulkEditFieldsSelectProps = {
  recordType: RecordType;
};

function normaliser(item: BulkEditField) {
  return {
    id: item.id,
    label: item.label
  };
}

export const BulkEditFieldsSelect: React.FC<BulkEditFieldsSelectProps> = ({
  recordType,
  ...props
}) => {
  const { data } = useBulkEditFields(recordType);

  const { getSelectProps } = useItems({
    getItems: React.useCallback(() => {
      return data?.fields ?? [];
    }, [data]),
    getSuggestedItems: React.useCallback(() => {
      return data?.fields ?? [];
    }, [data])
  });

  return (
    <Select multi {...props} {...getSelectProps()} normaliser={normaliser} />
  );
};
