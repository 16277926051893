import { useQuery } from 'react-query';
import { TenancyPrepaymentBucket } from 'src/modules/prepayments/types/tenancy-prepayment-bucket';
import { api } from 'utils/api/api-client';

export function useGetTenancyPrepaymentBuckets(
  tenancyId: string | undefined,
  bankAccountId: string | undefined
): { data: TenancyPrepaymentBucket[]; isLoading: boolean } {
  const { data, isLoading } = useQuery(
    ['prepayment-bucket', tenancyId, bankAccountId],
    async () => {
      const response = await api.get(
        `/financials/tenancy-prepayment-buckets/${bankAccountId}/${tenancyId}?include=chart_of_accounts_accounts,property`
      );

      return response.data;
    },
    {
      enabled: !!tenancyId && !!bankAccountId,
      refetchOnWindowFocus: false
    }
  );

  return { data, isLoading };
}
