import React, { ComponentType } from 'react';

import { NumberInput, TextInput } from '@rexlabs/text-input';
import { DateInput } from 'view/components/@luna/inputs/date-input/date-input';
import { BooleanInput } from 'view/components/table/toolbar/inputs/boolean-input';
import { CurrencyInput } from 'view/components/table/toolbar/inputs/currency-input';
import { RecordSelect } from 'view/components/table/toolbar/inputs/record-select';
import { TimestampDateInput } from 'view/components/table/toolbar/inputs/timestamp-date-input';
import { ValueListSelect } from 'view/components/table/toolbar/inputs/value-list-select';
import { LinkButton } from '@rexlabs/button';
import Icons from '@rexlabs/icons';
import { RecordType } from 'data/models/types';
import Box from '@rexlabs/box';
import {
  defaultObjectTypes,
  ManagedBySelect
} from 'src/modules/tasks/common/components/managed-by-select';

const bulkEditInputMap: Record<BulkEditFieldType, ComponentType<any>> = {
  string: TextInput,
  currency: CurrencyInput,
  integer: NumberInput,
  bool: BooleanInput,
  date: DateInput,
  timestamp: TimestampDateInput,
  value_list: ValueListSelect,
  static_value_list: ValueListSelect,
  resource: RecordSelect
};

// Some cases call for a custom component, rather than a generic one. Here, you can define a map of custom components to use for specific fields on a given record type.
const customInputMap: Partial<
  Record<
    RecordType,
    Record<
      string,
      { input: ComponentType<any>; props?: Record<string, unknown> }
    >
  >
> = {
  task: {
    managed_by: {
      input: ManagedBySelect
    }
  },
  checklist_item: {
    assignee: {
      input: ManagedBySelect,
      props: {
        objectTypes: [...defaultObjectTypes, 'portfolio_role']
      }
    }
  }
};

export type BulkEditFieldType =
  | 'bool'
  | 'currency'
  | 'date'
  | 'integer'
  | 'resource'
  | 'string'
  | 'timestamp'
  | 'value_list'
  | 'static_value_list';

type SpecificBulkEditFieldProps =
  | ValueListBulkEditFieldProps
  | ResourceBulkEditFieldProps;

type GenericBulkEditFieldProps = {
  type: Exclude<BulkEditFieldType, SpecificBulkEditFieldProps['type']>;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  value?: any;
  useExistingValue?: boolean;
  id?: string;
  parentRecordType?: RecordType;
};

type ValueListBulkEditFieldProps = Omit<GenericBulkEditFieldProps, 'type'> & {
  type: 'value_list' | 'static_value_list';
  valueListId: string;
};

type ResourceBulkEditFieldProps = Omit<GenericBulkEditFieldProps, 'type'> & {
  type: 'resource';
  recordType: RecordType;
};

export type BulkEditFieldProps<T extends BulkEditFieldType> = T extends
  | 'value_list'
  | 'static_value_list'
  ? ValueListBulkEditFieldProps
  : T extends 'resource'
  ? ResourceBulkEditFieldProps
  : GenericBulkEditFieldProps;

export function BulkEditField<T extends BulkEditFieldType>(
  props: BulkEditFieldProps<T>
) {
  const [useExistingValue, setUseExistingValue] = React.useState(
    props.useExistingValue ?? false
  );

  const customInputs = props.parentRecordType
    ? customInputMap[props.parentRecordType]
    : null;

  const customInput = customInputs && props.id ? customInputs[props.id] : null;

  const InputComponent = customInput?.input ?? bulkEditInputMap[props.type];

  const dontUseExistingValue = () => {
    setUseExistingValue(false);
  };

  if (useExistingValue) {
    return (
      <>
        <TextInput disabled value='Keep existing' />
        {useExistingValue && (
          <Box marginTop={4}>
            <LinkButton
              IconLeft={Icons.CrossSmall}
              onClick={dontUseExistingValue}
            >
              Remove existing value
            </LinkButton>
          </Box>
        )}
      </>
    );
  }

  // this conflicts with our `type` prop, so needs special handling
  const valueListProps = isValueListProps(props)
    ? {
        type: props.valueListId
      }
    : {};

  return (
    <>
      <InputComponent {...customInput?.props} {...props} {...valueListProps} />
    </>
  );
}

function isValueListProps(
  props: Partial<BulkEditFieldProps<any>>
): props is ValueListBulkEditFieldProps {
  return props.type === 'value_list' || props.type === 'static_value_list';
}
