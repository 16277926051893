import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useDialog } from '@rexlabs/dialog';
import { noop } from 'lodash';
import { AddIcon } from 'view/components/icons/add';
import { TodoFormData } from 'src/modules/tasks/todos/types/TodoFormData';

import { CreateTodoRecordDialog } from '../../dialogs/create-todo-record-dialog';

type GetCreateTodoActionProps = {
  onCreate?: (data) => void;
  isFixture?: boolean;
  initialValues: Partial<TodoFormData>;
};

export function useGetCreateTodoAction(isNested = false) {
  const { open } = useDialog(CreateTodoRecordDialog, {
    target: isNested ? 'nested-dialogs' : 'dialogs'
  });

  return (
    { onCreate, initialValues, isFixture }: GetCreateTodoActionProps = {
      onCreate: noop,
      initialValues: {},
      isFixture: false
    }
  ): SingleActionDeclaration => {
    return {
      id: 'create-todo',
      intent: 'primary',
      group: 'todo',
      label: 'Create to-do',
      Icon: AddIcon,
      handleAction: async () => {
        open({
          isFixture,
          onCreate,
          initialValues: {
            ...initialValues,
            status: {
              id: 'not_started',
              label: 'Not started'
            },
            priority: {
              id: 'normal',
              label: 'Normal'
            }
          }
        });
      }
    };
  };
}
