import { Banner } from 'src/modules/common/components/banner';
import InfoCircleIcon from 'view/components/icons/info';
import React, { useEffect, useState } from 'react';
import { PropertyTenancy } from 'src/modules/property-tenancies/types/property-tenancy-types';
import { pluralize } from 'utils/formatters';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { PreviewData } from 'src/modules/properties/types/preview-data';
import { api } from 'utils/api/api-client';
import { useGetOpenRegenerateInvoicesDialogAction } from 'src/modules/property-tenancies/actions/use-get-open-regenerate-invoices-dialog-action';
import { ActionButtons } from 'view/components/action-buttons';
import { transformSingleActionsToRegularActionMenuItems } from 'utils/actions/transforms';
import Box from '@rexlabs/box';
import { isPropertyTenancyActivish } from 'src/modules/property-tenancies/utils/is-property-tenancy-activish';

type Props = {
  propertyTenancy: PropertyTenancy;
};

const defaultStyles = StyleSheet({
  list: {}
});

export const RentInvoiceRegenerationBanner = ({ propertyTenancy }: Props) => {
  const getOpenRegenerateInvoicesDialogAction = useGetOpenRegenerateInvoicesDialogAction();
  const s = useStyles(defaultStyles);
  const defaultPreviewData = {
    invoicesToCreate: 0,
    invoicesToCancel: 0
  };
  const [previewData, setPreviewData] = useState<PreviewData>(
    defaultPreviewData
  );
  const fetchPreviewData = async (propertyTenancy: PropertyTenancy) => {
    if (isPropertyTenancyActivish(propertyTenancy)) {
      try {
        const { data } = await api.get<PreviewData>(
          `/property-tenancies/${propertyTenancy.id}/regenerate-invoices`
        );
        setPreviewData(data);
      } catch (error) {
        setPreviewData(defaultPreviewData);
      }
    }
  };

  useEffect(() => {
    fetchPreviewData(propertyTenancy);
  }, [propertyTenancy]);

  const { invoicesToCreate, invoicesToCancel } = previewData;

  if (invoicesToCreate === 0 && invoicesToCancel === 0) {
    return <></>;
  }

  return (
    <Banner intent='information' align={'start'} Icon={InfoCircleIcon}>
      <Box
        alignItems={'center'}
        justifyContent={'space-between'}
        width={'100%'}
      >
        <div>
          <ul {...s('list')}>
            {invoicesToCancel > 0 && (
              <li>
                {invoicesToCancel} rent {pluralize('invoice', invoicesToCancel)}{' '}
                can be cancelled
              </li>
            )}
            {invoicesToCreate > 0 && (
              <li>
                {invoicesToCreate} rent {pluralize('invoice', invoicesToCreate)}{' '}
                can be created
              </li>
            )}
          </ul>
        </div>
        <ActionButtons
          actions={transformSingleActionsToRegularActionMenuItems([
            getOpenRegenerateInvoicesDialogAction(propertyTenancy, () =>
              fetchPreviewData(propertyTenancy)
            )
          ])}
        />
      </Box>
    </Banner>
  );
};
