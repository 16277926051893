import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useModelActions } from '@rexlabs/model-generator';
import { useToast } from 'view/components/@luna/notifications/toast';
import { UserGroup } from '../../types/UserGroup';
import { userGroupsModel } from '../../models/user-groups-model';

export function useGetDeleteUserGroupAction() {
  const { trashItem, refreshLists } = useModelActions(userGroupsModel);
  const { addToast } = useToast();
  return (userGroup: UserGroup): ActionDeclaration => {
    return {
      id: 'delete-user-group',
      group: 'user-groups',
      label: 'Delete User group',
      intent: 'danger',
      handleAction: async () => {
        await trashItem({
          id: userGroup.id
        });

        addToast({
          type: 'success',
          description: 'User group deleted successfully'
        });

        return refreshLists();
      }
    };
  };
}
