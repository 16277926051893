import { useRef, useCallback } from 'react';

/**
 * This hook can be used to focus on inputs within a html element. To do this, you need to add the contentRef to a container div
 * in the ref prop. Then when you set the focusIndex, it will focus on the input at that index.
 *
 * Example:
 * function MyComponent() {
 *   const { contentRef, setFocusIndex } = useGetFocusOnInputElementIndex();
 *
 *   return (
 *     <div ref={contentRef}>
 *       <input onChange={() => setFocusIndex(1)} />
 *       <input />
 *     </div>
 *   );
 * }
 *
 * In the above example, when the input changes, the focus will be set on the second input.
 * @returns {contentRef, setIndex}
 */
export function useFocusOnInputElementIndex() {
  const contentRef = useRef<HTMLDivElement | null>(null);

  const focusOnIndex = useCallback(
    (focusIndex: number) => {
      const core = contentRef?.current;

      if (core && focusIndex !== null) {
        const selector =
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
        const focusable = core.querySelectorAll<HTMLElement>(selector);

        focusable?.[focusIndex]?.focus?.();
      }
    },
    [contentRef]
  );

  return { contentRef, focusOnIndex };
}
