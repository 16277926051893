import { ChecklistItem } from '../../common/types/Checklist';
import { ChecklistItemStatus } from '../types/checklist-item-status';

export function getItemStatus(item: ChecklistItem): ChecklistItemStatus {
  const isCompleted = item.completed_at !== null;

  if (item.has_outcome) {
    if (item.outcome_data?.outcome_value_id) {
      return ChecklistItemStatus.HAS_OUTCOME;
    } else {
      return ChecklistItemStatus.CAPTURES_OUTCOMES;
    }
  }

  const messageStatus = item.action_message?.status?.id;

  // If completed and message is sending
  if (isCompleted && messageStatus === 'sending') {
    return ChecklistItemStatus.IS_SENDING_NOW;
  }

  // If it has been triggered in the past
  const triggeredAt = item.triggered_at;
  const skippedAt = item.skipped_at;
  if (isCompleted && messageStatus === 'sent' && triggeredAt) {
    return ChecklistItemStatus.WAS_SENT_AT_TIME;
  }

  // TODO: when there are other action types, need to check for them here to see if triggered but no action side effect
  if (triggeredAt && skippedAt) {
    // probably skipped sending
    return ChecklistItemStatus.ACTION_SKIPPED;
  }

  if (item.trigger_type?.id && triggeredAt && isCompleted) {
    // probably about to send, but hasn't been picked up by queue worker yet
    return ChecklistItemStatus.TRIGGERED;
  }

  // has been triggered in the past, but is now incomplete, or has no message - probably was unchecked
  if (item.trigger_type?.id && triggeredAt) {
    return ChecklistItemStatus.PREVIOUSLY_TRIGGERED;
  }

  // if it will trigger in the future
  const triggerType = item.trigger_type?.id;

  if (triggerType === 'on_completion' && !isCompleted) {
    return ChecklistItemStatus.WILL_TRIGGER_ON_COMPLETION;
  }

  if (triggerType === 'on_due_date' && !isCompleted) {
    return ChecklistItemStatus.WILL_TRIGGER_AT_TIME;
  }

  return ChecklistItemStatus.DEFAULT;
}
