import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useModelActions } from '@rexlabs/model-generator';
import { useToast } from 'view/components/@luna/notifications/toast';
import * as React from 'react';
import { useErrorDialog } from '@rexlabs/dialog';
import { FolioCommissionTemplate } from '../../types/FolioCommissionTemplate';
import { commissionTemplatesModel } from '../../models/commission-templates-model';

export function useGetDuplicateSupplierCommissionTemplateAction() {
  const { addToast } = useToast();
  const errorDialog = useErrorDialog();
  const { duplicateItem, refreshLists } = useModelActions(
    commissionTemplatesModel
  );

  return (item: FolioCommissionTemplate): SingleActionDeclaration => {
    return {
      label: 'Duplicate',
      handleAction: async () => {
        try {
          await duplicateItem({
            supplierCommissionTemplate: item
          });

          addToast({
            type: 'success',
            description: (
              <>
                <b>{item.name}</b> has been <b>successfully</b> duplicated
              </>
            )
          });

          refreshLists();
        } catch (err) {
          errorDialog.open(err);
        }
      }
    };
  };
}
