import React, { useMemo } from 'react';
import { IconProps } from '@rexlabs/icons';
import { StyleSheet, StylesProvider, text, useStyles } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { formatDate } from 'utils/dates/format';
import { IconActionCell } from 'src/modules/common/components/cells/icon-action-cell';
import { noop } from 'lodash';
import { ValueListValue } from 'data/models/types';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { LinkButton } from '@rexlabs/button';
import { SplitIcon } from 'view/components/icons/split';
import { BatchReceiptingItemType } from '../types';
import { BATCH_RECEIPTING_FLAGS } from '../feature-flags';
import {
  BatchReceiptingCardLayout,
  Intent
} from './batch-receipting-card-layout';
import { IconValue } from './batch-receipting-card-icon-value';
import { Summary } from './batch-receipting-card-summary';
import { BankStatementTransactionTypeIcon } from './bank-statement-transaction-type-icon';

interface BankTransactionCardProps {
  intent: Intent;
  date: string;
  summary?: string;
  amount: number;
  bankReferenceId?: string;
  isChild?: boolean;
  RecordIcon: React.ComponentType<IconProps>;
  onSplitManualTransactionAction?: () => void;
  type: ValueListValue<BatchReceiptingItemType>;
}

const defaultStyles = StyleSheet({
  label: {
    ...text.styles({ fallback: 'normal.bold' })
  }
});

const overrideStyles = StyleSheet({
  iconContainer: {
    justifyContent: 'center',
    alignItems: 'center'
  }
});

const emptyValue = '--';

export function BankTransactionCard({
  date,
  intent,
  summary,
  amount,
  bankReferenceId,
  isChild,
  type,
  RecordIcon,
  onSplitManualTransactionAction
}: BankTransactionCardProps) {
  const s = useStyles(defaultStyles);

  const { hasFeature } = useFeatureFlags();

  const hasPaymentGatewaysFeature = hasFeature(
    BATCH_RECEIPTING_FLAGS.PAYMENT_GATEWAYS
  );

  const BankTransactionLabel = useMemo(
    () => (
      <Box
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
        height='100%'
      >
        <div {...s('label')}>{formatDate(date)}</div>

        {hasPaymentGatewaysFeature ? (
          <StylesProvider
            components={{
              IconActionCell: overrideStyles
            }}
          >
            <IconActionCell
              tooltipLabel={type?.label}
              Icon={() => <BankStatementTransactionTypeIcon type={type?.id} />}
              onClick={
                type?.id === 'split_transactions' &&
                onSplitManualTransactionAction
                  ? onSplitManualTransactionAction
                  : noop
              }
            />
          </StylesProvider>
        ) : (
          isChild && (
            <LinkButton
              IconLeft={SplitIcon}
              onClick={onSplitManualTransactionAction}
            />
          )
        )}
      </Box>
    ),
    [date, s, isChild, onSplitManualTransactionAction]
  );

  const BankTransactionValue = useMemo(
    () => (
      <>
        <Summary summary={summary || emptyValue} amount={amount} />
        <IconValue Icon={RecordIcon} value={bankReferenceId || emptyValue} />
      </>
    ),
    [RecordIcon, amount, bankReferenceId, summary]
  );

  return (
    <BatchReceiptingCardLayout
      intent={intent}
      label={BankTransactionLabel}
      value={BankTransactionValue}
    />
  );
}
