import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import {
  BankWithdrawal,
  BankWithdrawalStatus
} from 'src/modules/banking/bank-withdrawals/types/bank-withdrawal-types';
import { useStatusChangeAction } from 'src/modules/common/actions/status/use-status-change-action';
import { bankWithdrawalModel } from 'src/modules/banking/bank-withdrawals/models/bank-withdrawal-model';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useBankWithdrawalDownloadAction } from 'src/modules/banking/bank-withdrawals/actions/use-bank-withdrawal-download-action';
import { useGetReconcileAction } from '../utils/bank-withdrawals-actions/use-get-reconcile-action';
import { useGetUnreconcileAction } from '../utils/bank-withdrawals-actions/use-get-unreconcile-action';

export function useBankWithdrawalActions(): (
  bankWithdrawal: BankWithdrawal
) => ActionDeclaration[] {
  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();
  const getReconcileAction = useGetReconcileAction();
  const getUnreconcileAction = useGetUnreconcileAction();
  const getDownloadWithdrawal = useBankWithdrawalDownloadAction();
  const statusChangeAction = useStatusChangeAction(bankWithdrawalModel);

  return function (bankWithdrawal: BankWithdrawal) {
    const supportedFormats = bankWithdrawal.supported_formats;

    const downloadActions = supportedFormats
      ? supportedFormats.map((formatDetail) =>
          getDownloadWithdrawal(
            formatDetail.label,
            {
              type: 'bank_withdrawal',
              object: bankWithdrawal
            },
            formatDetail.id,
            'asynchronous_link'
          )
        )
      : [];

    const voidAction = statusChangeAction<BankWithdrawalStatus>({
      record: bankWithdrawal,
      status: 'void'
    });

    const statusChangeActions: ActionDeclaration[] = [];
    switch (bankWithdrawal.status.id) {
      case 'withdrawn':
      case 'pending_withdrawal':
        statusChangeActions.push(voidAction);
    }

    return [
      ...getPrimaryRecordActionGroup('bank_withdrawal', bankWithdrawal.id),
      getReconcileAction(),
      getUnreconcileAction(),
      ...downloadActions,
      ...statusChangeActions
    ];
  };
}
