import { DialogProps } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import * as React from 'react';
import { useRecordCreatedToast } from 'src/modules/common/toasts/hooks/use-record-created-toast';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { portfolioRoleDetailsBlock } from '../blocks/portfolio-role-details-block';
import { portfolioRolesModel } from '../models/portfolio-roles-model';

export interface CreatePortfolioRoleRecordDialogProps extends DialogProps {
  initialValues: Record<string, unknown>;
}

const content = [
  {
    id: 'add-portfolio-role',
    label: 'Add Portfolio role',
    blocks: [portfolioRoleDetailsBlock]
  }
];

export function CreatePortfolioRoleRecordDialog({
  onClose,
  initialValues
}: CreatePortfolioRoleRecordDialogProps) {
  const { createItem, refreshLists } = useModelActions(portfolioRolesModel);
  const addToast = useRecordCreatedToast(portfolioRolesModel, { actions: [] });

  const handleSubmit = async ({ values }) => {
    const dataToSubmit = { ...values };

    const { data: portfolioRole } = await createItem({ data: dataToSubmit });

    addToast(portfolioRole);

    return refreshLists();
  };

  return (
    <RecordDialog
      title='Create Portfolio role'
      data={initialValues}
      content={content}
      onClose={onClose}
      handleSubmit={handleSubmit}
    />
  );
}
