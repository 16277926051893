import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useEntityListQuery, useEntityQuery } from '@rexlabs/model-generator';

import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { RecordScreen } from 'view/components/record-screen';

import {
  FlattenedProperty,
  PropertiesModel
} from 'src/modules/properties/types/property-types';
import { PropertyTenancy } from 'src/modules/property-tenancies/types/property-tenancy-types';

import { PropertyOwnership } from 'src/modules/property-ownerships/types/property-ownership-types';
import _ from 'lodash';
import { getComplianceEntriesByIdAndTypeQuery } from 'src/modules/compliance/common/data/compliance-entries-query';
import { useWhereabouts } from '@rexlabs/whereabouts';
import { usePropertyContent } from '../data/content';

import { PropertyTitleBlock } from '../components/property-title-block';
import {
  filterRelevantAgreements,
  getMostRelevantAgreement
} from '../utils/get-most-relevant-agreement';
import { useHandlePropertyDetailsSubmit } from '../hooks/use-property-details-handle-submit';
import { usePropertyDetailsInitialData } from '../hooks/use-property-details-initial-data';
import { usePropertyDetailsKey } from '../hooks/use-property-details-key';
import { getPropertyDetailsQuery } from '../queries/property-details.query';

interface PropertyDetailsScreenProps {
  propertyId: string;
}

export interface PropertyDetailContext {
  setSelectedPropertyOwnership?: React.Dispatch<
    React.SetStateAction<PropertyOwnership | null>
  >;
  setSelectedPropertyTenancy?: React.Dispatch<
    React.SetStateAction<PropertyTenancy | null>
  >;
  allowPostcodeFinder?: boolean;
}

export type PropertyRentScheduleContext = PropertyTenancy['rent_schedule'];

export const propertyDetailContext = createContext<PropertyDetailContext>({});
export const propertyRentScheduleContext = createContext<PropertyRentScheduleContext>(
  undefined
);

export function PropertyDetailsScreen({
  propertyId
}: PropertyDetailsScreenProps) {
  const breadcrumbs = [{ type: BREADCRUMBS.PROPERTY }];
  const propertyQuery = useMemo(() => getPropertyDetailsQuery(propertyId), [
    propertyId
  ]);

  const [
    selectedPropertyTenancy,
    setSelectedPropertyTenancy
  ] = useState<PropertyTenancy | null>(null);

  const [
    selectedPropertyOwnership,
    setSelectedPropertyOwnership
  ] = useState<PropertyOwnership | null>(null);

  const { status, data } = useEntityQuery(propertyQuery);

  const { data: entryData, status: complianceEntryStatus } = useEntityListQuery(
    getComplianceEntriesByIdAndTypeQuery({
      objectId: propertyId,
      objectType: 'property'
    })
  );

  const propertyTenancies = useMemo(
    () => data?.property_tenancies?.items || [],
    [data?.property_tenancies?.items]
  );
  const propertyOwnerships = useMemo(
    () => data?.property_ownerships?.items || [],
    [data?.property_ownerships?.items]
  );

  const { query: queryParams } = useWhereabouts();

  // Reset the selectedPropertyTenancy and selectedPropertyOwnership when the propertyId changes
  useEffect(() => {
    const propertyTenancy = getMostRelevantAgreement(propertyTenancies);
    setSelectedPropertyTenancy(propertyTenancy);

    const propertyOwnership = getMostRelevantAgreement(propertyOwnerships);
    setSelectedPropertyOwnership(propertyOwnership);
  }, [propertyId]);

  // Once the data has loaded, we need to find out what the most sensible default propertyTenancy and propertyOwnership is, and set it
  useEffect(() => {
    if (selectedPropertyTenancy === null) {
      const propertyTenancy = getMostRelevantAgreement(propertyTenancies);
      setSelectedPropertyTenancy(propertyTenancy);
    }

    if (selectedPropertyOwnership === null) {
      const propertyOwnership = getMostRelevantAgreement(propertyOwnerships);
      setSelectedPropertyOwnership(propertyOwnership);
    }
  }, [
    propertyTenancies,
    propertyOwnerships,
    propertyTenancies.length,
    propertyOwnerships.length
  ]);

  useEffect(() => {
    const updatedPropertyOwnership = propertyOwnerships.find(
      (candidate) => candidate.id === selectedPropertyOwnership?.id
    );
    const hasPropertyOwnershipChanged = !_.isEqual(
      selectedPropertyOwnership,
      updatedPropertyOwnership
    );

    if (hasPropertyOwnershipChanged && updatedPropertyOwnership) {
      setSelectedPropertyOwnership(updatedPropertyOwnership);
    }
  }, [propertyOwnerships, selectedPropertyOwnership]);

  useEffect(() => {
    const updatedPropertyTenancy = propertyTenancies.find(
      (candidate) => candidate?.id === selectedPropertyTenancy?.id
    );
    const hasPropertyTenancyChanged = !_.isEqual(
      selectedPropertyTenancy,
      updatedPropertyTenancy
    );

    if (hasPropertyTenancyChanged && updatedPropertyTenancy) {
      setSelectedPropertyTenancy(updatedPropertyTenancy);
    }
  }, [data?.property_tenancies?.items, selectedPropertyTenancy]);

  useEffect(() => {
    if (queryParams?.managementAgreement) {
      const propertyOwnership = propertyOwnerships.find(
        (propertyOwnership) =>
          propertyOwnership.id === queryParams.managementAgreement
      );

      if (propertyOwnership) {
        setSelectedPropertyOwnership(propertyOwnership);
      }
    }
  }, [queryParams?.managementAgreement, propertyOwnerships]);

  useEffect(() => {
    if (queryParams?.leaseAgreement) {
      const propertyTenancy = propertyTenancies.find(
        (propertyTenancy) => propertyTenancy.id === queryParams.leaseAgreement
      );

      if (propertyTenancy) {
        setSelectedPropertyTenancy(propertyTenancy);
      }
    }
  }, [queryParams?.leaseAgreement, propertyTenancies]);

  // Generates the memoized initial data for the form
  const initialValues = usePropertyDetailsInitialData(
    data,
    selectedPropertyTenancy,
    selectedPropertyOwnership,
    complianceEntryStatus === 'loaded' ? entryData : undefined
  );

  const content = usePropertyContent({
    ...data,
    selected_property_ownership: selectedPropertyOwnership,
    selected_property_tenancy: selectedPropertyTenancy,
    compliance_entries:
      complianceEntryStatus === 'loaded' ? entryData : undefined
  } as FlattenedProperty);

  // we don't want to render the record until the above initialData memo has settled,
  // so we need to add this condition to the isLoading guard
  const isLoading = useMemo(() => {
    if (!data) {
      return true;
    }

    // we need to manually check these here, because `useState` setter is async, so we don't know when it is done
    const relevantPropertyTenancies = propertyTenancies.filter(
      filterRelevantAgreements
    );
    const relevantPropertyOwnerships = propertyOwnerships.filter(
      filterRelevantAgreements
    );
    if (
      !relevantPropertyTenancies ||
      !relevantPropertyOwnerships ||
      (relevantPropertyTenancies?.length > 0 && !selectedPropertyTenancy) ||
      (relevantPropertyOwnerships?.length > 0 && !selectedPropertyOwnership)
    ) {
      return true;
    }

    return status === 'loading' || complianceEntryStatus === 'loading';
  }, [
    data,
    selectedPropertyTenancy,
    selectedPropertyOwnership,
    status,
    complianceEntryStatus
  ]);

  // Composes the key that is used to force full refresh of the record screen
  const key = usePropertyDetailsKey(initialValues);

  const handleSubmit = useHandlePropertyDetailsSubmit(propertyId);

  return (
    <propertyDetailContext.Provider
      value={{
        setSelectedPropertyOwnership,
        setSelectedPropertyTenancy,
        allowPostcodeFinder: false
      }}
    >
      <propertyRentScheduleContext.Provider
        value={data?.active_property_tenancy?.rent_schedule}
      >
        <RecordScreen
          privilege={'properties.read'}
          isLoading={isLoading}
          data={initialValues}
          handleSubmit={handleSubmit}
          content={content}
          titleBlock={<PropertyTitleBlock property={data as PropertiesModel} />}
          breadcrumbs={breadcrumbs}
          // This key forces the record screen to refresh the initial values for the edit state of the forms
          key={key}
        />
      </propertyRentScheduleContext.Provider>
    </propertyDetailContext.Provider>
  );
}
