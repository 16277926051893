import { TrustJournalEntry } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';
import { Columns } from 'view/components/table';
import { BankStatementTransactionImport } from '../types/bank-statement-transaction-import';

export const matchColumns: Columns<
  TrustJournalEntry | BankStatementTransactionImport
> = [
  {
    id: 'date',
    type: 'date',
    Header: 'Date',
    width: 120,
    accessor: (item) =>
      item.__record_type === 'trust_journal_entry'
        ? item.date_of
        : item.uploaded_date
  },
  {
    id: 'type_id',
    Header: 'Type',
    width: 140,
    accessor: (item) =>
      item.__record_type === 'trust_journal_entry'
        ? item.type?.label
        : 'Payment gateway'
  },
  {
    id: 'payment_method_id',
    Header: 'Payment Method',
    width: 180,
    accessor: (item) =>
      item.__record_type === 'trust_journal_entry'
        ? item.payment_information?.method?.label
        : item?.payment_gateway?.label
  },
  {
    id: 'reference',
    Header: 'Reference',
    width: 200,
    accessor: (item) =>
      item.__record_type === 'trust_journal_entry'
        ? item.payment_information?.reference
        : item.file?.name
  },
  {
    id: 'contacts',
    Header: 'Received from',
    width: 200,
    accessor: (item) =>
      item.__record_type === 'trust_journal_entry'
        ? item.contacts?.data.map((contact) => contact.display_name).join(', ')
        : '-'
  },
  {
    id: 'amount',
    type: 'currency',
    Header: 'Amount',
    cellProps: {
      align: 'right'
    },
    width: 120,
    accessor: (item) =>
      item.__record_type === 'trust_journal_entry'
        ? item.amount
        : item.total_amount
  }
];
