import { CreateManualJournalEntryFormData } from 'src/modules/financials/types/CreateManualJournalEntryFormData';

import { filterNullValues } from 'utils/filter-null-values';

import {
  BaseManualDeferredFeeLineItemCreateRequest,
  ManualAgencyCommissionLineItemCreateRequest,
  ManualSupplierCommissionLineItemCreateRequest
} from '../types/ManualDeferredFeeLineItemCreateRequest';
import { DeferredFeeTypeId } from '../types/DeferredFeeTypeId';

export function mapManualJournalEntryFormDataToCreateDeferredFeeRequest(
  typeId: DeferredFeeTypeId,
  formData: CreateManualJournalEntryFormData
):
  | ManualSupplierCommissionLineItemCreateRequest
  | ManualAgencyCommissionLineItemCreateRequest {
  const basePayload: BaseManualDeferredFeeLineItemCreateRequest = {
    date_of: formData.date_of,
    is_tax_included: !!formData.is_tax_included,
    description: formData.description,
    folio: {
      id: formData.folio.id,
      type: { id: formData.folio.__record_type }
    },
    amount: formData.amount,
    tax_type: {
      id: formData.tax_type.id
    },
    payable_to_chart_of_accounts_account: {
      id: formData.account.id
    }
  };

  if (typeId === 'supplier_commission') {
    const supplierCommissionPayload: ManualSupplierCommissionLineItemCreateRequest = {
      ...basePayload,
      type: {
        id: 'supplier_commission'
      }
    };

    return filterNullValues(supplierCommissionPayload);
  }

  const agencyFeePayload: ManualAgencyCommissionLineItemCreateRequest = {
    ...basePayload,
    type: {
      id: 'agency_fee'
    },
    payable_by_chart_of_accounts_account: {
      id: formData.payable_by_chart_of_accounts_account!.id
    },
    payable_by_property: formData?.property,
    summary_description: 'Manual'
  };

  return filterNullValues(agencyFeePayload);
}
