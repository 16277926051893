import React from 'react';

import { Invoice } from 'data/models/entities/financials/invoices';

import { Column, RecordTable } from 'view/components/table';

type CommissionFields = Pick<
  Invoice,
  | 'commission_amount_exc_tax'
  | 'commission_amount_inc_tax'
  | 'commission_amount_tax'
  | 'commission_description'
>;

const columns: Column<CommissionFields>[] = [
  {
    id: 'commission_description',
    Header: 'Commission structure',
    type: 'text',
    width: 300
  },
  {
    id: 'commission_amount_exc_tax',
    Header: 'Commission excl. tax',
    type: 'currency',
    width: 150
  },
  {
    id: 'commission_amount_tax',
    Header: 'Tax',
    type: 'currency',
    width: 100
  },
  {
    id: 'commission_amount_inc_tax',
    Header: 'Commission incl. tax',
    type: 'currency',
    width: 150
  }
];

export function InvoiceCommissionRecordTable({
  items
}: {
  items: CommissionFields[];
}) {
  return <RecordTable isLoading={false} columns={columns} items={items} />;
}
