import {
  CommissionRule,
  Contact
} from 'src/modules/contacts/types/contact-types';

import { InvoiceLineItemFormValues } from '../../invoices/components/invoice-line-item';
import { useGetCommissionPreviewQuery } from './use-commission-preview-query';

export function useCommissionPreview({
  contact,
  isTaxIncluded,
  lineItems,
  commissionRule
}: {
  contact?: Contact;
  isTaxIncluded: boolean;
  lineItems: InvoiceLineItemFormValues[];
  commissionRule?: Partial<CommissionRule>;
}) {
  const {
    data: commissionPreviewResponse,
    isFetching
  } = useGetCommissionPreviewQuery({
    contact,
    lineItems,
    commissionRule,
    isTaxIncluded
  });

  return {
    isLoading: isFetching,
    commissionPreview: commissionPreviewResponse?.data
  };
}
