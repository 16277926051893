import React from 'react';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { User } from 'src/modules/users/types/user-types';
import { useConfirmationDialog } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import { useToast } from 'view/components/@luna/notifications/toast';
import { UserGroup } from '../../types/UserGroup';
import { userGroupsModel } from '../../models/user-groups-model';

export function useGetRemoveMemberAction() {
  const { open } = useConfirmationDialog();
  const { updateItem, refreshItem } = useModelActions(userGroupsModel);
  const { addToast } = useToast();
  return (userGroup: UserGroup, userToRemove: User): ActionDeclaration => {
    return {
      id: 'remove-member',
      group: 'user-groups',
      label: 'Remove member',
      intent: 'danger',
      handleAction: async () => {
        open({
          title: 'Remove member',
          message: `Are you sure you want to remove ${
            userToRemove.contact!.display_name
          } from this group?`,
          onConfirm: async () => {
            await updateItem({
              id: userGroup.id,
              data: {
                users: userGroup.users?.data
                  .filter((user) => user.id !== userToRemove.id)
                  .map((user) => ({ id: user.id }))
              }
            });
            await refreshItem({ id: userGroup.id });
            return addToast({
              description: (
                <span>
                  Removed <strong>{userToRemove.contact!.display_name}</strong>{' '}
                  from <strong>{userGroup.label}</strong>
                </span>
              )
            });
          }
        });
      }
    };
  };
}
