import {
  RexPmColDef,
  RexPmColGroupDef
} from '../hooks/use-custom-report-describers';
import { mapFieldTypeToFormatter } from './map-field-type-to-formatter';

// Recursively map the column definitions to add the valueFormatter
export function mapColumnDefinition<T extends RexPmColDef | RexPmColGroupDef>(
  colDef: T
): T {
  if ('children' in colDef) {
    return {
      ...colDef,
      children: colDef.children.map(mapColumnDefinition)
    };
  }
  return {
    ...colDef,
    valueFormatter: mapFieldTypeToFormatter(colDef.__rex_pm_type, colDef.type)
  };
}
