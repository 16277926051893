import React, { useMemo } from 'react';
import { useEntityQuery } from '@rexlabs/model-generator';
import { useTranslation } from 'react-i18next';

import { Ownership, ownershipsModel } from 'data/models/entities/ownerships';

import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { RecordScreen } from 'view/components/record-screen';
import { useRecordScreenSubmitHandler } from 'view/hooks/use-record-screen-submit-handler';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';

import { FLAGS } from 'utils/feature-flags';
import { getAllIncludesStringFromModel } from 'utils/api/get-all-includes-string-from-model';

import { ownershipQuery } from '../data/queries';
import { useOwnershipContent } from '../data/use-ownership-content';
import { OwnershipTitleBlock } from '../components/ownership-title-block';
import { mapDisbursementFrequencyToDisbursementFrequencyFormData } from '../../disbursements/mappers/map-disbursement-frequency-to-disbursement-frequency-form-data';
import { mapDisbursementFrequencyFormDataToDisbursementFrequency } from '../../disbursements/mappers/map-disbursement-frequency-form-data-to-disbursement-frequency-request';
import { getEvenSharePercentage } from '../utils/get-even-share-percentage';

interface OwnershipDetailsScreenProps {
  ownershipId: string;
}

export function OwnershipDetailsScreen({
  ownershipId
}: OwnershipDetailsScreenProps) {
  const breadcrumbs = [{ type: BREADCRUMBS.OWNERSHIP }];
  const { t } = useTranslation();
  const query = useMemo(() => ownershipQuery(ownershipId), [ownershipId]);

  const { data, status, actions } = useEntityQuery(query, {
    throwOnError: false
  });

  const { hasFeature } = useFeatureFlags();

  const handleSubmit = useRecordScreenSubmitHandler(
    async ({ values, changedValues }) => {
      const dataToSubmit: Partial<Ownership> = changedValues;

      if (changedValues?.preferred_suppliers) {
        dataToSubmit.preferred_suppliers =
          changedValues?.preferred_suppliers?.data;
      }

      if (changedValues?.disbursement_payment_methods) {
        dataToSubmit.disbursement_payment_methods =
          values?.disbursement_payment_methods;
      }

      if (changedValues?.disbursement_frequency) {
        dataToSubmit.disbursement_frequency = {
          ...values.disbursement_frequency,
          ...mapDisbursementFrequencyFormDataToDisbursementFrequency(
            values.disbursement_frequency
          )
        };
      }

      if ('owners' in changedValues) {
        dataToSubmit.owners = values.owners;

        /**
         * NOTE: We need to keep the owners in the disbursement preferences in sync with the owners in the ownership.
         * If there is an owner in the disbursement_payment_methods that is not in the owners, this could lead to problems
         * when attempting to disburse, as someone we're attempting to pay out to, is no longer an owner.
         */
        const disbursementPaymentMethods =
          data?.disbursement_payment_methods || [];
        if (disbursementPaymentMethods.length) {
          const hasRemovedContactThatHasDisbursementPaymentMethod = disbursementPaymentMethods.some(
            (disbursementPaymentMethod) =>
              !values.owners.some(
                (owner) =>
                  owner.contact.id === disbursementPaymentMethod.contact.id
              )
          );

          if (hasRemovedContactThatHasDisbursementPaymentMethod) {
            throw new Error(
              t(
                'disbursements.editing-owners-when-owner-has-disbursement-preferences'
              ) as string
            );
          }
        }

        /**
         * NOTE: Currently we create ownerships with even shares, but if the feature flag is not enabled, the
         * user can't change the shares. This is not a problem, until the user attempts to add an owner,
         * or remove an owner. In this case, we need to recalculate the shares.
         * Once the feature is released we can remove this check and always use the shares from the form.
         */
        if (!hasFeature(FLAGS.OWNERSHIP_INCOME_ATTRIBUTION)) {
          const evenShare = getEvenSharePercentage(values.owners.length);
          dataToSubmit.owners = values.owners.map((owner, index) => ({
            ...owner,
            share: evenShare[index]
          }));
        } else {
          dataToSubmit.owners = values.owners;
        }
      }

      await actions.updateItem({
        id: ownershipId,
        data: dataToSubmit,
        args: {
          include: getAllIncludesStringFromModel(ownershipsModel)
        }
      });
    }
  );

  const content = useOwnershipContent();

  const disbursementFrequency =
    data?.disbursement_frequency &&
    mapDisbursementFrequencyToDisbursementFrequencyFormData(
      data.disbursement_frequency
    );

  const initialValues = data
    ? {
        ...data,
        disbursement_frequency: disbursementFrequency
      }
    : undefined;

  return (
    <RecordScreen
      privilege={'ownerships.read'}
      isLoading={status === 'loading'}
      data={data}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      content={content}
      titleBlock={<OwnershipTitleBlock ownership={data as Ownership} />}
      breadcrumbs={breadcrumbs}
    />
  );
}
