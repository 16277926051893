import { useDialog } from '@rexlabs/dialog';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { AgencyFee } from 'src/modules/agency-fees/types/agency-fee-types';
import { usePrivileges } from 'src/modules/authorization/roles/hooks/use-privileges';
import { CreateNewAgencyFeeDialog } from '../dialogs/create-new-agency-fee';

export function useGetDuplicateAction() {
  const { hasPrivilege } = usePrivileges();
  const { open: openCreateNewAgencyFeeDialog } = useDialog(
    CreateNewAgencyFeeDialog
  );

  return (agencyFee: AgencyFee): SingleActionDeclaration => {
    return {
      id: 'agency_fee-duplicate',
      group: 'duplicate',
      label: 'Duplicate',
      hidden: !hasPrivilege({
        mode: 'all',
        privileges: ['agency-fees.create', 'agency-fees.read']
      }),
      handleAction: () => {
        openCreateNewAgencyFeeDialog({
          agencyFee
        });
      }
    };
  };
}
