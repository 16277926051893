import React from 'react';
import { Dialog, DialogProps } from '@rexlabs/dialog';
import { Field, ReactForms } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import { Select } from '@rexlabs/select';
import { Checkbox } from '@rexlabs/checkbox';
import { StandardDialogFooter } from 'view/components/dialogs/components/standard-dialog-footer';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { Column } from '@rexlabs/grid';
import Box from '@rexlabs/box';
import { useToken } from '@rexlabs/styling';
import { normaliseValueListItem } from 'utils/normaliser/value-list-item';
import { FormGrid } from 'view/components/@luna/grid/form';
import { MetricConfig } from 'src/modules/metric-write-rules/types/Metric';

interface AddEditLabelDialogProps extends DialogProps {
  onSave: (label: MetricConfig['metric_labels'][number]) => void;
  onClose: () => void;
  initialValues?: Partial<MetricConfig['metric_labels'][number]>;
}

const typeOptions = [
  { id: 'json_path', label: 'Jsonpath' },
  { id: 'literal', label: 'Literal' },
  { id: 'prompt', label: 'Prompt' }
];

const defaultInitialValues: MetricConfig['metric_labels'][number] = {
  label: '',
  value: {
    type: { id: 'json_path', label: 'Jsonpath' },
    value: '',
    invert: false
  }
};

const validate = {
  definitions: {
    label: { rules: 'required' },
    'value.type': { rules: 'required' },
    'value.value': {
      rules: [
        'required_unless:value.type.id,prompt',
        'metricValueIsValidJsonPath:value'
      ].join('|')
    }
  }
};

export function AddEditMetricLabelDialog({
  onClose,
  onSave,
  initialValues = {}
}: AddEditLabelDialogProps) {
  const token = useToken();

  const isEditing = !!initialValues.label;
  const dialogTitle = isEditing ? 'Edit Label' : 'Add Label to Metric';

  const handleSubmit = (values: MetricConfig['metric_labels'][number]) => {
    if (values.label && values.value.type) {
      onSave(values);
      onClose();
    }
  };

  return (
    <Dialog title={dialogTitle} onClose={onClose} size={'s'}>
      <ReactForms
        initialValues={{ ...defaultInitialValues, ...initialValues }}
        validate={validate}
        handleSubmit={handleSubmit}
      >
        {({ submitForm, values }) => (
          <Box sy={token('spacing.m')}>
            <Box>
              <FormGrid columns={12} gutter={token('spacing.m')}>
                <Column width={12}>
                  <Field
                    name='label'
                    id='metric_label_label'
                    label='Label'
                    Input={TextInput}
                    inputProps={{
                      'data-testid': 'metric_label_label'
                    }}
                  />
                </Column>
                <Column width={4}>
                  <Field
                    name='value.type'
                    label='Type'
                    id='metric_label_type'
                    Input={Select}
                    inputProps={{
                      items: typeOptions,
                      normaliser: normaliseValueListItem
                    }}
                  />
                </Column>
                <Column width={8}>
                  {values?.value?.type.id !== 'prompt' ? (
                    <Field
                      name='value.value'
                      label='Value'
                      Input={TextInput}
                      inputProps={{
                        'data-testid': 'metric_label_value'
                      }}
                    />
                  ) : null}
                </Column>
                <Column width={4} />
                <Column width={8}>
                  {values?.value?.type.id !== 'prompt' ? (
                    <Field
                      name='value.invert'
                      optional={false}
                      Input={Checkbox}
                      inputProps={{ label: 'Invert value' }}
                    />
                  ) : null}
                </Column>
              </FormGrid>
            </Box>
            <StandardDialogFooter>
              <GhostButton onClick={onClose}>Cancel</GhostButton>
              <PrimaryButton
                data-testid='metric-label-save-button'
                onClick={() => {
                  submitForm();
                }}
              >
                {isEditing ? 'Update Metric Label' : 'Add Metric Label'}
              </PrimaryButton>
            </StandardDialogFooter>
          </Box>
        )}
      </ReactForms>
    </Dialog>
  );
}
