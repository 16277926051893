import React, { useCallback } from 'react';
import { DialogProps } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { filterNullValues } from 'utils/filter-null-values';
import { useToast } from 'view/components/@luna/notifications/toast';
import { SecurityDeposit } from 'data/models/entities/financials/security-deposits';
import {
  financialsSecurityDepositPaymentsModel,
  SecurityDepositPayment
} from 'data/models/entities/financials/security-deposit-payments';
import { securityDepositPaymentBlock } from '../blocks/security-deposit-payment';

const content: DialogContentConfig = [
  {
    id: 'basics',
    label: 'Basics',
    blocks: [securityDepositPaymentBlock]
  }
];

export interface CreateSecurityDepositPaymentDialogProps extends DialogProps {
  securityDeposit: SecurityDeposit;
}

export function CreateSecurityDepositPaymentDialog({
  securityDeposit,
  onClose
}: CreateSecurityDepositPaymentDialogProps) {
  const { addToast } = useToast();
  const { createItem, refreshLists } = useModelActions(
    financialsSecurityDepositPaymentsModel
  );
  const handleSubmit: RecordSubmitHandler = useCallback(async ({ values }) => {
    const { data } = await createItem({
      data: {
        type: { id: 'deposit' },
        ...filterNullValues(values)
      }
    });

    const securityDepositPayment = data as SecurityDepositPayment;

    addToast({
      description: (
        <>
          {securityDepositPayment.type.label} payment has been successfully
          added
        </>
      )
    });
    await refreshLists();
    return securityDepositPayment;
  }, []);

  return (
    <RecordDialog
      onClose={onClose}
      data={{
        security_deposit: securityDeposit
      }}
      title='Deposit payment'
      handleSubmit={handleSubmit}
      content={content}
      submitLabel='Create deposit payment'
    />
  );
}
