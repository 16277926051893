import {
  FreeformDialogProps,
  NormalisedItem,
  Select,
  SelectProps,
  useFreeformDialog,
  UseFreeformDialogArgs,
  UseItemsArgs
} from '@rexlabs/select';
import { RecordType } from 'data/models/types';
import * as React from 'react';
import {
  getSearchResults,
  SearchResultItem
} from 'utils/api/get-search-results';
import { CreateTaskDialog } from 'src/modules/tasks/common/dialogs/create-task-dialog';
import { Task } from 'src/modules/tasks/common/types/Task';
import { tasksModel } from 'src/modules/tasks/common/models/tasks-model';
import { getSearchResultItemFromObjectAndModel } from 'src/modules/common/utils/search-result-items/get-search-result-items-from-object-and-model';
import { useItems } from 'view/hooks/use-items';

function FreeformTaskDialog({
  selectItem,
  inputValue,
  onCreate: propOnCreate,
  ...props
}: any & FreeformDialogProps<Partial<Task<any>>>) {
  const onCreate = React.useCallback(
    (data) => {
      const task = getSearchResultItemFromObjectAndModel(data, tasksModel);

      propOnCreate?.(task);
      selectItem(task);
    },
    [propOnCreate, selectItem]
  );

  return (
    <CreateTaskDialog
      {...props}
      initialSummaryValue={inputValue ?? ''}
      onCreate={onCreate}
    />
  );
}

const getItemsOuter = async (term, objectTypes: RecordType[]) => {
  const searchResults = await getSearchResults(term, {
    objectTypes
  });

  return searchResults;
};

const taskNormaliser = (item): NormalisedItem => {
  return {
    id: item?.id,
    label: item?.label,
    type: item?.record?.type?.id,
    heading: item?.record?.type?.label
  };
};

export type TaskEntitySelectProps = SelectProps<SearchResultItem> &
  Pick<UseItemsArgs<SearchResultItem>, 'getSuggestedItems'> & {
    dialogProps?: UseFreeformDialogArgs<Task<any>>['props'];
    disableFixture?: boolean;
  };

export function TaskEntitySelect({
  dialogProps,
  disableFixture,
  ...props
}: TaskEntitySelectProps) {
  // We need to include the default value as a suggested item, otherwise it gets clobbered when the user selects an additional item
  const getSuggestedItems = React.useCallback(() => {
    if (!props.value) {
      return [];
    }

    return Array.isArray(props.value)
      ? props.value
      : [props.value as SearchResultItem];
  }, [props.value]);

  const getItems = React.useCallback(
    (term) => getItemsOuter(term, ['task']),
    []
  );

  const { getSelectProps } = useItems({
    getItems: getItems,
    getSuggestedItems: props.getSuggestedItems ?? getSuggestedItems
  });

  const { getSelectProps: useFreeformDialogGetSelectProps } = useFreeformDialog<
    Task<any>
  >({
    props: dialogProps,
    Dialog: FreeformTaskDialog
  });

  const selectProps = getSelectProps();
  const selectPropsWithFixture = getSelectProps(
    useFreeformDialogGetSelectProps()
  );

  return (
    <Select
      normaliser={taskNormaliser}
      {...props}
      {...(disableFixture ? selectProps : selectPropsWithFixture)}
    />
  );
}
