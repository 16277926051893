import React, { useContext } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { ValueListValue } from 'data/models/types';
import { invokeActionDeclaration } from 'src/modules/common/actions/utils/invoke-action-declaration';
import { useMouseBoundary } from '../../hooks/smart-checklists/use-mouse-boundary';
import {
  ChecklistTemplateItem,
  ChecklistTemplateItemGroup
} from '../../types/ChecklistTemplate';
import { useCreateChecklistItemAction } from '../../hooks/action-declarations/checklist-item/use-create-checklist-item-action';
import { getItemIndex } from '../../utils/get-item-index';
import { getGlobalIndexOfPreviousItem } from '../../utils/get-global-index-of-previous-item';
import { ChecklistTemplateItemBranch } from './checklist-template-item-branch';
import { AddSubtaskButton } from './add-subtask-button';
import { smartChecklistContext } from './smart-checklist-context';
import { ChecklistTemplateItemWrapper } from './checklist-template-item-wrapper';
import { fieldArrayContext } from './field-array-context';

type ChecklistTemplatePathProps = {
  hoverTarget: string | null;
  triggeredByItem: ChecklistTemplateItem;
  outcomeValue: ValueListValue<string>;
  setHoverTarget: (hoverTarget: string) => void;
  parentHoverTarget: string;
  // TODO: make these non optional, refactor stories
  checklistTemplateItemGroup?: ChecklistTemplateItemGroup;
  checklistTemplateItems?: ChecklistTemplateItem[];
  grey: boolean;
};

export const ChecklistTemplatePath: React.FC<ChecklistTemplatePathProps> = ({
  hoverTarget,
  triggeredByItem,
  outcomeValue,
  setHoverTarget,
  parentHoverTarget,
  checklistTemplateItemGroup,
  checklistTemplateItems,
  grey
}) => {
  const { isEditing } = useContext(smartChecklistContext);
  const { values, taskType, fieldArrayProps } = useContext(fieldArrayContext);
  const {
    checklist_template_item_groups: groups,
    checklist_template_items: items
  } = values;
  const className = checklistTemplateItemGroup?.id ?? 'no-id'; // TODO: remove once prop required
  useMouseBoundary({
    className,
    onEnter: () => setHoverTarget(className),
    onLeave: () => setHoverTarget(parentHoverTarget)
  });

  const createChecklistItemAction = useCreateChecklistItemAction(taskType);
  const handleAddSubtaskClick = () => {
    const insertAtIndex = getGlobalIndexOfPreviousItem(
      checklistTemplateItemGroup,
      values.checklist_template_items,
      values.checklist_template_item_groups
    );

    return invokeActionDeclaration(createChecklistItemAction, {
      fieldArrayProps,
      index: insertAtIndex,
      checklistTemplateItemGroup
    });
  };

  return (
    <Droppable
      isDropDisabled={hoverTarget !== className}
      direction='vertical'
      droppableId={className}
      type='items'
    >
      {(provided) => (
        <ChecklistTemplateItemBranch
          triggeredByItem={triggeredByItem}
          outcomeValue={outcomeValue}
          grey={grey}
          renderBranchFooter={() =>
            isEditing && (
              <AddSubtaskButton
                label='Add subtask to path'
                onClick={handleAddSubtaskClick}
              />
            )
          }
        >
          <div
            className={className}
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{
              minHeight: isEditing ? 100 : 0 // make it bigger while in drag mode, so there is a target
            }}
          >
            {checklistTemplateItems?.map((item, index) => (
              <ChecklistTemplateItemWrapper
                key={item.id}
                index={index}
                globalIndex={getItemIndex(
                  item,
                  values.checklist_template_items ?? []
                )}
                item={item}
                checklistTemplateItemGroup={checklistTemplateItemGroup}
              >
                {item.has_outcome && item.outcome_data?.outcome_options
                  ? item.outcome_data.outcome_options.map((option) => {
                      // If no group is found, this could be an error, however it could also just be a timing thing,
                      // so we don't want to throw an error, as the group may be created shortly after this render.
                      // @see inject-groups-for-outcome-item.tsx
                      const group = getGroupForOption(item, option, groups);
                      if (!group) {
                        return null;
                      }
                      const itemsForGroup = getItemsForGroup(group, items);

                      return (
                        <ChecklistTemplatePath
                          // These props handle the stuff needed to enable and disable dropzones depending where the user is hovering
                          checklistTemplateItemGroup={group}
                          checklistTemplateItems={itemsForGroup}
                          triggeredByItem={item}
                          setHoverTarget={setHoverTarget}
                          parentHoverTarget={className}
                          hoverTarget={hoverTarget}
                          grey={!grey}
                          outcomeValue={option}
                          key={option.id}
                        ></ChecklistTemplatePath>
                      );
                    })
                  : null}
              </ChecklistTemplateItemWrapper>
            ))}
            {provided.placeholder}
          </div>
        </ChecklistTemplateItemBranch>
      )}
    </Droppable>
  );
};

function getItemsForGroup(group, items?: ChecklistTemplateItem[]) {
  return (
    items?.filter(
      (item) => item.checklist_template_item_group?.id === group.id
    ) ?? []
  );
}

function getGroupForOption(item, option, groups) {
  return groups?.find(
    (group) =>
      group.triggered_by_checklist_template_item?.id === item.id &&
      group.triggered_by_value === option.id
  );
}
