import React, { useEffect } from 'react';

import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { useQueries } from 'react-query';
import { RecordTypes } from 'data/models/types';
import { handleCommand } from 'src/modules/common/commands/handle-command';
import { ReconciliationBanner } from 'src/modules/reconciliations/components/reconciliation-banner';
import { LoadingState } from 'src/view/components/states/loading';
import { api } from 'utils/api/api-client';
import { FLAGS } from 'utils/feature-flags';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { PageLayout } from 'view/components/layouts/page-layout/page-layout';
import { RecordIcon } from 'view/components/record-icon/record-icon';
import { useDefaultBankAccount } from 'view/hooks/api/use-default-bank-account';
import { useGetAppWideFilterQueryParams } from 'src/modules/app-wide-filters/hooks/use-get-app-wide-filter-params';
import { useErrorDialog } from '@rexlabs/dialog';
import {
  Privilege,
  usePrivileges
} from 'src/modules/authorization/roles/hooks/use-privileges';
import { DashboardCard } from '../components/dashboard-card';
import { DashboardStatBlocks } from '../components/dashboard-stat-blocks';
import { DashboardWidget } from '../types/dashboard-widget';

const styles = StyleSheet({
  caps: {
    textTransform: 'uppercase',
    color: ({ token }) => token('color.textStyle.body.subtext')
  },

  header: {
    background: ({ token }) => token('palette.white'),
    borderRadius: 8
  },

  cardContainer: {
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill, minmax(530px, 1fr))`,
    gap: 24
  }
});

type AccessMap = {
  [key in string]: {
    flag?: string;
    privilege?: Privilege;
  };
};

// TODO: would be better to do this on the backend, but the backend is not currently flag-aware, so this will do for now.
const widgetAccessMap: AccessMap = {
  [RecordTypes.TaskArrears]: {
    flag: FLAGS.ARREARS,
    privilege: 'tasks.arrears-jobs.read'
  },
  [RecordTypes.TaskMaintenance]: {
    flag: FLAGS.TASKS_MAINTENANCE,
    privilege: 'tasks.maintenances.read'
  },
  [RecordTypes.TaskInspection]: {
    flag: FLAGS.INSPECTIONS,
    privilege: 'tasks.inspections.read'
  },
  [RecordTypes.TaskPropertyCompliance]: {
    flag: FLAGS.PROPERTY_COMPLIANCE_TASK,
    privilege: 'tasks.property-compliance.read'
  },
  [RecordTypes.TaskLeaseReview]: {
    flag: FLAGS.LEASE_REVIEWS,
    privilege: 'tasks.lease-review.read'
  },
  [RecordTypes.TaskMoveIn]: {
    flag: FLAGS.MOVE_INS,
    privilege: 'tasks.move-in.read'
  },
  [RecordTypes.TaskMoveOut]: {
    flag: FLAGS.MOVE_OUTS,
    privilege: 'tasks.move-out.read'
  },
  [RecordTypes.Todo]: {
    flag: FLAGS.TODOS,
    privilege: 'tasks.todos.read'
  },
  [RecordTypes.Property]: {
    privilege: 'properties.read'
  },
  [RecordTypes.TaskSupplierCompliance]: {
    flag: FLAGS.SUPPLIER_COMPLIANCE_TASK,
    privilege: 'tasks.supplier-compliance.read'
  }
};

export function DashboardScreen() {
  const s = useStyles(styles);
  const { defaultBankAccount } = useDefaultBankAccount();
  const { hasFeature } = useFeatureFlags();
  const { hasPrivilege } = usePrivileges();
  const filter = useGetAppWideFilterQueryParams();
  const errorDialog = useErrorDialog();

  const isWidgetEnabled = React.useCallback(
    (widget: DashboardWidget) => {
      const accessEntry = widgetAccessMap[widget.record_type.id];

      if (!accessEntry) return true;

      const passesFlag = !accessEntry.flag || hasFeature(accessEntry.flag);
      const passesPrivilege =
        !accessEntry.privilege || hasPrivilege(accessEntry.privilege);

      return passesFlag && passesPrivilege;
    },
    [hasFeature]
  );

  const queries = useQueries([
    {
      refetchOnWindowFocus: false,
      queryKey: ['dashboard-financial-summary'],
      queryFn: () => {
        return api.get(`/dashboard?include=financial_summary`);
      }
    },
    {
      refetchOnWindowFocus: false,
      queryKey: ['dashboard-widgets', filter],
      queryFn: () => {
        return api.get<DashboardWidget[]>(
          `/widgets/dashboard?global_filter_type=${filter.type}&global_filter_value=${filter.value}`
        );
      }
    }
  ]);

  const isLoading = queries.some((query) => query.isLoading);

  const isError = queries.some((query) => query.isError);

  useEffect(() => {
    if (isError) {
      const message = queries
        .map((query) => {
          if (query.error instanceof Error) {
            return <li>{query.error.message}</li>;
          }
        })
        .filter(Boolean);

      errorDialog.open({
        message: <ul>{message}</ul>
      });
    }
  }, [isError]);

  if (isLoading) return <LoadingState />;

  const [financialSummaryQuery, widgetsQuery] = queries;

  return (
    <PageLayout>
      <Box flexDirection='column' gap={20}>
        {defaultBankAccount &&
          hasPrivilege({
            mode: 'all',
            privileges: ['bank-accounts.read', 'reconciliations.read']
          }) && <ReconciliationBanner bankAccount={defaultBankAccount} />}

        <DashboardStatBlocks
          financial_summary={financialSummaryQuery.data?.data.financial_summary}
        />

        <Box marginBottom={24} {...s('cardContainer')}>
          {widgetsQuery.data?.data
            .filter((widget) => isWidgetEnabled(widget))
            .map((widget) => {
              return (
                <DashboardCard
                  key={widget.heading}
                  IconSlot={
                    <RecordIcon size={'l'} recordType={widget.record_type.id} />
                  }
                  title={widget.heading}
                  onAction={() => {
                    handleAction(widget.action);
                  }}
                >
                  <DashboardCard.SummarySection>
                    {widget.summaries.map((summary) => {
                      return (
                        <DashboardCard.Summary
                          key={summary.label}
                          label={summary.label}
                        >
                          {summary.value}
                        </DashboardCard.Summary>
                      );
                    })}
                  </DashboardCard.SummarySection>

                  <DashboardCard.StatsSection>
                    {widget.stats.map((stat) => {
                      return (
                        <DashboardCard.Stat
                          key={stat.label}
                          label={stat.label}
                          onClick={(e) => {
                            e.preventDefault();

                            handleAction(stat.action);
                          }}
                          variant={stat.value === 0 ? 'normal' : stat.intent}
                        >
                          {stat.value === 0 ? '--' : stat.value}
                        </DashboardCard.Stat>
                      );
                    })}
                  </DashboardCard.StatsSection>
                </DashboardCard>
              );
            })}
        </Box>
      </Box>
    </PageLayout>
  );
}

/**
 * Wrapper around handleCommand that handles the action type. Can be removed later if we never need different action types
 */
function handleAction(action: DashboardWidget['action']) {
  if (!action) return;

  if (action.type.id === 'command') {
    return handleCommand(action.data);
  }

  return;
}
