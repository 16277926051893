import { useModelActions } from '@rexlabs/model-generator';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useToast } from 'view/components/@luna/notifications/toast';
import { portfolioRolesModel } from '../../models/portfolio-roles-model';
import { PortfolioRole } from '../../types/PortfolioRole';

export function useGetDeletePortfolioRoleAction() {
  const { trashItem, refreshLists } = useModelActions(portfolioRolesModel);
  const { addToast } = useToast();
  return (portfolioRole: PortfolioRole): ActionDeclaration => {
    return {
      id: 'delete-portfolio-role',
      group: 'portfolio-roles',
      label: 'Delete Portfolio role',
      intent: 'danger',
      handleAction: async () => {
        await trashItem({
          id: portfolioRole.id
        });

        addToast({
          type: 'success',
          description: 'Portfolio role deleted successfully'
        });

        return refreshLists();
      }
    };
  };
}
