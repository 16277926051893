import { Column } from 'view/components/table';
import { Task } from '../types/Task';
import { TaskType } from '../types/TaskType';
import { getLabelForManagedBy } from './get-label-for-managed-by';

export const closedDate = {
  id: 'closed_date',
  Header: 'Closed date',
  accessor: (item: Task<any>) => item.closed_at,
  type: 'date',
  width: 140
} as const;

export const managedBy = {
  id: 'managed_by',
  Header: 'Managed by',
  type: 'text',
  accessor: (item: Task<any>) => getLabelForManagedBy(item.managed_by),
  width: 140
} as const;

export const createdBy: Column<Task<TaskType>> = {
  id: 'created_by',
  Header: 'Created by',
  type: 'author',
  accessor: (item) => item?.created_by,
  width: 140
};

export const createdDate: Column<Task<TaskType>> = {
  id: 'created_date',
  Header: 'Created date',
  type: 'date',
  accessor: (item) => item?.created_at,
  width: 140
};
