import { Columns, RecordTable, RecordTableProps } from 'view/components/table';
import React from 'react';
import {
  PaymentHistory,
  PaymentHistoryType
} from 'src/modules/payment-history/types/payment-history';
import { Tenancy } from 'data/models/entities/tenancies';
import { Ownership } from 'data/models/entities/ownerships';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { paymentHistoryQuery } from 'src/modules/payment-history/queries/payment-history-query';
import { useTableFilters } from 'view/hooks/use-table-filters';

const columns: Columns<PaymentHistory> = [
  {
    id: 'date',
    Header: 'Date',
    type: 'date',
    width: 140
  },
  {
    id: 'description',
    Header: 'Details',
    accessor: (item) => item?.description || item?.sub_description,
    width: 350
  },
  {
    id: 'payment_method',
    Header: 'Payment method',
    type: 'value',
    width: 150
  },
  {
    id: 'amount',
    Header: 'Amount',
    type: 'currency',
    width: 150
  }
];

const typeIds: PaymentHistoryType[] = [
  'invoice_payment',
  'invoice_credit',
  'transfer_deposit',
  'trust_journal_entry'
];

const initialSortBy = [{ id: 'date', desc: true }];

interface PaymentHistoryRecordTableProps {
  object: Tenancy | Ownership | Contact;
  propertyId: string;
  Empty?: RecordTableProps<any>['Empty'];
}

export function PaymentHistoryRecordTable(
  props: PaymentHistoryRecordTableProps
) {
  const { propertyId, object, Empty } = props;
  const { getSort, getFilters } = useTableFilters('payment-histories');

  // Shouldn't use same filters when switching to a different property
  const tableId = `payment-history-record-table-${propertyId}`;

  return (
    <RecordTable
      initialGlobalFilter={[
        {
          field: 'type_id',
          op: 'in',
          value: typeIds
        }
      ]}
      forcedGlobalFilter={[
        {
          field: 'object_id',
          op: 'eq',
          value: object.id
        },
        {
          field: 'property_id',
          op: 'eq',
          value: propertyId
        }
      ]}
      emptyWithoutTable={Empty != null}
      id={tableId}
      columns={columns}
      getQuery={paymentHistoryQuery}
      withPagination={true}
      withToolbar={true}
      getFilters={getFilters}
      getSort={getSort}
      initialSortBy={initialSortBy}
      Empty={Empty}
    />
  );
}
