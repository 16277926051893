import { useDialog } from '@rexlabs/dialog';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import InvoicesIcon from 'view/components/icons/invoices';
import { usePermission } from 'src/modules/authorization/roles/hooks/use-permission';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { ProcessBillsDialog } from '../../dialogs/process-bills-dialog';
import { Bill } from '../../types/Bill';
import { ProcessBillsDraftDialog } from '../../dialogs/process-bills-draft-dialog';

export function useGetProcessBillAction() {
  const { isStandardUser } = usePermission();
  const { hasFeature } = useFeatureFlags();

  const processBillsDialog = useDialog(ProcessBillsDialog);
  const processBillsDraftDialog = useDialog(ProcessBillsDraftDialog);

  const billsDialog =
    isStandardUser && hasFeature(FLAGS.QUICK_INVOICES)
      ? processBillsDraftDialog
      : processBillsDialog;

  return (billsToBeProcessed: Bill[]): ActionDeclaration => {
    return {
      id: 'process-bill',
      group: 'process-bill',
      label: 'Process',
      Icon: InvoicesIcon,
      handleAction: async () => {
        billsDialog.open({ billsToBeProcessed });
      }
    };
  };
}
