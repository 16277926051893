import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';
import Box from '@rexlabs/box';
import { Value } from 'view/components/values';
import { Field } from '@rexlabs/form';
import { useToken } from '@rexlabs/styling';
import { Grid } from 'view/components/@luna/form/grid';

import { ArrearsTask } from 'src/modules/tasks/arrears/types/ArrearsTask';
import { ManagedBySelect } from '../../common/components/managed-by-select';
import { getLabelForManagedBy } from '../../common/utils/get-label-for-managed-by';

const validate = {
  definitions: {
    managed_by: { name: 'managed by', rules: 'required' }
  }
};

export const arrearsDetailsBlock: BlockConfig<ArrearsTask> = {
  id: 'details',
  title: 'Details',
  validate,
  View: ({ data }) => {
    return (
      <Grid columns={2}>
        <Value
          label='Property'
          value={data?.details?.property_tenancy?.property?.address_string}
        />
        <Value
          label='Tenancy'
          value={data?.details?.property_tenancy?.tenancy?.display_name}
        />
        <Value
          label='Managed by'
          value={getLabelForManagedBy(data?.managed_by)}
        />
      </Grid>
    );
  },
  Edit: ({ values }) => {
    const token = useToken();

    return (
      <Box sy={token('spacing.xxl')}>
        <Grid columns={2}>
          <Value
            label='Property'
            value={values?.details?.property_tenancy?.property?.address_string}
          />
          <Value
            label='Tenancy'
            value={values?.details?.property_tenancy?.tenancy?.display_name}
          />

          <Box sy={token('spacing.xxl')} pt={token('spacing.m')}>
            <Grid columns={2}>
              <Field
                id='managed_by'
                name='managed_by'
                label='Managed by'
                Input={ManagedBySelect}
              />
            </Grid>
          </Box>
        </Grid>
      </Box>
    );
  }
};
