/* eslint-disable max-lines */
import React, {
  ComponentType,
  ReactNode,
  useCallback,
  useMemo,
  useState
} from 'react';
import flatten from 'flat';
import { get, set } from 'lodash';
import { Helmet } from 'react-helmet';
import { useErrorDialog } from '@rexlabs/dialog';
import { MultiForm } from '@rexlabs/form';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import RenderLoading from 'view/components/@luna/render-loading';
import { AutoFocusDialogProps, Dialog } from 'view/components/@luna/dialog';
import LoadingState from 'view/components/states/compact/loading';

import { RecordSubmitHandler, submitAllForms } from '../utils';
import { GetHandlers } from '../types';
import { FormContent, FormContentProps } from './content-dialog';
import { DialogContentConfig } from './types';

import { TabbedFormContent } from './tabbed-content-dialog';

export interface RecordDialogProps
  extends AutoFocusDialogProps,
    Pick<
      FormContentProps,
      | 'ButtonGroup'
      | 'buttonGroupProps'
      | 'data'
      | 'initialValues'
      | 'submitLabel'
    > {
  title: ReactNode;
  content: DialogContentConfig; // if more than one step, tabs will be used
  handleSubmit?: RecordSubmitHandler;
  isLoading?: boolean;
  LoadingView?: ComponentType<any>;
  submitOnAllSteps?: boolean;
  blockProps?: any;
  footerLeft?: React.ReactNode;
  documentTitle?: string;
  prompt?: React.ReactNode;
  getHandlers?: GetHandlers;
  SubmitButton?: ComponentType<any>;
}

const defaultStyles = StyleSheet({
  container: {
    position: 'relative',
    height: '100%',
    width: '100%'
  },

  content: {
    height: '100%',
    width: '100%'
  }
});

export function RecordDialog({
  content,
  data,
  initialValues,
  handleSubmit,
  isLoading,
  LoadingView = LoadingState,
  title,
  documentTitle,
  submitLabel = title,
  SubmitButton,
  onClose,
  ...props
}: RecordDialogProps) {
  const s = useStyles(defaultStyles);
  const errorDialog = useErrorDialog();

  const [isSubmitting, setSubmitting] = useState(false);
  // Submit individual blocks in the dialog, which will just pass the changed
  // values up to the dialogs submit handler, this way we can validate all
  // forms at the same time and handle errors appropriately
  const handleBlockSubmit = useCallback((values, { isChanged, id }) => {
    const changedValues = Object.keys(flatten(isChanged)).reduce(
      (acc, name) =>
        // HACK: we want to send the field up as changed if it is changed
        // or if it is part of a field array with any changes is it
        get(isChanged, name) || name.match(/\.[0-9]+\./)
          ? set(acc, name, get(values, name))
          : acc,
      {}
    );

    return { values, changedValues, id };
  }, []);

  // Submit all forms, on validation error stay on the current step if it
  // contains an error, otherwise switch to the first step that does
  const handleDialogSubmit = useCallback(
    ({ validateAll, submitAll }) => {
      return submitAllForms({
        validateAll,
        submitAll,
        handleSubmit,
        setSubmitting
      });
    },
    [content, handleSubmit]
  );

  // Initialise all forms so we can validate and submit them, without them
  // even needing to be rendered
  const formConfigs = useMemo(() => {
    return content.reduce((acc, { blocks, leftBlocks }) => {
      const allBlocks = [...blocks, ...(leftBlocks ? leftBlocks : [])];

      allBlocks?.forEach((block) => {
        if (block.id) {
          acc[block.id] = {
            names: block.fieldNames,
            initialValues: initialValues || data,
            validate: block.validate ?? {},
            handleSubmit: handleBlockSubmit
          };
        }
      });
      return acc;
    }, {});
  }, [content, initialValues, data, handleBlockSubmit]);

  return (
    <>
      <Helmet title={documentTitle ?? title?.toString?.()} />
      <Dialog onClose={onClose} title={title} isLoading={isLoading} {...props}>
        <RenderLoading LoadingView={LoadingView} isLoading={isLoading}>
          <MultiForm formConfigs={formConfigs}>
            {({ validateAll, submitAll, resetAll, forms }) => {
              const hasTabs = content.length > 1;

              const formContentProps = {
                ...props,
                content,
                submitLabel,
                onClose,
                SubmitButton,
                data,
                initialValues,
                forms,
                resetAll,
                isSubmitting,
                handleSubmit: () =>
                  handleDialogSubmit({ validateAll, submitAll }).catch((e) => {
                    console.error(e);
                    errorDialog.open(e);
                  })
              };

              if (hasTabs) {
                return <TabbedFormContent {...formContentProps} />;
              }

              return (
                <Box flexDirection='row' {...s('container')}>
                  <Box {...s('content')} flex={1}>
                    <FormContent {...formContentProps} step={content[0]} />
                  </Box>
                </Box>
              );
            }}
          </MultiForm>
        </RenderLoading>
      </Dialog>
    </>
  );
}
