import { useMutation, useQueryClient } from 'react-query';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { api } from 'utils/api/api-client';
import { CustomValidation } from 'src/modules/custom-validations/types/CustomValidation';
import { CUSTOM_VALIDATIONS_QUERY_KEY } from 'src/modules/custom-validations/hooks/use-custom-validations';

export function useGetToggleEnabledCustomValidationAction() {
  const queryClient = useQueryClient();

  const toggleEnabledMutation = useMutation(
    (customValidation: CustomValidation) =>
      api.put<CustomValidation>(`/custom-validations/${customValidation.id}`, {
        ...customValidation,
        is_enabled: !customValidation.is_enabled
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CUSTOM_VALIDATIONS_QUERY_KEY);
      }
    }
  );

  return (customValidation: CustomValidation): ActionDeclaration => {
    return {
      id: 'toggle-enabled-custom-validation',
      group: 'custom-validations',
      label: customValidation.is_enabled ? 'Disable' : 'Enable',
      handleAction: async () => {
        await toggleEnabledMutation.mutateAsync(customValidation);
      }
    };
  };
}
