import { push } from '@rexlabs/whereabouts';
import ROUTES from 'routes/app';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { BATCH_RECEIPTING_FLAGS } from '../../feature-flags';

export function useViewStatementsAction({
  bankAccountId
}: {
  bankAccountId: string;
}): SingleActionDeclaration {
  const { hasFeature } = useFeatureFlags();

  const hasPaymentGatewaysFeature = hasFeature(
    BATCH_RECEIPTING_FLAGS.PAYMENT_GATEWAYS
  );

  return {
    id: 'view-uploaded-files',
    label: hasPaymentGatewaysFeature
      ? 'View uploaded files'
      : 'View statements',
    handleAction: async () => {
      push(ROUTES.BATCH_RECEIPTING_STATEMENTS, {
        query: { bankAccount: bankAccountId }
      });
    }
  };
}
