import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { useGetCreateNoteAction } from 'src/modules/note/hooks/use-get-create-note-action';
import { invokeActionDeclaration } from 'src/modules/common/actions/utils/invoke-action-declaration';
import { Bill } from '../types/Bill';

export const addNoteBlock: BlockConfig<Bill> = {
  id: 'add-note',
  Edit: ({ values }) => {
    const getCreateNoteAction = useGetCreateNoteAction('uploaded_bill');
    const uploadedBillId = values?.uploaded_bill?.id;

    return (
      <form
        id='notesForm'
        onSubmit={(e) => {
          e.preventDefault();

          invokeActionDeclaration(getCreateNoteAction, {
            hideAddNoteToField: true,
            attached: {
              id: uploadedBillId
            }
          });
        }}
      />
    );
  }
};
