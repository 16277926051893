import React from 'react';

import { DialogProps } from '@rexlabs/dialog';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { postcodeBlock, PostcodeFormValues } from '../blocks/postcode';

const content = [
  {
    id: 'details',
    label: 'Details',
    blocks: [postcodeBlock]
  }
];

interface PostcodeFinderDialogProps extends DialogProps {
  handleSubmit: RecordSubmitHandler<PostcodeFormValues>;
}

export function PostcodeFinderDialog({
  onClose,
  handleSubmit
}: PostcodeFinderDialogProps) {
  return (
    <RecordDialog
      title='Postcode finder'
      onClose={onClose}
      content={content}
      submitLabel='Save'
      handleSubmit={handleSubmit}
    />
  );
}
