import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { FinancialEntity } from 'src/modules/financials/utils/financial-entity-action-group/use-get-financial-entity-actions';
import ROUTES from 'routes/app';
import { push } from '@rexlabs/whereabouts';
import { createTableRouteConfig } from 'src/modules/common/utils/create-table-route-config';
import { getRecordLabel } from 'utils/records/get-record-label';
import { getRecordObject } from 'src/modules/common/utils/Records/get-record-object';
import { getRecordTypeName } from 'utils/records/get-record-type-name';
import dayjs from 'dayjs';
import { getToday } from 'utils/dates/dates';
import { formatDateIso } from 'utils/dates/format';
import { FolioLedgerReportType } from '../types/folio-ledger-report';

export function viewFolioLedgerReportAction({
  folio,
  type
}: {
  folio: FinancialEntity;
  type: FolioLedgerReportType;
}): ActionDeclaration {
  return {
    id: 'view-folio-ledger-report',
    group: 'messages',
    label: 'View report',
    handleAction: () => {
      const recordType = folio?.__record_type;

      push(
        ROUTES.TABLE_REPORT,
        createTableRouteConfig(
          [
            {
              field: 'object_type',
              op: 'eq',
              value: {
                //@ts-ignore - can be an object
                value: recordType,
                label: getRecordTypeName(recordType)
              }
            },
            {
              field: 'object_id',
              op: 'eq',
              value: {
                //@ts-ignore - can be an object
                value: folio.id,
                label: getRecordLabel(getRecordObject(folio, recordType))
              }
            },
            {
              field: 'start_date',
              op: 'gte',
              value: formatDateIso(dayjs(getToday()).subtract(3, 'month'))
            },
            {
              field: 'end_date',
              op: 'lte',
              value: getToday()
            }
          ],
          null,
          {
            params: { reportId: `${type}-ledger` }
          }
        )
      );
    }
  };
}
