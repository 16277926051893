import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { ActionButtons } from 'view/components/@luna/action-buttons';
import { ChecklistTemplate } from '../types/ChecklistTemplate';
import { ChecklistTemplatesForm } from '../types/ChecklistTemplatesForm';
import { CustomTaskTypeTemplatesList } from '../components/custom-task-type-templates-list';
import { useGetCreateCustomTaskTemplateAction } from '../hooks/custom-task-templates/use-get-create-custom-task-template-action';

export const customTasksListBlock: BlockConfig<
  ChecklistTemplate,
  any,
  ChecklistTemplatesForm
> = {
  id: 'custom-tasks-list',
  title: 'Custom tasks',
  View: () => {
    return <CustomTaskTypeTemplatesList />;
  },
  Actions: () => {
    const getCreateCustomTaskTemplateAction = useGetCreateCustomTaskTemplateAction();

    const {
      handleAction: handleCreate,
      label: createLabel
    } = getCreateCustomTaskTemplateAction();

    return (
      <ActionButtons
        actions={[
          {
            label: createLabel,
            handleAction: handleCreate
          }
        ]}
      />
    );
  }
};
