import { Generator } from 'data/models/generator';
import { api } from 'utils/api/api-client';
import _ from 'lodash';
import { FolioCommissionTemplate } from '../types/FolioCommissionTemplate';

const actionCreators = {
  // TODO -- check with BE
  duplicateItem: {
    request: ({
      supplierCommissionTemplate
    }: {
      supplierCommissionTemplate: FolioCommissionTemplate;
    }) =>
      api.post(
        `/financials/commission-templates/${supplierCommissionTemplate.id}/duplicate`,
        {
          folio_template: supplierCommissionTemplate
        }
      ),
    reduce: _.identity
  }
};

export const commissionTemplatesModel = new Generator<
  FolioCommissionTemplate,
  typeof actionCreators
>('financials/commission-templates').createEntityModel({
  actionCreators
});
