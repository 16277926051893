import React, { useMemo } from 'react';
import { TitleBlock } from 'view/components/cards/title/title-block';
import { RecordObject, RecordTypes } from 'data/models/types';
import { getRecordTitle } from 'utils/records/get-record-title';
import { TagConfig } from 'view/components/@luna/title-block';
import { useGetUserGroupActions } from '../hooks/action-menu-items/use-get-user-group-actions';
import { UserGroup } from '../types/UserGroup';

const type = RecordTypes.UserGroup;

type UserGroupTitleBlockProps = {
  userGroup?: UserGroup;
};

export function UserGroupTitleBlock({ userGroup }: UserGroupTitleBlockProps) {
  const getUserGroupActions = useGetUserGroupActions();

  const title = getRecordTitle({ type, object: userGroup } as RecordObject);
  // Filter out the delete action from the header block
  const actions = useMemo(
    () =>
      getUserGroupActions(userGroup).filter(
        (action) => !('intent' in action) || action.intent !== 'danger'
      ),
    [userGroup, getUserGroupActions]
  );

  if (!userGroup) {
    return null;
  }

  const tags: TagConfig[] = [];

  return <TitleBlock title={title} type={type} actions={actions} tags={tags} />;
}
