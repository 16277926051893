import React, { useMemo } from 'react';
import { query, useEntityQuery } from '@rexlabs/model-generator';

import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { RecordScreen } from 'view/components/record-screen';

import { useRecordScreenSubmitHandler } from 'view/hooks/use-record-screen-submit-handler';
import { TaskRightBarOverride } from 'src/modules/tasks/common/components/right-bar-override';
import { getContent } from '../data/content';
import { PropertyComplianceTitleBlock } from '../components/property-compliance-title-block';
import { tasksModel } from '../../common/models/tasks-model';
import { mapPropertyComplianceFormDataToTaskCreateRequest } from '../mappers/map-property-compliance-form-data-to-task-create-request';
import { PropertyComplianceTask } from '../types/PropertyComplianceTask';
import { getSearchResultItemFromManagedBy } from '../../common/utils/get-search-result-item-from-managed-by';

// TODO: move this somewhere else
const getPropertyComplianceQuery = (id: string) => query`{
  ${tasksModel} (id: ${id}) {
    id
    propertyComplianceDetails
    property {
      active_property_tenancy {
        tenancy {
          id
          tenants
        }
      }
    }
    managed_by
    task_links
    created_by
    updated_by
  }
}`;

interface PropertyComplianceDetailsProps {
  taskId?: string;
}

export function PropertyComplianceDetails({
  taskId
}: PropertyComplianceDetailsProps) {
  const breadcrumbs = [{ type: BREADCRUMBS.TASK_PROPERTY_COMPLIANCE }];
  const query = useMemo(() => getPropertyComplianceQuery(taskId!), [taskId]);

  const { status, data: rawData, actions } = useEntityQuery(query, {
    throwOnError: false
  });

  const handleSubmit = useRecordScreenSubmitHandler(
    async ({ changedValues }) => {
      const dataToSubmit = mapPropertyComplianceFormDataToTaskCreateRequest(
        changedValues
      );
      await actions.updateItem({
        id: taskId,
        data: dataToSubmit
      });
    }
  );

  const data: PropertyComplianceTask | undefined = rawData;
  const initialValues = data
    ? { ...data, managed_by: getSearchResultItemFromManagedBy(data.managed_by) }
    : undefined;

  const titleBlock = <PropertyComplianceTitleBlock task={data} />;

  const content = useMemo(() => (data ? getContent(data) : []), [data]);

  return (
    <RecordScreen
      privilege={'tasks.property-compliance.read'}
      isLoading={status === 'loading'}
      data={data}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      content={content}
      titleBlock={titleBlock}
      breadcrumbs={breadcrumbs}
      RightBarOverride={TaskRightBarOverride}
    />
  );
}
