import React, { useMemo } from 'react';

import { RecordScreen } from 'view/components/record-screen';

import { query, useEntityQuery } from '@rexlabs/model-generator';
import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { bankWithdrawalModel } from 'src/modules/banking/bank-withdrawals/models/bank-withdrawal-model';
import { useRecordScreenSubmitHandler } from 'view/hooks/use-record-screen-submit-handler';
import { BankWithdrawal } from 'src/modules/banking/bank-withdrawals/types/bank-withdrawal-types';
import { useContent } from '../data/details-content';
import { BankWithdrawalTitleBlock } from '../components/bank-withdrawals-title-block';

const getBankWithdrawalQuery = (id: string) => query`{
  ${bankWithdrawalModel} (id: ${id}) {
    id
    status
    bank_account
    line_items {
        trust_journal_entry {
            payment_information
            contact
            created_by
            updated_by
        }
    }
    totals
    supported_formats
    created_by
    updated_by
  }
}`;

interface BankWithdrawalDetailsProps {
  bankWithdrawalId: string;
}

export function BankWithdrawalDetails({
  bankWithdrawalId
}: BankWithdrawalDetailsProps) {
  const breadcrumbs = [{ type: BREADCRUMBS.BANK_WITHDRAWAL }];
  const query = useMemo(() => getBankWithdrawalQuery(bankWithdrawalId), [
    bankWithdrawalId
  ]);

  const { data, status, actions } = useEntityQuery(query, {
    throwOnError: false
  });

  const handleSubmit = useRecordScreenSubmitHandler(({ changedValues }) =>
    actions.updateItem({
      id: bankWithdrawalId,
      data: changedValues
    })
  );

  const content = useContent({ data });

  return (
    <RecordScreen
      privilege={'bank-withdrawals.read'}
      isLoading={status === 'loading'}
      data={data}
      handleSubmit={handleSubmit}
      content={content}
      titleBlock={
        <BankWithdrawalTitleBlock bankWithdrawal={data as BankWithdrawal} />
      }
      breadcrumbs={breadcrumbs}
    />
  );
}
