import { useItems as useItemsOriginal, UseItemsArgs } from '@rexlabs/select';
import React from 'react';
import { PmApiError } from 'utils/api/create-error-from-response';
import { SelectPrivilegeErrorMessage } from 'src/modules/system/components/select-privilege-error-message';

// A wrapper for modelgenerator's useItems which gets permission errors and displays a message to the user via the help text.
export function useItems<Item>(props: UseItemsArgs<Item>) {
  const result = useItemsOriginal<Item>(props);

  return {
    getSelectProps: (...props) => {
      const selectProps = result.getSelectProps(...props);
      const error = selectProps?.error as PmApiError | null;

      const helperText =
        error?.statusCode === 403 ? (
          <SelectPrivilegeErrorMessage error={error} />
        ) : (
          selectProps.helperText
        );

      return {
        ...selectProps,
        helperText
      };
    }
  };
}
