import React from 'react';
import { useDialog } from '@rexlabs/dialog';
import { PrimaryButton } from '@rexlabs/button';
import { Columns, TableProvider } from 'view/components/table';
import { ActionMenuItem } from '@rexlabs/action-menu';
import Box from '@rexlabs/box';
import { useToken } from '@rexlabs/styling';
import { AddEditMetricLabelDialog } from 'src/modules/metric-write-rules/dialogs/add-edit-metric-label-dialog';
import { Heading } from '@rexlabs/text';
import { DumbTable } from 'view/components/table/dumb-table';

import { MetricConfig } from 'src/modules/metric-write-rules/types/Metric';

interface MetricLabelsTableProps {
  setFieldValue: (field: string, value: any) => void;
  values: Partial<MetricConfig>;
}

const labelColumns: Columns = [
  {
    id: 'label',
    Header: 'Label',
    minWidth: 150
  },
  {
    id: 'value.type',
    Header: 'Type',
    minWidth: 100,
    accessor: (item) => item.value.type.label
  },
  {
    id: 'value.value',
    Header: 'Value',
    accessor: (item) =>
      item.value.type.id === 'prompt' || item.value?.value
        ? '–'
        : item.value.value
  }
];

export function MetricLabelsTable({
  setFieldValue,
  values
}: MetricLabelsTableProps) {
  const token = useToken();
  const {
    open: openAddEditLabelDialog,
    close: closeAddEditLabelDialog
  } = useDialog(AddEditMetricLabelDialog);

  const handleAddLabel = () => {
    openAddEditLabelDialog({
      onClose: closeAddEditLabelDialog,
      onSave: (newLabel) => {
        setFieldValue('metric_labels', [
          ...(values.metric_labels || []),
          newLabel
        ]);
      }
    });
  };

  const handleEditLabel = (label: MetricConfig['metric_labels'][number]) => {
    openAddEditLabelDialog({
      onClose: closeAddEditLabelDialog,
      onSave: (updatedLabel) => {
        setFieldValue(
          'metric_labels',
          values.metric_labels?.map((l) =>
            l.label === label.label ? updatedLabel : l
          )
        );
      },
      initialValues: label
    });
  };

  const handleDeleteLabel = (label: MetricConfig['metric_labels'][number]) => {
    setFieldValue(
      'metric_labels',
      values.metric_labels?.filter((l) => l.label !== label.label)
    );
  };

  return (
    <Box
      marginBottom={token('spacing.m')}
      style={
        { zIndex: 0 } // needed to stop table covering dialog bottom bar
      }
    >
      <Heading level={4}>Labels</Heading>
      <TableProvider
        columns={labelColumns}
        isLoading={false}
        items={values.metric_labels}
        getActionMenuItems={({ item }): ActionMenuItem[] => [
          {
            label: 'Edit',
            onClick: () => handleEditLabel(item)
          },
          {
            label: 'Delete',
            intent: 'danger',
            onClick: () => handleDeleteLabel(item)
          }
        ]}
      >
        <DumbTable
          withPagination={false}
          Empty={() => (
            <Box
              alignItems={'center'}
              justifyContent={'center'}
              pt={token('spacing.xl')}
            >
              No labels added
            </Box>
          )}
        />
      </TableProvider>
      <Box marginTop={token('spacing.s')}>
        <PrimaryButton onClick={handleAddLabel}>Add Label</PrimaryButton>
      </Box>
    </Box>
  );
}
