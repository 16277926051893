import { CommissionCalculatorPreview } from 'src/modules/supplier-commission/types/CommissionCalculatorPreview';
import { CommissionPreviewRequest } from 'src/modules/supplier-commission/types/CommissionPreviewRequest';

import { api } from 'utils/api/api-client';

export async function postCommissionPreview(
  requestParams: CommissionPreviewRequest
) {
  return api.post<CommissionCalculatorPreview>(
    `financials/commission-calculator/preview`,
    requestParams
  );
}
