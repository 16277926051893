import React, { useMemo, useState } from 'react';
import { Dialog, DialogProps } from '@rexlabs/dialog';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import Box from '@rexlabs/box';
import { Body } from '@rexlabs/text';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { InfoCircleIcon } from 'view/components/icons/info';
import { StandardDialogFooter } from 'view/components/dialogs/components/standard-dialog-footer';
import { AxiosRequestConfig } from 'axios';
import { api } from 'utils/api/api-client';
import { Tooltip } from '@rexlabs/tooltip';
import { CustomValidation } from 'src/modules/custom-validations/types/CustomValidation';
import { RexApiResponse } from '@rexlabs/api-client';

interface CustomValidationError {
  message: string;
  custom_validation: CustomValidation;
}

interface CustomValidationErrorDialogProps extends DialogProps {
  errors: CustomValidationError[];
  requestConfig: AxiosRequestConfig;
  onCancel: () => void;
  onSaveAnywaySuccess: (response: RexApiResponse) => void;
  onSaveAnywayFailure: (response: RexApiResponse) => void;
}

const styles = StyleSheet({
  errorList: {
    listStyleType: 'disc',
    paddingLeft: 12,
    marginLeft: 12
  },
  errorItem: {
    marginBottom: 8
  },
  messageContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  tooltipIcon: {
    marginLeft: 8,
    cursor: 'help'
  },
  label: {
    color: 'inherit'
  }
});

const HelpTooltipContent = ({ name }: { name: string }) => <Body>{name}</Body>;

export function CustomValidationErrorDialog({
  errors,
  requestConfig,
  onClose,
  onCancel,
  onSaveAnywaySuccess,
  onSaveAnywayFailure
}: CustomValidationErrorDialogProps) {
  const s = useStyles(styles);
  const [isResubmitting, setIsResubmitting] = useState(false);

  const hasBlockingErrors = useMemo(
    () =>
      errors.some(
        (error) =>
          error.custom_validation.effective_validation_type.id === 'error'
      ),
    [errors]
  );

  const singleError = errors.length === 1 ? errors[0] : null;

  function cancelAndClose() {
    onCancel();
    onClose?.();
  }

  async function saveAnywayAndResubmit() {
    setIsResubmitting(true);
    try {
      const response = await api.request({
        ...requestConfig,
        data: JSON.stringify({
          ...JSON.parse(requestConfig.data),
          __custom_validation_no_warnings: true
        })
      });
      onSaveAnywaySuccess(response as RexApiResponse);
    } catch (e) {
      onSaveAnywayFailure(e as any);
    } finally {
      onClose?.();
      setIsResubmitting(false);
    }
  }

  const renderErrorMessage = (error: CustomValidationError) => (
    <Box {...s('messageContainer')}>
      <Body>{error.message}</Body>
      <Tooltip
        Content={() => (
          <HelpTooltipContent name={error.custom_validation.name} />
        )}
      >
        <Box alignItems='center' flex={0} {...s('tooltipIcon')}>
          <InfoCircleIcon {...s('label')} size='m' />
        </Box>
      </Tooltip>
    </Box>
  );

  return (
    <Dialog
      size={'s'}
      title={hasBlockingErrors ? 'Validation Error' : 'Validation Warning'}
      onClose={cancelAndClose}
    >
      <Box>
        {singleError ? (
          renderErrorMessage(singleError)
        ) : (
          <>
            <Body>
              The following validation{' '}
              {hasBlockingErrors ? 'errors' : 'warnings'} occurred:
            </Body>
            <ul {...s('errorList')}>
              {errors.map((error, index) => (
                <li
                  key={`${error.custom_validation.id}-${index}`}
                  {...s('errorItem')}
                >
                  {renderErrorMessage(error)}
                </li>
              ))}
            </ul>
          </>
        )}
      </Box>
      <StandardDialogFooter>
        {!hasBlockingErrors ? (
          <GhostButton onClick={cancelAndClose}>Cancel</GhostButton>
        ) : null}
        <PrimaryButton
          isLoading={isResubmitting}
          onClick={hasBlockingErrors ? cancelAndClose : saveAnywayAndResubmit}
        >
          {hasBlockingErrors ? 'Close' : 'Save anyway'}
        </PrimaryButton>
      </StandardDialogFooter>
    </Dialog>
  );
}
