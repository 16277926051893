import React from 'react';
import { ContactFlag } from 'view/components/record-flags/contact-flag';
import { TrustJournalEntry } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';
import { RecordFlag } from 'view/components/record-flags/record-flag';
import { useStyles, StyleSheet, margin } from '@rexlabs/styling';
import { uniqBy } from 'lodash';
import { TrustLedgerFolio } from 'src/modules/trust-ledgers/types/trust-ledger-types';
import { Body } from '@rexlabs/text';
import { EntryLink } from '../components/entry-link';
import { EntryList } from '../components/entry-list';
import { EmptySection } from '../empty/base';
import { SecondaryList } from '../components/secondary-list';
import { cap } from '../utils';
export interface TrustJournalEntrySourceAndDestinationSectionProps {
  trustJournalEntry: TrustJournalEntry | 'empty';
}

export const styles = StyleSheet({
  subtext: {
    color: ({ token }) => token('color.textStyle.body.subtext'),
    ...margin.styles({
      left: 'xl'
    })
  }
});

function getDestinationRecords(
  trustJournalEntry: TrustJournalEntry
): TrustLedgerFolio[] {
  const trustJournalEntryType = trustJournalEntry.type.id;

  switch (trustJournalEntryType) {
    case 'transfer':
    case 'reverse_transfer': {
      const folio = trustJournalEntry.transfer_source?.trust_ledger?.folio;
      return folio ? [folio] : [];
    }
    case 'withdrawal':
    case 'reverse_withdrawal': {
      const folio = trustJournalEntry.withdrawal_source?.trust_ledger?.folio;
      return folio ? [folio] : [];
    }
    case 'receipt':
    case 'reverse_receipt':
      return trustJournalEntry?.contacts?.data ?? [];
  }
}

function getSourceRecords(
  trustJournalEntry: TrustJournalEntry
): TrustLedgerFolio[] {
  const lineItems = trustJournalEntry.ledger_line_items.items || [];

  const filteredLineItems = lineItems.filter((lineItem) => {
    const lineItemType = lineItem.type.id;
    return (
      ['deposit', 'invoice_payment'].includes(lineItemType) &&
      !!lineItem.trust_ledger?.folio?.id
    );
  });

  const folios = filteredLineItems.map(
    (lineItem) => lineItem.trust_ledger!.folio!
  );

  return uniqBy(folios, (folio) => folio?.id);
}

const EmptyState = () => (
  <EmptySection headline='' buttonLabel=''>
    There are no related folios.
  </EmptySection>
);

function Entries({
  records,
  maxListLength
}: {
  records: any[];
  maxListLength: number;
}) {
  const s = useStyles(styles);

  const excessCount = records?.length - maxListLength;

  return (
    <>
      {cap(records, maxListLength).map((record) => {
        const recordType = record.__record_type;

        return recordType === 'contact' ? (
          <EntryLink type='contact' id={record.id}>
            <ContactFlag contact={record} />
          </EntryLink>
        ) : (
          <EntryLink key={record.id} type={recordType} id={record.id}>
            <RecordFlag record={record} recordType={recordType} />
          </EntryLink>
        );
      })}
      {excessCount > 0 && (
        <Body as='span' {...s('subtext')}>{`+ ${excessCount} more`}</Body>
      )}
    </>
  );
}

export function TrustJournalEntrySourceAndDestinationSection({
  trustJournalEntry
}: TrustJournalEntrySourceAndDestinationSectionProps) {
  if (trustJournalEntry === 'empty') {
    return <EmptyState />;
  }

  const maxListLength = 3;
  const fromRecords = getDestinationRecords(trustJournalEntry);
  const toRecords = getSourceRecords(trustJournalEntry);

  if (fromRecords.length === 0 && toRecords.length === 0) {
    return <EmptyState />;
  }

  return (
    <SecondaryList>
      {fromRecords?.length ? (
        <EntryList headline='From folio'>
          <Entries records={fromRecords} maxListLength={maxListLength} />
        </EntryList>
      ) : (
        <></>
      )}
      {toRecords?.length ? (
        <EntryList headline='To folio'>
          <Entries records={toRecords} maxListLength={maxListLength} />
        </EntryList>
      ) : (
        <></>
      )}
    </SecondaryList>
  );
}
