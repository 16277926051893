import Box from '@rexlabs/box';
import { OutlineButton } from '@rexlabs/button';
import { FieldArray, HiddenField } from '@rexlabs/form';
import { border, margin, StyleSheet, useStyles } from '@rexlabs/styling';
import { Bold } from '@rexlabs/text';
import { Invoice } from 'data/models/entities/financials/invoices';
import React, { useEffect } from 'react';
import ReceiptsTransfersWithdrawalsIllustration from 'src/assets/illustrations/receipts-transfers-withdrawals.svg';
import { DialogLineItem } from 'src/modules/common/components/dialog-line-item';
import { SearchResultItem } from 'utils/api/get-search-results';
import { useTaxPreview } from 'utils/tax-preview/use-tax-preview';
import { GridColumns } from 'view/components/@luna/form/grid/grid';
import { Message } from 'view/components/@luna/message';
import { AddIcon } from 'view/components/icons/add';
import { BlockViewProps } from 'view/components/record-screen/types';
import { useFormValueEffect } from 'view/hooks/@luna/use-form-value-effect';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { Tenancy } from 'data/models/entities/tenancies';
import { Ownership } from 'data/models/entities/ownerships';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { FolioCommissionTemplate } from 'src/modules/supplier-commission/types/FolioCommissionTemplate';
import { useCommissionPreview } from '../../supplier-commission/hooks/use-commission-preview';
import {
  COMMISSION_PREVIEW_FIELD_KEY,
  COMMISSION_RULE_FIELD_KEY,
  InvoiceLineItemsFormValues
} from '../blocks/invoice-line-items';
import { InvoiceBlockProps } from '../types/invoice-block';
import {
  InvoiceLineItem,
  InvoiceLineItemFormValues
} from './invoice-line-item';
import { TaxPreview } from './tax-preview';
import { CommissionCalculationPreview } from './commission-calculation-preview';

const defaultStyles = StyleSheet({
  hr: {
    ...margin.styles({
      y: 'l'
    }),
    ...border.styles({
      top: {
        width: 'thin',
        color: 'container.static.medium'
      },
      right: {
        width: 'none'
      },
      bottom: {
        width: 'none'
      },
      left: {
        width: 'none'
      }
    })
  }
});

interface InvoiceLineItemsProps
  extends Pick<
    BlockViewProps<Invoice, InvoiceLineItemsFormValues>,
    'values' | 'setFieldValue'
  > {
  payableBy: SearchResultItem<Contact | Ownership | Tenancy>;
  payableTo: SearchResultItem<Contact | Ownership | Tenancy>;
  invoiceLineItemColumns?: GridColumns;
  TaxWrapper?: React.ComponentType;
  taxWrapperProps?: any;
  prefix?: string;
  blockId: string;
  lineItemsFieldName?: string;
  isTaxIncludedFieldName?: string;
  dialogType?: InvoiceBlockProps['dialogType'];
  handleRemoveReimbursement?: () => void;
}

export function InvoiceLineItemsForm({
  setFieldValue,
  values,
  payableBy,
  payableTo,
  prefix = 'Invoice',
  invoiceLineItemColumns,
  lineItemsFieldName = 'line_items',
  isTaxIncludedFieldName = 'is_tax_included',
  TaxWrapper = React.Fragment,
  taxWrapperProps = {},
  blockId,
  dialogType,
  handleRemoveReimbursement
}: InvoiceLineItemsProps) {
  const { hasFeature } = useFeatureFlags();
  const s = useStyles(defaultStyles);

  const taxPreviewFieldName = `${prefix.toLowerCase()}_tax_preview`;

  const { isLoading, taxPreview, updateTaxPreviews } = useTaxPreview();

  const isSupplier =
    payableTo?.record?.__record_type === 'contact' &&
    !!payableTo?.record?.is_supplier;

  const selectedCommissionRule = values?.commission_rule;

  const currentCommissionPreview = values?.commission_preview;

  const formDeps = [
    `${lineItemsFieldName}.*.tax_type`,
    `${lineItemsFieldName}.*.amount`,
    isTaxIncludedFieldName
  ];

  const lineItems: InvoiceLineItemFormValues[] =
    values?.[lineItemsFieldName] || [];

  const isTaxIncluded = !!values?.[isTaxIncludedFieldName];

  const difference =
    (taxWrapperProps?.invoiceTotal || 0) - (taxPreview?.amount_inc_tax || 0);

  const showCommission =
    isSupplier &&
    !!lineItems.length &&
    hasFeature(FLAGS.SUPPLIER_COMMISSIONS) &&
    dialogType != 'draft';

  const {
    isLoading: isCommissionLoading,
    commissionPreview
  } = useCommissionPreview({
    // we only want to fetch the commission preview when the payable to is a supplier contact
    contact: isSupplier ? (payableTo?.record as Contact) : undefined,
    isTaxIncluded,
    lineItems,
    commissionRule: {
      structure_type: selectedCommissionRule?.commission_structure,
      commission_amount:
        selectedCommissionRule?.commission_amount_currency ||
        selectedCommissionRule?.commission_amount_percent,
      commission_template: selectedCommissionRule?.commission_template as FolioCommissionTemplate
    }
  });

  useFormValueEffect(
    blockId,
    ({ values }) => {
      const lineItems = values[lineItemsFieldName];

      if (lineItems?.length) {
        const validItems = lineItems?.filter(
          (item) => item?.amount && item?.tax_type?.id
        );

        if (!validItems.length) return;

        updateTaxPreviews(isTaxIncluded, validItems);
      }
    },
    formDeps
  );

  // If the commission preview changes, update the form value
  useEffect(() => {
    setFieldValue?.(COMMISSION_PREVIEW_FIELD_KEY, commissionPreview);
  }, [commissionPreview, COMMISSION_PREVIEW_FIELD_KEY]);

  // If the tax preview changes, update the form value
  useEffect(() => {
    setFieldValue?.(taxPreviewFieldName, taxPreview);
  }, [taxPreview, taxPreviewFieldName]);

  // When payable to changes and isn't a supplier, clear the commission preview values
  useEffect(() => {
    !isSupplier && setFieldValue?.(COMMISSION_PREVIEW_FIELD_KEY, null);
  }, [isSupplier]);

  return (
    <>
      <HiddenField name={taxPreviewFieldName} />

      <FieldArray name={lineItemsFieldName}>
        {function LineItem({ fields, push }) {
          if (!payableBy || !payableTo) {
            return (
              <Message
                Illustration={ReceiptsTransfersWithdrawalsIllustration}
                title='Select the relevant folios'
                grey={true}
              >
                Before adding in line items, please select the folio you wish to
                invoice from and bill to.
              </Message>
            );
          }
          return (
            <>
              <Box>
                {fields.map(({ field, actions }, index) => (
                  <DialogLineItem
                    key={field.name}
                    heading={<Bold>Invoice Line Item {index + 1}</Bold>}
                    onRemove={fields.length > 1 ? actions.remove : undefined}
                  >
                    <InvoiceLineItem
                      lineItemName={field.name}
                      payableBy={payableBy}
                      payableTo={payableTo}
                      testId={field.name}
                      columns={invoiceLineItemColumns}
                      setFieldValue={setFieldValue}
                    />
                  </DialogLineItem>
                ))}
              </Box>
              <Box mt='2rem' flexDirection='row' gap={12}>
                <OutlineButton
                  onClick={() => {
                    push({});
                  }}
                  IconLeft={AddIcon}
                >
                  Add line item
                </OutlineButton>

                {handleRemoveReimbursement && (
                  <OutlineButton onClick={handleRemoveReimbursement}>
                    Remove reimbursement invoice
                  </OutlineButton>
                )}
              </Box>
              <hr {...s('hr')} />
              <Box>
                <TaxWrapper {...taxWrapperProps} difference={difference}>
                  <TaxPreview
                    isTaxIncludedFieldName={isTaxIncludedFieldName}
                    prefix={prefix}
                    isLoading={isLoading}
                    taxPreview={taxPreview}
                    isTaxIncluded={values?.[isTaxIncludedFieldName]}
                    setFieldValue={setFieldValue}
                  />
                </TaxWrapper>
              </Box>
              {showCommission && (
                <>
                  <hr {...s('hr')} />
                  <Box>
                    <TaxWrapper {...taxWrapperProps} difference={difference}>
                      <CommissionCalculationPreview
                        isLoading={isCommissionLoading}
                        isTaxIncluded={isTaxIncluded}
                        commissionPreview={currentCommissionPreview}
                        commissionRuleLabel={
                          currentCommissionPreview?.commission_display_name
                            ? currentCommissionPreview?.commission_display_name
                            : 'none'
                        }
                        onCommissionRuleChange={(values) => {
                          setFieldValue?.(COMMISSION_RULE_FIELD_KEY, values);

                          // If the user clears the commission rule, clear the preview
                          if (
                            !values.commission_template &&
                            !values.commission_structure
                          ) {
                            setFieldValue?.(COMMISSION_PREVIEW_FIELD_KEY, null);
                          }
                          return values;
                        }}
                        currentCommissionRule={selectedCommissionRule}
                        onResetCommissionRule={() => {
                          setFieldValue?.(COMMISSION_RULE_FIELD_KEY, {
                            commission_template: null,
                            commission_amount_currency: null,
                            commission_amount_percent: null,
                            commission_structure: null
                          });
                          setFieldValue?.(COMMISSION_PREVIEW_FIELD_KEY, null);
                        }}
                      />
                    </TaxWrapper>
                  </Box>
                </>
              )}
              <HiddenField name={COMMISSION_RULE_FIELD_KEY} />
              <HiddenField name={COMMISSION_PREVIEW_FIELD_KEY} />
            </>
          );
        }}
      </FieldArray>
    </>
  );
}
