import React, { useMemo } from 'react';
import { query, useEntityQuery } from '@rexlabs/model-generator';

import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { RecordScreen } from 'view/components/record-screen';

import { useRecordScreenSubmitHandler } from 'view/hooks/use-record-screen-submit-handler';
import { TaskRightBarOverride } from 'src/modules/tasks/common/components/right-bar-override';
import { mapMoveInFormDataToTaskCreateRequest } from '../mappers/map-move-in-form-data-to-task-create-request';
import { content } from '../data/content';
import { MoveInTitleBlock } from '../components/move-in-title-block';
import { tasksModel } from '../../common/models/tasks-model';
import { getSearchResultItemFromManagedBy } from '../../common/utils/get-search-result-item-from-managed-by';

const getMoveInQuery = (id: string) => query`{
  ${tasksModel} (id: ${id}) {
    id
    property {
      active_property_ownership {
        ownership {
          id
          owners
        }
      }
    }
    managed_by
    moveInDetails
    task_links
    created_by
    updated_by
  }
}`;

interface MoveInDetailsProps {
  moveInId?: string;
}

export function MoveInDetails({ moveInId }: MoveInDetailsProps) {
  const breadcrumbs = [{ type: BREADCRUMBS.MOVE_IN }];
  const query = useMemo(() => getMoveInQuery(moveInId!), [moveInId]);

  const { status, data, actions } = useEntityQuery(query, {
    throwOnError: false
  });

  const handleSubmit = useRecordScreenSubmitHandler(
    async ({ changedValues }) => {
      const dataToSubmit = mapMoveInFormDataToTaskCreateRequest(changedValues);

      await actions.updateItem({
        id: moveInId,
        data: dataToSubmit
      });
    }
  );

  const titleBlock = <MoveInTitleBlock moveIn={data!} />;
  const initialValues = useMemo(
    () =>
      data
        ? {
            ...data,
            managed_by: getSearchResultItemFromManagedBy(data.managed_by)
          }
        : undefined,
    [data]
  );

  return (
    <RecordScreen
      privilege={'tasks.move-in.read'}
      isLoading={status === 'loading'}
      data={data}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      content={content}
      titleBlock={titleBlock}
      breadcrumbs={breadcrumbs}
      RightBarOverride={TaskRightBarOverride}
    />
  );
}
