import React, { useCallback, useState } from 'react';
import {
  DatePicker as VividDatePicker,
  DatePickerProps as VividDatePickerProps
} from '@rexlabs/date-input';
import { LinkButton } from '@rexlabs/button';
import { TextInput } from '@rexlabs/text-input';
import { debounce, isString, omit } from 'lodash';
import Box from '@rexlabs/box';
import { useValidDateExpression } from 'view/components/table/hooks/use-valid-date-expression';

export type DatePickerOnChangeEvent = {
  target: {
    value: string | null;
  };
};

export interface BaseDateInputProps
  extends Omit<VividDatePickerProps, 'onChange' | 'onBlur'> {
  format?: string;
  onChange?: (e: DatePickerOnChangeEvent) => void;
  onBlur?: (e: DatePickerOnChangeEvent) => void;
  allowExpressions?: boolean;
}

export function BaseDateInput({
  format,
  value,
  onChange,
  onBlur,
  allowExpressions,
  ...props
}: BaseDateInputProps) {
  const [isExpression, setIsExpression] = useState(
    !!allowExpressions && isString(value) && value.startsWith('~')
  );
  const [expression, setExpression] = useState(
    isString(value) ? cleanedValue(value) : ''
  );
  const { data: expressionData } = useValidDateExpression(
    expression,
    isExpression
  );

  const debouncedSetExpression = useCallback(
    debounce(setExpression, 500, {
      leading: true,
      trailing: true
    }),
    [setExpression]
  );
  return (
    <>
      {isExpression ? (
        <TextInput
          Prefix={() => <span>~</span>}
          {...omit(props, 'children', 'type', 'ref')}
          // if the value starts with a tilde, remove it
          value={isString(value) ? cleanedValue(value) : ''}
          onChange={(e) => {
            onChange?.({
              target: {
                value: prefixTilde(e.target.value || null)
              }
            });
            debouncedSetExpression(e.target.value);
          }}
          onBlur={(e) => {
            onBlur?.({
              target: {
                value: prefixTilde(e.target.value || null)
              }
            });
            debouncedSetExpression(e.target.value);
          }}
        />
      ) : (
        <VividDatePicker
          value={value}
          onChange={(e) => {
            onChange?.({
              target: {
                value: e.target.value?.format?.(format) || null
              }
            });
          }}
          onBlur={(e) => {
            onBlur?.({
              target: {
                value: e.target.value?.format?.(format) || null
              }
            });
          }}
          {...props}
        />
      )}
      {allowExpressions && (
        <Box flexDirection='row' justifyContent='space-between' paddingTop={8}>
          {isExpression ? (
            <Box>{expressionData?.resolved ?? 'Invalid expression'}</Box>
          ) : (
            <Box />
          )}

          <LinkButton onClick={() => setIsExpression((old) => !old)}>
            {isExpression ? 'Use date picker' : 'Use expression'}
          </LinkButton>
        </Box>
      )}
    </>
  );
}

function cleanedValue(value: string): string {
  return value.startsWith('~') ? value.slice(1) : value;
}

function prefixTilde(value: string | null): string | null {
  if (value === null) {
    return null;
  }
  return value.startsWith('~') ? value : `~${value}`;
}
