import { useMemo } from 'react';

import { getPayables, Payable } from 'utils/api/get-payables';
import { FinancialRecordType } from 'utils/financials/financial-objects';
import { SearchResultItem } from 'utils/api/get-search-results';
import { FLAGS } from 'utils/feature-flags';

import { useApi } from 'view/hooks/api/api';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { TenancyPrepaymentBucket } from 'src/modules/prepayments/types/tenancy-prepayment-bucket';

interface PayablesParams {
  folio: SearchResultItem | null;
  maxNumberOfPayables?: number;
  prepaymentBucket?: TenancyPrepaymentBucket | null;
  // NOTE: payables returns all invoices and is limited to a max number - so there is a chance we might not get
  // all the invoices we want. This is why we have an initialPayables param, where we can pass some in, and if they're
  // not in the request, we'll add them to the end of the list.
  // Ideally we need to update the payables endpoint to only return invoices that are not managed rent
  initialPayables?: Payable[];
}

export function usePayablesByFolio({
  folio,
  maxNumberOfPayables,
  prepaymentBucket,
  initialPayables
}: PayablesParams) {
  const { hasFeature } = useFeatureFlags();
  const {
    data: payables = [],
    isLoading: isPayablesLoading
  } = useApi(async () => {
    if (!folio) return;

    return getPayables({
      objectID: folio.id,
      objectType: folio.type.id as FinancialRecordType,
      prepaymentBucketId:
        folio.type.id === 'tenancy' ? prepaymentBucket?.bucket_id : undefined,
      prepaymentBucketPropertyId:
        folio.type.id === 'tenancy'
          ? prepaymentBucket?.property?.id
          : undefined,
      perPage: maxNumberOfPayables
    });
    // coalescing null and undefined below since there's no meaningful difference and otherwise we get multiple requests
  }, [
    folio?.id,
    prepaymentBucket?.bucket_id ?? null,
    prepaymentBucket?.property?.id
  ]);

  // check the payables to see if the initial payables are in the list,
  const filteredPayables = useMemo(() => {
    if (!initialPayables?.length) return payables;

    return [
      ...payables.filter((payable) => {
        return !initialPayables.some(
          (initialPayable) => initialPayable.id === payable.id
        );
      }),
      ...initialPayables
    ];
  }, [payables, initialPayables]);

  const filteredInvoices = useMemo(
    () =>
      filteredPayables?.filter(
        (payable) => payable.invoice.type.id !== 'managed_rent'
      ),
    [filteredPayables]
  );

  return {
    isPayablesLoading,
    payables: [
      ...(hasFeature(FLAGS.RENT_LINE_ITEM) ? filteredInvoices : payables)
    ]
  };
}
