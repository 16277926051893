import * as React from 'react';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogProps } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import { useToast } from 'view/components/@luna/notifications/toast';
import { useQueryClient } from 'react-query';
import { createCustomTaskTemplateBlock } from '../blocks/create-custom-task-template-block';
import { customTaskTemplatesModel } from '../models/custom-task-templates-model';
import { customTaskTemplateQueryKey } from '../hooks/custom-task-templates/use-custom-task-templates';

function getContent() {
  return [
    {
      id: 'create-custom-task-template',
      label: 'Create custom task type',
      blocks: [createCustomTaskTemplateBlock]
    }
  ];
}

type CreateCustomTaskTemplateDialogProps = DialogProps;

export function CreateCustomTaskTemplateDialog({
  onClose
}: CreateCustomTaskTemplateDialogProps) {
  const content = getContent();
  const queryClient = useQueryClient();
  const { addToast } = useToast();
  const { createItem, refreshLists } = useModelActions(
    customTaskTemplatesModel
  );
  const handleSubmit = async ({ values }) => {
    const result = await createItem({
      data: {
        label: values.label,
        // For now, hardcode the custom field on creation. In the future we may let the user change this.
        custom_fields: [
          {
            label: 'Template',
            type: {
              id: 'rich_text'
            }
          }
        ]
      }
    });
    await addToast({
      description: 'Custom task type created',
      type: 'success'
    });
    await Promise.all([
      refreshLists(),
      queryClient.invalidateQueries(customTaskTemplateQueryKey)
    ]);
    return result;
  };

  return (
    <RecordDialog
      title='Create custom task type'
      content={content}
      onClose={onClose}
      handleSubmit={handleSubmit}
    />
  );
}
