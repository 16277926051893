import React, { useState } from 'react';
import { BankAccountSelect } from 'src/modules/bank-accounts/components/bank-account-select';
import LoadingState from 'view/components/states/compact/loading';
import { useBankAccounts } from 'src/modules/bank-accounts/hooks/use-bank-accounts';
import { useGetTrustLedgerForFolioAndBankAccount } from 'src/modules/trust-ledgers/hooks/use-get-trust-ledger-for-folio-and-bank-account';
import { InfoBanner } from '@rexlabs/notifications';
import { useGetCreateLedgerSummaryDialogAction } from 'src/modules/trust-ledgers/hooks/use-get-create-ledger-summary-dialog-action';
import { OutlineButton } from '@rexlabs/button';
import { useQuery } from 'react-query';
import { api } from 'utils/api/api-client';
import { Quri } from '@rexlabs/quri';
import { getSearchQuery } from 'src/lib/react-query/hooks/use-records-query';
import { FinancialEntity } from 'src/modules/financials/utils/financial-entity-action-group/use-get-financial-entity-actions';
import { FolioLedgerTable } from '../components/folio-ledger-table';
import { FolioLedgerReportType } from '../types/folio-ledger-report';

export function FolioLedger({
  data,
  type
}: {
  data: FinancialEntity;
  type: FolioLedgerReportType;
}) {
  const operatingBankAccount =
    data?.__record_type === 'ownership' ? data?.operating_bank_account : null;
  const [selectedBankAccount, setSelectedBankAccount] = useState(
    operatingBankAccount ?? null
  );

  const { bankAccounts, defaultBankAccount } = useBankAccounts();

  React.useEffect(() => {
    if (!operatingBankAccount && defaultBankAccount) {
      setSelectedBankAccount(defaultBankAccount);
    }
  }, [bankAccounts]);

  const {
    trustLedger,
    isLoading: trustLedgerIsLoading
  } = useGetTrustLedgerForFolioAndBankAccount(data!, selectedBankAccount);

  const { data: folioLedgerData, isLoading: folioLedgersIsLoading } = useQuery(
    ['folio-ledger', type, data?.id, selectedBankAccount?.id],
    async () => {
      const filter: Quri[] = [
        {
          field: 'object_id',
          op: 'eq',
          value: data!.id
        },
        {
          field: 'object_type',
          op: 'eq',
          value: data!.__record_type
        },
        {
          field: 'bank_account_id',
          op: 'eq',
          value: selectedBankAccount!.id
        },
        {
          field: 'limit',
          op: 'eq',
          value: 5
        }
      ];

      const searchQuery = getSearchQuery({
        filter
      });

      return api.get(`/financials/folio-ledgers/${type}?${searchQuery}`);
    },
    { enabled: !!selectedBankAccount, select: ({ data }) => data }
  );

  const isLoading = folioLedgersIsLoading && trustLedgerIsLoading;

  const getCreateLedgerActivitySummaryDialogAction = useGetCreateLedgerSummaryDialogAction();
  const actionDeclaration = getCreateLedgerActivitySummaryDialogAction(
    trustLedger,
    selectedBankAccount
  );

  if (!selectedBankAccount || isLoading) {
    return <LoadingState />;
  }

  const showButton = 'handleAction' in actionDeclaration;

  const folioLedgerRowData = (folioLedgerData?.rows || []).map((row, index) => {
    return { id: index, ...row };
  });

  return (
    <>
      {showButton && type === 'cash' && (
        <OutlineButton
          onClick={() => {
            actionDeclaration.handleAction?.();
          }}
        >
          {actionDeclaration.label}
        </OutlineButton>
      )}
      <BankAccountSelect
        value={selectedBankAccount}
        onChange={(e) => setSelectedBankAccount(e.target.value)}
      />
      {folioLedgerData ? (
        <FolioLedgerTable items={folioLedgerRowData} />
      ) : (
        <InfoBanner
          description={`There is no transaction history for ${
            data!.display_name
          } in ${selectedBankAccount.name}`}
        />
      )}
    </>
  );
}
