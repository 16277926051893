/* eslint-disable max-lines */

import ROUTES from 'routes/app';
import { getRecordLinkProps } from 'view/components/record-link/get-record-link-props';
import { LinkProps } from '@rexlabs/whereabouts';
import i18n from 'src/modules/i18n/i18n';
import { useCustomTaskTemplates } from 'src/modules/tasks/settings/hooks/custom-task-templates/use-custom-task-templates';

export enum BREADCRUMBS {
  USER_GROUP = 'USER_GROUP',
  PORTFOLIO_ROLE = 'PORTFOLIO_ROLE',
  ROLE = 'ROLE',
  CUSTOM_REPORT = 'CUSTOM_REPORT',
  AUDIT_LOG = 'AUDIT_LOG',
  TASK_SETTINGS = 'TASK_SETTINGS',
  WORK_ORDERS = 'WORK_ORDERS',
  CUSTOM_TASKS = 'CUSTOM_TASKS',
  QUOTE = 'QUOTE',
  APPLIANCE = 'APPLIANCE',
  ARREARS = 'ARREARS',
  INSPECTION_TASKS = 'INSPECTION_TASKS',
  MOVE_OUT = 'MOVE_OUT',
  BILL_PROCESSING = 'BILL_PROCESSING',
  MOVE_IN = 'MOVE_IN',
  LEASE_REVIEW = 'LEASE_REVIEW',
  TODO = 'TODO',
  TASK_PROPERTY_COMPLIANCE = 'TASK_PROPERTY_COMPLIANCE',
  TASK_SUPPLIER_COMPLIANCE = 'TASK_SUPPLIER_COMPLIANCE',
  TASK_MAINTENANCE = 'TASK_MAINTENANCE',
  MESSAGE = 'MESSAGE',
  MESSAGE_TEMPLATE = 'MESSAGE_TEMPLATE',
  NOTE = 'NOTE',
  CONTACT = 'CONTACT',
  TENANCY = 'TENANCY',
  PROPERTY = 'PROPERTY',
  PROPERTY_TENANCY = 'PROPERTY_TENANCY',
  PROPERTY_TENANCY_DETAIL = 'PROPERTY_TENANCY_DETAIL',
  PROPERTY_OWNERSHIP = 'PROPERTY_OWNERSHIP',
  OWNERSHIP = 'OWNERSHIP',
  INVOICE = 'INVOICE',
  RECURRING_INVOICE = 'RECURRING_INVOICE',
  PAYMENT = 'PAYMENT',
  SETTINGS_SUPPLIER_SERVICE_CATEGORY = 'SETTINGS_SUPPLIER_SERVICE_CATEGORY',
  SETTINGS_PROPERTIES_COMPLIANCE_CATEGORY = 'SETTINGS_PROPERTIES_COMPLIANCE_CATEGORY',
  TRUST_ACCOUNT = 'TRUST_ACCOUNT',
  TRUST_LEDGER = 'TRUST_LEDGER',
  BANK_STATEMENT = 'BANK_STATEMENT',
  BANK_DEPOSIT = 'BANK_DEPOSIT',
  BANK_WITHDRAWAL = 'BANK_WITHDRAWAL',
  COMMUNICATION_TEMPLATE = 'COMMUNICATION_TEMPLATE',
  LETTER_LAYOUT = 'LETTER_LAYOUT',
  EMAIL_LAYOUT = 'EMAIL_LAYOUT',
  USER = 'USER',
  PORTFOLIO = 'PORTFOLIO',
  CHARGE_ENTRY = 'CHARGE_ENTRY',
  RECURRING_CHARGE_ENTRY = 'RECURRING_CHARGE_ENTRY',
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  CREDIT_NOTE = 'CREDIT_NOTE',
  TRUST_JOURNAL_ENTRY = 'TRUST_JOURNAL_ENTRY',
  RECONCILIATION = 'RECONCILIATION',
  DISBURSEMENT = 'DISBURSEMENT',
  OWNERSHIP_RECORD = 'OWNERSHIP_RECORD',
  CONTACT_RECORD = 'CONTACT_RECORD',
  STATIC_REPORTS = 'STATIC_REPORTS',
  HMRC_REPORTS_AND_SUBMISSIONS = 'HMRC_REPORTS_AND_SUBMISSIONS',
  HMRC_REPORTS_AND_SUBMISSIONS_DETAILS = 'HMRC_REPORTS_AND_SUBMISSIONS_DETAILS',
  BATCH_RECEIPTING = 'BATCH_RECEIPTING'
}

export type BreadcrumbArgs = {
  id?: string;
  parentId?: string;
  label?: string;
  link?: any;
  [key: string]: any;
};

export interface BreadcrumbDeclaration {
  getLabel: (args: BreadcrumbArgs) => string;
  getLink: (args: BreadcrumbArgs) => LinkProps;
}

export interface BreadcrumbMap {
  [key: string]: BreadcrumbDeclaration;
}

export const useBreadcrumbMap = () => {
  const { data } = useCustomTaskTemplates();

  const baseMap = { ...MAP };

  // Inject entries for custom task types so they can be invoked by custom task template id
  if (data) {
    data.forEach((template) => {
      baseMap[template.id] = {
        getLabel: () => template.label,
        getLink: () => ({
          to: ROUTES.CUSTOM_TASKS_LIST,
          hash: 'type=' + template.id
        })
      };
    });
  }

  return baseMap;
};

const MAP: BreadcrumbMap = {
  [BREADCRUMBS.TASK_SETTINGS]: {
    getLabel: () => 'Task Settings',
    getLink: () => ({ to: ROUTES.TASK_SETTINGS_DETAILS })
  },

  [BREADCRUMBS.STATIC_REPORTS]: {
    getLabel: () => 'Table reports',
    getLink: () => ({
      to: ROUTES.REPORTING_LIST,
      query: { tab: 'table-reports' }
    })
  },

  [BREADCRUMBS.WORK_ORDERS]: {
    getLabel: () => 'Work orders',
    getLink: () => ({ to: ROUTES.WORK_ORDERS_LIST })
  },

  [BREADCRUMBS.QUOTE]: {
    getLabel: () => 'Quotes',
    getLink: () => ({ to: ROUTES.QUOTE_LIST })
  },

  [BREADCRUMBS.CUSTOM_TASKS]: {
    getLabel: () => 'Custom tasks',
    getLink: () => ({ to: ROUTES.CUSTOM_TASKS_LIST_ALL })
  },

  [BREADCRUMBS.CUSTOM_REPORT]: {
    getLabel: () => 'Custom reports',
    getLink: () => ({ to: ROUTES.CUSTOM_REPORT_LIST })
  },

  [BREADCRUMBS.ARREARS]: {
    getLabel: () => 'Rent arrears',
    getLink: () => ({ to: ROUTES.ARREARS_LIST })
  },

  [BREADCRUMBS.INSPECTION_TASKS]: {
    getLabel: () => 'Inspections',
    getLink: () => ({ to: ROUTES.INSPECTION_TASK_LIST })
  },

  [BREADCRUMBS.MESSAGE]: {
    getLabel: () => 'Message center',
    getLink: () => ({ to: ROUTES.MESSAGE_LIST })
  },

  [BREADCRUMBS.MESSAGE_TEMPLATE]: {
    getLabel: () => 'Message templates',
    getLink: () => ({ to: ROUTES.MESSAGE_TEMPLATE_LIST })
  },

  [BREADCRUMBS.TODO]: {
    getLabel: () => 'To-dos',
    getLink: () => ({ to: ROUTES.TODO_LIST })
  },

  [BREADCRUMBS.TASK_MAINTENANCE]: {
    getLabel: () => 'Maintenance',
    getLink: () => ({ to: ROUTES.MAINTENANCE_TASKS_LIST })
  },

  [BREADCRUMBS.TASK_PROPERTY_COMPLIANCE]: {
    getLabel: () => 'Property compliance',
    getLink: () => ({ to: ROUTES.PROPERTY_COMPLIANCE_TASKS_LIST })
  },

  [BREADCRUMBS.TASK_SUPPLIER_COMPLIANCE]: {
    getLabel: () => 'Supplier compliance',
    getLink: () => ({ to: ROUTES.SUPPLIER_COMPLIANCE_TASKS_LIST })
  },

  [BREADCRUMBS.LEASE_REVIEW]: {
    getLabel: () => i18n.t('tasks.lease-review.label.plural', 'Lease reviews'),
    getLink: () => ({ to: ROUTES.LEASE_REVIEW_LIST })
  },

  [BREADCRUMBS.MOVE_IN]: {
    getLabel: () => i18n.t('tasks.move-in.title', 'Move in'),
    getLink: () => ({ to: ROUTES.MOVE_IN_LIST })
  },

  [BREADCRUMBS.MOVE_OUT]: {
    getLabel: () => 'Move out',
    getLink: () => ({ to: ROUTES.MOVE_OUT_LIST })
  },

  [BREADCRUMBS.CONTACT]: {
    getLabel: () => 'Contacts',
    getLink: () => ({ to: ROUTES.CONTACTS_LIST })
  },

  [BREADCRUMBS.TENANCY]: {
    getLabel: () => 'Tenancies',
    getLink: () => ({ to: ROUTES.TENANCIES_LIST })
  },

  [BREADCRUMBS.PROPERTY]: {
    getLabel: () => 'Properties',
    getLink: () => ({ to: ROUTES.PROPERTIES_LIST })
  },

  [BREADCRUMBS.OWNERSHIP]: {
    getLabel: () => 'Ownerships',
    getLink: () => ({ to: ROUTES.OWNERSHIPS_LIST })
  },

  [BREADCRUMBS.INVOICE]: {
    getLabel: () => 'Invoices',
    getLink: () => ({ to: ROUTES.INVOICES_LIST })
  },

  [BREADCRUMBS.TRUST_LEDGER]: {
    getLabel: () =>
      i18n.t('financials.trust.trust-ledgers.label.plural', 'Trust ledgers'),
    getLink: () => ({ to: ROUTES.TRUST_LEDGERS })
  },

  [BREADCRUMBS.PROPERTY_TENANCY_DETAIL]: {
    getLabel: ({ label }) => {
      return label as string;
    },
    getLink: ({ id, parentId }) =>
      getRecordLinkProps({
        type: 'property_tenancy',
        id: id!,
        parentId: parentId
      })!
  },

  [BREADCRUMBS.BANK_DEPOSIT]: {
    getLabel: () => 'Bank deposits',
    getLink: () => ({ to: ROUTES.BANK_DEPOSITS_LIST })
  },

  [BREADCRUMBS.USER]: {
    getLabel: () => 'Users',
    getLink: () => ({ to: ROUTES.USERS_LIST })
  },

  [BREADCRUMBS.USER_GROUP]: {
    getLabel: () => 'User groups',
    getLink: () => ({ to: ROUTES.USER_GROUP_LIST })
  },

  [BREADCRUMBS.ROLE]: {
    getLabel: () => 'Roles',
    getLink: () => ({ to: ROUTES.ROLES_LIST })
  },

  [BREADCRUMBS.BANK_ACCOUNT]: {
    getLabel: () => 'Bank accounts',
    getLink: () => ({ to: ROUTES.BANK_ACCOUNTS })
  },

  [BREADCRUMBS.CREDIT_NOTE]: {
    getLabel: () => 'Credit notes',
    getLink: () => ({ to: ROUTES.CREDIT_NOTES_LIST })
  },

  [BREADCRUMBS.TRUST_JOURNAL_ENTRY]: {
    getLabel: () => i18n.t('trust-journal-entries.label.plural'),
    getLink: () => ({ to: ROUTES.TRUST_JOURNAL_ENTRIES })
  },

  [BREADCRUMBS.BANK_WITHDRAWAL]: {
    getLabel: () => 'Bank withdrawals',
    getLink: () => ({ to: ROUTES.BANK_WITHDRAWALS_LIST })
  },

  [BREADCRUMBS.RECONCILIATION]: {
    getLabel: () => 'Reconciliations',
    getLink: () => ({ to: ROUTES.RECONCILIATIONS })
  },

  [BREADCRUMBS.DISBURSEMENT]: {
    getLabel: () => i18n.t('disbursements.list-screen.title'),
    getLink: () => ({ to: ROUTES.DISBURSEMENT_LIST })
  },

  [BREADCRUMBS.OWNERSHIP_RECORD]: {
    getLabel: ({ label }) => label!,
    getLink: ({ id }) => ({ to: ROUTES.OWNERSHIP, params: { ownershipId: id } })
  },

  [BREADCRUMBS.CONTACT_RECORD]: {
    getLabel: ({ label }) => label!,
    getLink: ({ id }) => ({ to: ROUTES.CONTACT, params: { contactId: id } })
  },

  [BREADCRUMBS.PORTFOLIO]: {
    getLabel: () => 'Portfolios',
    getLink: () => ({ to: ROUTES.PORTFOLIOS_LIST })
  },

  [BREADCRUMBS.PORTFOLIO_ROLE]: {
    getLabel: () => 'Portfolio roles',
    getLink: () => ({ to: ROUTES.PORTFOLIO_ROLE_LIST })
  },

  [BREADCRUMBS.HMRC_REPORTS_AND_SUBMISSIONS]: {
    getLabel: () => 'HMRC Reports and Submissions',
    getLink: () => ({ to: ROUTES.HMRC_REPORTS_AND_SUBMISSIONS_LIST })
  },

  [BREADCRUMBS.HMRC_REPORTS_AND_SUBMISSIONS_DETAILS]: {
    getLabel: ({ label }) => label!,
    getLink: ({ id }) => ({
      to: ROUTES.HMRC_REPORTS_AND_SUBMISSIONS_DETAILS,
      params: { id }
    })
  },

  [BREADCRUMBS.BATCH_RECEIPTING]: {
    getLabel: () => 'Batch Receipting',
    getLink: ({ bankAccountId }) => {
      if (!bankAccountId) {
        return { to: ROUTES.BATCH_RECEIPTING_LIST_SCREEN };
      }

      return {
        to: ROUTES.BATCH_RECEIPTING_LIST_SCREEN,
        query: { bankAccount: bankAccountId }
      };
    }
  }
};
