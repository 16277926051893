import { Generator } from 'data/models/generator';
import { auditableConfig } from 'src/modules/common/auditable/auditable-config';
import _ from 'lodash';
import { api } from 'utils/api/api-client';
import { CustomTaskTemplate } from '../types/CustomTaskTemplate';

const config = {
  entities: {
    related: {
      ...auditableConfig,

      task_summary: {
        include: 'task_summary'
      },

      custom_fields: {
        include: 'custom_fields'
      },

      custom_field_values: {
        include: 'custom_field_values'
      },

      type: {
        include: 'type'
      }
    }
  }
};

const actionCreators = {
  archiveItem: {
    request: ({
      customTaskTemplate
    }: {
      customTaskTemplate: CustomTaskTemplate;
    }) =>
      api.patch(
        `/tasks/custom-task-templates/${customTaskTemplate.id}/archive`
      ),
    reduce: _.identity
  },
  unarchiveItem: {
    request: ({
      customTaskTemplate
    }: {
      customTaskTemplate: CustomTaskTemplate;
    }) =>
      api.patch(
        `/tasks/custom-task-templates/${customTaskTemplate.id}/unarchive`
      ),
    reduce: _.identity
  }
};

export const customTaskTemplatesModel = new Generator<
  CustomTaskTemplate,
  typeof actionCreators
>('tasks/custom-task-templates', config).createEntityModel({ actionCreators });
