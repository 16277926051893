import { DialogProps } from '@rexlabs/dialog';
import * as React from 'react';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { TaskType } from '../../common/types/TaskType';
import { inspectionTypeBlock } from '../blocks/inspection-type-block';
import { upsertChecklistDetailsBlockV2 } from '../blocks/upsert-checklist-template-details-block-v2';
import { useCreateChecklistTemplate } from '../hooks/use-create-checklist-template';

export interface SmartChecklistTemplateDialogProps extends DialogProps {
  title: string;
  taskTypeId: TaskType;
  customTaskTypeId?: string;
}

function getContent(taskType: TaskType) {
  return [
    {
      id: 'checklist-template',
      label: 'Add checklist template',
      blocks: [
        upsertChecklistDetailsBlockV2,
        ...(taskType === 'task_inspection' ? [inspectionTypeBlock] : [])
      ]
    }
  ];
}

export function SmartChecklistTemplateDialog({
  onClose,
  taskTypeId,
  title,
  customTaskTypeId
}: SmartChecklistTemplateDialogProps) {
  const onSubmit = useCreateChecklistTemplate(taskTypeId, customTaskTypeId);
  const content = getContent(taskTypeId);

  return (
    <RecordDialog
      title={title}
      content={content}
      onClose={onClose}
      submitLabel='Continue'
      handleSubmit={onSubmit}
    />
  );
}
