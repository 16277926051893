import { Columns } from 'src/view/components/table';
import { PrepaymentBucket } from 'src/modules/prepayments/types/prepayment-bucket';
import { LargeDescriptionCell } from 'view/components/cells/large-description-cell';
import React from 'react';
import { PrepaymentBucketNameCell } from 'src/modules/prepayments/components/prepayment-bucket-name-cell';

export const prepaymentsColumns: Columns<PrepaymentBucket> = [
  {
    id: 'name',
    Header: 'Bucket name',
    Cell: ({ value, cell }) => (
      <PrepaymentBucketNameCell
        text={value}
        systemPurpose={cell.row.original?.system_purpose}
      />
    )
  },
  {
    id: 'chart_of_accounts_accounts',
    Header: 'Pay to chart of account(s)',
    Cell: ({ value }) => <LargeDescriptionCell text={value} />,
    accessor: (bucket) =>
      bucket.chart_of_accounts_accounts?.data
        ?.map(({ name }) => name)
        .join(', ') || 'n/a'
  },
  {
    id: 'auto_allocate_funds',
    Header: 'Automatic payment',
    type: 'boolean'
  },
  {
    id: 'reference_code',
    Header: 'Reference',
    accessor: (bucket) => bucket.reference_code
  }
];
