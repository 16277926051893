import { useDialog } from '@rexlabs/dialog';
import React from 'react';
import { usePermission } from 'src/modules/authorization/roles/hooks/use-permission';
import {
  CreateInvoiceDialog,
  CreateInvoiceDialogProps
} from '../dialogs/create-invoice-dialog';
import { AccessDeniedInvoiceDialog } from '../dialogs/access-denied-invoice-dialog';

export function useCreateInvoiceDialog() {
  const createInvoiceDialog = useDialog(CreateInvoiceDialog);
  const accessDeniedDialog = useDialog(AccessDeniedInvoiceDialog);
  const { isStandardUser, roleName } = usePermission();

  const handleDialog = React.useCallback(
    (invoiceDialogProps: CreateInvoiceDialogProps) => {
      if (isStandardUser) {
        return accessDeniedDialog.open({
          role: roleName
        });
      }

      createInvoiceDialog.open(invoiceDialogProps);
    },
    [isStandardUser, roleName]
  );

  return handleDialog;
}
