import Box from '@rexlabs/box';
import { Label } from '@rexlabs/text';
import React from 'react';
import { FroalaReadOnly } from 'view/components/@luna/inputs/froala-editor/components/froala-read-only';

export function CustomFieldViewField({ field, value }) {
  // TODO: add more renderers here when more field types are supported.
  return (
    <Box>
      <Label>{field.label}</Label>
      <FroalaReadOnly value={value} />
    </Box>
  );
}
