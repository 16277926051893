import { DeferredFeeLineItem } from 'src/modules/deferred-fees/types/DeferredFeeLineItem';
import { getDeferredFeesColumns } from 'src/modules/deferred-fees/utils/get-deferred-fees-columns';

import { Columns } from 'view/components/table';

export const agencyFeeLedgerColumns: Columns<DeferredFeeLineItem> = getDeferredFeesColumns(
  [
    'creation',
    'summary_description',
    'payable_by_property',
    'amount',
    'billed_on',
    'status'
  ]
);
