import React, { useCallback, useMemo } from 'react';

import { Message } from '@rexlabs/notifications';
import { GlobalFilter } from '@rexlabs/table/lib/types/context';

import EmptyRecord from 'src/assets/illustrations/empty-record.svg';

import { BlockConfig } from 'view/components/record-screen/types';
import { ActionButtons } from 'view/components/@luna/action-buttons';

import { SearchResultItem } from 'utils/api/get-search-results';
import { Ownership } from 'data/models/entities/ownerships';
import { Tenancy } from 'data/models/entities/tenancies';
import { getInvoicesQuery } from '../data/queries';
import { InvoicesList } from '../components/invoices-list';
import { InitialInvoiceValues } from '../dialogs/create-invoice-dialog';
import { InvoiceFilterAction } from '../utils/use-invoice-filters';
import { useCreateInvoiceDialog } from '../hooks/use-create-invoice-dialog';

export type GetProps<Data = any> = (options: {
  data?: Data;
}) => {
  getForcedGlobalFilter: (id: string) => GlobalFilter[];
  getInitialGlobalFilter?: () => GlobalFilter[];
  initialValues?: InitialInvoiceValues;
  hashParamKey?: string;
  refreshRecord?: () => Promise<any>;
  onSave?: () => Promise<any>;
  showEmpty?: (data: Data) => boolean;
  emptyMessage?: string;
  suggestions?: {
    payableTo?: Array<SearchResultItem<Ownership | Tenancy>>;
    payableBy?: Array<SearchResultItem<Ownership | Tenancy>>;
  };
  invoiceActions?: InvoiceFilterAction[];
  invoiceFilters?: GlobalFilter[];
};

export function getInvoicesPayableByBlock<Data = any>(
  getProps: GetProps<Data>
): BlockConfig<Data, any, any> {
  return {
    id: 'payable-invoices',
    title: 'Bills payable',
    Actions: ({ data }) => {
      const {
        initialValues,
        refreshRecord,
        onSave,
        suggestions,
        invoiceActions
      } = useMemo(() => getProps({ data }), [data]);
      const openInvoiceDialog = useCreateInvoiceDialog();

      return (
        <ActionButtons
          actions={[
            ...(invoiceActions || []),
            {
              label: 'Create invoice',
              handleAction: () => {
                openInvoiceDialog({
                  initialValues,
                  suggestions,
                  onSave: () => onSave?.() || refreshRecord?.()
                });
              }
            }
          ]}
        />
      );
    },
    View: ({ data }) => {
      const {
        getForcedGlobalFilter,
        getInitialGlobalFilter,
        hashParamKey,
        refreshRecord,
        invoiceFilters
      } = useMemo(() => getProps({ data }), [data]);

      const getQuery = useCallback(() => getInvoicesQuery(), []);
      const id = data!['id'];
      const combinedFilter = [
        ...getForcedGlobalFilter(id),
        ...(invoiceFilters || [])
      ];

      return (
        <InvoicesList
          shouldPersistTableSettings={false}
          getQuery={getQuery}
          forcedGlobalFilter={combinedFilter}
          initialGlobalFilter={getInitialGlobalFilter?.()}
          hashParamKey={hashParamKey}
          refreshParentEntityAction={refreshRecord}
        />
      );
    },
    showEmpty: (data) => {
      const { showEmpty } = getProps({ data });
      return showEmpty ? !!showEmpty?.(data) : false;
    },
    Empty: ({ data }) => {
      const {
        initialValues,
        refreshRecord,
        emptyMessage,
        onSave,
        suggestions
      } = useMemo(() => getProps({ data }), [data]);
      const openInvoiceDialog = useCreateInvoiceDialog();

      return (
        <Message
          title='No bills have been added'
          Illustration={EmptyRecord}
          actions={[
            {
              label: 'Create invoice',
              type: 'primary',
              handleAction: () => {
                openInvoiceDialog({
                  initialValues,
                  suggestions,
                  onSave: () => onSave?.() || refreshRecord?.()
                });
              }
            }
          ]}
        >
          {emptyMessage}
        </Message>
      );
    }
  };
}
