import { ContentConfig } from 'view/components/record-screen/types';
import { userGroupDetailsBlock } from '../blocks/user-group-details-block';
import { userGroupMembersBlock } from '../blocks/user-group-members-block';

export const content: ContentConfig = [
  {
    label: 'General',
    items: [
      {
        id: 'overview',
        label: 'Overview',
        blocks: [userGroupDetailsBlock, userGroupMembersBlock]
      }
    ]
  }
];
