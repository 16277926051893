import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';
import Box from '@rexlabs/box';
import { Value } from 'view/components/values';
import { Field } from '@rexlabs/form';
import { useToken } from '@rexlabs/styling';
import { Column, Grid } from 'view/components/@luna/form/grid';
import { SupplierComplianceTask } from '../types/SupplierComplianceTask';
import { ManagedBySelect } from '../../common/components/managed-by-select';
import { getLabelForManagedBy } from '../../common/utils/get-label-for-managed-by';

const validate = {
  definitions: {
    status: { name: 'progress', rules: 'required' },
    managed_by: { name: 'managed by', rules: 'required' }
  }
};

export const supplierComplianceDetailsBlock: BlockConfig<SupplierComplianceTask> = {
  id: 'details',
  title: 'Details',
  validate,
  View: ({ data }) => {
    const supplier =
      data?.details?.supplier || data?.details?.compliance_entry?.supplier;
    const complianceTypeName =
      data?.details?.compliance_type?.label ||
      data?.details?.compliance_entry?.compliance_type?.label;
    return (
      <Grid columns={2}>
        <Value label='Compliance requirement' value={complianceTypeName} />
        <Column width={2}>
          <Value label='Description' value={data?.description} />
        </Column>

        <Value label='Supplier' value={supplier?.display_name} />
        {/* div supplies the content for the column next to property */}
        <div />
        <Value
          label='Managed by'
          value={getLabelForManagedBy(data?.managed_by)}
        />
      </Grid>
    );
  },
  Edit: ({ data }) => {
    const token = useToken();
    const supplier =
      data?.details?.supplier || data?.details?.compliance_entry?.supplier;
    const complianceTypeName =
      data?.details?.compliance_type?.label ||
      data?.details?.compliance_entry?.compliance_type?.label;
    return (
      <Box sy={token('spacing.xxl')}>
        <Grid columns={2}>
          <Value
            label='Compliance requirement'
            value={complianceTypeName || null}
          />

          <Column width={2}>
            <Value label='Description' value={data?.description} />
          </Column>
        </Grid>
        <Grid columns={2}>
          <Value label='Supplier' value={supplier?.display_name} />
          <div />
          <Field
            id='managed_by'
            name='managed_by'
            label='Managed by'
            Input={ManagedBySelect}
          />
        </Grid>
      </Box>
    );
  }
};
