import { Includes } from '@rexlabs/model-generator';
import {
  BaseFields,
  RecordTypes,
  TimestampAttributes,
  ValueListValue
} from 'data/models/types';

export type RoleSystemPurpose = 'admin' | 'financial' | 'standard';

export const Roles = {
  Admin: 'admin',
  Standard: 'standard',
  Financial: 'financial'
} as const;

export type PrivilegeEntry = {
  id: string;
  privilege_id: string;
  privilege_set_id: string | null;
  privilege_set: ValueListValue<string> | null;
};

export interface Role
  extends BaseFields<typeof RecordTypes.Role>,
    TimestampAttributes {
  id: string;
  name: string;
  description: string;
  system_purpose: ValueListValue<RoleSystemPurpose> | null;
  privilege_entries?: Includes<PrivilegeEntry[]>;
  privileges?: string[];
}
