import React from 'react';
import { PropertyOwnership } from 'src/modules/property-ownerships/types/property-ownership-types';
import { SecondaryList } from '../components/secondary-list';
import { EmptyPropertyOwnership } from '../empty/empty-property-ownership';
import { getOwnershipObjects } from '../utils';
import { OwnershipEntries } from './ownership-entries';

type PropertyOwnershipSegment = PropertyOwnership[] | 'empty';

export interface PropertyOwnershipSectionProps {
  activePropertyOwnerships: PropertyOwnershipSegment;
  incomingPropertyOwnerships: PropertyOwnershipSegment;
  outgoingPropertyOwnerships: PropertyOwnershipSegment;
  draftPropertyOwnerships: PropertyOwnershipSegment;
  maxListLength: number;
}

export function PropertyOwnershipSection({
  activePropertyOwnerships: active,
  incomingPropertyOwnerships: incoming,
  outgoingPropertyOwnerships: outgoing,
  draftPropertyOwnerships: draft,
  maxListLength
}: PropertyOwnershipSectionProps) {
  const noActiveOwnership = active === 'empty';
  const noIncomingOwnership = incoming === 'empty';
  const noDraftOwnership = draft === 'empty';
  const noOutgoingOwnership = outgoing === 'empty';

  if (
    noActiveOwnership &&
    noIncomingOwnership &&
    noDraftOwnership &&
    noOutgoingOwnership
  ) {
    return <EmptyPropertyOwnership />;
  }

  return (
    <SecondaryList>
      {!noActiveOwnership &&
        active.map((ownership) => (
          <OwnershipEntries
            {...getOwnershipObjects(ownership)}
            key={ownership.id}
            headline='Active'
            maxListLength={maxListLength}
            status='active'
          />
        ))}

      {!noIncomingOwnership &&
        incoming.map((ownership) => (
          <OwnershipEntries
            {...getOwnershipObjects(ownership)}
            key={ownership.id}
            headline='Incoming'
            maxListLength={maxListLength}
            status='incoming'
          />
        ))}

      {!noOutgoingOwnership &&
        outgoing.map((ownership) => (
          <OwnershipEntries
            {...getOwnershipObjects(ownership)}
            key={ownership.id}
            headline='Outgoing'
            maxListLength={maxListLength}
            status='outgoing'
          />
        ))}

      {!noDraftOwnership &&
        draft.map((ownership) => (
          <OwnershipEntries
            {...getOwnershipObjects(ownership)}
            key={ownership.id}
            headline='Draft'
            maxListLength={maxListLength}
            status='draft'
          />
        ))}
    </SecondaryList>
  );
}
