import * as React from 'react';

import { toQuri } from '@rexlabs/quri';
import { query, BaseModelGeneratorModel } from '@rexlabs/model-generator';

import { RecordTable } from 'view/components/table';
import { BlockConfig } from 'view/components/record-screen/types';

import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import ROUTES from 'routes/app';
import {
  Disbursement,
  financialsDisbursementsModel
} from 'data/models/entities/financials/disbursements/disbursements';

import { Trans } from 'react-i18next';
import { useGetDisbursementActionsForBlock } from '../hooks/use-get-disbursement-actions-for-block';
import { completedDisbursementColumnsForBlock } from '../utils/completed-disbursement-columns';

const getFilteredQuery = (filter: string) => query`{
  ${financialsDisbursementsModel} (o: "created_at+desc", q: ${filter}) {
    id
    object
    payment_methods
    ownership {
      owners
    }
    contact
    disbursement_snapshot_summary
  }
}`;

const getQuery = (filterKey, id) => {
  let quris;
  if (!id) {
    quris = [];
  }

  quris = [
    {
      field: 'object_type',
      op: '==',
      value: filterKey
    },
    {
      field: 'object_id',
      op: '==',
      value: id
    }
  ];

  return getFilteredQuery(toQuri(quris));
};

export interface GetNoteTableBlockArgs {
  type: 'contact' | 'ownership' | 'agency';
}

function getCompletedDisbursementRowLinkProps({ item }) {
  return {
    to: ROUTES.COMPLETED_DISBURSEMENT,
    params: { completedDisbursementId: item.id }
  };
}

export function getDisbursementTableBlock<T extends BaseModelGeneratorModel>({
  type
}: GetNoteTableBlockArgs): BlockConfig<T> {
  return {
    id: 'completed-disbursements',
    title: () => (
      <Trans i18nKey='disbursements.list-screen.tabs.completed-disbursements.label' />
    ),
    View: ({ data }) => {
      const getDisbursementActions = useGetDisbursementActionsForBlock();

      const getActionMenuItems = ({ item }) =>
        transformActionDeclarationsToActionMenuItems(
          getDisbursementActions(item as Disbursement)
        );

      const dataQuery = React.useMemo(() => getQuery(type, data?.id), [
        data?.id
      ]);

      return (
        <RecordTable
          withPagination
          getRowLinkProps={getCompletedDisbursementRowLinkProps}
          id='completed-disbursements-list'
          columns={completedDisbursementColumnsForBlock}
          getQuery={() => dataQuery}
          getActionMenuItems={getActionMenuItems}
        />
      );
    }
  };
}
