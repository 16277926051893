import React from 'react';
import { useConfirmationDialog, useErrorDialog } from '@rexlabs/dialog';
import { useMutation, useQueryClient } from 'react-query';
import { useToast } from 'view/components/@luna/notifications/toast';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { api } from 'utils/api/api-client';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'routes/app';
import { CustomValidation } from 'src/modules/custom-validations/types/CustomValidation';
import { CUSTOM_VALIDATIONS_QUERY_KEY } from 'src/modules/custom-validations/hooks/use-custom-validations';

type UseGetDeleteCustomValidationActionArgs = {
  redirectToListScreen?: boolean;
};

export function useGetDeleteCustomValidationAction({
  redirectToListScreen
}: UseGetDeleteCustomValidationActionArgs = {}) {
  const { open: openConfirmationDialog } = useConfirmationDialog();
  const { open: openErrorDialog } = useErrorDialog();

  const { addToast } = useToast();
  const queryClient = useQueryClient();

  const deleteCustomValidationMutation = useMutation(
    (id: string) => api.delete(`/custom-validations/${id}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CUSTOM_VALIDATIONS_QUERY_KEY);
      }
    }
  );

  return (customValidation: CustomValidation): ActionDeclaration => {
    return {
      id: 'delete-custom-validation',
      group: 'delete',
      label: 'Delete',
      intent: 'danger',
      handleAction: () =>
        openConfirmationDialog({
          title: 'Delete Custom Validation',
          destructive: true,
          size: 's',
          confirmText: 'Delete',
          message: (
            <div>
              Are you sure you wish to delete the custom validation{' '}
              <strong>{customValidation.name}</strong>?
            </div>
          ),
          onConfirm: async () => {
            try {
              await deleteCustomValidationMutation.mutateAsync(
                customValidation.id
              );

              addToast({
                type: 'success',
                description: (
                  <>
                    <b>{customValidation.name}</b> has been deleted
                  </>
                )
              });

              if (redirectToListScreen) {
                push(ROUTES.CUSTOM_VALIDATION_LIST);
              }
            } catch (e) {
              openErrorDialog(e);
            }
          }
        })
    };
  };
}
