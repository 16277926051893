import * as React from 'react';

import { Card } from 'view/components/card';
import { ListScreen } from 'view/components/list-screen/list-screen';
import { useGetUploadBillsAction } from '../hooks/action-declarations/use-get-upload-bills-action';
import { BillProcessingListTable } from '../components/bill-processing-list-table';

export function BillProcessingList() {
  const uploadBillsAction = useGetUploadBillsAction();

  return (
    <ListScreen
      privilege={'uploaded-bills.read'}
      title='Bill processing'
      actions={[uploadBillsAction]}
    >
      <Card>
        <BillProcessingListTable />
      </Card>
    </ListScreen>
  );
}
