export const ROLE_ROUTES = {
  ROLES_LIST: {
    config: {
      path: '/roles',
      Component: require('./screens/role-list.tsx').RoleList
    }
  },
  ROLE_DETAILS: {
    config: {
      path: '/role/:roleId',
      Component: require('./screens/role-details.tsx').RoleDetails
    }
  }
};
