import React, { useMemo, Fragment } from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, text, useStyles } from '@rexlabs/styling';

import ChevronRightIcon from 'view/components/icons/chevron-right';

import { Breadcrumb } from '@rexlabs/whereabouts';
import { useBreadcrumbMap, BreadcrumbArgs } from './constants';
import { Crumb } from './crumb';

const defaultStyles = StyleSheet({
  container: {
    ...text.styles({
      fallback: 'small.semibold'
    }),

    color: ({ token }) => token('color.textStyle.body.default'),

    '& a': {
      textDecoration: 'none',
      color: 'inherit',

      '&:hover': {
        textDecoration: 'underline',
        color: 'inherit'
      }
    }
  },

  spacer: {
    display: 'flex',
    padding: ({ token }) => `0 ${token('spacing.xs')}`
  }
});

export interface BreadcrumbsProps {
  breadcrumbs?: (Breadcrumb & { args?: BreadcrumbArgs })[];
}

export function Breadcrumbs({ breadcrumbs }: BreadcrumbsProps) {
  const s = useStyles(defaultStyles);
  const MAP = useBreadcrumbMap();

  const crumbs = useMemo(
    () =>
      breadcrumbs
        ?.map?.(({ type, args }) => {
          const breadcrumbDefinition = MAP[type];
          if (!breadcrumbDefinition) return undefined;

          return {
            ...breadcrumbDefinition,
            args
          };
        })
        ?.filter?.(Boolean),
    [MAP, breadcrumbs]
  );

  if (!crumbs?.length) {
    return null;
  }

  return (
    <Box {...s('container')} flexDirection='row' alignItems='center'>
      {crumbs.map((props, i) => (
        <Fragment key={i}>
          <Crumb {...props!} />
          <span {...s('spacer')}>
            <ChevronRightIcon />
          </span>
        </Fragment>
      ))}
    </Box>
  );
}
