import { useModelActions } from '@rexlabs/model-generator';
import { Tenancy, tenancyModel } from 'data/models/entities/tenancies';
import { useDeleteAction } from 'src/modules/common/actions/delete/use-delete-action';
import { useEditRecordDetailsAction } from 'src/modules/common/actions/record-details/hooks/use-edit-record-details-action';
import { useGetCreateEmailAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-email-action';
import { useGetFinancialEntityActionGroup } from 'src/modules/financials/utils/financial-entity-action-group/use-get-financial-entity-actions';
import { useGetCreateNoteAction } from 'src/modules/note/hooks/use-get-create-note-action';
import { useGetCreatePropertyTenancyAction } from 'src/modules/property-tenancies/actions/use-get-create-property-tenancy-action';
import { useGetAccountingEntityActionGroup } from 'src/modules/trust-journal-entries/utils/use-get-accounting-entity-action-group';
import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import { useGetCreateSMSAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-sms-action';
import { queryClient } from 'src/lib/react-query/query-client';
import { trustJournalEntryLineItemModel } from 'src/modules/trust-journal-entry-line-items/models/trust-journal-entry-line-item-model';
import { useGetCreateTodoAction } from 'src/modules/tasks/todos/hooks/action-declarations/use-get-create-todo-action';
import { getSearchResultItemFromObjectAndModel } from 'src/modules/common/utils/search-result-items/get-search-result-items-from-object-and-model';
import { tenancyIncludes } from '../screens/tenancy-details';

export function useGetTenancyActions() {
  const { refreshItem } = useModelActions(tenancyModel);
  const { refreshLists } = useModelActions(trustJournalEntryLineItemModel);

  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();
  const getCreatePropertyTenancyAction = useGetCreatePropertyTenancyAction();
  const getFinancialEntityActionGroup = useGetFinancialEntityActionGroup();
  const getAccountingEntityActionGroup = useGetAccountingEntityActionGroup();
  const getEditRecordDetails = useEditRecordDetailsAction(tenancyModel);
  const getDeleteRecordAction = useDeleteAction(tenancyModel);
  const getCreateNoteAction = useGetCreateNoteAction(tenancyModel);
  const getCreateEmailAction = useGetCreateEmailAction(tenancyModel);
  const getCreateSMSAction = useGetCreateSMSAction(tenancyModel);
  const getCreateTodoAction = useGetCreateTodoAction();

  return (tenancy?: Tenancy) => {
    if (!tenancy) return [];

    return [
      ...getPrimaryRecordActionGroup('tenancy', tenancy.id),
      ...getFinancialEntityActionGroup({
        actionArgs: {
          recordTypeName: 'tenancy',
          entity: tenancy,
          onCreate: async () => {
            await queryClient.invalidateQueries([
              'prepayment-bucket',
              tenancy?.id
            ]);

            refreshItem({
              id: tenancy.id,
              args: { include: tenancyIncludes }
            });
            refreshLists();
          }
        },
        receiptFundsActionArgs: {
          receiptFrom: tenancy.tenants?.map((tenant) => tenant.contact)
        }
      }),
      getEditRecordDetails(tenancy),
      getCreateTodoAction({
        initialValues: {
          relates_to: getSearchResultItemFromObjectAndModel(
            tenancy,
            tenancyModel
          )
        }
      }),
      getCreateNoteAction({ attached: tenancy }),
      getCreateEmailAction(tenancy),
      getCreateSMSAction(tenancy),
      getCreatePropertyTenancyAction({ tenancy }),
      ...getAccountingEntityActionGroup(),
      getDeleteRecordAction(tenancy)
    ];
  };
}
