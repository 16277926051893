import React from 'react';
import dayjs from 'dayjs';
import { LinkButton } from '@rexlabs/button';
import { ChecklistItem } from '../../common/types/Checklist';
import { ChecklistItemStatus } from '../types/checklist-item-status';
import { getItemStatus } from './get-item-status';

export function generateSubtextForItem(
  item: ChecklistItem,
  onClickHandler: () => void,
  displayTimezone: string
): React.ReactNode | null {
  const status = getItemStatus(item);
  const templateName = item.action_message_template?.name;
  const triggeredAt = item.triggered_at;
  const triggerAt = item.trigger_at;
  const timeHour = item.trigger_data?.time_hour;

  const actionId = item.action_type?.id;
  const isMessageAction = ['send_email', 'send_sms', 'send_message'].includes(
    actionId ?? ''
  );

  const linkButtonStyle = {
    fontSize: '1.2rem',
    textDecoration: 'underline'
  };

  switch (status) {
    case ChecklistItemStatus.CAPTURES_OUTCOMES: {
      return (
        <>More subtasks may be added based on the outcome of this subtask</>
      );
    }
    case ChecklistItemStatus.HAS_OUTCOME: {
      const outcomeOptions = item.outcome_data?.outcome_options;
      const outcomeValueId = item.outcome_data?.outcome_value_id;
      const selectedOutcome = outcomeOptions?.find(
        (option) => option.id === outcomeValueId
      );
      return (
        <>
          Outcome is &quot;<u>{selectedOutcome?.label}</u>&quot;
        </>
      );
    }
    case ChecklistItemStatus.IS_SENDING_NOW: {
      return (
        <>
          <LinkButton style={linkButtonStyle} onClick={onClickHandler}>
            {templateName}
          </LinkButton>{' '}
          is sending now
        </>
      );
    }
    case ChecklistItemStatus.WAS_SENT_AT_TIME: {
      return (
        <>
          <LinkButton style={linkButtonStyle} onClick={onClickHandler}>
            {templateName}
          </LinkButton>{' '}
          was sent at {dayjs(triggeredAt).tz(displayTimezone).format('h:mma')}{' '}
          on {dayjs(triggeredAt).tz(displayTimezone).format('DD MMM YYYY')}
        </>
      );
    }
    case ChecklistItemStatus.WILL_TRIGGER_ON_COMPLETION: {
      const actionText = getPendingActionText(item, onClickHandler);
      return <>{actionText} when this subtask is completed</>;
    }
    case ChecklistItemStatus.WILL_TRIGGER_AT_TIME: {
      const time = triggerAt
        ? dayjs(triggerAt).tz(displayTimezone).format('h:mma')
        : dayjs('1/01/23 ' + `${timeHour}:00`).format('h:mma');
      const actionText = getPendingActionText(item, onClickHandler);
      return (
        <>
          {actionText} at {time} on the due date
        </>
      );
    }
    case ChecklistItemStatus.TRIGGERED: {
      if (isMessageAction) {
        return (
          <>
            <u style={linkButtonStyle}>{templateName}</u> will send shortly.
          </>
        );
      }

      return <>Action has been performed</>;
    }
    case ChecklistItemStatus.PREVIOUSLY_TRIGGERED: {
      return <>Action has already been performed previously</>;
    }
    case ChecklistItemStatus.ACTION_SKIPPED: {
      if (isMessageAction) {
        return (
          <>
            <u style={linkButtonStyle}>{templateName}</u> sending was skipped by
            user.
          </>
        );
      }
      return <>Action was skipped by user.</>;
    }
    default:
      return null;
  }
}

function getPendingActionText(
  item: ChecklistItem,
  onClickHandler: () => void
): React.ReactNode {
  const templateName = item.action_message_template?.name;
  const actionId = item.action_type?.id;

  const linkButtonStyle = {
    fontSize: '1.2rem',
    textDecoration: 'underline'
  };

  switch (actionId) {
    case 'close_task':
      return 'Task will be closed';
    case 'create_note':
      return 'Note will be created';
    case 'upload_document':
      return 'Document will be uploaded';
    case 'add_linked_task':
      return 'Linked task will be created';
    case 'set_status':
      return (
        <>
          Task status will be set to &quot;
          {item.action_data?.task_status?.label}&quot;
        </>
      );
    case 'send_email':
    case 'send_sms':
    case 'send_message':
      return (
        <>
          <LinkButton style={linkButtonStyle} onClick={onClickHandler}>
            {templateName}
          </LinkButton>{' '}
          will automatically send
        </>
      );
    default:
      return null;
  }
}
