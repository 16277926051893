export const taskTypes = [
  'todo',
  'task_maintenance',
  'task_inspection',
  'task_lease_review',
  'task_move_in',
  'task_move_out',
  'task_supplier_compliance',
  'task_property_compliance',
  'task_arrears',
  'task_quote',
  'task_work_order',
  'task_custom_task'
] as const;

export type TaskType = typeof taskTypes[number];
