import React, { useMemo } from 'react';

import { TitleBlock } from 'view/components/cards/title/title-block';

import { RecordObject, RecordType } from 'data/models/types';

import { getRecordTitle } from 'utils/records/get-record-title';
import {
  Disbursement,
  financialsDisbursementsModel
} from 'data/models/entities/financials/disbursements/disbursements';
import { TitleBlockProps } from 'view/components/@luna/title-block';
import DisbursementsIcon from 'view/components/icons/disbursements';
import { formatCurrency } from 'utils/formatters';
import { Stat } from 'view/components/@luna/stat-block/stat-blocks';
import { useModelDownloadAction } from 'src/modules/common/actions/reporting/use-model-download-action';
import { useModelActions } from '@rexlabs/model-generator';
import { useTranslation } from 'react-i18next';
import { ViewDocumentDialog } from 'src/modules/documents/dialogs/view-document-dialog';
import { useDialog } from '@rexlabs/dialog';
import { getViewDocumentPropsFromPdfLink } from 'src/modules/documents/actions/use-get-view-documents-action';
import { useGetVoidDisbursementAction } from '../hooks/use-get-void-disbursement-action';
import { useSendDisbursementAction } from '../hooks/use-get-send-disbursement-action';

const type: RecordType = 'disbursement';

type CompletedDisbursementTitleBlockProps = {
  disbursement: Disbursement;
};

export function CompletedDisbursementTitleBlock({
  disbursement
}: CompletedDisbursementTitleBlockProps) {
  const { t } = useTranslation();

  const viewDocumentDialog = useDialog(ViewDocumentDialog);
  const getVoidDisbursementAction = useGetVoidDisbursementAction();
  const getDownloadAction = useModelDownloadAction(
    financialsDisbursementsModel
  );

  const getSendDisbursementAction = useSendDisbursementAction();
  const { downloadItem, viewItem } = useModelActions(
    financialsDisbursementsModel
  );

  const title = getRecordTitle({
    type,
    object: disbursement
  } as RecordObject);

  const tags: TitleBlockProps['tags'] = useMemo(() => {
    return [
      {
        type: disbursement.status.id === 'disbursed' ? 'good' : 'bad',
        label: disbursement.status.label!
      }
    ];
  }, [disbursement.status]);

  const stats: Stat[] = useMemo(() => {
    const billPayouts =
      disbursement.disbursement_snapshot_summary?.disbursement.bill_payouts ??
      0;
    const feePayouts =
      disbursement.disbursement_snapshot_summary?.disbursement.fee_payouts ?? 0;

    return [
      {
        label: 'Bills & fees paid',
        value: formatCurrency(billPayouts + feePayouts),
        valueTag: `${disbursement.summary_total_transferred_count} transfer(s)`,
        intent: 'secondary'
      },
      {
        label: 'Specific invoice payouts',
        value: formatCurrency(
          disbursement.disbursement_snapshot_summary?.disbursement
            .specific_invoice_payouts ?? 0
        ),
        intent: 'secondary'
      },
      {
        label: 'Payout amount',
        value: formatCurrency(
          disbursement.disbursement_snapshot_summary?.disbursement
            .total_payout_amount ?? 0
        ),
        intent: 'secondary'
      }
    ];
  }, [disbursement]);

  const actions = useMemo(() => {
    const voidAction = getVoidDisbursementAction(disbursement);
    const sendDisbursementAction = getSendDisbursementAction(disbursement);
    const downloadAction = getDownloadAction({
      fetcher: () => downloadItem(disbursement.id),
      record: disbursement,
      label: 'Download'
    });

    return [
      ...(voidAction ? [voidAction] : []),
      ...(sendDisbursementAction ? [sendDisbursementAction] : []),
      {
        id: 'download',
        group: 'reporting',
        label: 'View statement',
        async handleAction() {
          viewDocumentDialog.open({
            isLoading: true
          });

          const response = await viewItem(disbursement.id);

          viewDocumentDialog.update(
            getViewDocumentPropsFromPdfLink({
              link: response.data.link
            })
          );
        }
      },
      downloadAction
    ];
  }, [disbursement]);

  return (
    <TitleBlock
      title={t('disbursements.title-block.title', { title })}
      tags={tags}
      type={t('disbursements.singular.label') as string}
      reference={disbursement.record_reference}
      Icon={DisbursementsIcon}
      stats={stats}
      actions={actions}
    />
  );
}
