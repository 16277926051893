import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { OutlineButton } from '@rexlabs/button';
import { useDialog } from '@rexlabs/dialog';
import { SupplierCommissionTemplatesTable } from '../components/supplier-commission-templates-table';
import { UpdateSupplierCommissionTemplateDialog } from '../dialogs/update-supplier-commission-template-dialog';

export const SUPPLIER_COMMISSION_TEMPLATES_BLOCK_ID =
  'supplier-commission-templates';

export const supplierCommissionTemplatesBlock: BlockConfig = {
  id: SUPPLIER_COMMISSION_TEMPLATES_BLOCK_ID,
  title: 'Supplier commission templates',
  View: () => {
    return <SupplierCommissionTemplatesTable />;
  },
  Actions() {
    const updateSupplierCommissionTemplateDialog = useDialog(
      UpdateSupplierCommissionTemplateDialog
    );

    return (
      <OutlineButton
        onClick={() => {
          updateSupplierCommissionTemplateDialog.open();
        }}
      >
        Create template
      </OutlineButton>
    );
  }
};
