import React from 'react';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';

import { uploadPaymentGatewayBlock } from '../blocks/upload-payment-gateway-file-block';

const content: DialogContentConfig = [
  {
    id: 'upload-payment-gateway',
    label: 'Upload Payment Gateway File',
    blocks: [uploadPaymentGatewayBlock]
  }
];

export function UploadPaymentGatewayDialog({
  onClose,
  handleSubmit
}: Pick<
  React.ComponentProps<typeof RecordDialog>,
  'onClose' | 'handleSubmit'
>) {
  return (
    <RecordDialog
      title='Upload payment gateway file'
      onClose={onClose}
      content={content}
      submitLabel='Continue'
      handleSubmit={handleSubmit}
    />
  );
}
