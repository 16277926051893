import { useQuery } from 'react-query';
import { api } from 'utils/api/api-client';

type DateExpressionResult = {
  expression: string;
  resolved: string | null;
};

export function useValidDateExpression(expression: string, enabled = true) {
  const USE_VALID_DATE_EXPRESSION_QUERY_KEY = [
    'valid-date-expression',
    expression
  ];

  return useQuery({
    queryKey: USE_VALID_DATE_EXPRESSION_QUERY_KEY,
    queryFn:
      // debounce(
      async () =>
        api.post<DateExpressionResult>(
          'meta/filter-operators/date-expression',
          {
            expression
          }
        ),
    select: (data) => data.data,
    staleTime: Infinity,
    enabled: !!(enabled && expression)
  });
}
