import React from 'react';
import { Heading } from '@rexlabs/text';
import { border, padding, StyleSheet, useStyles } from '@rexlabs/styling';
import { Card } from 'view/components/@luna/card';
import { TimestampAttributes } from 'data/models/types';
import { RecordChangeLog } from 'view/components/record-change-log/record-change-log';
import {
  RelatedRecords,
  RelatedRecordsProps
} from '../related-records/related-records';

const defaultStyles = StyleSheet({
  header: {
    display: 'none',

    ...padding.styles({
      x: 'xl',
      y: 'm'
    }),
    ...border.styles({
      bottom: {
        width: 'thin',
        color: ({ token }) => token('color.border.container.static.light')
      }
    }),

    '@media (min-width: 1280px)': {
      display: 'block'
    }
  },
  content: {
    minHeight: '50px'
  }
});

export type RightBarProps = {
  relatedRecordsSections: RelatedRecordsProps['sections'];
  record: TimestampAttributes & { id: string };
};

export function RightBar({ relatedRecordsSections, record }: RightBarProps) {
  const s = useStyles(defaultStyles);

  return (
    <>
      <Card flex={0}>
        <header {...s('header')}>
          <Heading level={3}>Related Records</Heading>
        </header>
        <section {...s('content')}>
          <RelatedRecords sections={relatedRecordsSections} />
        </section>
      </Card>
      <RecordChangeLog record={record} />
    </>
  );
}
