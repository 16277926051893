import React, { ComponentType, useCallback, useMemo, useState } from 'react';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import {
  ButtonGroupProps,
  DialogContentConfig
} from 'view/components/record-screen/dialog/types';
import {
  query,
  useEntityListQuery,
  fragment,
  useModelActions
} from '@rexlabs/model-generator';
import { trustJournalEntryModel } from 'src/modules/trust-journal-entries/models/trust-journal-entries';
import { toQuri } from '@rexlabs/quri';

import { BankAccount } from 'data/models/entities/financials/bank-accounts';
import { useToast } from 'view/components/@luna/notifications/toast';
import { pluralize } from 'utils/formatters';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { TrustJournalEntry } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';
import { availableReceiptsBlock } from '../blocks/available-receipts';
import { selectedReceiptsBlock } from '../blocks/selected-receipts';
import { searchReceiptsBlock } from '../blocks/search-for-receipts';
import {
  MatchToExistingReceiptButtonGroup,
  MatchToExistingReceiptButtonGroupProps
} from '../components/match-to-existing-receipt-dialog-button-group';
import { bankStatementTransactionsModel } from '../models/bank-statement-transaction-model';
import { bankStatementTransactionsQueryKey } from '../hooks/use-bank-statement-transactions-query';

const content: DialogContentConfig = [
  {
    id: 'match-to-existing-receipt',
    label: 'Match to existing receipt',
    blocks: [searchReceiptsBlock, availableReceiptsBlock, selectedReceiptsBlock]
  }
];

const getTrustJournalEntriesQuery = (filter: string, searchValue: string) => {
  const trustJournalEntryQueryFragment = fragment`
    id
    payment_information
    contacts
  `;

  return searchValue
    ? query`{
      ${trustJournalEntryModel} (search: ${searchValue}, q: ${filter}){
        ${trustJournalEntryQueryFragment}
      }
    }`
    : query`{
    ${trustJournalEntryModel} (q: ${filter}){
      ${trustJournalEntryQueryFragment}
    }
  }`;
};

interface MatchToExistingReceiptDialogProps {
  data: {
    amount: number;
    reference?: string;
    dateOf?: string;
    bankAccount?: BankAccount;
    bankStatementTransactionId?: string;
  };
  closeParentDialog?: () => void;
  onClose?: () => void;
}

export function MatchToExistingReceiptDialog({
  onClose,
  closeParentDialog,
  data: { amount, reference = '', bankStatementTransactionId }
}: MatchToExistingReceiptDialogProps) {
  const [searchValue, setSearchValue] = React.useState(reference);
  const [selectedReceipts, setSelectedReceipts] = useState<
    TrustJournalEntry[] | undefined
  >([]);
  const [availableReceipts, setAvailableReceipts] = useState<
    TrustJournalEntry[] | undefined
  >([]);

  const { t } = useTranslation();

  const { matchBankStatementTransaction } = useModelActions(
    bankStatementTransactionsModel
  );

  const { addToast } = useToast();

  const queryClient = useQueryClient();

  const handleSubmit = useCallback(
    async ({ values }) => {
      const {
        selected_receipts: selectedReceipts,
        bank_statement_transaction_id: bankStatementTransactionId
      } = values;

      const response = await matchBankStatementTransaction({
        bankStatementTransactionId: bankStatementTransactionId,
        trustJournalEntryIds: selectedReceipts.map(
          (selectedReceipt) => selectedReceipt.id
        )
      });

      await addToast({
        description: (
          <>
            The bank transaction has been successfully matched against{' '}
            {selectedReceipts.length} trust journal{' '}
            {pluralize('entry', selectedReceipts.length, false)}
          </>
        )
      });

      await queryClient.refetchQueries({
        queryKey: [bankStatementTransactionsQueryKey],
        refetchPage: () => true
      });

      return response.data;
    },
    [addToast, matchBankStatementTransaction, queryClient]
  );

  const filter = toQuri([
    {
      field: 'type_id',
      op: '==',
      value: 'receipt'
    },
    {
      field: 'bank_statement_transaction_id',
      op: 'eq',
      value: 'null'
    },
    {
      field: 'status_id',
      op: 'eq',
      value: 'active'
    }
  ]);

  const trustJournalEntriesQuery = useMemo(
    () => getTrustJournalEntriesQuery(filter, searchValue),
    [searchValue, filter]
  );

  const { data: trustJournalEntriesData, status } = useEntityListQuery(
    trustJournalEntriesQuery
  );

  return (
    <RecordDialog
      title={t(
        'banking.batch-receipting.match-to-existing-trust-journal-entries.label'
      )}
      data={trustJournalEntriesData}
      handleSubmit={handleSubmit}
      content={content}
      ButtonGroup={
        MatchToExistingReceiptButtonGroup as ComponentType<ButtonGroupProps>
      }
      buttonGroupProps={
        {
          closeParentDialog,
          selectedReceipts,
          amount
        } as MatchToExistingReceiptButtonGroupProps
      }
      onClose={onClose}
      size='l'
      blockProps={{
        amount,
        reference,
        setSelectedReceipts,
        selectedReceipts,
        availableReceipts,
        setAvailableReceipts,
        searchValue,
        setSearchValue,
        bankStatementTransactionId,
        trustJournalEntriesDataIsLoading: status === 'loading'
      }}
    />
  );
}
