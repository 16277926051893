export const USER_GROUP_ROUTES = {
  USER_GROUP_LIST: {
    config: {
      path: '/user-groups',
      Component: require('./screens/user-group-list.tsx').UserGroupList
    }
  },
  USER_GROUP_DETAILS: {
    config: {
      path: '/user-group/:userGroupId',
      Component: require('./screens/user-group-details.tsx').UserGroupDetails
    }
  }
};
