import React, { useMemo } from 'react';
import { TitleBlock } from 'view/components/cards/title/title-block';
import { RecordObject, RecordTypes } from 'data/models/types';
import { getRecordTitle } from 'utils/records/get-record-title';
import { useGetRoleActions } from '../hooks/action-menu-items/use-get-role-actions';
import { Role } from '../types/Role';

const type = RecordTypes.Role;

type RoleTitleBlockProps = {
  role?: Role;
};

export function RoleTitleBlock({ role }: RoleTitleBlockProps) {
  const getRoleActions = useGetRoleActions();

  const title = getRecordTitle({ type, object: role } as RecordObject);
  const actions = useMemo(() => getRoleActions(role), [role, getRoleActions]);

  if (!role) {
    return null;
  }

  return <TitleBlock title={title} type={type} actions={actions} />;
}
