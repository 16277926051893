import * as React from 'react';

import { useGetNoteActions } from 'src/modules/note/hooks/use-get-note-actions';
import { useGetNoteListLinkProps } from 'src/modules/note/hooks/use-get-note-list-link-props';
import { getNotesQuery } from 'src/modules/note/hooks/use-note-query';
import { NotesList } from 'src/modules/note/components/notes-list';
import { BlockConfig } from 'view/components/record-screen/types';
import { ReadOnlyNotesListEmptyState } from 'src/modules/note/components/read-only-notes-list-empty-state';
import { InvoiceBlockProps } from 'src/modules/invoices/types/invoice-block';

export const billProcessingNotesTableBlock: BlockConfig<
  any,
  InvoiceBlockProps
> = {
  id: 'notes',
  Edit: function ({ values, blockProps }) {
    const isNotesTab = blockProps?.activeTab === 'notes';
    const uploadedBillId = values?.uploaded_bill?.id;

    const noteQuery = React.useMemo(
      () => getNotesQuery('uploaded_bill_id', uploadedBillId),
      [uploadedBillId]
    );

    const getNoteActions = useGetNoteActions();
    const getNoteListLinkProps = useGetNoteListLinkProps(true, true);

    return (
      <div
        style={{
          display: isNotesTab ? 'block' : 'none',
          marginTop:
            blockProps?.dialogType === 'invoice' ? '-9.6rem' : '-4.8rem'
        }}
      >
        <NotesList
          id={`notes-list-${uploadedBillId}`}
          hashParamKey={`notes-list-${uploadedBillId}`}
          getQuery={noteQuery ? () => noteQuery : undefined}
          Empty={ReadOnlyNotesListEmptyState}
          getRowLinkProps={getNoteListLinkProps}
          getActionMenuItems={({ item }) => getNoteActions(item, true)}
        />
      </div>
    );
  }
};
