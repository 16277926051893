// INJECT: Import Flags here
import { USER_GROUPS_FLAGS } from 'src/modules/user-groups/feature-flags';
import { PORTFOLIO_ROLES_FLAGS } from 'src/modules/portfolio-roles/feature-flags';
import { ROLES_FLAGS } from 'src/modules/authorization/roles/feature-flags';
import { CUSTOM_REPORTS_FLAGS } from 'src/modules/reporting/custom-reports/feature-flags';
import { AUDIT_LOGS_FLAGS } from 'src/modules/audit-logs/feature-flags';
import { PREPAYMENTS_FLAGS } from 'src/modules/prepayments/feature-flags';
import { WITHHELD_FUNDS_FLAGS } from 'src/modules/withheld-funds/feature-flags';
import { PROPERTY_AREA_FLAGS } from 'src/modules/property-areas/common/feature-flags';
import { APPLIANCES_FLAGS } from 'src/modules/appliances/feature-flags';
import {
  MESSAGES_FLAGS,
  SMS_FLAGS
} from 'src/modules/communications/messages/feature-flags';
import { PROPERTY_COMPLIANCE_FLAGS } from 'src/modules/compliance/property-compliance/feature-flags/property-compliance-flags';
import { SUPPLIER_COMPLIANCE_FLAGS } from 'src/modules/compliance/supplier-compliance/feature-flags/supplier-compliance-flags';
import { FINANCIALS_FLAGS } from 'src/modules/financials/feature-flags/financials-flags';
import { KEYS_SET_FLAGS } from 'src/modules/key-sets/feature-flags/key-sets-flags';
import { ACCOUNT_SETTINGS_FLAGS } from 'src/modules/settings/account-settings/feature-flags/account-settings-flags';
import { SUBSCRIPTIONS_FLAGS } from 'src/modules/settings/subscriptions/feature-flags/subscriptions-flags';
import { ARREARS_FLAGS } from 'src/modules/tasks/arrears/feature-flags';
import { TASKS_FLAGS } from 'src/modules/tasks/common/feature-flags/tasks-flags';
import { INSPECTION_FLAGS } from 'src/modules/tasks/inspections/feature-flags';
import { LEASE_REVIEWS_FLAGS } from 'src/modules/tasks/lease-review/feature-flags';
import { TASKS_MAINTENANCE_FLAGS } from 'src/modules/tasks/maintenance/feature-flags';
import { MOVE_INS_FLAGS } from 'src/modules/tasks/move-in/feature-flags';
import { MOVE_OUTS_FLAGS } from 'src/modules/tasks/move-out/feature-flags';
import { QUOTES_FLAGS } from 'src/modules/tasks/quotes/feature-flags';
import {
  CHECKLIST_TEMPLATES_FLAGS,
  CUSTOM_TASKS_FLAGS,
  SMART_CHECKLISTS_FLAGS,
  TASK_TRIGGER_FLAGS
} from 'src/modules/tasks/settings/feature-flags';
import { TODOS_FLAGS } from 'src/modules/tasks/todos/feature-flags';
import { WORK_ORDERS_FLAGS } from 'src/modules/tasks/work-orders/feature-flags';
import { TENANCIES_FLAGS } from 'src/modules/tenancies/feature-flags';
import { CHART_OF_ACCOUNT_FLAGS } from 'src/modules/chart-of-accounts/feature-flags/chart-of-accounts-flags';
import { OWNERSHIP_FLAGS } from 'src/modules/ownerships/feature-flags/feature-flags';
import { TAX_FLAGS } from 'src/modules/tax/feature-flags/feature-flags';
import { AGENCY_FEES_FLAGS } from 'src/modules/agency-fees/feature-flags';

// TODO: should move these other flags to their respective modules at some point
import { MANUAL_ACCOUNTING_JOURNAL_ENTRY_FLAGS } from 'src/modules/account-journal-entries/feature-flags/manual-accounting-journal-entry-flags';
import { CONTACTS_FLAGS } from 'src/modules/contacts/feature-flags';
import { NOTIFICATIONS_FLAGS } from 'src/modules/notifications/feature-flags/notifications-flags';
import { PROPERTY_COMPLIANCE_TASK_FLAGS } from 'src/modules/tasks/property-compliance/feature-flags';
import { SUPPLIER_COMPLIANCE_TASK_FLAGS } from 'src/modules/tasks/supplier-compliance/feature-flags';
import { REPORTING_FLAGS } from 'src/modules/reporting/dialogs/reporting-flags';
import { SERVICE_PACKAGES_FLAGS } from 'src/modules/service-packages/feature-flags';
import { BULK_EDIT_FLAGS } from 'src/modules/bulk-edit/feature-flags/bulk-edit-flags';
import { BATCH_RECEIPTING_FLAGS } from 'src/modules/banking/batch-receipting/feature-flags';
import { OWNERSHIP_STATEMENTS_FLAGS } from 'src/modules/ownership-statements/common/feature-flags';
import { SUPPLIER_COMMISSION_FLAGS } from 'src/modules/supplier-commission/feature-flags/feature-flags';
import { CUSTOM_VALIDATIONS_FLAGS } from 'src/modules/custom-validations/feature-flags/index';
import { QUICK_INVOICES_FLAGS } from 'src/modules/bill-processing/feature-flags';
import { TRUST_JOURNAL_ENTRIES_FLAGS } from 'src/modules/trust-journal-entries/feature-flags';
import { WIP_ACTIONS_FLAGS } from './wip-actions-flags';

export const FLAGS = {
  ...USER_GROUPS_FLAGS,
  ...PORTFOLIO_ROLES_FLAGS,
  ...CUSTOM_REPORTS_FLAGS,
  ...QUICK_INVOICES_FLAGS,
  ...ROLES_FLAGS,
  ...SUPPLIER_COMMISSION_FLAGS,
  ...CUSTOM_TASKS_FLAGS,
  ...OWNERSHIP_STATEMENTS_FLAGS,
  ...AUDIT_LOGS_FLAGS,
  ...PREPAYMENTS_FLAGS,
  ...BULK_EDIT_FLAGS,
  ...WITHHELD_FUNDS_FLAGS,
  ...REPORTING_FLAGS,
  ...PROPERTY_AREA_FLAGS,
  ...SMART_CHECKLISTS_FLAGS,
  ...SUBSCRIPTIONS_FLAGS,
  ...WORK_ORDERS_FLAGS,
  ...QUOTES_FLAGS,
  ...NOTIFICATIONS_FLAGS,
  ...CONTACTS_FLAGS,
  ...APPLIANCES_FLAGS,
  ...ARREARS_FLAGS,
  ...PROPERTY_COMPLIANCE_TASK_FLAGS,
  ...SUPPLIER_COMPLIANCE_TASK_FLAGS,
  ...INSPECTION_FLAGS,
  ...CHECKLIST_TEMPLATES_FLAGS,
  ...TASK_TRIGGER_FLAGS,
  ...MOVE_OUTS_FLAGS,
  ...MOVE_INS_FLAGS,
  ...LEASE_REVIEWS_FLAGS,
  ...TASKS_MAINTENANCE_FLAGS,
  ...TODOS_FLAGS,
  ...TASKS_FLAGS,
  ...MESSAGES_FLAGS,
  ...SMS_FLAGS,
  ...ACCOUNT_SETTINGS_FLAGS,
  ...TENANCIES_FLAGS,
  ...WIP_ACTIONS_FLAGS,
  ...PROPERTY_COMPLIANCE_FLAGS,
  ...SUPPLIER_COMPLIANCE_FLAGS,
  ...FINANCIALS_FLAGS,
  ...MANUAL_ACCOUNTING_JOURNAL_ENTRY_FLAGS,
  ...KEYS_SET_FLAGS,
  ...CHART_OF_ACCOUNT_FLAGS,
  ...OWNERSHIP_FLAGS,
  ...TAX_FLAGS,
  ...SERVICE_PACKAGES_FLAGS,
  ...BATCH_RECEIPTING_FLAGS,
  ...CUSTOM_VALIDATIONS_FLAGS,
  ...AGENCY_FEES_FLAGS,
  ...TRUST_JOURNAL_ENTRIES_FLAGS
};
