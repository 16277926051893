import { Generator } from 'data/models/generator';
import { auditableConfig } from 'src/modules/common/auditable/auditable-config';
import { api } from 'utils/api/api-client';
import _ from 'lodash';
import { Role } from '../types/Role';

const config = {
  entities: {
    related: {
      // TODO: configure includes here
      ...auditableConfig,

      privilege_entries: {
        include: 'privilege_entries'
      },
      privileges: {
        include: 'privileges'
      }
    }
  }
};

const actionCreators = {
  duplicateItem: {
    request: ({ role }: { role: Role }) =>
      api.post(`/roles/${role.id}/duplicate`),
    reduce: _.identity
  }
};

export const rolesModel = new Generator<Role, typeof actionCreators>(
  'roles',
  config
).createEntityModel({ actionCreators });
