import React from 'react';
import PaymentGatewayIcon from 'assets/icons/m/card.svg';
import { SplitIcon } from 'view/components/icons/split';
import BankingAccountIcon from 'view/components/icons/banking-account';
import { BatchReceiptingItemType } from '../types';
import { BatchReceiptingFileType } from '../types/bank-statement-transaction-import';

type ItemTypes = BatchReceiptingItemType | BatchReceiptingFileType;

export const BATCH_TRANSACTION_ICON_MAP: Record<ItemTypes, React.ReactNode> = {
  payment_gateway: <PaymentGatewayIcon />,
  bank_statement: <BankingAccountIcon />,
  transactions: <BankingAccountIcon />,
  split_transactions: <SplitIcon />
};

export const BankStatementTransactionTypeIcon = React.memo(function ({
  type
}: {
  type: ItemTypes;
}) {
  switch (type) {
    case 'payment_gateway':
      return <PaymentGatewayIcon />;
    case 'bank_statement':
    case 'transactions':
      return <BankingAccountIcon />;
    case 'split_transactions':
      return <SplitIcon />;
  }
});
