import { usePrivileges } from 'src/modules/authorization/roles/hooks/use-privileges';
import { useDialog } from '@rexlabs/dialog';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { EditApplianceRecordDialog } from '../../dialogs/edit-appliance-record-dialog';
import { Appliance } from '../../types/Appliance';

export function useGetEditApplianceAction() {
  const { hasPrivilege } = usePrivileges();
  const editApplianceDialog = useDialog(EditApplianceRecordDialog);

  return (appliance: Appliance): ActionDeclaration => {
    const { open } = editApplianceDialog;

    return {
      id: 'edit-appliance',
      group: 'appliance',
      label: 'Edit details',
      hidden: !hasPrivilege('appliances.update'),
      handleAction: async () => {
        open({ appliance: appliance });
      }
    };
  };
}
