import { Contact } from 'src/modules/contacts/types/contact-types';
import { PortfolioRole } from 'src/modules/portfolio-roles/types/PortfolioRole';
import { UserGroup } from 'src/modules/user-groups/types/UserGroup';

export function getLabelForManagedBy(
  managedBy?: Contact | UserGroup | PortfolioRole | null
) {
  switch (managedBy?.__record_type) {
    case 'contact':
      return managedBy.display_name;
    case 'user_group':
    case 'portfolio_role':
      return managedBy.label;
    default:
      return '--';
  }
}
