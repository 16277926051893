import React from 'react';
import { uniqBy } from 'lodash';

import Box from '@rexlabs/box';
import { OutlineButton } from '@rexlabs/button';

import { Banner } from 'src/modules/common/components/banner';

import InfoCircleIcon from 'view/components/icons/info';

import { InspectionTask } from '../types/InspectionTask';
import { useUserInspections } from '../hooks/use-user-inspections';
import { useGetUpdateManagedBy } from '../hooks/use-get-updated-managed-by';
import { getLabelForManagedBy } from '../../common/utils/get-label-for-managed-by';

export function ChangeManagedByWarningBanner({
  inspections = [],
  onCreate
}: {
  inspections?: InspectionTask[];
  onCreate: (args?: any) => void;
}) {
  const [isLoading, setIsLoading] = React.useState(false);

  const {
    inspectionsManagedByUser,
    inspectionsNotManagedByUser
  } = useUserInspections(inspections);
  const getUpdateManagedBy = useGetUpdateManagedBy();

  const bannerText = React.useMemo(
    () => getBannerText(inspectionsNotManagedByUser),
    [inspectionsNotManagedByUser]
  );

  const submitHandler = async () => {
    setIsLoading(true);

    const response = await getUpdateManagedBy(inspectionsNotManagedByUser);

    const mergedInspections = [
      ...(response?.data || []),
      ...inspectionsManagedByUser
    ];

    onCreate(mergedInspections);
    setIsLoading(false);
  };

  return (
    <Box data-testid='change-managed-by-warning-banner'>
      <Banner
        intent='warning'
        Icon={InfoCircleIcon}
        data-testid='change-managed-by-warning-banner'
      >
        {bannerText}
        <OutlineButton onClick={submitHandler} isLoading={isLoading}>
          Change managed by
        </OutlineButton>
      </Banner>
    </Box>
  );
}

function getBannerText(inspectionsNotManagedByUser: InspectionTask[]) {
  const managedBy =
    uniqBy(inspectionsNotManagedByUser, 'managed_by.id').length === 1
      ? getLabelForManagedBy(inspectionsNotManagedByUser[0]!.managed_by!)
      : 'multiple users';

  return `You are about to plan ${
    inspectionsNotManagedByUser.length === 1 ? 'an inspection' : 'inspections'
  } managed by ${managedBy}.`;
}
