import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

import { useModelActions } from '@rexlabs/model-generator';
import { useToast } from 'view/components/@luna/notifications/toast';
import { useConfirmationDialog } from '@rexlabs/dialog';
import { useQueryClient } from 'react-query';
import { customTaskTemplatesModel } from '../../models/custom-task-templates-model';
import { CustomTaskTemplate } from '../../types/CustomTaskTemplate';
import { customTaskTemplateQueryKey } from './use-custom-task-templates';

export function useGetDeleteCustomTaskTemplateAction() {
  const { trashItem, refreshLists } = useModelActions(customTaskTemplatesModel);
  const queryClient = useQueryClient();
  const { addToast } = useToast();

  const { open } = useConfirmationDialog({
    message: 'Are you sure you want to delete this custom task type?',
    confirmText: 'Delete',
    destructive: true,
    title: 'Delete custom task type'
  });

  return ({
    customTaskTemplate
  }: {
    customTaskTemplate: CustomTaskTemplate;
  }): SingleActionDeclaration => {
    return {
      id: 'delete-custom-task-template',
      intent: 'danger',
      group: 'custom-task-templates',
      label: 'Delete custom task type',
      handleAction: () => {
        open({
          onConfirm: async () => {
            const result = await trashItem({ id: customTaskTemplate.id });
            await addToast({
              description: 'Custom task type deleted',
              type: 'success'
            });
            await Promise.all([
              refreshLists(),
              queryClient.invalidateQueries(customTaskTemplateQueryKey)
            ]);
            return result;
          }
        });
      }
    };
  };
}
