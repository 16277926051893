import React from 'react';

import { query } from '@rexlabs/model-generator';

import { Columns, RecordTable, TabbedTable } from 'view/components/table';

import { EmptyState } from 'view/components/states/compact/empty';
import { transformSingleActionsToRegularActionMenuItems } from 'utils/actions/transforms';
import { customTaskTemplatesModel } from '../models/custom-task-templates-model';
import { CustomTaskTemplate } from '../types/CustomTaskTemplate';
import { useGetArchiveCustomTaskTemplateAction } from '../hooks/custom-task-templates/use-get-archive-custom-task-template-action';
import { useGetUnarchiveCustomTaskTemplateAction } from '../hooks/custom-task-templates/use-get-unarchive-custom-task-template-action';
import { useGetDeleteCustomTaskTemplateAction } from '../hooks/custom-task-templates/use-get-delete-custom-task-template-action';

const columns: Columns<CustomTaskTemplate> = [
  {
    id: 'label',
    width: 180,
    Header: 'Custom task type'
  }
];

export const customTaskTemplatesQuery = query`{
    ${customTaskTemplatesModel} {
      type
      custom_fields
      custom_field_values
      task_summary
    }
  }`;

function useGetActions() {
  const getArchiveCustomTaskTemplateAction = useGetArchiveCustomTaskTemplateAction();
  const getUnarchiveCustomTaskTemplateAction = useGetUnarchiveCustomTaskTemplateAction();
  const getDeleteArchiveCustomTaskTemplateAction = useGetDeleteCustomTaskTemplateAction();

  return (customTaskTemplate: CustomTaskTemplate) => {
    return [
      ...(customTaskTemplate.archived_at === null
        ? // TODO: show delete if no items, otherwise show archive
          [getArchiveCustomTaskTemplateAction({ customTaskTemplate })]
        : [getUnarchiveCustomTaskTemplateAction({ customTaskTemplate })]),
      // do a check to see if there are any items associated with this custom task template. If there are no items, or the include is missing, show the delete actions
      ...(!customTaskTemplate.task_summary?.count
        ? [getDeleteArchiveCustomTaskTemplateAction({ customTaskTemplate })]
        : [])
    ];
  };
}

export function CustomTaskTypeTemplatesList() {
  const getActions = useGetActions();

  const getActionMenuItems = ({ item }) => {
    return transformSingleActionsToRegularActionMenuItems(getActions(item));
  };

  const tabs = [
    {
      name: 'active',
      label: 'Active',
      Table: RecordTable,
      id: 'custom-task-type-templates-list',
      columns,
      getQuery: () => customTaskTemplatesQuery,
      Empty: EmptyState,
      emptyWithoutTable: true,
      getActionMenuItems: getActionMenuItems,
      forcedGlobalFilter: [
        {
          field: 'archived',
          op: 'eq',
          value: 'false'
        }
      ]
    },
    {
      name: 'archived',
      label: 'Archived',
      Table: RecordTable,
      id: 'custom-task-type-templates-list',
      columns,
      getQuery: () => customTaskTemplatesQuery,
      Empty: EmptyState,
      emptyWithoutTable: true,
      getActionMenuItems: getActionMenuItems,
      forcedGlobalFilter: [
        {
          field: 'archived',
          op: 'eq',
          value: 'true'
        }
      ]
    }
  ];

  return <TabbedTable tabs={tabs} />;
}
