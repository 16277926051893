import Box from '@rexlabs/box';
import { OutlineButton } from '@rexlabs/button';
import { FieldArray } from '@rexlabs/form';
import React from 'react';
import { AddIcon } from 'view/components/icons/add';
import { FolioCommissionTemplateStructure } from '../types/FolioCommissionTemplate';
import { EmptyTierFormData } from '../types/FolioCommissionTemplateFormData';
import { EditTemplateTierLineItem } from './edit-template-tier-line-item';

interface EditTemplateTierLineItemsProps {
  templateStructureType: Extract<
    FolioCommissionTemplateStructure,
    'progressive' | 'tiered'
  >;
  children: React.ReactNode;
}

export function getNewTier(): EmptyTierFormData {
  return {
    amount: {
      type: { id: 'percentage' },
      value: null
    },
    range: {
      to: null,
      from: null
    }
  };
}

export function EditTemplateTierLineItems({
  templateStructureType,
  children
}: EditTemplateTierLineItemsProps) {
  return (
    <>
      <FieldArray name={'tiers'}>
        {function LineItem({ fields, push }) {
          return (
            <>
              <Box>
                {fields.map(({ field, actions }, index) => {
                  return (
                    <EditTemplateTierLineItem
                      key={field.key}
                      lineItemName={field.name}
                      templateStructureType={templateStructureType}
                      isLastTier={index === fields.length - 1}
                      onRemove={index >= 2 ? actions.remove : undefined}
                    />
                  );
                })}
              </Box>
              {children}
              <Box mt='2rem' flexDirection='row' gap={12}>
                <OutlineButton
                  onClick={() => {
                    push(getNewTier());
                  }}
                  IconLeft={AddIcon}
                >
                  Add another tier
                </OutlineButton>
              </Box>
            </>
          );
        }}
      </FieldArray>
    </>
  );
}
