import React, { useMemo, useState } from 'react';
import { Dialog, DialogProps } from '@rexlabs/dialog';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { StandardDialogFooter } from 'view/components/dialogs/components/standard-dialog-footer';
import { AxiosRequestConfig } from 'axios';
import { api } from 'utils/api/api-client';
import { RexApiResponse } from '@rexlabs/api-client';
import { Field, ReactForms } from '@rexlabs/form';
import { NumberInput, TextInput } from '@rexlabs/text-input';
import { Validate } from '@rexlabs/form/lib/types/core/types';
import Box from '@rexlabs/box';
import { DateInput } from 'view/components/@luna/inputs/date-input/date-input';
import { ValueListValue } from 'data/models/types';
import { AuthorSelect } from 'view/components/inputs/selects/v4-selects/author-select';

enum MetricRuleInputType {
  UserSelection = 'user_selection',
  DateTime = 'date_time',
  Number = 'number',
  Text = 'text'
}

const metricRuleInputTypeMap = {
  [MetricRuleInputType.UserSelection]: AuthorSelect,
  [MetricRuleInputType.DateTime]: DateInput,
  [MetricRuleInputType.Number]: NumberInput,
  [MetricRuleInputType.Text]: TextInput
};

interface MetricWriteRuleError {
  message: string;
  required_inputs: {
    input_category: ValueListValue<'metric_write_rule'>;
    input_field_type: ValueListValue<MetricRuleInputType>;
    label: string;
    name: string;
  };
}

interface MetricWriteRuleErrorDialogProps extends DialogProps {
  errors: MetricWriteRuleError[];
  requestConfig: AxiosRequestConfig;
  onCancel: () => void;
  onSubmitSuccess: (response: RexApiResponse) => void;
  onSubmitFailure: (response: RexApiResponse) => void;
}

export function MetricWriteRuleErrorDialog({
  requestConfig,
  onClose,
  onCancel,
  errors,
  onSubmitSuccess,
  onSubmitFailure
}: MetricWriteRuleErrorDialogProps) {
  const [isResubmitting, setIsResubmitting] = useState(false);

  const inputs = useMemo(
    () => errors.flatMap((error) => error.required_inputs),
    [errors]
  );

  const validate: Validate<unknown, unknown> = useMemo(
    () =>
      inputs.reduce(
        (acc, input) => {
          acc.definitions[input.name] = {
            name: input.label,
            rules: 'required'
          };
          return acc;
        },
        { definitions: {} }
      ),
    [inputs]
  );

  async function submitFormInputs(values: any) {
    setIsResubmitting(true);
    try {
      const response = await api.request({
        ...requestConfig,
        data: JSON.stringify({
          ...JSON.parse(requestConfig.data),
          __metric_write_rule_inputs: {
            ...values
          }
        })
      });
      onSubmitSuccess(response as RexApiResponse);
    } catch (e) {
      onSubmitFailure(e as any);
    } finally {
      onClose?.();
      setIsResubmitting(false);
    }
  }

  function cancelAndClose() {
    onCancel();
    onClose?.();
  }

  return (
    <Dialog
      size={'s'}
      title={'Metrics – Input Required'}
      onClose={cancelAndClose}
    >
      <ReactForms validate={validate} handleSubmit={submitFormInputs}>
        {({ submitForm }) => (
          <>
            <Box gap={24} flexDirection='column'>
              {inputs.map((input) => (
                <Field
                  key={input.name}
                  name={input.name}
                  label={input.label}
                  Input={metricRuleInputTypeMap[input.input_field_type.id]}
                />
              ))}
            </Box>
            <StandardDialogFooter>
              <GhostButton onClick={cancelAndClose}>Cancel</GhostButton>
              <PrimaryButton
                isLoading={isResubmitting}
                onClick={() => {
                  submitForm();
                }}
              >
                Submit
              </PrimaryButton>
            </StandardDialogFooter>
          </>
        )}
      </ReactForms>
    </Dialog>
  );
}
