import * as React from 'react';

import { DialogProps } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { CreateRecordDialog } from 'view/components/dialogs/create-record-dialog/create-record-dialog';
import { useRecordCreatedToast } from 'src/modules/common/toasts/hooks/use-record-created-toast';
import { tasksModel } from '../../common/models/tasks-model';
import { taskPriorityBlock } from '../../common/blocks/task-priority-block';
import { taskDateBlock } from '../../common/blocks/task-date-block';
import { CustomTaskFormData } from '../types/CustomTaskFormData';
import { checklistTemplateSelectBlock } from '../../common/blocks/checklist-template-select-block';
import { createTaskAndLinkChecklists } from '../../common/utils/create-task-and-link-checklists';
import { useTaskTypesWithCustomAddToRecord } from '../../settings/hooks/use-task-types-with-custom-add-to-record';
import { CustomTaskTemplate } from '../../settings/types/CustomTaskTemplate';
import { mapCustomTaskFormDataToTaskCreateRequest } from '../mappers/map-custom-task-form-data-to-task-create-request';
import { customTaskDetailsBlock } from '../blocks/custom-task-details-block';
import { customTaskCustomFieldsBlock } from '../blocks/custom-task-custom-fields-block';

export interface CreateCustomTaskRecordDialogProps extends DialogProps {
  customTaskTemplate: CustomTaskTemplate;
  initialValues: Partial<CustomTaskFormData>;
  onCreate?: (data) => void;
  isFixture?: boolean;
}

function useContent({
  customTaskTemplate
}: {
  customTaskTemplate: CustomTaskTemplate;
}) {
  const taskTypesWithCustomAddToRecord = useTaskTypesWithCustomAddToRecord();

  return React.useMemo(
    () => [
      {
        id: 'add-custom-task',
        label: `Add ${customTaskTemplate.label}`,
        blocks: [
          customTaskDetailsBlock,
          customTaskCustomFieldsBlock,
          ...(taskTypesWithCustomAddToRecord.includes('task_custom_task')
            ? [checklistTemplateSelectBlock]
            : []),
          taskPriorityBlock,
          taskDateBlock
        ]
      }
    ],
    []
  );
}

export function CreateCustomTaskRecordDialog({
  customTaskTemplate,
  onClose,
  isFixture,
  initialValues,
  onCreate,
  target
}: CreateCustomTaskRecordDialogProps) {
  const { refreshLists } = useModelActions(tasksModel);
  const addToast = useRecordCreatedToast('task_custom_task');
  const content = useContent({ customTaskTemplate });

  const handleSubmit: RecordSubmitHandler<CustomTaskFormData> = async ({
    values
  }) => {
    const customTask = await createTaskAndLinkChecklists<'task_custom_task'>(
      values,
      (formData: Partial<CustomTaskFormData>) =>
        mapCustomTaskFormDataToTaskCreateRequest(formData, customTaskTemplate)
    );
    onCreate?.(customTask);
    addToast(customTask);
    await refreshLists();
    return customTask;
  };
  return (
    <CreateRecordDialog
      title={`Create ${customTaskTemplate.label}`}
      recordType='task_custom_task'
      data={initialValues}
      blockProps={{
        taskTypeId: 'task_custom_task',
        customTaskTypeId: customTaskTemplate.type?.id
      }}
      content={content}
      onClose={onClose}
      onSubmit={handleSubmit}
      target={target}
      isFixture={isFixture}
    />
  );
}
