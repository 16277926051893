import React, { useMemo } from 'react';
import { query, useEntityQuery } from '@rexlabs/model-generator';

import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { RecordScreen } from 'view/components/record-screen';

import {
  ChargeEntry,
  financialsChargeEntriesModel
} from 'data/models/entities/financials/charge-entries';
import { useRecordScreenSubmitHandler } from 'view/hooks/use-record-screen-submit-handler';
import { chargeEntryDetailsContent } from '../data/charge-entry-details';
import { ChargeEntryTitleBlock } from '../components/charge-entry-title-block';

interface ChargeEntryDetailsScreenProps {
  chargeEntryId?: string;
}

const getChargeEntryQuery = (chargeEntryId: string) => query`{
  ${financialsChargeEntriesModel} (id: ${chargeEntryId}) {
    id
    description
    due_date
    amount
    payable_by
    payable_to
    tax_type
  }
}`;

export function ChargeEntryDetailsScreen({
  chargeEntryId
}: ChargeEntryDetailsScreenProps) {
  const breadcrumbs = [{ type: BREADCRUMBS.CHARGE_ENTRY }];
  const query = useMemo(() => getChargeEntryQuery(chargeEntryId!), [
    chargeEntryId
  ]);
  const { data, status, actions } = useEntityQuery(query);

  const handleSubmit = useRecordScreenSubmitHandler(({ changedValues }) =>
    actions.updateItem({
      id: chargeEntryId,
      data: changedValues
    })
  );

  return (
    <RecordScreen
      // TODO: does this need a priv?
      isLoading={status === 'loading'}
      data={data}
      handleSubmit={handleSubmit}
      content={chargeEntryDetailsContent}
      titleBlock={<ChargeEntryTitleBlock chargeEntry={data as ChargeEntry} />}
      breadcrumbs={breadcrumbs}
    />
  );
}
