import { useWhereabouts } from '@rexlabs/whereabouts';
import { Columns } from 'src/view/components/table';
import { ProgressDropdown } from 'src/modules/tasks/common/components/progress-dropdown';
import { SummaryCell } from '../../common/components/summary-cell';
import {
  closedDate,
  createdBy,
  createdDate,
  managedBy
} from '../../common/utils/common-columns';
import { CustomTask } from '../types/CustomTask';
import { CustomTaskTemplate } from '../../settings/types/CustomTaskTemplate';

const summary = {
  id: 'summary',
  Header: 'Summary',
  Cell: SummaryCell,
  accessor: (item) => item,
  width: 380,
  toCsv: (item: CustomTask) => item.summary
};

const followUpDate = {
  id: 'follow_up_date',
  Header: 'Follow up date',
  accessor: (item: CustomTask) => item.follow_up_date,
  type: 'date',
  width: 140
} as const;

const dueDate = {
  id: 'due_date',
  Header: 'Due date',
  accessor: (item: CustomTask) => item.due_date,
  type: 'date',
  width: 140
} as const;

const status = {
  id: 'status',
  Header: 'Task progress',
  Cell: ProgressDropdown,
  accessor: (item: CustomTask) => item,
  width: 150,
  toCsv: (item: CustomTask) => item?.status?.label
};

const priority = {
  id: 'priority_id',
  type: 'value',
  Header: 'Priority',
  accessor: (item: CustomTask) => item.priority,
  toCsv: (item: CustomTask) => item?.priority?.label
} as const;

const type = {
  id: 'details.type.id',
  type: 'value',
  Header: 'Custom task type',
  accessor: (item: CustomTask) => item.details?.type,
  toCsv: (item: CustomTask) => item?.details?.type?.label
} as const;

export function useCustomTaskColumns(
  customTaskTemplate?: CustomTaskTemplate | null
): Columns {
  const whereabouts = useWhereabouts();
  const tab = whereabouts.hashQuery?.tab;

  const typeColumn = customTaskTemplate?.type?.id ? null : type;

  if (tab === 'closed') {
    return [
      summary,
      typeColumn,
      closedDate,
      dueDate,
      managedBy,
      priority,
      createdBy,
      createdDate
    ].filter(Boolean) as Columns;
  }

  return [
    summary,
    typeColumn,
    followUpDate,
    dueDate,
    status,
    managedBy,
    priority,
    createdBy,
    createdDate
  ].filter(Boolean) as Columns;
}
