import React, { useMemo } from 'react';
import { query, useEntityQuery } from '@rexlabs/model-generator';

import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { RecordScreen } from 'view/components/record-screen';

import { useRecordScreenSubmitHandler } from 'view/hooks/use-record-screen-submit-handler';
import { TaskRightBarOverride } from 'src/modules/tasks/common/components/right-bar-override';
import { content } from '../data/content';
import { TodoTitleBlock } from '../components/todo-title-block';
import { tasksModel } from '../../common/models/tasks-model';
import { mapTodoFormDataToTaskCreateRequest } from '../mappers/map-todo-form-data-to-task-create-request';
import { TodoFormData } from '../types/TodoFormData';
import { TodoTask } from '../types/TodoTask';
import { mapTodoTaskToTodoFormData } from '../mappers/map-todo-task-to-todo-form-data';

// TODO: move this somewhere else
const getTodoQuery = (id: string) => query`{
  ${tasksModel} (id: ${id}) {
    id
    todoDetails
    property
    managed_by
    task_links
    created_by
    updated_by
  }
}`;

interface TodoDetailsProps {
  todoId?: string;
}

export function TodoDetails({ todoId }: TodoDetailsProps) {
  const breadcrumbs = [{ type: BREADCRUMBS.TODO }];
  const query = useMemo(() => getTodoQuery(todoId!), [todoId]);

  const { status, data: rawData, actions } = useEntityQuery(query, {
    throwOnError: false
  });

  const handleSubmit = useRecordScreenSubmitHandler(
    async ({ changedValues }) => {
      const dataToSubmit = mapTodoFormDataToTaskCreateRequest(changedValues);
      await actions.updateItem({
        id: todoId,
        data: dataToSubmit
      });
    }
  );

  const data: TodoTask | undefined = rawData;
  const titleBlock = <TodoTitleBlock todo={data} />;

  const initialValues: TodoFormData | undefined = data
    ? mapTodoTaskToTodoFormData(data)
    : undefined;

  return (
    <RecordScreen
      privilege={'tasks.todos.read'}
      isLoading={status === 'loading'}
      data={data}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      content={content}
      titleBlock={titleBlock}
      breadcrumbs={breadcrumbs}
      RightBarOverride={TaskRightBarOverride}
    />
  );
}
