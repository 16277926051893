import { GhostButton, PrimaryButton } from '@rexlabs/button';
import React, { useCallback } from 'react';
import { ButtonGroupProps } from 'view/components/record-screen/dialog/types';
import { StandardDialogFooter } from 'view/components/dialogs/components/standard-dialog-footer';
import { FolioCommissionTemplateStructure } from '../types/FolioCommissionTemplate';
import {
  FolioCommissionTemplateFormData,
  FolioCommissionTemplateTierFormData
} from '../types/FolioCommissionTemplateFormData';

function hasValue(value) {
  return value === 0 || value;
}

export function UpdateSupplierCommissionTemplateDialogButtonGroup({
  handleSubmit,
  onClose,
  isSubmitting,
  forms,
  label
}: ButtonGroupProps & { label: string }) {
  const values = forms?.['supplier-commission-details']
    .values as FolioCommissionTemplateFormData;

  const structureTypeId: FolioCommissionTemplateStructure =
    values?.structure_type?.id;

  let isDisabled = !structureTypeId || !values?.name;

  switch (structureTypeId) {
    case 'tiered':
    case 'progressive':
      {
        const tiers = values?.tiers || [];

        const hasInvalidData = tiers.some(
          (tier: FolioCommissionTemplateTierFormData, index: number) => {
            const isLastTier = tiers?.length === index + 1;

            const hasAmountData =
              hasValue(tier.amount) &&
              tier.amount?.type?.id &&
              hasValue(tier?.amount?.value);

            // the last tier requires a 'from' value but no 'to' value
            const hasRangeData =
              tier.range &&
              hasValue(tier.range?.from) &&
              (!isLastTier ? hasValue(tier.range?.to) : true);

            return !(hasAmountData && hasRangeData);
          }
        );

        if (hasInvalidData) {
          isDisabled = true;
        }
      }
      break;
    case 'flat_fee':
      if (!hasValue(values?.commission_amount_currency)) {
        isDisabled = true;
      }
      break;
    case 'percentage':
      if (!hasValue(values?.commission_amount_percent)) {
        isDisabled = true;
      }
      break;
  }

  const handleSubmitAndClose = useCallback(async () => {
    const result = await handleSubmit();

    if (!result) return;

    onClose?.();
  }, [handleSubmit, onClose]);

  return (
    <StandardDialogFooter>
      <GhostButton onClick={onClose}>Cancel</GhostButton>
      <PrimaryButton
        onClick={handleSubmitAndClose}
        isDisabled={isDisabled}
        isLoading={isSubmitting}
      >
        {label}
      </PrimaryButton>
    </StandardDialogFooter>
  );
}
