import React, { useState } from 'react';

import { ListScreen } from 'view/components/list-screen/list-screen';
import { Card } from 'view/components/card';
import RenderLoading from 'view/components/@luna/render-loading';
import { NumberInput } from '@rexlabs/text-input';
import dayjs from 'dayjs';
import { DateInput } from 'view/components/@luna/inputs/date-input/date-input';
import { formatDateIso } from 'utils/dates/format';
import { Grid } from 'view/components/@luna/form/grid';
import { Label } from '@rexlabs/text';
import Box from '@rexlabs/box';
import { StyleSheet, text, useStyles } from '@rexlabs/styling';
import { useWhereabouts } from '@rexlabs/whereabouts';
import { StatementType } from 'src/modules/statements/types';
import { OwnershipStatementsTable } from '../../common/components/ownership-statements-table';

const defaultStyles = StyleSheet({
  label: {
    ...text.styles({
      fallback: 'small.semibold',
      color: ({ token }) => token('color.textStyle.body.subtext')
    })
  }
});

export function PeriodicStatementsListScreen() {
  const s = useStyles(defaultStyles);
  const w = useWhereabouts();
  const [days, setDays] = useState(30);
  const [statementEndDate, setStatementEndDate] = useState(
    formatDateIso(dayjs().subtract(1, 'day'))
  );

  return (
    <RenderLoading>
      <ListScreen
        privilege={'statements.read'}
        title='Periodic ownership statements'
      >
        <Card>
          <Grid columns={4}>
            <Box>
              <Label {...s('label')}>Statement end date</Label>
              <DateInput
                value={statementEndDate}
                onChange={(e) => setStatementEndDate(e.target.value!)}
              />
            </Box>
            <Box>
              <Label {...s('label')}>Minimum days since last statement</Label>
              <NumberInput
                value={days}
                onChange={(e) => setDays(e.target.value)}
                suffix={<>days</>}
                disabled={w.hashQuery?.tab === 'issued'}
              />
            </Box>
          </Grid>
        </Card>
        <Card>
          <OwnershipStatementsTable
            numberOfDays={days}
            statementEndDate={statementEndDate}
            statementType={StatementType.PeriodicOwnership}
          />
        </Card>
      </ListScreen>
    </RenderLoading>
  );
}
