import { Generator } from 'data/models/generator';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { auditableConfig } from 'src/modules/common/auditable/auditable-config';

export const contactModelConfig = {
  entities: {
    related: {
      service_categories: {
        include: 'service_categories'
      },
      roles: {
        include: 'roles'
      },
      emails: {
        include: 'emails'
      },
      phones: {
        include: 'phones'
      },
      addresses: {
        include: 'addresses'
      },
      primary_phone: {
        include: 'primary_phone'
      },
      primary_sms: {
        include: 'primary_sms'
      },
      primary_email: {
        include: 'primary_email'
      },
      primary_address: {
        include: 'primary_address'
      },
      ownerships: {
        include: 'ownerships.owners.contact'
      },
      tenancies: {
        include: 'tenancies.tenants.contact'
      },
      disbursement_payment_methods: {
        include: 'disbursement_payment_methods{contact_payment_method,contact}'
      },
      bpay: {
        include: 'bpay'
      },
      disbursement_withheld_funds: {
        include: 'disbursement_withheld_funds'
      },
      financial_summary: {
        include: 'financial_summary'
      },
      financial_contact: {
        include: 'financial_contact'
      },
      trust_summary: {
        include: 'trust_summary'
      },
      logo: {
        include: 'logo'
      },
      commission_rules: {
        include: [
          'commission_rules',
          'commission_rules.commission_template',
          'commission_rules.chart_of_accounts_account'
        ].join(',')
      },
      compliance_categories: {
        include:
          'compliance_categories, compliance_categories.category_requirements'
      },
      tax: {
        include: 'tax'
      },
      ...auditableConfig
    }
  }
};

export const contactsModel = new Generator<Contact>(
  'contacts',
  contactModelConfig
).createEntityModel();
