import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { FinancialEntity } from 'src/modules/financials/utils/financial-entity-action-group/use-get-financial-entity-actions';
import RenderLoading from 'view/components/@luna/render-loading';
import { ActionButtons } from 'view/components/@luna/action-buttons';
import { FolioLedger } from '../components/folio-ledger';
import { viewFolioLedgerReportAction } from '../actions/view-folio-ledger-report-action';
import { FolioLedgerTableTooltip } from '../components/folio-ledger-table-tooltip';

export const payablesLedgerBlock: BlockConfig<FinancialEntity> = {
  id: 'payables-ledger',
  title: () => 'Payables ledger',
  TitleTooltip: FolioLedgerTableTooltip,
  View: ({ data }) => {
    return (
      <RenderLoading isLoading={!data}>
        {data ? <FolioLedger data={data} type='payables' /> : null}
      </RenderLoading>
    );
  },
  Actions: ({ data }) => {
    if (!data) {
      return null;
    }

    return (
      <ActionButtons
        actions={[
          viewFolioLedgerReportAction({ folio: data, type: 'payables' })
        ]}
      />
    );
  }
};
