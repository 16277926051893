import React, { useMemo } from 'react';

import { StyleSheet, useStyles, useToken } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { Truncate } from '@rexlabs/text';

import { ColoredTag } from 'view/components/tags/colored-tag';
import { CircleFilledIcon } from 'view/components/icons/circle';
import { CommentIcon } from 'view/components/icons/comment';
import { ReceiptIcon } from 'view/components/icons/receipt';
import { NoteIcon } from 'view/components/icons/note';

import { getReceiptingInstructionDescription } from '../utils/get-receipting-instruction-description';
import {
  BatchReceiptingCardLayout,
  Intent
} from './batch-receipting-card-layout';
import { IconValue } from './batch-receipting-card-icon-value';
import { Summary } from './batch-receipting-card-summary';

interface ReceiptCardProps {
  intent: Intent;
  status: string;
  summary?: string;
  amount: number;
  invoicesDescription?: string;
  invoicesDescriptionIcon?: React.ComponentType;
  receiptingInstruction?: string;
  note?: string;
}

const defaultStyles = StyleSheet({
  truncated: {
    '-webkit-line-clamp': '2',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    whiteSpace: 'pre-line'
  }
});

const emptyValue = '--';

export function ReceiptCard({
  status,
  intent,
  summary,
  amount,
  invoicesDescription,
  invoicesDescriptionIcon = ReceiptIcon,
  receiptingInstruction,
  note
}: ReceiptCardProps) {
  const token = useToken();

  const s = useStyles(defaultStyles);

  const ReceiptLabel = useMemo(
    () => (
      <Box
        flexDirection='row'
        height='100%'
        justifyContent='space-between'
        alignItems='center'
      >
        <ColoredTag intent={intent}>{status}</ColoredTag>
        {note && <NoteIcon width={16} />}
      </Box>
    ),
    [status, intent, note]
  );

  const NoteValue = useMemo(
    () => <Truncate {...s('truncated')}>{note}</Truncate>,
    [note, s]
  );

  const ReceiptValue = useMemo(() => {
    return (
      <>
        <Summary
          summary={summary || emptyValue}
          amount={amount}
          amountDisabled={summary === emptyValue}
        />
        <Box flexDirection='row' gap={token('spacing.s')}>
          <IconValue
            Icon={invoicesDescriptionIcon}
            value={invoicesDescription || emptyValue}
          />
          <CircleFilledIcon
            width={12}
            color={token('color.textStyle.body.disabled')}
          />
          <IconValue
            Icon={CommentIcon}
            value={
              getReceiptingInstructionDescription({
                summary,
                receiptingInstruction
              }) || emptyValue
            }
          />
        </Box>
      </>
    );
  }, [amount, invoicesDescription, receiptingInstruction, summary, token]);

  return (
    <BatchReceiptingCardLayout
      intent={intent}
      label={ReceiptLabel}
      value={note ? NoteValue : ReceiptValue}
    />
  );
}
