import React, { useEffect, useState } from 'react';

import { ListScreen } from 'view/components/list-screen/list-screen';
import RenderLoading from 'view/components/@luna/render-loading';
import { Card } from 'view/components/card';
import Box from '@rexlabs/box';
import { FinancialPeriod, StatementType } from 'src/modules/statements/types';
import {
  FinancialPeriodSelect,
  useFinancialPeriodsSelectItems
} from 'view/components/inputs/selects/v4-selects/financial-period-select';
import { EmptyState } from 'view/components/states/compact/empty';
import { Message } from '@rexlabs/notifications';
import { EmptyCard } from 'view/components/record-screen/cards/empty-card';
import EmptyTrustJournalEntriesIllustration from 'assets/illustrations/empty-trust-journal-entries-table.svg';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'routes/app';
import { useExtractFinancialPeriodFromUrl } from 'src/modules/ownership-statements/eoy-statements/hooks/use-extract-financial-period-from-url';
import { isEqual } from 'lodash';
import InfoCircleIcon from 'view/components/icons/info';
import { Banner } from 'src/modules/common/components/banner';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { OwnershipStatementsTable } from '../../common/components/ownership-statements-table';

export function FinancialYearStatementsListScreen() {
  const urlFinancialPeriod = useExtractFinancialPeriodFromUrl();
  const { hasFeature } = useFeatureFlags();

  const {
    items: financialPeriods,
    isLoading
  } = useFinancialPeriodsSelectItems();

  const [financialPeriod, setFinancialPeriod] = useState<FinancialPeriod>();

  // This is because financialPeriods is an async call, and we need to wait till
  // its finished loading, this effect will prioritise URL params
  useEffect(() => {
    if (
      !financialPeriods ||
      (financialPeriod &&
        (!urlFinancialPeriod || isEqual(financialPeriod, urlFinancialPeriod)))
    ) {
      return;
    }

    if (urlFinancialPeriod) {
      setFinancialPeriod(urlFinancialPeriod);
      return;
    }

    setFinancialPeriod(financialPeriods[0]);
  }, [financialPeriods, urlFinancialPeriod]);

  return (
    <ListScreen
      privilege={'statements.read'}
      title='Financial year ownership statements'
      titleRightContent={
        <Box justifyContent={'flex-end'} width={'25rem'}>
          <FinancialPeriodSelect
            id='financial-period-select'
            value={financialPeriod}
            onChange={(e) => {
              const value = e.target.value as FinancialPeriod;
              setFinancialPeriod(value);
              push(ROUTES.EOY_STATEMENTS_LIST, {
                query: {
                  financial_period_start_date: value.startDate,
                  financial_period_end_date: value.endDate
                }
              });
            }}
          />
        </Box>
      }
    >
      <Box flexDirection='column' sy='2.4rem'>
        <RenderLoading isLoading={isLoading}>
          {financialPeriods?.length === 0 ? (
            <EmptyCard>
              <Message
                title='No financial year ownership statements'
                Illustration={EmptyTrustJournalEntriesIllustration}
              >
                <div>
                  The earliest financial transactions in your account occurred
                  in the current financial year. The data needed to issue End of
                  Financial Year Statements will be available the day after the
                  financial year closes.
                </div>
              </Message>
            </EmptyCard>
          ) : (
            <>
              {hasFeature(FLAGS.STATEMENT_PREVIEW) && (
                <Banner intent={'information'} Icon={InfoCircleIcon}>
                  Only &lsquo;Issued&lsquo; financial statements close the
                  financial period, preventing any further financial activity in
                  Rex PM for those dates. If a statement is not
                  &lsquo;Issued,&lsquo; the financial period remains open,
                  allowing continued financial activity for that period.
                </Banner>
              )}
              <Card>
                {!financialPeriod ? (
                  // TODO: we might want to replace this with something from Design, but this should be fine for now
                  <EmptyState title='Please select a financial year' />
                ) : (
                  <OwnershipStatementsTable
                    statementPeriod={financialPeriod}
                    statementType={StatementType.YearlyOwnership}
                  />
                )}
              </Card>
            </>
          )}
        </RenderLoading>
      </Box>
    </ListScreen>
  );
}
