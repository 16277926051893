import React, { useCallback } from 'react';
import { capitalize, get } from 'lodash';
import { DialogProps } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';

import {
  postRequestPipelineRequests,
  RequestPipeLineRequests,
  RequestPipelineMethod
} from 'utils/api/post-request-pipeline';

import {
  ComplianceType,
  settingsComplianceTypesModel
} from 'src/modules/compliance/common/models/compliance-types';

import { useToast } from 'view/components/@luna/notifications/toast';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';

import { SupplierComplianceCategory } from 'data/models/entities/settings/supplier-compliance-categories';
import { SupplierCategoryRequirement } from 'data/models/entities/settings/supplier-category-requirements';
import { ComplianceSettingsDialogButtonGroup } from 'src/modules/compliance/common/components/compliance-settings-dialog-button-group';
import { useSettings } from 'src/modules/settings/hooks/useSettings';
import {
  supplierComplianceRequirementDetailsBlock,
  SupplierComplianceRequirementDetailsBlockFormValues
} from '../blocks/supplier-compliance-requirement-details-block';

const content = [
  {
    id: 'details',
    label: 'Details',
    blocks: [supplierComplianceRequirementDetailsBlock]
  }
];

export function CreateSupplierComplianceRequirementDialog({
  onClose
}: DialogProps) {
  const { addToast } = useToast();
  const settings = useSettings();
  const defaultTaskCreationSettings = settings?.['supplier-compliance-task'];

  const { refreshLists } = useModelActions(settingsComplianceTypesModel);

  const handleSubmit = useCallback(
    async ({
      values
    }: {
      values: SupplierComplianceRequirementDetailsBlockFormValues;
    }) => {
      const {
        include_in_categories,
        is_default_task_creation,
        ...restOfValues
      } = values;

      const isDefaultTaskCreation = Boolean(is_default_task_creation);

      const requests: RequestPipeLineRequests = [
        {
          method: 'POST',
          path: '/api/v1/settings/compliance-types',
          json: {
            ...restOfValues,
            applicable_to_object_type: { id: 'supplier' },
            is_default_task_creation: isDefaultTaskCreation,
            ...(isDefaultTaskCreation
              ? {}
              : {
                  task_expiration_frequency:
                    restOfValues.task_expiration_frequency
                })
          }
        },
        ...(include_in_categories || []).map(
          (category: SupplierComplianceCategory) => ({
            method: 'POST' as RequestPipelineMethod,
            path: '/api/v1/settings/supplier-category-requirements',
            json: {
              supplier_compliance_category: { id: category.id },
              compliance_type: { id: '{{$.0.id}}' }
            }
          })
        )
      ];

      const { data } = await postRequestPipelineRequests<
        typeof requests,
        [ComplianceType, ...SupplierCategoryRequirement[]]
      >(requests);

      const newRequirement = data.find((item) => {
        if ('label' in item) {
          return item;
        }
      });

      const label = get(newRequirement, 'label', '');

      addToast({
        description: (
          <>
            Compliance requirement created - <b>{label}</b>
          </>
        )
      });

      await refreshLists();

      return data;
    },
    []
  );

  const initialValues = {
    is_default_task_creation: true,
    task_expiration_frequency: {
      count: defaultTaskCreationSettings.count,
      unit: {
        id: defaultTaskCreationSettings.unit,
        label: capitalize(defaultTaskCreationSettings.unit)
      }
    }
  };

  return (
    <RecordDialog
      title='Create compliance requirement'
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      onClose={onClose}
      content={content}
      ButtonGroup={ComplianceSettingsDialogButtonGroup}
      size='s'
    />
  );
}
