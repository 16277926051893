import React from 'react';
import { DialogProps, useErrorDialog } from '@rexlabs/dialog';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { useRecordCreatedToast } from 'src/modules/common/toasts/hooks/use-record-created-toast';
import { useMutation, useQueryClient } from 'react-query';
import { api } from 'utils/api/api-client';
import { METRIC_WRITE_RULES_QUERY_KEY } from 'src/modules/metric-write-rules/hooks/use-metric-write-rules';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';
import {
  MetricWriteRule,
  MetricWriteRuleFormData
} from '../types/MetricWriteRule';
import { metricWriteRuleDetailsBlock } from '../blocks/metric-write-rule-details-block';

export interface CreateMetricWriteRuleDialogProps extends DialogProps {
  onClose: () => void;
}

const content: DialogContentConfig = [
  {
    id: 'add-metric-write-rule',
    label: 'Add Metric Write Rule',
    blocks: [metricWriteRuleDetailsBlock]
  }
];

export function CreateMetricWriteRuleDialog({
  onClose
}: CreateMetricWriteRuleDialogProps) {
  const queryClient = useQueryClient();
  const { open: openErrorDialog } = useErrorDialog();
  const addToast = useRecordCreatedToast('metric_write_rule', {
    actions: []
  });

  const createMetricWriteRule = useMutation(
    (newRule: MetricWriteRuleFormData) =>
      api.post<MetricWriteRule>('/metric-write-rules', newRule),
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(METRIC_WRITE_RULES_QUERY_KEY);
        const newRule = response.data;
        addToast(newRule);
        onClose();
      },
      onError: (error) => {
        openErrorDialog(error);
      }
    }
  );

  const initialValues: MetricWriteRuleFormData = {
    name: '',
    description: '',
    condition: {
      code: 'module.exports = function (context) {\n  \n};'
    },
    extra_data: null,
    metrics: [],
    is_enabled: true,
    record_type: { id: '', label: '' },
    trigger_type: { id: 'created', label: 'Created' },
    is_attribution_selection_required: false
  };

  return (
    <RecordDialog
      title='Create Metric Write Rule'
      content={content}
      onClose={onClose}
      submitLabel='Create Metric Write Rule'
      handleSubmit={({ values }) => createMetricWriteRule.mutateAsync(values)}
      initialValues={initialValues}
    />
  );
}
