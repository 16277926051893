import React from 'react';
import { Field } from '@rexlabs/form';

import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { TextArea } from '@rexlabs/text-input';
import { ChangeReasonSelect } from 'view/components/inputs/selects/v4-selects/change-reason-select';

const validate = {
  definitions: {
    change_reason: { rules: 'required' },
    change_description: { rules: 'required' }
  }
};

export const reasonForChangeBlock: BlockConfig = {
  id: 'reason-change',
  title: 'Details',
  validate,
  Edit: () => {
    return (
      <Grid columns={1}>
        <Field
          name='change_reason'
          label='Reason for change'
          Input={ChangeReasonSelect}
          optional={false}
        />

        <Field
          name='change_description'
          label='Description'
          Input={TextArea}
          optional={false}
        />
      </Grid>
    );
  }
};
