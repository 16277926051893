import React from 'react';
import {
  RoleSystemPurpose,
  Roles
} from 'src/modules/authorization/roles/types/Role';
import { useSessionState } from 'src/modules/common/hooks/session-state';
// import { RoleNames, Roles } from 'src/modules/roles/types/role-types';

export function usePermission() {
  const sessionData = useSessionState();

  const roleName = sessionData.role?.name ?? 'none';
  const systemId = sessionData.role?.system_purpose?.id;

  const hasPermission = React.useCallback(
    (role: RoleSystemPurpose | RoleSystemPurpose[]) => {
      if (typeof role === 'string') return systemId === role;

      return role.some((roleName) => systemId == roleName);
    },
    [systemId]
  );

  const isStandardUser = systemId === Roles.Standard;
  const isAdminUser = systemId === Roles.Admin;
  const isFinancialUser = systemId === Roles.Financial;

  return {
    hasPermission,
    isAdminUser,
    isFinancialUser,
    isStandardUser,
    roleName
  };
}
