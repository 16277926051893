import React, { useEffect } from 'react';

import { Dialog as VividDialog, DialogProps } from '@rexlabs/dialog';

import { useFocusOnInputElementIndex } from 'utils/form/use-focus-on-input-element-index';

export interface AutoFocusDialogProps extends DialogProps {
  autofocus?: boolean;
  autofocusIndex?: number;
}

export function Dialog({
  children,
  isLoading = false,
  autofocus = true,
  autofocusIndex = 0,
  ...props
}: AutoFocusDialogProps) {
  const { contentRef, focusOnIndex } = useFocusOnInputElementIndex();

  useEffect(() => {
    // If the dialog is loading, we want to wait till there is content to focus on
    if (isLoading === false) {
      // Timeout is needed because of the way the dialog renders/mounts
      // within the PortalTarget
      // Also setting timeout to 100ms to match the dialog animation timing
      setTimeout(() => {
        focusOnIndex(autofocusIndex);
      }, 100);
    }
  }, [isLoading]);

  return (
    <VividDialog {...props}>
      <div
        style={{
          height: '100%',
          width: '100%'
        }}
        ref={autofocus ? contentRef : null}
      >
        {children}
      </div>
    </VividDialog>
  );
}
