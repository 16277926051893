import { Generator } from 'data/models/generator';
import { trustJournalEntryLineItemModel } from 'src/modules/trust-journal-entry-line-items/models/trust-journal-entry-line-item-model';
import { TrustLedger } from 'src/modules/trust-ledgers/types/trust-ledger-types';

const config = {
  entities: {
    related: {
      trust_account: {
        include: 'trust_account'
      },
      bank_account: {
        include: 'bank_account'
      },
      financial_summary: {
        include: 'financial_summary'
      },
      trust_journal_entry_line_items: {
        include: 'trust_journal_entry_line_items',
        model: [trustJournalEntryLineItemModel]
      },
      folio: {
        include: 'folio'
      }
    }
  }
};

export const trustLedgerModel = new Generator<TrustLedger>(
  'financials/trust-ledgers',
  config
).createEntityModel();
