import { DestructiveButton } from '@rexlabs/button';
import { useModelActions } from '@rexlabs/model-generator';
import { Bold } from '@rexlabs/text';
import React from 'react';
import { useStatusChangeAction } from 'src/modules/common/actions/status/use-status-change-action';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { statementsModel } from 'src/modules/statements/models/statements';
import {
  Statement,
  StatementStatus,
  StatementType
} from 'src/modules/statements/types';
import { formatDate } from 'utils/dates/format';
import { useBulkModelDownloadAction } from 'src/modules/common/actions/reporting/use-bulk-model-download-action';
import { FLAGS } from 'utils/feature-flags';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { useGetStatementViewActions } from 'src/modules/ownership-statements/common/actions/use-get-statement-view-actions';
import { useGetEofyStatementActions } from 'src/modules/ownership-statements/common/actions/use-get-eofy-statement-actions';
import { useGetStatementDownloadActions } from 'src/modules/ownership-statements/common/actions/use-get-statement-download-actions';
import { useGetSendStatementAction } from './use-get-send-statement-action';

export function useStatementActions(): (
  statement: Statement
) => ActionDeclaration[] {
  const { hasFeature } = useFeatureFlags();

  const modelBulkDownloadAction = useBulkModelDownloadAction();

  const { bulkDownloadItems } = useModelActions(statementsModel);
  const statusChangeAction = useStatusChangeAction(statementsModel);
  const getSendStatementAction = useGetSendStatementAction();

  const getStatementViewActions = useGetStatementViewActions();
  const getStatementDownloadActions = useGetStatementDownloadActions();
  const getEofyStatementActions = useGetEofyStatementActions();

  return function (statement: Statement) {
    const sendStatementAction = getSendStatementAction(statement);
    const { summaryViewActions, detailedViewActions } = getStatementViewActions(
      statement
    );

    const {
      summaryDownloadActions,
      detailedDownloadActions
    } = getStatementDownloadActions(statement);

    const shouldShowStatementPreview =
      hasFeature(FLAGS.STATEMENT_PREVIEW) &&
      statement.type.id === StatementType.YearlyOwnership;

    const bulkStatementData =
      statement.recipients?.items.map((contact) => {
        return {
          statement: {
            id: statement.id
          },
          recipient: {
            id: contact.id
          }
        };
      }) ?? [];

    const bulkSummaryDownloadAction = modelBulkDownloadAction({
      fetcher: () => {
        return bulkDownloadItems({
          data: bulkStatementData
        });
      },
      label: 'Download all',
      group: 'summary-income-statements'
    });

    const bulkDetailedDownloadAction = modelBulkDownloadAction({
      fetcher: () => {
        return bulkDownloadItems({
          reportId: 'income-detailed',
          data: bulkStatementData
        });
      },
      label: 'Download all',
      group: 'detailed-income-statements'
    });

    const voidAction = statusChangeAction<StatementStatus>({
      record: statement,
      status: StatementStatus.Void,
      dialogOptions: {
        SubmitButton: (props) => (
          <DestructiveButton {...props} data-testid='void-statement-button' />
        ),
        submitLabel: 'Void statement',
        title: 'Voiding issued statement',
        reasonLabel: 'Reason for voiding statement',
        description: (
          <>
            Please confirm you wish to void the statement for{' '}
            <Bold>{statement.object.display_name}</Bold>, for the statement end
            date of <Bold>{formatDate(statement.statement_to)}</Bold>.
          </>
        ),
        label: 'Void statement',
        intent: 'danger'
      },
      toastOptions: {
        type: 'warning',
        description: (
          <>
            <Bold>
              {statement.type.id === StatementType.PeriodicOwnership ||
              statement.type.id === StatementType.PeriodicAgency
                ? 'Periodic '
                : 'Financial year '}
              {statement.type.id === StatementType.PeriodicOwnership ||
              statement.type.id === StatementType.YearlyOwnership
                ? 'ownership'
                : 'agency'}{' '}
              statement
            </Bold>{' '}
            for <Bold>{statement.object.display_name}</Bold> has been{' '}
            <Bold>voided</Bold>.
          </>
        )
      }
    });

    const actions: ActionDeclaration[] = [];

    if (statement.status.id === StatementStatus.Issued) {
      actions.push(
        ...summaryViewActions,
        ...summaryDownloadActions,
        ...detailedViewActions,
        ...detailedDownloadActions,
        voidAction
      );

      if (statement.recipients && statement.recipients.items.length > 1) {
        actions.unshift(bulkSummaryDownloadAction);
        actions.unshift(bulkDetailedDownloadAction);
      }

      if (sendStatementAction) {
        actions.unshift(sendStatementAction);
      }
    }

    if (shouldShowStatementPreview) {
      actions.push(...getEofyStatementActions(statement));
    }

    return actions;
  };
}
