import React from 'react';

import { useModelActions } from '@rexlabs/model-generator';

import { Bill } from 'src/modules/bill-processing/types/Bill';
import {
  InvoiceBillFormValues,
  invoiceViewerBlock
} from 'src/modules/invoices/blocks/invoice-viewer';
import { InvoiceDetailsFormValues } from 'src/modules/invoices/components/invoice-details-form';
import { invoiceDetailsBlock } from 'src/modules/invoices/blocks/invoice-details';
import {
  invoiceLineItemsBlock,
  InvoiceLineItemsFormValues
} from 'src/modules/invoices/blocks/invoice-line-items';
import { reimbursementDetailsBlock } from 'src/modules/invoices/blocks/reimbursement-details';
import { reimbursementLineItemsBlock } from 'src/modules/invoices/blocks/reimbursement-line-items';
import { invoiceTabs } from 'src/modules/invoices/blocks/tab-block';
import { useInvoiceSubmitHandler } from 'src/modules/invoices/hooks/use-invoice-submit-handler';
import { getToday } from 'utils/dates/dates';
import { AWAITING_PAYMENT_INVOICE_STATUS } from 'utils/static-value-lists/invoice-status';
import { useDefaultBankAccount } from 'view/hooks/api/use-default-bank-account';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useToast } from 'view/components/@luna/notifications/toast';
import { useGetResetTableUrlParams } from 'view/components/table/hooks/use-get-reset-table-url-params';
import { defaultNotesRecordTableHashParamKey } from 'src/modules/note/components/notes-list';
import { InvoiceBlockProps } from 'src/modules/invoices/types/invoice-block';
import { financialsInvoicesModel } from 'data/models/entities/financials/invoices';
import { MultiItemDialog } from '../../../view/components/dialogs/multi-item-dialog/multi-item-dialog';
import { financialsUploadedBillsModel } from '../models/uploaded-bills';
import { ProcessBillsButtonGroup } from '../components/process-bill-button-group';
import { addNoteBlock } from '../blocks/add-note-block';
import { billProcessingNotesTableBlock } from '../components/bill-processing-notes-table';
import { InitialInvoiceValues } from '../types/InitialInvoiceValues';

export type CreateInvoiceFormValues = InvoiceDetailsFormValues &
  InvoiceLineItemsFormValues &
  InvoiceBillFormValues;

type ProcessBillsDialogProps = {
  initialValues?: InitialInvoiceValues;
  isLoading?: boolean;
  onClose?: () => void;
  billsToBeProcessed: Bill[];
} & Pick<InvoiceBlockProps, 'suggestions'>;

const NEEDS_MORE_INFO = Symbol('NEEDS_MORE_INFO');

export function ProcessBillsDialog({
  initialValues: externalInitialValues,
  isLoading,
  onClose,
  billsToBeProcessed,
  suggestions,
  ...props
}: ProcessBillsDialogProps) {
  const { addToast } = useToast();

  const isFirstChangeRef = React.useRef(false);
  const { refreshLists: refreshBillsLists, updateStatus } = useModelActions(
    financialsUploadedBillsModel
  );
  const { refreshLists: refreshInvoicesLists } = useModelActions(
    financialsInvoicesModel
  );

  const resetTableUrlParams = useGetResetTableUrlParams();

  const [activeTab, setActiveTab] = React.useState<
    'invoice' | 'reimbursement' | 'notes'
  >('invoice');
  const [
    isReimbursementEmptyStateVisible,
    setIsReimbursementEmptyStateVisible
  ] = React.useState(true);

  const {
    defaultBankAccount,
    isLoading: isDefaultBankLoading
  } = useDefaultBankAccount();

  const content = [
    {
      id: 'basics',
      label: 'Basics',
      blocks: [
        invoiceTabs,
        invoiceDetailsBlock,
        invoiceLineItemsBlock,
        reimbursementDetailsBlock,
        reimbursementLineItemsBlock,
        billProcessingNotesTableBlock,
        addNoteBlock
      ],
      leftBlocks: [invoiceViewerBlock]
    }
  ];

  const initialValues = billsToBeProcessed.map((billToBeProcessed) => {
    return {
      status: AWAITING_PAYMENT_INVOICE_STATUS,
      is_tax_included: true,
      invoice_date: getToday(),
      uploaded_bill: billToBeProcessed,
      bank_account: defaultBankAccount,
      bill_priority: {
        id: 'normal',
        label: 'Normal'
      },
      reimbursed_is_tax_included: true,
      reimbursed_invoice_date: getToday(),
      reimbursed_bank_account: defaultBankAccount,
      reimbursed_bill_priority: {
        id: 'normal',
        label: 'Normal'
      },
      reimbursed_to: externalInitialValues?.payable_by,
      ...(billToBeProcessed.draft_invoice ?? {}),
      ...externalInitialValues
    };
  });

  const handleFirstChange = React.useCallback(() => {
    isFirstChangeRef.current = true;
  }, []);

  const handleReimbursementEmptyState = React.useCallback((state: boolean) => {
    setIsReimbursementEmptyStateVisible(state);
  }, []);

  const clickedActionRef = React.useRef<symbol | null>(null);

  const handleInvoiceSubmit = useInvoiceSubmitHandler({
    isBillProcessingDialog: true,
    async onSave() {
      await refreshBillsLists();
      await refreshInvoicesLists();
    }
  });

  const handleNeedsMoreInfo = React.useCallback(() => {
    clickedActionRef.current = NEEDS_MORE_INFO;
  }, []);

  const needsMoreInfoSubmitHandler: RecordSubmitHandler = React.useCallback(
    async ({ values }) => {
      try {
        await updateStatus({
          id: values.uploaded_bill.id,
          status: 'needs_more_detail'
        });

        addToast({
          description: `Status has been changed successfully`
        });

        refreshBillsLists();

        return true;
      } catch (error) {
        //
      }
    },
    []
  );

  const handleSubmit = React.useCallback((args) => {
    switch (clickedActionRef.current) {
      case NEEDS_MORE_INFO: {
        return needsMoreInfoSubmitHandler(args);
      }

      default: {
        return handleInvoiceSubmit(args);
      }
    }
  }, []);

  const handleClose = React.useCallback(() => {
    resetTableUrlParams({
      resetPageTableKeys: [defaultNotesRecordTableHashParamKey]
    });

    refreshBillsLists();

    onClose?.();
  }, []);

  return (
    <MultiItemDialog
      // This is provided to reset the initialValues when default bank account is available
      key={`${isLoading}-${isDefaultBankLoading}`}
      content={content}
      data={initialValues}
      nextButtonLabel={'Skip this bill'}
      removeButtonLabel={'Remove bill'}
      recordTypePluralized={'bills'}
      subTitleSuffix={'to process'}
      onClose={handleClose}
      title={'Create invoice'}
      isLoading={isLoading || isDefaultBankLoading}
      handleSubmit={handleSubmit}
      size={'xl'}
      ButtonGroup={ProcessBillsButtonGroup}
      buttonGroupProps={{
        handleNeedsMoreInfo
      }}
      blockProps={{
        activeTab,
        setActiveTab,
        isReimbursementEmptyStateVisible,
        handleReimbursementEmptyState,
        isFirstChange: isFirstChangeRef.current,
        handleFirstChange: handleFirstChange,
        dialogType: 'invoice',
        suggestions
      }}
      {...props}
    />
  );
}
