import { Generator } from 'data/models/generator';
import { auditableConfig } from 'src/modules/common/auditable/auditable-config';
import { api } from 'utils/api/api-client';
import _ from 'lodash';
import { Bill } from '../types/Bill';
import { BillStatus } from '../types/BillStatus';

const config = {
  entities: {
    related: {
      file: {
        include: 'file'
      },
      invoice: {
        include: 'invoice'
      },
      draft_invoice: {
        include: [
          'draft_invoice',
          'draft_invoice.line_items',
          'draft_invoice.line_items.payable_to_chart_of_accounts_account',
          'draft_invoice.line_items.payable_by_chart_of_accounts_account',
          'draft_invoice.line_items.payable_to_property',
          'draft_invoice.line_items.payable_by_property',
          'draft_invoice.line_items.tax_type',
          'draft_invoice.task.details',
          'draft_invoice.task.property'
        ].join(',')
      },
      ...auditableConfig
    }
  }
};

const actionCreators = {
  updateStatus: {
    request: ({ id, status }: { id: string; status: BillStatus }) =>
      api.patch(`financials/uploaded-bills/${id}/status`, {
        status: {
          id: status
        }
      }),
    reduce: _.identity
  },
  importBills: {
    request: ({ fileIds }: { fileIds: string[] }) =>
      api.post('financials/uploaded-bills?include=file', {
        files: fileIds.map((fileId) => {
          return { id: fileId };
        })
      }),
    reduce: _.identity
  }
};

export const financialsUploadedBillsModel = new Generator<
  Bill,
  typeof actionCreators
>('financials/uploaded-bills', config).createEntityModel({ actionCreators });
