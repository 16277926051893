import { ContentConfig } from 'view/components/record-screen/types';
import { customReportDetailsBlock } from '../blocks/custom-report-details-block';
import { customReportGridBlock } from '../blocks/custom-report-grid-block';

export const content: ContentConfig = [
  {
    label: 'General',
    items: [
      {
        id: 'overview',
        label: 'Overview',
        blocks: [customReportDetailsBlock, customReportGridBlock]
      }
    ]
  }
];
