import React from 'react';

import { RecordScreen } from 'view/components/record-screen';
import { useSessionState } from 'src/modules/common/hooks/session-state';
import { UniversalUser } from 'data/models/custom/session';
import { ContentConfig } from 'view/components/record-screen/types';
import _ from 'lodash';
import { emailBlock } from '../blocks/email';
import { ProfileSettingsTitleBlock } from '../components/profile-settings-title-block';

const profileSettingsContent: ContentConfig = [
  {
    label: 'User',
    items: [
      {
        id: 'user',
        label: 'User',
        blocks: [emailBlock]
      }
    ]
  }
];

export function ProfileSettingsScreen() {
  const sessionData = useSessionState();

  return (
    <RecordScreen
      privilege={'settings.read'}
      isLoading={status === 'loading'}
      data={{
        ...sessionData.user
      }}
      handleSubmit={_.stubTrue}
      content={profileSettingsContent}
      titleBlock={
        <ProfileSettingsTitleBlock user={sessionData.user as UniversalUser} />
      }
    />
  );
}
