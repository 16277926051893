import { StyleSheet, useStyles } from '@rexlabs/styling';
import React from 'react';
import { Field } from '@rexlabs/form';
import { ValueListSelect } from 'view/components/inputs/selects/value-list';
import { Checkbox } from '@rexlabs/checkbox';
import { PrivilegeCategory, PrivilegeDescriber } from '../hooks/use-privileges';

const defaultStyles = StyleSheet({
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    '& tr': {
      borderBottom: ({ token }) => `1px solid ${token('palette.grey.300')}`,
      '& td': {
        padding: '0.5rem',
        '&:nth-child(1)': {
          width: 250
        },
        '&:nth-child(2)': {
          width: 'auto'
        },
        '&:nth-child(3)': {
          width: 300
        }
      }
    },
    '& thead': {
      textAlign: 'left',
      '& th': {
        padding: '0.5rem'
      }
    }
  },
  sectionHeader: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    margin: '1rem 0',
    background: ({ token }) => token('palette.grey.100')
  }
});

export type PrivilegesTableProps = {
  data: PrivilegeDescriber[];
  category: PrivilegeCategory;
  readonly?: boolean;
};

export function PrivilegesTable({
  data,
  category,
  readonly
}: PrivilegesTableProps) {
  const s = useStyles(defaultStyles);
  return (
    <table {...s('table')}>
      <thead>
        <tr>
          <th>Privilege</th>
          <th>Description</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {data
          ?.filter((describer) => describer.category.id === category)
          .map((privilegeDescriber) => (
            <>
              <tr {...s('sectionHeader')}>
                <td colSpan={3}>{privilegeDescriber.label}</td>
              </tr>
              {privilegeDescriber.privilege_sets.data.map((set) => (
                <tr key={set.id}>
                  <td>{set.label}</td>
                  <td>{set.description}</td>
                  <td>
                    <Field
                      optional={false}
                      name={`privilege_sets.${set.id}`}
                      Input={ValueListSelect}
                      inputProps={{
                        type: set.id,
                        disabled: readonly
                      }}
                    />
                  </td>
                </tr>
              ))}
              {privilegeDescriber.individual_privileges.data.map((priv) => (
                <tr key={priv.id}>
                  <td>{priv.label}</td>
                  <td>{priv.description}</td>
                  <td>
                    <Field
                      optional={false}
                      name={`privileges.${priv.id}`}
                      Input={Checkbox}
                      inputProps={{
                        disabled: readonly
                      }}
                    />
                  </td>
                </tr>
              ))}
            </>
          ))}
      </tbody>
    </table>
  );
}
