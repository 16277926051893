import { NormalisedItem, Select, SelectProps } from '@rexlabs/select';
import * as React from 'react';
import { useRecordsQuery } from 'src/lib/react-query/hooks/use-records-query';
import { Role } from 'src/modules/authorization/roles/types/Role';

export function useUserRoleQuery() {
  return useRecordsQuery<Role>(['roles'], {
    staleTime: 60 * 1000
  });
}

// The record select adds a label and value to the record, so the item is sometimes extended
export function UserRoleSelect<ExtendedRole extends Role>(
  props: SelectProps<ExtendedRole> & {
    mapper?: (roles: Role[]) => ExtendedRole[];
  }
) {
  const { data } = useUserRoleQuery();
  const roles = data as ExtendedRole[];

  const normaliser = (item: ExtendedRole): NormalisedItem => ({
    id: item.id,
    label: item.name
  });

  return (
    <Select<ExtendedRole>
      normaliser={normaliser}
      items={props?.mapper ? props?.mapper(roles || []) : roles ?? []}
      {...props}
    />
  );
}
