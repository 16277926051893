import React from 'react';

import { ListScreen } from 'view/components/list-screen/list-screen';
import LoadingState from 'view/components/states/loading';
import { Card } from 'view/components/card';
import RenderLoading from 'view/components/@luna/render-loading';

import { useBankAccountSelectActions } from 'src/modules/bank-accounts/hooks/use-bank-account-select-actions';
import ROUTES from 'routes/app';
import { BankWithdrawalTable } from '../components/bank-withdrawal-table';
import { useBankWithdrawalsStats } from '../hooks/use-bank-withdrawals-stats';

export function BankWithdrawalsListScreen() {
  const {
    isLoading: isBankAccountsLoading,
    bankAccount,
    actions
  } = useBankAccountSelectActions(ROUTES.BANK_WITHDRAWALS_LIST);

  const stats = useBankWithdrawalsStats(bankAccount);

  return (
    <RenderLoading isLoading={isBankAccountsLoading} LoadingView={LoadingState}>
      <ListScreen
        privilege={'bank-withdrawals.read'}
        title='Bank withdrawals'
        actions={actions}
        stats={stats}
      >
        <Card title='Outstanding withdrawals to bank'>
          {bankAccount && (
            <BankWithdrawalTable bankAccountId={bankAccount.id} />
          )}
        </Card>
      </ListScreen>
    </RenderLoading>
  );
}
