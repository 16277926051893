import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { usePermission } from 'src/modules/authorization/roles/hooks/use-permission';
import { Bill } from '../../types/Bill';
import { useBillProcessingDeleteAction } from '../action-declarations/use-bill-processing-delete-action';
import { useGetProcessBillAction } from '../action-declarations/use-get-process-bill-action';
import { useGetReviewBillAction } from '../action-declarations/use-get-review-bill-action';

export function useGetBillProcessingActions() {
  const { isAdminUser, isFinancialUser, isStandardUser } = usePermission();
  const getProcessBillAction = useGetProcessBillAction();
  const getBillProcessingDeleteAction = useBillProcessingDeleteAction();
  const getBillProcessingReviewAction = useGetReviewBillAction();

  return (billsToBeProcessed: Bill[]): ActionDeclaration[] => {
    const actions = [getProcessBillAction(billsToBeProcessed)];

    if (isAdminUser || isFinancialUser) {
      actions.push(getBillProcessingDeleteAction(billsToBeProcessed));
    }

    const hasNeedsMoreDetailBills =
      billsToBeProcessed.length > 0 &&
      billsToBeProcessed.every(
        (bill) => bill.status.id === 'needs_more_detail'
      );

    if (hasNeedsMoreDetailBills && isStandardUser) {
      actions.push(getBillProcessingReviewAction(billsToBeProcessed));
    }

    return actions;
  };
}
