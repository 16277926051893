import React from 'react';
import { ContactFlag } from 'view/components/record-flags/contact-flag';
import { Task } from 'src/modules/tasks/common/types/Task';
import { RecordFlag } from 'view/components/record-flags/record-flag';
import { itemIsContact } from 'src/modules/tasks/common/components/checklist-assignee-select';
import { EntryLink } from '../components/entry-link';
import { EntryList } from '../components/entry-list';

export interface JobDetailsSectionProps {
  task: Task<'task_maintenance'> | 'empty';
}

export function JobDetailsSection({ task }: JobDetailsSectionProps) {
  if (task === 'empty') {
    return null;
  }

  const reportedByType = task?.details?.reported_by?.__record_type;

  return (
    <>
      {task?.managed_by && (
        <EntryList headline={'managed by'}>
          <EntryLink
            type={task.managed_by.__record_type}
            id={task.managed_by.id}
          >
            {itemIsContact(task.managed_by) ? (
              <ContactFlag contact={task.managed_by} />
            ) : (
              <RecordFlag
                record={task.managed_by}
                recordType={task.managed_by.__record_type}
              />
            )}
          </EntryLink>
        </EntryList>
      )}

      {task?.details?.reported_by && (
        <EntryList headline={'reported by'}>
          <EntryLink
            type={task.details.reported_by.__record_type}
            id={task.details.reported_by.id}
          >
            {reportedByType === 'contact' ? (
              <ContactFlag contact={task.details.reported_by} />
            ) : (
              <RecordFlag
                record={task.details.reported_by}
                recordType={task.details.reported_by.__record_type}
              />
            )}
          </EntryLink>
        </EntryList>
      )}
    </>
  );
}
