import { Columns } from 'src/view/components/table';

export const customReportColumns: Columns = [
  {
    id: 'label',
    Header: 'Label',
    width: 300
  },
  {
    id: 'resource',
    Header: 'Resource',
    type: 'value',
    width: 250
  },
  { id: 'description', Header: 'Description' }
];
