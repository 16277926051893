import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { Field } from '@rexlabs/form';
import { InactiveAgencyFeeTemplatesForRecord } from 'view/components/inputs/selects/v4-selects/inactive-agency-fee-templates-for-record';
import { useTranslation } from 'react-i18next';

export const inactivePropertyOwnershipFeesBlock: BlockConfig = {
  id: 'inactive-ownership-fees',
  title: 'Inactive ownership fees',
  validate: {
    definitions: {
      inactive_property_ownership_fees: {
        rules: 'required'
      }
    }
  },
  Edit: ({ blockProps }) => {
    const { t } = useTranslation();

    return (
      <Grid columns={1}>
        <Field<typeof InactiveAgencyFeeTemplatesForRecord>
          id='inactive_property_ownership_fees'
          name='inactive_property_ownership_fees'
          label={
            t(
              'property-ownerships.management-agreement-fees.label.plural'
            ) as string
          }
          Input={InactiveAgencyFeeTemplatesForRecord}
          inputProps={{
            entityFeeType: 'property_ownership',
            recordId: blockProps.propertyOwnershipId,
            multi: true
          }}
        />
      </Grid>
    );
  }
};
