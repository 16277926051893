import React from 'react';
import { RecordType } from 'data/models/types';

import { TenancyIcon } from '../icons/tenancy';
import { OwnershipsIcon } from '../icons/ownerships';
import { ContactIcon } from '../icons/contact';
import { PropertyIcon } from '../icons/property';
import { InvoicesIcon } from '../icons/invoices';
import { FinancialsIcon } from '../icons/financials';
import { ManagementAgreementIcon } from '../icons/management-agreement';
import { LeaseAgreementIcon } from '../icons/lease-agreement';
import { PlaceholderIcon } from '../icons/placeholder';
import { BankingAccountIcon } from '../icons/banking-account';
import { ReceiptIcon } from '../icons/receipt';
import { TaskIcon } from '../icons/task';
import { MaintenanceIcon } from '../icons/maintenance';
import { InspectionsIcon } from '../icons/inspections';
import { LeaseReviewIcon } from '../icons/lease-review';
import { MoveInIcon } from '../icons/move-in';
import { MoveOutIcon } from '../icons/move-out';
import { ComplianceIcon } from '../icons/compliance';
import { QuoteIcon } from '../icons/quote';
import { WorkOrderIcon } from '../icons/work-order';
import { CalendarIcon } from '../icons/calendar';
import DisbursementsIcon from '../icons/disbursements';
import PortfolioIcon from '../icons/portfolio';
import ContactsIcon from '../icons/contacts';

type ExtractProps<Component> = Component extends React.ComponentType<
  infer Props
>
  ? Props
  : Component;

type AvailableSizes =
  | ExtractProps<typeof OwnershipsIcon>['size']
  | ExtractProps<typeof ManagementAgreementIcon>['size']
  | ExtractProps<typeof ContactIcon>['size']
  | ExtractProps<typeof PropertyIcon>['size']
  | ExtractProps<typeof InvoicesIcon>['size']
  | ExtractProps<typeof FinancialsIcon>['size']
  | ExtractProps<typeof TenancyIcon>['size']
  | ExtractProps<typeof LeaseAgreementIcon>['size']
  | ExtractProps<typeof BankingAccountIcon>['size']
  | ExtractProps<typeof ReceiptIcon>['size']
  | ExtractProps<typeof TaskIcon>['size']
  | ExtractProps<typeof MaintenanceIcon>['size']
  | ExtractProps<typeof InspectionsIcon>['size']
  | ExtractProps<typeof LeaseReviewIcon>['size']
  | ExtractProps<typeof MoveInIcon>['size']
  | ExtractProps<typeof MoveOutIcon>['size']
  | ExtractProps<typeof ComplianceIcon>['size']
  | ExtractProps<typeof QuoteIcon>['size']
  | ExtractProps<typeof WorkOrderIcon>['size']
  | ExtractProps<typeof CalendarIcon>['size'];

export interface RecordIconProps {
  recordType: RecordType;
  size?: AvailableSizes;
}

export function RecordIcon({ recordType, ...props }: RecordIconProps) {
  switch (recordType) {
    case 'ownership':
      return <OwnershipsIcon {...props} />;
    case 'property_ownership':
      return <ManagementAgreementIcon {...props} />;
    case 'tenancy':
      return <TenancyIcon {...props} />;
    case 'property_tenancy':
      return <LeaseAgreementIcon {...props} />;
    case 'contact':
      return <ContactIcon {...props} />;
    case 'property':
      return <PropertyIcon {...props} />;
    case 'invoice':
      return <InvoicesIcon {...props} />;
    case 'bank_account':
    case 'bank_deposit':
      return <BankingAccountIcon {...props} />;
    case 'trust_journal_entry':
    case 'credit_note':
      return <ReceiptIcon {...props} />;
    case 'todo':
    case 'task_custom_task':
    case 'task':
      return <TaskIcon {...props} />;
    case 'task_arrears':
      return <CalendarIcon {...props} />;
    case 'task_inspection':
      return <InspectionsIcon {...props} />;
    case 'task_lease_review':
      return <LeaseReviewIcon {...props} />;
    case 'task_move_in':
      return <MoveInIcon {...props} />;
    case 'task_move_out':
      return <MoveOutIcon {...props} />;
    case 'task_supplier_compliance':
    case 'task_property_compliance':
      return <ComplianceIcon {...props} />;
    case 'task_maintenance':
      return <MaintenanceIcon {...props} />;
    case 'task_quote':
      return <QuoteIcon {...props} />;
    case 'task_work_order':
      return <WorkOrderIcon {...props} />;
    case 'disbursement':
      return <DisbursementsIcon {...props} />;
    case 'portfolio':
      return <PortfolioIcon {...props} />;
    case 'user_group':
      return <ContactsIcon {...props} />;
    default:
      return <PlaceholderIcon {...props} />;
  }
}
