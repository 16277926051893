import { FolioCommissionTemplateTier } from '../types/FolioCommissionTemplate';
import { FolioCommissionTemplateTierFormData } from '../types/FolioCommissionTemplateFormData';

export function mapTemplateTierToTierFormData(
  tiers: FolioCommissionTemplateTier[]
): Array<FolioCommissionTemplateTierFormData> {
  return tiers.map((tier) => {
    const { type, to, from } = tier;

    const commissionAmount = tier.amount;
    const typeId = type?.id;

    return {
      amount: {
        type: { id: typeId === 'flat_fee' ? 'fixed_amount' : typeId },
        value: commissionAmount
      },
      range: {
        to: to,
        from: from
      }
    };
  });
}
