import React from 'react';
import invariant from 'invariant';

import { Field } from '@rexlabs/form';

import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { ChecklistTemplateSelect } from 'view/components/inputs/selects/v4-selects/checklist-template-select';
import { FLAGS } from 'utils/feature-flags';

import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { ChecklistTemplate } from '../../settings/types/ChecklistTemplate';
import { Task } from '../types/Task';
import { TaskType } from '../types/TaskType';
import { InspectionType } from '../../inspections/types/InspectionType';
import { getDiscriminatorFields } from '../../settings/hooks/use-create-checklist-template';

export type ChecklistTemplateSelectBlockFormValues = {
  checklists?: ChecklistTemplate[];
  inspection_type?: string;
};

/**
 * NOTE: please note, you MUST provide a taskTypeId through the blockProps for this block to work
 */
export const checklistTemplateSelectBlock: BlockConfig<
  Task<TaskType>,
  {
    taskTypeId: TaskType;
    inspectionType?: InspectionType;
    customTaskTypeId?: string | null;
  },
  ChecklistTemplateSelectBlockFormValues
> = {
  id: 'checklist',
  title: 'Checklist',
  Edit: ({ blockProps, forms }) => {
    const { hasFeature } = useFeatureFlags();

    invariant(
      blockProps?.taskTypeId,
      'You must provide a taskTypeId to the checklist block'
    );

    const taskTypeId = blockProps.taskTypeId;

    // Apply discriminators for things with specific checklists per type, like custom tasks, and inspections
    const discriminators = getDiscriminatorFields(
      taskTypeId,
      blockProps.inspectionType ?? forms?.['details']?.values?.inspection_type,
      blockProps?.customTaskTypeId ?? null
    );

    const discriminatorFilters =
      discriminators.discriminator_field && discriminators.discriminator_value
        ? [
            {
              field: 'discriminator_field',
              op: 'in' as const,
              value: [discriminators.discriminator_field]
            },
            {
              op: 'or' as const,
              value: [
                {
                  field: 'discriminator_value',
                  op: 'eq' as const,
                  value: discriminators.discriminator_value
                },
                {
                  field: 'discriminator_value',
                  op: 'eq' as const,
                  value: 'null'
                }
              ]
            }
          ]
        : [];

    return (
      <Grid columns={2}>
        <Field<typeof ChecklistTemplateSelect>
          Input={ChecklistTemplateSelect}
          id='checklists'
          name='checklists'
          label='Select checklist(s)'
          optional={true}
          inputProps={{
            dialogProps: {
              taskTypeId: blockProps!.taskTypeId
            },
            disableFixture: hasFeature(FLAGS.SMART_CHECKLISTS_SETTINGS)
              ? true
              : false,
            multi: true,
            searchFilter: [
              {
                field: 'task_type_id',
                op: 'eq',
                value: taskTypeId
              },
              {
                field: 'is_default',
                op: 'neq',
                value: 'true'
              },
              ...discriminatorFilters
            ]
          }}
        />
      </Grid>
    );
  }
};
