import React, { useMemo } from 'react';
import { query, useEntityQuery } from '@rexlabs/model-generator';

import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { RecordScreen } from 'view/components/record-screen';

import { useRecordScreenSubmitHandler } from 'view/hooks/use-record-screen-submit-handler';
import { TaskRightBarOverride } from 'src/modules/tasks/common/components/right-bar-override';
import { getContent } from '../data/content';
import { SupplierComplianceTitleBlock } from '../components/supplier-compliance-title-block';
import { tasksModel } from '../../common/models/tasks-model';
import { mapSupplierComplianceFormDataToTaskCreateRequest } from '../mappers/map-supplier-compliance-form-data-to-task-create-request';
import { SupplierComplianceTask } from '../types/SupplierComplianceTask';
import { getSearchResultItemFromManagedBy } from '../../common/utils/get-search-result-item-from-managed-by';

const getSupplierComplianceQuery = (id: string) => query`{
  ${tasksModel} (id: ${id}) {
    id
    supplierComplianceDetails
    property {
      active_property_tenancy {
        tenancy {
          id
          tenants
        }
      }
    }
    managed_by
    task_links
    created_by
    updated_by
  }
}`;

interface PropertyComplianceDetailsProps {
  taskId?: string;
}

export function SupplierComplianceDetails({
  taskId
}: PropertyComplianceDetailsProps) {
  const breadcrumbs = [{ type: BREADCRUMBS.TASK_SUPPLIER_COMPLIANCE }];
  const query = useMemo(() => getSupplierComplianceQuery(taskId!), [taskId]);

  const { status, data: rawData, actions } = useEntityQuery(query, {
    throwOnError: false
  });

  const handleSubmit = useRecordScreenSubmitHandler(
    async ({ changedValues }) => {
      const dataToSubmit = mapSupplierComplianceFormDataToTaskCreateRequest(
        changedValues
      );
      await actions.updateItem({
        id: taskId,
        data: dataToSubmit
      });
    }
  );

  const data: SupplierComplianceTask | undefined = rawData;
  const initialValues = useMemo(
    () =>
      data
        ? {
            ...data,
            managed_by: getSearchResultItemFromManagedBy(data.managed_by)
          }
        : undefined,
    [data]
  );
  const titleBlock = <SupplierComplianceTitleBlock task={data} />;

  const content = useMemo(() => (data ? getContent(data) : []), [data]);

  return (
    <RecordScreen
      privilege={'tasks.supplier-compliance.read'}
      isLoading={status === 'loading'}
      data={data}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      content={content}
      titleBlock={titleBlock}
      breadcrumbs={breadcrumbs}
      RightBarOverride={TaskRightBarOverride}
    />
  );
}
