import { useWhereabouts } from '@rexlabs/whereabouts';
import { Columns } from 'src/view/components/table';
import { Task } from 'src/modules/tasks/common/types/Task';
import { TaskType } from 'src/modules/tasks/common/types/TaskType';
import { AnyTaskProgressCell } from 'src/modules/tasks/common/components/any-task-progress-cell';
import { TaskTypeIconCell } from 'src/modules/tasks/common/components/task-type-icon-cell';
import { pluralize } from 'utils/formatters';
import { SummaryCell } from '../components/summary-cell';
import {
  closedDate,
  createdBy,
  createdDate,
  managedBy
} from '../../common/utils/common-columns';

const typeIcon = {
  id: 'type_icon',
  Header: '',
  accessor: (item: Task<TaskType>) => item.type,
  Cell: TaskTypeIconCell,
  width: 76,
  toCsv: (item: Task<TaskType>) => item.type?.label
};

const type = {
  id: 'type',
  Header: 'Type',
  accessor: (item: Task<TaskType>) => item.type?.label
};

const status = {
  id: 'status',
  Header: 'Task progress',
  Cell: AnyTaskProgressCell,
  accessor: (item: Task<TaskType>) => item,
  width: 200,
  toCsv: (item: Task<TaskType>) => item?.status?.label
};

const dueDate = {
  id: 'due_date',
  Header: 'Due date',
  type: 'date',
  width: 200
} as const;

const followUpDate = {
  id: 'follow_up_date',
  Header: 'Follow up date',
  accessor: (item: Task<TaskType>) => item.follow_up_date,
  type: 'date',
  width: 140
} as const;

const summary = {
  id: 'summary',
  Header: 'Summary',
  Cell: SummaryCell,
  accessor: (item) => item,
  width: 380,
  toCsv: (item: Task<TaskType>) => item.summary
};

const priority = {
  id: 'priority_id',
  type: 'value',
  Header: 'Priority',
  accessor: (item: Task<TaskType>) => item.priority,
  toCsv: (item: Task<TaskType>) => item?.priority?.label
} as const;

export function useAllTasksColumns(): Columns {
  const whereabouts = useWhereabouts();
  const tab = whereabouts.hashQuery?.tab;

  if (tab === 'closed') {
    return [
      typeIcon,
      type,
      summary,
      closedDate,
      dueDate,
      managedBy,
      priority,
      createdBy,
      createdDate
    ];
  }

  return [
    typeIcon,
    type,
    summary,
    followUpDate,
    dueDate,
    status,
    managedBy,
    priority,
    createdBy,
    createdDate
  ];
}

export function useTasksWithAssignedSubtasksColumns(): Columns {
  return [
    typeIcon,
    summary,
    {
      id: 'subtask_count',
      Header: 'Assigned to me',
      accessor: (item: Task<TaskType>) =>
        `${item.subtask_summary?.count_assigned_to_user} ${pluralize(
          'subtask',
          item.subtask_summary?.count_assigned_to_user ?? 0
        )}`
    },
    {
      id: 'next_subtask_due_date',
      Header: 'Next subtask due date',
      type: 'date',
      accessor: (item: Task<TaskType>) => item.subtask_summary?.next_due_date
    },
    {
      ...dueDate,
      Header: 'Task due date'
    },
    status,
    managedBy,
    createdBy,
    createdDate
  ];
}
