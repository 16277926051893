import { useQuery } from 'react-query';
import { api } from 'utils/api/api-client';
import { CustomValidation } from '../types/CustomValidation';

export const CUSTOM_VALIDATIONS_QUERY_KEY = 'customValidations';

export function useCustomValidations() {
  return useQuery<CustomValidation[]>(
    CUSTOM_VALIDATIONS_QUERY_KEY,
    async () => {
      const response = await api.get<CustomValidation[]>('/custom-validations');
      return response.data;
    },
    { staleTime: Infinity }
  );
}
