import React from 'react';
import { RecordTable } from 'view/components/table';
import { Message } from '@rexlabs/notifications';
import { EmptyCard } from 'view/components/record-screen/cards/empty-card';
import EmptyTable from 'src/assets/illustrations/empty-table.svg';
import { useToken } from '@rexlabs/styling';
import { matchColumns } from '../utils/match-columns';
import { SelectMatchActionCell } from './select-match-action-cell';

const AvailableMatchesEmptyState = () => {
  const token = useToken();

  return (
    <div style={{ marginTop: token('spacing.s') }}>
      <EmptyCard hasGreyBackground={true}>
        <Message title='No matches' Illustration={EmptyTable}>
          Type in the search bar above to find existing matches in Rex Property
          Management
        </Message>
      </EmptyCard>
    </div>
  );
};

export function AvailableMatchesTable({ data, blockProps, isLoading }) {
  const {
    availableMatches,
    setAvailableMatches,
    selectedMatches,
    setSelectedMatches
  } = blockProps;

  return (
    <RecordTable
      items={data}
      isLoading={isLoading}
      Empty={AvailableMatchesEmptyState}
      columns={[
        ...matchColumns,
        {
          id: 'action',
          Cell: SelectMatchActionCell,
          accessor: (item) => {
            return {
              item: item,
              selectedMatches,
              setSelectedMatches,
              availableMatches,
              setAvailableMatches
            };
          },
          width: 70,
          sticky: 'right'
        }
      ]}
    />
  );
}
