import { useErrorDialog } from '@rexlabs/dialog';

import { useToast } from 'view/components/@luna/notifications/toast';
import { useModelActions } from '@rexlabs/model-generator';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { Bill } from '../../types/Bill';
import { financialsUploadedBillsModel } from '../../models/uploaded-bills';

export function useGetReviewBillAction() {
  const errorDialog = useErrorDialog();
  const { addToast } = useToast();

  const { refreshLists, updateStatus } = useModelActions(
    financialsUploadedBillsModel
  );

  return (billsToBeProcessed: Bill[]): ActionDeclaration => {
    return {
      label: 'Ready for review',
      handleAction: async () => {
        try {
          const [bill] = billsToBeProcessed;

          await updateStatus({
            id: bill.id,
            status: 'ready_for_processing'
          });

          addToast({
            description: `Status has been changed successfully`
          });

          await refreshLists();

          return true;
        } catch (error) {
          errorDialog.open(error);
        }
      }
    };
  };
}
