import * as React from 'react';
import { TabbedTable, ListTable } from 'view/components/table';
import { Card } from 'view/components/card';
import { ListScreen } from 'view/components/list-screen/list-screen';
import { query } from '@rexlabs/model-generator';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { MESSAGE_TEMPLATE_ROUTES } from '../routes';

import { messageTemplateColumns } from '../utils/message-template-columns';
import { useGetMessageTemplateActions } from '../hooks/action-menu-items/use-get-message-template-actions';
import { useGetCreateMessageTemplateAction } from '../hooks/action-declarations/use-get-create-message-template-action';
import { messageTemplatesModel } from '../models/message-templates-model';
import { messageTemplateQueryFragment } from '../data/message-template-query-fragment';

export const getMessageTemplateQuery = () => query`{
  ${messageTemplatesModel} {
    ${messageTemplateQueryFragment}
  }
}`;

export function MessageTemplateList() {
  const getMessageTemplateActions = useGetMessageTemplateActions();
  const getCreateMessageTemplateAction = useGetCreateMessageTemplateAction();

  const { getSort, getFilters } = useTableFilters('message-templates');

  const getRowLinkProps = ({ item }) => {
    return {
      to: MESSAGE_TEMPLATE_ROUTES.MESSAGE_TEMPLATE_DETAILS,
      params: { messageTemplateId: item.id }
    };
  };

  const getActionMenuItems = React.useCallback(
    ({ item }) => getMessageTemplateActions(item),
    [getMessageTemplateActions]
  );

  const tabs = React.useMemo(() => {
    const commonProps = {
      id: 'message-template',
      columns: messageTemplateColumns,
      getRowLinkProps,
      getActionMenuItems,
      getQuery: getMessageTemplateQuery,
      Table: ListTable,
      getFilters,
      getSort
    };

    return [
      {
        ...commonProps,
        name: 'active',
        label: 'Active',
        forcedGlobalFilter: [
          {
            field: 'status_id',
            op: 'eq',
            value: { id: 'active', label: 'Active' }
          }
        ]

        // TODO: initialGlobalFilter, initialSortBy
      },
      {
        ...commonProps,
        name: 'archived',
        label: 'Archived',
        forcedGlobalFilter: [
          {
            field: 'status_id',
            op: 'eq',
            value: { id: 'archived', label: 'Archived' }
          }
        ]

        // TODO: initialGlobalFilter, initialSortBy
      }
    ];
  }, [getFilters, getSort]);

  return (
    <ListScreen
      privilege={'message-templates.update'}
      title='Message templates'
      actions={[getCreateMessageTemplateAction()]}
    >
      <Card>
        <TabbedTable tabs={tabs} />
      </Card>
    </ListScreen>
  );
}
