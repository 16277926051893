import {
  FreeformDialogProps,
  Select,
  useFreeformDialog
} from '@rexlabs/select';
import { merge } from 'lodash';
import * as React from 'react';
import { getSearchResultItemFromObjectAndModel } from 'src/modules/common/utils/search-result-items/get-search-result-items-from-object-and-model';
import { CreateContactDialog } from 'src/modules/contacts/dialogs/create-contact-dialog';
import { contactsModel } from 'src/modules/contacts/models/contacts';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { SearchResultItem } from 'utils/api/get-search-results';
import { useItems } from 'view/hooks/use-items';
import { ContactSelectProps } from '../contact';
import { EntitySelectProps, getItemsOuter, normaliser } from './entity-select';

export function FreeformContactDialog({
  contact: propContact,
  inputValue,
  selectItem,
  onCreate: propOnCreate,
  ...props
}: any & FreeformDialogProps<Partial<Contact>>) {
  const names = inputValue.split(' ');
  const contact = React.useMemo(
    () =>
      merge(
        {
          given_name: names?.[0],
          middle_name:
            names?.length > 2 ? names.slice(1, -1)?.join(' ') : undefined,
          family_name: names?.length > 1 ? names[names.length - 1] : undefined
        },
        propContact
      ),
    [names, propContact]
  );

  const onCreate = React.useCallback(
    (data) => {
      const contactResult = getSearchResultItemFromObjectAndModel(
        data,
        contactsModel
      );

      propOnCreate?.(contactResult);
      selectItem(contactResult);
    },
    [propOnCreate, selectItem]
  );

  return (
    <CreateContactDialog
      {...props}
      initialValues={contact}
      onCreate={onCreate}
    />
  );
}

export function EntityFixtureSelect(
  props: EntitySelectProps & { dialogProps?: ContactSelectProps['dialogProps'] }
) {
  const getSuggestedItems = React.useCallback(() => {
    if (!props.value) {
      return [];
    }

    return Array.isArray(props.value)
      ? props.value
      : [props.value as SearchResultItem];
  }, [props.value]);

  const getItems = React.useCallback(
    (term) => getItemsOuter(term, props.objectTypes),
    [props.objectTypes]
  );

  const { getSelectProps } = useItems({
    getItems,
    getSuggestedItems: props.getSuggestedItems ?? getSuggestedItems
  });

  const {
    getSelectProps: useFreeformDialogGetSelectProps
  } = useFreeformDialog<Contact>({
    props: props.dialogProps,
    Dialog: FreeformContactDialog
  });

  const selectPropsWithFixture = getSelectProps(
    useFreeformDialogGetSelectProps()
  );

  return (
    <Select normaliser={normaliser} {...props} {...selectPropsWithFixture} />
  );
}
