import React, { useMemo } from 'react';
import { useWhereabouts } from '@rexlabs/whereabouts';

import { BlockConfig } from 'view/components/record-screen/types';

import { RecordTaskTabbedTable } from 'src/modules/tasks/common/components/record-task-tabbed-table';
import { useTaskColumns } from 'src/modules/tasks/common/hooks/use-task-list-columns';
import { ActionButtons } from 'view/components/action-buttons';
import { useDialog } from '@rexlabs/dialog';
import { CreateTaskDialog } from 'src/modules/tasks/common/dialogs/create-task-dialog';
import { toQuri } from '@rexlabs/quri';
import { Contact } from 'src/modules/contacts/types/contact-types';

export const useFilterByContactId = (contactId?: string) => {
  return useMemo(
    () =>
      contactId
        ? [
            {
              field: 'todo_relates_to_id',
              op: 'eq',
              value: contactId
            }
            // see assigned-tasks-block in this folder if/when we need to get other task types here
          ]
        : [],
    [contactId]
  );
};

export const contactTasksBlock: BlockConfig<Contact> = {
  id: 'contact-tasks',
  title: 'Tasks',

  View: ({ data }) => {
    const whereabouts = useWhereabouts();
    const tab = whereabouts.hashQuery?.tab;

    const filter = useFilterByContactId(data?.id);

    const columns = useTaskColumns(tab);

    return (
      <RecordTaskTabbedTable
        key={tab}
        columns={columns}
        filters={filter}
        normaliseGlobalFilter={normaliseGlobalFilter}
      />
    );
  },
  Actions: ({ data }) => {
    const { open: openCreateTaskDialog } = useDialog(CreateTaskDialog);

    if (!data) {
      return null;
    }

    return (
      <ActionButtons
        actions={[
          {
            label: 'Create task',
            onClick: () => {
              openCreateTaskDialog({});
            }
          }
        ]}
      />
    );
  }
};

function normaliseGlobalFilter(filters) {
  const flattenedFilter = Object.values(filters).flatMap(
    (filterArray: any) => filterArray[0]
  );

  return toQuri(flattenedFilter);
}
