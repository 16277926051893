import React from 'react';
import { isString, lowerCase } from 'lodash';
import { Message } from '@rexlabs/notifications';
import LockClosedIcon from 'view/components/icons/lock-closed';
import Box from '@rexlabs/box';
import { useToken } from '@rexlabs/styling';
import { Privilege } from 'src/modules/authorization/roles/hooks/use-privileges';
import { EmptyCard } from './empty-card';

export function UnauthorizedEmptyCard({
  requiredPrivilege
}: {
  requiredPrivilege: Privilege;
}) {
  const token = useToken();

  const privileges = isString(requiredPrivilege)
    ? [requiredPrivilege]
    : requiredPrivilege.privileges;

  return (
    <EmptyCard hasGreyBackground>
      <Message title="You don't have permissions" Icon={LockClosedIcon}>
        If you think you should have access, please contact your system
        administrator.
        <Box marginTop={token('spacing.s')}>
          <span>
            This section requires:
            <ul>
              {privileges?.map((privilege) => {
                const privilegeArray = privilege.split('.');
                const priv = privilegeArray.pop();
                const feature = privilegeArray.map(lowerCase).join(' ');

                return (
                  <li key={privilege}>
                    <b>{priv && lowerCase(privilegeMap(priv))}</b> privileges
                    for <b>{lowerCase(feature)}</b>
                  </li>
                );
              })}
            </ul>
          </span>
        </Box>
      </Message>
    </EmptyCard>
  );
}

// At this stage, BE returns a 'read' privilege but on the FE we want to show 'view' instead
function privilegeMap(privilege: string) {
  switch (privilege) {
    case 'read':
      return 'view';
    default:
      return privilege;
  }
}
