import { capitalize, lowerCase, upperFirst } from 'lodash';

import { SharedDeferredFeeLineItemsSharedTabConfig } from 'src/modules/deferred-fees/hooks/use-get-shared-deferred-fee-line-item-shared-tab-config';

import { ListTableProps } from 'view/components/table/list-table';
import { Tab } from 'view/components/table/tabbed';

const NOT_BILLED_ID = 'not_billed';
const BILLED_ID = 'billed';
const ALL_ID = 'all';

export function useGetDeferredFeeLineItemTabConfig(): (props: {
  sharedTabProps: SharedDeferredFeeLineItemsSharedTabConfig;
}) => Tab<ListTableProps>[] {
  return ({ sharedTabProps }) => [
    {
      ...sharedTabProps,
      label: upperFirst(lowerCase(NOT_BILLED_ID)),
      id: NOT_BILLED_ID,
      name: NOT_BILLED_ID,
      forcedGlobalFilter: [
        ...sharedTabProps.forcedGlobalFilter!,
        {
          field: 'is_billed',
          op: 'eq',
          value: 'false'
        }
      ],
      columns: (sharedTabProps.columns || []).filter(
        (column) => column.id !== 'billed_on'
      )
    },
    {
      ...sharedTabProps,
      label: capitalize(BILLED_ID),
      id: BILLED_ID,
      name: BILLED_ID,
      forcedGlobalFilter: [
        ...sharedTabProps.forcedGlobalFilter!,
        {
          field: 'is_billed',
          op: 'eq',
          value: 'true'
        }
      ]
    },
    {
      ...sharedTabProps,
      label: capitalize(ALL_ID),
      id: ALL_ID,
      name: ALL_ID
    }
  ];
}
