import React from 'react';
import { Dialog, DialogProps } from '@rexlabs/dialog';
import { Field, ReactForms } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import { TextArea } from 'view/components/inputs/text-area/text-area';
import { StandardDialogFooter } from 'view/components/dialogs/components/standard-dialog-footer';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { Column } from '@rexlabs/grid';
import { useToken } from '@rexlabs/styling';
import { MetricLabelsTable } from 'src/modules/metric-write-rules/components/metric-labels-table';
import { MetricValueField } from 'src/modules/metric-write-rules/components/metric-value-field';
import { Heading } from '@rexlabs/text';
import { FormGrid } from 'view/components/@luna/grid/form';
import Validator from '@rexlabs/validator';

import { MetricConfig } from 'src/modules/metric-write-rules/types/Metric';

Validator.registerImplicit(
  'metricValueIsValidJsonPath',
  function (this: any, value: string, otherField: string) {
    const type = this.validator.input[otherField].type.id;

    if (type === 'json_path') {
      return value.startsWith('$.');
    }

    return true;
  },
  'Jsonpath values must start with "$."'
);

interface AddEditMetricDialogProps extends DialogProps {
  onSave: (metric: MetricConfig) => void;
  onClose: () => void;
  initialValues?: Partial<MetricConfig>;
}

const defaultInitialValues: Partial<MetricConfig> = {
  identifier: '',
  name: '',
  description: '',
  is_reversal: false,
  metric_attribute_to_user_id: {
    type: { id: 'preset', label: 'Preset' },
    value: { id: 'attributed_user', label: 'Attributed user' }
  },
  metric_value: {
    type: { id: 'literal', label: 'Literal' },
    value: '1',
    invert: false
  },
  metric_time: {
    type: { id: 'preset', label: 'Preset' },
    value: { id: 'current_time', label: 'Current time' }
  },
  metric_labels: []
};

const validate = {
  definitions: {
    identifier: { rules: 'required' },
    name: { rules: 'required' },
    'metric_attribute_to_user_id.type': { rules: 'required' },
    'metric_attribute_to_user_id.value': {
      rules: [
        'required_unless:metric_attribute_to_user_id.type.id,prompt',
        'metricValueIsValidJsonPath:metric_attribute_to_user_id'
      ].join('|')
    },
    'metric_value.type': { rules: 'required' },
    'metric_value.value': {
      rules: [
        'required_unless:metric_value.type.id,prompt',
        'metricValueIsValidJsonPath:metric_value'
      ].join('|')
    },
    'metric_time.type': { rules: 'required' },
    'metric_time.value': {
      rules: [
        'required_unless:metric_time.type.id,prompt',
        'metricValueIsValidJsonPath:metric_time'
      ].join('|')
    }
  }
};

export function AddEditMetricDialog({
  onClose,
  onSave,
  initialValues = {}
}: AddEditMetricDialogProps) {
  const token = useToken();

  const isEditing = !!initialValues.identifier;
  const dialogTitle = isEditing ? 'Edit Metric' : 'Add Metric';

  const handleSubmit = (values: Partial<MetricConfig>) => {
    onSave({ ...values } as MetricConfig);
    onClose();
  };

  return (
    <Dialog title={dialogTitle} onClose={onClose} size={'s'}>
      <ReactForms
        initialValues={{ ...defaultInitialValues, ...initialValues }}
        validate={validate}
        handleSubmit={handleSubmit}
      >
        {({ setFieldValue, values, submitForm }) => (
          <>
            <FormGrid columns={12}>
              <Column width={12}>
                <FormGrid columns={12} gutter={token('spacing.m')}>
                  <Column width={6}>
                    <Field
                      name='identifier'
                      label='Identifier'
                      Input={TextInput}
                      inputProps={{ 'data-testid': 'metric_identifier' }}
                    />
                  </Column>
                  <Column width={6}>
                    <Field
                      name='name'
                      label='Name'
                      Input={TextInput}
                      inputProps={{ 'data-testid': 'metric_name' }}
                    />
                  </Column>
                  <Column width={12}>
                    <Field
                      name='description'
                      label='Description'
                      Input={TextArea}
                      inputProps={{ 'data-testid': 'metric_description' }}
                    />
                  </Column>
                </FormGrid>
              </Column>

              <Column width={12}>
                <Heading level={4}>Effective date</Heading>
                <MetricValueField
                  label='Effective date'
                  value={values?.metric_time}
                  setFieldValue={setFieldValue}
                  presetOptions={[
                    { id: 'current_time', label: 'Current time' }
                  ]}
                  fieldPrefix='metric_time'
                />
              </Column>

              <Column width={12}>
                <Heading level={4}>Attribute to</Heading>
                <MetricValueField
                  label='Attribute to'
                  value={values?.metric_attribute_to_user_id}
                  setFieldValue={setFieldValue}
                  presetOptions={[
                    { id: 'attributed_user', label: 'Attributed user' },
                    { id: 'record_owner', label: 'Record owner' },
                    { id: 'current_user', label: 'Current user' }
                  ]}
                  fieldPrefix='metric_attribute_to_user_id'
                />
              </Column>

              <Column width={12}>
                <Heading level={4}>Metric value</Heading>
                <MetricValueField
                  label='Metric value'
                  value={values?.metric_value}
                  setFieldValue={setFieldValue}
                  fieldPrefix='metric_value'
                  showInvert
                />
              </Column>

              <Column width={12}>
                <MetricLabelsTable
                  setFieldValue={setFieldValue}
                  values={values}
                />
              </Column>
            </FormGrid>
            <StandardDialogFooter>
              <GhostButton onClick={onClose}>Cancel</GhostButton>
              <PrimaryButton
                data-testid='metric-save-button'
                onClick={() => {
                  submitForm();
                }}
              >
                {isEditing ? 'Update Metric ' : 'Add Metric '}
              </PrimaryButton>
            </StandardDialogFooter>
          </>
        )}
      </ReactForms>
    </Dialog>
  );
}
