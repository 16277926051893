import React, { useCallback } from 'react';

import { query } from '@rexlabs/model-generator';
import { BulkAction } from '@rexlabs/table/lib/types/bulk-actions/simple';
import { GlobalFilter } from '@rexlabs/table/lib/types/context';

import { usePermission } from 'src/modules/authorization/roles/hooks/use-permission';

import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { FLAGS } from 'utils/feature-flags';

import { ListTable, TabbedTable } from 'view/components/table';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { Tab } from 'view/components/table/tabbed';

import { useGetBillProcessingActions } from '../hooks/action-menu-items/use-get-bill-processing-actions';
import { useGetBillProcessingColumns } from '../utils/bill-processing-columns';
import { financialsUploadedBillsModel } from '../models/uploaded-bills';
import { useGetProcessBillAction } from '../hooks/action-declarations/use-get-process-bill-action';
import { useGetDeleteBillAction } from '../hooks/action-declarations/use-get-delete-bill-action';
import { DefaultBillProcessingListEmptyState } from './default-bill-processing-list-empty-state';

const getBillProcessingQuery = () => query`{
  ${financialsUploadedBillsModel} {
    id
    invoice
    file
    updated_by
    created_by
    draft_invoice
  }
}`;

type BillProcessingListTableProps = {
  forcedCommonGlobalFilter?: GlobalFilter[];
  initialHiddenColumns?: string[];
  EmptyListState?: React.ComponentType<{ children: React.ReactNode }>;
};

export function BillProcessingListTable({
  forcedCommonGlobalFilter = [],
  initialHiddenColumns = [],
  EmptyListState = DefaultBillProcessingListEmptyState
}: BillProcessingListTableProps) {
  const { hasFeature } = useFeatureFlags();
  const { isAdminUser, isFinancialUser } = usePermission();

  const hasQuickInvoicesFeature = hasFeature(FLAGS.QUICK_INVOICES);

  const getBillProcessingColumns = useGetBillProcessingColumns();

  const { getFilters, getSort } = useTableFilters('uploaded-bills');

  const getBillProcessingActions = useGetBillProcessingActions();

  const getProcessBillAction = useGetProcessBillAction();
  const getDeleteBillAction = useGetDeleteBillAction();

  const getBulkActions = useCallback(
    (args) => {
      const bulkActions = [
        getProcessBillAction(args.selectedItems)
      ] as Array<BulkAction>;

      if (isAdminUser || isFinancialUser) {
        bulkActions.push(getDeleteBillAction(args.selectedItems));
      }

      return bulkActions;
    },
    [getProcessBillAction, getDeleteBillAction, isAdminUser, isFinancialUser]
  );

  const getActionMenuItems = useCallback(
    ({ item }) => {
      const actions = getBillProcessingActions([item]);
      return transformActionDeclarationsToActionMenuItems(actions);
    },
    [getBillProcessingActions]
  );

  const tabs: Tab = React.useMemo(() => {
    const commonProps = {
      id: 'bill-processing',
      Table: ListTable,
      getQuery: getBillProcessingQuery,
      emptyWithoutTable: true,
      getSort,
      getFilters,
      initialSortBy: [{ id: 'created_at', desc: true }],
      forcedGlobalFilter: forcedCommonGlobalFilter,
      initialHiddenColumns
    };

    return [
      {
        ...commonProps,
        name: 'not-processed',
        label: 'Not processed',
        columns: getBillProcessingColumns('not_processed'),
        Empty: () => (
          <EmptyListState>
            Uploaded bills that have not yet been processed will be displayed
            here.
          </EmptyListState>
        ),
        getActionMenuItems,
        forcedGlobalFilter: [
          ...commonProps.forcedGlobalFilter,
          {
            field: 'status_id',
            op: 'eq',
            value: 'not_processed'
          }
        ],
        getBulkActions
      },
      ...(hasQuickInvoicesFeature
        ? [
            {
              ...commonProps,
              name: 'ready-for-processing',
              label: 'Ready for processing',
              columns: getBillProcessingColumns('ready_for_processing'),
              Empty: () => (
                <EmptyListState>
                  Uploaded bills that are ready for processing will be displayed
                  here.
                </EmptyListState>
              ),
              getActionMenuItems,
              forcedGlobalFilter: [
                ...commonProps.forcedGlobalFilter,
                {
                  field: 'status_id',
                  op: 'eq',
                  value: 'ready_for_processing'
                }
              ],
              getBulkActions
            },
            {
              ...commonProps,
              name: 'needs-more-detail',
              label: 'Needs more detail',
              columns: getBillProcessingColumns('needs_more_detail'),
              Empty: () => (
                <EmptyListState>
                  Uploaded bills that need more detail will be displayed here.
                </EmptyListState>
              ),
              getActionMenuItems,
              forcedGlobalFilter: [
                ...commonProps.forcedGlobalFilter,
                {
                  field: 'status_id',
                  op: 'eq',
                  value: 'needs_more_detail'
                }
              ],
              getBulkActions
            }
          ]
        : []),
      {
        ...commonProps,
        name: 'processed',
        label: 'Processed',
        columns: getBillProcessingColumns('processed'),
        Empty: () => (
          <EmptyListState>
            Uploaded bills that have been processed will be displayed here.
          </EmptyListState>
        ),
        forcedGlobalFilter: [
          ...commonProps.forcedGlobalFilter,
          {
            field: 'status_id',
            op: 'eq',
            value: 'processed'
          }
        ]
      }
    ];
  }, []);

  return <TabbedTable tabs={tabs} />;
}
