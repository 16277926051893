import React, { useMemo } from 'react';
import { query, useEntityQuery } from '@rexlabs/model-generator';

import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { RecordScreen } from 'view/components/record-screen';

import { useRecordScreenSubmitHandler } from 'view/hooks/use-record-screen-submit-handler';
import { content } from '../data/content';
import { PortfolioRoleTitleBlock } from '../components/portfolio-role-title-block';
import { portfolioRolesModel } from '../models/portfolio-roles-model';

const getPortfolioRoleQuery = (id: string) => query`{
  ${portfolioRolesModel} (id: ${id}) {
    id
  }
}`;

interface PortfolioRoleDetailsProps {
  portfolioRoleId?: string;
}

export function PortfolioRoleDetails({
  portfolioRoleId
}: PortfolioRoleDetailsProps) {
  const breadcrumbs = [{ type: BREADCRUMBS.PORTFOLIO_ROLE }];
  const query = useMemo(() => getPortfolioRoleQuery(portfolioRoleId!), [
    portfolioRoleId
  ]);

  const { status, data, actions } = useEntityQuery(query, {
    throwOnError: false
  });

  const handleSubmit = useRecordScreenSubmitHandler(({ values }) => {
    return actions.updateItem({
      id: portfolioRoleId,
      data: values
    });
  });

  const titleBlock = <PortfolioRoleTitleBlock portfolioRole={data} />;

  return (
    <RecordScreen
      privilege={'portfolio-roles.read'}
      isLoading={status === 'loading'}
      data={data}
      handleSubmit={handleSubmit}
      content={content}
      titleBlock={titleBlock}
      breadcrumbs={breadcrumbs}
    />
  );
}
