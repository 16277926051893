import { Generator, GeneratorConfig } from 'data/models/generator';
import { User } from 'src/modules/users/types/user-types';
import { contactsModel } from 'src/modules/contacts/models/contacts';
import { auditableConfig } from 'src/modules/common/auditable/auditable-config';
import { portfoliosModel } from 'src/modules/portfolios/models/portfolios-model';
import { rolesModel } from 'src/modules/authorization/roles/models/roles-model';

export const userIncludes = [
  'contact',
  'contact.primary_email',
  'role',
  'linked_files'
].join(',');

const config: GeneratorConfig = {
  entities: {
    related: {
      contact: {
        include: 'contact',
        model: contactsModel
      },
      role: {
        include: 'role',
        model: [rolesModel]
      },
      linked_files: {
        include: 'linked_files'
      },
      portfolios: {
        include: 'portfolios',
        mode: [portfoliosModel]
      },
      ...auditableConfig
    }
  }
};
export const usersModel = new Generator<User>(
  'users',
  config
).createEntityModel();
