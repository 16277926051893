import React from 'react';

import { padding, StyleSheet, useStyles } from '@rexlabs/styling';
import { NumberInputProps } from '@rexlabs/text-input';
import { CentAmountInput } from 'view/components/inputs/cent-amount';
import { PercentageInput } from 'view/components/inputs/percentage-input';
import { AmountTypeSelect } from 'view/components/inputs/selects/v4-selects/amount-type-select';

export type TierAmountValue = {
  type?: {
    id: string;
    label: string;
  } | null;
  value?: number | null;
};

interface TierAmountSplitInputProps
  extends Omit<NumberInputProps, 'value' | 'onChange' | 'onBlur'> {
  value?: TierAmountValue;
  onChange?: (e: { target: { value: TierAmountValue } }) => void;
  onBlur?: (e: { target: { value: TierAmountValue } }) => void;
  testId?: string;
}

const defaultTierAmountValue: TierAmountValue = {
  type: null,
  value: null
};

const defaultStyles = StyleSheet({
  divider: {
    ...padding.styles({
      x: 'xs'
    })
  },
  splitGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 90px',
    gridGap: '12px'
  }
});

export function TierAmountSplitInput({
  value,
  onChange,
  onBlur
}: TierAmountSplitInputProps) {
  const s = useStyles(defaultStyles);

  const isFixedAmount = value?.type?.id === 'fixed_amount';

  const handleChange = (newValue: Partial<TierAmountValue>) => {
    onChange?.({
      target: {
        value: {
          ...(value || defaultTierAmountValue),
          ...newValue
        }
      }
    });
  };

  const handleBlur = (newValue: Partial<TierAmountValue>) => {
    onBlur?.({
      target: {
        value: {
          ...(value || defaultTierAmountValue),
          ...newValue
        }
      }
    });
  };

  const handleTypeChange = ({ target }) =>
    handleChange({
      type: target.value
    });

  const handleTypeBlur = ({ target }) =>
    handleBlur({
      type: target.value
    });

  const handleValueChange = ({ target }) =>
    handleChange({
      value: target.value
    });

  const handleValueBlur = ({ target }) =>
    handleBlur({
      value: target.value
    });

  return (
    <div {...s('splitGrid')}>
      {isFixedAmount ? (
        <CentAmountInput
          value={value?.value}
          onChange={handleValueChange}
          onBlur={handleValueBlur}
          Prefix={null}
        />
      ) : (
        <PercentageInput
          value={value?.value}
          onChange={handleValueChange}
          onBlur={handleValueBlur}
          prefix={undefined}
        />
      )}
      <AmountTypeSelect
        value={value?.type}
        onChange={handleTypeChange}
        onBlur={handleTypeBlur}
      />
    </div>
  );
}
