export function getStaticReportEndpoint(reportId: string): string {
  switch (reportId) {
    case 'cash-ledger':
      return '/financials/folio-ledgers/cash';
    case 'payables-ledger':
      return '/financials/folio-ledgers/payables';
    case 'receivables-ledger':
      return '/financials/folio-ledgers/receivables';
    default:
      return `/static-reports/${reportId}`;
  }
}
