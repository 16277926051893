import React, { ReactNode } from 'react';
import { Body, Small, Truncate } from '@rexlabs/text';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles, useToken } from '@rexlabs/styling';

interface TwoTierCellProps {
  text: ReactNode | null;
  subtext: ReactNode | null;
}
const styles = StyleSheet({
  subText: {
    color: ({ token }) => token('color.textStyle.body.subtext')
  }
});

export function TwoTierCell({ text, subtext }: TwoTierCellProps) {
  const token = useToken();
  const s = useStyles(styles);

  return (
    <Body as='div'>
      <Truncate>{text ?? '--'}</Truncate>
      {subtext && (
        <Box marginTop={token('spacing.xxs')}>
          <Truncate>
            <Small as='span' {...s('subText')}>
              {subtext}
            </Small>
          </Truncate>
        </Box>
      )}
    </Body>
  );
}
