import React from 'react';
import { DialogProps } from '@rexlabs/dialog';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';

import { OutlineButton } from '@rexlabs/button';
import { valueListFactory } from 'src/lib/testing/factories/value-list-factory';
import { pick } from 'lodash';
import { subtaskFieldsBlock } from '../blocks/subtask-fields-block';
import { TaskType } from '../types/TaskType';
import { getDueDateData } from '../../settings/mappers/map-checklist-template-item-form-data-to-checklist-template-item';
import { mapChecklistTemplateItemToChecklistTemplateItemFormData } from '../../settings/mappers/map-checklist-template-item-to-checklist-template-item-form-data';
import { useGetIsSmartChecklist } from '../../settings/hooks/use-get-is-smart-checklist';

const content: DialogContentConfig = [
  {
    id: 'subtask-fields',
    label: 'Subtask fields',
    blocks: [subtaskFieldsBlock]
  }
];

interface EditSubtaskDialog extends DialogProps {
  onRemove: () => void;
  onSave: (values) => void;
  subtask: any;
  taskType: TaskType;
}

export function EditSubtaskDialog({
  onClose,
  onSave,
  onRemove,
  subtask,
  taskType
}: EditSubtaskDialog) {
  const getIsSmartChecklist = useGetIsSmartChecklist();
  const isSmartChecklist = getIsSmartChecklist(taskType);

  const handleSubmit = async ({ values }) => {
    const subtaskDueDate = values.subtask_due_date;
    const isSpecificDate = subtaskDueDate?.due_date_type === 'specific_date';

    const dueDateValues = {
      due_date_type: valueListFactory(subtaskDueDate?.due_date_type),
      due_date_data: subtaskDueDate
        ? getDueDateData({ dueDate: subtaskDueDate })
        : undefined,
      ...(isSpecificDate ? { due_date: subtaskDueDate.due_date } : {})
    };

    onSave({
      ...pick(values, ['label', 'assignee']),
      ...dueDateValues
    });

    return true;
  };

  const data = {
    ...pick(subtask, ['label', 'assignee']),
    isSmartChecklist,
    subtask_due_date: mapChecklistTemplateItemToChecklistTemplateItemFormData({
      checklistTemplateItem: subtask,
      defaultRelatedDate: null,
      defaultRelatedDateOffset: null
    }).due_date
  };

  const handleRemove = () => {
    onRemove();

    onClose?.();
  };

  return (
    <RecordDialog
      data={data}
      title='Edit subtask'
      onClose={onClose}
      content={content}
      submitLabel='Save'
      handleSubmit={handleSubmit}
      blockProps={{ taskType, isSmartChecklist }}
      footerLeft={
        <OutlineButton onClick={handleRemove}>
          Delete this subtask
        </OutlineButton>
      }
    />
  );
}
