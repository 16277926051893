import React from 'react';

import { ListScreen } from 'view/components/list-screen/list-screen';
import { Card } from 'view/components/card';

import ArrowUpCircle from 'view/components/icons/arrow-up-circle';
import RefreshIcon from 'view/components/icons/refresh';
import { ButtonGroupInput } from 'view/components/@luna/button-group-input';
import ColumnsIcon from 'view/components/icons/columns';
import BulletedListIcon from 'view/components/icons/bulleted-list';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import BankingStatementIcon from 'view/components/icons/banking-statement';
import { BankStatementTransactionImportSelect } from 'view/components/inputs/selects/v4-selects/bank-statement-transaction-import-select';
import Box from '@rexlabs/box';
import { useValueListQuery } from 'src/lib/react-query/hooks/use-value-list-query';
import { useBatchReceiptingStatBlocksConfig } from '../hooks/use-batch-receipting-stat-blocks-config';
import { useGetUploadBankTransactionsAction } from '../hooks/use-get-upload-bank-transactions-action';
import {
  BankAccountProvider,
  useBankAccountState
} from '../hooks/use-bank-account-state';
import { useGetBulkReceiptToRentAction } from '../hooks/actions/use-get-bulk-receipt-to-rent-action';
import { useRematchTransactionsAction } from '../hooks/actions/use-rematch-transactions-action';
import { LabelWithIcon } from '../hooks/use-batch-receipting-button-config';
import { BatchReceiptingListTableView } from '../components/batch-receipting-list-table-view';
import { BatchReceiptingListCardView } from '../components/batch-receipting-list-card-view';
import { usePersistView, View } from '../hooks/use-persist-view';
import { useViewStatementsAction } from '../hooks/actions/use-view-statements-action';
import { useGetUploadPaymentGatewayAction } from '../hooks/use-get-upload-payment-gateway-action';
import { BankStatementTransactionImport } from '../types/bank-statement-transaction-import';
import { BatchReceiptingHeaderValuesProvider } from '../context/header-values';

const ViewItems = [
  {
    id: View.Card,
    value: View.Card,
    Icon: ColumnsIcon
  },
  {
    id: View.Table,
    value: View.Table,
    Icon: BulletedListIcon
  }
];

export function BatchReceiptingListScreenInner() {
  const { bankAccount, actions: bankAccountAction } = useBankAccountState();

  const bankAccountId = bankAccount.id;

  const uploadBankTransactionsAction = useGetUploadBankTransactionsAction();
  const uploadPaymentGatewayAction = useGetUploadPaymentGatewayAction();
  const getBulkReceiptToRentAction = useGetBulkReceiptToRentAction();
  const rematchTransactionsAction = useRematchTransactionsAction();
  const viewStatementsAction = useViewStatementsAction({
    bankAccountId
  });

  const [viewType, setViewType] = usePersistView();

  const [
    transactionFile,
    setTransactionFile
  ] = React.useState<BankStatementTransactionImport | null>(null);

  const { stats, matchedReceiptCount } = useBatchReceiptingStatBlocksConfig(
    bankAccountId,
    transactionFile?.id
  );

  const { hasFeature } = useFeatureFlags();

  const hasBatchReceiptingTableToggleFeature = hasFeature(
    FLAGS.BATCH_RECEIPTING_TABLE_TOGGLE
  );

  const hasBatchReceiptingStatementsFeature = hasFeature(
    FLAGS.BATCH_RECEIPTING_STATEMENTS
  );

  const hasPaymentGatewaysFeature = hasFeature(FLAGS.PAYMENT_GATEWAYS);

  const { data: paymentGatewayTypes } = useValueListQuery('payment_gateway');

  const hasPaymentGatewayTypes =
    paymentGatewayTypes && paymentGatewayTypes.length > 0;

  return (
    <ListScreen
      privilege={{
        mode: 'any',
        privileges: [
          'bank-statement-transactions.read',
          'bank-statement-transactions.suspended-read'
        ]
      }}
      title='Batch receipting'
      stats={stats}
      amountDisplayedAsButtons={3}
      actions={[
        {
          label: 'Actions',
          actions: [
            {
              ...uploadBankTransactionsAction,
              label: LabelWithIcon(
                uploadBankTransactionsAction.label!,
                <ArrowUpCircle />
              )
            },
            ...(hasPaymentGatewaysFeature && hasPaymentGatewayTypes
              ? [
                  {
                    ...uploadPaymentGatewayAction,
                    label: LabelWithIcon(
                      uploadPaymentGatewayAction.label!,
                      <ArrowUpCircle />
                    )
                  }
                ]
              : []),
            {
              ...rematchTransactionsAction,
              label: LabelWithIcon(
                rematchTransactionsAction.label!,
                <RefreshIcon />
              )
            },
            ...(hasBatchReceiptingStatementsFeature
              ? [
                  {
                    ...viewStatementsAction,
                    label: LabelWithIcon(
                      viewStatementsAction.label!,
                      <BankingStatementIcon />
                    )
                  }
                ]
              : [])
          ]
        },
        {
          id: 'bank_statement_transaction_file',
          ActionTrigger: () => {
            return (
              <Box width={340}>
                <BankStatementTransactionImportSelect
                  deselectable
                  placeholder='Select transaction file'
                  value={transactionFile}
                  onChange={(e) => {
                    setTransactionFile(e.target.value);
                  }}
                />
              </Box>
            );
          }
        },
        ...(Array.isArray(bankAccountAction) ? bankAccountAction : [])
      ]}
    >
      <Card
        title='Transactions'
        actions={[
          ...(hasBatchReceiptingTableToggleFeature
            ? [
                {
                  id: 'view_toggle',
                  ActionTrigger: () => (
                    <ButtonGroupInput
                      value={viewType}
                      items={ViewItems}
                      onChange={(item) => {
                        setViewType(
                          item?.target?.value === View.Card
                            ? View.Card
                            : View.Table
                        );
                      }}
                    />
                  )
                }
              ]
            : []),
          ...(matchedReceiptCount > 0
            ? [
                getBulkReceiptToRentAction(
                  matchedReceiptCount,
                  bankAccount,
                  transactionFile
                )
              ]
            : [])
        ]}
      >
        <BatchReceiptingHeaderValuesProvider transactionFiles={transactionFile}>
          {hasBatchReceiptingTableToggleFeature && viewType === View.Table ? (
            <BatchReceiptingListTableView />
          ) : (
            <BatchReceiptingListCardView />
          )}
        </BatchReceiptingHeaderValuesProvider>
      </Card>
    </ListScreen>
  );
}

export function BatchReceiptingListScreen() {
  return (
    <BankAccountProvider>
      <BatchReceiptingListScreenInner />
    </BankAccountProvider>
  );
}
