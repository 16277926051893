import React, { useCallback, useMemo } from 'react';

import { ActionMenuItem } from '@rexlabs/action-menu';

import { useGetSharedDeferredFeeLineItemSharedTabProps } from 'src/modules/deferred-fees/hooks/use-get-shared-deferred-fee-line-item-shared-tab-config';
import { useGetDeferredFeeLineItemTabConfig } from 'src/modules/deferred-fees/hooks/use-get-deferred-fee-line-item-tab-config';
import { useGetVoidDeferredLineItemAction } from 'src/modules/deferred-fees/hooks/action-declarations/use-get-void-deferred-line-item-action';
import { DeferredFeeLineItem } from 'src/modules/deferred-fees/types/DeferredFeeLineItem';

import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';

import { TabbedTable } from 'view/components/table';
import { ListTableProps } from 'view/components/table/list-table';
import { Tab } from 'view/components/table/tabbed';

import { agencyFeeLedgerColumns } from '../utils/agency-fee-ledger-columns';

export function AgencyFeeLedgerTabbedTable({ folioId }: { folioId: string }) {
  const getVoidDeferredLineItemAction = useGetVoidDeferredLineItemAction();

  const getActionMenuItems = useCallback(
    ({ item }: { item: DeferredFeeLineItem }) => {
      const actionMenuItems: ActionMenuItem[] = [];
      if (item.status.id !== 'voided' && item.payment_invoice === null) {
        actionMenuItems.push(
          ...transformActionDeclarationsToActionMenuItems([
            getVoidDeferredLineItemAction({ item })
          ])
        );
      }
      return actionMenuItems;
    },
    []
  );

  const getSharedDeferredFeeLineItemSharedTabProps = useGetSharedDeferredFeeLineItemSharedTabProps();
  const getDeferredFeeLineItemTabConfig = useGetDeferredFeeLineItemTabConfig();

  const tabs: Tab<ListTableProps>[] = useMemo(() => {
    const sharedTabProps = getSharedDeferredFeeLineItemSharedTabProps({
      folioId: folioId,
      deferredFeeLineItemTypeId: 'agency_fee',
      columns: agencyFeeLedgerColumns,
      initialHiddenColumns: ['status'],
      getActionMenuItems
    });

    return getDeferredFeeLineItemTabConfig({ sharedTabProps });
  }, [folioId]);

  return <TabbedTable hashParamKey='agency-fee-ledger' tabs={tabs} />;
}
