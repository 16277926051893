import * as React from 'react';
import { ListTable } from 'view/components/table';
import { Card } from 'view/components/card';
import { ListScreen } from 'view/components/list-screen/list-screen';
import { StatusBadTag, StatusGoodTag } from '@rexlabs/tag';
import { TagCell } from '@rexlabs/table';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { useDialog } from '@rexlabs/dialog';
import { EditCustomValidationDialog } from 'src/modules/custom-validations/dialogs/edit-custom-validation-dialog';
import { useCustomValidations } from '../hooks/use-custom-validations';
import { CustomValidation } from '../types/CustomValidation';
import { useGetDeleteCustomValidationAction } from '../hooks/action-declarations/use-get-delete-custom-validation-action';
import { useGetToggleEnabledCustomValidationAction } from '../hooks/action-declarations/use-get-toggle-enabled-custom-validation-action';
import { useGetCreateCustomValidationAction } from '../hooks/action-declarations/use-get-create-custom-validation-action';
import { useGetEditCustomValidationAction } from '../hooks/action-declarations/use-get-edit-custom-validation-action';

export function CustomValidationList() {
  const { data: customValidations, isLoading } = useCustomValidations();
  const getDeleteCustomValidationAction = useGetDeleteCustomValidationAction();
  const getToggleEnabledCustomValidationAction = useGetToggleEnabledCustomValidationAction();
  const getCreateCustomValidationAction = useGetCreateCustomValidationAction();
  const getEditCustomValidationAction = useGetEditCustomValidationAction();
  const { open: openEditCustomValidationDialog } = useDialog(
    EditCustomValidationDialog
  );
  // const { getSort, getFilters } = useTableFilters('custom-validations');
  const getRowLinkProps = ({ item }: { item: CustomValidation }) => {
    return {
      onClick: () =>
        openEditCustomValidationDialog({
          customValidation: item
        })
    };
  };

  const getActionMenuItems = React.useCallback(
    ({ item }: { item: CustomValidation }) =>
      transformActionDeclarationsToActionMenuItems([
        getEditCustomValidationAction(item),
        getToggleEnabledCustomValidationAction(item),
        getDeleteCustomValidationAction(item)
      ]),
    [
      getEditCustomValidationAction,
      getToggleEnabledCustomValidationAction,
      getDeleteCustomValidationAction
    ]
  );

  const columns = React.useMemo(
    () => [
      {
        id: 'name',
        Header: 'Name',
        accessor: 'name'
      },
      {
        id: 'is_enabled',
        Header: 'Enabled',
        accessor: (item: CustomValidation) => (item.is_enabled ? 'Yes' : 'No'),
        Cell: ({ row }) => {
          return (
            <TagCell>
              {row.original.is_enabled ? (
                <StatusGoodTag>Yes</StatusGoodTag>
              ) : (
                <StatusBadTag>No</StatusBadTag>
              )}
            </TagCell>
          );
        }
      },
      {
        id: 'validation_type',
        Header: 'Type',
        accessor: (item: CustomValidation) =>
          item.validation_type.id === 'error' ? 'Error' : 'Warning'
      }
    ],
    []
  );

  return (
    <ListScreen
      title='Custom Validations'
      actions={[getCreateCustomValidationAction()]}
      privilege={'custom-validation.read'}
    >
      <Card>
        <ListTable
          columns={columns}
          items={customValidations || []}
          isLoading={isLoading}
          getRowLinkProps={getRowLinkProps}
          getActionMenuItems={getActionMenuItems}
          hideToolbar
        />
      </Card>
    </ListScreen>
  );
}
