import { fragment } from '@rexlabs/model-generator';

export const customTaskQueryIncludeFragment = fragment`
    managed_by
    parent_task
    custom_fields
    custom_field_values
    property {
      active_property_ownership {
        ownership {
          owners
        }
      }
      active_property_tenancy {
          tenancy {
            tenants
          }
      }
    }
    customTaskDetails
    task_links
    created_by
    updated_by
`;
