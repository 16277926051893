import { Generator } from 'data/models/generator';
import { auditableConfig } from 'src/modules/common/auditable/auditable-config';
import { propertiesModel } from 'data/models/entities/properties';
import { Task } from '../types/Task';

export const sharedIncludes = [
  'property',
  'managed_by',
  'task_links',
  'task_links.child_task',
  'task_links.inverse_task_link'
];

// Because these includes are getting a bit silly, just define them once here and reuse them where we can, such as when refreshing items
export const taskIncludes = {
  todoDetails: [
    'details',
    'details.relates_to',
    'details.relates_to.primary_phone',
    // we need these deep includes for the related records section
    'details.relates_to.tenants',
    'details.relates_to.tenants.contact.primary_phone',
    'details.relates_to.related_contacts',
    'details.relates_to.related_contacts.contact.primary_phone',
    'details.relates_to.owners',
    'details.relates_to.owners.contact.primary_phone'
  ].join(','),

  customTaskDetails: [
    'details',
    'details.type',
    'details.custom_task_template',
    'details.custom_task_template.type'
  ].join(','),

  maintenanceDetails: [
    'details.created_by',
    'details.updated_by',
    'details.deleted_by',
    'details.images',
    'details.reported_by',
    'details.reported_date',
    'details.access_provided_by',
    'details.completion_date',
    'details.status',
    'details.type',
    'details.notes',
    'details.invoices',
    'details.appliance.warranties',
    'details.appliance.manuals',
    'details.disbursement_withheld_funds'
  ].join(','),

  leaseReviewDetails: [
    'details.status',
    'details.property_tenancy',
    'details.property_tenancy.tenancy',
    'details.property_tenancy.tenancy.tenants.contact.primary_phone',
    'details.property_tenancy.tenancy.related_contacts.contact.primary_phone'
  ].join(','),

  moveInDetails: [
    'details.move_in_date',
    'details.property_tenancy',
    'details.property_tenancy.tenancy',
    'details.property_tenancy.tenancy.tenants',
    'details.property_tenancy.tenancy.tenants.contact.primary_phone',
    'details.property_tenancy.tenancy.related_contacts.contact.primary_phone'
  ].join(','),

  moveOutDetails: [
    'details.move_out_date',
    'details.property_tenancy',
    'details.property_tenancy.tenancy',
    'details.property_tenancy.tenancy.tenants',
    'details.property_tenancy.tenancy.tenants.contact.primary_phone',
    'details.property_tenancy.tenancy.related_contacts.contact.primary_phone'
  ].join(','),

  inspectionDetails: [
    'details.status',
    'details.type',
    'details.rooms',
    'details.rooms.images',
    'details.room_order',
    'details.areas',
    'details.area_order',
    'details.areas.images',
    'details.areas.items.inspection_conditions',
    'details.areas.items.images',
    'details.inspection_report',
    'details.inspection_report.file',
    'details.property_tenancy',
    'details.property_tenancy.tenancy.tenants.contact.primary_phone',
    'details.property_tenancy.tenancy.related_contacts.contact.primary_phone',
    'details.scheduled_at',
    'details.scheduled_duration',
    'details.scheduled_order',
    'details.inspection_run_id',
    'details.property_details',
    'details.supporting_documents',
    'details.cover_summary',
    'details.cover_image'
  ].join(','),

  arrearsDetails: [
    'details.property_tenancy',
    'details.property_tenancy.id',
    'details.property_tenancy.property',
    'details.property_tenancy.property_id',
    'details.property_tenancy.tenancy',
    'details.property_tenancy.tenancy.tenants.contact.primary_phone',
    'details.property_tenancy.tenancy.related_contacts.contact.primary_phone',
    'details.property_tenancy.rent_arrears',
    'details.property_tenancy.rent_position',
    'details.property_tenancy.property.active_property_ownership.ownership.owners.contact.primary_phone'
  ].join(','),

  propertyComplianceDetails: [
    'details.compliance_entry',
    'details.compliance_entry.compliance_type',
    'details.compliance_type'
  ].join(','),

  supplierComplianceDetails: [
    'details.compliance_entry',
    'details.compliance_entry.compliance_type',
    'details.compliance_type',
    'details.supplier'
  ].join(','),

  quoteDetails: [
    'details.supplier',
    'details.access_provided_by',
    'details.images',
    'details.quote_attachments',
    'details.task_work_orders'
  ].join(','),

  workOrderDetails: [
    'details.work_done_by',
    'details.invoices',
    'details.images',
    'details.access_provided_by'
  ].join(',')
};

const config = {
  entities: {
    related: {
      // TODO: configure includes here
      ...auditableConfig,
      details: {
        include: 'details'
      },
      todoDetails: {
        include: taskIncludes.todoDetails
      },
      customTaskDetails: {
        include: taskIncludes.customTaskDetails
      },
      custom_fields: {
        include: 'custom_fields'
      },
      custom_field_values: {
        include: 'custom_field_values'
      },
      maintenanceDetails: {
        include: taskIncludes.maintenanceDetails
      },
      moveInDetails: {
        include: taskIncludes.moveInDetails
      },
      moveOutDetails: {
        include: taskIncludes.moveOutDetails
      },
      leaseReviewDetails: {
        include: taskIncludes.leaseReviewDetails
      },
      inspectionDetails: {
        include: taskIncludes.inspectionDetails
      },
      arrearsDetails: {
        include: taskIncludes.arrearsDetails
      },
      propertyComplianceDetails: {
        include: taskIncludes.propertyComplianceDetails
      },
      supplierComplianceDetails: {
        include: taskIncludes.supplierComplianceDetails
      },
      quoteDetails: { include: taskIncludes.quoteDetails },
      workOrderDetails: { include: taskIncludes.workOrderDetails },
      parent_task: {
        include: [
          'parent_task',
          'parent_task.managed_by',
          'parent_task.property',
          'parent_task.details.access_provided_by',
          'parent_task.details.images'
        ].join(',') // cannot include model here because it becomes self-referential
      },
      child_tasks: {
        include: [
          'child_tasks',
          'child_tasks.details',
          'child_tasks.details.work_done_by'
        ].join(',')
      },
      invoices_summary: {
        include: 'invoices_summary'
      },
      task_links: {
        include: [
          'task_links',
          'task_links.child_task',
          'task_links.inverse_task_link',
          'task_links.child_task.details',
          'task_links.child_task.details.status',
          // this provides the record label for inspections
          'task_links.child_task.details.type',
          // this provides the record label for arrears
          'task_links.child_task.details.property_tenancy.tenancy'
        ].join(',')
      },
      property: {
        include: 'property',
        model: propertiesModel
      },
      managed_by: {
        include: 'managed_by'
      },
      checklists: {
        include: 'checklists,checklists.items'
      },
      subtask_summary: {
        include: 'subtask_summary'
      }
    }
  }
};

export const tasksModel = new Generator<Task<any>>(
  'tasks',
  config
).createEntityModel();
