import React from 'react';
import { Card } from 'view/components/card';
import { PmApiError } from 'utils/api/create-error-from-response';
import { CardFallbackComponentProps } from './error-boundary';
import { UnauthorizedEmptyCard } from './unauthorized-empty-card';

type UnauthorizedCardProps = {
  title?: React.ReactNode;
} & CardFallbackComponentProps & { error: PmApiError };

export function UnauthorizedCard(props: UnauthorizedCardProps) {
  const { error } = props;
  const { requiredPrivileges } = error;

  return (
    <Card title={props.title}>
      <UnauthorizedEmptyCard
        requiredPrivilege={{
          mode: 'all',
          privileges: requiredPrivileges || []
        }}
      />
    </Card>
  );
}
