import {
  NormalisedItem,
  Select,
  SelectProps,
  UseItemsArgs
} from '@rexlabs/select';
import { RecordType } from 'data/models/types';
import { capitalize, lowerCase } from 'lodash';
import * as React from 'react';
import {
  getSearchResults,
  SearchResultItem
} from 'utils/api/get-search-results';
import { useItems } from 'view/hooks/use-items';

const relationSymbol = Symbol.for('relationType');

export const defaultObjectTypes: ObjectType[] = [
  'contact',
  'property',
  'tenancy',
  'ownership'
];

export const getItemsOuter = async (
  term,
  objectTypes: RecordType[] = defaultObjectTypes
) => {
  const searchResults = await getSearchResults(term, {
    objectTypes
  });

  return searchResults;
};

export const normaliser = (item: SearchResultItem): NormalisedItem => {
  // HACK: I found a weird bug where if you use setFieldValues, we sometimes get the value of the field after
  // this select. For example, in the add quote dialog, if you click the checkbox, add a supplier then a quote price,
  // then click the checkbox again, an error is thrown as the value is now what ever you entered in the quote price field.

  let heading = item?.type?.label;
  let type = item?.type?.id;
  let label = item?.label;

  // For tasks, we want to group them based on their sub-type
  if (item.type.id === 'task' && 'type' in item.record) {
    heading = item.record.type?.label ?? heading;
    type = item.record.type?.id ?? type;
  }

  if (
    item.type.id === 'disbursement' &&
    'object' in item.record &&
    'type' in item.record.object
  ) {
    const prefix = item.record.object.type; // This should be something like "Ownership" or "Contact"
    heading = `${prefix.label} ${heading}`; // This should be something like "Ownership Disbursement"
    type = `${prefix.id}_${type}`; // This should be something like "ownership_disbursement
  }

  if (item.type.id === 'statement' && 'type' in item.record) {
    const prefix = item.record.type; // This should be something like "Periodic Ownership" or "Yearly Ownership"
    heading = `${prefix.label} ${heading}`; // This should be something like "Periodic Ownership Statement"
    type = `${prefix.id}_${type}`; // This should be something like "periodic_ownership_statement"
  }

  if (item.type.id === 'trust_journal_entry' && 'type' in item.record) {
    const prefix = item.record.type; // This should be something like "Receipt" or "Withdrawal"
    heading = `${prefix.label} ${heading}`; // This should be something like "Receipt Trust Journal Entry"
    type = `${prefix.id}_${type}`; // This should be something like "receipt_trust_journal_entry"
  }
  if (
    item.type.id === 'invoice' &&
    'type' in item.record &&
    item.record.__record_type === 'invoice'
  ) {
    label = `${item.label} - ${item.record.description}`;
  }

  return {
    id: item?.id,
    label: label,
    description: capitalize(lowerCase(item?.record?.[relationSymbol] ?? type)),
    type,
    heading
  };
};

export type ObjectType =
  | 'contact'
  | 'property'
  | 'tenancy'
  | 'ownership'
  | 'task'
  | 'disbursement'
  | 'statement'
  | 'trust_journal_entry'
  | 'invoice';

export type EntitySelectProps = SelectProps<SearchResultItem> &
  Pick<UseItemsArgs<SearchResultItem>, 'getSuggestedItems'> & {
    objectTypes?: ObjectType[];
  };

export function EntitySelect(props: EntitySelectProps) {
  // We need to include the default value as a suggested item, otherwise it gets clobbered when the user selects an additional item
  const getSuggestedItems = React.useCallback(() => {
    if (!props.value) {
      return [];
    }

    return Array.isArray(props.value)
      ? props.value
      : [props.value as SearchResultItem];
  }, [props.value]);

  const getItems = React.useCallback(
    (term) => getItemsOuter(term, props.objectTypes),
    [props.objectTypes]
  );

  const { getSelectProps } = useItems({
    getItems: getItems,
    getSuggestedItems: props.getSuggestedItems ?? getSuggestedItems
  });

  return <Select normaliser={normaliser} {...props} {...getSelectProps()} />;
}
