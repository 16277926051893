import React, { FC } from 'react';
import { SelectProps } from '@rexlabs/select';

import { Contact } from 'src/modules/contacts/types/contact-types';
import { BaseFields, RecordType } from 'data/models/types';
import { UserGroup } from 'src/modules/user-groups/types/UserGroup';
import { SearchResultItem } from 'utils/api/get-search-results';
import { PortfolioRole } from 'src/modules/portfolio-roles/types/PortfolioRole';
import { TaskType } from '../types/TaskType';
import { defaultObjectTypes, ManagedBySelect } from './managed-by-select';

export type Assignee = Contact | PortfolioRole | UserGroup;

export type ChecklistAssigneeSelectProps = SelectProps<SearchResultItem> & {
  taskType?: TaskType;
};

export const ChecklistAssigneeSelect: FC<ChecklistAssigneeSelectProps> = ({
  taskType,
  ...props
}) => {
  // supplier compliance doesn't have portfolio roles as a valid option
  const objectTypes: RecordType[] =
    taskType === 'task_supplier_compliance'
      ? defaultObjectTypes
      : [...defaultObjectTypes, 'portfolio_role'];

  return <ManagedBySelect objectTypes={objectTypes} {...props} />;
};

export function itemIsContact(
  item: BaseFields<any> | null | undefined
): item is Contact {
  if (!item) {
    return false;
  }
  return '__record_type' in item && item.__record_type === 'contact';
}
