import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import { TextArea } from 'view/components/inputs/text-area/text-area';
import { Column, Grid } from '@rexlabs/grid';
import { Checkbox } from '@rexlabs/checkbox';
import {
  CodeInput,
  generateJavaScriptBeforeMountHandler
} from 'view/components/inputs/code-input';
import { ValueListSelect } from 'view/components/inputs/selects/value-list';
import { MetricsTable } from 'src/modules/metric-write-rules/components/metrics-table';
import {
  MetricWriteRule,
  MetricWriteRuleFormData
} from '../types/MetricWriteRule';

const validate = {
  definitions: {
    name: { name: 'name', rules: 'required' },
    record_type: { name: 'record type', rules: 'required' },
    trigger_type: { name: 'trigger type', rules: 'required' },
    'condition.code': { name: 'condition', rules: 'required' }
  }
};

export const metricWriteRuleDetailsBlock: BlockConfig<
  MetricWriteRule,
  any,
  MetricWriteRuleFormData
> = {
  id: 'details',
  title: 'Metric Write Rule Details',
  validate,
  Edit: ({ values, setFieldValue }) => {
    const tsTypes = `
    interface RexContext {
      before: Record<string, any>;
      after: Record<string, any>;
    }

    declare function module(exports: any): void;
    declare namespace module {
      var exports: {
        (context: RexContext): void;
      };
    }
    `;

    return (
      <Grid columns={12}>
        <Column width={6}>
          <Field name='name' label='Name' Input={TextInput} />
        </Column>
        <Column width={6}>
          <Field
            name={'record_type'}
            id={'record_type'}
            label='Record Type'
            Input={ValueListSelect}
            inputProps={{
              type: 'metric_write_rule_record_type',
              'data-testid': 'uuid-3'
            }}
          />
        </Column>
        <Column width={6}>
          <Field
            name={'trigger_type'}
            id={'trigger_type'}
            label='Trigger Type'
            Input={ValueListSelect}
            inputProps={{
              type: 'metric_write_rule_trigger'
            }}
          />
        </Column>
        <Column width={6}>
          <Field
            name='is_attribution_selection_required'
            label='Attribution'
            optional={false}
            Input={Checkbox}
            inputProps={{
              label: 'Triggering user must specify user for attribution'
            }}
          />
        </Column>
        <Column width={12}>
          <Field name='description' label='Description' Input={TextArea} />
        </Column>
        <Column width={12}>
          <Field
            name='condition.code'
            Input={CodeInput}
            label='Condition'
            inputProps={{
              monacoProps: {
                beforeMount: generateJavaScriptBeforeMountHandler(tsTypes),
                language: 'javascript'
              }
            }}
          />
        </Column>
        <Column width={12}>
          <Field
            name='extra_data.code'
            Input={CodeInput}
            label='Extra Data'
            inputProps={{
              monacoProps: {
                beforeMount: generateJavaScriptBeforeMountHandler(tsTypes),
                language: 'javascript'
              }
            }}
          />
        </Column>
        <Column width={12}>
          <Field
            HelpTooltipContent={() =>
              'Comma seperated list of API includes to be provided to the code.'
            }
            name='include'
            label='Includes'
            Input={TextInput}
          />
        </Column>
        <Column width={12}>
          <MetricsTable setFieldValue={setFieldValue} values={values} />
        </Column>
      </Grid>
    );
  }
};
