import _ from 'lodash';
import bugsnag from '@bugsnag/js';
import { identify } from '@rexlabs/analytics';
import flagsmith from 'flagsmith';
import { TOKENS } from '@rexlabs/theme-luna';
import { CustomAction } from '@rexlabs/model-generator';

import { Generator } from 'data/models/generator';
import {
  api,
  resetApiClient,
  setAuthToken,
  setBaseUrl,
  setSiloId
} from 'utils/api/api-client';
import { fetchAllFilters } from 'view/hooks/use-table-filters';
import { FLAGS } from 'utils/feature-flags';
import { AddressCountry } from 'src/modules/properties/types/property-types';
import { getTemporaryFilterKey } from 'src/modules/app-wide-filters/utils/get-temporary-filter-key';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { parseUrlToRoute } from '@rexlabs/whereabouts';
import { Role } from 'src/modules/authorization/roles/types/Role';
import { UserGroup } from 'src/modules/user-groups/types/UserGroup';
import { ValueListValue } from '../types';

export interface UniversalUser {
  id: string;
  family_name: string;
  given_name: string;
  email: string;
  password?: string;
}

export type Silo = {
  id: string;
  label: string;
  internal_name: string;
  is_sample: boolean;
  base_url: string;
  country: ValueListValue<AddressCountry>;
};

export interface Session {
  ready: boolean;
  token: string | null;
  user: UniversalUser | null;
  availableSilos: Silo[];
  contact: Contact | null;
  role: Role | null;
  userGroups: UserGroup[] | null;
  activeSilo: Silo | null;
  featureFlags: any | null;
  expired: boolean;
}

const MAX_MOBILE_WIDTH = TOKENS.breakpoint.s;

const initialState: Session = {
  ready: false,
  token: null,
  user: null,
  role: null,
  availableSilos: [],
  userGroups: null,
  activeSilo: null,
  contact: null,
  featureFlags: null,
  expired: false
};

const selectors = {
  ready: (state) => state.session.ready,
  token: (state) => state.session.token,
  user: (state) => state.session.user
};

type InitSession = {
  token: string | null;
  user?: UniversalUser | null;
  availableSilos?: Silo[] | null;
  userGroups?: UserGroup[] | null;
  activeSilo?: Silo | null;
  contact?: Contact | null;
};

interface MeResponse {
  universal_user: UniversalUser;
  silos: Silo[];
}

const isCypress = window['Cypress'] != null;

const isMobile = window.matchMedia(`(max-width: ${MAX_MOBILE_WIDTH}px)`)
  .matches;

const hideDefaultLauncher = isMobile || isCypress;

function clearTemporaryAppWideFilter({
  siloId,
  userId
}: {
  siloId: string;
  userId: string;
}) {
  const temporaryFilterKey = getTemporaryFilterKey({ siloId, userId });
  localStorage.removeItem(temporaryFilterKey);
}

async function initSession(
  {
    token,
    user,
    availableSilos,
    activeSilo: previouslyActiveSilo
  }: InitSession,
  isLogin = false
) {
  try {
    if (!token) {
      return {
        availableSilos,
        previouslyActiveSilo,
        user,
        token: null
      };
    }

    setAuthToken(token);
    const me = await api.get<MeResponse>('/me');

    if (me.status === 401) {
      throw new Error('Token is invalid');
    }

    bugsnag.addMetadata('user', {
      ...me.data?.universal_user,
      silos: me.data?.silos,
      activeSilo: me.data?.silos?.[0]
    });

    if (window.Sprig) {
      window.Sprig('setUserId', me.data?.universal_user?.id);
      window.Sprig('setEmail', me.data?.universal_user?.email);
    }

    // check the silos to see if there is an id match with the previously active silo
    // We fetch a fresh copy of it, so that if the baseURL has changed, we can use the new one
    const freshActiveSilo =
      me.data.silos.find((silo) => silo.id === previouslyActiveSilo?.id) ||
      previouslyActiveSilo;

    const activeSilo: Silo = freshActiveSilo ?? me.data?.silos?.[0];

    if (activeSilo?.base_url) {
      setBaseUrl(activeSilo.base_url);
    }

    setSiloId(activeSilo?.id);

    let role: Role | null = null;

    try {
      const roleResponse = await api.get<Role>(
        '/me/current-role?include=privileges'
      );
      role = roleResponse.data;
    } catch (error) {
      // If we get a 404, it means the user doesn't have a role, so we can ignore it
    }

    let userGroups: UserGroup[] | null = null;

    try {
      const userGroupsResponse = await api.get<UserGroup[]>('/me/user-groups');
      userGroups = userGroupsResponse.data;
    } catch (error) {
      // If we get a 404, it means the user doesn't have a role, so we can ignore it
    }

    let contact: Contact | null = null;

    try {
      const contactResponse = await api.get<Contact>('/me/contact');
      contact = contactResponse.data;
    } catch (error) {
      // If we get a 404, it means the user doesn't have a contact, so we can ignore it
    }

    identify({
      userId: me.data?.universal_user?.id,
      ...me.data?.universal_user,
      // If a role is found, add the role name and purpose id to the user analytics object
      ...(role
        ? {
            role_name: role?.name,
            role_purpose_id: role?.system_purpose?.id
          }
        : {}),
      options: { Intercom: { hideDefaultLauncher } }
    });

    await flagsmith.identify(me.data?.universal_user?.email);

    if (isLogin) {
      clearTemporaryAppWideFilter({
        siloId: activeSilo?.id,
        userId: me.data?.universal_user?.id
      });
    }

    const flag = flagsmith.hasFeature(FLAGS.WIP_ACTIONS);
    await fetchAllFilters({ showWIP: flag });

    return {
      universal_user: me.data.universal_user,
      availableSilos: me.data.silos,
      role,
      userGroups,
      activeSilo,
      contact,
      token
    };
  } catch (e) {
    console.error('Session caught error');
    console.error(e);
    return {
      availableSilos,
      activeSilo: previouslyActiveSilo,
      user,
      role: null,
      userGroups: null,
      contact: null,
      token: null
    };
  }
}

type LoginActionPayload = {
  email: string;
  password: string;
  ttl?: number;
};

type JwtLoginActionPayload = {
  token: string;
  active_silo_id: string | undefined;
  ttl?: number;
};

const actionCreators = {
  login: {
    request: (payload, _actions, _dispatch, _getState) =>
      api
        .post('/auth/login', payload)
        .then((response) => initSession({ token: response.data.token }, true)),
    reduce: {
      initial: _.identity,
      success: (state, action) => ({
        ...state,
        user: action.payload.universal_user,
        token: action.payload.token,
        role: action.payload.role,
        expired: false,
        availableSilos: action.payload.availableSilos,
        userGroups: action.payload.userGroups,
        contact: action.payload.contact,
        activeSilo:
          action.payload.activeSilo || action.payload.availableSilos?.[0]
      }),
      failure: (state) => ({
        ...state,
        token: null
      })
    }
  } as CustomAction<LoginActionPayload, any>,

  loginViaAuthServiceJwt: {
    request: async (payload, _actions, _dispatch, _getState) => {
      // Need to do this so that we log in to the correct server, otherwise if we are trying to log in to a silo on a non AU server, it will fail
      const route = parseUrlToRoute(window.location.href);
      const regionUrls = await api.get<Record<string, string>>(
        '/auth/region-urls'
      );
      const origin = (route.query?.origin
        ? route.query.origin
        : 'AUS') as string;

      const baseUrl = regionUrls.data[origin];

      setBaseUrl(baseUrl + '/api/v1');

      return await api.post('/auth/jwt-login', payload).then((response) =>
        initSession(
          {
            token: response.data.token,
            activeSilo: response.data.active_silo
          },
          true
        )
      );
    },
    reduce: {
      initial: _.identity,
      success: (state, action) => ({
        ...state,
        user: action.payload.universal_user,
        token: action.payload.token,
        role: action.payload.role,
        userGroups: action.payload.userGroups,
        contact: action.payload.contact,
        expired: false,
        availableSilos: action.payload.availableSilos,
        activeSilo:
          action.payload.activeSilo || action.payload.availableSilos?.[0]
      }),
      failure: (state) => ({
        ...state,
        token: null
      })
    }
  } as CustomAction<JwtLoginActionPayload, any>,

  logout: {
    reduce: (state, _action) => {
      bugsnag.clearMetadata('user');
      window?.Intercom?.('shutdown');
      flagsmith.logout();
      resetApiClient();
      localStorage.removeItem('meta');
      return {
        ...state,
        user: null,
        token: null,
        activeSilo: null
      };
    }
  } as CustomAction<void, any>,

  expireToken: {
    reduce: (state, _action) => {
      window?.Intercom?.('shutdown');
      flagsmith.logout();
      localStorage.removeItem('meta');
      return {
        ...state,
        token: null,
        // It is possible that this will get fired while logged out if an API call returns after logging out
        // so don't change expired state if token is already null
        expired: state.token === null ? state.expired : true
      };
    }
  } as CustomAction<void, any>,

  switchSilo: {
    reduce: (state: Session, action) => {
      const { siloId } = action.payload;
      const newActiveSilo = state.availableSilos.find(
        (silo) => silo.id === siloId
      );

      bugsnag.addMetadata('user', { activeSilo: newActiveSilo });

      setSiloId(siloId);

      if (newActiveSilo?.base_url) {
        setBaseUrl(newActiveSilo.base_url);
      }

      return {
        ...state,
        activeSilo: newActiveSilo
      };
    }
  } as CustomAction<{ siloId: string }, Session>,

  updateSilo: {
    reduce: (state, action) => {
      bugsnag.addMetadata('user', { activeSilo: action.payload });
      setSiloId(action.payload?.id);
      return {
        ...state,
        activeSilo: action.payload
      };
    }
  } as CustomAction<any, any>,

  init: {
    request: (_payload, _actions, _dispatch, getState) =>
      initSession(getState().session),
    reduce: {
      initial: _.identity,
      success: (state, action) => ({
        ...state,
        ready: true,
        token: action.payload.token,
        contact: action.payload.contact,
        userGroups: action.payload.userGroups,
        user: action.payload.universal_user,
        role: action.payload.role,
        availableSilos: action.payload.availableSilos,
        activeSilo:
          action.payload.activeSilo || action.payload.availableSilos?.[0]
      }),
      failure: (state) => ({
        ...state,
        ready: true,
        token: null
      })
    }
  } as CustomAction<void, any>,

  update: {
    request: () => api.get('/me'),
    reduce: {
      initial: _.identity,
      success: (state, action) => ({
        ...state,
        user: action.payload.data.user
      }),
      failure: (state) => ({
        ...state,
        token: null
      })
    }
  } as CustomAction<void, any>,

  // This action is purely for development/demo purposes and should probably be
  // removed eventually
  invalidateToken: {
    reduce: (state) => ({
      ...state,
      token: 'invalidated'
    })
  } as CustomAction<void, any>
};

export const sessionModel = new Generator<Session, typeof actionCreators>(
  'session'
).createModel({
  initialState,
  selectors,
  actionCreators
});
