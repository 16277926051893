import React from 'react';
import invariant from 'invariant';

import { TableProps } from '@rexlabs/table';

interface ReplaceTableStatesProps {
  children: React.ReactNode;
  showLoadingWithoutTable: boolean;
  showEmptyWithoutTable: boolean;
  Loading: TableProps['Loading'];
  Empty: TableProps['Empty'];
}

// Use this component to replace the table states with custom components - for example,
// we might not want to show the loading state inside the table, but instead show it as is.
export function ReplaceTableStatesComponent(props: ReplaceTableStatesProps) {
  const {
    children,
    showLoadingWithoutTable,
    showEmptyWithoutTable,
    Loading,
    Empty
  } = props;

  if (showLoadingWithoutTable) {
    invariant(Loading, `Loading prop can't be undefined`);
    return <Loading />;
  }

  if (showEmptyWithoutTable) {
    invariant(Empty, `Empty prop can't be undefined`);
    return <Empty />;
  }

  return <>{children}</>;
}
