import * as React from 'react';
import { Card } from 'view/components/card';
import { ListScreen } from 'view/components/list-screen/list-screen';

import { LettersTabbedTable } from '../components/letters/letters-tabbed-table';
import { useGetCreateLetterAction } from '../hooks/action-declarations/use-get-create-letter-action';

export function LetterList() {
  const getCreateLetterAction = useGetCreateLetterAction();

  return (
    <ListScreen
      privilege={'messages.read'}
      title='Letters'
      actions={[getCreateLetterAction()]}
    >
      <Card>
        <LettersTabbedTable />
      </Card>
    </ListScreen>
  );
}
