import * as React from 'react';
import { Card } from 'view/components/card';
import { ListScreen } from 'view/components/list-screen/list-screen';

import { useGetCreateEmailAction } from '../hooks/action-declarations/use-get-create-email-action';
import { useGetCreateSMSAction } from '../hooks/action-declarations/use-get-create-sms-action';
import { MessagesTabbedTable } from '../components/messages-tabbed-table';

export function MessageList() {
  const getCreateEmailAction = useGetCreateEmailAction();
  const getCreateSMSAction = useGetCreateSMSAction();

  return (
    <ListScreen
      privilege={'messages.read'}
      title='Messages'
      actions={[getCreateSMSAction(), getCreateEmailAction()]}
    >
      <Card>
        <MessagesTabbedTable />
      </Card>
    </ListScreen>
  );
}
