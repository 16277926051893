import { useCallback } from 'react';
import { invokeActionDeclaration } from 'src/modules/common/actions/utils/invoke-action-declaration';
import { useGetViewMessageAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-view-message-action';
import { useDialog, useErrorDialog } from '@rexlabs/dialog';
import { CreateEmailRecordDialog } from 'src/modules/communications/messages/dialogs/create-email-record-dialog';
import { CreateSMSRecordDialog } from 'src/modules/communications/messages/dialogs/create-sms-record-dialog';
import { useGetPreviewMessageAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-preview-message-action';
import { useMutation, useQueryClient } from 'react-query';
import { Message } from 'src/modules/communications/messages/types/Message';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { getSearchResultItemFromObjectAndModel } from 'src/modules/common/utils/search-result-items/get-search-result-items-from-object-and-model';
import { getRecipients } from 'src/modules/communications/messages/utils/get-recipients';
import { api } from 'utils/api/api-client';
import { noop } from 'lodash';
import { CreateLetterRecordDialog } from 'src/modules/communications/messages/dialogs/create-letter-record-dialog';
import { ChecklistItemStatus } from '../types/checklist-item-status';
import { getItemStatus } from '../utils/get-item-status';
import { Checklist, ChecklistItem } from '../../common/types/Checklist';
import { Task } from '../../common/types/Task';
import { TaskType } from '../../common/types/TaskType';
import { tasksModel } from '../../common/models/tasks-model';

type ChecklistItemWithTaskAndChecklist = ChecklistItem & {
  checklist: Checklist;
  task: Task<TaskType>;
};
export function useSubtextOnClickHandlerForItem(
  item: ChecklistItemWithTaskAndChecklist
) {
  const createEmailDialog = useDialog(CreateEmailRecordDialog);
  const createSMSDialog = useDialog(CreateSMSRecordDialog);
  const createLetterDialog = useDialog(CreateLetterRecordDialog);
  const getPreviewMessageAction = useGetPreviewMessageAction(false);
  const getViewMessageAction = useGetViewMessageAction();
  const errorDialog = useErrorDialog();

  const queryClient = useQueryClient();

  const handleActionMessage = useMutation(
    ({ message, item }: { message: Message; item: ChecklistItem }) => {
      return api.patch(`/tasks/checklist-items/${item.id}`, {
        action_data: {
          ...item.action_data,
          message_id: message.id,
          message_template_id: message.template?.id
        }
      });
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(['checklist', item.checklist.id]);
      }
    }
  );

  return useCallback(() => {
    const status = getItemStatus(item);

    const handleOnSave = () => {
      queryClient.invalidateQueries(['checklist', item.checklist.id]);
    };

    async function handleCreate(message) {
      try {
        await handleActionMessage.mutateAsync({
          message,
          item
        });
      } catch (err) {
        errorDialog.open(err);
      }
    }

    const handlePreview = async (value: ChecklistItemWithTaskAndChecklist) => {
      const message = window.structuredClone(value.action_message);

      // show sent message
      if (
        message &&
        (message.status.id === 'sent' || message.status.id === 'sending')
      ) {
        invokeActionDeclaration(getViewMessageAction, {
          channelType: message.channels?.[0].channel_type.id,
          message
        });

        return;
      }

      // has a custom message for this checklist item
      if (message) {
        // transforms response to follow model-generator ListData<PROP>
        const recipients = message.recipients;
        message.recipients = {
          items: (recipients as unknown) as Contact[],
          status: 'loaded'
        };
        const cc_recipients = message.cc_recipients;
        message.cc_recipients = {
          items: (cc_recipients as unknown) as Contact[],
          status: 'loaded'
        };
        const bcc_recipients = message.bcc_recipients;
        message.bcc_recipients = {
          items: (bcc_recipients as unknown) as Contact[],
          status: 'loaded'
        };

        invokeActionDeclaration(getPreviewMessageAction, message, handleOnSave);

        return;
      }

      const relatesTo = getSearchResultItemFromObjectAndModel(
        value.task,
        tasksModel
      );

      const template = value.action_message_template;
      const recipients = await getRecipients(template, relatesTo);
      const actionType = value.action_type?.id;

      // go with the message template
      // if (actionType === 'send_email') {
      // }

      function handleEmail() {
        const emailChannel = template?.channels?.find(
          (channel) => channel.channel_type.id === 'email'
        );

        createEmailDialog.open({
          title: 'Save message',
          onCreate: handleCreate,
          hasButtonGroupActions: false,
          data: {
            template,
            recipients,
            relates_to: relatesTo,
            subject: emailChannel?.email_subject,
            content: emailChannel?.plain_text_body
          }
        });
      }

      function handleSms() {
        const smsChannel = template?.channels?.find(
          (channel) => channel.channel_type.id === 'sms'
        );

        createSMSDialog.open({
          title: 'Save SMS',
          onCreate: handleCreate,
          hasButtonGroupActions: false,
          data: {
            template,
            recipients,
            relates_to: relatesTo,
            smsContent: smsChannel?.plain_text_body
          }
        });
      }

      function handleLetter() {
        const letterChannel = template?.channels?.find(
          (channel) => channel.channel_type.id === 'letter'
        );

        createLetterDialog.open({
          title: 'Save Letter',
          onCreate: handleCreate,
          hasButtonGroupActions: false,
          data: {
            template,
            recipients,
            relates_to: relatesTo,
            smsContent: letterChannel?.plain_text_body
          }
        });
      }

      switch (actionType) {
        case 'send_email':
          handleEmail();
          break;
        case 'send_sms':
          handleSms();
          break;
        case 'send_message':
          {
            // TODO: Currently, only one channel is supported. This will need to be changed if/when we support more than one.
            const channelType = value.action_data?.message_channel_types?.[0];
            switch (channelType?.id) {
              case 'email':
                handleEmail();
                break;
              case 'sms':
                handleSms();
                break;
              case 'letter':
                handleLetter();
                break;
              default:
                break;
            }
          }
          break;
        default:
          break;
      }
    };

    switch (status) {
      case ChecklistItemStatus.IS_SENDING_NOW:
      case ChecklistItemStatus.WAS_SENT_AT_TIME:
      case ChecklistItemStatus.WILL_TRIGGER_ON_COMPLETION:
      case ChecklistItemStatus.WILL_TRIGGER_AT_TIME:
        // Currently these are all the same fn, but in the future there will be different ones as we build out more actions.
        handlePreview(item);
        break;
      default:
        return noop;
    }
  }, [
    createEmailDialog,
    createSMSDialog,
    errorDialog,
    getPreviewMessageAction,
    getViewMessageAction,
    handleActionMessage,
    item,
    queryClient
  ]);
}
