import { NormalisedItem, Select } from '@rexlabs/select';
import * as React from 'react';
import { User } from 'src/modules/users/types/user-types';
import { api } from 'utils/api/api-client';
import { useItems } from 'view/hooks/use-items';

const normaliser = (item: User): NormalisedItem => ({
  id: item.id,
  label: item.contact!.display_name
});

export function UserSelect(props) {
  const getSuggestedItems = React.useCallback(() => {
    if (!props.value) {
      return [];
    }
    return Array.isArray(props.value) ? props.value : [props.value];
  }, [props.value]);

  const getItems = React.useCallback(
    async (term) => {
      const users = await api.get(
        `/users?include=contact${props?.filter ? `&q=${props.filter}` : ''}`,
        {
          search: term,
          per_page: 30
        }
      );

      return users.data;
    },
    [props?.filter]
  );

  const { getSelectProps } = useItems({
    getItems,
    getSuggestedItems
  });

  return <Select normaliser={normaliser} {...props} {...getSelectProps()} />;
}
