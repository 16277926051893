import React from 'react';
import { query } from '@rexlabs/model-generator';

import { Card } from 'view/components/card';
import ROUTES from 'routes/app';
import { Columns, ListTable } from 'view/components/table';
import { bankAccountModel } from 'data/models/entities/financials/bank-accounts';
import { getRecordLinkProps } from 'view/components/record-link/get-record-link-props';
import { ListScreen } from 'view/components/list-screen/list-screen';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';

import { StatusGoodTag } from '@rexlabs/tag';
import { TagCell } from '@rexlabs/table';
import { useTranslation } from 'react-i18next';
import { useGetBankAccountActions } from '../actions/use-get-bank-account-actions';

const useColumns = (): Columns => {
  const { t } = useTranslation();

  return [
    {
      id: 'record_reference',
      width: 140,
      Header: 'Reference'
    },
    {
      id: 'name',
      Header: 'Name'
    },
    {
      id: 'is_default',
      Cell: ({ value }) => (
        <TagCell>{value && <StatusGoodTag>Default</StatusGoodTag>}</TagCell>
      ),
      width: 100
    },
    {
      id: 'bank',
      type: 'value',
      Header: 'Bank'
    },
    {
      id: 'account.account_name',
      Header: 'Account name'
    },
    {
      id: 'account.account_number',
      Header: 'Account number'
    },
    {
      id: 'account.branch_code',
      Header: t('bank-accounts.branch-code.label.singular') as string
    },
    {
      id: 'account.currency.id',
      Header: 'Currency',
      accessor: (item) => item.currency.id
    },
    {
      id: 'last_reconciliation.statement_date',
      Header: 'Reconciled to date',
      type: 'date'
    },
    {
      id: 'created_at',
      type: 'date',
      Header: 'Created at'
    },
    {
      id: 'updated_at',
      type: 'date',
      Header: 'Updated at'
    }
  ];
};

const bankAccountsQuery = query`{
  ${bankAccountModel}  {
    id
    name
    account
    currecy
    last_reconciliation
  }
}`;

const getRowLinkProps = ({ item }) =>
  getRecordLinkProps({ type: 'bank_account', id: item.id });

export function BankAccountsListScreen() {
  const actions = useGetBankAccountActions();
  const { getSort, getFilters } = useTableFilters('bank-accounts');
  const columns = useColumns();

  return (
    <ListScreen
      privilege={'bank-accounts.update'}
      title='Bank Accounts'
      recordType='bank_account'
      createLabel='Create bank account'
      createLinkProps={{ to: ROUTES.BANK_ACCOUNTS.CREATE }}
    >
      <Card>
        <ListTable
          id='bank-accounts'
          columns={columns}
          getQuery={() => bankAccountsQuery}
          getRowLinkProps={getRowLinkProps}
          getActionMenuItems={({ item }) =>
            transformActionDeclarationsToActionMenuItems(actions(item))
          }
          getSort={getSort}
          getFilters={getFilters}
        />
      </Card>
    </ListScreen>
  );
}
