import { useQuery } from 'react-query';
import { api } from 'utils/api/api-client';
import { CustomTaskTemplate } from '../../types/CustomTaskTemplate';

export const customTaskTemplateQueryKey = ['custom-task-templates'];

export function useCustomTaskTemplates() {
  return useQuery({
    queryKey: customTaskTemplateQueryKey,
    queryFn: async () => {
      // TODO: can change this to use the filter functions if it becomes more complex
      const queryString =
        'q=archived.eq(false)&include=type,custom_fields,custom_field_values,task_summary';
      return api.get<CustomTaskTemplate[]>(
        `/tasks/custom-task-templates?${queryString}`
      );
    },
    select: (data) => data.data,
    staleTime: Infinity
  });
}
