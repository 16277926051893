import { DestructiveButton } from '@rexlabs/button';
import React from 'react';
import { useQueryClient } from 'react-query';
import {
  StatusChangeActionArgs,
  useStatusChangeAction
} from 'src/modules/common/actions/status/use-status-change-action';
import { invokeActionDeclaration } from 'src/modules/common/actions/utils/invoke-action-declaration';
import RemoveIcon from 'view/components/icons/remove';
import { usePermission } from 'src/modules/authorization/roles/hooks/use-permission';
import { AccessDeniedDialog } from 'view/components/dialogs/access-denied-dialog';
import { useDialog } from '@rexlabs/dialog';
import { bankStatementTransactionsModel } from '../../models/bank-statement-transaction-model';
import { BatchReceiptingItem } from '../../types';
import { useBankAccountState } from '../use-bank-account-state';
import { bankStatementTransactionsQueryKey } from '../use-bank-statement-transactions-query';
import {
  getTransactionLabel,
  LabelWithIcon
} from '../use-batch-receipting-button-config';
import { useBatchReceiptingStatsSummary } from '../use-batch-receipting-stats-summary';

export function useGetRemoveAction() {
  const { bankAccount } = useBankAccountState();
  const { isStandardUser, roleName } = usePermission();

  const { refetch: refetchStatsSummary } = useBatchReceiptingStatsSummary(
    bankAccount?.id
  );

  const getStatusChangeAction = useStatusChangeAction(
    bankStatementTransactionsModel
  );

  const queryClient = useQueryClient();

  const accessDeniedDialog = useDialog(AccessDeniedDialog);

  return (item: BatchReceiptingItem) => {
    const transactionLabel = getTransactionLabel(item);

    return {
      label: LabelWithIcon('Remove', <RemoveIcon />),
      onClick: async () => {
        if (isStandardUser) {
          return accessDeniedDialog.open({
            role: roleName
          });
        }

        await invokeActionDeclaration<StatusChangeActionArgs<'removed'>[]>(
          getStatusChangeAction,
          {
            record: item,
            status: 'removed',
            dialogOptions: {
              title: 'Remove transaction',
              reasonLabel: 'Comment',
              description: (
                <>
                  Do you want to remove the transaction for {transactionLabel}?
                </>
              ),
              SubmitButton: DestructiveButton,
              afterAction: async () => {
                await refetchStatsSummary();

                await queryClient.refetchQueries({
                  queryKey: [bankStatementTransactionsQueryKey],
                  refetchPage: () => true
                });
              }
            },
            toastOptions: {
              description: (
                <>Transaction for {transactionLabel} successfully removed</>
              )
            }
          }
        );
      },
      intent: 'danger' as const
    };
  };
}
