import * as React from 'react';
import { TabbedTable, ListTable } from 'view/components/table';
import { Card } from 'view/components/card';
import { ListScreen } from 'view/components/list-screen/list-screen';
import { query } from '@rexlabs/model-generator';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { ROLE_ROUTES } from '../routes';

import { roleColumns } from '../utils/role-columns';
import { useGetRoleActions } from '../hooks/action-menu-items/use-get-role-actions';
import { Role } from '../types/Role';
import { rolesModel } from '../models/roles-model';
import { useGetCreateRoleAction } from '../hooks/action-declarations/use-get-create-role-action';

// TODO: add required includes
const roleQuery = query`{
  ${rolesModel} {
    id
  }
}`;

/**
 * Tabbed List Table
 * Model Generator Variant
 */
export function RoleList() {
  const getRoleActions = useGetRoleActions();
  const getCreateAction = useGetCreateRoleAction();

  const { getSort, getFilters } = useTableFilters('roles');

  // TODO: this should come from elsewhere
  type GetBulkActionsArgs = {
    selectedItems: Role[];
    deselectedItems: Role[];
    selectedAllRows: boolean;
  };

  // TODO: configure bulk actions
  const getBulkActions = React.useCallback((args: GetBulkActionsArgs) => {
    return [
      {
        label: 'Bulk Action',
        handleAction: () => {
          // TODO: implement an action
          // eslint-disable-next-line no-console
          console.log(args.selectedItems);
        }
      }
    ];
  }, []);

  const getRowLinkProps = ({ item }) => {
    return {
      to: ROLE_ROUTES.ROLE_DETAILS,
      params: { roleId: item.id }
    };
  };

  const getActionMenuItems = React.useCallback(
    ({ item }) => {
      const actions = getRoleActions(item);
      return transformActionDeclarationsToActionMenuItems(actions);
    },
    [getRoleActions]
  );

  const tabs = React.useMemo(() => {
    const commonProps = {
      id: 'roles',
      Table: ListTable // TODO: need to replace this with a new one for react query
    };

    return [
      {
        ...commonProps,
        getBulkActions,
        columns: roleColumns,
        name: 'role',
        label: 'Roles',
        getQuery: () => roleQuery,
        getRowLinkProps,
        getActionMenuItems,

        // TODO: enable these once the resource is wired up
        getFilters,
        getSort

        // TODO: initialSortBy, initialSortBy
      }
      // TODO: add more tabs if needed
    ];
  }, []);

  return (
    <ListScreen
      privilege={'roles.read'}
      title='Roles'
      actions={[getCreateAction()]}
    >
      <Card>
        <TabbedTable tabs={tabs} />
      </Card>
    </ListScreen>
  );
}
