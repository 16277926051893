import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { CustomReport } from '../../types/CustomReport';

export function useGetCustomReportActions() {
  return (customReport?: CustomReport): ActionDeclaration[] => {
    if (!customReport) return [];

    const actions = [];

    return actions;
  };
}
