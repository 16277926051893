import React, { ReactNode, useCallback } from 'react';

import SearchIcon from 'view/components/icons/search';

import Box from '@rexlabs/box';
import { formatCurrency } from 'utils/formatters';
import { FLAGS } from 'utils/feature-flags';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { useGetCreateNoteAction } from 'src/modules/note/hooks/use-get-create-note-action';
import { transformSingleActionToRegularActionMenuItem } from 'utils/actions/transforms';
import NoteIcon from 'view/components/icons/note';
import ArrowBackIcon from 'view/components/icons/arrow-back';
import { usePermission } from 'src/modules/authorization/roles/hooks/use-permission';
import {
  BatchReceiptingAction,
  BatchReceiptingItem,
  BatchReceiptingPrimaryAction
} from '../types';
import { bankStatementTransactionsModel } from '../models/bank-statement-transaction-model';
import { useGetMatchAndReceiptAction } from './actions/use-get-match-and-receipt-action';
import { useGetReceiptToRentAction } from './actions/use-get-receipt-to-rent-action';
import { useGetRemoveAction } from './actions/use-get-remove-action';
import { useGetRestoreAction } from './actions/use-get-restore-action';
import { useGetSplitTransactionAction } from './actions/use-get-split-transaction-action';
import { useGetSplitTransactionViewEditAction } from './actions/use-get-split-transaction-view-edit-action';
import { useGetSuspendAction } from './actions/use-get-suspend-action';
import { useGetViewNoteAction } from './actions/use-get-view-note-action';
import { useGetSuggestMatchAction } from './actions/use-get-suggest-match-action';
import { useGetEditMatchAction } from './actions/use-get-edit-match-action';

export interface BatchReceiptingButtonConfig {
  primaryAction: BatchReceiptingPrimaryAction;
  actions: BatchReceiptingAction[];
}

export type GetActions = (
  item: BatchReceiptingItem
) => BatchReceiptingButtonConfig;

// TODO: pull this out into its own reusable component
export function LabelWithIcon(label: string, Icon: ReactNode) {
  return (
    <Box style={{ display: 'flex' }}>
      <Box
        style={{ marginRight: '0.8rem', display: 'flex', alignItems: 'center' }}
      >
        {Icon}
      </Box>
      {label}
    </Box>
  );
}

export function getTransactionLabel(item) {
  return (
    <>
      <b>{formatCurrency(item.amount)}</b> with{' '}
      {item.reference ? (
        <>
          reference <b>{item.reference}</b>
        </>
      ) : (
        <b>unknown reference</b>
      )}
    </>
  );
}

export function useBatchReceiptingButtonConfig() {
  const { hasFeature } = useFeatureFlags();
  const { isStandardUser } = usePermission();

  const getSplitTransactionAction = useGetSplitTransactionAction();
  const getSplitTransactionViewEditAction = useGetSplitTransactionViewEditAction();
  const getRemoveAction = useGetRemoveAction();
  const getRestoreAction = useGetRestoreAction();
  const getSuspendAction = useGetSuspendAction();
  const getViewNoteAction = useGetViewNoteAction();
  const getReceiptToRentAction = useGetReceiptToRentAction();
  const getMatchAndReceiptAction = useGetMatchAndReceiptAction();
  const getCreateNoteAction = useGetCreateNoteAction(
    bankStatementTransactionsModel
  );
  const getSuggestMatchAction = useGetSuggestMatchAction();
  const getEditMatchAction = useGetEditMatchAction();

  const hasSuspensionFlowFeature = hasFeature(
    FLAGS.BATCH_RECEIPTING_SUSPENSION_FLOW
  );

  const hasPaymentGatewaysFeature = hasFeature(FLAGS.PAYMENT_GATEWAYS);

  function getButtonConfig(item: BatchReceiptingItem) {
    const statusId = item.status.id;
    const prioritisedMatchStatusId = item.prioritised_match.status.id;

    const hasParentTransaction = item.parent_transaction_id != null;

    const canSplitTransaction = hasPaymentGatewaysFeature
      ? !hasParentTransaction && item.type?.id !== 'payment_gateway'
      : !hasParentTransaction;
    const matchTypeId = item.prioritised_match?.match_type?.id;

    const prioritisedMatchStatusCheck = [
      'not_matched',
      'matched_receipt'
    ].includes(prioritisedMatchStatusId);

    const splitTransactionAction =
      prioritisedMatchStatusCheck && canSplitTransaction
        ? [getSplitTransactionAction(item), { isDivider: true }]
        : [];

    const splitTransactionViewEditAction = hasParentTransaction
      ? [getSplitTransactionViewEditAction(item), { isDivider: true }]
      : [];

    const createNoteAction = getCreateNoteAction({
      attached: item,
      hideAddNoteToField: true
    });

    switch (statusId) {
      case 'suspended': {
        const actions: Array<BatchReceiptingAction> = [
          {
            ...getRestoreAction(item),
            label: LabelWithIcon('Restore', <ArrowBackIcon />)
          },
          getRemoveAction(item)
        ];

        actions.unshift(
          hasSuspensionFlowFeature
            ? {
                ...transformSingleActionToRegularActionMenuItem(
                  createNoteAction
                ),
                label: LabelWithIcon(createNoteAction.label!, <NoteIcon />)
              }
            : getViewNoteAction(item)
        );

        return {
          primaryAction:
            isStandardUser && hasSuspensionFlowFeature
              ? matchTypeId === 'auto' || matchTypeId === 'manual'
                ? getEditMatchAction(item)
                : getSuggestMatchAction(item)
              : getMatchAndReceiptAction(item),

          actions
        };
      }
      case 'removed':
        return {
          primaryAction: getRestoreAction(item),
          actions: [
            getViewNoteAction(item),
            {
              ...getMatchAndReceiptAction(item),
              label: LabelWithIcon('Allocate & receipt', <SearchIcon />)
            },
            getSuspendAction(item)
          ]
        };
      default:
        switch (prioritisedMatchStatusId) {
          case 'matched_receipt':
            return {
              primaryAction: getReceiptToRentAction(item),
              actions: [
                ...splitTransactionAction,
                ...splitTransactionViewEditAction,
                {
                  ...getMatchAndReceiptAction(item),
                  label: LabelWithIcon('Allocate & receipt', <SearchIcon />)
                },
                getSuspendAction(item),
                getRemoveAction(item)
              ]
            };

          default:
            return {
              primaryAction: getMatchAndReceiptAction(item),
              actions: [
                ...splitTransactionViewEditAction,
                ...splitTransactionAction,
                getSuspendAction(item),
                getRemoveAction(item)
              ]
            };
        }
    }
  }

  return useCallback(
    (item: BatchReceiptingItem): BatchReceiptingButtonConfig =>
      getButtonConfig(item),
    []
  );
}
