import { AxiosResponse } from 'axios';

import { useQuery } from 'react-query';
import { api } from 'utils/api/api-client';

import { BatchReceiptingStatsSummary } from '../types';

async function fetcher(
  bankAccountId,
  transactionFileId
): Promise<AxiosResponse<BatchReceiptingStatsSummary>> {
  const queryParams = new URLSearchParams();

  if (bankAccountId) {
    queryParams.set('bank_account_id', bankAccountId);
  }

  if (transactionFileId) {
    queryParams.set('bank_statement_transaction_import_id', transactionFileId);
  }

  return api.get(`financials/bank-statement-transactions-summary`, queryParams);
}

export function useBatchReceiptingStatsSummary(
  bankAccountId?: string,
  transactionFileId?: string
) {
  return useQuery(
    ['batchReceiptingStatsSummary', bankAccountId, transactionFileId],
    () => fetcher(bankAccountId, transactionFileId)
  );
}
