import { useModelActions } from '@rexlabs/model-generator';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'routes/app';
import { useRecordCreatedToast } from 'src/modules/common/toasts/hooks/use-record-created-toast';
import { TaskType } from 'src/modules/tasks/common/types/TaskType';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { checklistTemplatesModel } from '../models/checklist-templates-model';
import { ChecklistTemplatesForm } from '../types/ChecklistTemplatesForm';

export function useCreateChecklistTemplate(
  type: TaskType,
  customTaskTypeId?: string
) {
  const { refreshLists, createItem } = useModelActions(checklistTemplatesModel);

  const addCreatedToast = useRecordCreatedToast(checklistTemplatesModel, {
    actions: []
  });

  const onSubmit: RecordSubmitHandler<ChecklistTemplatesForm> = async ({
    values
  }) => {
    const discriminatorFields = getDiscriminatorFields(
      type,
      values.inspection_type,
      customTaskTypeId ?? null
    );

    const response = await createItem({
      data: {
        label: values.label,
        description: values.description,
        is_default: false,
        task_type: {
          id: type
        },
        ...discriminatorFields
      }
    });

    addCreatedToast(response.data);
    await refreshLists();

    push(ROUTES.CHECKLIST_DETAILS, {
      params: {
        checklistId: response.data.id
      }
    });

    return response.data;
  };

  return onSubmit;
}

export function getDiscriminatorFields(
  type: TaskType,
  inspectionType: string | null | undefined,
  customTaskTypeId: string | null
): { discriminator_field: string | null; discriminator_value: string | null } {
  switch (type) {
    case 'task_inspection':
      return {
        discriminator_field: 'details.type_id',
        discriminator_value: inspectionType ?? null
      };
    case 'task_custom_task':
      return {
        discriminator_field: 'details.type_id',
        discriminator_value: customTaskTypeId ?? null
      };
    default:
      return {
        discriminator_field: null,
        discriminator_value: null
      };
  }
}
