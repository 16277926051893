import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { ValueListValue } from 'data/models/types';
import { TaskType } from 'src/modules/tasks/common/types/TaskType';
import { Field, FieldArray } from '@rexlabs/form';
import invariant from 'invariant';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult
} from 'react-beautiful-dnd';
import Box from '@rexlabs/box';
import { get, uniqueId } from 'lodash';
import { TextInput } from '@rexlabs/text-input';
import Tooltip from '@rexlabs/tooltip';
import { GhostIconButton, OutlineIconButton } from '@rexlabs/button';
import Icons from '@rexlabs/icons';
import AddIcon from 'view/components/icons/add';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { DragHandle } from '../../components/smart-checklists/drag-handle';
import {
  ChecklistTemplatesForm,
  ChecklistTemplatesItemForm
} from '../../types/ChecklistTemplatesForm';
import { ChecklistTemplateItem } from '../../types/ChecklistTemplate';

const overrideStyles = StyleSheet({
  deleteRed: {
    color: ({ token }) => token('palette.red.900')
  },
  deleteGrey: {
    color: ({ token }) => token('palette.grey.500')
  }
});

export const checklistTemplateItemOutcomesBlock: BlockConfig<
  ChecklistTemplateItem,
  {
    taskType: ValueListValue<TaskType>;
    mainValues: Partial<ChecklistTemplatesForm>;
  },
  ChecklistTemplatesItemForm & { new_outcome_option: string }
> = {
  id: 'checklist-template-item-outcomes',
  title: 'Outcomes',
  Edit({ data, blockProps, values, setFieldValue }) {
    const s = useStyles(overrideStyles);
    invariant(blockProps, 'blockProps is required');
    const { mainValues } = blockProps;
    invariant(values, 'values is required');
    // TODO: in mapper, need to set `has_outcome` based on the subtask type
    return (
      <FieldArray name='outcome_data.outcome_options'>
        {function LineItems({ fields, push, move }) {
          const handleNewOutcome = () => {
            push({
              label: values.new_outcome_option,
              id: generateItemId(values.new_outcome_option)
            });
            setFieldValue?.('new_outcome_option', '');
          };

          const handleDragEnd = (result: DropResult) => {
            if (!result.destination) {
              return;
            }

            move(result.source.index, result.destination.index);
          };

          return (
            <DragDropContext onDragEnd={handleDragEnd}>
              <Box>
                <Droppable droppableId='outcomes'>
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      display='flex'
                      flexDirection='column'
                      gap={12}
                      {...provided.droppableProps}
                    >
                      {fields.map(({ field, actions }, index) => (
                        <Draggable
                          key={field.name}
                          draggableId={field.name}
                          index={index}
                        >
                          {(provided) => {
                            let isLocked = false;
                            if (mainValues) {
                              const groupIds = mainValues.checklist_template_item_groups
                                ?.filter(
                                  (group) =>
                                    group.triggered_by_checklist_template_item
                                      ?.id === data?.id &&
                                    group.triggered_by_value ===
                                      get(values, field.name)?.id
                                )
                                .map((group) => group.id);

                              const items = mainValues.checklist_template_items?.filter(
                                (item) =>
                                  item.checklist_template_item_group?.id &&
                                  groupIds?.includes(
                                    item.checklist_template_item_group?.id
                                  )
                              );
                              isLocked = !!items?.length;
                            }
                            return (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <Box
                                  display='flex'
                                  flexDirection='row'
                                  alignItems='center'
                                  gap={12}
                                  padding={12}
                                  style={{
                                    background: '#f6f6f6',
                                    borderRadius: 8
                                  }}
                                >
                                  <Box
                                    width={20}
                                    height={20}
                                    display='flex'
                                    justifyContent='center'
                                    alignItems='center'
                                    {...provided.dragHandleProps}
                                  >
                                    <DragHandle />
                                  </Box>
                                  <Field
                                    optional={false}
                                    name={`${field.name}.label`}
                                    Input={TextInput}
                                  />
                                  <Tooltip
                                    isDisabled={!isLocked}
                                    Content={() =>
                                      'Cannot delete outcome while path contains subtasks'
                                    }
                                    placement='left'
                                  >
                                    <GhostIconButton
                                      isDisabled={isLocked}
                                      Icon={Icons.DeleteCross}
                                      onClick={actions.remove}
                                      {...s(
                                        isLocked ? 'deleteGrey' : 'deleteRed'
                                      )}
                                    />
                                  </Tooltip>
                                </Box>
                              </div>
                            );
                          }}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
                <AddNewOutcomePrompt onClick={handleNewOutcome} />
              </Box>
            </DragDropContext>
          );
        }}
      </FieldArray>
    );
  }
};

function generateItemId(option: string): string {
  // We generate an incrementing ID here, and the backend will replace them with unique UUIDs when we save.
  const optionSlug = option.toLowerCase().replace(/\s/g, '-');
  return uniqueId(`placeholder-outcome-option-${optionSlug}-`);
}

function AddNewOutcomePrompt({ onClick }) {
  return (
    <Box>
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        gap={12}
        padding={12}
      >
        {/* placeholder to align with above items */}
        <Box width={20} />
        <Field
          optional={false}
          name={`new_outcome_option`}
          Input={TextInput}
          inputProps={{
            placeholder: 'Add another option'
          }}
        />
        <OutlineIconButton Icon={AddIcon} onClick={onClick} />
      </Box>
    </Box>
  );
}
