import React, { useCallback } from 'react';

import { query } from '@rexlabs/model-generator';

import { Card } from 'view/components/card';
import ROUTES from 'routes/app';
import { trustLedgerModel } from 'src/modules/trust-ledgers/models/trust-ledger-model';
import { Columns, ListTable } from 'view/components/table';
import { ListScreen } from 'view/components/list-screen/list-screen';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { TrustLedger } from 'src/modules/trust-ledgers/types/trust-ledger-types';
import { useTranslation } from 'react-i18next';
import { useGetTrustLedgerActions } from '../utils/use-get-trust-ledger-actions';

const columns: Columns = [
  {
    id: 'name',
    Header: 'Name',
    minWidth: 360
  },
  {
    id: 'object.type',
    type: 'value',
    Header: 'Folio',
    width: 140
  },
  {
    id: 'bank_account',
    type: 'bank_account',
    Header: 'Bank account',
    width: 250
  },
  {
    id: 'financial_summary.balance_amount',
    Header: 'Balance',
    type: 'currency',
    width: 140
  },
  {
    id: 'created_at',
    type: 'date',
    Header: 'Created at',
    width: 140
  },
  {
    id: 'updated_at',
    type: 'date',
    Header: 'Updated at',
    width: 140
  }
];

const getRowLinkProps = ({ item }) => ({
  to: ROUTES.TRUST_LEDGER,
  params: { trustLedgerId: item.id }
});

const getTrustLedgersQuery = () => query`{
  ${trustLedgerModel} {
    id
    name
    object {
      id
      label
      type
    }
    bank_account {
      id
      name
    }
    financial_summary
  }
}`;

export const TrustLedgersList = () => {
  const getActions = useGetTrustLedgerActions();
  const { getSort, getFilters } = useTableFilters('trust-ledgers');

  const getActionMenuItems = useCallback(
    ({ item }) =>
      transformActionDeclarationsToActionMenuItems(
        getActions(item as TrustLedger)
      ),
    []
  );

  const { t } = useTranslation();

  return (
    <ListScreen
      privilege={'trust-ledgers.read'}
      title={t('financials.trust.trust-ledgers.label.plural')}
    >
      <Card>
        <ListTable
          id='financials-trust-ledgers'
          columns={columns}
          getQuery={getTrustLedgersQuery}
          getRowLinkProps={getRowLinkProps}
          getActionMenuItems={getActionMenuItems}
          getFilters={getFilters}
          getSort={getSort}
        />
      </Card>
    </ListScreen>
  );
};
