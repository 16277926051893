import { api } from 'utils/api/api-client';
import { RegularQuri, toQuri } from '@rexlabs/quri';
import { BankAccount } from 'data/models/entities/financials/bank-accounts';
import { BatchReceiptingItem } from '../types';
import { BankStatementTransactionImport } from '../types/bank-statement-transaction-import';

function createTrustJournalEntryInPipeline(id: string, receiptData) {
  return {
    id: `trust_journal_entry-${id}`,
    method: 'POST',
    path: `/api/v1/financials/trust-journal-entries`,
    json: { receipt: receiptData }
  };
}

function getMatchBankStatementRequest({
  parentId,
  bankStatementTransactionId
}: {
  parentId: string;
  bankStatementTransactionId: string;
}) {
  return {
    id: `match_bank_statement-${parentId}`,
    method: 'PUT',
    path: `/api/v1/financials/bank-statement-transactions/${bankStatementTransactionId}/matched-trust-transactions`,
    json: {
      trust_journal_entries: [
        { id: `{{$.trust_journal_entry-${parentId}.id}}` }
      ]
    }
  };
}

export function useCreateTrustJournalEntryAndLinkTransaction() {
  const createTrustJournalEntryAndLinkTransaction = async (
    item: BatchReceiptingItem
  ) => {
    return api.post('financials/bank-statement-transactions/receipt-matched', {
      selection: {
        selected_ids: [item.id]
      }
    });
  };

  const createTjeAndLinkToTransactionInPipeline = async (
    bankStatementTransactionId: string,
    receiptData
  ) => {
    return api.post('request-pipelines', {
      requests: [
        createTrustJournalEntryInPipeline(
          bankStatementTransactionId,
          receiptData
        ),
        getMatchBankStatementRequest({
          parentId: bankStatementTransactionId,
          bankStatementTransactionId
        })
      ]
    });
  };

  const batchCreateTrustJournalEntriesAndLinkTransactions = async ({
    bankAccount,
    bankTransactionFile
  }: {
    bankAccount?: BankAccount;
    bankTransactionFile?: BankStatementTransactionImport;
  }) => {
    const filters: RegularQuri[] = [
      {
        field: 'matched_receipt',
        op: 'eq',
        value: 'true'
      },
      {
        field: 'locked',
        op: 'eq',
        value: 'false'
      },
      {
        field: 'is_parent',
        op: 'neq',
        value: 'true'
      }
    ];

    if (bankAccount) {
      filters.push({
        field: 'bank_account_id',
        op: 'eq',
        value: bankAccount.id
      });
    }

    if (bankTransactionFile) {
      filters.push({
        field: 'bank_statement_transaction_import_id',
        op: 'eq',
        value: bankTransactionFile.id
      });
    }

    return api.post('financials/bank-statement-transactions/receipt-matched', {
      selection: {
        q: toQuri(filters)
      }
    });
  };

  return {
    createTrustJournalEntryAndLinkTransaction,
    createTjeAndLinkToTransactionInPipeline,
    batchCreateTrustJournalEntriesAndLinkTransactions
  };
}
