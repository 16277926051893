import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';
import { useGetCreatePropertyAreaAction } from 'src/modules/property-areas/property-areas/hooks/action-declarations/use-get-create-property-area-action';
import { Property } from 'src/modules/properties/types/property-types';
import { Card } from 'view/components/card';

import EmptyTable from 'src/assets/illustrations/empty-table.svg';
import { Message } from 'view/components/@luna/message';
import { isEmpty } from 'lodash';
import Box from '@rexlabs/box';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult
} from 'react-beautiful-dnd';
import { ActionButtons } from 'view/components/@luna/action-buttons';
import { useModelActions } from '@rexlabs/model-generator';
import { propertiesModel } from 'data/models/entities/properties';
import { PropertyArea } from 'src/modules/property-areas/property-areas/types/PropertyArea';
import {
  PropertyAreaActionMenu,
  PropertyAreaCard
} from '../components/property-area';

export const inspectionAreasBlock: BlockConfig<Property> = {
  id: 'inspection-areas',
  title: 'Inspection Areas',
  Empty: EmptyState,
  showEmpty: (data) => isEmpty(data?.areas?.data),
  View: ({ data }) => {
    const { updateItem } = useModelActions(propertiesModel);
    // note: eslint is worried that this variable is recreated on every render,
    // but there isn't an actual case where this would occur -
    // because we wouldn't render the block if the data was empty.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const originalPropertyAreas = data?.areas?.data || [];

    const [orderedPropertyAreas, setOrderedPropertyAreas] = React.useState(
      originalPropertyAreas
    );
    React.useLayoutEffect(() => {
      // if the data changes due to other updates from the server, update the local areas
      setOrderedPropertyAreas(originalPropertyAreas);
    }, [originalPropertyAreas]);

    const propertyAreasObject: Record<
      string,
      PropertyArea
    > = React.useMemo(() => makeIdAreaMap(originalPropertyAreas), [
      originalPropertyAreas
    ]);

    const handleDragEnd = async (result: DropResult) => {
      if (!result.destination) return;

      const originalOrder = orderedPropertyAreas; // Store original order
      const newItemOrder = Array.from(orderedPropertyAreas);

      newItemOrder.splice(result.source.index, 1);
      newItemOrder.splice(
        result.destination.index,
        0,
        propertyAreasObject[result.draggableId]
      );
      // to reduce UI jank: optimistically update the order
      setOrderedPropertyAreas(newItemOrder);
      // then update the server
      try {
        await updateItem({
          id: data!.id,
          data: {
            area_order: newItemOrder.map((area) => area.id)
          }
        });
      } catch (err) {
        // If the server update fails, revert to original order
        setOrderedPropertyAreas(originalOrder);
      }
    };

    return (
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable direction='vertical' droppableId='propertyAreas'>
          {(provided) => {
            return (
              <Box
                flexDirection='column'
                gap={12}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {orderedPropertyAreas.map((area, index) => {
                  if (!area) return null;
                  return (
                    <Draggable
                      draggableId={area.id}
                      key={area.id}
                      index={index}
                    >
                      {(provided) => {
                        return (
                          <PropertyAreaCard
                            key={area.id}
                            ref={provided.innerRef}
                            propertyArea={area}
                            dragContainerProps={provided.draggableProps}
                            dragHandleProps={provided.dragHandleProps!}
                          >
                            <PropertyAreaActionMenu
                              propertyId={data!.id}
                              propertyArea={area}
                              areaOrder={orderedPropertyAreas.map(
                                (area) => area.id
                              )}
                            />
                          </PropertyAreaCard>
                        );
                      }}
                    </Draggable>
                  );
                })}

                {provided.placeholder}
              </Box>
            );
          }}
        </Droppable>
      </DragDropContext>
    );
  },
  Actions: ({ data }) => {
    const getCreatePropertyAreaAction = useGetCreatePropertyAreaAction();

    return (
      <ActionButtons
        actions={data ? [getCreatePropertyAreaAction({ property: data })] : []}
      />
    );
  }
};

function EmptyState({ data }) {
  const getCreatePropertyAreaAction = useGetCreatePropertyAreaAction();

  return (
    <Card>
      <Message
        title='Inspection areas'
        Illustration={EmptyTable}
        actions={
          data
            ? [
                getCreatePropertyAreaAction({
                  property: data
                })
              ]
            : []
        }
      >
        Provide details about rooms and other living spaces for this property.
      </Message>
    </Card>
  );
}

function makeIdAreaMap(areas: PropertyArea[]): Record<string, PropertyArea> {
  return areas.reduce((acc, area) => {
    acc[area.id] = area;
    return acc;
  }, {});
}
