import { useWhereabouts } from '@rexlabs/whereabouts';
import { Columns } from 'src/view/components/table';
import { ComplianceProgressDropdown } from 'src/modules/tasks/common/components/compliance-progress-dropdown';
import { SummaryCell } from '../../common/components/summary-cell';
import { ExpiryCell } from '../../common/components/expiry-cell';
import {
  getLinkedTaskText,
  LinkedTasksCell
} from '../../common/components/linked-tasks-cell';
import { PropertyComplianceTask } from '../types/PropertyComplianceTask';
import {
  closedDate,
  createdBy,
  createdDate,
  managedBy
} from '../../common/utils/common-columns';

const linkedTasks = {
  id: 'linked_tasks',
  Header: 'Linked tasks',
  Cell: LinkedTasksCell,
  accessor: (item: PropertyComplianceTask) => item,
  toCsv: (item: PropertyComplianceTask) =>
    getLinkedTaskText(item.task_links?.data)
};

const status = {
  id: 'status',
  Header: 'Status',
  Cell: ComplianceProgressDropdown,
  accessor: (item: PropertyComplianceTask) => item,
  width: 150,
  toCsv: (item: PropertyComplianceTask) => item?.status?.label
};

const followUpDate = {
  id: 'follow_up_date',
  Header: 'Follow up',
  accessor: (item: PropertyComplianceTask) => item.follow_up_date,
  type: 'date',
  width: 140
} as const;

const dueDate = {
  id: 'due_date',
  Header: 'Expires',
  Cell: ExpiryCell,
  accessor: (item: PropertyComplianceTask) => item,
  width: 160,
  toCsv: (item: PropertyComplianceTask) => item.due_date || 'NULL'
};

const summary = {
  id: 'summary',
  Header: 'Summary',
  Cell: SummaryCell,
  accessor: (item) => item,
  width: 380,
  toCsv: (item: PropertyComplianceTask) => item.summary
};

export function usePropertyComplianceColumns(): Columns {
  const whereabouts = useWhereabouts();
  const tab = whereabouts.hashQuery?.tab;

  if (tab === 'closed') {
    return [
      summary,
      closedDate,
      dueDate,
      managedBy,
      linkedTasks,
      createdBy,
      createdDate
    ];
  }

  return [
    summary,
    dueDate,
    followUpDate,
    status,
    managedBy,
    linkedTasks,
    createdBy,
    createdDate
  ];
}
