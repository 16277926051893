import { Columns } from 'src/view/components/table';

export const portfolioRoleColumns: Columns = [
  {
    id: 'label',
    Header: 'Label',
    width: 120
  },
  {
    id: 'description',
    Header: 'Description',
    width: 120
  },
  {
    id: 'system_purpose',
    Header: 'System purpose',
    width: 120,
    accessor: (item) => item.system_purpose?.label
  }
];
