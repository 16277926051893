import React from 'react';

import { Card } from 'view/components/card';
import ROUTES from 'routes/app';
import { ListScreen } from 'view/components/list-screen/list-screen';

import { PortfoliosListTable } from '../components/portfolios-list-table';

export const PortfoliosList = () => {
  return (
    <ListScreen
      privilege={'portfolios.update'}
      title='Portfolios'
      createLabel='Create portfolio'
      createLinkProps={{ to: ROUTES.PORTFOLIOS_LIST.CREATE }}
    >
      <Card>
        <PortfoliosListTable />
      </Card>
    </ListScreen>
  );
};
