import React, { useEffect, useMemo } from 'react';
import { query, useEntityQuery } from '@rexlabs/model-generator';
import { useMediaQuery } from '@rexlabs/breakpoints';
import { push, useWhereabouts } from '@rexlabs/whereabouts';

import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { RecordScreen } from 'view/components/record-screen';

import { useRecordScreenSubmitHandler } from 'view/hooks/use-record-screen-submit-handler';
import { TaskRightBarOverride } from 'src/modules/tasks/common/components/right-bar-override';
import { useGetUpdateRoomSubmitHandler } from 'src/modules/tasks/inspections/hooks/use-update-room-submit-handler';
import { useGetUpdateRoutineAreaSubmitHandler } from 'src/modules/tasks/inspections/hooks/use-update-routine-area-submit-handler';
import { useInspectionDetailsContent } from '../data/use-inspection-details-content';
import { InspectionTitleBlock } from '../components/inspection-title-block';
import { tasksModel } from '../../common/models/tasks-model';
import { mapInspectionFormDataToInspectionCreateRequest } from '../mappers/map-inspection-form-data-to-inspection-create-request';
import { InspectionFormData } from '../types/InspectionFormData';
import { InspectionTask } from '../types/InspectionTask';
import { mapInspectionTaskToInspectionFormData } from '../mappers/map-inspection-task-to-inspection-form-data';
import { useGetIsEntryExit } from '../entry-exit/hooks/use-get-is-entry-exit';
import { mapEntryExitTaskToFormData } from '../entry-exit/mappers/use-map-entry-exit-task-to-form-data';
import { useGetPropertyDetailsSubmitHandler } from '../entry-exit/hooks/use-get-property-details-submit-handler';
import { useGetSupportingDocumentsSubmitHandler } from '../entry-exit/hooks/use-get-supporting-documents-submit-handler';
import {
  hasEntryExitAreaBlockChanges,
  useGetUpdateEntryExitAreasSubmitHandler
} from '../hooks/use-update-entry-exit-areas-submit-handler';
import { useGetCoverPageSubmitHandler } from '../entry-exit/hooks/use-get-cover-page-submit-handler';

// do we need ownership on record?
export const getInspectionQuery = (id: string) => query`{ 
  ${tasksModel} (id: ${id}) {
    id
    details
    inspectionDetails
    property {
        address
        active_property_ownership {
            ownership {
                owners
            }
        },
        active_property_tenancy {
            tenancy
        }
    }
    managed_by
    task_links
    created_by
    updated_by
  }
}`;

interface inspectionDetailsProps {
  inspectionId?: string;
}

// NOTE: This is used to redirect to the report tab if the user is on mobile.
// The reason for this as the user is likely wanting to do a report if they are on mobile.
const useMobileRedirect = () => {
  const whereabouts = useWhereabouts();
  const matchesMobile = useMediaQuery({ maxWidth: 's' });

  const triggerRedirectForMobile = () =>
    push({
      config: {
        ...whereabouts,
        query: { tab: 'inspection-report' }
      }
    });

  return {
    triggerRedirectForMobile,
    shouldRedirect: matchesMobile
  };
};

export function InspectionDetails({ inspectionId }: inspectionDetailsProps) {
  const breadcrumbs = [{ type: BREADCRUMBS.INSPECTION_TASKS }];

  const { triggerRedirectForMobile, shouldRedirect } = useMobileRedirect();

  const query = useMemo(() => getInspectionQuery(inspectionId!), [
    inspectionId
  ]);

  const { status, data: rawData, actions } = useEntityQuery(query, {
    throwOnError: false
  });

  const getUpdateRoomSubmitHandler = useGetUpdateRoomSubmitHandler(
    inspectionId!
  );
  const getUpdateRoutineAreaSubmitHandler = useGetUpdateRoutineAreaSubmitHandler(
    inspectionId!
  );

  const getUpdateEntryExitAreasSubmitHandler = useGetUpdateEntryExitAreasSubmitHandler(
    inspectionId!
  );

  const getPropertyDetailsSubmitHandler = useGetPropertyDetailsSubmitHandler(
    inspectionId!
  );

  const getSupportingDocumentsSubmitHandler = useGetSupportingDocumentsSubmitHandler(
    inspectionId!
  );

  const getCoverPageSubmitHandler = useGetCoverPageSubmitHandler(inspectionId!);

  const handleSubmit = useRecordScreenSubmitHandler(async (formValues) => {
    const { changedBlockIds } = formValues;

    if (
      changedBlockIds.some(hasBlockNameWith('routine-inspection-report-area'))
    ) {
      return await getUpdateRoutineAreaSubmitHandler(formValues);
    }

    if (changedBlockIds.some(hasBlockNameWith('inspection-report-room'))) {
      return await getUpdateRoomSubmitHandler(formValues);
    }

    if (changedBlockIds.some(hasBlockNameWith('property-details'))) {
      return await getPropertyDetailsSubmitHandler(formValues);
    }

    if (changedBlockIds.some(hasEntryExitAreaBlockChanges)) {
      return await getUpdateEntryExitAreasSubmitHandler(formValues, rawData);
    }

    if (changedBlockIds.includes('entry-exit-supporting-documents')) {
      return await getSupportingDocumentsSubmitHandler(formValues);
    }

    if (changedBlockIds.some(hasBlockNameWith('report-cover-details'))) {
      return await getCoverPageSubmitHandler(formValues);
    }

    const dataToSubmit = mapInspectionFormDataToInspectionCreateRequest(
      formValues.changedValues
    );

    await actions.updateItem({
      id: inspectionId,
      data: dataToSubmit
    });
  });

  const data: InspectionTask | undefined = rawData;
  const titleBlock = <InspectionTitleBlock inspection={data} />;

  const content = useInspectionDetailsContent(data);
  const getIsEntryExit = useGetIsEntryExit();

  const initialValues: InspectionFormData | undefined = data
    ? getIsEntryExit(data?.details?.type?.id)
      ? mapEntryExitTaskToFormData(data)
      : mapInspectionTaskToInspectionFormData(data)
    : undefined;
  useEffect(() => {
    if (shouldRedirect) {
      triggerRedirectForMobile();
    }
  }, []);

  return (
    <RecordScreen
      privilege={'tasks.inspections.read'}
      isLoading={status === 'loading'}
      data={data}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      content={content}
      titleBlock={titleBlock}
      breadcrumbs={breadcrumbs}
      RightBarOverride={TaskRightBarOverride}
    />
  );
}

function hasBlockNameWith(name: string) {
  return (item) => item.includes(name);
}
