import Tooltip from '@rexlabs/tooltip';
import Box from '@rexlabs/box';
import InfoCircleIcon from 'view/components/icons/info';
import React from 'react';

export function FolioLedgerTableTooltip() {
  return (
    <Tooltip
      Content={() =>
        'Only the last 5 results will be displayed below. To view all ledger line items please click the View report button'
      }
    >
      <Box flexDirection='row' alignItems='center' width={24} height={24}>
        <InfoCircleIcon size={'m'} />
      </Box>
    </Tooltip>
  );
}
