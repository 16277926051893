import { useDialog } from '@rexlabs/dialog';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { CreateUserGroupRecordDialog } from '../../dialogs/create-user-group-record-dialog';

export function useGetCreateUserGroupAction() {
  const { open } = useDialog(CreateUserGroupRecordDialog);
  return (): ActionDeclaration => {
    return {
      id: 'create-user-group',
      group: 'user-groups',
      label: 'Create User group',
      handleAction: async () => {
        open();
      }
    };
  };
}
