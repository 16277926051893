import React, { useMemo } from 'react';
import { query, useEntityQuery } from '@rexlabs/model-generator';

import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { RecordScreen } from 'view/components/record-screen';

import { useRecordScreenSubmitHandler } from 'view/hooks/use-record-screen-submit-handler';
import { trustJournalEntryModel } from 'src/modules/trust-journal-entries/models/trust-journal-entries';
import { TrustJournalEntry } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';
import { VoidedBanner } from 'src/modules/common/components/voided-banner';
import { ReversingEntryBanner } from 'src/modules/trust-journal-entries/components/reversing-entry-banner';
import { trustJournalEntryDetailsContent } from '../data/details-content';
import { TrustJournalEntryTitleBlock } from '../components/trust-journal-entry-title-block';

const getTrustJournalEntryQuery = (trustJournalEntryId: string) => query`{
  ${trustJournalEntryModel} (id: ${trustJournalEntryId}) {
    id
    ledger_line_items {
      trust_journal_entry
      trust_ledger {
        folio
      }
      invoice
    }
    reversed_by {
      created_by
    }
    reverses
    payment_information
    bank_account
    contacts
    created_by
    transfer_source {
      trust_ledger {
        folio
      }
    }
    withdrawal_source {
      trust_ledger {
        folio
      }
    }
    refunded_by  
    voided_by
  }
}`;

interface TrustJournalEntryDetailsScreenProps {
  trustJournalEntryId: string;
}

const getReversalStatusBanner = (data?: TrustJournalEntry) => {
  if (data?.status.id === 'reversed') {
    return (
      <VoidedBanner
        record={
          {
            ...data,
            voided_at: data.reversed_by?.created_at,
            voided_by: data.reversed_by?.created_by
          } as any
        }
      />
    );
  }
  if (data?.status.id === 'reversing_entry') {
    return <ReversingEntryBanner record={data} />;
  }
};

export function TrustJournalEntryDetailsScreen({
  trustJournalEntryId
}: TrustJournalEntryDetailsScreenProps) {
  const breadcrumbs = [{ type: BREADCRUMBS.TRUST_JOURNAL_ENTRY }];
  const query = useMemo(() => getTrustJournalEntryQuery(trustJournalEntryId!), [
    trustJournalEntryId
  ]);
  const { data, status, actions } = useEntityQuery(query);

  const handleSubmit = useRecordScreenSubmitHandler(({ changedValues }) =>
    actions.updateItem({
      id: trustJournalEntryId,
      data: changedValues
    })
  );

  return (
    <RecordScreen
      privilege={'trust-journal-entries.read'}
      isLoading={status === 'loading'}
      data={data}
      handleSubmit={handleSubmit}
      content={trustJournalEntryDetailsContent}
      titleBlock={
        <TrustJournalEntryTitleBlock
          trustJournalEntry={data as TrustJournalEntry}
        />
      }
      breadcrumbs={breadcrumbs}
      banner={getReversalStatusBanner(data)}
    />
  );
}
