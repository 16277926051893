import { useDialog } from '@rexlabs/dialog';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { FolioCommissionTemplate } from '../../types/FolioCommissionTemplate';
import { ViewSupplierCommissionTemplateDialog } from '../../dialogs/view-supplier-commission-template-dialog';

export function useGetViewSupplierCommissionTemplateAction() {
  const { open: openViewSupplierCommissionTemplateDialog } = useDialog(
    ViewSupplierCommissionTemplateDialog
  );

  return (item: FolioCommissionTemplate): SingleActionDeclaration => {
    return {
      label: 'View',
      handleAction: () =>
        openViewSupplierCommissionTemplateDialog({
          supplierCommissionTemplate: item
        })
    };
  };
}
