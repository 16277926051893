import React from 'react';
import { Columns, TableProvider } from 'view/components/table';
import { DumbTable } from 'view/components/table/dumb-table';
import { TableProviderProps } from '@rexlabs/table';
import { formatCurrency } from 'utils/formatters';
import { FolioLedgerReportItemRowData } from '../types/folio-ledger-report';

const columns: Columns<FolioLedgerReportItemRowData> = [
  {
    Header: 'Entry Id',
    accessor: (item) =>
      item.tje_reference?.length > 0 ? item.tje_reference : '-',
    width: 120
  },
  {
    Header: 'Date of',
    type: 'date',
    accessor: (item) => item.date,
    width: 120
  },
  {
    Header: 'Description',
    accessor: (item) => item.description
  },
  {
    Header: 'Amount',
    accessor: (item) => formatCurrency(item.amount ?? 0),
    cellProps: {
      align: 'right'
    },
    width: 120
  },
  {
    Header: 'Balance',
    accessor: (item) => formatCurrency(item.balance ?? 0),
    cellProps: {
      align: 'right'
    },
    width: 120
  }
];

export function FolioLedgerTable({ items }: Pick<TableProviderProps, 'items'>) {
  return (
    <TableProvider items={items} columns={columns} isLoading={false}>
      <DumbTable />
    </TableProvider>
  );
}
