import React from 'react';
import { useQueryClient } from 'react-query';
import {
  StatusChangeActionArgs,
  useStatusChangeAction
} from 'src/modules/common/actions/status/use-status-change-action';
import { invokeActionDeclaration } from 'src/modules/common/actions/utils/invoke-action-declaration';
import ArrowBackIcon from 'view/components/icons/arrow-back';
import { usePermission } from 'src/modules/authorization/roles/hooks/use-permission';
import { useDialog } from '@rexlabs/dialog';
import { AccessDeniedDialog } from 'view/components/dialogs/access-denied-dialog';
import { bankStatementTransactionsModel } from '../../models/bank-statement-transaction-model';
import { BatchReceiptingItem } from '../../types';
import { useBankAccountState } from '../use-bank-account-state';
import { bankStatementTransactionsQueryKey } from '../use-bank-statement-transactions-query';
import { getTransactionLabel } from '../use-batch-receipting-button-config';
import { useBatchReceiptingStatsSummary } from '../use-batch-receipting-stats-summary';

export function useGetRestoreAction() {
  const { bankAccount } = useBankAccountState();
  const { isStandardUser, roleName } = usePermission();

  const { refetch: refetchStatsSummary } = useBatchReceiptingStatsSummary(
    bankAccount?.id
  );

  const getStatusChangeAction = useStatusChangeAction(
    bankStatementTransactionsModel
  );

  const accessDeniedDialog = useDialog(AccessDeniedDialog);
  const queryClient = useQueryClient();

  return (item: BatchReceiptingItem) => {
    const transactionLabel = getTransactionLabel(item);
    return {
      label: 'Restore',
      Icon: ArrowBackIcon,
      onClick: async () => {
        if (isStandardUser) {
          return accessDeniedDialog.open({
            role: roleName
          });
        }

        await invokeActionDeclaration<StatusChangeActionArgs<'new'>[]>(
          getStatusChangeAction,
          {
            record: item,
            status: 'new',
            dialogOptions: {
              title: 'Restore transaction',
              reasonLabel: 'Comment',
              description: (
                <>
                  Do you want to restore the transaction for {transactionLabel}?
                </>
              ),
              afterAction: async () => {
                await refetchStatsSummary();
                await queryClient.refetchQueries({
                  queryKey: [bankStatementTransactionsQueryKey],
                  refetchPage: () => true
                });
              }
            },
            toastOptions: {
              description: (
                <>Transaction for {transactionLabel} successfully restored</>
              )
            }
          }
        );
      }
    };
  };
}
