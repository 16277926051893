import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import 'src/modules/tasks/settings/utils/checklist-item-validation-rules.ts';

import { ChecklistItemWithId } from '../../common/blocks/create-checklists-block';

export const actionTriggerDialogBlock: BlockConfig<{
  message: string;
  checklistItem: ChecklistItemWithId;
}> = {
  id: 'action-trigger',
  title: 'Action trigger',
  Edit: ({ data }) => {
    return <Grid columns={1}>{data?.message}</Grid>;
  }
};
