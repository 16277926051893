import { useStyles, StyleSheet } from '@rexlabs/styling';
import { TitleBlock } from 'view/components/cards/title/title-block';
import { RecordObject, RecordTypes } from 'data/models/types';
import { getRecordTitle } from 'utils/records/get-record-title';
import { TagConfig } from 'view/components/@luna/title-block';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import { useGetToggleClosedAction } from 'src/modules/tasks/common/hooks/action-declarations/use-get-toggle-closed-action';
import { formatDate } from 'utils/dates/format';
import { lowerCase } from 'lodash';
import React from 'react';
import TaskIcon from 'view/components/icons/task';
import { tasksModel } from 'src/modules/tasks/common/models/tasks-model';
import { useGetCreateSMSAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-sms-action';
import { useGetCreateEmailAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-email-action';
import { CustomTask } from '../types/CustomTask';
import { useCustomTaskStatusTag } from '../hooks/use-custom-task-status-tag';

const type = RecordTypes.TaskCustomTask;

type CustomTaskRecordObject = Extract<
  RecordObject,
  { type: 'task_custom_task' }
>;

type CustomTaskTitleBlockProps = {
  customTask?: CustomTask;
  onStatusChange?: () => void;
};

const styles = StyleSheet({
  subText: {
    color: ({ token }) => token('color.textStyle.body.default'),
    fontWeight: ({ token }) => token('typography.weight.semibold')
  }
});

export function CustomTaskTitleBlock({
  customTask
}: CustomTaskTitleBlockProps) {
  const s = useStyles(styles);

  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();
  const getToggleClosedAction = useGetToggleClosedAction();
  const getCreateEmailAction = useGetCreateEmailAction(tasksModel);
  const getCreateSMSAction = useGetCreateSMSAction(tasksModel);

  const statusTag: TagConfig = useCustomTaskStatusTag(customTask);

  const title = getRecordTitle({
    type,
    object: customTask
  } as CustomTaskRecordObject);
  if (!customTask) {
    return null;
  }

  const actions = [
    ...getPrimaryRecordActionGroup('task_custom_task', customTask.id),
    getToggleClosedAction(customTask),
    getCreateEmailAction(customTask),
    getCreateSMSAction(customTask)
  ];

  const closed_at = customTask.closed_at;

  return (
    <TitleBlock
      title={title}
      type={customTask.details?.type?.label ?? 'custom task'}
      actions={actions as ActionDeclaration[]}
      tags={closed_at ? undefined : [statusTag]}
      Icon={() => <TaskIcon size='l' />}
      subText={
        closed_at ? (
          <div>
            <span>
              This {customTask.details?.type?.label ?? 'custom task'} task has
              been closed with the status{' '}
            </span>
            <span {...s('subText')}>{lowerCase(customTask.status.label)}</span>{' '}
            on <span {...s('subText')}>{formatDate(closed_at)}</span>
          </div>
        ) : undefined
      }
    />
  );
}
