import React, { useEffect, useState } from 'react';
import { lowerCase, upperFirst } from 'lodash';

import { Field } from '@rexlabs/form';

import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { Value } from 'view/components/values';

import { MessageTemplateSelect } from 'src/modules/communications/common/components/selects/message-template-select';

import { Select } from '@rexlabs/select';
import { ChannelTypeId } from 'src/modules/communications/common/types/channel';
import { MessageTemplate } from 'src/modules/communications/message-templates/types/MessageTemplate';
import { getChannelIdsFromMessageTemplate } from 'src/modules/communications/messages/settings/message-triggers/utils/get-channel-ids-from-message-template';
import { getLabelForChannelTypeId } from 'src/modules/communications/messages/settings/message-triggers/utils/get-label-for-channel-type-id';
import { MessageTriggerData } from '../types/MessageTriggerData';
import { MessageTriggerForm } from '../types/MessageTriggerForm';
import { FieldMapItem } from '../utils/field-map';

export function messageTriggerSettingsBlockFactory(
  key: string,
  value: FieldMapItem
): BlockConfig<MessageTriggerData, any, MessageTriggerForm> {
  return {
    id: key,
    title: upperFirst(lowerCase(key)),
    View: ({ data, values }) => {
      // Using both here, to incase the values are not set yet.
      // This can happen if you refresh the triggers page, and settings have not
      // been fetched yet
      const template =
        data![key]?.template?.name || values![key]?.template?.name;
      const channels: ChannelTypeId[] =
        data![key]?.channel_types || values![key]?.channel_types;
      return (
        <Grid columns={2}>
          <Value key={key} label={value.label} value={template} />
          <Value
            key={`${key}-channels`}
            label='Selected channels'
            value={channels
              ?.map((channel) => getLabelForChannelTypeId(channel))
              .join(', ')}
          />
        </Grid>
      );
    },
    Edit: ({ data, setFieldValue }) => {
      const [channelTypes, setChannelTypes] = useState<ChannelTypeId[]>([]);

      useEffect(() => {
        const template = data?.[key]?.template;

        if (template) {
          setChannelTypes(getChannelIdsFromMessageTemplate(template));
        }
      }, [data]);

      return (
        <Grid columns={2}>
          <Field<typeof MessageTemplateSelect>
            key={`${key}-template`}
            id={`${key}-template`}
            name={`${key}[template]`}
            label={value.label}
            Input={MessageTemplateSelect}
            inputProps={{
              contextType: value.context_type_id,
              deselectable: true,
              recipientGroups: value?.recipient_groups,
              showChannelTypes: true
            }}
            onChange={(e) => {
              const template = (e.target.value as unknown) as MessageTemplate;

              if (template) {
                const channels = getChannelIdsFromMessageTemplate(template);
                setChannelTypes(channels);
                setFieldValue?.(`${key}[channel_types]`, channels);
              } else {
                setFieldValue?.(`${key}[channel_types]`, []);
              }
            }}
          />
          <Field
            key={`${key}-channel_types`}
            id={`${key}-channel_types`}
            name={`${key}[channel_types]`}
            label='Selected channels'
            Input={Select}
            inputProps={{
              items: channelTypes,
              normaliser: (item: ChannelTypeId) => ({
                label: getLabelForChannelTypeId(item),
                id: item
              }),
              searchable: false,
              multi: true
            }}
          />
        </Grid>
      );
    }
  };
}
