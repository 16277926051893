import { OutlineButton } from '@rexlabs/button';
import React from 'react';
import { usePermission } from 'src/modules/authorization/roles/hooks/use-permission';
import { ButtonGroupProps } from 'view/components/record-screen/dialog/types';

interface ProcessBillsButtonGroupProps extends ButtonGroupProps {
  handleNeedsMoreInfo?: () => void;
  onClose?: () => void;
}

export function ProcessBillsButtonGroup({
  handleSubmit,
  handleNeedsMoreInfo,
  onClose
}: ProcessBillsButtonGroupProps) {
  const { isFinancialUser, isAdminUser } = usePermission();
  const allowNeedsMoreInfo = isAdminUser || isFinancialUser;

  return (
    <>
      {/* Since we need access to values for adding a note, we explicitly set the target 
          of this button to submit a different form in a block.

          It targets a form in this block `frontend/src/modules/bill-processing/blocks/add-note-block.tsx`
      */}
      <OutlineButton type='submit' form='notesForm'>
        Add note
      </OutlineButton>

      {allowNeedsMoreInfo ? (
        <OutlineButton
          onClick={async () => {
            handleNeedsMoreInfo?.();

            const handleSubmitResponse = await handleSubmit();

            // If validation fails, don't proceed
            if (!handleSubmitResponse) {
              return;
            }

            onClose?.();
          }}
        >
          Needs more info
        </OutlineButton>
      ) : null}
    </>
  );
}
