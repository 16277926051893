import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useGetViewSupplierCommissionTemplateAction } from '../action-declarations/use-get-view-supplier-commission-template-action';
import { FolioCommissionTemplate } from '../../types/FolioCommissionTemplate';
import { useGetDuplicateSupplierCommissionTemplateAction } from '../action-declarations/use-get-duplicate-supplier-commission-template-action';
import { useGetDeleteSupplierCommissionTemplateAction } from '../action-declarations/use-get-delete-supplier-commission-template-action';
import { useGetAppliedToSupplierCommissionTemplateAction } from '../action-declarations/use-get-applied-to-supplier-commission-template-action';
import { useGetEditSupplierCommissionTemplateAction } from '../action-declarations/use-get-edit-supplier-commission-template-action';

export function useGetSupplierCommissionTemplateActions() {
  const getViewSupplierCommissionTemplateAction = useGetViewSupplierCommissionTemplateAction();
  const getDuplicateSupplierCommissionTemplateAction = useGetDuplicateSupplierCommissionTemplateAction();
  const getDeleteSupplierCommissionTemplateAction = useGetDeleteSupplierCommissionTemplateAction();
  const getAppliedToSupplierCommissionTemplateAction = useGetAppliedToSupplierCommissionTemplateAction();
  const getEditSupplierCommissionTemplateAction = useGetEditSupplierCommissionTemplateAction();

  return (template: FolioCommissionTemplate): ActionDeclaration[] => {
    return [
      getViewSupplierCommissionTemplateAction(template),
      getEditSupplierCommissionTemplateAction(template),
      getAppliedToSupplierCommissionTemplateAction(template),
      getDuplicateSupplierCommissionTemplateAction(template),
      getDeleteSupplierCommissionTemplateAction(template)
    ];
  };
}
