import React from 'react';

import { StyleSheet, useStyles } from '@rexlabs/styling';
import { NumberInputProps } from '@rexlabs/text-input';
import { Semibold } from '@rexlabs/text';
import Box from '@rexlabs/box';
import { CentAmountInput } from 'view/components/inputs/cent-amount';

export type AmountRangeValue = {
  from: number | null;
  to?: number | null;
};

interface AmountRangeInputProps
  extends Omit<NumberInputProps, 'value' | 'onChange' | 'onBlur'> {
  value?: AmountRangeValue;
  onChange?: (e: { target: { value: AmountRangeValue } }) => void;
  onBlur?: (e: { target: { value: AmountRangeValue } }) => void;
  hideUpperLimit?: boolean;
}

const defaultAmountRangeValue: AmountRangeValue = {
  from: null,
  to: null
};

const defaultStyles = StyleSheet({
  splitGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: ({ token }) => token('spacing.xs')
  },
  subtext: {
    color: ({ token }) => token('color.textStyle.body.subtext'),
    display: 'flex',
    alignItems: 'center',
    paddingRight: ({ token }) => token('spacing.xs')
  }
});

export function AmountRangeInput({
  value,
  onChange,
  onBlur,
  hideUpperLimit = false
}: AmountRangeInputProps) {
  const s = useStyles(defaultStyles);

  const handleChange = (newValue: Partial<AmountRangeValue>) => {
    onChange?.({
      target: {
        value: {
          ...(value || defaultAmountRangeValue),
          ...newValue
        }
      }
    });
  };

  const handleBlur = (newValue: Partial<AmountRangeValue>) => {
    onBlur?.({
      target: {
        value: {
          ...(value || defaultAmountRangeValue),
          ...newValue
        }
      }
    });
  };

  const handleFromChange = ({ target }) =>
    handleChange({
      from: target.value
    });

  const handleFromBlur = ({ target }) =>
    handleBlur({
      from: target.value
    });

  const handleToChange = ({ target }) =>
    handleChange({
      to: target.value
    });

  const handleToBlur = ({ target }) =>
    handleBlur({
      to: target.value
    });

  return (
    <div {...s('splitGrid')}>
      <CentAmountInput
        value={value?.from}
        onChange={handleFromChange}
        onBlur={handleFromBlur}
      />
      {hideUpperLimit ? (
        <Semibold {...s('subtext')}>and over</Semibold>
      ) : (
        <Box display='flex' flexDirection='row'>
          <Semibold {...s('subtext')}>to</Semibold>
          <CentAmountInput
            value={value?.to}
            onChange={handleToChange}
            onBlur={handleToBlur}
          />
        </Box>
      )}
    </div>
  );
}
