import { Generator } from 'data/models/generator';

import { auditableConfig } from 'src/modules/common/auditable/auditable-config';

import { InspectionRun } from '../types/InspectionRun';

const config = {
  entities: {
    related: {
      // TODO: configure includes here
      ...auditableConfig,
      inspection_tasks: {
        include: [
          'inspection_tasks',
          'inspection_tasks.property',
          'inspection_tasks.property.address',
          'inspection_tasks.details'
        ].join(',')
      }
    }
  }
};

export const inspectionRunsModel = new Generator<InspectionRun>(
  'inspection-runs',
  config
).createEntityModel();
