import * as React from 'react';

import { Select, SelectProps } from '@rexlabs/select';
import { api } from 'utils/api/api-client';
import { useItems } from 'view/hooks/use-items';

interface Postcode {
  postcode: string;
}

export function PostcodeSelect(props: SelectProps<string>) {
  const { ...selectProps } = props;

  const { getSelectProps } = useItems<string>({
    getItems: React.useCallback(fetchPostcodes, [])
  });

  return (
    <Select {...selectProps} {...getSelectProps()} normaliser={normaliser} />
  );
}

// Helper

function normaliser(item) {
  return {
    id: item,
    label: item
  };
}

async function fetchPostcodes(term) {
  const queryParams = new URLSearchParams();

  queryParams.append('search', term);

  const response = await api.get<Postcode[]>(`/uk-postcodes`, queryParams);
  return response.data.map((item) => item.postcode);
}
