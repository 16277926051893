import LockClosedIcon from 'view/components/icons/lock-closed';
import React from 'react';
import { PmApiError } from 'utils/api/create-error-from-response';
import { useToken } from '@rexlabs/styling';
import { Message } from '@rexlabs/notifications';
import { lowerCase } from 'lodash';
import Box from '@rexlabs/box';

export function SelectPrivilegeErrorMessage({ error }: { error: PmApiError }) {
  const token = useToken();
  const privilegesArray = getPrivilegesArray(error?.requiredPrivileges || []);

  return (
    <Message title="You don't have permissions" Icon={LockClosedIcon}>
      <>
        <span>
          If you think you should have access, please contact your system
          administrator.
        </span>

        <Box mt={token('spacing.l')}>
          <span>
            This section requires:
            <ul>
              {privilegesArray?.map((privilege) => {
                return (
                  <li key={[privilege.privilege, privilege.record].join('-')}>
                    <b>
                      {privilege.privilege &&
                        lowerCase(privilegeMap(privilege.privilege))}
                    </b>{' '}
                    privileges for <b>{lowerCase(privilege.record)}</b>
                  </li>
                );
              })}
            </ul>
          </span>
        </Box>
      </>
    </Message>
  );
}

function getPrivilegesArray(
  requiredPrivileges: string[]
): { record?: string; privilege?: string }[] {
  return (
    requiredPrivileges?.map?.((privilegeString) => {
      const privilegeElements = privilegeString?.split?.('.') || [];
      const privilege = privilegeElements.pop();
      const record = privilegeElements.join(' - ');

      return { record, privilege };
    }) || []
  );
}

function privilegeMap(privilege: string) {
  switch (privilege) {
    case 'read':
      return 'view';
    default:
      return privilege;
  }
}
