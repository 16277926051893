export const CUSTOM_REPORT_ROUTES = {
  CUSTOM_REPORT_LIST: {
    config: {
      path: '/custom-reports',
      Component: require('./screens/custom-report-list.tsx').CustomReportList
    }
  },
  CUSTOM_REPORT_DETAILS: {
    config: {
      path: '/custom-report/:customReportId',
      Component: require('./screens/custom-report-details.tsx')
        .CustomReportDetails
    }
  }
};
