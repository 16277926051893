import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';

import { PaymentHistoryRecordTable } from 'src/modules/payment-history/components/payment-history-record-table';
import { ActionButtons } from 'view/components/@luna/action-buttons';

import { useGetPaymentHistoryDownloadAction } from 'src/modules/tenancies/actions/use-get-payment-history-download-action';
import { Task } from 'src/modules/tasks/common/types/Task';

export const tenantPaymentHistoryBlock: BlockConfig<Task<'task_arrears'>> = {
  id: 'payment-history',
  title: 'Payment history',

  View: ({ data }) => {
    const propertyTenancy = data?.details?.property_tenancy;

    return propertyTenancy?.tenancy ? (
      <PaymentHistoryRecordTable
        key={propertyTenancy.property_id}
        object={propertyTenancy.tenancy}
        propertyId={propertyTenancy.property_id}
      />
    ) : null;
  },
  Actions: ({ data }) => {
    const getPaymentHistoryDownloadAction = useGetPaymentHistoryDownloadAction();
    const propertyTenancy = data?.details?.property_tenancy;

    const hasPreviousPayments =
      propertyTenancy?.rent_position?.fully_paid_period_start_date ||
      propertyTenancy?.rent_position?.partially_paid_period_start_date;
    return (
      <ActionButtons
        actions={[
          getPaymentHistoryDownloadAction({
            tenancy: propertyTenancy?.tenancy,
            propertyTenancy,
            isDisabled: !hasPreviousPayments
          })
        ]}
      />
    );
  }
};
