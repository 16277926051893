import { valueListFactory } from 'src/lib/testing/factories/value-list-factory';
import invariant from 'invariant';
import { mapTaskFormDataToTaskCreateRequest } from '../../common/mappers/map-task-form-data-to-task-create-request';
import { TaskCreateRequest } from '../../common/types/TaskCreateRequest';
import { CustomTaskFormData } from '../types/CustomTaskFormData';
import { CustomTaskTemplate } from '../../settings/types/CustomTaskTemplate';

export function mapCustomTaskFormDataToTaskCreateRequest(
  formData: Partial<CustomTaskFormData>,
  customTaskTemplate: CustomTaskTemplate
): Partial<TaskCreateRequest> {
  invariant(
    customTaskTemplate.type?.id,
    'Custom task template must have a type'
  );

  // map the base values for the task
  const base = mapTaskFormDataToTaskCreateRequest(formData);

  const details = {
    type: { id: customTaskTemplate.type!.id },
    custom_task_template: {
      id: customTaskTemplate.id
    }
  };

  return { ...base, type: valueListFactory('task_custom_task'), details };
}
