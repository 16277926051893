import React, { useMemo } from 'react';
import { query } from '@rexlabs/model-generator';

import { ListScreen } from 'view/components/list-screen/list-screen';
import { Card } from 'view/components/card';
import {
  financialsSecurityDepositTypesModel,
  SecurityDepositType
} from 'data/models/entities/financials/security-deposit-types';
import { Columns, ListTable, TabbedTable } from 'view/components/table';
import { DynamicAmountTableCell } from 'src/modules/agency-fees/components/dynamic-amount-table-cell';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import ROUTES from 'routes/app';

import { Tab } from 'view/components/table/tabbed';
import { useTranslation, Trans } from 'react-i18next';

import { useGetSecurityDepositTypeActions } from '../utils/use-get-security-deposit-type-actions';

const getSecurityDepositTypesQuery = () => query`{
    ${financialsSecurityDepositTypesModel} {
        id
        held_by_contact
        initial_payment_method
        top_up_payment_method
        held_by_bank_account
    }
}`;

const securityDepositTypeColumns: Columns<SecurityDepositType> = [
  {
    id: 'name',
    Header: 'Name'
  },
  {
    id: 'held_by',
    type: 'value',
    Header: 'Held by'
  },
  {
    id: 'payment-methods-or-bank-account',
    type: 'text',
    accessor: (item) =>
      item.initial_payment_method?.payment_method.label ||
      item.held_by_bank_account?.name,
    Header: 'Payment methods / Bank Account'
  },
  {
    id: 'default_amount',
    Cell: DynamicAmountTableCell,
    cellProps: {
      align: 'right'
    },
    Header: () => <Trans i18nKey='bonds-and-deposits.default-amount' />
  }
];

export function SecurityDepositTypesList() {
  const { t } = useTranslation();
  const getSecurityDepositTypeActions = useGetSecurityDepositTypeActions();

  const tabs = useMemo<Tab[]>(() => {
    const commonProps = {
      id: 'security-deposit-type-list',
      Table: ListTable,
      getQuery: getSecurityDepositTypesQuery,
      columns: securityDepositTypeColumns,
      getActionMenuItems: ({ item }) =>
        transformActionDeclarationsToActionMenuItems(
          getSecurityDepositTypeActions(
            item as SecurityDepositType,
            getSecurityDepositTypesQuery().uuid
          )
        )
    };

    return [
      {
        ...commonProps,
        name: 'ownerships-pending-disbursements',
        label: 'Active',
        forcedGlobalFilter: [
          {
            field: 'status_id',
            op: 'eq',
            value: 'active'
          }
        ]
      },
      {
        ...commonProps,
        name: 'archived',
        label: 'Archived',
        forcedGlobalFilter: [
          {
            field: 'status_id',
            op: 'eq',
            value: 'archived'
          }
        ]
      }
    ];
  }, []);

  return (
    <ListScreen
      privilege={'security-deposit-types.update'}
      title={t('bonds-and-deposits.title')}
      createLabel='Create security deposit'
      createLinkProps={{ to: ROUTES.SECURITY_DEPOSIT_TYPES.CREATE }}
    >
      <Card title='Deposits types'>
        <TabbedTable tabs={tabs} />
      </Card>
    </ListScreen>
  );
}
