import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { Field } from '@rexlabs/form';
import { InactiveAgencyFeeTemplatesForRecord } from 'view/components/inputs/selects/v4-selects/inactive-agency-fee-templates-for-record';

export const inactiveOwnershipFeesBlock: BlockConfig = {
  id: 'inactive-ownership-fees',
  title: 'Inactive ownership fees',
  validate: {
    definitions: {
      inactive_ownership_fees: {
        rules: 'required'
      }
    }
  },
  Edit: ({ blockProps }) => (
    <Grid columns={1}>
      <Field<typeof InactiveAgencyFeeTemplatesForRecord>
        id='inactive_ownership_fees'
        name='inactive_ownership_fees'
        label='Ownership Fees'
        Input={InactiveAgencyFeeTemplatesForRecord}
        inputProps={{
          entityFeeType: 'ownership',
          recordId: blockProps.ownershipId,
          multi: true
        }}
      />
    </Grid>
  )
};
