import { formatCurrency } from 'utils/formatters';
import { formatDate, formatDateTime } from 'utils/dates/format';
import { ReportingFieldType } from '../types/CustomReport';

export function mapFieldTypeToFormatter(
  fieldType: ReportingFieldType,
  type?: string | string[] | undefined
) {
  const types = (Array.isArray(type) ? type : [type]).filter(Boolean);
  switch (fieldType) {
    // TODO: For these date formatters, this is a bit iffy but works for now, we should consider refactoring if it gets more complex
    // The issue here is we need to format dates, but not if they are not actually date strings, as in the case of a count aggregate
    case 'date-time':
      return (params) => {
        return params.value &&
          (params.column.aggFunc !== 'count' || params.data.group !== true)
          ? formatDateTime(params.value)
          : params.value;
      };
    case 'date':
      return (params) => {
        return params.value &&
          (params.column.aggFunc !== 'count' || params.data.group !== true)
          ? formatDate(params.value)
          : params.value;
      };
    case 'enum':
      return (params) => params.value?.label;
    case 'int':
      return (params) => {
        if (types.includes('currency')) {
          return formatCurrency(params.value);
        }
        return params.value;
      };
    default:
      return (params) => params.value;
  }
}
