import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';

const validate = {
  definitions: {
    label: { rules: 'required' }
  }
};

export const createCustomTaskTemplateBlock: BlockConfig = {
  id: 'custom-task-template',
  title: 'Custom task type',
  validate,
  Edit: () => {
    return (
      <Grid columns={1}>
        <Field name='label' label='Task name' Input={TextInput} />
      </Grid>
    );
  }
};
