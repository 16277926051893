import { BlockConfig } from 'view/components/record-screen/types';
import React, { useEffect, useState } from 'react';
import { Tenancy } from 'data/models/entities/tenancies';
import { PaymentHistoryRecordTable } from 'src/modules/payment-history/components/payment-history-record-table';
import { ActionButtons } from 'view/components/@luna/action-buttons';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { InfoBanner } from 'view/components/@luna/notifications/banner';
import { PropertyTenancySelect } from 'view/components/inputs/selects/v4-selects/property-tenancy-select';
import { PropertyTenancy } from 'src/modules/property-tenancies/types/property-tenancy-types';
import { normalisePropertyTenancyForTenancy } from 'utils/normaliser/v4-select-normalisers/property-tenancy';
import { replace, useWhereabouts } from '@rexlabs/whereabouts';
import { useGetPaymentHistoryDownloadAction } from 'src/modules/tenancies/actions/use-get-payment-history-download-action';
import { useTranslation } from 'react-i18next';

export const tenantPaymentHistoryBlock: BlockConfig<Tenancy> = {
  id: 'payment-history',
  title: 'Payment history',

  View: ({ data, setValues }) => {
    const { t } = useTranslation();
    const { isFeatureDisabled } = useFeatureFlags();
    const whereabouts = useWhereabouts();

    const [
      propertyTenancy,
      setPropertyTenancy
    ] = useState<PropertyTenancy | null>(
      data?.property_tenancies?.data.find((pt) => pt.status.id === 'active') ||
        null
    );

    useEffect(() => {
      setValues!({
        selectedPropertyTenancy: propertyTenancy
      });
    }, [propertyTenancy]);

    if (isFeatureDisabled(FLAGS.PAYMENT_HISTORY)) {
      return null;
    }

    return (
      <>
        {data?.property_tenancies?.data.length ? (
          <PropertyTenancySelect
            value={propertyTenancy}
            normaliser={normalisePropertyTenancyForTenancy}
            getItems={() => data!.property_tenancies!.data}
            onChange={(e) => {
              setPropertyTenancy(e.target.value);
              replace({
                config: {
                  ...whereabouts,
                  hash: undefined,
                  hashQuery: {}
                }
              });
            }}
          />
        ) : null}

        {propertyTenancy ? (
          <PaymentHistoryRecordTable
            key={propertyTenancy.property_id}
            propertyId={propertyTenancy?.property_id}
            object={data!}
          />
        ) : (
          <InfoBanner
            description={
              (data?.property_tenancies?.data.length
                ? t(
                    'tenancies.tenant-payment-history.no-property-tenancy-selected.select-a-property-tenancy.message'
                  )
                : t(
                    'tenancies.tenant-payment-history.no-property-tenancy-selected.no-property-tenancies.message'
                  )) as string
            }
          />
        )}
      </>
    );
  },
  Actions: ({ data, values }) => {
    const getPaymentHistoryDownloadAction = useGetPaymentHistoryDownloadAction();
    return (
      <>
        {values?.selectedPropertyTenancy ? (
          <ActionButtons
            actions={[
              getPaymentHistoryDownloadAction({
                tenancy: data,
                propertyTenancy: values.selectedPropertyTenancy
              })
            ]}
          />
        ) : null}
      </>
    );
  }
};
