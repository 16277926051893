import React, { useMemo } from 'react';
import { TabbedTable } from 'view/components/table';
import {
  getQueryString,
  ListTable
} from 'view/components/table/list-table-react-query';
import { Tab } from 'view/components/table/tabbed';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { StylesProvider, useToken } from '@rexlabs/styling';
import { useGetBankStatementTransactionFilter } from '../hooks/use-get-bank-statement-transaction-filter';
import {
  bankStatementTransactionIncludes,
  bankStatementTransactionsQueryKey,
  fetchBankStatementTransactions
} from '../hooks/use-bank-statement-transactions-query';
import { useBankStatementTransactionColumns } from '../utils/use-bank-statement-transaction-columns';
import {
  tableHiddenFilters,
  tableSuggestedFilters
} from '../utils/bank-statement-filters';
import { AllActiveTabEmptyState } from './empty-states/all-active-tab-empty-state';
import { NotMatchedTabEmptyState } from './empty-states/not-matched-tab-empty-state';
import { MatchedTabEmptyState } from './empty-states/matched-tab-empty-state';
import { SuspendedTabEmptyState } from './empty-states/suspended-tab-empty-state';
import { RemovedTabEmptyState } from './empty-states/removed-tab-empty-state';

export function BatchReceiptingListTableView() {
  const token = useToken();

  const { getSort, getFilters } = useTableFilters(
    'bank-statement-transactions'
  );

  const getBankStatementTransactionFilter = useGetBankStatementTransactionFilter();

  const queryFn = React.useCallback(async (query) => {
    const queryString = getQueryString({
      ...query,
      include: bankStatementTransactionIncludes
    });

    const response = await fetchBankStatementTransactions(queryString);

    return response;
  }, []);

  const bankStatementTransactionColumns = useBankStatementTransactionColumns();

  const tabs = useMemo<Tab[]>(() => {
    const commonProps = {
      id: 'batch-receipting-list',
      Table: ListTable,
      primaryQueryKey: bankStatementTransactionsQueryKey,
      queryFn,
      queryOptions: {
        cacheTime: 0
      },
      columns: bankStatementTransactionColumns,
      getSort,
      getFilters,
      hideBulkActions: true,
      forcedSortBy: [{ id: 'paid_date', label: 'Paid date', desc: true }],
      suggestedFilters: tableSuggestedFilters,
      hiddenFilters: tableHiddenFilters
    };

    return [
      {
        ...commonProps,
        name: 'all_active',
        label: 'Unreceipted',
        Empty: AllActiveTabEmptyState,
        forcedGlobalFilter: getBankStatementTransactionFilter('ACTIVE')
      },
      {
        ...commonProps,
        name: 'not_match',
        label: 'Not matched',
        Empty: NotMatchedTabEmptyState,
        forcedGlobalFilter: getBankStatementTransactionFilter('NOT_MATCHED')
      },
      {
        ...commonProps,
        name: 'matched',
        label: 'Matched',
        Empty: MatchedTabEmptyState,
        forcedGlobalFilter: getBankStatementTransactionFilter('MATCHED')
      },
      {
        ...commonProps,
        name: 'suspended',
        label: 'Suspended',
        Empty: SuspendedTabEmptyState,
        forcedGlobalFilter: getBankStatementTransactionFilter('SUSPENDED')
      },
      {
        ...commonProps,
        name: 'removed',
        label: 'Removed',
        Empty: RemovedTabEmptyState,
        forcedGlobalFilter: getBankStatementTransactionFilter('REMOVED')
      }
    ];
  }, [getFilters, getSort, getBankStatementTransactionFilter, queryFn]);

  return (
    <StylesProvider
      tokens={{
        'table.cell.padding.top': token('spacing.xs'),
        'table.cell.padding.bottom': token('spacing.xs')
      }}
    >
      <TabbedTable tabs={tabs}></TabbedTable>
    </StylesProvider>
  );
}
