import React from 'react';
import { DialogProps, useErrorDialog } from '@rexlabs/dialog';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useRecordUpdatedToast } from 'src/modules/common/toasts/hooks/use-record-updated-toast';
import { useMutation, useQueryClient } from 'react-query';
import { api } from 'utils/api/api-client';
import { CUSTOM_VALIDATIONS_QUERY_KEY } from 'src/modules/custom-validations/hooks/use-custom-validations';
import { CustomValidation } from '../types/CustomValidation';
import { customValidationDetailsBlock } from '../blocks/custom-validation-details-block';

export interface EditCustomValidationDialogProps extends DialogProps {
  onClose?: () => void;
  customValidation: CustomValidation;
}

const content = [
  {
    id: 'edit-custom-validation',
    label: 'Edit Custom Validation',
    blocks: [customValidationDetailsBlock]
  }
];

export function EditCustomValidationDialog({
  onClose,
  customValidation
}: EditCustomValidationDialogProps) {
  const queryClient = useQueryClient();
  const { open: openErrorDialog } = useErrorDialog();
  const addToast = useRecordUpdatedToast('custom_validation');

  const updateCustomValidation = useMutation(
    (updatedValidation: CustomValidation) =>
      api.put<CustomValidation>(
        `/custom-validations/${updatedValidation.id}`,
        updatedValidation
      ),
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(CUSTOM_VALIDATIONS_QUERY_KEY);
        const updatedValidation = response.data;
        addToast(updatedValidation);
        onClose?.();
      },
      onError: (error) => {
        openErrorDialog(error);
      }
    }
  );

  const handleSubmit: RecordSubmitHandler<CustomValidation> = async ({
    values
  }) => {
    await updateCustomValidation.mutateAsync(values);
  };

  return (
    <RecordDialog
      title={`Edit Custom Validation: ${customValidation.name}`}
      content={content}
      onClose={onClose}
      submitLabel='Update Custom Validation'
      handleSubmit={handleSubmit}
      initialValues={customValidation}
      data={customValidation}
    />
  );
}
