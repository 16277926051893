import React from 'react';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';
import { DialogProps } from '@rexlabs/dialog';
import dayjs from 'dayjs';
import { ChecklistItemWithId } from '../../common/blocks/create-checklists-block';
import { useChecklistItemMutation } from '../hooks/use-checklist-item-mutation';
import { ActionTriggerButtonGroup } from '../components/action-trigger-button-group';
import { actionTriggerDialogBlock } from '../blocks/action-trigger-dialog-block';

const content: DialogContentConfig = [
  {
    id: 'action-trigger',
    label: 'Action trigger',
    blocks: [actionTriggerDialogBlock]
  }
];

interface ActionTriggerDialogProps extends DialogProps {
  checklistItem: ChecklistItemWithId;
  onTrigger: () => void;
  onDiscard: () => void;
  onCancel: () => void;
}

export const ActionTriggerDialog = ({
  checklistItem,
  onCancel,
  onDiscard,
  onTrigger,
  onClose
}: ActionTriggerDialogProps) => {
  const checklistItemMutation = useChecklistItemMutation();
  async function handleSubmit({ values }) {
    const result = await checklistItemMutation.mutateAsync({
      skip_triggered_actions: false, // in this case, we want to trigger the action when we complete the task
      item: checklistItem,
      outcome_value_id: values.outcome_value_id
    });
    return result;
  }

  const scheduledMessage = (triggerAt) => {
    const triggerDate = dayjs(triggerAt).tz();
    const time = triggerDate.format('h:mma');
    const date = triggerDate.format('DD MMM YYYY');

    return `This subtask has an automated action that is scheduled to send at ${time} on ${date}. If you complete the task manually you must either discard the action or trigger it now.`;
  };

  const automatedMessage = () =>
    `This subtask has an automated action. Would you like to discard the action or trigger it now?`;

  const scheduledTitle = 'Trigger scheduled action';
  const automatedTitle = 'Trigger automated action';

  const isDueDateTrigger = checklistItem.trigger_type?.id === 'on_due_date';

  async function handleCancel() {
    await onCancel?.();
    await onClose?.();
  }

  async function handleTrigger() {
    await onTrigger();
    await onClose?.();
  }

  async function handleDiscard() {
    await onDiscard();
    await onClose?.();
  }

  return (
    <RecordDialog
      title={isDueDateTrigger ? scheduledTitle : automatedTitle}
      content={content}
      data={{
        message: isDueDateTrigger
          ? scheduledMessage(checklistItem.triggered_at)
          : automatedMessage(),
        checklistItem
      }}
      onClose={onClose}
      handleSubmit={handleSubmit}
      ButtonGroup={ActionTriggerButtonGroup}
      buttonGroupProps={{
        handleTrigger,
        handleDiscard,
        handleCancel,
        triggerLabel,
        discardLabel,
        isSubmitting: checklistItemMutation.isLoading
      }}
      submitLabel='Complete subtask with outcome'
    />
  );
};

const discardLabel = `Complete subtask and discard action`;
const triggerLabel = `Complete subtask and trigger action`;
