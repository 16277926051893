import { RecordObject } from 'data/models/types';
import { getRecordLabel } from 'utils/records/get-record-label';
import { formatDate } from 'utils/dates/format';
import { getCreditNoteTitle } from 'src/modules/credit-notes/utils/get-credit-note-title';
import i18n from 'src/modules/i18n/i18n';

/**
 * Get the title for the record, typically used within the title block component and is more descriptive than the label
 * if no title is defined, it will fallback to the label
 */
export function getRecordTitle(recordObject: RecordObject): string {
  switch (recordObject.type) {
    case 'pending_ownership_disbursement':
    case 'pending_contact_disbursement':
      return i18n.t('disbursements.title-block.title', {
        title: recordObject.object.object?.display_name
      });

    case 'bank_deposit':
      return `Bank deposit ${recordObject.object.audit_number}`;

    case 'bank_withdrawal':
      return `Bank withdrawal ${recordObject.object.audit_number}`;

    case 'credit_note':
      return getCreditNoteTitle(recordObject.object);

    case 'recurring_invoice':
      return 'Recurring invoice';

    case 'charge_entry':
      return 'Charge entry';

    case 'recurring_charge_entry':
      return 'Recurring charge entry';

    case 'trust_journal_entry':
      return `${recordObject.object.type.label} ${recordObject.object.audit_number}`;

    case 'invoice':
      return recordObject.object.description;

    case 'reconciliation': {
      const reconciliationType = recordObject.object.type;

      const format =
        reconciliationType.id === 'end_of_month' ? 'MMMM YYYY' : 'D MMM YYYY';

      return `${reconciliationType.label} reconciliation${
        recordObject.object.statement_date
          ? `: ${formatDate(recordObject.object.statement_date, format)}`
          : ''
      }`;
    }
    case 'security_deposit':
      return recordObject.object.security_deposit_type?.name ?? '';
    case 'task_inspection':
      return recordObject?.object?.summary ?? '';

    case 'property_tenancy':
    case 'property_ownership':
      return recordObject.object.display_name;

    case 'custom_report':
      return recordObject.object.label;

    default:
      return getRecordLabel(recordObject);
  }
}
