import { InputProps } from '@rexlabs/form';
import { Quri } from '@rexlabs/quri';
import {
  NormalisedItem,
  RecordOption,
  Select,
  SelectProps
} from '@rexlabs/select';
import * as React from 'react';
import { getSearchQuery } from 'src/lib/react-query/hooks/use-records-query';
import { MessageTemplate } from 'src/modules/communications/message-templates/types/MessageTemplate';
import { api } from 'utils/api/api-client';
import { useItems } from 'view/hooks/use-items';
import { ChannelTypeId } from '../../types/channel';
import { CommunicationContextType } from '../../types/communication-context-type';

export type MessageTemplateSelectProps = SelectProps<MessageTemplate> &
  Partial<Pick<InputProps, 'actions'>> & {
    contextType?: CommunicationContextType;
    channelType?: ChannelTypeId;
    recipientGroups?: string[];
    showChannelTypes?: boolean;
  };

const PER_PAGE_LIMIT = 30;

function normaliserWithDescription(item: MessageTemplate): NormalisedItem {
  return {
    id: item.id,
    label: item.name,
    description: (item.channels || [])
      .map((channel) => channel.channel_type.label)
      .sort()
      .join(' • ')
  };
}

function defaultNormaliser(item: MessageTemplate): NormalisedItem {
  return {
    id: item.id,
    label: item.name
  };
}

/**
 * HACK: This filter is a hack to get around the fact that the API does not support
 * filtering by channel type or default recipient group type.
 */
function filterReturnData({
  channelType,
  recipientGroups,
  data
}: Pick<MessageTemplateSelectProps, 'recipientGroups' | 'channelType'> & {
  data: MessageTemplate[];
}) {
  let filteredData = data;

  if (channelType) {
    filteredData = filteredData.filter((messageTemplate) =>
      messageTemplate.channels?.some(
        (channel) => channel.channel_type.id === channelType
      )
    );
  }

  if (recipientGroups?.length) {
    filteredData = filteredData.filter((messageTemplate) =>
      recipientGroups?.includes(messageTemplate.default_recipient_group)
    );
  }

  return filteredData;
}

export const MessageTemplateSelect: React.FC<MessageTemplateSelectProps> = ({
  contextType,
  channelType,
  recipientGroups,
  showChannelTypes = false,
  ...props
}) => {
  /**
   * We're using one request for both getItems and getSuggestedItems as there is little variation
   * between the two - the only difference is that getSuggestedItems does not have a search term.
   * Previously, we had to maintain to separate functions for this, but now we can just use one.
   */
  const getItemsRequest = React.useCallback(
    async (additionalFilters: Quri[] = []) => {
      const filter: Quri[] = [
        { field: 'status_id', op: 'neq', value: 'archived' },
        ...((contextType
          ? [
              {
                field: 'context_type_id',
                op: 'eq',
                value: contextType
              }
            ]
          : []) as Quri[]),
        ...additionalFilters
      ];

      const searchQuery = getSearchQuery({
        filter,
        includes: [
          'channels',
          'send_from_author',
          'inline_attachments',
          'document_types',
          'compliance_types'
        ],
        perPage: PER_PAGE_LIMIT
      });

      const { data } = await api.get<MessageTemplate[]>(
        `/communication/message-templates${
          searchQuery ? `?${searchQuery}` : ''
        }}`
      );

      return filterReturnData({
        channelType,
        recipientGroups,
        data
      });
    },
    [channelType, contextType, recipientGroups]
  );

  const getItems = React.useCallback(
    async (term?: string) => {
      const additionalFilters: Quri[] = [];

      if (term) {
        additionalFilters.push({
          field: 'name',
          op: 'match',
          value: `%${term}%`
        });
      }

      return getItemsRequest(additionalFilters);
    },
    [getItemsRequest]
  );
  const getSuggestedItems = React.useCallback(async () => getItemsRequest(), [
    getItemsRequest
  ]);

  const { getSelectProps } = useItems({
    getItems,
    getSuggestedItems
  });

  const normaliser = React.useMemo(
    () => (showChannelTypes ? normaliserWithDescription : defaultNormaliser),
    [showChannelTypes]
  );

  return (
    <Select
      {...props}
      {...getSelectProps()}
      Option={RecordOption}
      normaliser={normaliser}
    />
  );
};
