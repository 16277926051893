import React from 'react';
import { PropertyTenancy } from 'src/modules/property-tenancies/types/property-tenancy-types';
import { SecondaryList } from '../components/secondary-list';
import { EmptyPropertyTenancy } from '../empty/empty-property-tenancy';
import { useGetTenancyObjects } from '../utils';
import { TenancyEntries } from './tenancy-entries';

type PropertyTenancySegment = PropertyTenancy[] | 'empty';

export interface PropertyTenancySectionProps {
  activePropertyTenancies: PropertyTenancySegment;
  incomingPropertyTenancies: PropertyTenancySegment;
  outgoingPropertyTenancies: PropertyTenancySegment;
  draftPropertyTenancies: PropertyTenancySegment;
  maxListLength: number;
}

export function PropertyTenancySection({
  activePropertyTenancies: active,
  incomingPropertyTenancies: incoming,
  outgoingPropertyTenancies: outgoing,
  draftPropertyTenancies: draft,
  maxListLength
}: PropertyTenancySectionProps) {
  const getTenancyObjects = useGetTenancyObjects();

  const noActiveTenancy = active === 'empty';
  const noIncomingTenancy = incoming === 'empty';
  const noDraftTenancy = draft === 'empty';
  const noOutgoingTenancy = outgoing === 'empty';

  if (
    noActiveTenancy &&
    noIncomingTenancy &&
    noOutgoingTenancy &&
    noDraftTenancy
  ) {
    return <EmptyPropertyTenancy />;
  }

  return (
    <SecondaryList>
      {!noActiveTenancy &&
        active.map((tenancy) => (
          <TenancyEntries
            {...getTenancyObjects(tenancy)}
            key={tenancy.id}
            headline='Active'
            maxListLength={maxListLength}
            status='active'
          />
        ))}
      {!noIncomingTenancy &&
        incoming.map((tenancy) => (
          <TenancyEntries
            {...getTenancyObjects(tenancy)}
            key={tenancy.id}
            headline='Incoming'
            maxListLength={maxListLength}
            status='incoming'
          />
        ))}
      {!noOutgoingTenancy &&
        outgoing.map((tenancy) => (
          <TenancyEntries
            {...getTenancyObjects(tenancy)}
            key={tenancy.id}
            headline='Outgoing'
            maxListLength={maxListLength}
            status='outgoing'
          />
        ))}
      {!noDraftTenancy &&
        draft.map((tenancy) => (
          <TenancyEntries
            {...getTenancyObjects(tenancy)}
            key={tenancy.id}
            headline='Draft'
            maxListLength={maxListLength}
            status='draft'
          />
        ))}
    </SecondaryList>
  );
}
