import { Generator } from 'data/models/generator';
import { BankStatementTransactionImport } from '../types/bank-statement-transaction-import';

const config = {
  entities: {
    related: {
      file: {
        include: 'file'
      },
      payment_gateway: {
        include: 'payment_gateway'
      }
    }
  }
};

export const bankStatementTransactionImportsModel = new Generator<BankStatementTransactionImport>(
  'financials/bank-statement-transaction-imports',
  config
).createEntityModel();
