import React, { useCallback } from 'react';

import { MaintenanceTask } from 'src/modules/tasks/maintenance/types/MaintenanceTask';
import { BillProcessingListEmptyState } from 'src/modules/bill-processing/components/bill-processing-list-empty-state';

import { BlockConfig } from 'view/components/record-screen/types';
import { ActionButtons } from 'view/components/@luna/action-buttons';
import { BillProcessingListTable } from 'src/modules/bill-processing/components/bill-processing-list-table';
import { useGetCreateDraftInvoiceFromTaskAction } from '../../common/hooks/action-declarations/use-get-create-draft-invoice-from-task-action';

export const draftInvoicesBlock: BlockConfig<MaintenanceTask> = {
  id: 'related-draft-invoices',
  title: 'Draft invoices',
  Actions: ({ data }) => {
    const getCreateDraftInvoiceFromTaskAction = useGetCreateDraftInvoiceFromTaskAction();
    if (!data) return null;

    return (
      <ActionButtons
        actions={[
          getCreateDraftInvoiceFromTaskAction<typeof data>({
            newStatus: 'work_finished',
            task: data
          })
        ]}
      />
    );
  },
  View: ({ data }) => {
    const taskId = data?.id;

    const getCreateDraftInvoiceFromTaskAction = useGetCreateDraftInvoiceFromTaskAction();

    const EmptyListState = useCallback(
      (props) => {
        return (
          <BillProcessingListEmptyState
            actions={[
              ...(data
                ? [
                    getCreateDraftInvoiceFromTaskAction<typeof data>({
                      newStatus: 'work_finished',
                      task: data
                    })
                  ]
                : [])
            ]}
            {...props}
          />
        );
      },
      [data]
    );

    return (
      <BillProcessingListTable
        forcedCommonGlobalFilter={[
          ...(taskId
            ? [
                {
                  field: 'draft_invoice_task',
                  op: '==',
                  value: taskId
                }
              ]
            : [])
        ]}
        initialHiddenColumns={[
          'relates_to_work_order',
          'relates_to_work_order'
        ]}
        EmptyListState={EmptyListState}
      />
    );
  }
};
