import React, { useCallback } from 'react';

import Box from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { Field, HiddenField, ReactForms } from '@rexlabs/form';
import Popout from '@rexlabs/popout';
import { StyleSheet, useStyles, StylesProvider } from '@rexlabs/styling';

import { CommissionInput } from 'src/modules/supplier-commission/components/commission-field';
import { ResetButton } from 'src/modules/common/components/reset-button';

import { Grid } from 'view/components/@luna/form/grid';
import { StandardDialogFooter } from 'view/components/dialogs/components/standard-dialog-footer';
import { CommissionStructureSelect } from 'view/components/inputs/selects/v4-selects/commission-structure-select';
import { CommissionRuleFormValue } from '../types/CommissionRuleFormValue';

const commissionsStructureFieldId = 'commission_template';

const defaultStyles = StyleSheet({
  contentContainer: {
    overflow: 'hidden'
  }
});

const overrideStyles = StyleSheet({
  popout: {
    padding: 0
  },
  content: {
    padding: 0
  }
});

type SharedProps = {
  onSubmit: (values: CommissionRuleFormValue) => any;
  initialValues?: CommissionRuleFormValue;
  onReset?: () => void;
};

type ChangeCommissionRulePopoutProps = SharedProps & {
  children: React.ReactNode;
};

export function ChangeCommissionRulePopout({
  children,
  onSubmit,
  initialValues,
  onReset
}: ChangeCommissionRulePopoutProps) {
  const Content = useCallback(
    ({ toggle }) => (
      <PopoutForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        onClose={toggle}
        onReset={() => {
          onReset?.();
          toggle();
        }}
      />
    ),
    [initialValues, onSubmit]
  );

  return (
    <StylesProvider
      components={{
        Popout: overrideStyles
      }}
    >
      <Popout
        css={{
          padding: 0
        }}
        Content={Content}
        placement='top-start'
      >
        <Box>{children}</Box>
      </Popout>
    </StylesProvider>
  );
}

const popoutValidation = {
  definitions: {
    commission_amount_currency: {
      name: 'amount',
      rules: 'required_if:commission_structure.id,flat_fee'
    },
    commission_amount_percent: {
      name: 'amount',
      rules: 'required_if:commission_structure.id,percentage'
    }
  }
};

type CommissionRuleFormProps = SharedProps & {
  onClose?: () => void;
};

function PopoutForm({
  onClose,
  onSubmit,
  initialValues,
  onReset
}: CommissionRuleFormProps) {
  const s = useStyles(defaultStyles);

  return (
    <ReactForms<CommissionRuleFormValue, CommissionRuleFormValue>
      handleSubmit={onSubmit}
      initialValues={initialValues}
      validate={popoutValidation}
    >
      {({ values, submitForm, setFieldValue }) => {
        const showCommissionAmount =
          values?.commission_template &&
          !('__record_type' in values.commission_template);

        const handleSubmitAndClose = async () => {
          const result = await submitForm();

          if (!result) return;

          onClose?.();
        };

        const manualCommissionStructureHasBeenSet =
          !!initialValues?.commission_template ||
          !!initialValues?.commission_structure;

        return (
          <Box
            width={400}
            display='flex'
            flexDirection='column'
            {...s('contentContainer')}
          >
            <Box display='flex' padding={'2.4rem'}>
              <Grid columns={1}>
                <Field<typeof CommissionStructureSelect>
                  name={commissionsStructureFieldId}
                  id={commissionsStructureFieldId}
                  label={'Commission structure'}
                  Input={CommissionStructureSelect}
                  onChange={(e: any) => {
                    if (
                      ['flat_fee', 'percentage'].includes(e.target.value?.id)
                    ) {
                      setFieldValue('commission_amount_currency', null);
                      setFieldValue('commission_amount_percent', null);
                      return setFieldValue(
                        'commission_structure',
                        e.target.value
                      );
                    }

                    setFieldValue('commission_structure', null);
                    setFieldValue('commission_amount_currency', null);
                    setFieldValue('commission_amount_percent', null);
                  }}
                  inputProps={{
                    hasStructureTypes: true,
                    deselectable: false
                  }}
                />

                {showCommissionAmount && (
                  <CommissionInput
                    label={'Commission amount'}
                    structureType={values.commission_structure?.id}
                  />
                )}

                <HiddenField name='commission_structure' />
                <HiddenField name='commission_amount_currency' />
                <HiddenField name='commission_amount_percent' />

                {manualCommissionStructureHasBeenSet && (
                  <ResetButton onClick={onReset}>
                    Reset to default commission
                  </ResetButton>
                )}
              </Grid>
            </Box>

            <StandardDialogFooter>
              <Box display='flex' flexDirection='row' padding={24}>
                <GhostButton onClick={onClose}>Cancel</GhostButton>
                <PrimaryButton onClick={handleSubmitAndClose}>
                  Apply commission
                </PrimaryButton>
              </Box>
            </StandardDialogFooter>
          </Box>
        );
      }}
    </ReactForms>
  );
}
