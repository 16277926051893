import { GhostButton, OutlineButton, PrimaryButton } from '@rexlabs/button';
import * as React from 'react';
import { StandardDialogFooter } from 'view/components/dialogs/components/standard-dialog-footer';
import { ButtonGroupProps } from 'view/components/record-screen/dialog/types';

export interface SMSCreateDialogButtonGroupProps extends ButtonGroupProps {
  handleTrigger: (...args: any[]) => Promise<any> | any;
  handleDiscard: (...args: any[]) => Promise<any> | any;
  handleCancel: (...args: any[]) => Promise<any> | any;
  triggerLabel: string;
  discardLabel: string;
  isSubmitting: boolean;
}

export function ActionTriggerButtonGroup({
  handleTrigger,
  handleDiscard,
  handleCancel,
  triggerLabel,
  discardLabel,
  isSubmitting
}: SMSCreateDialogButtonGroupProps) {
  return (
    <StandardDialogFooter>
      <GhostButton onClick={handleCancel}>Cancel</GhostButton>
      <OutlineButton onClick={handleDiscard}>{discardLabel}</OutlineButton>
      <PrimaryButton
        type='submit'
        isLoading={isSubmitting}
        onClick={handleTrigger}
      >
        {triggerLabel}
      </PrimaryButton>
    </StandardDialogFooter>
  );
}
