import { ContentConfig } from 'view/components/record-screen/types';
import { FLAGS } from 'utils/feature-flags';
import { noteTableBlock } from 'src/modules/note/blocks/note-table-block';
import { documentsBlock } from 'src/modules/tasks/common/blocks/documents';
import { linkedTasksBlock } from 'src/modules/tasks/common/blocks/linked-tasks-block';
import { taskDateBlock } from '../../common/blocks/task-date-block';
import { taskPriorityBlock } from '../../common/blocks/task-priority-block';
import { checklistsBlock } from '../../common/blocks/create-checklists-block';
import { customTaskDetailsBlock } from '../blocks/custom-task-details-block';
import { customTaskCustomFieldsBlock } from '../blocks/custom-task-custom-fields-block';

export const content: ContentConfig = [
  {
    label: 'General',
    items: [
      {
        id: 'overview',
        label: 'Overview',
        blocks: [
          customTaskDetailsBlock,
          customTaskCustomFieldsBlock,
          taskPriorityBlock,
          taskDateBlock
        ]
      },
      {
        id: 'notes',
        label: 'Notes',
        blocks: [noteTableBlock]
      },
      {
        id: 'documents',
        label: 'Documents',
        blocks: [documentsBlock]
      },
      {
        id: 'checklist',
        label: 'Checklist',
        blocks: [checklistsBlock],
        flag: FLAGS.CHECKLIST_TEMPLATES
      }
    ]
  },
  {
    label: 'Manage',
    items: [
      {
        id: 'linked-tasks',
        label: 'Linked tasks',
        blocks: [linkedTasksBlock],
        flag: FLAGS.LINKED_TASKS
      }
    ]
  }
];
