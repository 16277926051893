import React from 'react';

import { NumberInput, NumberInputProps } from '@rexlabs/text-input';

export interface PercentageInputProps
  extends Omit<NumberInputProps, 'onChange' | 'onBlur'> {
  onChange: (e: { target: { value: number | null } }) => void;
  onBlur: (e: { target: { value: number | null } }) => void;
}

// Expects a value of a float * 10000, this is to mitigate issues with floating point precision.
// e.g. (0.7 * 10000) / 100 = 0.7.
// It also avoids the issue
export const denormalise = (amount = 0): number => amount / 1;

// Expects a value of a float, this is to mitigate issues with floating point precision.
// e.g. (0.7 * 10000) * 100 = 0.7
export const normalise = (amount = 0): number | null =>
  !amount ? null : amount * 1;

export function PercentageInput({
  value,
  onChange,
  onBlur,
  ...props
}: PercentageInputProps) {
  return (
    <NumberInput
      data-testid='percentage-input'
      prefix={props.prefix ?? '%'} // TODO: think about localisation, need to align with BE tho
      placeholder='0'
      value={!value && value !== 0 ? undefined : denormalise(value)}
      onChange={(e) => {
        onChange?.({
          target: {
            value: normalise(e.target?.valueAsNumber)
          }
        });
      }}
      onBlur={(e) => {
        onBlur?.({
          target: {
            value: normalise(e.target?.valueAsNumber)
          }
        });
      }}
      style={{ textAlign: 'right' }}
      min='0'
      max='100'
      {...props}
    />
  );
}
