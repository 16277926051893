import { usePrivileges } from 'src/modules/authorization/roles/hooks/use-privileges';
import { useModelActions } from '@rexlabs/model-generator';
import {
  BankAccount,
  bankAccountModel
} from 'data/models/entities/financials/bank-accounts';
import { ConfirmationDialog, useDialog } from '@rexlabs/dialog';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { getRecordLabel } from 'utils/records/get-record-label';
import React from 'react';
import { Box } from '@rexlabs/box';

export function useMakeBankAccountDefaultAction() {
  const { hasPrivilege } = usePrivileges();
  const { updateItem } = useModelActions(bankAccountModel);
  const { open } = useDialog(ConfirmationDialog);

  return (bankAccount: BankAccount): SingleActionDeclaration => {
    const label = getRecordLabel({ type: 'bank_account', object: bankAccount });

    return {
      label: 'Make default',
      hidden: !hasPrivilege('bank-accounts.update'),
      id: 'make default',
      group: 'make_default',
      handleAction: async () => {
        open({
          title: `Change the default bank account`,
          message: (
            <Box>
              Make <b>{label}</b> the default bank account
            </Box>
          ),
          onConfirm: async () => {
            await updateItem({
              id: bankAccount.id,
              data: { is_default: true }
            });
          }
        });
      }
    };
  };
}
