import React, {
  RefObject,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { margin, padding, StyleSheet, text, useStyles } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { useWhereabouts } from '@rexlabs/whereabouts';

import { Forms } from '@rexlabs/form';
import { WorkInProgress } from 'view/components/work-in-progress';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { usePrivileges } from 'src/modules/authorization/roles/hooks/use-privileges';
import { ContentConfig, TabConfig } from '../types';
import { RecordNavigationItem } from './item';

const defaultStyles = StyleSheet({
  menu: {
    position: 'relative',

    ...padding.styles({
      target: 'recordNavigation',
      left: 'xl',
      bottom: () => 80
    })
  },

  indicator: {
    position: 'absolute',
    height: '2.4rem',
    width: '0.4rem',
    left: 0,
    transition: 'transform 300ms ease-out',

    backgroundColor: ({ token }) => token('color.primary.active.default')
  },

  group: {
    ':not(:last-child)': {
      ...margin.styles({
        bottom: 'xxxl'
      })
    }
  },

  groupLabel: {
    textTransform: 'uppercase',

    ...text.styles({
      target: 'recordNavigation.heading',
      fallback: 'small.semibold',
      color: ({ token }) => token('color.textStyle.body.subtext')
    }),
    ...padding.styles({
      target: 'recordNavigation.heading',
      bottom: 's'
    })
  }
});

function getIndicatorPosition(
  currentId: string,
  menuRef: RefObject<HTMLElement>,
  indicatorRef: RefObject<HTMLElement>
) {
  const itemElement = menuRef?.current?.querySelector<HTMLElement>(
    `[role="menuitem"][data-id="${currentId}"]`
  );

  const menuTop = menuRef?.current?.getBoundingClientRect()?.top ?? 0;
  const itemTop = itemElement?.getBoundingClientRect()?.top ?? 0;

  const itemHeight = itemElement?.offsetHeight ?? 0;
  const indicatorHeight = indicatorRef?.current?.offsetHeight ?? 0;

  return { top: itemTop - menuTop + itemHeight / 2 - indicatorHeight / 2 };
}

interface RecordNavigationProps {
  content: ContentConfig;
  data?: any;
  isLoading?: boolean;
  forms: Forms;
}

export const RecordNavigation = ({
  content,
  data,
  forms
}: RecordNavigationProps) => {
  const s = useStyles(defaultStyles);
  const whereabouts = useWhereabouts();
  const { hasFeature } = useFeatureFlags();
  const { hasPrivilege } = usePrivileges();

  const indicatorRef = useRef<HTMLSpanElement>(null);

  // Flatten menu items from config
  const items = useMemo(() => {
    return content.reduce<TabConfig[]>((all, group) => {
      const flagPasses = !group.flag || hasFeature(group.flag);
      const privilegePasses = !group.privilege || hasPrivilege(group.privilege);
      if (flagPasses && privilegePasses) {
        return all.concat(group.items);
      }
      return all;
    }, []);
  }, [content, hasFeature]);

  // Get current menu item from URL
  const current =
    items.find((item) => item.id === whereabouts.query?.tab) || items[0];

  // Calculate indicator position, also re-calculate whenever the currently
  // active item changes or the width of the menu
  const menuRef = useRef<HTMLElement>(null);
  const initialState = getIndicatorPosition(current.id, menuRef, indicatorRef);
  const [indicator, setIndicator] = useState(initialState);
  useLayoutEffect(() => {
    setIndicator(getIndicatorPosition(current.id, menuRef, indicatorRef));
  }, [current.id]);

  useEffect(() => {
    const element = menuRef?.current;
    if (!element) {
      return;
    }
    const resizeObserver = new ResizeObserver((entries) => {
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) return;

        const newState = getIndicatorPosition(
          current.id,
          menuRef,
          indicatorRef
        );
        setIndicator((state) =>
          newState.top !== state.top ? newState : state
        );
      });
    });

    resizeObserver.observe(element);
    return () => resizeObserver.unobserve(element);
  }, [current.id]);

  // We want to hide the record navigation if there is only one or less
  // menu items available
  if (items?.length <= 1) {
    return null;
  }

  return (
    <Box ref={menuRef} role='menu' {...s('menu')}>
      <span
        {...s.with('indicator')({
          // Get the top position from the actual items themselves
          // then tweak the position based on the height of the item and the indicator
          transform: `translate3d(0, ${indicator.top}px, 0)`
        })}
        ref={indicatorRef}
      />
      {content.map((group) => {
        if (group?.visible && !group.visible({ data })) {
          return null;
        }

        const groupHasVisibleItem = group.items.some((item) => {
          return (
            !item.workInProgress &&
            (!item.flag || hasFeature(item.flag)) &&
            (!item.privilege || hasPrivilege(item.privilege))
          );
        });

        // If none of the child items are visible, don't show the group
        if (!groupHasVisibleItem) {
          return null;
        }

        const Wrapper = group.workInProgress ? WorkInProgress : Box;
        return (
          <Wrapper key={group?.label} {...s('group')} flexDirection='column'>
            <Box {...s('groupLabel')}>{group?.label}</Box>
            <Box flexDirection='column' id='navigation-tabs'>
              {group.items
                ?.filter((item) => {
                  const flagPasses = !item.flag || hasFeature(item.flag);
                  const privilegePasses =
                    !item.privilege || hasPrivilege(item.privilege);
                  return flagPasses && privilegePasses;
                })
                .map((item) => {
                  if (item.workInProgress) {
                    return (
                      <WorkInProgress key={item.id} flex={1}>
                        <RecordNavigationItem
                          data={data}
                          item={item}
                          current={current}
                          forms={forms}
                        />
                      </WorkInProgress>
                    );
                  }
                  return (
                    <RecordNavigationItem
                      key={item.id}
                      data={data}
                      item={item}
                      current={current}
                      forms={forms}
                    />
                  );
                })}
            </Box>
          </Wrapper>
        );
      })}
    </Box>
  );
};
