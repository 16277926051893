import React, { useMemo } from 'react';
import { useEntityQuery } from '@rexlabs/model-generator';
import { RecordScreen } from 'view/components/record-screen';
import { Portfolio } from 'src/modules/portfolios/models/portfolios-model';
import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { useContent } from '../data/details-content';
import { PortfolioTitleBlock } from '../components/portfolio-title-block';
import { useMapPortfolioToPortfolioFormData } from '../mappers/use-map-portfolio-to-portfolio-form-data';
import { usePortfolioSubmitHandler } from '../hooks/use-portfolio-submit-handler';
import { getPortfolioDetailsQuery } from '../queries/portfolio-details-query';

interface PortfolioDetailsScreenProps {
  portfolioId: string;
}

export function PortfolioDetailsScreen(props: PortfolioDetailsScreenProps) {
  const breadcrumbs = [{ type: BREADCRUMBS.PORTFOLIO }];
  const portfolioId = props?.portfolioId;
  const mapPortfolioToPortfolioFormData = useMapPortfolioToPortfolioFormData();

  const query = useMemo(() => getPortfolioDetailsQuery(portfolioId), [
    portfolioId
  ]);

  const { data, status, actions } = useEntityQuery(query);
  const content = useContent();

  const handleSubmit = usePortfolioSubmitHandler({ actions, data });

  const initialData = useMemo(
    () => (data ? mapPortfolioToPortfolioFormData(data) : {}),
    [data]
  );

  return (
    <RecordScreen
      privilege={'portfolios.update'}
      isLoading={status === 'loading'}
      data={initialData}
      handleSubmit={handleSubmit}
      content={content}
      titleBlock={<PortfolioTitleBlock portfolio={data as Portfolio} />}
      breadcrumbs={breadcrumbs}
    />
  );
}
