import { ContentConfig } from 'view/components/record-screen/types';
import { roleDetailsBlock } from '../blocks/role-details-block';
import { rolePrivilegesBlock } from '../blocks/role-privileges-block';

export const content: ContentConfig = [
  {
    label: 'General',
    items: [
      {
        id: 'overview',
        label: 'Overview',
        blocks: [roleDetailsBlock, rolePrivilegesBlock]
      }
    ]
  }
];
