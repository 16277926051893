import React, { useMemo } from 'react';

import { CommissionCalculatorPreview } from 'src/modules/supplier-commission/types/CommissionCalculatorPreview';

import { CurrencySummary } from 'view/components/currency-summary';

import { CommissionRuleFormValue } from '../types/CommissionRuleFormValue';
import { ChangeCommissionRulePopout } from './change-structure-rule-popout';
import { ArrowDownLabelButton } from './arrow-down-label-button';

interface CommissionCalculationPreviewProps {
  isLoading: boolean;
  isTaxIncluded: boolean;
  commissionPreview?: CommissionCalculatorPreview;
  onCommissionRuleChange: (values: CommissionRuleFormValue) => void;
  commissionRuleLabel?: string;
  currentCommissionRule?: CommissionRuleFormValue;
  onResetCommissionRule?: () => void;
}

export function CommissionCalculationPreview({
  isTaxIncluded,
  commissionPreview,
  isLoading,
  onCommissionRuleChange,
  commissionRuleLabel,
  currentCommissionRule,
  onResetCommissionRule
}: CommissionCalculationPreviewProps) {
  const label = useMemo(
    () =>
      `Commission ${
        isTaxIncluded ? 'incl' : 'excl'
      } tax (${commissionRuleLabel})`,
    [isTaxIncluded, commissionRuleLabel]
  );

  return (
    <>
      <CurrencySummary
        rows={[
          {
            label: (
              <ChangeCommissionRulePopout
                onSubmit={onCommissionRuleChange}
                initialValues={currentCommissionRule}
                onReset={onResetCommissionRule}
              >
                <ArrowDownLabelButton label={label} />
              </ChangeCommissionRulePopout>
            ),
            valueTooltipContent:
              'Commission will be collected at the time of disbursement to the supplier',
            value: isLoading
              ? undefined
              : isTaxIncluded
              ? commissionPreview?.amount_inc_tax
              : commissionPreview?.amount_exc_tax
          },
          ...(commissionPreview?.tax_type
            ? [
                {
                  label: `Total ${commissionPreview?.tax_type?.label}`,
                  value: commissionPreview?.amount_tax
                }
              ]
            : []),
          {
            isLoading,
            label: 'Commission Total',
            bold: true,
            value: commissionPreview?.amount_inc_tax
          }
        ]}
      />
    </>
  );
}
