import React from 'react';
import { Field } from '@rexlabs/form';
import { PhoneInput, EmailInput, TextArea } from '@rexlabs/text-input';

import { BlockConfig } from 'view/components/record-screen/types';
import { Column, Grid } from 'view/components/@luna/form/grid';
import { GhostButton } from '@rexlabs/button';
import { usePropertyPostcodeFinderFlag } from 'src/modules/properties/hooks/use-property-postcode-finder-flag';
import { useDialog } from '@rexlabs/dialog';
import { PostcodeFinderDialog } from 'src/modules/properties/dialogs/postcode-finder-dialog';

export const contactInformationBlock: BlockConfig = {
  id: 'contact-information',
  title: 'Contact information',
  validate: {
    definitions: {
      email: {
        name: 'email address',
        rules: 'email'
      }
    }
  },
  Edit: ({ setFieldValue }) => {
    const propertyPostcodeFinderFlag = usePropertyPostcodeFinderFlag();
    const postcodeFinderDialog = useDialog(PostcodeFinderDialog, {
      target: 'nested-dialogs'
    });

    const handlePostcodeFinder = () => {
      postcodeFinderDialog.open({
        handleSubmit({ values }) {
          setFieldValue?.(`address`, values.address.formats.postal_address);
          return values;
        }
      });
    };

    return (
      <Grid columns={4}>
        <Column width={2}>
          <Field
            name='address'
            label='Address'
            Input={TextArea}
            HelpContent={
              propertyPostcodeFinderFlag.isActive
                ? () => (
                    <GhostButton size='xs' onClick={handlePostcodeFinder}>
                      Search with a postcode
                    </GhostButton>
                  )
                : React.Fragment
            }
          />
        </Column>
        <Field name='email' label='Email address' Input={EmailInput} />
        <Field name='phone_number' label='Phone number' Input={PhoneInput} />
      </Grid>
    );
  }
};
