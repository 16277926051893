import * as React from 'react';
import { useCallback } from 'react';
import {
  NormalisedItem,
  RecordOption,
  Select,
  SelectProps
} from '@rexlabs/select';
import { BankStatementTransactionImport } from 'src/modules/banking/batch-receipting/types/bank-statement-transaction-import';
import { api } from 'utils/api/api-client';
import { toQuri } from '@rexlabs/quri';
import { formatCurrency } from 'utils/formatters';
import { formatDate } from 'utils/dates/format';
import { BATCH_TRANSACTION_ICON_MAP } from 'src/modules/banking/batch-receipting/components/bank-statement-transaction-type-icon';
import { useItems } from 'view/hooks/use-items';

const normaliser = (item: BankStatementTransactionImport): NormalisedItem => {
  const date = formatDate(item.uploaded_date);

  const percentage =
    100 - Math.round((item.not_receipted_count / item.total_count) * 100);

  const amount = `${formatCurrency(
    item.not_receipted_amount
  )} / ${formatCurrency(item.total_amount)}`;

  const avatar = item.type?.id
    ? BATCH_TRANSACTION_ICON_MAP[item.type.id]
    : null;

  return {
    id: item.id,
    label: item.name,
    avatar,
    description: `${date} • ${percentage}% (${amount})`
  };
};

export function BankStatementTransactionImportSelect(
  props: SelectProps<BankStatementTransactionImport>
) {
  const getItems = useCallback(async (term) => {
    const imports = await api.get(
      `/financials/bank-statement-transaction-imports`,
      {
        per_page: 30,
        search: term
      }
    );
    return (imports.data || []).map((item) => {
      return {
        ...item,
        label: item.name,
        value: item.id
      };
    });
  }, []);

  const getSuggestedItems = useCallback(async () => {
    const imports = await api.get(
      `/financials/bank-statement-transaction-imports`,
      {
        per_page: 5,
        o: `updated_at+desc`,
        q: toQuri([
          {
            field: 'status',
            op: 'neq',
            value: 'completed'
          }
        ])
      }
    );
    return (imports.data || []).map((item) => {
      return {
        ...item,
        label: item.name,
        value: item.id
      };
    });
  }, []);

  const { getSelectProps } = useItems<BankStatementTransactionImport>({
    getItems,
    getSuggestedItems
  });

  return (
    <Select
      {...props}
      {...getSelectProps()}
      Option={RecordOption}
      id='bank-statement-transaction-import-select'
      normaliser={normaliser}
    />
  );
}
