import { Generator, GeneratorConfig } from 'data/models/generator';
import { api } from 'utils/api/api-client';
import _ from 'lodash';
import { CustomAction } from '@rexlabs/model-generator';
import { AgencyFee } from 'src/modules/agency-fees/types/agency-fee-types';

const config: GeneratorConfig = {
  entities: {
    related: {
      agency_fee: {
        include:
          'agency_fee,agency_fee.agency_chart_of_accounts_account,agency_fee.ownership_chart_of_accounts_account,agency_fee.tax_type'
      }
    }
  }
};

type CreateNewFeeActionPayload = {
  propertyOwnershipId: string;
  agencyFeeId: string;
};

const actionCreators = {
  createNewFee: {
    request: (
      { propertyOwnershipId, agencyFeeId },
      _actions,
      _dispatch,
      _getState
    ) =>
      api.post(`/property-ownerships/${propertyOwnershipId}/fees`, {
        agency_fee: { id: agencyFeeId }
      }),
    reduce: _.identity
  } as CustomAction<CreateNewFeeActionPayload, unknown>
};

export const propertyOwnershipsFeesInactiveTemplatesModel = new Generator<
  AgencyFee,
  typeof actionCreators
>('property-ownerships/fees/inactive-templates', config).createEntityModel({
  actionCreators
});
