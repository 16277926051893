import React, { useMemo } from 'react';
import { TitleBlock } from 'view/components/cards/title/title-block';
import { RecordObject, RecordTypes } from 'data/models/types';
import { getRecordTitle } from 'utils/records/get-record-title';
import { TagConfig } from 'view/components/@luna/title-block';
import { useGetCustomReportActions } from '../hooks/action-menu-items/use-get-custom-report-actions';
import { CustomReport } from '../types/CustomReport';

const type = RecordTypes.CustomReport;

type CustomReportTitleBlockProps = {
  customReport?: CustomReport;
};

export function CustomReportTitleBlock({
  customReport
}: CustomReportTitleBlockProps) {
  const getCustomReportActions = useGetCustomReportActions();

  const title = getRecordTitle({ type, object: customReport } as RecordObject);
  const actions = useMemo(() => getCustomReportActions(customReport), [
    customReport,
    getCustomReportActions
  ]);

  if (!customReport) {
    return null;
  }

  const tags: TagConfig[] = [];

  return <TitleBlock title={title} type={type} actions={actions} tags={tags} />;
}
