import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import Box from '@rexlabs/box';
import { OutlineButton } from '@rexlabs/button';
import { StyleSheet, text, useStyles } from '@rexlabs/styling';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString();

const defaultStyles = StyleSheet({
  button: {
    marginLeft: ({ token }) => token('spacing.xs')
  },
  text: {
    ...text.styles({
      color: ({ token }) => token('color.textStyle.body.subtext')
    })
  }
});

interface PDFViewerProps {
  fileUrl: string;
  maxWidth?: string;
  onError?: (val: boolean) => void;
}

export const PDFViewer = ({
  fileUrl,
  onError,
  maxWidth = '100%'
}: PDFViewerProps) => {
  const s = useStyles(defaultStyles);

  const [pageNumber, setPageNumber] = React.useState(1);
  const [numPages, setNumPages] = React.useState(0);
  const [, setHasError] = React.useState(false);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function onDocumentLoadError() {
    setHasError(true);
    onError?.(true);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <Box style={{ maxWidth }}>
      <Box justifyContent='space-between' alignItems='center'>
        <Box {...s('text')}>
          Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
        </Box>
        <Box>
          <OutlineButton
            {...s('button')}
            isDisabled={pageNumber <= 1}
            onClick={previousPage}
          >
            Previous
          </OutlineButton>
          <OutlineButton
            {...s('button')}
            isDisabled={pageNumber >= numPages}
            onClick={nextPage}
          >
            Next
          </OutlineButton>
        </Box>
      </Box>
      <Box style={{ overflow: 'scroll' }}>
        <Document
          file={fileUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onDocumentLoadError}
          options={{
            cMapPacked: true,
            cMapUrl: '/cmaps/',
            standardFontDataUrl: '/standard_fonts/'
          }}
        >
          <Page
            pageNumber={pageNumber}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        </Document>
      </Box>
    </Box>
  );
};
