import { valueListFactory } from 'src/lib/testing/factories/value-list-factory';
import { Task } from 'src/modules/tasks/common/types/Task';
import { mapTaskFormDataToTaskCreateRequest } from '../../common/mappers/map-task-form-data-to-task-create-request';
import { TaskCreateRequest } from '../../common/types/TaskCreateRequest';
import { TaskFormData } from '../../common/types/TaskFormData';

export function mapSupplierComplianceFormDataToTaskCreateRequest(
  formData: Partial<
    Omit<Task<'task_property_compliance'>, 'managed_by'> &
      Pick<TaskFormData, 'managed_by'>
  >
): Partial<TaskCreateRequest> {
  // map the base values for the task
  const base = mapTaskFormDataToTaskCreateRequest(formData);

  return {
    ...base,
    type: valueListFactory('task_supplier_compliance')
  };
}
