import React, { MutableRefObject, useMemo, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import { LicenseManager } from 'ag-grid-enterprise';

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-material.css'; // Optional theme CSS
import '../styles/rex-pm-theme.css';
import { useQuery } from 'react-query';
import { api } from 'utils/api/api-client';
import { RecordScreen } from 'view/components/record-screen';
import { ContentConfig } from 'view/components/record-screen/types';
import { TitleBlock } from 'view/components/@luna/title-block';
import { TableProvider, useUrlState } from '@rexlabs/table';
import { ResourceId, useTableFilters } from 'view/hooks/use-table-filters';
import { ValueListValue } from 'data/models/types';
import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import LoadingState from 'view/components/states/compact/loading';
import dayjs from 'dayjs';
import { TaskType } from 'src/modules/tasks/common/types/TaskType';
import { getIsFolioLedgerReport } from 'src/modules/reporting/utils/get-is-folio-ledger-report';
import config from 'config';
import { tableReportBlock } from '../blocks/table-report-block';
import { exportToPDF } from '../utils/gridExporter';
import { getStaticReportEndpoint } from '../utils/get-static-report-endpoint';
import { getStaticReportForcedGlobalFilters } from '../utils/get-static-report-forced-global-filters';
// Note our license is for pre-May-2024 versions of AG Grid
LicenseManager.setLicenseKey(config.AG_GRID_KEY);

function withReport(Component) {
  return (props) => {
    const { reportId } = props;

    const { data: report, isLoading } = useQuery(['report-data', reportId], {
      queryFn: () =>
        api.get<ValueListValue<string>[]>(
          `${getStaticReportEndpoint(reportId)}`
        ),
      select: (data) => data?.data
    });

    if (isLoading) {
      return <LoadingState />;
    }

    if (!report && !isLoading) {
      // TODO: better error state
      return <div>something went wrong</div>;
    }

    return <Component {...props} report={report} />;
  };
}

export const TableReportRecord = withReport(
  withTableProviders(({ reportId, report }) => {
    // pull the gridRef from the context
    const gridRef = React.useContext(GridRefContext);

    const content: ContentConfig = [
      {
        label: 'Report',
        items: [
          {
            id: 'table-reports',
            label: 'Table reports',
            blocks: [tableReportBlock]
          }
        ]
      }
    ];

    const fileName = `${report.label} - ${dayjs().format(
      'YYYY-MM-DD'
    )}T${dayjs().format('HH_mm_ss')}`;

    const actions: ActionDeclaration[] = [
      // The reason for conditionally showing the print option here is that some other reports
      // have a lot of columns and they don't fit (horizontally) on a single page
      // So we're only supporting PDF export for folio ledger reports for now
      ...(getIsFolioLedgerReport(reportId)
        ? [
            {
              id: 'export-pdf',
              label: 'Print',
              group: 'export',
              handleAction: () =>
                gridRef?.current && exportToPDF(gridRef.current?.api)
            }
          ]
        : []),
      {
        id: 'export-csv',
        label: 'Export CSV',
        group: 'export',
        handleAction: () =>
          gridRef?.current?.api.exportDataAsCsv({
            fileName
          })
      },
      {
        id: 'export-excel',
        label: 'Export Excel',
        group: 'export',
        handleAction: () =>
          gridRef?.current?.api.exportDataAsExcel({
            fileName
          })
      }
    ];

    return (
      <RecordScreen
        privilege={'reporting.read'}
        isLoading={!report}
        titleBlock={
          <TitleBlock
            title={report.label}
            actions={actions}
            amountDisplayedAsButtons={3}
          />
        }
        data={{ reportId, report }}
        content={content}
        breadcrumbs={[{ type: BREADCRUMBS.STATIC_REPORTS }]}
      />
    );
  })
);

// create a context to hold the ag grid ref, so we can access it from the actions
export const GridRefContext = React.createContext<MutableRefObject<
  AgGridReact<any> | null | undefined
> | null>(null);

type FilterConfig = [resource: ResourceId, taskInfo?: { type_id: TaskType }];

function withTableProviders(Component) {
  return (props) => {
    const filterConfig: FilterConfig =
      props.report.resource_id === 'tasks'
        ? ['tasks', { type_id: props.report.task_type.id as TaskType }]
        : [props.report.resource_id];

    const { getSort, getFilters } = useTableFilters(...filterConfig);
    const { reportId } = props;

    const gridRef = useRef<AgGridReact<unknown> | undefined | null>(); // Optional - for accessing Grid's API

    const initialGlobalFilter = useMemo(() => {
      // get the filters hash param
      const hash = window.location.hash;
      const hashParams = new URLSearchParams(hash.slice(1));
      const filters = hashParams.get('filters');
      if (filters) {
        const parsed = JSON.parse(filters);
        return parsed;
      }
    }, []);

    const initialSortBy = useMemo(() => {
      // get the filters hash param
      const hash = window.location.hash;
      const hashParams = new URLSearchParams(hash.slice(1));
      const sort = hashParams.get('sort');
      if (sort) {
        const parsed = JSON.parse(sort);
        return parsed;
      }
    }, []);

    const { getTableProps: getUrlStateProps } = useUrlState({
      initialSortBy,
      initialGlobalFilter: initialGlobalFilter
    });

    return (
      <GridRefContext.Provider value={gridRef}>
        <TableProvider
          // this is what sets the url state filters into the table context
          {...getUrlStateProps()}
          getFilters={getFilters}
          getSort={getSort}
          forcedGlobalFilter={getStaticReportForcedGlobalFilters({
            reportId,
            initialGlobalFilter
          })}
        >
          <Component {...props} />
        </TableProvider>
      </GridRefContext.Provider>
    );
  };
}
