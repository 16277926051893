import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { TrustJournalEntry } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';

export function useGetIssueRefundAction() {
  return (_trustJournalEntry: TrustJournalEntry): ActionDeclaration => ({
    id: 'issue-refund',
    label: 'Issue refund',
    handleAction: () => {
      // TODO
    }
  });
}
