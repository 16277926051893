import React, { useMemo } from 'react';
import { useEntityQuery } from '@rexlabs/model-generator';

import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { RecordScreen } from 'view/components/record-screen';

import { useRecordScreenSubmitHandler } from 'view/hooks/use-record-screen-submit-handler';
import { TrustLedger } from 'src/modules/trust-ledgers/types/trust-ledger-types';
import { content } from '../data/content';
import { getTrustLedgerQuery } from '../data/queries';
import { TrustLedgerTitleBlock } from '../components/trust-ledger-title-block';

interface TrustLedgerDetailsScreenProps {
  trustLedgerId: string;
}

export function TrustLedgerDetailsScreen({
  trustLedgerId
}: TrustLedgerDetailsScreenProps) {
  const breadcrumbs = [{ type: BREADCRUMBS.TRUST_LEDGER }];
  const query = useMemo(() => getTrustLedgerQuery(trustLedgerId), [
    trustLedgerId
  ]);
  const { status, data, actions } = useEntityQuery(query);

  const handleSubmit = useRecordScreenSubmitHandler(({ changedValues }) =>
    actions.updateItem({
      id: trustLedgerId,
      data: changedValues
    })
  );

  return (
    <RecordScreen
      privilege={'trust-ledgers.read'}
      key={JSON.stringify(data)}
      isLoading={status === 'loading'}
      data={data}
      handleSubmit={handleSubmit}
      content={content}
      titleBlock={<TrustLedgerTitleBlock trustLedger={data as TrustLedger} />}
      breadcrumbs={breadcrumbs}
    />
  );
}
