import { Select } from '@rexlabs/select';
import { InputProps } from '@rexlabs/form';
import { useCachedItems } from 'view/hooks/api/cached-items';
import { api } from 'utils/api/api-client';
import React from 'react';
import {
  normaliseValueListItem,
  ValueListItem
} from 'utils/normaliser/value-list-item';
import { useItems } from 'view/hooks/use-items';

interface FeeTriggerProps extends InputProps {
  feeEntity: string;
}

export function FeeTrigger({ feeEntity, ...props }: FeeTriggerProps) {
  const feeTriggerUrl = `/financials/agency-fees/meta/triggers?available_for=${feeEntity}`;

  const { loadItems } = useCachedItems(
    feeTriggerUrl,
    async () => {
      const feeTriggerRequest = await api.get(feeTriggerUrl);

      const legacyFeeTriggerId = 'rent_receipted';

      const feeTriggerRequestData = feeTriggerRequest.data;
      return feeTriggerRequestData
        ? (feeTriggerRequestData?.filter(
            (feeTriggerItem) => feeTriggerItem.id !== legacyFeeTriggerId
          ) as ValueListItem[])
        : [];
    },
    { prefetch: true }
  );

  const { getSelectProps } = useItems({
    getItems: loadItems,
    getSuggestedItems: loadItems
  });

  return (
    <Select
      {...props}
      {...getSelectProps()}
      searchable={false}
      normaliser={normaliseValueListItem}
    />
  );
}
