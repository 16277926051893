import { DialogProps } from '@rexlabs/dialog';
import * as React from 'react';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';

import { FolioCommissionTemplate } from '../types/FolioCommissionTemplate';
import { supplierCommissionDetailsBlock } from '../blocks/supplier-commission-details';
import { useGetCreateSupplierCommissionTemplateHandler } from '../hooks/use-get-create-supplier-commission-template-handler';
import { useGetUpdateSupplierCommissionTemplateHandler } from '../hooks/use-get-update-supplier-commission-template-handler';
import { getNewTier } from '../components/edit-template-tier-line-items';
import { UpdateSupplierCommissionTemplateDialogButtonGroup } from '../components/update-supplier-commission-template-dialog-button-group';
import { mapSupplierCommissionTemplateToFormData } from '../mappers/map-supplier-commission-template-to-form-data';

export interface UpdateSupplierCommissionTemplateDialogProps
  extends DialogProps {
  supplierCommissionTemplate?: FolioCommissionTemplate;
}

export function UpdateSupplierCommissionTemplateDialog({
  onClose,
  supplierCommissionTemplate
}: UpdateSupplierCommissionTemplateDialogProps) {
  const createHandler = useGetCreateSupplierCommissionTemplateHandler();
  const updateHandler = useGetUpdateSupplierCommissionTemplateHandler();

  const isEditMode = supplierCommissionTemplate != null;

  const title = isEditMode
    ? 'Update commission template'
    : 'Create commission template';

  const handleSubmit = isEditMode ? updateHandler : createHandler;

  return (
    <RecordDialog
      title={title}
      handleSubmit={handleSubmit}
      ButtonGroup={(props) => (
        <UpdateSupplierCommissionTemplateDialogButtonGroup
          {...props}
          label={title}
        />
      )}
      content={[
        {
          id: 'details',
          label: 'details',
          blocks: [supplierCommissionDetailsBlock]
        }
      ]}
      onClose={onClose}
      initialValues={
        isEditMode
          ? mapSupplierCommissionTemplateToFormData(supplierCommissionTemplate)
          : {
              tiers: [getNewTier(), getNewTier()]
            }
      }
    />
  );
}
