import React from 'react';

import { Field } from '@rexlabs/form';

import { User } from 'src/modules/users/types/user-types';

import { Grid, Column } from 'view/components/@luna/form/grid';
import { BlockConfig } from 'view/components/record-screen/types';
import { UserRoleSelect } from 'view/components/inputs/selects/v4-selects/user-role-select';
import { InfoBanner } from 'view/components/@luna/notifications/banner';
import InfoCircleIcon from 'view/components/icons/info';
import { Value } from 'view/components/values';
import { Role } from 'src/modules/authorization/roles/types/Role';

type UserPermissionsBlockForm = {
  role?: Role;
};

export const userPermissionsBlock: BlockConfig<
  User,
  any,
  UserPermissionsBlockForm
> = {
  id: 'permissions',
  title: 'Permissions',
  View: ({ data }) => {
    return (
      <Grid columns={1}>
        <Grid columns={4}>
          <Column width={2}>
            <Value label='Role' value={data?.role?.name} />
          </Column>
        </Grid>
        <Grid columns={4}>
          <Column width={2}>
            <InfoBanner
              description={data?.role?.description || ''}
              Icon={InfoCircleIcon}
            />
          </Column>
        </Grid>
      </Grid>
    );
  },
  Edit: ({ values }) => {
    return (
      <Grid columns={1}>
        <Grid columns={4}>
          <Column width={2}>
            <Field id='role' label='Role' name='role' Input={UserRoleSelect} />
          </Column>
        </Grid>
        {values?.role?.description && (
          <Grid columns={4}>
            <Column width={2}>
              <InfoBanner
                description={values?.role?.description || ''}
                Icon={InfoCircleIcon}
              />
            </Column>
          </Grid>
        )}
      </Grid>
    );
  }
};
