export const CUSTOM_TASKS_ROUTES = {
  CUSTOM_TASKS_LIST: {
    config: {
      exact: true,
      path: '/custom-tasks',
      Component: require('../screens/custom-tasks-list.tsx').CustomTasksList
    }
  },
  // This is the same route as above, but with a different path to make the nav menu active state work properly for the "All" tab
  CUSTOM_TASKS_LIST_ALL: {
    config: {
      exact: true,
      path: '/custom-tasks/all',
      Component: require('../screens/custom-tasks-list.tsx').CustomTasksList
    }
  },
  CUSTOM_TASK_DETAILS: {
    config: {
      exact: true,
      path: '/custom-task/:customTaskId',
      Component: require('../screens/custom-task-details.tsx').CustomTaskDetails
    }
  }
};
