import { NormalisedItem, Select } from '@rexlabs/select';
import * as React from 'react';
import { Property } from 'src/modules/properties/types/property-types';
import { api } from 'utils/api/api-client';
import { useItems } from 'view/hooks/use-items';

const normaliser = (item: Property): NormalisedItem => ({
  id: item.id,
  label: item.address_string
});

export function PropertySelect(props) {
  const { includes: propIncludes, filters: propFilters } = props;

  const queryParams = new URLSearchParams();

  if (Array.isArray(propIncludes)) {
    queryParams.set('include', propIncludes.join(','));
  }

  if (typeof propFilters === 'string') {
    queryParams.set('q', propFilters);
  }

  const query = queryParams.toString();

  const getItems = React.useCallback(
    async (term) => {
      const properties = await api.get(`/properties?${query}`, {
        search: term,
        per_page: 30
      });

      return properties.data;
    },
    [query]
  );

  const { getSelectProps } = useItems({
    getItems: getItems,
    ...(props.getSuggestedItems
      ? { getSuggestedItems: props.getSuggestedItems }
      : {})
  });

  return <Select normaliser={normaliser} {...props} {...getSelectProps()} />;
}
