import { Message } from '@rexlabs/notifications';
import React from 'react';
import { Card } from 'view/components/card';
import EmptyBatchReceipting from 'src/assets/illustrations/empty-batch-receipting-list.svg';
import ArrowUpCircle from 'view/components/icons/arrow-up-circle';
import { FLAGS } from 'utils/feature-flags';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { useGetUploadBankTransactionsAction } from '../../hooks/use-get-upload-bank-transactions-action';
import { useGetUploadPaymentGatewayAction } from '../../hooks/use-get-upload-payment-gateway-action';

export function AllActiveTabEmptyState() {
  const uploadBankTransactionsAction = useGetUploadBankTransactionsAction();
  const uploadPaymentGatewayAction = useGetUploadPaymentGatewayAction();

  const { hasFeature } = useFeatureFlags();
  const hasPaymentGatewaysFeature = hasFeature(FLAGS.PAYMENT_GATEWAYS);

  return (
    <Card>
      <Message
        title={
          hasPaymentGatewaysFeature
            ? 'Add file'
            : 'Add transactions from your bank'
        }
        Illustration={EmptyBatchReceipting}
        actions={[
          {
            ...uploadBankTransactionsAction,
            Icon: ArrowUpCircle
          },
          ...(hasPaymentGatewaysFeature
            ? [
                {
                  ...uploadPaymentGatewayAction,
                  Icon: ArrowUpCircle
                }
              ]
            : [])
        ]}
      >
        {hasPaymentGatewaysFeature
          ? 'Upload your bank statement or payment gateway file to start batch receipting.'
          : 'Upload your bank statement to start batch receipting.'}
      </Message>
    </Card>
  );
}
