import React from 'react';
import { Field, HiddenField } from '@rexlabs/form';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { BlockConfig } from 'view/components/record-screen/types';
import { SimpleFileUploadInputWithErrorHandling } from 'view/components/inputs/file-upload-input/simple-file-upload-input-with-error-handling';
import { PDFViewer } from 'view/components/pdf-viewer';

export type InvoiceBillFormValues = {
  uploaded_bill?: {
    id: string;
  };
};

const fileUploadCSSVariable = '--file-upload-height';

const defaultStyles = StyleSheet({
  fileUpload: {
    '& label[for="uploaded_bill"]': {
      height: `var(${fileUploadCSSVariable})`,
      display: 'flex',
      flexDirection: 'column',
      gap: 8
    }
  }
});

const validate = {
  definitions: {
    uploaded_bill: {
      name: 'bill file',
      rules: 'uploadedBillMustBePdf'
    }
  }
};

export const invoiceViewerBlock: BlockConfig = {
  id: 'invoice-viewer',
  validate,
  Edit: ({ values, setFieldValue }) => {
    const s = useStyles(defaultStyles);

    const [hasError, setHasError] = React.useState(false);

    const wrapperRef = useFullSizeFileInput();

    const fileUrl = values?.uploaded_bill?.file?.url ?? values?.preview_url;
    const hasBillUploaded = fileUrl != null;

    return (
      <div ref={wrapperRef} {...s('fileUpload')}>
        <HiddenField name='preview_url' />

        {!hasBillUploaded || hasError ? (
          <Field<typeof SimpleFileUploadInputWithErrorHandling>
            id='uploaded_bill'
            name='uploaded_bill'
            optional={false}
            Input={SimpleFileUploadInputWithErrorHandling}
            inputProps={{
              accept: 'application/pdf',
              multi: false,
              onFileSelectCallback: (file) => {
                setFieldValue!('preview_url', file?.url);
              }
            }}
          />
        ) : (
          <PDFViewer fileUrl={fileUrl} maxWidth='40vw' onError={setHasError} />
        )}
      </div>
    );
  }
};

/**
 * Context: We need file-upload input component to occupy full height but currently it is 40px.
 *
 * This hook finds the file-upload label element and updates the height of the input with
 * dialog body's height
 */
const useFullSizeFileInput = () => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    if (!wrapperRef.current) return;

    // This is the block wrapper element.
    const blockElement =
      wrapperRef.current.parentElement?.parentElement?.parentElement;
    if (!blockElement) return;

    wrapperRef.current.style.setProperty(
      fileUploadCSSVariable,
      `${blockElement.offsetHeight}px`
    );
  }, []);

  React.useEffect(() => {
    if (!wrapperRef.current) return;

    // This is the block wrapper element.
    const blockElement =
      wrapperRef.current.parentElement?.parentElement?.parentElement;
    if (!blockElement) return;

    // Whenever the height of the dialog changes, get the height and set as CSS variable.
    const resizeObserver = new ResizeObserver(
      (entries: ResizeObserverEntry[]) => {
        window.requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length) return;

          const entry = entries[0];

          if (!wrapperRef.current) return;

          wrapperRef.current.style.setProperty(
            fileUploadCSSVariable,
            `${entry.contentRect.height}px`
          );
        });
      }
    );

    resizeObserver.observe(blockElement);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return wrapperRef;
};
