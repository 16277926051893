import { bankAccountModel } from 'data/models/entities/financials/bank-accounts';
import { Generator } from 'data/models/generator';
import { auditableConfig } from 'src/modules/common/auditable/auditable-config';
import { api } from 'utils/api/api-client';
import _ from 'lodash';
import { CustomAction, DeepPartial } from '@rexlabs/model-generator';
import {
  BankStatementChildTransaction,
  BatchReceiptingItem,
  NewSplitTransactionFormValue
} from '../types';

const config = {
  entities: {
    related: {
      ...auditableConfig,
      prioritised_match: {
        include: 'prioritised_match'
      },
      bank_account: {
        include: 'bank_account',
        model: bankAccountModel
      },
      child_transactions: {
        include: [
          'child_transactions',
          'child_transactions.prioritised_match',
          'child_transactions.trust_journal_entries'
        ].join(',')
      }
    }
  }
};

const actionCreators = {
  manualMatch: {
    request: ({
      bankStatementTransactionId,
      data
    }: {
      bankStatementTransactionId: string;
      data: DeepPartial<BatchReceiptingItem> | any;
    }) =>
      api.patch(
        `/financials/bank-statement-transactions/${bankStatementTransactionId}/manual-match`,
        data
      ),
    reduce: _.identity
  },
  matchBankStatementTransaction: {
    request: ({
      bankStatementTransactionId,
      trustJournalEntryIds
    }: {
      bankStatementTransactionId: string;
      trustJournalEntryIds: string[];
    }) =>
      api.put(
        `/financials/bank-statement-transactions/${bankStatementTransactionId}/matched-trust-transactions`,
        {
          trust_journal_entries: trustJournalEntryIds.map(
            (trustJournalEntryId) => {
              return { id: trustJournalEntryId };
            }
          )
        }
      ),
    reduce: _.identity
  },
  rematchBankStatementTransactions: {
    request: () =>
      api.post(
        'financials/bank-statement-transactions/recalculate-match-cache'
      ),
    reduce: _.identity
  } as CustomAction<void, unknown>,
  previewBankUpload: {
    request: (fileId) =>
      api.post('financials/bank-statement-transactions/imports/preview', {
        file: { id: fileId }
      }),
    reduce: _.identity
  },
  importBankTransactions: {
    request: ({
      fileId,
      bankAccountId,
      typeId,
      paymentGatewayId
    }: {
      fileId: string;
      bankAccountId: string;
      typeId?: string;
      paymentGatewayId?: string;
    }) =>
      api.post('financials/bank-statement-transactions/imports', {
        bank_account: { id: bankAccountId },
        file: { id: fileId },
        ...(typeId ? { type: { id: typeId } } : {}),
        ...(paymentGatewayId
          ? { payment_gateway: { id: paymentGatewayId } }
          : {})
      }),
    reduce: _.identity
  },
  importSubBankTransactions: {
    request: ({
      fileId,
      bankStatementTransactionId
    }: {
      fileId: string;
      bankStatementTransactionId: string;
    }) =>
      api.post(
        `financials/bank-statement-transactions/${bankStatementTransactionId}/sub-transactions/imports`,
        {
          file: { id: fileId }
        }
      ),
    reduce: _.identity
  },
  createSubBankTransactions: {
    request: ({
      bankStatementTransactionId,
      subTransactions
    }: {
      bankStatementTransactionId: string;
      subTransactions: Array<NewSplitTransactionFormValue>;
    }) =>
      api.post(
        `financials/bank-statement-transactions/${bankStatementTransactionId}/sub-transactions`,
        {
          sub_transactions: subTransactions
        }
      ),
    reduce: _.identity
  },
  updateSubBankTransactions: {
    request: ({
      bankStatementTransactionId,
      subTransactions
    }: {
      bankStatementTransactionId: string;
      subTransactions: Array<
        NewSplitTransactionFormValue | BankStatementChildTransaction
      >;
    }) =>
      api.put(
        `financials/bank-statement-transactions/${bankStatementTransactionId}/sub-transactions`,
        {
          sub_transactions: subTransactions
        }
      ),
    reduce: _.identity
  },
  deleteSubBankTransactions: {
    request: ({
      bankStatementTransactionId
    }: {
      bankStatementTransactionId: string;
    }) =>
      api.delete(
        `financials/bank-statement-transactions/${bankStatementTransactionId}/sub-transactions`
      ),
    reduce: _.identity
  }
};

export const bankStatementTransactionsModel = new Generator<
  BatchReceiptingItem,
  typeof actionCreators
>('financials/bank-statement-transactions', config).createEntityModel({
  actionCreators
});
