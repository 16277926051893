import Box from '@rexlabs/box';
import { OutlineButton } from '@rexlabs/button';
import { useDialog } from '@rexlabs/dialog';
import { Tenancy } from 'data/models/entities/tenancies';
import { ValueListValue } from 'data/models/types';
import invariant from 'invariant';
import { get } from 'lodash';
import React from 'react';
import { MatchStatusId } from 'src/modules/banking/batch-receipting/types';
import { Grid } from 'view/components/@luna/form/grid';
import {
  ErrorBanner,
  InfoBanner,
  SuccessBanner,
  WarningBanner
} from 'view/components/@luna/notifications/banner';
import CheckCircleIcon from 'view/components/icons/check-circle';
import CommentIcon from 'view/components/icons/comment';
import InfoCircleIcon from 'view/components/icons/info';
import { BlockConfig } from 'view/components/record-screen/types';
import { ReceiptPaymentMethods } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { UpdateTenantReferenceNumberDialog } from '../dialogs/update-tenant-reference-number';
import { getFolio } from '../utils/get-folio';
import {
  ReceiptFundsPaymentForm,
  receiptFundsPaymentFormValidationRules
} from './receipt-funds-payment-form';

export type ReceiptFundsPaymentDetailsBlockProps = {
  payment_information: {
    method: ValueListValue<ReceiptPaymentMethods>;
    reference?: string;
    drawer?: string;
    issuer?: string;
    branch_code?: string;
  };
  matchStatus: MatchStatusId;
};
export const receiptFundsPaymentDetailsBlock: BlockConfig<
  any,
  {
    allowUpdateTenantReference: boolean;
    renderBatchReceiptingDialog?: boolean;
  },
  ReceiptFundsPaymentDetailsBlockProps
> = {
  id: 'receipt-funds-payment-details',
  title: 'Payment details',
  validate: {
    definitions: {
      ...receiptFundsPaymentFormValidationRules
    }
  },
  Edit: ({ values, data, forms, blockProps }) => {
    const allowUpdateTenantReference = blockProps?.allowUpdateTenantReference;

    const [hasUpdatedReference, setHasUpdatedReference] = React.useState(false);

    const updateReferenceNumberDialog = useDialog(
      UpdateTenantReferenceNumberDialog
    );

    const folio = getFolio(forms);

    const receiptingInstruction =
      folio?.type.id === 'tenancy'
        ? (folio?.record as Tenancy)?.receipting_instruction
        : null;

    const isMatched = ['matched_allocate', 'matched_receipt'].includes(
      values!.matchStatus
    );

    let receiptFrom = get(
      forms,
      'receipt-funds-details.values.contacts'
    ) as Array<Contact>;
    receiptFrom = Array.isArray(receiptFrom) ? receiptFrom : [];

    const tenants = get(forms, 'receipt-funds-details.values.tenants');

    const contactIds = Array.isArray(data?.contacts)
      ? data.contacts.map((contact) => contact.id)
      : [];

    const isReceiptFromSame =
      receiptFrom.length > 0 &&
      receiptFrom.some((contact) => contactIds.includes(contact.id));

    const tenantName = receiptFrom
      .map((contact) => contact.display_name)
      .join(', ');

    const tenantReferenceNumbers = receiptFrom
      .filter((contact) => contact.payment_reference != null)
      .map((contact) => contact.payment_reference);

    const tenantReferenceNumberDisplay = tenantReferenceNumbers.join(', ');

    const newReferenceNumber = values?.payment_information?.reference;

    const hasSameReferenceNumber = !!(
      tenantReferenceNumbers.length &&
      tenantReferenceNumbers.every(
        (referenceNumber) => referenceNumber === newReferenceNumber
      )
    );
    const isTenancy = folio?.type.id === 'tenancy';

    const showUpdateBanner = !!(
      allowUpdateTenantReference &&
      !hasSameReferenceNumber &&
      receiptFrom.length &&
      isTenancy &&
      !isMatched &&
      !hasUpdatedReference
    );

    const handleUpdateReferenceNumber = React.useCallback(() => {
      invariant(folio?.id, `folio.id can't be null`);

      updateReferenceNumberDialog.open({
        folioId: folio?.id,
        tenantIds: receiptFrom.map((contact) => contact.id),
        tenants,
        initialValues: {
          tenantName,
          tenantReferenceNumbers: tenantReferenceNumberDisplay,
          newReferenceNumber
        },
        onSave() {
          setHasUpdatedReference(true);
        }
      });
    }, [
      updateReferenceNumberDialog,
      tenantName,
      tenantReferenceNumberDisplay,
      newReferenceNumber,
      folio?.id,
      receiptFrom,
      tenants
    ]);

    return (
      <Grid columns={1}>
        <Grid columns={3}>
          <ReceiptFundsPaymentForm blockProps={blockProps} values={values} />
        </Grid>

        {receiptingInstruction && (
          <InfoBanner
            data-testid='receipting-instructions-banner'
            Icon={CommentIcon}
            description={<>{receiptingInstruction}</>}
          />
        )}

        {showUpdateBanner && (
          <WarningBanner
            data-testid='warning-banner'
            Icon={InfoCircleIcon}
            description={
              <Box gap={12}>
                <div>
                  The reference number for this payment does not match the{' '}
                  <b>tenant reference number</b> for <b>{tenantName}</b>. To
                  ensure future payments will automatically match, you can
                  update the tenant reference number for this tenant.
                </div>

                <OutlineButton onClick={handleUpdateReferenceNumber}>
                  Update tenant reference number
                </OutlineButton>
              </Box>
            }
          />
        )}

        {hasUpdatedReference && (
          <SuccessBanner
            data-testid='success-banner'
            Icon={CheckCircleIcon}
            description={
              <div>
                The tenant reference number has been updated successfully. All
                future payments using this reference number will be
                automatically matched to this tenant.
              </div>
            }
          />
        )}

        {isMatched && !isReceiptFromSame && (
          <ErrorBanner
            data-testid='error-banner'
            Icon={InfoCircleIcon}
            description={
              <div>
                The reference number for this payment does not match the tenant
                reference number for <b>{tenantName}</b>. The reference can not
                be updated as it is already in use by a different tenant.
              </div>
            }
          />
        )}
      </Grid>
    );
  }
};
