import React from 'react';
import Box from '@rexlabs/box';
import { useStyles, StyleSheet } from '@rexlabs/styling';

import {
  Privilege,
  usePrivileges
} from 'src/modules/authorization/roles/hooks/use-privileges';
import { StatBlock, StatBlockProps } from './stat-block';

const defaultStyles = StyleSheet({
  container: {
    overflow: 'auto',
    padding: '24px',
    margin: '-24px',

    gap: ({ token }) => token('spacing.xl')
  }
});

export type Stat = StatBlockProps & {
  id?: string;
  privilege?: Privilege;
};

export interface StatBlocksProps {
  stats: Stat[];
  shouldWrap?: boolean;
}

export function StatBlocks({ stats, shouldWrap = false }: StatBlocksProps) {
  const s = useStyles(defaultStyles);
  const { hasPrivilege } = usePrivileges();
  return (
    <Box
      {...s('container')}
      flexDirection='row'
      flexWrap={shouldWrap ? 'wrap' : 'nowrap'}
    >
      {stats
        .filter((stat) => {
          return !stat.privilege || hasPrivilege(stat.privilege);
        })
        .map(({ id, ...stat }, index) => (
          <StatBlock key={id || index} {...stat} />
        ))}
    </Box>
  );
}
