import * as React from 'react';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { TitleBlock } from 'view/components/@luna/title-block';
import { RecordScreen } from 'view/components/record-screen';
import {
  query,
  useEntityListQuery,
  useModelActions
} from '@rexlabs/model-generator';
import { toQuri } from '@rexlabs/quri';
import {
  LetterLayout,
  communicationLetterLayoutsModel
} from 'data/models/entities/communication/letter-layouts';
import invariant from 'invariant';
import { useContent } from '../hooks/use-content';
import { mapLetterLayoutEditFormToUpdateRequest } from '../mappers/map-letter-layout-edit-form-to-update-request';
import { LetterLayoutForm } from '../types/letter-layout-form';

const getDefaultLetterLayoutsQuery = (filter: string) => query`{
  ${communicationLetterLayoutsModel} (q: ${filter}) {
    print_offsets
  }
}`;

const defaultFilter = toQuri([
  {
    field: 'system_purpose_id',
    op: '==',
    value: 'default'
  }
]);

export function DefaultLetterLayout() {
  const defaultLetterLayoutsQuery = React.useMemo(
    () => getDefaultLetterLayoutsQuery(defaultFilter),
    []
  );

  const { data, status } = useEntityListQuery(defaultLetterLayoutsQuery);
  const { updateItem } = useModelActions(communicationLetterLayoutsModel);

  const defaultLetterLayout = React.useMemo<LetterLayout | null>(() => {
    if (data?.length === 0) {
      return null;
    }

    // assuming the filter works, we are only getting defaults, and there should be only one.
    return data?.[0] ?? null;
  }, [data]);

  const content = useContent();

  // update the default layout
  const handleSubmit: RecordSubmitHandler = async ({
    changedValues,
    values
  }) => {
    invariant(defaultLetterLayout?.id, 'Default letter layout not found');

    const deeplyMergedValues: LetterLayoutForm = {
      ...values,
      ...changedValues,
      print_offsets: {
        ...values.print_offsets,
        ...changedValues.print_offsets
      }
    };

    const payload = mapLetterLayoutEditFormToUpdateRequest(deeplyMergedValues);

    await updateItem({
      id: defaultLetterLayout.id,
      data: payload
    });
  };

  return (
    <RecordScreen
      privilege={'message-templates.read'}
      isLoading={status === 'loading'}
      data={{
        ...defaultLetterLayout
      }}
      handleSubmit={handleSubmit}
      content={content}
      titleBlock={<TitleBlock title='Default letter layout'></TitleBlock>}
    />
  );
}
