import React from 'react';
import { createTableBlock } from 'view/components/record-screen/cards/create-table-block';
import { toQuri } from '@rexlabs/quri';
import { query } from '@rexlabs/model-generator';
import { financialsSecurityDepositPaymentsModel } from 'data/models/entities/financials/security-deposit-payments';
import { Message } from 'view/components/@luna/message';
import { SecurityDeposit } from 'data/models/entities/financials/security-deposits';
import { ActionButtons } from 'view/components/@luna/action-buttons';
import { useGetCreateSecurityDepositReturnPaymentAction } from 'src/modules/security-deposits/utils/security-deposit-actions/use-get-create-security-deposit-return-payment-action';
import { securityDepositPaymentColumns } from '../utils/security-deposit-payment-columns';
import { useGetCreateSecurityDepositPaymentAction } from '../utils/security-deposit-actions/use-get-create-security-deposit-payment-action';

const getSecurityDepositPaymentsQuery = (securityDepositId: string) => {
  const q = toQuri([
    {
      field: 'security_deposit_id',
      op: '==',
      value: securityDepositId
    }
  ]);
  return query`{
    ${financialsSecurityDepositPaymentsModel} (q: ${q}) {
      id
      type
      description
      amount
    }
  }`;
};

export const securityDepositPaymentsBlock = createTableBlock<
  SecurityDeposit,
  typeof financialsSecurityDepositPaymentsModel
>({
  id: 'security-deposit-payments',
  title: 'Payments',
  getTableProps: ({ data }) => ({
    id: 'security-deposit-requests',
    getQuery: () => getSecurityDepositPaymentsQuery(data.id),
    columns: securityDepositPaymentColumns
  }),
  Empty: ({ data }) => {
    const getCreateSecurityDepositPaymentAction = useGetCreateSecurityDepositPaymentAction();

    return (
      <Message
        title='Payments'
        actions={[getCreateSecurityDepositPaymentAction(data!)]}
      >
        Payments receipted against any request (invoice) on this security
        deposit will appear here automatically. For this deposit, you can also
        add in payments made directly to the holder of the deposit.
      </Message>
    );
  },
  Actions: ({ data }) => {
    const getCreateSecurityDepositPaymentAction = useGetCreateSecurityDepositPaymentAction();
    const getCreateSecurityDepositReturnPaymentAction = useGetCreateSecurityDepositReturnPaymentAction();

    return (
      <ActionButtons
        actions={[
          getCreateSecurityDepositPaymentAction(data!),
          getCreateSecurityDepositReturnPaymentAction(data!)
        ]}
      />
    );
  }
});
