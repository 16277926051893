import { JobDetailsSection } from 'view/components/related-records/sections/job-details';
import { SupplierSection } from 'view/components/related-records/sections/supplier';
import { OwnersSection } from './owners';
import { PropertyOwnershipsSection } from './property-ownerships';
import { PropertyTenanciesSection } from './property-tenancies';
import { TenantsSection } from './tenants';
import { PropertyOwnershipSection } from './property-ownership';
import { PropertyTenancySection } from './property-tenancy';
import { PortfolioManagersSection } from './portfolio-manager';
import { OwnershipsSection } from './ownerships';
import { TenanciesSection } from './tenancies';
import { PropertiesSection } from './properties';
import { OwnershipSection } from './ownership';
import { TenancySection } from './tenancy';
import { InvoiceSection } from './invoice';
import { DepositHeldBySection } from './deposit-held-by';
import { DepositInsuredBy } from './deposit-insured-by';
import { ManagedBySection } from './managed-by';
import { ContactSection } from './contact';
import { PropertySection } from './property';
import { AccountingJournalEntrySourceSection } from './accounting-journal-entry-source';
import { LinkedTasksSection } from './linked-tasks';
import { PortfoliosSection } from './portfolios';
import { TaskSection } from './task';
import { TrustJournalEntrySourceAndDestinationSection } from './trust-journal-entry-source-and-destination';

const invoiceSection = {
  type: 'invoice',
  headline: 'Invoice details',
  Component: InvoiceSection
} as const;

const propertiesSection = {
  type: 'properties',
  headline: 'Properties',
  Component: PropertiesSection
} as const;

const ownersSection = {
  type: 'owners',
  headline: 'Owners',
  Component: OwnersSection
} as const;

const tenantsSection = {
  type: 'tenants',
  headline: 'Tenants',
  Component: TenantsSection
} as const;

const ownershipSection = {
  type: 'ownership',
  headline: 'Ownership',
  Component: OwnershipSection
} as const;

const ownershipsSection = {
  type: 'ownerships',
  headline: 'Ownerships',
  Component: OwnershipsSection
} as const;

const tenancySection = {
  type: 'tenancy',
  headline: 'Tenancy',
  Component: TenancySection
} as const;

const tenanciesSection = {
  type: 'tenancies',
  headline: 'Tenancies',
  Component: TenanciesSection
} as const;

const propertyOwnershipsSection = {
  type: 'property-ownerships',
  headline: 'Properties',
  Component: PropertyOwnershipsSection
} as const;

const propertyTenanciesSection = {
  type: 'property-tenancies',
  headline: 'Properties',
  Component: PropertyTenanciesSection
} as const;

const propertyOwnershipSection = {
  type: 'property-ownership',
  headline: 'Ownership',
  Component: PropertyOwnershipSection
} as const;

const tenancyOwnershipSection = {
  type: 'property-tenancy',
  headline: 'Tenancy',
  Component: PropertyTenancySection
} as const;

const portfolioSection = {
  type: 'portfolio',
  headline: 'Portfolio',
  Component: PortfolioManagersSection
} as const;

const depositHeldBySection = {
  type: 'deposit-held-by',
  headline: 'Deposit held by',
  Component: DepositHeldBySection
} as const;

const depositInsuredBySection = {
  type: 'deposit-insured-by',
  headline: 'Insured by',
  Component: DepositInsuredBy
} as const;

const jobDetailsSection = {
  type: 'job-details',
  headline: 'Job details',
  Component: JobDetailsSection
};

const managedBySection = {
  type: 'managed-by',
  headline: 'Managed by',
  Component: ManagedBySection
} as const;

const supplierSection = {
  type: 'supplier',
  headline: 'Supplier',
  Component: SupplierSection
} as const;

const contactSection = {
  type: 'contact',
  headline: 'Contact',
  Component: ContactSection
} as const;

const propertySection = {
  type: 'property',
  headline: 'Property',
  Component: PropertySection
} as const;

const accountingJournalEntrySource = {
  type: 'accounting-journal-entry-source',
  headline: 'Source',
  Component: AccountingJournalEntrySourceSection
};

const workAssignedToSection = {
  type: 'work-assigned-to',
  headline: 'Work assigned to',
  Component: ContactSection
};

const linkedTasksSection = {
  type: 'linked-tasks',
  headline: 'Linked tasks',
  Component: LinkedTasksSection
};

const portfoliosSection = {
  type: 'portfolios',
  headline: 'Portfolios',
  Component: PortfoliosSection
};

const taskSection = {
  type: 'task',
  headline: 'Task',
  Component: TaskSection
};

const trustJournalEntrySourceAndDestinationSection = {
  type: 'trust-journal-entries-source-and-destination',
  headline: 'Trust journal entry details',
  Component: TrustJournalEntrySourceAndDestinationSection
};

export const AVAILABLE_SECTIONS = [
  invoiceSection,
  propertiesSection,
  ownersSection,
  tenantsSection,
  ownershipSection,
  ownershipsSection,
  tenancySection,
  tenanciesSection,
  propertyOwnershipsSection,
  propertyTenanciesSection,
  propertyOwnershipSection,
  tenancyOwnershipSection,
  portfolioSection,
  depositHeldBySection,
  depositInsuredBySection,
  jobDetailsSection,
  managedBySection,
  supplierSection,
  contactSection,
  propertySection,
  accountingJournalEntrySource,
  workAssignedToSection,
  linkedTasksSection,
  portfoliosSection,
  taskSection,
  trustJournalEntrySourceAndDestinationSection
] as const;
