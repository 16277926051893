import { padding, StyleSheet, useStyles } from '@rexlabs/styling';
import { TimestampAttributes } from 'data/models/types';
import React from 'react';
import { formatDateTime } from 'utils/dates/format';
import { getRecordLabel } from 'utils/records/get-record-label';
import { Author } from 'src/modules/authors/types/author-types';
import { usePrivileges } from 'src/modules/authorization/roles/hooks/use-privileges';
import { Link } from '@rexlabs/whereabouts';
import ROUTES from 'routes/app';

const defaultStyles = StyleSheet({
  content: {
    ...padding.styles({
      x: 'l'
    }),
    color: ({ token }) => token('color.textStyle.body.subtext'),
    fontSize: ({ token }) => token('typography.size.s'),
    fontWeight: ({ token }) => token('typography.weight.semibold')
  },
  author: {
    color: ({ token }) => token('color.textStyle.body.default')
  },
  recordChangeLogItem: {
    ...padding.styles({
      y: 'xxs'
    })
  }
});

export type RecordChangeLogItem = {
  label: React.ReactNode | string;
  author: Author | null;
  timestamp: Date | null;
};

type RecordChangeLogProps = {
  items?: RecordChangeLogItem[];
  record?: TimestampAttributes & { id: string };
  showAuditLogLink?: boolean;
};

export function RecordChangeLog({
  items,
  record,
  showAuditLogLink = true
}: RecordChangeLogProps) {
  const s = useStyles(defaultStyles);
  const { hasPrivilege } = usePrivileges();

  const shouldShowAuditLogLink =
    hasPrivilege('audit-logs.read') && record?.id && showAuditLogLink;

  let data: RecordChangeLogItem[] = [];

  if (record) {
    data = [
      {
        label: 'Created by',
        author: record.created_by!,
        timestamp: record.created_at!
      }
    ];

    if (record.updated_by && record.updated_at) {
      data = [
        ...data,
        {
          label: 'Updated by',
          author: record.updated_by!,
          timestamp: record.updated_at!
        }
      ];
    }
  }

  if (items) {
    items.forEach((item) => {
      if (item.timestamp && item.author) {
        data.push(item);
      }
    });
  }

  return (
    <div {...s('content')}>
      {data.map(({ label, author, timestamp }, index) => {
        if (!author) {
          console.warn(`There is no ${label} author`);
          return;
        }

        return (
          <div {...s('recordChangeLogItem')} key={index}>
            <span>{label} </span>
            <span {...s('author')}>
              {getRecordLabel({ object: author!, type: 'author' })}
            </span>
            <span> on {formatDateTime(timestamp!)}</span>
          </div>
        );
      })}
      {shouldShowAuditLogLink && (
        <Link
          to={ROUTES.AUDIT_LOG_LIST}
          hash={`filters=${JSON.stringify({
            auditable_id: [
              {
                op: { value: 'eq', label: 'Equals' },
                value: record?.id
              }
            ]
          })}`}
        >
          Change log
        </Link>
      )}
    </div>
  );
}
