import { BaseModelGeneratorModel } from '@rexlabs/model-generator';
import {
  BaseFields,
  RecordTypes,
  TimestampAttributes,
  ValueListValue
} from 'data/models/types';

const folioCommissionTemplateStructureIds = [
  'flat_fee',
  'percentage',
  'tiered',
  'progressive'
] as const;

export type FolioCommissionTemplateStructure = typeof folioCommissionTemplateStructureIds[number];

type FolioCommissionTemplateTierType = 'percentage' | 'flat_fee';

export type FolioCommissionTemplateTier = {
  from: number;
  to: number | null;
  amount: number;
  type: ValueListValue<FolioCommissionTemplateTierType>;
};

export interface FolioCommissionTemplate
  extends BaseModelGeneratorModel,
    TimestampAttributes,
    BaseFields<typeof RecordTypes.CommissionTemplate> {
  id: string;
  name: string;
  structure_type: ValueListValue<FolioCommissionTemplateStructure>;
  commission_amount: number | null;
  tiers: FolioCommissionTemplateTier[];
}

export const FLAT_FEE: FolioCommissionTemplateStructure = 'flat_fee';
export const PERCENTAGE: FolioCommissionTemplateStructure = 'percentage';
