import { getSearchResultItemFromManagedBy } from '../../common/utils/get-search-result-item-from-managed-by';
import {
  ChecklistTemplate,
  ChecklistTemplateItem
} from '../types/ChecklistTemplate';
import { ChecklistTemplatesForm } from '../types/ChecklistTemplatesForm';

export function mapChecklistTemplateToChecklistTemplateFormData(
  checklistTemplate: Partial<ChecklistTemplate>
): Partial<ChecklistTemplatesForm> {
  const { items, ...rest } = checklistTemplate;
  return {
    ...rest,
    ...(rest.task_type?.id === 'task_inspection' && rest.discriminator_value
      ? {
          inspection_type: rest.discriminator_value
        }
      : {}),
    checklist_template_items: items?.data?.map(mapChecklistTemplateItem) || []
  };
}

// Form states are expecting assignees to be search items, rather than direct models.
export function mapChecklistTemplateItem(
  checklistTemplateItem: ChecklistTemplateItem
): ChecklistTemplateItem {
  return {
    ...checklistTemplateItem,
    assignee:
      checklistTemplateItem.assignee &&
      '__record_type' in checklistTemplateItem.assignee
        ? getSearchResultItemFromManagedBy(checklistTemplateItem.assignee)
        : checklistTemplateItem.assignee
  };
}
