import React from 'react';
import { RecordTable } from 'view/components/table';
import { matchColumns } from '../utils/match-columns';
import { DeSelectMatchActionCell } from './deselect-match-action-cell';

export function SelectedMatchesTable({ data, blockProps }) {
  const {
    availableMatches,
    setAvailableMatches,
    selectedMatches,
    setSelectedMatches
  } = blockProps;

  return (
    <RecordTable
      items={data}
      isLoading={false}
      columns={[
        ...matchColumns,
        {
          id: 'action',
          Cell: DeSelectMatchActionCell,
          accessor: (item) => {
            return {
              item: item,
              selectedMatches,
              setSelectedMatches,
              availableMatches,
              setAvailableMatches
            };
          },
          width: 70,
          sticky: 'right'
        }
      ]}
    />
  );
}
