import { useModelActions } from '@rexlabs/model-generator';
import { push, useWhereabouts } from '@rexlabs/whereabouts';
import ROUTES from 'routes/app';
import { useDeleteAction } from 'src/modules/common/actions/delete/use-delete-action';
import { useEditRecordDetailsAction } from 'src/modules/common/actions/record-details/hooks/use-edit-record-details-action';
import { useStatusChangeAction } from 'src/modules/common/actions/status/use-status-change-action';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useGetCreateEmailAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-email-action';
import { contactsModel } from 'src/modules/contacts/models/contacts';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { useGetDisbursableEntityActionGroup } from 'src/modules/disbursements/hooks/use-get-disbursable-entity-action-group';
import { useGetFinancialEntityActionGroup } from 'src/modules/financials/utils/financial-entity-action-group/use-get-financial-entity-actions';
import { useGetCreateNoteAction } from 'src/modules/note/hooks/use-get-create-note-action';
import { useGetAccountingEntityActionGroup } from 'src/modules/trust-journal-entries/utils/use-get-accounting-entity-action-group';
import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import { useGetCreateSMSAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-sms-action';
import { useGetCreateTodoAction } from 'src/modules/tasks/todos/hooks/action-declarations/use-get-create-todo-action';
import { getSearchResultItemFromObjectAndModel } from 'src/modules/common/utils/search-result-items/get-search-result-items-from-object-and-model';
import { contactIncludes } from '../screens/contact-details';
import { useGetEditAction } from '../utils/contact-actions/use-get-edit-action';

export function useContactActions() {
  const { refreshItem } = useModelActions(contactsModel);
  const whereabouts = useWhereabouts();

  const getEditRecordDetails = useEditRecordDetailsAction(contactsModel);
  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();
  const getEditAction = useGetEditAction();
  const getFinancialEntityActionGroup = useGetFinancialEntityActionGroup();
  const getDisbursableEntityActionGroup = useGetDisbursableEntityActionGroup();
  const getAccountingEntityActionGroup = useGetAccountingEntityActionGroup();
  const getStatusChangeAction = useStatusChangeAction(contactsModel);
  const deleteAction = useDeleteAction(contactsModel);
  const getCreateNoteAction = useGetCreateNoteAction(contactsModel);
  const getCreateEmailAction = useGetCreateEmailAction(contactsModel);
  const getCreateSMSAction = useGetCreateSMSAction(contactsModel);
  const getCreateTodoAction = useGetCreateTodoAction();

  const deleteActionConfig =
    whereabouts.path === ROUTES.CONTACTS_LIST.config.path
      ? {}
      : {
          afterAction: () => push(ROUTES.CONTACTS_LIST)
        };

  return (contact?: Contact) => {
    if (!contact) return [];

    const statusChangeActions: ActionDeclaration[] = [];

    switch (contact.status.id) {
      case 'active':
        if (!contact.is_agency) {
          statusChangeActions.push(
            getStatusChangeAction({
              record: contact,
              status: 'archived',
              dialogOptions: {
                label: 'Archive'
              }
            })
          );
        }

        break;

      case 'archived':
        statusChangeActions.push(
          getStatusChangeAction({
            record: contact,
            status: 'active',
            dialogOptions: { label: 'Activate' }
          })
        );
        break;
    }

    return [
      ...getPrimaryRecordActionGroup('contact', contact.id),
      ...getFinancialEntityActionGroup({
        actionArgs: {
          recordTypeName: contact.display_name,
          entity: contact,
          onCreate: async () =>
            refreshItem({
              id: contact.id,
              args: { include: contactIncludes }
            })
        }
      }),
      getEditRecordDetails(contact),
      getCreateTodoAction({
        initialValues: {
          relates_to: getSearchResultItemFromObjectAndModel(
            contact,
            contactsModel
          )
        }
      }),
      getCreateNoteAction({ attached: contact }),
      getCreateEmailAction(contact),
      getCreateSMSAction(contact),
      getEditAction(),
      ...getDisbursableEntityActionGroup(),
      ...getAccountingEntityActionGroup(),
      ...statusChangeActions,
      ...(contact.is_agency ? [] : [deleteAction(contact, deleteActionConfig)])
    ];
  };
}
