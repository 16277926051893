import React, { useMemo } from 'react';
import { useEntityQuery } from '@rexlabs/model-generator';

import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { RecordScreen } from 'view/components/record-screen';

import { useRecordScreenSubmitHandler } from 'view/hooks/use-record-screen-submit-handler';
import { TrustAccount } from 'data/models/entities/financials/trust-accounts';
import { content } from '../data/content';
import { getTrustAccountQuery } from '../data/queries';
import { TrustAccountTitleBlock } from '../components/trust-account-title-block';

interface TrustAccountDetailsScreenProps {
  trustAccountId: string;
}

export function TrustAccountDetailsScreen({
  trustAccountId
}: TrustAccountDetailsScreenProps) {
  const breadcrumbs = [{ type: BREADCRUMBS.TRUST_ACCOUNT }];
  const query = useMemo(() => getTrustAccountQuery(trustAccountId), [
    trustAccountId
  ]);
  const { data, status, actions } = useEntityQuery(query);

  const handleSubmit = useRecordScreenSubmitHandler(({ changedValues }) =>
    actions.updateItem({
      id: trustAccountId,
      data: changedValues
    })
  );

  return (
    <RecordScreen
      // TODO: does this need a priv?
      isLoading={status !== 'loaded'}
      data={data}
      handleSubmit={handleSubmit}
      content={content}
      titleBlock={
        <TrustAccountTitleBlock trustAccount={data as TrustAccount} />
      }
      breadcrumbs={breadcrumbs}
    />
  );
}
