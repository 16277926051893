export const PORTFOLIO_ROLE_ROUTES = {
  PORTFOLIO_ROLE_LIST: {
    config: {
      path: '/portfolio-roles',
      Component: require('./screens/portfolio-role-list.tsx').PortfolioRoleList
    }
  },
  PORTFOLIO_ROLE_DETAILS: {
    config: {
      path: '/portfolio-role/:portfolioRoleId',
      Component: require('./screens/portfolio-role-details.tsx')
        .PortfolioRoleDetails
    }
  }
};
