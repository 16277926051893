import { useMemo } from 'react';

import { formatCurrency } from 'utils/formatters';

import { Stat } from 'view/components/@luna/stat-block/stat-blocks';

import { useBatchReceiptingStatsSummary } from './use-batch-receipting-stats-summary';

function getValueTag(value = 0) {
  return `${value} ${value === 1 ? 'transaction' : 'transactions'}`;
}

export function useBatchReceiptingStatBlocksConfig(
  bankAccountId: string,
  transactionFileId?: string
): { stats: Stat[]; matchedReceiptCount: number } {
  const { data: statsSummary, isLoading } = useBatchReceiptingStatsSummary(
    bankAccountId,
    transactionFileId
  );

  return useMemo(() => {
    if (isLoading) return { stats: [], matchedReceiptCount: 0 };

    return {
      stats: [
        {
          label: 'Unreceipted',
          value: formatCurrency(statsSummary?.data?.not_matched_sum || 0),
          intent: 'secondary',
          valueTag: getValueTag(statsSummary?.data?.not_matched_count)
        },
        {
          label: 'Matched',
          value: formatCurrency(statsSummary?.data?.matched_sum || 0),
          intent: 'success',
          valueTag: getValueTag(statsSummary?.data?.matched_count)
        },
        {
          label: 'Suspended',
          value: formatCurrency(statsSummary?.data?.suspended_sum || 0),
          intent: 'warning',
          valueTag: getValueTag(statsSummary?.data?.suspended_count)
        }
      ],
      matchedReceiptCount: statsSummary!.data.matched_receipt_count
    };
  }, [statsSummary, isLoading]);
}
