import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { Property } from 'src/modules/properties/types/property-types';
import { SearchResultItem } from 'utils/api/get-search-results';
import { Tenancy } from 'data/models/entities/tenancies';
import { Ownership } from 'data/models/entities/ownerships';
import { PropertyOwnership } from 'src/modules/property-ownerships/types/property-ownership-types';
import { PropertyTenancyModel } from 'src/modules/property-tenancies/types/property-tenancy-types';
import { useCreateInvoiceDialog } from 'src/modules/invoices/hooks/use-create-invoice-dialog';

export function useGetCreateInvoiceFromProperty() {
  const openInvoiceDialog = useCreateInvoiceDialog();

  return (property: Property): SingleActionDeclaration => {
    const suggestedItems = getOwnershipAndTenancySearchResults(
      property.property_ownerships?.items,
      property.property_tenancies?.items
    );

    return {
      id: 'create-invoice-from-property',
      label: 'Create invoice',
      handleAction: () => {
        openInvoiceDialog({
          initialValues: {},
          suggestions: {
            payableBy: suggestedItems,
            payableTo: suggestedItems
          }
        });
      },
      group: 'create-invoice'
    };
  };
}

function getOwnershipAndTenancySearchResults(
  propertyOwnerships: Array<PropertyOwnership> = [],
  propertyTenancies: Array<PropertyTenancyModel> = []
) {
  const results: Array<SearchResultItem<Ownership | Tenancy>> = [];

  for (const item of propertyOwnerships) {
    const record = item.ownership;
    if (!record) return;
    results.push({
      id: record.id,
      label: record.display_name,
      record,
      type: {
        id: 'ownership',
        label: 'Ownership'
      }
    });
  }

  for (const item of propertyTenancies) {
    const record = item.tenancy;

    if (!record) return;

    results.push({
      id: record.id,
      label: record.display_name,
      record,
      type: {
        id: 'tenancy',
        label: 'Tenancy'
      }
    });
  }

  return results;
}
