import { useDialog } from '@rexlabs/dialog';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { EditCustomValidationDialog } from '../../dialogs/edit-custom-validation-dialog';
import { CustomValidation } from '../../types/CustomValidation';

export function useGetEditCustomValidationAction() {
  const { open: openEditCustomValidationDialog } = useDialog(
    EditCustomValidationDialog
  );

  return (customValidation: CustomValidation): ActionDeclaration => {
    return {
      id: 'edit-custom-validation',
      group: 'custom-validations',
      label: 'Edit Custom Validation',
      handleAction: async () => {
        openEditCustomValidationDialog({
          customValidation
        });
      }
    };
  };
}
