import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';
import Box from '@rexlabs/box';
import { Heading } from '@rexlabs/text';
import { startCase } from 'lodash';
import { PrivilegeCategory, usePrivilegeList } from '../hooks/use-privileges';
import { Role } from '../types/Role';
import { PrivilegesTable } from '../components/privileges-table';

const validate = {
  definitions: {}
};

const categories: PrivilegeCategory[] = [
  'admin_resources',
  'basic_resources',
  'complex_resources',
  'task_resources',
  'financial_resources'
];

export const rolePrivilegesBlock: BlockConfig<Role> = {
  id: 'privileges',
  title: 'Privileges',
  validate,
  isEditable: (role: Role) => !role?.system_purpose?.id,
  Edit: () => {
    const { data } = usePrivilegeList();
    return (
      <Box>
        {categories.map((category) => (
          <Box marginBottom={36} key={category}>
            <Heading level={4}>{startCase(category)}</Heading>
            <PrivilegesTable data={data ?? []} category={category} />
          </Box>
        ))}
      </Box>
    );
  },
  View: () => {
    const { data } = usePrivilegeList();
    return (
      <Box sy='6rem'>
        {categories.map((category) => (
          <Box marginBottom={36} key={category}>
            <Heading level={4}>{startCase(category)}</Heading>
            <PrivilegesTable data={data ?? []} category={category} readonly />
          </Box>
        ))}
      </Box>
    );
  }
};
