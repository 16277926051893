import React from 'react';
import { GhostButton, OutlineButton, PrimaryButton } from '@rexlabs/button';

import { ButtonGroupProps } from 'view/components/record-screen/dialog/types';
import { StandardDialogFooter } from 'view/components/dialogs/components/standard-dialog-footer';
import ReceiptIcon from 'view/components/icons/receipt';
import { formatCurrency, pluralize } from 'utils/formatters';
import InfoCircleIcon from 'view/components/icons/info';
import { TrustJournalEntry } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';
import { BankStatementTransactionImport } from '../types/bank-statement-transaction-import';

export interface MatchToExistingButtonGroupProps extends ButtonGroupProps {
  closeParentDialog?: () => void;
  selectedMatches: Array<TrustJournalEntry | BankStatementTransactionImport>;
  amount: number;
}

function getFundsLeftToMatch({
  selectedMatches,
  amount
}: {
  selectedMatches: Array<TrustJournalEntry | BankStatementTransactionImport>;
  amount: number;
}) {
  const fundsMatched: number = selectedMatches?.reduce(
    (previousTotal, currentMatch) => {
      const currentAmount =
        currentMatch.__record_type === 'trust_journal_entry'
          ? currentMatch.amount
          : currentMatch.total_amount;

      return previousTotal + currentAmount;
    },
    0
  );

  return amount - fundsMatched;
}

function getSubmitButtonLabel({
  fundsLeftToMatch,
  selectedMatchCount
}: {
  fundsLeftToMatch: number;
  selectedMatchCount: number;
}) {
  if (fundsLeftToMatch === 0) {
    return `Match ${pluralize('receipts', selectedMatchCount, true)}`;
  }

  return `${formatCurrency(fundsLeftToMatch)} funds left to match`;
}

export function MatchToExistingButtonGroup({
  handleSubmit,
  onClose,
  closeParentDialog,
  isSubmitting,
  selectedMatches,
  amount
}: MatchToExistingButtonGroupProps) {
  const handleSubmitAndClose = async () => {
    const result = await handleSubmit();

    if (!result) return;

    onClose?.();

    closeParentDialog?.();
  };

  const fundsLeftToMatch = getFundsLeftToMatch({ selectedMatches, amount });

  return (
    <StandardDialogFooter
      left={
        <OutlineButton IconLeft={ReceiptIcon} onClick={() => onClose?.()}>
          Receipt funds
        </OutlineButton>
      }
    >
      <GhostButton onClick={onClose}>Cancel</GhostButton>
      <PrimaryButton
        IconLeft={InfoCircleIcon}
        onClick={handleSubmitAndClose}
        isLoading={isSubmitting}
        isDisabled={fundsLeftToMatch != 0}
      >
        {getSubmitButtonLabel({
          fundsLeftToMatch,
          selectedMatchCount: selectedMatches.length
        })}
      </PrimaryButton>
    </StandardDialogFooter>
  );
}
