import { Box } from '@rexlabs/box';
import { LinkButton } from '@rexlabs/button';
import { ReactFormsProps } from '@rexlabs/form';
import { Invoice } from 'data/models/entities/financials/invoices';
import React from 'react';
import AddIcon from 'view/components/icons/add';
import { BlockConfig } from 'view/components/record-screen/types';
import { CommissionCalculatorPreview } from 'src/modules/supplier-commission/types/CommissionCalculatorPreview';
import { InvoiceLineItemFormValues } from '../components/invoice-line-item';
import { InvoiceLineItems } from '../components/invoice-line-items';
import { InvoiceLineItemsForm } from '../components/invoice-line-items-form';
import '../utils/invoice-validation-rules';
import { CommissionRuleFormValue } from '../types/CommissionRuleFormValue';
import { InvoiceBlockProps } from '../types/invoice-block';

type ValidationRules = ReactFormsProps<any, any>['validate'];

// NOTE: This is an attempt to make sure that the keys are synced where they are used.
// I've noticed that when we need to know the key (to set field values, for example), if the
// key changes, there is no link between the key in the form, and the string we use to find the
// key when we need to set the value. This is an attempt to make sure that the keys are synced
type CommissionPreviewField = {
  commission_preview?: CommissionCalculatorPreview;
};
type CommissionPreviewFieldKey = keyof CommissionPreviewField;
export const COMMISSION_PREVIEW_FIELD_KEY: CommissionPreviewFieldKey =
  'commission_preview';

type CommissionRuleField = {
  commission_rule?: CommissionRuleFormValue;
};
type CommissionRuleFieldKey = keyof CommissionRuleField;
export const COMMISSION_RULE_FIELD_KEY: CommissionRuleFieldKey =
  'commission_rule';

export type InvoiceLineItemsFormValues = CommissionPreviewField &
  CommissionRuleField & {
    line_items: InvoiceLineItemFormValues[];
  };

const defaultValidationRules = {
  definitions: {
    'line_items.*.description': { name: 'description', rules: 'required' },
    'line_items.*.tax_type': { name: 'tax type', rules: 'required' },
    'line_items.*.amount': { name: 'amount', rules: 'required' },
    'line_items.*.payable_to_chart_of_accounts_account': {
      rules: 'required_if_true:is_payable_to_COA_needed',
      name: 'invoice from account code'
    },
    'line_items.*.payable_to_property': {
      rules: 'required_if_true:is_payable_to_property_needed',
      name: 'invoice from property'
    },
    'line_items.*.payable_by_chart_of_accounts_account': {
      rules: 'required_if_true:is_payable_by_COA_needed',
      name: 'bill to account code'
    },
    'line_items.*.payable_by_property': {
      rules: 'required_if_true:is_payable_by_property_needed',
      name: 'bill to property'
    }
  }
};

function getInvoiceLineItemsBlock(
  validate: ValidationRules = defaultValidationRules
): BlockConfig<Invoice, InvoiceBlockProps, InvoiceLineItemsFormValues> {
  return {
    id: 'line-items',
    validate,
    View: ({ data }) => {
      return <InvoiceLineItems data={data} />;
    },
    Edit: function EditLineItems({ forms, blockProps, values, ...props }) {
      const hasLineItems = (values?.line_items || [])?.length > 0;

      const handleReimbursementInvoice = () => {
        if (!blockProps?.isFirstChange) {
          forms!['reimbursement-details'].setFieldValue(
            'reimbursed_bill_priority',
            forms!['invoice-details'].values.bill_priority
          );

          forms!['reimbursement-details'].setFieldValue(
            'reimbursed_bank_account',
            forms!['invoice-details'].values.bank_account
          );
        }

        blockProps?.handleFirstChange();

        blockProps?.setActiveTab('reimbursement');

        const tab = document.querySelector('a[data-tab-name="reimbursement"]');

        if (!tab) return;

        tab.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      };

      return (
        <div
          style={{
            display: blockProps?.activeTab === 'invoice' ? 'block' : 'none'
          }}
        >
          <InvoiceLineItemsForm
            {...props}
            blockId='line-items'
            values={values}
            payableBy={forms?.['invoice-details']?.values.payable_by?.object}
            payableTo={forms?.['invoice-details']?.values.payable_to?.object}
            invoiceLineItemColumns={2}
            dialogType={blockProps?.dialogType}
          />

          {hasLineItems && blockProps?.dialogType != 'draft' && (
            <Box justifyContent='flex-end' marginTop={12}>
              <LinkButton
                IconLeft={AddIcon}
                onClick={handleReimbursementInvoice}
              >
                Add reimbursement invoice
              </LinkButton>
            </Box>
          )}
        </div>
      );
    }
  };
}

export const invoiceLineItemsBlock = getInvoiceLineItemsBlock();

export const useInvoiceLineItemsBlock = (validate?: ValidationRules) =>
  React.useMemo(() => getInvoiceLineItemsBlock(validate), []);
