import React, { CSSProperties } from 'react';

import Box from '@rexlabs/box';
import { useStyles, StyleSheet, border } from '@rexlabs/styling';
import { RecordStatus } from '../types';

const defaultStyles = StyleSheet({
  container: {
    height: '1.6rem',
    width: '1.6rem'
  },

  indicator: {
    height: '.8rem',
    width: '.8rem',

    ...border.styles({
      all: {
        radius: 'circle'
      }
    })
  },

  active: {
    backgroundColor: ({ token }) => token('color.success.idle.default')
  },

  pending: {
    backgroundColor: ({ token }) => token('color.warning.idle.default')
  }
});

interface StatusIndicatorProps {
  status: RecordStatus;
  style?: CSSProperties;
  className?: string;
}

export function StatusIndicator({
  status,
  style,
  className
}: StatusIndicatorProps) {
  const s = useStyles(defaultStyles);

  return (
    <Box
      {...s.with('container')({ style, className })}
      alignItems='center'
      justifyContent='center'
    >
      <div {...s('indicator', statusMap(status))} />
    </Box>
  );
}

function statusMap(status: RecordStatus): 'active' | 'pending' {
  switch (status) {
    case 'active':
      return 'active';
    case 'incoming':
    case 'outgoing':
    case 'draft':
      return 'pending';
  }
}
