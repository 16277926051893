import React from 'react';
import { DialogProps, useErrorDialog } from '@rexlabs/dialog';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useRecordCreatedToast } from 'src/modules/common/toasts/hooks/use-record-created-toast';
import { useMutation, useQueryClient } from 'react-query';
import { api } from 'utils/api/api-client';
import { CUSTOM_VALIDATIONS_QUERY_KEY } from 'src/modules/custom-validations/hooks/use-custom-validations';
import { CustomValidation } from '../types/CustomValidation';
import { customValidationDetailsBlock } from '../blocks/custom-validation-details-block';

export interface CreateCustomValidationDialogProps extends DialogProps {
  onClose: () => void;
}

const content = [
  {
    id: 'add-custom-validation',
    label: 'Add Custom Validation',
    blocks: [customValidationDetailsBlock]
  }
];

export function CreateCustomValidationDialog({
  onClose
}: CreateCustomValidationDialogProps) {
  const queryClient = useQueryClient();
  const { open: openErrorDialog } = useErrorDialog();
  const addToast = useRecordCreatedToast('custom_validation', {
    actions: []
  });

  const createCustomValidation = useMutation(
    (
      newValidation: Omit<CustomValidation, 'id' | 'created_at' | 'updated_at'>
    ) => api.post<CustomValidation>('/custom-validations', newValidation),
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(CUSTOM_VALIDATIONS_QUERY_KEY);
        const newValidation = response.data;
        addToast(newValidation);
        onClose();
      },
      onError: (error) => {
        openErrorDialog(error);
      }
    }
  );

  const handleSubmit: RecordSubmitHandler<
    Omit<CustomValidation, 'id' | 'created_at' | 'updated_at'>
  > = async ({ values }) => {
    await createCustomValidation.mutateAsync(values);
  };

  const initialValues: Omit<
    CustomValidation,
    | 'id'
    | 'created_at'
    | 'updated_at'
    | 'created_by'
    | 'updated_by'
    | 'effective_validation_type'
  > = {
    name: '',
    description: '',
    record_type: { id: 'contact', label: 'Contact' },
    trigger_type: { id: 'created', label: 'Created' },
    ignore_for_roles: [],
    treat_as_warning_for_roles: [],
    is_enabled: true,
    include: '',
    validation_type: { id: 'error', label: 'Error' },
    code: 'module.exports = function (context) {\n  \n};',
    default_message: ''
  };

  return (
    <RecordDialog
      title='Create Custom Validation'
      content={content}
      onClose={onClose}
      submitLabel='Create Custom Validation'
      handleSubmit={handleSubmit}
      initialValues={initialValues}
    />
  );
}
