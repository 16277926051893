import { useDialog } from '@rexlabs/dialog';

import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { FinancialEntity } from 'src/modules/financials/utils/financial-entity-action-group/use-get-financial-entity-actions';

import { CreateManualDeferredFeeEntryDialog } from '../../dialogs/create-manual-deferred-fee-entry-dialog';
import { DeferredFeeTypeId } from '../../types/DeferredFeeTypeId';
import { mapManualJournalEntryFormDataToCreateDeferredFeeRequest } from '../../mappers/map-manual-journal-entry-form-data-to-create-deferred-fee-request';
import { useDeferredFeeSubmitHandler } from '../use-deferred-fee-submit-handler';

type CreateManualDeferredFeeEntryActionProps = {
  folio: FinancialEntity;
  entryType: DeferredFeeTypeId;
};

export function useGetCreateManualDeferredFeeEntryAction() {
  const { open: openCreateDialog } = useDialog(
    CreateManualDeferredFeeEntryDialog
  );

  const deferredFeeSubmitHandler = useDeferredFeeSubmitHandler();

  return ({
    entryType,
    folio
  }: CreateManualDeferredFeeEntryActionProps): SingleActionDeclaration => ({
    label: 'Create entry',
    handleAction: () => {
      openCreateDialog({
        folio,
        entryType,
        submitHandler: async ({ values }) => {
          const dataToSubmit = mapManualJournalEntryFormDataToCreateDeferredFeeRequest(
            entryType,
            values
          );

          const journalEntry = await deferredFeeSubmitHandler({
            payload: dataToSubmit
          });

          return journalEntry;
        }
      });
    }
  });
}
