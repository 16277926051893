/**
 * This assumes that a column has an include if there is a dot in the key.
 * foo.bar -> foo is the include
 * foo.bar.baz -> foo.bar is the include
 */
export function getIncludesFromColumnIds(columnIds: string[]): string[] {
  return columnIds.reduce((acc, columnId) => {
    const include = getIncludeFromColumnId(columnId);
    if (include && acc.indexOf(include) === -1) {
      return [...acc, include];
    }
    return acc;
  }, [] as string[]);
}

export function getIncludeFromColumnId(columnId: string): string | null {
  // split on the last dot, if there is more than one. everything before the final dot is the include
  const lastDotIndex = columnId.lastIndexOf('.');
  if (lastDotIndex !== -1) {
    return columnId.slice(0, lastDotIndex);
  }
  return null;
}

// TODO: tests
