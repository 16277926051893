import React from 'react';
import { lowerCase, noop } from 'lodash';

import { Dialog, DialogProps } from '@rexlabs/dialog';
import { PrimaryButton } from '@rexlabs/button';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { PmApiError } from 'utils/api/create-error-from-response';

import { LockClosedIcon } from '../icons/lock-closed';
import { StandardDialogFooter } from './components/standard-dialog-footer';

const defaultStyles = StyleSheet({
  list: {
    listStyleType: 'disc',
    paddingLeft: ({ token }) => token('spacing.l')
  }
});

type NoPermissionToPerformActionDialogProps = DialogProps & {
  error: PmApiError;
};
export function NoPermissionToPerformActionDialog(
  props: NoPermissionToPerformActionDialogProps
) {
  const s = useStyles(defaultStyles);
  const { onClose = noop, error } = props;

  const { requiredPrivileges } = error;

  return (
    <Dialog
      title="You don't have permission"
      TitleIcon={LockClosedIcon}
      size='s'
    >
      <Box>
        <p>
          {`You don't have permission to access this feature. If you think you
          should have access, please contact your system administrator.`}
        </p>
        <p>
          This feature requires:
          <ul {...s('list')}>
            {requiredPrivileges?.map((privilege) => {
              const privilegeArray = privilege.split('.');
              const priv = privilegeArray.pop();
              const feature = privilegeArray.map(lowerCase).join(' ');

              return (
                <li key={privilege}>
                  <b>{priv && lowerCase(privilegeMap(priv))}</b> privileges for{' '}
                  <b>{lowerCase(feature)}</b>
                </li>
              );
            })}
          </ul>
        </p>
      </Box>
      <Box></Box>
      <StandardDialogFooter>
        <PrimaryButton onClick={onClose}>Ok, I understand</PrimaryButton>
      </StandardDialogFooter>
    </Dialog>
  );
}

// At this stage, BE returns a 'read' privilege but on the FE we want to show 'view' instead
function privilegeMap(privilege: string) {
  switch (privilege) {
    case 'read':
      return 'view';
    default:
      return privilege;
  }
}
