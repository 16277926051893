import { Generator } from 'data/models/generator';
import { auditableConfig } from 'src/modules/common/auditable/auditable-config';
import { UserGroup } from '../types/UserGroup';

const config = {
  entities: {
    related: {
      ...auditableConfig,
      users: {
        include: 'users,users.contact,users.role'
      }
    }
  }
};

export const userGroupsModel = new Generator<UserGroup>(
  'user-groups',
  config
).createEntityModel();
