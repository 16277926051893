import { pluralize } from 'utils/formatters';

export function getReceiptDescription(
  rentInvoicesCount: number,
  otherInvoiceCount: number
) {
  const rentString = rentInvoicesCount ? 'Rent' : '';
  const invoiceString =
    otherInvoiceCount === 0
      ? ''
      : pluralize('invoice', otherInvoiceCount, true);

  return rentString + (rentString && invoiceString ? ', ' : '') + invoiceString;
}
