import React, { useEffect } from 'react';
import { Field } from '@rexlabs/form';
import { TextArea, TextInput } from '@rexlabs/text-input';

import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { Value } from 'view/components/values';
import { PropertySelect } from 'view/components/inputs/selects/v4-selects/property-select';
import { MaintenanceStatusSelect } from '../components/maintenance-status-select';
import { MaintenanceTask } from '../types/MaintenanceTask';
import { MaintenanceFormData } from '../types/MaintenanceFormData';
import { onPropertyChangeHandler } from '../../common/utils/on-property-change-handler';
import { ManagedBySelect } from '../../common/components/managed-by-select';
import { getLabelForManagedBy } from '../../common/utils/get-label-for-managed-by';

const validate = {
  definitions: {
    summary: { rules: 'required|max:255' },
    property: { rules: 'required' },
    managed_by: { rules: 'required' },
    'details.status': { rules: 'required', name: 'status' }
  }
};

export const detailsBlock: BlockConfig<
  MaintenanceTask,
  any,
  MaintenanceFormData
> = {
  id: 'details',
  title: 'Details',
  validate,
  View: ({ data }) => {
    return (
      <Grid columns={1}>
        <Value label='Description' value={data?.description} />

        <Grid columns={2}>
          <Value label='Property' value={data?.property?.address_string} />
        </Grid>

        <Grid columns={2}>
          <Value
            label='Managed by'
            value={getLabelForManagedBy(data?.managed_by)}
          />
        </Grid>
      </Grid>
    );
  },
  Edit: ({ editMode, values, setFieldValue }) => {
    useEffect(() => {
      onPropertyChangeHandler({
        values,
        setFieldValue,
        taskType: 'task_maintenance'
      });
    }, [values?.property]);

    return (
      <Grid columns={1}>
        <Grid columns={2}>
          <Field name='summary' label='Summary' Input={TextInput} />
        </Grid>

        <Field name='description' label='Description' Input={TextArea} />

        <Grid columns={2}>
          {editMode ? (
            <Value label='Property' value={values?.property?.address_string} />
          ) : (
            <Field
              id='property'
              name='property'
              label='Property'
              Input={PropertySelect}
            />
          )}
        </Grid>

        <Grid columns={editMode ? 2 : 4}>
          <Field
            id='managed_by'
            name='managed_by'
            label='Managed by'
            Input={ManagedBySelect}
          />
          {editMode ? null : (
            <Field
              id='status'
              name='details.status'
              label='Progress'
              Input={MaintenanceStatusSelect}
            />
          )}
        </Grid>
      </Grid>
    );
  }
};
