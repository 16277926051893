import React from 'react';

import Box from '@rexlabs/box';
import { Field } from '@rexlabs/form';
import { TextArea } from '@rexlabs/text-input';
import { Checkbox } from '@rexlabs/checkbox';

import { RecordTypes } from 'data/models/types';

import { AccountingJournalEntryPropertySelect } from 'src/modules/account-journal-entries/components/accounting-journal-entry-property-select';

import { Grid } from 'view/components/@luna/form/grid';
import { DateInput } from 'view/components/@luna/inputs/date-input/date-input';
import { BlockConfig } from 'view/components/record-screen/types';
import { CentAmountInput } from 'view/components/inputs/cent-amount';
import { TaxTypeSelect } from 'view/components/inputs/selects/tax-type';
import { ChartOfAccountsAccountSelect } from 'view/components/inputs/selects/v4-selects/chart-of-accounts-account';
import { Value } from 'view/components/values';

import { CreateManualJournalEntryFormData } from '../types/CreateManualJournalEntryFormData';

export function getCreateManualJournalEntryBlock(
  includePayableBy?: boolean
): BlockConfig<any, any, CreateManualJournalEntryFormData> {
  return {
    id: 'create-manual-accounting-journal-entry',
    validate: {
      definitions: {
        date_of: {
          rules: 'required',
          name: 'date'
        },
        description: {
          rules: 'required'
        },
        ...(includePayableBy
          ? {
              payable_by_chart_of_accounts_account: {
                rules: 'required',
                name: 'ownership expense account'
              },
              account: {
                rules: 'required',
                name: 'agency income account'
              }
            }
          : {
              account: {
                rules: 'required'
              }
            }),
        tax_type: {
          rules: 'required',
          name: 'tax type'
        },
        amount: {
          rules: 'required'
        }
      }
    },
    Edit: ({ values, setFieldValue }) => {
      return (
        <Box>
          <Grid columns={1}>
            <Grid columns={2}>
              <Value label='Folio' value={values?.folio.display_name} />
              <Field label='Date' name='date_of' Input={DateInput} />
              {values?.folio.__record_type === RecordTypes.Ownership && (
                <Field
                  label='Property'
                  name='property'
                  Input={AccountingJournalEntryPropertySelect}
                  inputProps={{
                    folioId: values.folio.id
                  }}
                />
              )}
            </Grid>

            <Field label='Description' name='description' Input={TextArea} />

            <Grid columns={2}>
              {includePayableBy ? (
                <>
                  <Field
                    id={'payable_by_chart_of_accounts_account'}
                    label='Ownership expense account'
                    name='payable_by_chart_of_accounts_account'
                    Input={ChartOfAccountsAccountSelect}
                    inputProps={{
                      filterOnChartOfAccountCategories: ['expense'],
                      disableFixture: false
                    }}
                    onChange={(e: any) =>
                      setFieldValue?.(
                        'tax_type',
                        e.target.value.default_tax_type
                      )
                    }
                  />
                  <Field
                    id={'payable_to_chart_of_accounts_account'}
                    label='Agency income account'
                    name='account'
                    Input={ChartOfAccountsAccountSelect}
                    inputProps={{
                      filterOnChartOfAccountCategories: ['income'],
                      disableFixture: false
                    }}
                  />
                </>
              ) : (
                <Field
                  id={'chart-of-accounts-account'}
                  label='Account'
                  name='account'
                  Input={ChartOfAccountsAccountSelect}
                  inputProps={{
                    filterOnChartOfAccountCategories: ['income', 'expense'],
                    disableFixture: false
                  }}
                  onChange={(e: any) =>
                    setFieldValue?.('tax_type', e.target.value.default_tax_type)
                  }
                />
              )}
            </Grid>

            <Grid columns={2}>
              <Field label='Tax type' name='tax_type' Input={TaxTypeSelect} />
              <Field label='Amount' name='amount' Input={CentAmountInput} />
              <Field
                name='is_tax_included'
                Input={Checkbox}
                optional={false}
                inputProps={{
                  label: 'Tax is included in the amount'
                }}
              />
            </Grid>
          </Grid>
        </Box>
      );
    }
  };
}
