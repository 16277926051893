import React from 'react';
import { Select } from '@rexlabs/select';
import { useCountryRegions } from 'src/modules/bank-accounts/hooks/use-country-regions';
import { ValueListSelectProps } from '../value-list';

export function CountryRegionSelect(
  props: ValueListSelectProps & { selectedCountryId?: string }
) {
  const selectedCountryId = props?.selectedCountryId;

  const items = useCountryRegions(selectedCountryId);

  return <Select {...props} items={items} normaliser={(val) => val} />;
}
