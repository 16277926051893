import * as React from 'react';
import dayjs from 'dayjs';
import { query, useModelActions } from '@rexlabs/model-generator';

import { Card } from 'view/components/card';
import { ListTable, TabbedTable } from 'view/components/table';
import { ListScreen } from 'view/components/list-screen/list-screen';
import { useTableFilters } from 'view/hooks/use-table-filters';

import {
  useAllTasksColumns,
  useTasksWithAssignedSubtasksColumns
} from 'src/modules/tasks/common/hooks/use-all-tasks-columns';
import { getRecordLinkProps } from 'view/components/record-link/get-record-link-props';
import { useGetAnyTaskActions } from 'src/modules/tasks/common/hooks/action-menu-items/use-get-any-task-actions';
import { useGetCreateTaskAction } from 'src/modules/tasks/common/hooks/action-declarations/use-get-create-task-action';
import { useAppWideFilterContext } from 'src/modules/app-wide-filters/contexts/app-wide-filter-context';
import { useGetBulkEditAction } from 'src/modules/bulk-edit/hooks/use-get-bulk-edit-action';
import { EmptyState } from 'view/components/states/compact/empty';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import invariant from 'invariant';
import { makeAllTaskPrivilegesArray } from 'src/modules/authorization/roles/hooks/use-privileges';
import { Tab } from 'view/components/table/tabbed';
import { tasksModel } from '../../common/models/tasks-model';
import { TaskTableEmptyState } from '../../common/components/task-table-empty-state';
import { ChecklistItem } from '../types/Checklist';
import { checklistItemsModel } from '../../checklists/models/checklist-items-model';
import { subtaskDueColumns } from '../data/subtask-due-columns';
import { initialHiddenColumns } from '../utils/initial-hidden-columns';
import { useChecklistItemAssigneeInfo } from '../../checklists/hooks/use-checklist-item-assignee-info';

const filterOpen = {
  field: 'closed_at',
  op: 'eq',
  value: 'null'
};

const filterClosed = {
  field: 'closed_at',
  op: 'neq',
  value: 'null'
};

export const taskQuery = query`{
  ${tasksModel} {
    id
    status
    follow_up_date
    due_date
    managed_by
    property
    type
    details
    leaseReviewDetails
    maintenanceDetails
    inspectionDetails
    moveInDetails
    moveOutDetails
    arrearsDetails
    propertyComplianceDetails
    supplierComplianceDetails
    quoteDetails
    workOrderDetails
    task_links
    created_by
  }
}`;

export const subtaskQuery = query`{
  ${checklistItemsModel} {
    checklist {
      task {
        property
        managed_by
      }
    }
  }
}`;

const tasksWithSubtasksQuery = query`{
  ${tasksModel} {
    checklists
    subtask_summary
    managed_by
    created_by
  }
}`;

const getSubtaskRowLinkProps = ({ item }: { item: ChecklistItem }) => {
  // TODO: see how much effort it is to turn this into a drawer.
  // Checklist items don't have their own page, so instead we will go to the task page, with the correct tab and hash
  invariant(item.checklist?.task, 'Checklist item must have a task');

  const linkProps = getRecordLinkProps({
    type: item.checklist?.task?.type.id,
    id: item.checklist?.task?.id
  });
  if (!linkProps) {
    return null;
  }
  linkProps.query = { tab: 'checklist' };
  linkProps.hash = item.checklist.id;
  return linkProps;
};

export function TaskList() {
  const { hasFeature } = useFeatureFlags();
  const { appWideFilterKey } = useAppWideFilterContext();
  const getRowLinkProps = ({ item }) => {
    return getRecordLinkProps({ type: item.type.id, id: item.id });
  };

  const { refreshLists } = useModelActions(tasksModel);
  const { refreshLists: refreshSubtasks } = useModelActions(
    checklistItemsModel
  );

  const getBulkEditAction = useGetBulkEditAction('task', {
    onSuccess: () => refreshLists(),
    defaultFields: ['managed_by']
  });

  const getSubtaskBulkEditAction = useGetBulkEditAction('checklist_item', {
    onSuccess: () => refreshSubtasks(),
    defaultFields: [
      'assignee' // TODO: need to add this to backend describer
    ]
  });

  const getCreateAction = useGetCreateTaskAction();
  const getActions = useGetAnyTaskActions();
  const getActionMenuItems = React.useCallback(({ item }) => getActions(item), [
    getActions
  ]);

  const { getSort, getFilters } = useTableFilters('tasks');
  const {
    getSort: getSubtaskSort,
    getFilters: getSubtaskFilters
  } = useTableFilters('checklist-items');

  const idInfo = useChecklistItemAssigneeInfo();

  const columns = useAllTasksColumns();

  const tasksWithAssignedSubtasksColumns = useTasksWithAssignedSubtasksColumns();

  const tabs = React.useMemo(() => {
    const commonProps = {
      id: 'task',
      columns: columns,
      initialHiddenColumns: ['type', ...initialHiddenColumns],
      getQuery: () => taskQuery,
      Table: ListTable,
      getRowLinkProps,
      getActionMenuItems,
      getFilters,
      getSort,
      shouldUseAppWideFilter: true,
      getBulkActions: ({ selectedItems }) => [getBulkEditAction(selectedItems)]
    };

    const subtaskCommonProps = {
      getQuery: () => subtaskQuery,
      Table: ListTable,
      getRowLinkProps: getSubtaskRowLinkProps,
      getFilters: getSubtaskFilters,
      getSort: getSubtaskSort,
      shouldUseAppWideFilter: true
    };

    const now = dayjs().format('YYYY-MM-DD');
    // NOTE: LTE now - not working as expected, so for subtasks we use LT tomorrow
    const tomorrow = dayjs().add(1, 'day').format('YYYY-MM-DD');

    return [
      {
        ...commonProps,
        name: 'open',
        label: 'Open',
        Empty: () => <TaskTableEmptyState label='Open' />,
        forcedGlobalFilter: [filterOpen]
      },
      {
        ...commonProps,
        name: 'follow_up',
        label: 'Follow up',
        Empty: () => <TaskTableEmptyState label='Follow up' />,
        forcedGlobalFilter: [
          filterOpen,
          {
            field: 'follow_up_date',
            op: 'lte',
            value: now
          }
        ]
      },
      {
        ...commonProps,
        name: 'due',
        label: 'Due',
        Empty: () => <TaskTableEmptyState label='Due' />,
        forcedGlobalFilter: [
          filterOpen,
          {
            field: 'due_date',
            op: 'eq',
            value: now
          }
        ]
      },
      {
        ...commonProps,
        name: 'overdue',
        label: 'Overdue',
        Empty: () => <TaskTableEmptyState label='Overdue' />,
        forcedGlobalFilter: [
          filterOpen,
          {
            field: 'due_date',
            op: 'lt',
            value: now
          }
        ]
      },
      {
        ...commonProps,
        name: 'closed',
        label: 'Closed',
        Empty: () => <TaskTableEmptyState label='Closed' />,
        forcedGlobalFilter: [filterClosed]
      },
      ...(hasFeature(FLAGS.SMART_CHECKLISTS_LIST_VIEWS)
        ? [
            {
              ...subtaskCommonProps,
              columns: subtaskDueColumns,
              name: 'due-subtasks',
              label: 'Due subtasks',
              Empty: () => <EmptyState />,
              getBulkActions: ({ selectedItems }) => [
                getSubtaskBulkEditAction(selectedItems)
              ],
              forcedGlobalFilter: [
                {
                  field: 'due_date',
                  op: 'lte',
                  value: tomorrow,
                  logicalOperator: 'and'
                },
                {
                  field: 'completed_at',
                  op: 'eq',
                  value: 'null',
                  logicalOperator: 'and'
                },
                // If we don't have contact info, we are likely a super user, and need to remove this filter, otherwise it will probably crash.
                ...(idInfo.contactId
                  ? [
                      {
                        field: 'assignee_id',
                        op: 'in',
                        value: [
                          ...new Set(idInfo.portfolioRoleIds ?? []),
                          ...new Set(idInfo.userGroupIds ?? []),
                          idInfo.contactId
                        ].filter(Boolean),
                        logicalOperator: 'and'
                      }
                    ]
                  : [])
              ]
            },
            {
              ...commonProps,
              columns: tasksWithAssignedSubtasksColumns,
              name: 'assigned-to-me-subtasks',
              label: 'Subtasks assigned to me',
              Empty: () => <EmptyState />,
              getQuery: () => tasksWithSubtasksQuery,
              forcedGlobalFilter: [
                {
                  field: 'closed_at',
                  op: 'eq',
                  value: 'null'
                },
                // If we don't have contact info, we are likely a super user, and need to remove this filter, otherwise it will probably crash.
                ...(idInfo.contactId
                  ? [
                      {
                        field: 'has_subtasks_assigned_to_id',
                        op: 'in',
                        value: [
                          ...new Set(idInfo.portfolioRoleIds ?? []),
                          ...new Set(idInfo.userGroupIds ?? []),
                          idInfo.contactId
                        ].filter(Boolean)
                      }
                    ]
                  : [])
              ]
            }
          ]
        : [])
    ] as Tab[];
  }, [
    columns,
    getActionMenuItems,
    getBulkEditAction,
    getFilters,
    getSort,
    getSubtaskBulkEditAction,
    getSubtaskFilters,
    getSubtaskSort,
    hasFeature,
    idInfo,
    tasksWithAssignedSubtasksColumns
  ]);

  return (
    <ListScreen
      privilege={{
        mode: 'any',
        privileges: makeAllTaskPrivilegesArray('read')
      }}
      title='All tasks'
      recordType='task'
      actions={[getCreateAction()]}
      key={appWideFilterKey}
    >
      <Card>
        <TabbedTable tabs={tabs} />
      </Card>
    </ListScreen>
  );
}
