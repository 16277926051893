import { agencyFeesTableBlock } from 'src/modules/agency-fees/blocks/agency-fees-table-block';
import { servicePackageSettingsBlock } from 'src/modules/service-packages/blocks/service-package-settings-block';
import { servicePackagesTableBlock } from 'src/modules/service-packages/blocks/service-packages-table-block';
import { ContentConfig } from 'view/components/record-screen/types';

export const content: ContentConfig = [
  {
    label: 'General',
    items: [
      {
        id: 'fees',
        label: 'Fees',
        privilege: 'agency-fees.update',
        blocks: [agencyFeesTableBlock]
      },
      {
        id: 'service-packages',
        label: 'Service packages',
        privilege: 'service-packages.read',
        blocks: [servicePackageSettingsBlock, servicePackagesTableBlock]
      }
    ]
  }
];
