import { useQuery } from 'react-query';

import { api } from 'utils/api/api-client';

export async function fetchCountryRegions() {
  return api.get('value-lists/country_region').then((res) => res.data.items);
}
export function useCountryRegions(selectedCountryId = '') {
  const { data = [] } = useQuery(['country_region'], fetchCountryRegions, {
    staleTime: Infinity,
    cacheTime: Infinity
  });

  return data?.filter((item) => item?.country_code === selectedCountryId);
}
