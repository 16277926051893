import { useWhereabouts } from '@rexlabs/whereabouts';

import { Columns } from 'src/view/components/table';
import { SummaryCell } from 'src/modules/tasks/common/components/summary-cell';

import { LeaseAgreementCell } from 'src/modules/tasks/lease-review/components/lease-agreement-cell';
import { RentCell } from 'src/modules/tasks/lease-review/components/rent-cell';
import { LeaseReview } from 'src/modules/tasks/lease-review/types/LeaseReview';
import { ProgressDropdown } from 'src/modules/tasks/lease-review/components/progress-dropdown';
import { useTranslation } from 'react-i18next';
import {
  closedDate,
  createdBy,
  createdDate,
  managedBy
} from '../../common/utils/common-columns';

const priority = {
  id: 'priority_id',
  type: 'value',
  Header: 'Priority',
  accessor: (item: LeaseReview) => item.priority,
  toCsv: (item: LeaseReview) => item?.priority?.label
} as const;

const taskLeaseReviewStatusId = {
  id: 'task_lease_review_status_id',
  Header: 'Task progress',
  Cell: ProgressDropdown,
  accessor: (item: LeaseReview) => item,
  width: 200,
  toCsv: (item: LeaseReview) => item?.details?.status?.label || ''
};

const dueDate = {
  id: 'due_date',
  Header: 'Due date',
  accessor: (item: LeaseReview) => item.due_date,
  type: 'date',
  width: 140
} as const;

const followUpDate = {
  id: 'follow_up_date',
  Header: 'Follow up date',
  accessor: (item: LeaseReview) => item.follow_up_date,
  type: 'date',
  width: 140
} as const;

const rent = {
  id: 'rent',
  Header: 'Rent',
  Cell: RentCell,
  accessor: (item) =>
    item?.details?.property_tenancy?.rent_schedule?.find?.(
      (schedule) => schedule?.status?.id === 'current'
    ),
  width: 230,
  toCsv: (item) => item?.amount_total_inc_tax?.toString?.() || ''
};

const summary = {
  id: 'summary',
  Header: 'Summary',
  Cell: SummaryCell,
  accessor: (item: LeaseReview) => item,
  width: 380,
  toCsv: (item: LeaseReview) => item.summary
};

export function useLeaseReviewColumns(): Columns {
  const { t } = useTranslation();
  const whereabouts = useWhereabouts();
  const tab = whereabouts.hashQuery?.tab;

  const leaseAgreement = {
    id: 'lease_agreement',
    Header: t('lease-agreements.label.singular'),
    Cell: LeaseAgreementCell,
    accessor: (item: LeaseReview) => item?.details?.property_tenancy,
    width: 200,
    toCsv: (item) => item?.lease_type?.label || ''
  };

  if (tab === 'closed') {
    return [
      summary,
      leaseAgreement,
      rent,
      closedDate,
      dueDate,
      managedBy,
      priority,
      createdBy,
      createdDate
    ];
  }

  return [
    summary,
    leaseAgreement,
    rent,
    followUpDate,
    dueDate,
    taskLeaseReviewStatusId,
    managedBy,
    priority,
    createdBy,
    createdDate
  ];
}
