import { useDeferredLineItemsTableFilters } from 'src/modules/deferred-fees/hooks/use-deferred-line-items-table-filters';

import { ListTable } from 'view/components/table';
import { ListTableProps } from 'view/components/table/list-table';
import { Tab } from 'view/components/table/tabbed';

import { getDeferredFeeLineItemsQuery } from '../utils/get-deferred-fee-line-items-query';
import { DeferredFeeTypeId } from '../types/DeferredFeeTypeId';
import { DeferredFeeLineItemColumn } from '../types/DeferredFeeLineItemColumn';

type RequiredTabProps = Pick<Tab<ListTableProps>, 'Table' | 'columns'>;
type TabProps = Partial<Omit<Tab<ListTableProps>, 'Table' | 'columns'>>;

type SharedDeferredFeeLineItemSharedTabConfigProps = TabProps & {
  folioId: string;
  deferredFeeLineItemTypeId: DeferredFeeTypeId;
  columns: DeferredFeeLineItemColumn[];
};

export type SharedDeferredFeeLineItemsSharedTabConfig = RequiredTabProps &
  TabProps;

export function useGetSharedDeferredFeeLineItemSharedTabProps(): (
  props: SharedDeferredFeeLineItemSharedTabConfigProps
) => SharedDeferredFeeLineItemsSharedTabConfig {
  const { getSort, getFilters } = useDeferredLineItemsTableFilters();

  return ({ folioId, deferredFeeLineItemTypeId, columns, ...overrides }) => ({
    Table: ListTable,
    hideBulkActions: true,
    columns,
    getQuery: getDeferredFeeLineItemsQuery,
    getSort,
    initialSortBy: [{ id: 'date_of', desc: true }],
    getFilters,
    forcedGlobalFilter: [
      {
        field: 'folio_id',
        op: 'eq',
        value: folioId
      },
      {
        field: 'type_id',
        op: 'eq',
        value: deferredFeeLineItemTypeId
      },
      {
        field: 'status_id',
        op: 'neq',
        value: 'voided'
      }
    ],
    ...overrides
  });
}
