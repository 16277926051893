import React from 'react';
import { useToken } from '@rexlabs/styling';
import { ColoredCard } from 'view/components/cards/colored-card';

export function TemplateTierLineItemContainer({
  children
}: {
  children: React.ReactNode;
}) {
  const token = useToken();

  return (
    <ColoredCard
      intent={'neutral'}
      p={token('spacing.xs')}
      pl={token('spacing.s')}
      pt={token('spacing.xs')}
      mb={token('spacing.xl')}
      sy='1rem'
    >
      {children}
    </ColoredCard>
  );
}
