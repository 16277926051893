import React from 'react';

import { StyleSheet, useStyles } from '@rexlabs/styling';
import { Small, Tiny, Body } from '@rexlabs/text';

type BadgeSize = 'tiny' | 'small' | 'regular';
type BadgeColor = 'red' | 'grey';

const DEFAULT_SIZE: BadgeSize = 'regular';
const DEFAULT_COLOR: BadgeColor = 'grey';

const badgeStyles = StyleSheet({
  red: {
    background: ({ token }) => token('palette.red.800'),
    color: ({ token }) => token('palette.white')
  },

  grey: {
    background: ({ token }) => token('palette.grey.400')
  },

  badge: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    padding: 2,
    paddingLeft: 8,
    paddingRight: 8
  },

  greyActive: {
    background: ({ token }) => token('palette.indigo.200')
  }
});

const textComponentMap = {
  tiny: Tiny,
  small: Small,
  regular: Body
};

export function NumberBadge({
  number,
  isActive = false,
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR
}: {
  number: number;
  isActive?: boolean;
  size?: BadgeSize;
  color?: BadgeColor;
}) {
  const s = useStyles(badgeStyles);

  const isNumberLarge = number > 99;

  const value = isNumberLarge ? '99+' : number;

  const red = color === 'red';
  const grey = color === 'grey';

  const TextComponent = textComponentMap[size];

  return (
    <div
      {...s.with('badge', {
        greyActive: isActive && grey,
        red,
        grey
      })()}
    >
      <TextComponent as={'span'}>{value}</TextComponent>
    </div>
  );
}
