import React, { useCallback } from 'react';
import { query } from '@rexlabs/model-generator';

import ROUTES from 'routes/app';
import { ListTable, Columns } from 'view/components/table';
import { Card } from 'view/components/card';
import { ListScreen } from 'view/components/list-screen/list-screen';
import { financialsCreditNotesModel } from 'data/models/entities/financials/credit-notes';
import { useTableFilters } from 'view/hooks/use-table-filters';

import { TagCell } from '@rexlabs/table';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { StatusTag } from 'src/modules/common/components/status-tag';
import { useCreditNotesActions } from '../actions/use-credit-note-actions';
import { creditNoteStatusMap } from '../maps/credit-note-map';

const creditNotesQuery = query`{
  ${financialsCreditNotesModel} {
    id
    applications
  }
}`;

const columns: Columns = [
  {
    id: 'record_reference',
    width: 140,
    Header: 'Reference'
  },
  {
    id: 'amount_total_inc_tax',
    cellProps: {
      align: 'right'
    },
    type: 'currency',
    Header: 'Total amount'
  },
  {
    id: 'credit_note_date',
    type: 'date',
    Header: 'Date of'
  },
  {
    id: 'payable_by.object.label',
    Header: 'Issued by'
  },
  {
    id: 'payable_to.object.label',
    Header: 'Issued to'
  },
  {
    id: 'status',
    Header: 'Status',
    width: 160,
    Cell: (table) => {
      const {
        cell: { value }
      } = table;
      return (
        <TagCell>
          <StatusTag status={value} map={creditNoteStatusMap} />
        </TagCell>
      );
    }
  },
  {
    id: 'created_at',
    type: 'date',
    Header: 'Created at'
  },
  {
    id: 'updated_at',
    type: 'date',
    Header: 'Updated at'
  }
];

function getRowLinkProps({ item }) {
  return {
    to: ROUTES.CREDIT_NOTE,
    params: { creditNoteId: item.id }
  };
}

export const CreditNotesList = () => {
  const { getSort, getFilters } = useTableFilters('credit-notes');
  const getCreditNoteActions = useCreditNotesActions();

  const getActionMenuItems = useCallback(({ item }) => {
    return transformActionDeclarationsToActionMenuItems(
      getCreditNoteActions(item)
    );
  }, []);

  return (
    <ListScreen
      privilege={'credit-notes.read'}
      title='Credit notes'
      createLabel='Create credit note'
      createLinkProps={{ to: ROUTES.CREDIT_NOTES_LIST.CREATE }}
    >
      <Card>
        <ListTable
          id='credit-notes-list'
          columns={columns}
          getQuery={() => creditNotesQuery}
          getRowLinkProps={getRowLinkProps}
          getActionMenuItems={getActionMenuItems}
          getSort={getSort}
          getFilters={getFilters}
        />
      </Card>
    </ListScreen>
  );
};
