import { uniqBy } from 'lodash';

import { getDisbursementRelatedItems } from 'utils/records/get-disbursement-related-items';
import { getOwnershipRelatedItems } from 'utils/records/get-ownership-related-items';
import { getPropertyRelatedItems } from 'utils/records/get-property-related-items';
import { getQuoteTaskRelatedItems } from 'utils/records/get-quote-task-related-items';
import { getStatementRelatedItems } from 'utils/records/get-statement-related-items';
import { getTenancyRelatedItems } from 'utils/records/get-tenancy-related-items';
import { getTrustJournalEntryRelatedItems } from 'utils/records/get-trust-journal-entry-related-items';
import { getUserRelatedItems } from 'utils/records/get-user-related-items';
import { getWorkOrderTaskRelatedItems } from 'utils/records/get-work-order-task-related-items';
import { getInvoiceRelatedItems } from 'utils/records/get-invoice-related-items';

import { Contact } from 'src/modules/contacts/types/contact-types';
import { Task } from 'src/modules/tasks/common/types/Task';

import { itemIsContact } from 'src/modules/tasks/common/components/checklist-assignee-select';
import { CoreCommunicationContextObject } from '../../messages/mappers/types/create-message-form-data';

const relationSymbol = Symbol.for('relationType');

/**
 * This function takes a record object of the communication type - e.g. a property, tenancy, statement, etc. - and returns a promise,
 * that when resolved, returns an array of contacts that are related to the record.
 * For example, if you were sending a message in relation to a property, you could use this function to retrieve the related contacts,
 * then add those contacts as suggested recipients for the message.
 * @param searchItem
 * @returns {Promise<Contact[]>}
 */
export async function getRelatedContactsOfCommunicationRecord({
  searchItem,
  hasGuarantorAndOccupierUpdates
}: {
  searchItem?: CoreCommunicationContextObject;
  hasGuarantorAndOccupierUpdates: boolean;
}) {
  const contacts: any[] = [];

  switch (searchItem?.__record_type) {
    case 'property': {
      const {
        activeOwners,
        outgoingOwners,
        activeTenants,
        outgoingTenants
      } = await getPropertyRelatedItems(searchItem);

      addRelationTypeToContact(activeOwners, 'ownership');
      addRelationTypeToContact(outgoingOwners, 'ownership');
      addRelationTypeToContact(activeTenants, 'tenancy');
      addRelationTypeToContact(outgoingTenants, 'tenancy');

      contacts.push(
        ...activeOwners,
        ...outgoingOwners,
        ...activeTenants,
        ...outgoingTenants
      );
      break;
    }

    case 'ownership': {
      const { owners } = await getOwnershipRelatedItems(searchItem);
      addRelationTypeToContact(owners, 'owner');

      contacts.push(...owners);
      break;
    }

    // TODO: add get contact method
    case 'user': {
      const { contact } = await getUserRelatedItems(searchItem);
      addRelationTypeToContact(contact, 'contact');

      contacts.push(contact);
      break;
    }

    case 'disbursement': {
      const { owners, contact } = await getDisbursementRelatedItems(searchItem);
      addRelationTypeToContact(owners, 'owner');
      addRelationTypeToContact(contact, 'owner');

      contacts.push(...owners, contact);
      break;
    }

    case 'contact': {
      addRelationTypeToContact(searchItem, 'contact');

      contacts.push(searchItem);
      break;
    }

    case 'statement': {
      const { contact, owners } = await getStatementRelatedItems(searchItem);
      addRelationTypeToContact(owners, 'owner');
      addRelationTypeToContact(contact, 'owner');

      contacts.push(...owners, contact);
      break;
    }

    case 'tenancy': {
      const { tenants, relatedContacts } = await getTenancyRelatedItems(
        searchItem
      );

      if (hasGuarantorAndOccupierUpdates) {
        const tenancyRelatedContactsData = structuredClone(
          relatedContacts?.data || []
        );
        tenancyRelatedContactsData.forEach((relatedContact) => {
          return addRelationTypeToContact(
            relatedContact.contact,
            relatedContact.role.label
          );
        });

        const tenancyRelatedContacts = tenancyRelatedContactsData.map(
          (item) => item.contact
        );

        contacts.push(...tenancyRelatedContacts);
      } else {
        addRelationTypeToContact(tenants, 'tenant');
        contacts.push(...tenants);
      }

      break;
    }

    case 'trust_journal_entry': {
      const { contact } = await getTrustJournalEntryRelatedItems(searchItem);
      addRelationTypeToContact(contact, 'contact');

      contacts.push(contact);
      break;
    }

    case 'task': {
      switch (searchItem?.type?.id) {
        case 'task_quote': {
          const {
            accessProvidedBy,
            managedBy,
            supplier
          } = await getQuoteTaskRelatedItems(searchItem as Task<'task_quote'>);
          addRelationTypeToContact(accessProvidedBy, 'access_provided_by');
          if (itemIsContact(managedBy)) {
            addRelationTypeToContact(managedBy, 'managed_by');
          }
          addRelationTypeToContact(supplier, 'supplier');

          contacts.push(accessProvidedBy, managedBy, supplier);
          break;
        }

        case 'task_work_order': {
          const {
            accessProvidedBy,
            managedBy,
            workDoneBy
          } = await getWorkOrderTaskRelatedItems(
            searchItem as Task<'task_work_order'>
          );
          addRelationTypeToContact(accessProvidedBy, 'access_provided_by');
          if (itemIsContact(managedBy)) {
            addRelationTypeToContact(managedBy, 'managed_by');
          }
          addRelationTypeToContact(workDoneBy, 'work_done_by');

          contacts.push(accessProvidedBy, managedBy, workDoneBy);
          break;
        }
      }
      break;
    }

    case 'invoice': {
      const { payableBy, payableTo } = await getInvoiceRelatedItems(searchItem);

      addRelationTypeToContact(payableBy, 'payable_by');
      addRelationTypeToContact(payableTo, 'payable_to');

      contacts.push(...payableBy, ...payableTo);
      break;
    }
  }

  // Sometimes the related contacts can be undefined or null, so rather than conditionally add them to the array if they are
  // truthy, we just filter them out at the end.
  const cleanContacts = contacts.filter((contact) => !!contact);

  // In case the same contact is included twice, we remove the duplicates here.
  return uniqBy(cleanContacts, (contact: Contact) => contact.id);
}

function addRelationTypeToContact(
  contact: Array<Contact> | Contact | undefined | null,
  type: string
) {
  if (Array.isArray(contact)) {
    contact.forEach((c) => addRelationTypeToContact(c, type));

    return;
  }

  if (!contact) return;

  try {
    contact[relationSymbol] = type;
  } catch (e) {
    console.error(e);
  }
}
