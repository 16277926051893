import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Field } from '@rexlabs/form';
import { BankWithdrawal } from 'src/modules/banking/bank-withdrawals/types/bank-withdrawal-types';
import { getToday } from 'utils/dates/dates';
import { Grid } from 'view/components/@luna/form/grid';
import { DateInput } from 'view/components/@luna/inputs/date-input/date-input';
import dayjs from 'dayjs';
import moment from 'moment';

export const bacsBankWithdrawalDetailsBlock: BlockConfig<BankWithdrawal> = {
  id: 'bacsBankWithdrawalDetails',
  Edit: function EditBacsWithdrawalDetails() {
    const isOutsideRange = (momentDate: moment.Moment) => {
      const date = dayjs(momentDate.toDate());

      return date.isBefore(dayjs(getToday()).add(1, 'day'));
    };

    return (
      <Grid columns={1}>
        <Grid columns={2}>
          <Field
            label='BACS Processing Date'
            name='bacs_processing_date'
            Input={DateInput}
            inputProps={{ isOutsideRange, defaultValue: getToday() }}
          />
        </Grid>
      </Grid>
    );
  }
};
