import { getNewTier } from '../components/edit-template-tier-line-items';
import { FolioCommissionTemplate } from '../types/FolioCommissionTemplate';
import {
  EmptyTierFormData,
  FolioCommissionTemplateFormData,
  FolioCommissionTemplateTierFormData
} from '../types/FolioCommissionTemplateFormData';
import { mapTemplateTierToTierFormData } from './map-template-tier-to-tier-form-data';

export function mapSupplierCommissionTemplateToFormData(
  values: FolioCommissionTemplate
): Omit<FolioCommissionTemplateFormData, 'tiers'> & {
  tiers: EmptyTierFormData[] | FolioCommissionTemplateTierFormData[];
} {
  const { structure_type, commission_amount, tiers, ...rest } = values;

  const structureTypeId = structure_type?.id;

  const common = {
    structure_type,
    ...rest
  };

  const placeholderTiers: EmptyTierFormData[] = [getNewTier(), getNewTier()];

  switch (structureTypeId) {
    case 'flat_fee':
      return {
        ...common,
        commission_amount_currency: commission_amount!,
        commission_amount_percent: undefined,
        tiers: placeholderTiers
      };
    case 'percentage':
      return {
        ...common,
        commission_amount_percent: commission_amount!,
        commission_amount_currency: undefined,
        tiers: placeholderTiers
      };
    case 'tiered':
    case 'progressive':
      return {
        ...common,
        tiers: mapTemplateTierToTierFormData(tiers || []),
        commission_amount_currency: undefined,
        commission_amount_percent: undefined
      };
  }
}
