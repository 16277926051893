import { LinkButton } from '@rexlabs/button';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import React from 'react';
import AddIcon from 'view/components/icons/add';

export const styles = StyleSheet({
  actionLink: {
    color: ({ token }) => token('color.textStyle.primary.idle.default')
  }
});

export const SelectMatchActionCell = (props) => {
  const s = useStyles(styles);

  const { setSelectedMatches, setAvailableMatches, item } = props.value;

  const handleClick = () => {
    setSelectedMatches((previousSelectedMatches) => [
      ...previousSelectedMatches,
      item
    ]);

    setAvailableMatches((previousAvailableMatches) => {
      return {
        singleReceipts:
          item.__record_type === 'trust_journal_entry'
            ? previousAvailableMatches.singleReceipts.filter(
                (selectedMatch) => selectedMatch.id !== item.id
              )
            : previousAvailableMatches.singleReceipts,
        paymentGatewayFiles:
          item.__record_type === 'bank_statement_transaction_import'
            ? previousAvailableMatches.paymentGatewayFiles.filter(
                (selectedMatch) => selectedMatch.id !== item.id
              )
            : previousAvailableMatches.paymentGatewayFiles
      };
    });
  };

  return (
    <LinkButton
      data-testId={'select-match-action-button'}
      IconLeft={AddIcon}
      {...s('actionLink')}
      onClick={handleClick}
    />
  );
};
