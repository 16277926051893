import React from 'react';
import { useSessionState } from 'src/modules/common/hooks/session-state';
import { useUserPortfolioMembers } from 'src/modules/portfolios/hooks/use-user-portfolio-members';

export function useChecklistItemAssigneeInfo() {
  const { contact, userGroups } = useSessionState();
  const { data: portfolioMembers } = useUserPortfolioMembers();

  // If the user has a contact, we capture the ID so we can show 'Me' instead of the user's name
  const myId = contact?.id;
  const myPortfolioRoleIds = React.useMemo(
    () =>
      (portfolioMembers
        ?.map((member) => member.portfolio_role?.id)
        .filter(Boolean) as string[]) || [],
    [portfolioMembers]
  );

  const myUserGroupIds = React.useMemo(
    () => userGroups?.map((group) => group.id) || [],
    [userGroups]
  );

  const idInfo: AssigneeIdInfo = React.useMemo(
    () => ({
      contactId: myId,
      portfolioRoleIds: myPortfolioRoleIds,
      userGroupIds: myUserGroupIds
    }),
    [myUserGroupIds, myId, myPortfolioRoleIds]
  );

  return idInfo;
}

export type AssigneeIdInfo = {
  contactId?: string;
  portfolioRoleIds?: string[];
  userGroupIds?: string[];
};
