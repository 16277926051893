import * as React from 'react';

import { LinkButton } from '@rexlabs/button';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { useDialog } from '@rexlabs/dialog';

import { Column } from 'src/view/components/table';
import { ViewDocumentDialog } from 'src/modules/documents/dialogs/view-document-dialog';
import { WorkOrderTask } from 'src/modules/tasks/work-orders/types/WorkOrderTask';

import { FLAGS } from 'utils/feature-flags';
import { formatDate, formatHumanReadableFileSize } from 'utils/dates/format';
import { getRecordLabel } from 'utils/records/get-record-label';
import { formatCurrency } from 'utils/formatters';

import { LargeDescriptionCell } from 'view/components/cells/large-description-cell';
import { TwoTierHeader } from 'view/components/column-headers/two-tier-column-header';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';

import { Bill } from '../types/Bill';
import { InvoiceCell } from '../components/invoice-cell';

const styles = StyleSheet({
  button: {
    color: ({ token }) => token('color.textStyle.body.default')
  }
});

type BillProcessingColumnId = keyof typeof billProcessingColumnMap;

type TabID =
  | 'not_processed'
  | 'ready_for_processing'
  | 'needs_more_detail'
  | 'processed';

function getBillProcessingColumnIds(
  tabId: TabID,
  hasQuickInvoicesFeature?: boolean
): BillProcessingColumnId[] {
  if (hasQuickInvoicesFeature) {
    switch (tabId) {
      case 'processed':
        return ['file', 'linked_invoice', 'uploaded_date'];
      case 'needs_more_detail':
      case 'not_processed':
        return [
          'file',
          'relates_to_work_order',
          'bill_amount',
          'payable_to',
          'payable_by',
          'uploaded_date'
        ];
      case 'ready_for_processing':
        return [
          'file',
          'relates_to_work_order',
          'bill_amount',
          'payable_to',
          'payable_by',
          'uploaded_date',
          'process_ready_date'
        ];
      default:
        return [];
    }
  }
  switch (tabId) {
    case 'needs_more_detail':
    case 'not_processed':
    case 'ready_for_processing':
      return ['file_with_link', 'file_size', 'file_created_at'];
    case 'processed':
      return [
        'file_with_link',
        'linked_invoice',
        'file_size',
        'file_created_at'
      ];
    default:
      return [];
  }
}

export function useGetBillProcessingColumns() {
  const { hasFeature } = useFeatureFlags();
  const hasQuickInvoicesFeature = hasFeature(FLAGS.QUICK_INVOICES);

  return (tab: TabID) => {
    const columnIds = getBillProcessingColumnIds(tab, hasQuickInvoicesFeature);
    return columnIds.map((columnId) => billProcessingColumnMap[columnId]);
  };
}

const linkedInvoiceColumn: Column<Bill> = {
  id: 'bill_processing_invoice',
  Header: 'Linked invoice',
  width: 400,
  accessor: (item) => item.invoice,
  Cell: InvoiceCell
};

const fileColumn: Column<Bill> = {
  id: 'file',
  Header: () => <TwoTierHeader header='File name' subheader='File size' />,
  headerString: 'File name - File size',
  type: 'two_tier',
  accessor: (item: Bill) => {
    return {
      text: item.file?.name,
      subtext: item.file?.size
        ? formatHumanReadableFileSize(item?.file?.size)
        : undefined
    };
  },
  width: 300
};

const relatesToWorkOrderColumn: Column<Bill> = {
  id: 'relates_to_work_order',
  Header: () => <TwoTierHeader header='Relates to task' subheader='Property' />,
  headerString: 'Relates to task - Property',
  type: 'two_tier',
  accessor: (item: Bill) => {
    const task = item?.draft_invoice?.task;

    const property = task?.property;

    return {
      text: task?.summary,
      subtext: property?.display_name
    };
  }
};

const billAmountColumn: Column<Bill> = {
  id: 'bill_amount',
  Header: () => (
    <TwoTierHeader
      header='Bill amount'
      subheader='Work order estimated amount'
      align='right'
    />
  ),
  headerString: 'Bill amount - Work order estimated amount',
  cellProps: {
    align: 'right'
  },
  width: 220,
  type: 'two_tier',
  accessor: (item: Bill) => {
    const task =
      item?.draft_invoice?.task?.type?.id === 'task_work_order'
        ? (item?.draft_invoice?.task as WorkOrderTask)
        : undefined;
    const isWorkOrder = task?.type?.id === 'task_work_order';
    const amount = item.draft_invoice?.amount;

    return {
      text: amount || amount === 0 ? formatCurrency(amount) : undefined,
      subtext:
        isWorkOrder && (amount || amount === 0)
          ? formatCurrency(task?.details?.amount)
          : undefined
    };
  }
};

const payableToColumn: Column<Bill> = {
  id: 'draft_invoice.payable_to.object',
  Cell: ({ value }) => (
    <LargeDescriptionCell lineClamp={2} text={value?.label || '--'} />
  ),
  Header: 'Payable to',
  width: 200,
  toCsv: (item) => item?.label
};

const payableByColumn: Column<Bill> = {
  id: 'draft_invoice.payable_by.object',
  Cell: ({ value }) => (
    <LargeDescriptionCell lineClamp={2} text={value?.label || '--'} />
  ),
  Header: 'Payable by',
  width: 200,
  toCsv: (item) => item?.label
};

const uploadedDateColumn: Column<Bill> = {
  id: 'uploaded_date',
  Header: () => (
    <TwoTierHeader header='Uploaded date' subheader='Uploaded by' />
  ),
  headerString: 'Uploaded date - Uploaded by',
  type: 'two_tier',
  accessor: (item: Bill) => {
    const createdAt = item.created_at;
    const createdBy = item.created_by;

    return {
      text: createdAt ? formatDate(createdAt) : undefined,
      subtext: createdBy
        ? `by ${getRecordLabel({
            type: 'author',
            object: createdBy
          })}`
        : undefined
    };
  }
};

const processReadyDateColumn: Column<Bill> = {
  id: 'process_ready_date',
  Header: () => (
    <TwoTierHeader header='Process ready date' subheader='Prepared by' />
  ),
  headerString: 'Process ready date - Prepared by',
  type: 'two_tier',
  accessor: (item: Bill) => {
    const updatedAt = item.updated_at;
    const updatedBy = item.updated_by;
    return {
      text: updatedAt ? formatDate(updatedAt) : undefined,
      subtext: updatedBy
        ? `by ${getRecordLabel({
            type: 'author',
            object: updatedBy
          })}`
        : undefined
    };
  }
};

const fileWithLinkColumn: Column<Bill> = {
  id: 'file',
  Header: 'File name',
  accessor: (item) => item.file,
  Cell({ value }) {
    const s = useStyles(styles);

    const viewDocumentDialog = useDialog(ViewDocumentDialog);

    return (
      <LinkButton
        {...s('button')}
        onClick={() => {
          viewDocumentDialog.open({
            fileName: value.name,
            fileType: value.type,
            fileUrl: value.url
          });
        }}
      >
        {value.name}
      </LinkButton>
    );
  }
};

const fileSizeColumn: Column<Bill> = {
  id: 'file.size',
  Header: 'File size',
  type: 'fileSize',
  width: 110
};

const fileCreatedAtColumn: Column<Bill> = {
  id: 'file.created_at',
  Header: 'Date uploaded',
  type: 'date',
  width: 140
};

const billProcessingColumnMap = {
  linked_invoice: linkedInvoiceColumn,
  file: fileColumn,
  relates_to_work_order: relatesToWorkOrderColumn,
  bill_amount: billAmountColumn,
  payable_to: payableToColumn,
  payable_by: payableByColumn,
  uploaded_date: uploadedDateColumn,
  process_ready_date: processReadyDateColumn,
  file_with_link: fileWithLinkColumn,
  file_size: fileSizeColumn,
  file_created_at: fileCreatedAtColumn
};
