import React, { ComponentProps } from 'react';

import Box from '@rexlabs/box';
import { StyleSheet, useStyles, useToken } from '@rexlabs/styling';
import { Body, Small } from '@rexlabs/text';

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    overflow: 'hidden',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  right: {
    overflow: 'hidden'
  },
  headline: {
    'div:hover > * > &': {
      textDecoration: 'underline solid currentColor 2px'
    },
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  subline: {
    marginTop: ({ token }) => token('spacing.xxxs'),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textDecoration: 'none !important'
  }
});

interface FlagProps extends ComponentProps<'div'> {
  /**
   * Frequently: an icon that e.g. indicates the type of record.
   */
  signifier?: React.ReactNode;
  headline?: React.ReactNode;
}

export function Flag({ signifier, headline, children }: FlagProps) {
  const s = useStyles(defaultStyles);
  const token = useToken();

  return (
    <Box {...s('container')} flexDirection='row' alignItems='center'>
      <Box flexDirection='column' justifyContent='center' mr='1rem'>
        {signifier}
      </Box>
      <Box
        {...s('right')}
        flex={1}
        flexDirection='column'
        sy={token('spacing.xxxs')}
      >
        <Body as='header' {...s('headline')} semibold>
          {headline}
        </Body>
        <Small as='span' grey {...s('subline')}>
          {children}
        </Small>
      </Box>
    </Box>
  );
}
