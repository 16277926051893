import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { PortfolioRole } from '../../types/PortfolioRole';
import { useGetDeletePortfolioRoleAction } from '../action-declarations/use-get-delete-portfolio-role-action';

export function useGetPortfolioRoleActions() {
  const getDeletePortfolioRoleAction = useGetDeletePortfolioRoleAction();
  return (portfolioRole?: PortfolioRole): ActionDeclaration[] => {
    if (!portfolioRole) return [];

    const actions: ActionDeclaration[] = [];

    if (portfolioRole.system_purpose === null) {
      actions.push(getDeletePortfolioRoleAction(portfolioRole));
    }

    return actions;
  };
}
