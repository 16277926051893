import * as React from 'react';
import { query } from '@rexlabs/model-generator';
import dayjs from 'dayjs';
import { ListTable, TabbedTable } from 'view/components/table';
import { Card } from 'view/components/card';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { ListScreen } from 'view/components/list-screen/list-screen';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { tasksModel } from 'src/modules/tasks/common/models/tasks-model';
import { useAppWideFilterContext } from 'src/modules/app-wide-filters/contexts/app-wide-filter-context';
import { MOVE_OUT_ROUTES } from '../routes';
import { useMoveOutColumns } from '../hooks/use-move-out-columns';
import { useGetCreateMoveOutAction } from '../hooks/action-declarations/use-get-create-move-out-action';
import { useGetMoveOutActions } from '../hooks/action-menu-items/use-get-move-out-actions';
import { TaskTableEmptyState } from '../../common/components/task-table-empty-state';
import { initialHiddenColumns } from '../../common/utils/initial-hidden-columns';

const now = dayjs().format('YYYY-MM-DD');

const filterOpen = {
  field: 'closed_at',
  op: 'eq',
  value: 'null'
};

const filterClosed = {
  field: 'closed_at',
  op: 'neq',
  value: 'null'
};

const filterFollowUp = {
  field: 'follow_up_date',
  op: 'lte',
  value: now
};

const filterDue = {
  field: 'task_move_out_move_out_date',
  op: 'eq',
  value: now
};

const filterOverdue = {
  field: 'task_move_out_move_out_date',
  op: 'lt',
  value: now
};

const moveOutQuery = () => query`{
  ${tasksModel} {
    id
    property
    managed_by
    moveOutDetails
    created_by
  }
}`;

/**
 * Tabbed List Table
 * Model Generator Variant
 */
export function MoveOutList() {
  const { appWideFilterKey } = useAppWideFilterContext();
  const getCreateMoveOutAction = useGetCreateMoveOutAction();
  const getMoveOutActions = useGetMoveOutActions();

  const { getSort, getFilters } = useTableFilters('tasks', {
    type_id: 'task_move_out'
  });

  const getRowLinkProps = ({ item }) => {
    return {
      to: MOVE_OUT_ROUTES.MOVE_OUT_DETAILS,
      params: { moveOutId: item.id }
    };
  };

  const getActionMenuItems = React.useCallback(
    ({ item }) => {
      const actions = getMoveOutActions(item);
      return transformActionDeclarationsToActionMenuItems(actions);
    },
    [getMoveOutActions]
  );

  const moveOutColumns = useMoveOutColumns();
  const columnDependency = JSON.stringify(moveOutColumns);

  const tabs = React.useMemo(() => {
    const commonProps = {
      id: 'move_outs',
      columns: moveOutColumns,
      getQuery: moveOutQuery,
      Table: ListTable,
      getRowLinkProps,
      getFilters,
      getSort,
      getActionMenuItems,
      forcedGlobalFilter: [
        {
          field: 'type_id',
          op: 'eq',
          value: { value: 'task_move_out', label: 'Move out' }
        }
      ],
      shouldUseAppWideFilter: true,
      initialHiddenColumns: initialHiddenColumns
    };

    return [
      {
        ...commonProps,
        name: 'open',
        label: 'Open',
        Empty: () => <TaskTableEmptyState label='Open' />,
        forcedGlobalFilter: [...commonProps.forcedGlobalFilter, filterOpen]
      },
      {
        ...commonProps,
        name: 'follow_up',
        label: 'Follow up',
        Empty: () => <TaskTableEmptyState label='Follow up' />,
        forcedGlobalFilter: [
          ...commonProps.forcedGlobalFilter,
          filterOpen,
          filterFollowUp
        ]
      },
      {
        ...commonProps,
        name: 'due',
        label: 'Due',
        Empty: () => <TaskTableEmptyState label='Due' />,
        forcedGlobalFilter: [
          ...commonProps.forcedGlobalFilter,
          filterOpen,
          filterDue
        ]
      },
      {
        ...commonProps,
        name: 'overdue',
        label: 'Overdue',
        Empty: () => <TaskTableEmptyState label='Overdue' />,
        forcedGlobalFilter: [
          ...commonProps.forcedGlobalFilter,
          filterOpen,
          filterOverdue
        ]
      },
      {
        ...commonProps,
        name: 'closed',
        label: 'Closed',
        Empty: () => <TaskTableEmptyState label='Closed' />,
        forcedGlobalFilter: [...commonProps.forcedGlobalFilter, filterClosed]
      }
    ];
  }, [columnDependency, getFilters]);

  return (
    <ListScreen
      privilege={'tasks.move-out.read'}
      title='Move out'
      actions={[getCreateMoveOutAction()]}
      key={appWideFilterKey}
    >
      <Card>
        <TabbedTable tabs={tabs} />
      </Card>
    </ListScreen>
  );
}
