import { useQuery } from 'react-query';
import { api } from 'utils/api/api-client';
import { MetricWriteRule } from '../types/MetricWriteRule';

export const METRIC_WRITE_RULES_QUERY_KEY = 'metricWriteRules';

export function useMetricWriteRules() {
  return useQuery<MetricWriteRule[]>(
    METRIC_WRITE_RULES_QUERY_KEY,
    async () => {
      const response = await api.get<MetricWriteRule[]>('/metric-write-rules');
      return response.data;
    },
    { staleTime: Infinity }
  );
}
