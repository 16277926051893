import React, { useState, useMemo } from 'react';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';
import { contactsModel } from 'src/modules/contacts/models/contacts';
import { filterNullValues } from 'utils/filter-null-values';

import { isString, merge } from 'lodash';
import {
  Contact,
  CreateContact
} from 'src/modules/contacts/types/contact-types';
import { useRecordCreatedToast } from 'src/modules/common/toasts/hooks/use-record-created-toast';

import { api } from 'utils/api/api-client';
import { detailsBlock } from '../blocks/details';
import { typeBlock } from '../blocks/type';
import { contactInformationBlock } from '../blocks/contact-information';
import { businessDetailsBlock } from '../blocks/business-details';
import { depositAuthorityDetailsBlock } from '../blocks/deposit-authority-details-block';
import { createContactPaymentMethodsBlock } from '../blocks/create-contact-payment-methods';

const createDetailsBlock = detailsBlock('create');

export const createContactBlocksForTypeMap = {
  business: [
    businessDetailsBlock,
    createDetailsBlock,
    contactInformationBlock,
    createContactPaymentMethodsBlock
  ],
  deposit_authority: [
    depositAuthorityDetailsBlock,
    createDetailsBlock,
    contactInformationBlock,
    createContactPaymentMethodsBlock
  ],
  individual: [
    createDetailsBlock,
    contactInformationBlock,
    createContactPaymentMethodsBlock
  ]
};

const getContent = ({
  type,
  showTypeBlock
}: {
  type: string;
  showTypeBlock?: boolean;
}): DialogContentConfig => {
  // In the case that we're passing in the specific contact type, we want to hide the type block
  const blocksForType = [
    ...(showTypeBlock ? [typeBlock] : []),
    ...createContactBlocksForTypeMap[type]
  ];

  return [
    {
      id: 'details',
      label: 'Details',
      blocks: blocksForType.map((block) => ({
        ...block,
        title: isString(block.title)
          ? block.title.replace(/\[.*?\]/, '').trim()
          : block.title
      }))
    }
  ];
};

export interface CreateContactDialogProps {
  onClose?: () => void;
  onCreate?: (contact: Contact) => void;
  initialValues?: Partial<Contact>;
  newContact?: Partial<CreateContact>;
  showTypeBlock?: boolean;
}

export function useSubmitHandler(
  onCreate?: CreateContactDialogProps['onCreate']
) {
  const addCreatedToast = useRecordCreatedToast(contactsModel);

  return async ({ values }) => {
    const { payment_methods = [], ...contactValues } = filterNullValues(values);

    if (contactValues.type?.id === 'business') {
      contactValues.is_business = true;
    }

    if (contactValues.type?.id === 'deposit_authority') {
      contactValues.is_business = true;
      contactValues.is_security_deposit_authority = true;
    }

    const response = await api.post('request-pipelines', {
      requests: [
        {
          id: 'contact',
          method: 'POST',
          path: '/api/v1/contacts',
          json: {
            ...contactValues,
            is_supplier: !!contactValues.is_supplier,
            emails: contactValues.email
              ? [
                  {
                    is_primary_email: true,
                    email_address: contactValues.email,
                    description: 'Default'
                  }
                ]
              : [],
            phones: contactValues.phone_number
              ? [
                  {
                    is_primary_phone: true,
                    phone_number: contactValues.phone_number,
                    description: 'Default'
                  }
                ]
              : [],
            addresses: contactValues.address
              ? [
                  {
                    is_primary: true,
                    address: contactValues.address,
                    description: 'Default'
                  }
                ]
              : []
          }
        },
        ...payment_methods.map((method, index) => ({
          id: `payment_method_${index}`,
          method: 'POST',
          path: '/api/v1/contacts/{{$.contact.id}}/payment-methods',
          json: { ...method, is_default: index === 0 }
        }))
      ]
    });

    const contact = response?.data?.contact;

    await onCreate?.(contact);

    addCreatedToast(contact);

    return contact;
  };
}

export function CreateContactDialog({
  onCreate,
  initialValues,
  newContact,
  showTypeBlock = true,
  ...props
}: CreateContactDialogProps) {
  const handleSubmit = useSubmitHandler(onCreate);

  // HACK: this is just to get access to the current value from outside the form
  const [type, setType] = useState(getContactType(initialValues));

  const isSupplier = !!initialValues?.is_supplier;

  const blockProps = useMemo(
    () => ({ type, setType, hideSupplierCheckbox: isSupplier }),
    [type, isSupplier]
  );

  const content = getContent({ type, showTypeBlock });

  const initialData = useMemo(
    () =>
      merge(
        {
          is_supplier: isSupplier,
          ...(!showTypeBlock ? { type: { id: type } } : {})
        },
        initialValues,
        newContact
      ),
    []
  );

  return (
    <RecordDialog
      {...props}
      data={initialData}
      title={'Create contact'}
      handleSubmit={handleSubmit}
      content={content}
      blockProps={blockProps}
    />
  );
}

function getContactType(contact?: Partial<Contact>): string {
  if (contact?.is_security_deposit_authority) {
    return 'deposit_authority';
  }
  if (contact?.is_business) {
    return 'business';
  }
  return 'individual';
}
