import React, { useCallback, useMemo } from 'react';
import {
  GhostButton,
  OutlineButton,
  PrimaryButton,
  PrimarySplitButton
} from '@rexlabs/button';
import ActionMenu from '@rexlabs/action-menu';
import { useModelActions } from '@rexlabs/model-generator';

import { Tenancy, tenancyModel } from 'data/models/entities/tenancies';
import ChevronDownIcon from 'view/components/icons/chevron-down';
import { ButtonGroupProps } from 'view/components/record-screen/dialog/types';
import { StandardDialogFooter } from 'view/components/dialogs/components/standard-dialog-footer';
import { ReceiptingFundsLineItemEntry } from 'src/modules/financials/blocks/trust-journal-entry-line-items';
import { getFolio } from 'src/modules/financials/utils/get-folio';
import { formatCurrency } from 'utils/formatters';
import ReceiptIcon from 'view/components/icons/receipt';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { useGetCreateNoteAction } from 'src/modules/note/hooks/use-get-create-note-action';
import NoteIcon from 'view/components/icons/note';

function getAllocatedAmount(items: ReceiptingFundsLineItemEntry[]) {
  return items.reduce((total, item) => total + (item.amount ?? 0), 0);
}

function getSubmitButtonLabel(
  unallocatedAmount: number,
  allocatedAmount: number,
  receivedAmount: number,
  withCreateAnother?: boolean
) {
  if (unallocatedAmount > 0) {
    return `${formatCurrency(unallocatedAmount)} unallocated`;
  }

  if (allocatedAmount === receivedAmount && receivedAmount !== 0) {
    return `Create receipt for ${formatCurrency(allocatedAmount)}${
      withCreateAnother ? ' and create another' : ''
    }`;
  }

  if (allocatedAmount > receivedAmount) {
    const overAllocatedAmount = allocatedAmount - receivedAmount;
    return `${formatCurrency(overAllocatedAmount)} over allocated`;
  }

  return 'Create receipt';
}

export interface ReceiptFundsDialogButtonGroupProps extends ButtonGroupProps {
  handleMatchToExisting?: () => void;
  showCreateAnotherButton?: boolean;
  bankStatementTransactionId?: string;
}

export function ReceiptFundsDialogButtonGroup({
  handleSubmit,
  handleMatchToExisting,
  onClose,
  isSubmitting,
  forms,
  resetAll,
  showCreateAnotherButton,
  bankStatementTransactionId
}: ReceiptFundsDialogButtonGroupProps) {
  const { hasFeature } = useFeatureFlags();

  const hasPaymentGatewaysFeature = hasFeature(FLAGS.PAYMENT_GATEWAYS);

  const getCreateNoteAction = useGetCreateNoteAction(
    'bank_statement_transaction'
  );

  const createNoteAction = useMemo(
    () =>
      bankStatementTransactionId &&
      getCreateNoteAction({
        attached: { id: bankStatementTransactionId },
        hideAddNoteToField: true
      }),
    []
  );

  const { updateItem: updateTenancy } = useModelActions(tenancyModel);
  const receiptedAmount = forms?.['receipt-funds-details'].values?.amount ?? 0;

  const folio = getFolio(forms);

  const receiptingInstruction =
    folio?.type.id === 'tenancy'
      ? (folio?.record as Tenancy)?.receipting_instruction
      : null;

  const allocatedAmount = getAllocatedAmount(
    forms?.['trust-journal-entry-line-items'].values.line_items ?? []
  );
  const unallocatedAmount = receiptedAmount - allocatedAmount;

  const isSubmitDisabled = receiptedAmount === 0 || unallocatedAmount !== 0;

  const handleSubmitAndClose = useCallback(async () => {
    const result = await handleSubmit();

    if (!result) return;

    onClose?.();
  }, [handleSubmit, onClose]);

  const handleSaveAndRemoveInstructions = useCallback(async () => {
    const result = await handleSubmit();

    if (!result) return;

    await updateTenancy({
      data: { receipting_instruction: null },
      id: folio?.record?.id
    });

    onClose?.();
  }, [onClose, folio?.record?.id, handleSubmit, updateTenancy]);

  const handleSaveAndCreateAnother = useCallback(async () => {
    const result = await handleSubmit();
    if (!result) return;

    resetAll();

    // NOTE: The above resets the form values, but this means we still have the field array with
    // no values. This is a problem when you go to add a new receipt as this will be in the form.
    // The below resets the trust-journal-entry-line-items and removes the field array.
    forms?.['trust-journal-entry-line-items']?.setFieldValue('line_items', []);
  }, [handleSubmit, resetAll, forms]);

  return (
    <StandardDialogFooter
      left={
        handleMatchToExisting ? (
          <OutlineButton
            IconLeft={ReceiptIcon}
            onClick={() => handleMatchToExisting?.()}
          >
            {hasPaymentGatewaysFeature
              ? 'Match to existing'
              : 'Match to existing receipt'}
          </OutlineButton>
        ) : null
      }
    >
      <GhostButton onClick={onClose}>Cancel</GhostButton>
      {hasFeature(FLAGS.BATCH_RECEIPTING_SUSPENSION_FLOW) &&
      createNoteAction ? (
        <OutlineButton
          IconLeft={NoteIcon}
          onClick={createNoteAction.handleAction}
        >
          Add note
        </OutlineButton>
      ) : null}
      {(receiptingInstruction || showCreateAnotherButton) &&
      !isSubmitDisabled ? (
        <PrimarySplitButton
          data-testid='receipt-funds-dialog-split-button'
          Left={({ Button }) => (
            <Button
              type='submit'
              isLoading={isSubmitting}
              onClick={
                showCreateAnotherButton
                  ? handleSaveAndCreateAnother
                  : handleSubmitAndClose
              }
            >
              {getSubmitButtonLabel(
                unallocatedAmount,
                allocatedAmount,
                receiptedAmount,
                !!(allocatedAmount && showCreateAnotherButton)
              )}
            </Button>
          )}
          Right={({ IconButton }) => (
            <ActionMenu
              placement='bottom-end'
              items={[
                ...(receiptingInstruction
                  ? [
                      {
                        label: 'Create receipt and remove instructions',
                        onClick: handleSaveAndRemoveInstructions
                      }
                    ]
                  : []),
                ...(showCreateAnotherButton
                  ? [
                      {
                        label: getSubmitButtonLabel(
                          unallocatedAmount,
                          allocatedAmount,
                          receiptedAmount
                        ),
                        onClick: handleSubmitAndClose
                      }
                    ]
                  : [])
              ]}
              // Note the ref ensures that the action menu appears in the correct place beneath the button
              Button={React.forwardRef((props, ref) => (
                <IconButton {...props} ref={ref} Icon={ChevronDownIcon} />
              ))}
            />
          )}
        />
      ) : (
        <PrimaryButton
          onClick={handleSubmitAndClose}
          isDisabled={isSubmitDisabled}
          isLoading={isSubmitting}
          type='submit'
        >
          {getSubmitButtonLabel(
            unallocatedAmount,
            allocatedAmount,
            receiptedAmount
          )}
        </PrimaryButton>
      )}
    </StandardDialogFooter>
  );
}
