import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import { PrepaymentBucket } from 'src/modules/prepayments/types/prepayment-bucket';
import { Column, Grid } from 'view/components/@luna/form/grid';

import { TextArea } from 'view/components/inputs/text-area/text-area';

const validate = {
  definitions: {
    name: { rules: 'required' },
    reference_code: { rules: 'regex:/^[a-zA-Z0-9_]+$/' }
  },
  messages: {
    regex: 'The :attribute field can have only letters, numbers and underscore.'
  }
};

export const prepaymentDetailsBlock: BlockConfig<PrepaymentBucket> = {
  id: 'details',
  validate,
  Edit: () => {
    return (
      <Grid columns={2}>
        <Field name='name' label='Name' Input={TextInput} />
        <Field name='reference_code' label='Reference' Input={TextInput} />
        <Column width={2}>
          <Field name='description' label='Description' Input={TextArea} />
        </Column>
      </Grid>
    );
  }
};
