import { ownershipsModel } from 'data/models/entities/ownerships';

import { getSearchResultItemFromObjectAndModel } from 'src/modules/common/utils/search-result-items/get-search-result-items-from-object-and-model';
import { contactsModel } from 'src/modules/contacts/models/contacts';
import { WorkOrderTask } from 'src/modules/tasks/work-orders/types/WorkOrderTask';
import { InitialInvoiceValues } from 'src/modules/bill-processing/types/InitialInvoiceValues';

import { MaintenanceTask } from '../../maintenance/types/MaintenanceTask';

export function mapTaskToInvoiceValues(
  task?: MaintenanceTask | WorkOrderTask
): InitialInvoiceValues {
  if (!task) {
    return {};
  }
  const workOrderTask =
    task.type.id === 'task_work_order' ? (task as WorkOrderTask) : undefined;

  const payableBy = getSearchResultItemFromObjectAndModel(
    task.property?.active_property_ownership?.ownership,
    ownershipsModel
  );

  const payableTo = getSearchResultItemFromObjectAndModel(
    workOrderTask?.details?.work_done_by,
    contactsModel
  );
  return {
    description: task?.description || undefined,
    payable_by: payableBy
      ? {
          object: payableBy
        }
      : undefined,
    payable_to: payableTo
      ? {
          object: payableTo
        }
      : undefined,
    task
  };
}
