import { useDialog } from '@rexlabs/dialog';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { CreateCustomReportRecordDialog } from '../../dialogs/create-custom-report-record-dialog';

export function useGetCreateCustomReportAction() {
  const createCustomReportDialog = useDialog(CreateCustomReportRecordDialog);
  return (): ActionDeclaration => {
    return {
      id: 'create-custom-report',
      group: 'reporting-custom-reports',
      label: 'Create custom report',
      handleAction: async () => {
        return createCustomReportDialog.open();
      }
    };
  };
}
