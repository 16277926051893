import { PostcodeFormValues } from '../blocks/postcode';

export function mapPostcodeFinderFormToPropertyAddress(
  values: PostcodeFormValues
) {
  const postal_code = values.postal_code;
  const post_town = values.address.post_town;
  let line_1 = values.address.formats.single_line;

  try {
    const regex = new RegExp(`,\\s*${post_town},\\s*${postal_code}`);
    line_1 = line_1.replace(regex, '');
  } catch (error) {
    // ignore and use fallback
    line_1 = values.address.formats.single_line;
  }

  const payload = {
    line_1,
    post_town,
    postal_code,
    locality: values.address.dependent_locality
  };

  return payload;
}
