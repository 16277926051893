import { Field } from '@rexlabs/form';
import React, { useEffect } from 'react';

import { Grid } from 'view/components/@luna/form/grid';
import { BlockConfig } from 'view/components/record-screen/types';
import { BulkEditField } from '../components/bulk-edit-field';
import { BulkEditFieldsSelect } from '../components/bulk-edit-fields-select';
import { useBulkEditFields } from '../hooks/use-bulk-edit-fields';

export const bulkEditBlock: BlockConfig = {
  id: 'bulk-edit',
  title: 'Bulk Edit',
  validate: {
    definitions: {
      fields: {
        rules: 'required'
      }
    }
  },
  Edit: ({ values, blockProps, setFieldValue }) => {
    const { data } = useBulkEditFields(blockProps.recordType);

    useEffect(() => {
      const fieldsDefaultValue = data?.fields?.filter((field) => {
        return blockProps.defaultFields?.includes(field.id);
      });

      setFieldValue?.('fields', fieldsDefaultValue ?? []);
    }, [blockProps.defaultFields, data?.fields, setFieldValue]);

    return (
      <Grid columns={1}>
        <Field
          id='bulk-edit'
          name='fields'
          label='Fields'
          Input={BulkEditFieldsSelect}
          inputProps={{
            recordType: blockProps.recordType
          }}
        />

        {values?.fields?.map((field) => {
          return (
            <Field
              optional={false}
              key={field.id}
              id={field.id}
              name={field.id}
              label={field.label}
              Input={BulkEditField}
              inputProps={{
                parentRecordType: blockProps.recordType,
                useExistingValue: true,
                type: field.type.id,
                ...remapTypeConfig(field.type_config)
              }}
            />
          );
        })}
      </Grid>
    );
  }
};

function remapTypeConfig(typeConfig: Record<string, any>): Record<string, any> {
  const output: Record<string, any> = {};

  if (typeConfig) {
    if ('resource_type' in typeConfig) {
      output.recordType = typeConfig.resource_type.id;
    }

    if ('value_list' in typeConfig) {
      output.valueListId = typeConfig.value_list.id;
    }
  }

  return output;
}
