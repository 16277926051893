import { useDialog } from '@rexlabs/dialog';
import { ReceiptFundsDialog } from 'src/modules/trust-journal-entries/dialogs/receipt-funds-dialog';
import SearchIcon from 'view/components/icons/search';
import { usePermission } from 'src/modules/authorization/roles/hooks/use-permission';
import { AccessDeniedDialog } from 'view/components/dialogs/access-denied-dialog';
import { BatchReceiptingItem } from '../../types';
import { useBankAccountState } from '../use-bank-account-state';
import { useBatchReceiptingStatsSummary } from '../use-batch-receipting-stats-summary';

export function useGetMatchAndReceiptAction() {
  const { isStandardUser, roleName } = usePermission();
  const { bankAccount } = useBankAccountState();

  const { refetch: refetchStatsSummary } = useBatchReceiptingStatsSummary(
    bankAccount?.id
  );

  const receiptFundsDialog = useDialog(ReceiptFundsDialog);
  const accessDeniedDialog = useDialog(AccessDeniedDialog);

  return (item: BatchReceiptingItem) => {
    const matchStatusTypeId = item.prioritised_match?.status?.id;

    return {
      label:
        matchStatusTypeId === 'not_matched'
          ? 'Match & receipt'
          : 'Allocate & receipt',
      Icon: SearchIcon,
      onClick: () => {
        if (isStandardUser) {
          return accessDeniedDialog.open({
            role: roleName
          });
        }

        receiptFundsDialog.open({
          onCreate: () => refetchStatsSummary(),
          showTransactionFileDetails: true,
          amount: item.amount,
          description: item.description,
          bankTransactionFile: item.bank_statement_transaction_import?.file,
          reference: item.reference,
          dateOf: item.paid_date,
          bankAccount: item.bank_account,
          bankStatementTransactionId: item.id,
          paidBy: item.paid_by,
          folio: item.prioritised_match.folio
            ? item.prioritised_match.folio
            : undefined,
          receiptFrom: item.prioritised_match.contacts
            ? item.prioritised_match.contacts
            : undefined,
          matchStatus: item.prioritised_match.status.id,
          allowUpdateTenantReference: true,
          paymentMethod:
            item.type.id === 'payment_gateway'
              ? {
                  id: 'payment_gateway',
                  label: 'Payment Gateway'
                }
              : undefined
        });
      }
    };
  };
}
