import React from 'react';

import { Contact } from 'src/modules/contacts/types/contact-types';

import { BlockConfig } from 'view/components/record-screen/types';
import { ActionButtons } from 'view/components/@luna/action-buttons';

import { useGetGenerateDeferredFeeInvoiceAction } from 'src/modules/deferred-fees/hooks/action-declarations/use-get-generate-deferred-fee-invoice-action';
import { useGetCreateManualDeferredFeeEntryAction } from 'src/modules/deferred-fees/hooks/action-declarations/use-get-create-manual-deferred-fee-entry-action';

import { SupplierCommissionLedgerTabbedTable } from '../components/supplier-commission-ledger-tabbed-table';

export const supplierCommissionLedgerBlock: BlockConfig<Contact> = {
  id: 'supplier_commission_ledger',
  title: 'Supplier commission ledger',
  Actions: ({ data }) => {
    const getCreateManualSupplierCommissionEntryAction = useGetCreateManualDeferredFeeEntryAction();
    const getGenerateDeferredFeeInvoiceAction = useGetGenerateDeferredFeeInvoiceAction();

    if (!data) {
      return null;
    }

    return (
      <ActionButtons
        actions={[
          getCreateManualSupplierCommissionEntryAction({
            folio: data,
            entryType: 'supplier_commission'
          }),
          getGenerateDeferredFeeInvoiceAction({
            folio: data,
            type: 'supplier_commission'
          })
        ]}
      />
    );
  },
  View: ({ data }) => {
    if (!data) {
      throw new Error('Supplier is required to view commission ledger');
    }

    return <SupplierCommissionLedgerTabbedTable supplierId={data.id} />;
  }
};
