import { DialogProps, useErrorDialog } from '@rexlabs/dialog';

import React from 'react';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useModelActions } from '@rexlabs/model-generator';
import { tasksModel } from 'src/modules/tasks/common/models/tasks-model';
import { useMessageTriggerSettings } from 'src/modules/communications/messages/settings/message-triggers/hooks/use-message-trigger-settings';
import { useToast } from 'view/components/@luna/notifications/toast';
import { fetchTemplate } from 'src/modules/communications/message-templates/utils/fetch-template';
import { AxiosResponse } from 'axios';
import { InspectionTask } from 'src/modules/tasks/inspections/types/InspectionTask';
import { api } from 'utils/api/api-client';
import { useQuery } from 'react-query';
import { completeAndSyncInspectionReportBlock } from 'src/modules/tasks/inspections/blocks/complete-and-sync-inspection-report-block';
import { FLAGS } from 'utils/feature-flags';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { useGetReportName } from 'src/modules/tasks/inspections/entry-exit/hooks/use-get-report-name';

export interface CompleteInspectionReportDialogProps extends DialogProps {
  inspectionTask: InspectionTask;
}

export interface CompleteInspectionFormData {
  should_sync_to_property: boolean;
}

const content = [
  {
    id: 'complete-report',
    label: 'Complete report',
    blocks: [completeAndSyncInspectionReportBlock]
  }
];

export function CompleteInspectionReportDialog({
  onClose,
  inspectionTask
}: CompleteInspectionReportDialogProps) {
  const { updateItem } = useModelActions(tasksModel);
  const { messageTriggerSettings } = useMessageTriggerSettings();
  const { open: openErrorDialog } = useErrorDialog();
  const { addToast } = useToast();
  const getInspectionReportName = useGetReportName();
  const { hasFeature } = useFeatureFlags();

  const defaultTemplateId =
    messageTriggerSettings['inspection-template']?.template_id;

  const templateQuery = useQuery(['inspection-message-template'], () =>
    fetchTemplate(defaultTemplateId)
  );

  const owners =
    inspectionTask.property?.active_property_ownership?.ownership.owners || [];

  const isSendImmediately =
    !!templateQuery.data?.send_immediately &&
    owners.filter((owner) => owner.contact.primary_email).length ===
      owners.length;
  const hasTemplatedAttachment = templateQuery.data?.templated_attachments?.includes(
    'inspection_report'
  );

  const handleSubmit: RecordSubmitHandler<CompleteInspectionFormData> = async ({
    values
  }) => {
    try {
      const response: AxiosResponse<InspectionTask> = await updateItem({
        id: inspectionTask.id,
        data: {
          details: {
            status: {
              id: 'report_completed'
            }
          }
        }
      });

      if (
        values.should_sync_to_property &&
        hasFeature(FLAGS.ENTRY_EXIT_INSPECTION_REPORT)
      ) {
        await api.put(`tasks/inspection-reports/${inspectionTask.id}/sync`);
      }

      const { data } = response;

      addToast({
        description: `${getInspectionReportName(
          inspectionTask
        )} saved to documents tab`
      });

      if (templateQuery?.data) {
        const getDescription = () => {
          const baseMessage = `Message${
            hasTemplatedAttachment
              ? ` including the ${getInspectionReportName(inspectionTask)}`
              : ''
          }`;

          if (templateQuery.data?.send_immediately) {
            return `${baseMessage} sent to ${
              inspectionTask.property?.active_property_ownership?.ownership
                ?.display_name || 'ownership'
            }.`;
          }

          return `${baseMessage} saved to the outbox.`;
        };

        addToast({
          description: getDescription()
        });
      }

      return data;
    } catch (error) {
      openErrorDialog(error);
    }
  };
  return (
    <RecordDialog
      initialValues={{ should_sync_to_property: true }}
      isLoading={templateQuery.isLoading}
      blockProps={{ isSendImmediately, hasTemplatedAttachment }}
      title='Complete report'
      content={content}
      onClose={onClose}
      handleSubmit={handleSubmit}
      submitLabel='Complete report'
    />
  );
}
