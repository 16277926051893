import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useDialog } from '@rexlabs/dialog';
import { AddIcon } from 'view/components/icons/add';
import { TodoFormData } from 'src/modules/tasks/todos/types/TodoFormData';
import { CustomField } from 'src/modules/custom-fields/types/CustomField';
import { CreateCustomTaskRecordDialog } from '../dialogs/create-custom-task-record-dialog';
import { CustomTaskTemplate } from '../../settings/types/CustomTaskTemplate';

interface UseGetCreateCustomTaskActionProps {
  customTaskTemplate: CustomTaskTemplate | null;
  onCreate?: (data) => void;
  isNested?: boolean;
  isFixture?: boolean;
  initialValues?: Partial<TodoFormData>;
}

export function useGetCreateCustomTaskAction({
  customTaskTemplate: propCustomTaskTemplate,
  onCreate,
  isNested,
  initialValues,
  isFixture
}: UseGetCreateCustomTaskActionProps) {
  const { open } = useDialog(CreateCustomTaskRecordDialog, {
    target: isNested ? 'nested-dialogs' : 'dialogs'
  });

  return ({
    customTaskTemplate = propCustomTaskTemplate
  } = {}): SingleActionDeclaration | null => {
    if (!customTaskTemplate) return null;
    return {
      id: 'create-custom-task',
      intent: 'primary',
      group: 'custom-task',
      label: `Create ${customTaskTemplate.label}`,
      Icon: AddIcon,
      handleAction: async () => {
        open({
          customTaskTemplate,
          isFixture,
          onCreate,
          initialValues: {
            ...initialValues,
            status: {
              id: 'not_started',
              label: 'Not started'
            },
            priority: {
              id: 'normal',
              label: 'Normal'
            },
            custom_fields: {
              data: (customTaskTemplate.custom_fields?.data ?? []).map(
                (field) => ({
                  ...field,
                  id: undefined,
                  derived_from_custom_field: field as CustomField & {
                    id: string;
                  }
                })
              )
            },
            custom_field_values: customTaskTemplate.custom_field_values ?? {}
          }
        });
      }
    };
  };
}
