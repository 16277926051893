import { useErrorDialog } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import { financialsInvoicesModel } from 'data/models/entities/financials/invoices';
import * as React from 'react';
import { financialsUploadedBillsModel } from 'src/modules/bill-processing/models/uploaded-bills';
import { useToast } from 'view/components/@luna/notifications/toast';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { Bill } from 'src/modules/bill-processing/types/Bill';
import { mapDraftInvoiceValuesToPostData } from '../utils/map-invoice-form-values-to-post-data';

export type UseDraftInvoiceSubmitHandlerProps = {
  onSave?: (uploadedBill?: Bill) => any;
  isBillProcessingDialog?: boolean;
};

export function useDraftInvoiceSubmitHandler(
  props: UseDraftInvoiceSubmitHandlerProps
) {
  const { onSave, isBillProcessingDialog = false } = props;

  const { importBills, updateItem } = useModelActions(
    financialsUploadedBillsModel
  );
  const { refreshLists } = useModelActions(financialsInvoicesModel);
  const { open: openErrorDialog } = useErrorDialog();
  const { addToast } = useToast();

  const handleSubmit: RecordSubmitHandler = React.useCallback(
    async ({ values }) => {
      const uploaded_bill = values.uploaded_bill;

      if (uploaded_bill && !isBillProcessingDialog) {
        if (!uploaded_bill?.data) return;
        const { data: bill } = await Promise.resolve(uploaded_bill?.data);
        try {
          const uploadedBill = await importBills({
            fileIds: [bill.id]
          });
          await refreshLists();
          values.uploaded_bill = { id: uploadedBill.data[0].id };
        } catch (error) {
          openErrorDialog(error);
          return;
        }
      }

      const response = await updateItem({
        id: uploaded_bill.id,
        data: {
          draft_invoice: mapDraftInvoiceValuesToPostData(values)
        }
      });

      await refreshLists();

      const uploadedBill = response?.data;

      await Promise.resolve(onSave?.(uploadedBill));

      addToast({
        description: `You have successfully updated the draft invoice.`
      });

      return uploadedBill;
    },
    [
      isBillProcessingDialog,
      addToast,
      onSave,
      refreshLists,
      importBills,
      openErrorDialog,
      updateItem
    ]
  );

  return handleSubmit;
}
