import { useDialog } from '@rexlabs/dialog';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { EditMetricWriteRuleDialog } from '../../dialogs/edit-metric-write-rule-dialog';
import { MetricWriteRule } from '../../types/MetricWriteRule';

export function useGetEditMetricWriteRuleAction() {
  const { open: openEditMetricWriteRuleDialog } = useDialog(
    EditMetricWriteRuleDialog
  );

  return (metricWriteRule: MetricWriteRule): ActionDeclaration => {
    return {
      id: 'edit-metric-write-rule',
      group: 'metric-write-rules',
      label: 'Edit Metric Write Rule',
      handleAction: async () => {
        openEditMetricWriteRuleDialog({
          metricWriteRule
        });
      }
    };
  };
}
