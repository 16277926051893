import * as React from 'react';
import { DialogProps } from '@rexlabs/dialog';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import { uniqBy } from 'lodash';
import { useToast } from 'view/components/@luna/notifications/toast';
import { pluralize } from 'utils/formatters';
import { UserGroup } from '../types/UserGroup';
import { addMembersBlock } from '../blocks/add-members-block';
import { userGroupsModel } from '../models/user-groups-model';

export interface AddMemberDialogProps extends DialogProps {
  userGroup: UserGroup;
}

const content = [
  {
    id: 'add-member',
    label: 'Add member',
    blocks: [addMembersBlock]
  }
];

export function AddMemberDialog({ onClose, userGroup }: AddMemberDialogProps) {
  const { updateItem, refreshItem } = useModelActions(userGroupsModel);
  const { addToast } = useToast();
  const handleSubmit = async ({ values }) => {
    const existingMembers =
      userGroup.users?.data.map((user) => ({ id: user.id })) ?? [];
    const newMembers = values.users.map((user) => ({ id: user.id }));

    // Combine existing members with new members, and remove duplicates.
    // This way, the backend can just sync the provided list of members on update, which is a simpler approach.
    const users = uniqBy([...existingMembers, ...newMembers], 'id');

    await updateItem({
      id: userGroup.id,
      data: {
        users
      }
    });

    const countAdded = newMembers.length;

    await addToast({
      description: `Added ${countAdded} ${pluralize(
        'members',
        countAdded
      )} to group`
    });
    return refreshItem({ id: userGroup.id });
  };

  // We don't want to prefill existing members, because on submit we combine the new members with existing anyway,
  // so it would be confusing to have them in the add member dialog.
  const initialValues = { users: [] };

  return (
    <RecordDialog
      title='Add member'
      data={userGroup}
      initialValues={initialValues}
      content={content}
      onClose={onClose}
      handleSubmit={handleSubmit}
    />
  );
}
