import React from 'react';
import { ErrorBoundary } from '@rexlabs/error-boundary';
import { FallbackComponentProps } from '@rexlabs/error-boundary/lib/types/error-boundary';
import { PmApiError } from 'utils/api/create-error-from-response';
import { Card } from 'view/components/card';
import { ErrorCard } from 'view/components/record-screen/cards/error-card';
import { UnauthorizedCard } from './unauthorized-card';

export type CardFallbackComponentProps = FallbackComponentProps & {
  error: FallbackComponentProps['error'] | PmApiError;
  title: React.ReactNode;
};

function CardFallbackComponent(props: CardFallbackComponentProps) {
  if ('statusCode' in props.error && props.error?.statusCode === 403) {
    // return custom unauthorized error card
    return <UnauthorizedCard {...props} />;
  }
  // return default error card
  return (
    <Card title={props.title}>
      <ErrorCard />
    </Card>
  );
}

interface CardErrorBoundaryProps {
  title?: React.ReactNode;
  children: React.ReactNode;
}

export function CardErrorBoundary({ title, children }: CardErrorBoundaryProps) {
  return (
    <ErrorBoundary
      FallbackComponent={(props) => (
        <CardFallbackComponent {...props} title={title} />
      )}
    >
      {children}
    </ErrorBoundary>
  );
}
