import React from 'react';
import ROUTES from 'routes/app';
import { StatBlocks } from 'view/components/@luna/stat-block';
import { formatCurrency } from 'utils/formatters';
import getReconciledAtStatusColor from 'src/modules/bank-accounts/utils/reconcilation-status-color';
import getOverdueInvoiceStatusColour from 'src/modules/invoices/utils/overdue-invoice-status-color';
import { getDaysFromNow } from 'utils/dates/dates';
import { DashboardFinancialSummary } from '../types/dashboard-summary';

export const DashboardStatBlocks = ({
  financial_summary
}: {
  financial_summary: DashboardFinancialSummary;
}) => {
  return (
    <StatBlocks
      stats={[
        {
          label: 'Overdue invoices',
          privilege: 'invoices.read',
          value: `${financial_summary?.overdue_invoice_count} invoices`,
          to: ROUTES.INVOICES_LIST,
          hash: 'tab=overdue',
          intent: getOverdueInvoiceStatusColour(
            financial_summary?.overdue_invoice_count
          )
        },
        {
          label: 'Outstanding bank deposits',
          privilege: 'bank-deposits.read',
          value: formatCurrency(
            financial_summary?.outstanding_deposits_total_amount
          ),
          to: ROUTES.BANK_DEPOSITS_LIST
        },
        {
          label: 'Outstanding bank withdrawals',
          privilege: 'bank-withdrawals.read',
          value: formatCurrency(
            financial_summary?.outstanding_withdrawals_total_amount
          ),
          to: ROUTES.BANK_WITHDRAWALS_LIST
        },
        {
          label: 'Last reconciliation',
          privilege: 'reconciliations.read',
          value: getDaysFromNow(financial_summary.last_reconciled_at),
          to: ROUTES.RECONCILIATIONS,
          intent: getReconciledAtStatusColor(
            financial_summary?.last_reconciled_at
          )
        },
        {
          label: 'Disbursements due',
          privilege: 'disbursements.read',
          value: '3 owners, 5 contacts',
          workInProgress: true
        }
      ]}
    />
  );
};
