import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { EntityFee } from 'src/modules/agency-fees/types/EntityFee';
import { capitalize } from 'lodash';
import { getRecordTypeName } from 'utils/records/get-record-type-name';
import { PropertyOwnership } from 'src/modules/property-ownerships/types/property-ownership-types';
import { InvoiceLineItem } from 'data/models/entities/financials/invoices';
import { useGetAgencyContact } from 'src/modules/contacts/hooks/use-get-agency-contact';
import { Ownership } from 'data/models/entities/ownerships';
import { useCreateInvoiceDialog } from 'src/modules/invoices/hooks/use-create-invoice-dialog';

export function useGetCreateInvoiceFromEntityFee() {
  const openInvoiceDialog = useCreateInvoiceDialog();

  const agencyContact = useGetAgencyContact();

  return (
    entityFee: EntityFee,
    entity: PropertyOwnership | Ownership
  ): SingleActionDeclaration => {
    return {
      id: 'create-invoice-from-entity-fee',
      label: 'Create invoice',
      handleAction: () => {
        let ownership, property;
        if ('ownership' in entity) {
          ownership = entity.ownership;
          property = entity.property;
        } else {
          ownership = entity;
        }

        openInvoiceDialog({
          initialValues: {
            description: entityFee.agency_fee?.name,
            ...(agencyContact && {
              payable_to: {
                object: {
                  id: agencyContact.id,
                  label: agencyContact.display_name,
                  type: {
                    id: 'contact',
                    label: capitalize(getRecordTypeName('contact'))
                  }
                }
              }
            }),
            payable_by: {
              object: {
                id: ownership.id,
                label: ownership.display_name,
                type: {
                  id: 'ownership',
                  label: capitalize(getRecordTypeName('ownership'))
                }
              }
            },
            line_items: [
              {
                description: entityFee.agency_fee!.name,
                amount: entityFee.fee_amount.amount,
                payable_by_chart_of_accounts_account:
                  entityFee.agency_fee?.ownership_chart_of_accounts_account,
                payable_to_chart_of_accounts_account:
                  entityFee.agency_fee?.agency_chart_of_accounts_account,
                tax_type: entityFee.tax_type,
                payable_by_property: property ?? null
              } as InvoiceLineItem
            ],
            is_tax_included: entityFee.is_tax_included
          }
        });
      },
      group: 'create-invoice'
    };
  };
}
