import { Column } from '@rexlabs/grid';
import { Field } from '@rexlabs/form';
import { Select } from '@rexlabs/select';
import { normaliseValueListItem } from 'utils/normaliser/value-list-item';
import { TextInput } from '@rexlabs/text-input';
import { Checkbox } from '@rexlabs/checkbox';
import React from 'react';
import { FormGrid } from 'view/components/@luna/grid/form';
import { MetricConfig } from 'src/modules/metric-write-rules/types/Metric';
import { ValueListValue } from 'data/models/types';
import { capitalize, isString, lowerCase } from 'lodash';

export interface MetricValueFieldProps {
  label: string;
  fieldPrefix: string;
  presetOptions?: ValueListValue<any>[];
  setFieldValue: (field: string, value: any) => void;
  showInvert?: boolean;
  value?:
    | MetricConfig['metric_value']
    | MetricConfig['metric_attribute_to_user_id']
    | MetricConfig['metric_time'];
}

const typeOptions = [
  { id: 'json_path', label: 'Jsonpath' },
  { id: 'literal', label: 'Literal' },
  { id: 'prompt', label: 'Prompt' },
  { id: 'preset', label: 'Preset' }
];

export function MetricValueField({
  fieldPrefix,
  showInvert,
  setFieldValue,
  presetOptions,
  value
}: MetricValueFieldProps) {
  let valueInputField;
  switch (value?.type?.id) {
    case 'json_path':
    case 'literal':
      valueInputField = (
        <Field name={`${fieldPrefix}.value`} label='Value' Input={TextInput} />
      );
      break;
    case 'preset':
      valueInputField = (
        <Field
          name={`${fieldPrefix}.value`}
          label='Value'
          Input={Select}
          inputProps={{
            items: presetOptions,
            normaliser: (item) => {
              if (isString(item)) {
                return {
                  id: item,
                  label: capitalize(lowerCase(item))
                };
              }
              return {
                id: item.id,
                label: item.label
              };
            }
          }}
        />
      );
      break;
    case 'prompt':
      valueInputField = null;
      break;
  }

  return (
    <FormGrid columns={12} mt={'0rem'}>
      <Column width={4}>
        <Field
          name={`${fieldPrefix}.type`}
          label='Type'
          Input={Select}
          onChange={() => setFieldValue(`${fieldPrefix}.value`, '')}
          inputProps={{
            items: typeOptions,
            normaliser: normaliseValueListItem
          }}
        />
      </Column>
      <Column width={8}>{valueInputField}</Column>
      {showInvert && (
        <>
          <Column width={4} />
          <Column width={8}>
            <Field
              name={`${fieldPrefix}.invert`}
              Input={Checkbox}
              optional={false}
              inputProps={{
                label: 'Invert value'
              }}
            />
          </Column>
        </>
      )}
    </FormGrid>
  );
}
