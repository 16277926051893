import { useStatusChangeAction } from 'src/modules/common/actions/status/use-status-change-action';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

import { deferredFeeLineItemModel } from '../../models/financials-deferred-fee-line-items';
import { DeferredFeeLineItem } from '../../types/DeferredFeeLineItem';

export function useGetVoidDeferredLineItemAction() {
  const statusChangeAction = useStatusChangeAction(deferredFeeLineItemModel);

  return ({ item }: { item: DeferredFeeLineItem }): ActionDeclaration =>
    statusChangeAction({
      record: item,
      status: 'voided',
      generalOptions: {
        avoidItemRefresh: true
      },
      dialogOptions: {
        intent: 'danger',
        title: 'Void entry',
        type: 'reason'
      }
    });
}
