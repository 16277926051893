import React, { ComponentProps, lazy, Suspense } from 'react';
import { Monaco } from '@monaco-editor/react';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import LoadingState from 'view/components/states/compact/loading';

const MonacoEditor = lazy(() => import('@monaco-editor/react'));

const defaultStyles = StyleSheet({
  editorWrapper: {
    borderRadius: 4,
    width: '100%',
    height: '300px',
    overflow: 'hidden'
  }
});

export interface CodeInputProps {
  onChange: (event: { target: { value: string } }) => void;
  onBlur: (event: { target: { value: string } }) => void;
  value?: string;
  monacoProps?: ComponentProps<typeof MonacoEditor>;
}

export const generateJavaScriptBeforeMountHandler = (types?: string) => (
  monaco: Monaco
) => {
  if (types) {
    monaco.languages.typescript.javascriptDefaults.addExtraLib(
      types,
      'global.d.ts'
    );
  }

  monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
    target: monaco.languages.typescript.ScriptTarget.ES2020,
    module: monaco.languages.typescript.ModuleKind.CommonJS,
    moduleResolution: monaco.languages.typescript.ModuleResolutionKind.NodeJs,
    lib: ['es2020'],
    allowNonTsExtensions: true,
    noEmit: true,
    typeRoots: ['node_modules/@types']
  });
};

export function CodeInput({
  onChange,
  value,
  monacoProps,
  onBlur
}: CodeInputProps) {
  const s = useStyles(defaultStyles);

  return (
    <div {...s('editorWrapper')}>
      <Suspense fallback={<LoadingState />}>
        <MonacoEditor
          options={{
            minimap: { enabled: false },
            fixedOverflowWidgets: true,
            scrollBeyondLastLine: false,
            padding: { top: 10, bottom: 10 },
            scrollbar: { alwaysConsumeMouseWheel: false },
            ...monacoProps?.options
          }}
          height='100%'
          value={value}
          onChange={(value, event) => {
            onChange({ target: { value: value || '' } });
            onBlur?.({ target: { value: value || '' } });
            if (monacoProps?.onChange) {
              monacoProps.onChange(value, event);
            }
          }}
          theme={'vs-dark'}
          {...monacoProps}
        />
      </Suspense>
    </div>
  );
}
