import { tasksModel } from 'src/modules/tasks/common/models/tasks-model';
import { useStatusChangeAction } from 'src/modules/common/actions/status/use-status-change-action';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

import { getInvoicePayableBySuggestedItems } from 'src/modules/tasks/common/utils/get-invoice-payable-by-suggested-items';
import { useCreateInvoiceDialog } from 'src/modules/invoices/hooks/use-create-invoice-dialog';
import { getCreateInvoiceInitialValues } from '../../utils/get-create-invoice-initial-values';
import { WorkOrderTask } from '../../types/WorkOrderTask';
import { getWorkOrderStatusChangeTransformPayload } from '../../utils/get-work-order-status-change-transform-payload';

export function useGetAddBillAction() {
  const openInvoiceDialog = useCreateInvoiceDialog();
  const getStatusChangeAction = useStatusChangeAction(tasksModel);

  return (data: WorkOrderTask) => {
    const statusChangeTransformPayload = getWorkOrderStatusChangeTransformPayload(
      data
    );

    const { handleAction: changeStatusAction } = getStatusChangeAction({
      record: data,
      status: 'bill_added',
      dialogOptions: { type: 'none' },
      transformPayload: statusChangeTransformPayload
    });

    return {
      id: 'add-bill',
      label: 'Add bill',
      group: 'add-bill',
      intent: 'primary',
      handleAction: () => {
        const initialValues = getCreateInvoiceInitialValues(data);

        openInvoiceDialog({
          initialValues,
          suggestions: {
            payableBy: getInvoicePayableBySuggestedItems({
              property: data?.property
            })
          },
          onSave: changeStatusAction
        });
      }
    } as SingleActionDeclaration;
  };
}
