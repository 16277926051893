import { usePrivileges } from 'src/modules/authorization/roles/hooks/use-privileges';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useDialog } from '@rexlabs/dialog';
import { CreateRoleRecordDialog } from '../../dialogs/create-role-record-dialog';

export function useGetCreateRoleAction() {
  const { hasPrivilege } = usePrivileges();
  const { open } = useDialog(CreateRoleRecordDialog);
  return (): ActionDeclaration => {
    return {
      id: 'create-role',
      group: 'authorization-roles',
      label: 'Create Role',
      hidden: !hasPrivilege('roles.create'),
      handleAction: async () => {
        open();
      }
    };
  };
}
