import { useCallback, useEffect } from 'react';
import { api } from 'utils/api/api-client';
import { useDialog } from '@rexlabs/dialog';
import { CustomValidationErrorDialog } from 'src/modules/custom-validation/dialogs/custom-validation-error-dialog';
import { RexApiResponse } from '@rexlabs/api-client';

type Interceptor = Parameters<typeof api.addResponseInterceptor>[0];

export function isCustomValidationErrorResponse(response: RexApiResponse) {
  return (
    response.status === 422 &&
    response.data.errors &&
    'custom_validation' in response.data.errors
  );
}

export function CustomValidationInterceptor() {
  const customValidationErrorDialog = useDialog(CustomValidationErrorDialog);

  const interceptor: Interceptor = useCallback<Interceptor>(
    ((response) => {
      if (!isCustomValidationErrorResponse(response)) {
        return response;
      }

      return new Promise<RexApiResponse>((resolve, reject) => {
        customValidationErrorDialog.open({
          requestConfig: response.config,
          errors: response.data.errors.custom_validation,
          onSaveAnywaySuccess: (r) => resolve(r),
          onSaveAnywayFailure: (r) => reject(r),
          onCancel: () => {
            reject({ problem: 'CANCEL_ERROR' });
          }
        });
      });
    }) as Interceptor,
    [customValidationErrorDialog]
  );
  useEffect(() => {
    api.addResponseInterceptor(interceptor);
    return () => {
      // @ts-ignore
      api.responseInterceptors = api.responseInterceptors.filter(
        (i) => interceptor !== i
      );
    };
  }, [interceptor]);

  return null;
}
