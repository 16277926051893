import { getSearchResultItemFromObjectAndModel } from 'src/modules/common/utils/search-result-items/get-search-result-items-from-object-and-model';
import { parseUploadedFileMeta } from 'utils/files';
import { ownershipsModel } from 'data/models/entities/ownerships';
import { tasksModel } from '../../common/models/tasks-model';
import { MaintenanceTask } from '../../maintenance/types/MaintenanceTask';
import { QuoteTask } from '../../quotes/types/QuoteTask';
import { WorkOrderFormData } from '../types/WorkOrderFormData';
import { getOwnershipFromProperty } from '../utils/get-ownership-from-property';
import { getSearchResultItemFromManagedBy } from '../../common/utils/get-search-result-item-from-managed-by';

export async function mapRelatedTaskToCreateWorkOrderForm(
  task: MaintenanceTask | QuoteTask
): Promise<Partial<WorkOrderFormData>> {
  const parentTask = task.type.id === 'task_quote' ? task.parent_task : task;

  const ownership = parentTask?.property
    ? await getOwnershipFromProperty(parentTask.property)
    : null;

  const baseFormData: Partial<WorkOrderFormData> = parentTask
    ? {
        parent_task: getSearchResultItemFromObjectAndModel(
          parentTask,
          tasksModel
        ),
        summary: `Work order for ${parentTask.summary}`,
        description: parentTask.description,
        details: {
          ...(parentTask.details &&
            ('access_provided_by' in parentTask.details
              ? {
                  access_provided_by: parentTask.details?.access_provided_by
                }
              : {})),
          ...(parentTask.details && 'images' in parentTask.details
            ? { images: parseUploadedFileMeta(parentTask?.details?.images) }
            : {}),
          work_done_by_type: 'supplier',
          ...(ownership
            ? {
                bill_to: getSearchResultItemFromObjectAndModel(
                  ownership,
                  ownershipsModel
                )
              }
            : {})
        },
        property: parentTask?.property || null,
        managed_by: getSearchResultItemFromManagedBy(parentTask.managed_by),
        priority: parentTask?.priority
      }
    : {};

  // if the related task is a quote then we want to
  // set additional values specific to the quote
  if (task.type.id === 'task_quote') {
    const quoteTask = task as QuoteTask;
    const supplier = quoteTask.details?.supplier;
    const price = quoteTask.details?.amount;

    return {
      ...baseFormData,
      details: {
        ...baseFormData.details,
        quote: quoteTask,
        work_done_by_type: 'supplier',
        supplier,
        ...(price ? { amount: price } : {})
      }
    };
  }

  return baseFormData;
}
