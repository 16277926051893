import React from 'react';
import { useQueryClient } from 'react-query';
import {
  useStatusChangeAction,
  StatusChangeActionArgs
} from 'src/modules/common/actions/status/use-status-change-action';
import { invokeActionDeclaration } from 'src/modules/common/actions/utils/invoke-action-declaration';
import WarningCircleIcon from 'view/components/icons/warning-circle';
import { useDialog } from '@rexlabs/dialog';
import { FLAGS } from 'utils/feature-flags';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { AccessDeniedDialog } from 'view/components/dialogs/access-denied-dialog';
import { usePermission } from 'src/modules/authorization/roles/hooks/use-permission';
import { bankStatementTransactionsModel } from '../../models/bank-statement-transaction-model';
import { BatchReceiptingItem } from '../../types';
import { useBankAccountState } from '../use-bank-account-state';
import { bankStatementTransactionsQueryKey } from '../use-bank-statement-transactions-query';
import {
  getTransactionLabel,
  LabelWithIcon
} from '../use-batch-receipting-button-config';
import { useBatchReceiptingStatsSummary } from '../use-batch-receipting-stats-summary';
import { SuspendTransactionDialog } from '../../dialogs/suspend-transaction-dialog';

export function useGetSuspendAction() {
  const { hasFeature } = useFeatureFlags();
  const { isStandardUser, roleName } = usePermission();

  const queryClient = useQueryClient();

  const { bankAccount } = useBankAccountState();

  const { refetch: refetchStatsSummary } = useBatchReceiptingStatsSummary(
    bankAccount?.id
  );

  const getStatusChangeAction = useStatusChangeAction(
    bankStatementTransactionsModel
  );

  const suspendTransactionDialog = useDialog(SuspendTransactionDialog);
  const accessDeniedDialog = useDialog(AccessDeniedDialog);

  const hasSuspensionFlowFeature = hasFeature(
    FLAGS.BATCH_RECEIPTING_SUSPENSION_FLOW
  );

  return (item: BatchReceiptingItem) => {
    const transactionLabel = getTransactionLabel(item);
    return {
      label: LabelWithIcon('Suspend', <WarningCircleIcon />),
      Icon: WarningCircleIcon,
      onClick: async () => {
        if (isStandardUser) {
          return accessDeniedDialog.open({
            role: roleName
          });
        }

        hasSuspensionFlowFeature
          ? suspendTransactionDialog.open({ batchReceiptingItem: item })
          : await invokeActionDeclaration<
              StatusChangeActionArgs<'suspended'>[]
            >(getStatusChangeAction, {
              record: item,
              status: 'suspended',
              dialogOptions: {
                title: 'Suspend transaction',
                reasonLabel: 'Comment',
                description: (
                  <>
                    Do you want to suspend the transaction for{' '}
                    {transactionLabel}?
                  </>
                ),
                afterAction: async () => {
                  await refetchStatsSummary();
                  await queryClient.refetchQueries({
                    queryKey: [bankStatementTransactionsQueryKey],
                    refetchPage: () => true
                  });
                }
              },
              toastOptions: {
                description: (
                  <>Transaction for {transactionLabel} successfully suspended</>
                )
              }
            });
      }
    };
  };
}
