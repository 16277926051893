import { ActionMenuItem } from '@rexlabs/action-menu';
import { useDialog } from '@rexlabs/dialog';
import { downloadFile } from 'utils/files';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'routes/app';
import { createTableRouteConfig } from 'src/modules/common/utils/create-table-route-config';
import { AccessDeniedDialog } from 'view/components/dialogs/access-denied-dialog';
import { usePermission } from 'src/modules/authorization/roles/hooks/use-permission';
import { BankStatementTransactionImport } from '../types/bank-statement-transaction-import';

export function useUploadedBankStatementActions() {
  const getDownloadFileAction = useGetDownloadFileAction();
  return (
    uploadedStatement: BankStatementTransactionImport,
    bankAccountId?: string
  ): ActionMenuItem[] => {
    if (!uploadedStatement) return [];

    const filters = [
      {
        field: 'bank_statement_transaction_import_id',
        op: 'eq' as const,
        value: {
          ...uploadedStatement,
          label: uploadedStatement.name,
          value: uploadedStatement.id
        }
      }
    ];

    const routeQuery = {
      query: {
        bankAccount: bankAccountId
      }
    };

    return [
      getViewTransaction(
        'View unreceipted transactions',
        ROUTES.BATCH_RECEIPTING_LIST_SCREEN,
        // @ts-ignore - filters can be object
        createTableRouteConfig(filters, 'all_active', routeQuery)
      ),
      getViewTransaction(
        'View receipted transactions',
        ROUTES.TRUST_JOURNAL_ENTRIES,
        // @ts-ignore - filters can be object
        createTableRouteConfig(filters, 'all', routeQuery)
      ),
      getDownloadFileAction(uploadedStatement)
    ];
  };
}

function useGetDownloadFileAction() {
  const { isStandardUser, roleName } = usePermission();
  const accessDeniedDialog = useDialog(AccessDeniedDialog);

  return (uploadedStatement) => ({
    label: 'Download file',
    onClick: () => {
      if (isStandardUser) {
        return accessDeniedDialog.open({
          role: roleName
        });
      }
      return downloadFile(uploadedStatement.file.download_url);
    }
  });
}

function getViewTransaction(label, route, routeConfig) {
  return {
    label,
    onClick: () => {
      push(route, routeConfig);
    }
  };
}
