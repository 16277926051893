import React, { ComponentProps, useMemo } from 'react';

import { Avatar } from '@rexlabs/avatar';

import { Ownership } from 'data/models/entities/ownerships';
import { formatDateLong } from 'utils/dates/format';
import { Tenancy } from 'data/models/entities/tenancies';
import RelatedRecordConnectionSvg from 'src/assets/misc/related-record-connection.svg';
import { Property } from 'src/modules/properties/types/property-types';
import { PropertyOwnership } from 'src/modules/property-ownerships/types/property-ownership-types';
import { PropertyTenancy } from 'src/modules/property-tenancies/types/property-tenancy-types';
import { Invoice } from 'data/models/entities/financials/invoices';
import { getRecordLabel } from 'utils/records/get-record-label';
import { RecordObject } from 'data/models/types';
import { TrustJournalEntry } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';
import { CreditNote } from 'data/models/entities/financials/credit-notes';
import { startCase } from 'lodash';
import i18n from 'src/modules/i18n/i18n';
import { Task } from 'src/modules/tasks/common/types/Task';
import { TaskType } from 'src/modules/tasks/common/types/TaskType';
import { Portfolio } from 'src/modules/portfolios/models/portfolios-model';
import { UserGroup } from 'src/modules/user-groups/types/UserGroup';
import { Flag } from '../@luna/flag/flag';
import { RecordIcon } from '../record-icon/record-icon';

export type RecordTypeSubset =
  | 'property'
  | 'ownership'
  | 'property_ownership'
  | 'tenancy'
  | 'property_tenancy'
  | 'invoice'
  | 'trust_journal_entry'
  | 'credit_note'
  | TaskType
  | 'user_group'
  | 'portfolio';

type RecordSet =
  | Property
  | Ownership
  | PropertyOwnership
  | Tenancy
  | PropertyTenancy
  | Invoice
  | TrustJournalEntry
  | CreditNote
  | Task<TaskType>
  | UserGroup
  | Portfolio;

function useGetHeadline(record: RecordSet, recordType: RecordTypeSubset) {
  if (recordType === 'property_ownership') {
    return startCase(
      i18n.t(
        'property-ownerships.management-agreement.label.singular',
        'Management Agreement'
      ) as string
    );
  }

  if (recordType === 'property_tenancy') {
    return startCase(
      i18n.t('lease-agreements.label.singular', 'Lease Agreement') as string
    );
  }

  return getRecordLabel({
    type: recordType,
    object: record
  } as RecordObject);
}

function getSubline(record: RecordSet, recordType: RecordTypeSubset) {
  if (
    ['ownership', 'tenancy', 'property'].includes(recordType) &&
    'record_reference' in record
  ) {
    return record.record_reference;
  }

  if (
    'agreement_start_date' in record &&
    'agreement_end_date' in record &&
    record.agreement_start_date
  ) {
    const endDate = record.agreement_end_date
      ? formatDateLong(record.agreement_end_date)
      : 'Ongoing';
    return `${formatDateLong(record.agreement_start_date)} - ${endDate}`;
  }

  // Due to a weird model-generator bug, if an include changes type, the fields can get merged together,
  // so it isn't always safe to check for the presence of a field without asserting the type of the record as a primary check.
  // I.e. if you change a task managed_by from a contact to a user_group, the merged record will still have the contact's display_name field.
  // Much of this code is from before we had the concept of __record_type, so it doesn't always check for it.
  if ('__record_type' in record && record.__record_type === 'user_group') {
    return null;
  }

  if ('display_name' in record) {
    return record.display_name;
  }

  return null;
}

function getSignifier(recordType: RecordTypeSubset) {
  if (
    recordType === 'property_ownership' ||
    recordType === 'property_tenancy'
  ) {
    return <RelatedRecordConnectionSvg />;
  }

  return (
    <Avatar
      rounded='m'
      Icon={(props) => <RecordIcon {...props} recordType={recordType} />}
    />
  );
}

export interface RecordFlag extends ComponentProps<'div'> {
  record: RecordSet;
  recordType: RecordTypeSubset;
  description?: React.ReactNode;
}

export function RecordFlag({ record, recordType, description }: RecordFlag) {
  const descriptionElement = description || getSubline(record, recordType);
  // this confused me so: a signifier is a visual element that is used to indicate the type of record
  // so it's basically an icon.
  const signifier = useMemo(() => getSignifier(recordType), []);

  return (
    <Flag signifier={signifier} headline={useGetHeadline(record, recordType)}>
      {descriptionElement}
    </Flag>
  );
}
