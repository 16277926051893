import { useDialog } from '@rexlabs/dialog';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useQueryClient } from 'react-query';
import { METRIC_WRITE_RULES_QUERY_KEY } from 'src/modules/metric-write-rules/hooks/use-metric-write-rules';
import { CreateMetricWriteRuleDialog } from '../../dialogs/create-metric-write-rule-dialog';

export function useGetCreateMetricWriteRuleAction() {
  const { open: openCreateMetricWriteRuleDialog } = useDialog(
    CreateMetricWriteRuleDialog
  );
  const queryClient = useQueryClient();

  return (): ActionDeclaration => {
    return {
      intent: 'primary',
      id: 'create-metric-write-rule',
      group: 'metric-write-rules',
      label: 'Create Metric Write Rule',
      handleAction: async () => {
        openCreateMetricWriteRuleDialog({
          onClose: () => {
            queryClient.invalidateQueries(METRIC_WRITE_RULES_QUERY_KEY);
          }
        });
      }
    };
  };
}
