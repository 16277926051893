import React from 'react';
import { Trans } from 'react-i18next';

import { Disbursement } from 'data/models/entities/financials/disbursements/disbursements';

import { Columns, Column } from '../../../view/components/table';
import { CompletedDisbursementStatusCell } from '../components/completed-disbursement-status-cell';
import { DisbursedToCell } from '../components/disbursed-to-cell';

const statusColumn: Column<Disbursement> = {
  id: 'status',
  Header: 'Status',
  Cell: CompletedDisbursementStatusCell,
  width: 140,
  toCsv: (item) => item?.label
};
const objectTypeColumn: Column<Disbursement> = {
  id: 'type',
  Header: 'Type',
  accessor: (item) =>
    item.contact?.is_agency ? 'Agency' : item.object.type.label,
  width: 120
};
const disbursedToColumn: Column<Disbursement> = {
  id: 'disbursed_to',
  Header: () => <Trans i18nKey='disbursements.disbursed-to.label' />,
  accessor: (item) => item,
  Cell: DisbursedToCell,
  toCsv: (item) => item?.object?.label
};

const disbursementDateColumn: Column<Disbursement> = {
  id: 'created_at',
  type: 'date' as const,
  Header: () => <Trans i18nKey='disbursements.disbursement-date.label' />,
  width: 190
};
const billsColumn: Column<Disbursement> = {
  id: 'disbursement_snapshot_summary.disbursement.bill_payouts',
  type: 'currency' as const,
  Header: 'Bills',
  cellProps: {
    align: 'right'
  },
  width: 180
};

const feesColumn: Column<Disbursement> = {
  id: 'disbursement_snapshot_summary.disbursement.fee_payouts',
  type: 'currency' as const,
  Header: 'Fees',
  cellProps: {
    align: 'right'
  },
  width: 180
};

const specificInvoiceColumn: Column<Disbursement> = {
  id: 'disbursement_snapshot_summary.disbursement.specific_invoice_payouts',
  type: 'currency' as const,
  Header: 'Specific payments',
  cellProps: {
    align: 'right'
  },
  width: 180
};

const payoutColumn: Column<Disbursement> = {
  id: 'disbursement_snapshot_summary.disbursement.total_payout_amount',
  type: 'currency' as const,
  Header: 'Payout amount',
  cellProps: {
    align: 'right'
  },
  width: 180
};

export const completedDisbursementColumnsForList: Columns<Disbursement> = [
  statusColumn,
  objectTypeColumn,
  disbursedToColumn,
  disbursementDateColumn,
  billsColumn,
  feesColumn,
  specificInvoiceColumn,
  payoutColumn
];

export const completedDisbursementColumnsForBlock: Columns<Disbursement> = [
  statusColumn,
  disbursementDateColumn,
  billsColumn,
  feesColumn,
  payoutColumn
];
