import { ContentConfig } from 'view/components/record-screen/types';
import { activityBlock } from 'view/components/blocks/activity';
import { wipBlock } from 'view/components/blocks/wip';
import { trustTransactionsWithBankAccountSelectBlock } from 'src/modules/trust-journal-entry-line-items/blocks/trust-transactions-table-with-bank-account-select';
import { FLAGS } from 'utils/feature-flags';
import { noteTableBlock } from 'src/modules/note/blocks/note-table-block';
import { tenancyModel } from 'data/models/entities/tenancies';
import { tenantPaymentHistoryBlock } from 'src/modules/tenancies/blocks/tenant-payment-history-block';
import { useMessageTableBlock } from 'src/modules/communications/messages/blocks/message-table-block';
import { useTranslation } from 'react-i18next';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { prepaymentTrustAccountBlock } from 'src/modules/tenancies/blocks/prepayment-trust-account-block';
import { tenancyTrustTransactionsBlock } from 'src/modules/tenancies/blocks/tenancy-trust-transactions-table';
import { tenantEditBanner } from 'src/modules/tenancies/components/tenant-edit-banner';
import { cashLedgerBlock } from 'src/modules/folio-ledgers/blocks/cash-ledger-block';
import { payablesLedgerBlock } from 'src/modules/folio-ledgers/blocks/payables-ledger-block';
import { receivablesLedgerBlock } from 'src/modules/folio-ledgers/blocks/receivables-ledger-block';
import { tenancyTasksBlock } from 'src/modules/tenancies/blocks/tenancy-tasks-block';
import { documentsBlock } from '../blocks/documents';
import { usePayableByInvoicesBlock } from '../blocks/invoices-payable-by';
import { usePayableToInvoicesBlock } from '../blocks/invoices-payable-to';
import { propertyTenanciesBlock } from '../blocks/property-tenancies';
import { getTenantBlock } from '../blocks/tenant-block';
import { cashflowStatementBlock } from '../blocks/cashflow-statement';
import { receiptingInstructionsBlock } from '../blocks/receipting-instructions';

export const useContent = (): ContentConfig => {
  const { t } = useTranslation();
  const { hasFeature } = useFeatureFlags();

  const payableByInvoicesBlock = usePayableByInvoicesBlock();
  const payableToInvoicesBlock = usePayableToInvoicesBlock();
  const tenantBlock = getTenantBlock(tenantEditBanner());
  const messageTableBlock = useMessageTableBlock(tenancyModel);

  return [
    {
      label: 'General',
      items: [
        {
          id: 'overview',
          label: 'Overview',
          blocks: [cashflowStatementBlock, propertyTenanciesBlock, tenantBlock]
        },
        {
          id: 'activity',
          label: 'Activity',
          blocks: [activityBlock],
          workInProgress: true
        },
        {
          id: 'documents',
          label: 'Documents',
          blocks: [documentsBlock]
        },
        {
          id: 'notes',
          label: 'Notes',
          blocks: [noteTableBlock]
        },
        { id: 'messages', label: 'Messages', blocks: [messageTableBlock] },
        {
          id: 'tasks',
          label: 'Tasks',
          blocks: [tenancyTasksBlock]
        }
      ]
    },
    {
      label: 'Inspections & Maintenance',
      items: [
        {
          id: 'inspections',
          label: 'Inspections',
          blocks: [wipBlock],
          workInProgress: true
        },
        {
          id: 'maintenance',
          label: 'Maintenance',
          blocks: [wipBlock],
          workInProgress: true
        }
      ],
      workInProgress: true
    },
    {
      label: 'Financials',
      items: [
        {
          id: 'invoices',
          label: 'Invoices & bills',
          blocks: [payableByInvoicesBlock, payableToInvoicesBlock]
        },
        {
          id: 'trust',
          label: t('financials.trust.label'),
          blocks: [
            ...(hasFeature(FLAGS.PREPAYMENTS)
              ? [
                  prepaymentTrustAccountBlock,
                  ...(hasFeature(FLAGS.FOLIO_LEDGERS)
                    ? [
                        cashLedgerBlock,
                        payablesLedgerBlock,
                        receivablesLedgerBlock
                      ]
                    : [tenancyTrustTransactionsBlock])
                ]
              : [trustTransactionsWithBankAccountSelectBlock])
          ]
        },
        {
          id: 'payment-history',
          label: 'Payment history',
          blocks: [tenantPaymentHistoryBlock],
          flag: FLAGS.PAYMENT_HISTORY
        },
        {
          id: 'receipting-instructions',
          label: 'Receipting instructions',
          blocks: [receiptingInstructionsBlock]
        }
      ]
    }
  ];
};
