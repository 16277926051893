import { Generator } from 'data/models/generator';
import { auditableConfig } from 'src/modules/common/auditable/auditable-config';
import { CustomReport } from '../types/CustomReport';

const config = {
  entities: {
    related: {
      // TODO: configure includes here
      ...auditableConfig,

      required_privileges: {
        include: 'required_privileges'
      }
    }
  }
};

export const customReportsModel = new Generator<CustomReport>(
  'custom-reports',
  config
).createEntityModel();
