import { FLAT_FEE } from 'src/modules/supplier-commission/types/FolioCommissionTemplate';
import {
  CommissionRule,
  CommissionRulesFormValues
} from '../types/contact-types';

export function mapSupplierCommissionPayload(
  values: CommissionRulesFormValues
) {
  const payload: Array<Partial<CommissionRule>> = [];

  const hasOverrides = values.has_more_rules && values.rules.length > 0;
  const commissionStructure = values.commission_structure;

  if (commissionStructure) {
    const isFlatFee = commissionStructure.id === FLAT_FEE;
    const isTemplate =
      commissionStructure['__record_type'] === 'commission_template';

    const key = isTemplate ? 'commission_template' : 'structure_type';

    payload.push({
      is_default: true,
      [key]: commissionStructure,
      ...(values.default_rule_id ? { id: values.default_rule_id } : {}),
      ...(!isTemplate
        ? {
            commission_amount: isFlatFee
              ? values.commission_amount_currency
              : values.commission_amount_percent
          }
        : {})
    });
  }

  if (!hasOverrides) return payload;

  payload.push(...values.rules);

  return payload;
}
