import React from 'react';
import ROUTES from 'routes/app';
import { Card } from 'view/components/card';
import { ListScreen } from 'view/components/list-screen/list-screen';
import { ChartOfAccountsAccountTable } from 'src/modules/chart-of-accounts/components/chart-of-accounts-table';

export function ChartOfAccountsScreen() {
  return (
    <ListScreen
      privilege={'chart-of-accounts-accounts.update'}
      title='Chart of accounts'
      createLabel='Create account'
      createLinkProps={{ to: ROUTES.CHART_OF_ACCOUNTS.CREATE }}
    >
      <Card>
        <ChartOfAccountsAccountTable />
      </Card>
    </ListScreen>
  );
}
