import { FLAGS } from 'utils/feature-flags';
import { ContentConfig } from 'view/components/record-screen/types';
import { usePayableByInvoicesBlock } from 'src/modules/tasks/work-orders/blocks/invoices-payable-by';
import { useMessageTableBlock } from 'src/modules/communications/messages/blocks/message-table-block';
import { noteTableBlock } from 'src/modules/note/blocks/note-table-block';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { taskPriorityBlock } from '../../common/blocks/task-priority-block';
import { relatedImagesBlock } from '../../quotes/blocks/related-images-block';
import { documentsBlock } from '../../common/blocks/documents';
import { taskDateBlock } from '../blocks/task-date-block';
import { supplierWorkOrderDetailsBlock } from '../blocks/supplier-work-order-details-block';
import { WorkOrderTask } from '../types/WorkOrderTask';
import { detailsBlock } from '../blocks/details-block';
import { linkedTasksBlock } from '../../common/blocks/linked-tasks-block';
import { checklistsBlock } from '../../common/blocks/create-checklists-block';
import { tasksModel } from '../../common/models/tasks-model';
import { draftInvoicesBlock } from '../blocks/draft-invoice-block';

export function useWorkOrderContent(
  workOrderTask?: WorkOrderTask
): ContentConfig {
  const { hasFeature } = useFeatureFlags();
  const payableByInvoicesBlock = usePayableByInvoicesBlock();
  const messageTableBlock = useMessageTableBlock(tasksModel);

  return [
    {
      label: 'General',
      items: [
        {
          id: 'overview',
          label: 'Overview',
          blocks: [
            detailsBlock,
            relatedImagesBlock,
            ...(workOrderTask?.details?.work_done_by_type?.id === 'supplier'
              ? [supplierWorkOrderDetailsBlock]
              : []),
            taskPriorityBlock,
            taskDateBlock
          ]
        },
        {
          id: 'notes',
          label: 'Notes',
          blocks: [noteTableBlock]
        },
        {
          id: 'documents',
          label: 'Documents',
          blocks: [documentsBlock]
        },
        { id: 'messages', label: 'Messages', blocks: [messageTableBlock] },
        {
          id: 'checklist',
          label: 'Checklist',
          blocks: [checklistsBlock],
          flag: FLAGS.CHECKLIST_TEMPLATES
        }
      ]
    },
    {
      label: 'Manage',
      items: [
        {
          id: 'linked-tasks',
          label: 'Linked tasks',
          blocks: [linkedTasksBlock],
          flag: FLAGS.LINKED_TASKS
        },
        ...(workOrderTask?.details?.work_done_by_type?.id === 'supplier'
          ? [
              {
                id: 'bills',
                label: 'Bills',
                blocks: [
                  payableByInvoicesBlock,
                  ...(hasFeature(FLAGS.TASK_QUICK_INVOICES)
                    ? [draftInvoicesBlock]
                    : [])
                ]
              }
            ]
          : [])
      ]
    }
  ];
}
