import { ContentConfig } from 'view/components/record-screen/types';
import { activityBlock } from 'view/components/blocks/activity';
import { wipBlock } from 'view/components/blocks/wip';
import { documentsBlock } from 'src/modules/contacts/blocks/documents';
import { contactDisbursementPaymentMethodsBlock } from 'src/modules/disbursements/blocks/contact-disbursement-payment-methods';
import { getDisbursementTableBlock } from 'src/modules/disbursements/blocks/disbursement-table-block';

import { trustTransactionsWithBankAccountSelectBlock } from 'src/modules/trust-journal-entry-line-items/blocks/trust-transactions-table-with-bank-account-select';
import { incomeAndExpenseBlock } from 'src/modules/account-journal-entries/blocks/income-and-expense-block';
import { FLAGS } from 'utils/feature-flags';
import { noteTableBlock } from 'src/modules/note/blocks/note-table-block';
import { useMemo } from 'react';
import { useMessageTableBlock } from 'src/modules/communications/messages/blocks/message-table-block';
import { addSupplierComplianceCategoryBlock } from 'src/modules/contacts/blocks/add-supplier-compliance-category-block';
import { ComplianceEntry } from 'src/modules/compliance/common/models/compliance-entries';
import { getComplianceEntriesBlock } from 'src/modules/contacts/blocks/compliance-entries';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { useTranslation } from 'react-i18next';
import { useSessionState } from 'src/modules/common/hooks/session-state';
import { supplierCommissionLedgerBlock } from 'src/modules/supplier-commission/blocks/supplier-commission-ledger-block';
import { supplierCommissionAccountCodesBlock } from 'src/modules/supplier-commission/blocks/supplier-commission-account-codes';
import { cashLedgerBlock } from 'src/modules/folio-ledgers/blocks/cash-ledger-block';
import { payablesLedgerBlock } from 'src/modules/folio-ledgers/blocks/payables-ledger-block';
import { receivablesLedgerBlock } from 'src/modules/folio-ledgers/blocks/receivables-ledger-block';
import { contactTasksBlock } from 'src/modules/contacts/blocks/contact-tasks-block';
import { ownershipsBlock } from '../blocks/ownerships';
import { usePayableToInvoicesBlock } from '../blocks/invoices-payable-to';
import { usePayableByInvoicesBlock } from '../blocks/invoices-payable-by';
import { emailsBlock } from '../blocks/emails';
import { phoneNumbersBlock } from '../blocks/phone-numbers';
import { getPaymentMethodsBlock } from '../blocks/payment-methods';
import { detailsBlock } from '../blocks/details';
import { addressesBlock } from '../blocks/addresses';
import { supplierInfoBlock } from '../blocks/supplier-info';
import { businessDetailsBlock } from '../blocks/business-details';
import { depositAuthorityDetailsBlock } from '../blocks/deposit-authority-details-block';
import { propertyTenanciesBlock } from '../blocks/property-tenancies';
import { propertyOwnershipsBlock } from '../blocks/property-ownerships';
import { tenanciesBlock } from '../blocks/tenancies';
import { Contact } from '../types/contact-types';
import { agencyLogo } from '../blocks/agency-logo';
import { agencyContactStatementsBlock } from '../blocks/agency-contact-statements-block';
import { contactsModel } from '../models/contacts';
import { hmrcTaxBlock } from '../blocks/hmrc-tax-block';
import { assignedTasksBlock } from '../blocks/assigned-tasks-block';

function showAgencySpecificBlock({ data }: { data: Contact }) {
  return !!(data?.roles || []).find((role) => role.id === 'agency');
}

export const useContent = (
  data?: Contact,
  complianceEntries?: ComplianceEntry[]
): ContentConfig => {
  const { t } = useTranslation();
  const payableByInvoicesBlock = usePayableByInvoicesBlock();
  const payableToInvoicesBlock = usePayableToInvoicesBlock();
  const messageTableBlock = useMessageTableBlock(contactsModel);
  const { hasFeature } = useFeatureFlags();
  const showSupplierOverview = hasFeature(FLAGS.SUPPLIER_COMPLIANCE_OVERVIEW);
  const { activeSilo } = useSessionState();

  // typescript no like it when I compare undefined to a number
  const hasComplianceEntries = (complianceEntries?.length || 0) > 0;

  const complianceCategoriesBlock = useMemo(
    () =>
      addSupplierComplianceCategoryBlock({
        hasComplianceEntries
      }),
    [hasComplianceEntries]
  );

  const complianceEntriesBlock = useMemo(
    () =>
      getComplianceEntriesBlock({
        complianceEntries
      }),
    [complianceEntries]
  );

  const isAgency = data?.is_agency;

  const isSupplier = data?.is_supplier;

  // labels such as [tenant] or [supplier] added to the content label
  // filter out the content based on the contact role.
  // see docs in frontend/src/view/components/record-screen/filter-content.md
  // for more info

  return [
    {
      label: 'General',
      items: [
        {
          id: 'overview',
          label: 'Overview',
          blocks: [
            ...(showSupplierOverview ? [supplierInfoBlock] : []),
            businessDetailsBlock,
            depositAuthorityDetailsBlock,
            detailsBlock('edit'),
            emailsBlock,
            phoneNumbersBlock,
            addressesBlock
          ]
        },
        {
          id: 'activity',
          label: 'Activity',
          blocks: [activityBlock],
          workInProgress: true
        },
        {
          id: 'documents',
          label: 'Documents',
          blocks: [documentsBlock]
        },
        {
          id: 'notes',
          label: 'Notes',
          blocks: [noteTableBlock]
        },
        { id: 'messages', label: 'Messages', blocks: [messageTableBlock] },
        {
          id: 'agency-logo',
          label: 'Agency logo',
          blocks: [agencyLogo],
          visible: showAgencySpecificBlock
        },
        {
          id: 'agency-statements',
          label: 'Statements',
          blocks: [agencyContactStatementsBlock],
          visible: showAgencySpecificBlock,
          flag: FLAGS.AGENCY_STATEMENTS
        },
        {
          id: 'tasks',
          label: 'Tasks',
          blocks: [contactTasksBlock],
          visible: ({ data }) => !data?.is_supplier
        }
      ]
    },
    {
      label: '[tenant] Tenancy',
      // TODO: implement once available from BE
      // https://app.clubhouse.io/rexlabs/story/57158
      // visible: ({data}) => ...
      items: [
        {
          id: 'tenancies',
          label: 'Tenancies',
          blocks: [tenanciesBlock]
        },
        {
          id: 'lease-agreements',
          label: t('lease-agreements.label.plural'),
          blocks: [propertyTenanciesBlock]
        }
      ]
    },
    {
      label: '[owner] Management',
      // TODO: implement once available from BE
      // https://app.clubhouse.io/rexlabs/story/57158
      // visible: ({data}) => ...
      items: [
        {
          id: 'ownerships',
          label: 'Ownerships',
          blocks: [ownershipsBlock]
        },
        {
          id: 'management-agreements',
          label: t('property-ownerships.management-agreement.label.plural'),
          blocks: [propertyOwnershipsBlock]
        }
      ]
    },
    {
      label: '[supplier] Supplier',
      // TODO: implement once available from BE
      // https://app.clubhouse.io/rexlabs/story/57158
      // visible: ({data}) => ...
      items: [
        {
          id: 'assigned-jobs',
          label: 'Assigned jobs',
          blocks: [wipBlock],
          workInProgress: true
        }
      ],
      workInProgress: true
    },

    {
      label: '[supplier] Manage',
      items: [
        {
          id: 'assigned-tasks',
          label: 'Assigned tasks',
          blocks: [assignedTasksBlock]
        },
        {
          id: 'compliance',
          label: 'Insurance & compliance',
          blocks: [
            complianceCategoriesBlock,
            ...(!!data?.compliance_categories?.length || hasComplianceEntries
              ? [complianceEntriesBlock]
              : [])
          ],
          flag: FLAGS.SUPPLIER_COMPLIANCE_TASK
        }
      ]
    },
    {
      label: 'Financials',
      items: [
        {
          id: 'invoices',
          label: 'Invoices & bills',
          blocks: [payableByInvoicesBlock, payableToInvoicesBlock]
        },
        ...(isSupplier
          ? [
              {
                id: 'supplier-commission-ledger',
                label: 'Supplier commission',
                flag: FLAGS.SUPPLIER_COMMISSIONS,
                blocks: [
                  supplierCommissionAccountCodesBlock,
                  supplierCommissionLedgerBlock
                ]
              }
            ]
          : []),
        {
          id: 'trust',
          label: t('financials.trust.label'),
          blocks: hasFeature(FLAGS.FOLIO_LEDGERS)
            ? [cashLedgerBlock, payablesLedgerBlock, receivablesLedgerBlock]
            : [trustTransactionsWithBankAccountSelectBlock]
        },
        {
          id: 'accounting',
          label: 'Income & expense',
          blocks: [incomeAndExpenseBlock],
          visible: showAgencySpecificBlock,
          flag: FLAGS.MANUAL_ACCOUNTING_JOURNAL_ENTRIES
        },
        ...(activeSilo?.country.id === 'GBR'
          ? [
              {
                id: 'tax',
                label: 'Tax',
                blocks: [hmrcTaxBlock],
                flag: FLAGS.WITHHOLDING_TAX
              }
            ]
          : [])
      ]
    },
    {
      label: 'Disbursements',
      items: [
        {
          id: 'disbursement-preferences',
          label: t('disbursements.preferences.label'),
          blocks: [
            useMemo(() => getPaymentMethodsBlock((contact) => contact), []),
            contactDisbursementPaymentMethodsBlock
          ]
        },
        {
          id: 'completed-disbursements',
          label: t(
            'disbursements.list-screen.tabs.completed-disbursements.label'
          ) as string,
          blocks: [
            useMemo(
              () =>
                getDisbursementTableBlock<Contact>({
                  type: isAgency ? 'agency' : 'contact'
                }),
              [isAgency]
            )
          ]
        }
      ]
    }
  ];
};
