import Box from '@rexlabs/box';
import { Body, Bold } from '@rexlabs/text';
import { formatCurrency } from 'utils/formatters';
import { ErrorBanner } from 'view/components/@luna/notifications/banner';
import WarningCircleIcon from 'view/components/icons/warning-circle';
import { BlockConfig } from 'view/components/record-screen/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PendingOwnershipDisbursement } from 'data/models/entities/financials/pending-disbursements/pending-ownership-disbursement';

export const disburseFundsDetailsBlock: BlockConfig<
  any,
  { entitiesToDisburse: PendingOwnershipDisbursement[] }
> = {
  id: 'disburse-funds-details',
  Edit: ({ blockProps }) => {
    const { t } = useTranslation();
    const { entitiesToDisburse } = blockProps!;

    const centsToDisburse = entitiesToDisburse.reduce(
      (acc, item) => acc + item.disburse_summary.expected_disbursement_amount,
      0
    );

    const isSingle = entitiesToDisburse.length === 1;
    const disbursement = entitiesToDisburse[0];

    return (
      <Box>
        {isSingle && disbursement?.payment_methods?.length === 0 && (
          <ErrorBanner
            title={t('disbursements.preferences.missing.label') as string}
            Icon={WarningCircleIcon}
            description={
              <Body>
                {t('disbursements.no-preferences-for-name', {
                  display_name: disbursement.object?.display_name
                })}
              </Body>
            }
          />
        )}
        <Body>
          {isSingle && disbursement ? (
            <>
              <p>
                {t('disbursements.confirm-disbursement')}{' '}
                <Bold>
                  {formatCurrency(
                    disbursement.disburse_summary.expected_disbursement_amount
                  )}
                </Bold>
              </p>
              <div>
                Payout amount to folio:{' '}
                <Bold>
                  {formatCurrency(
                    entitiesToDisburse[0].disburse_summary
                      .recommended_payout_allocation_amount
                  )}
                </Bold>
              </div>
              <div>
                Specific payments:{' '}
                <Bold>
                  {formatCurrency(
                    entitiesToDisburse[0].disburse_summary
                      .allocated_for_specific_payout_ready_amount
                  )}
                </Bold>
              </div>
              <div>
                Bills & fees:{' '}
                <Bold>
                  {formatCurrency(
                    entitiesToDisburse[0].disburse_summary
                      .recommended_bill_payment_allocation_amount +
                      (entitiesToDisburse[0].disburse_summary
                        ?.recommended_fee_payment_allocation_amount || 0) +
                      (entitiesToDisburse[0].disburse_summary
                        ?.recommended_deferred_fees_allocation_amount || 0)
                  )}
                </Bold>
              </div>
              {disbursement.ownership_tax_transactions?.data
                .filter(
                  (transaction) =>
                    transaction.type.id === 'tax_withheld_on_disbursement'
                )
                .map((transaction) => (
                  <div key={transaction.id}>
                    HMRC tax withheld to date for{' '}
                    {transaction.contact.display_name}:{' '}
                    <Bold>{formatCurrency(transaction.amount)}</Bold>
                  </div>
                ))}
            </>
          ) : (
            <>
              {t('disbursements.confirm-disbursement')} a total of{' '}
              <Bold>{formatCurrency(centsToDisburse)}</Bold>
            </>
          )}
        </Body>
        <Body>{t('disbursements.post-disbursement-message')}</Body>
      </Box>
    );
  }
};
