import { RexApiResponse } from '@rexlabs/api-client';
import { SinglePrivilege } from 'src/modules/authorization/roles/hooks/use-privileges';

export interface PmApiError extends Error {
  statusCode?: number;
  statusText?: string;
  trace?: string;
  errors?: any;
  requiredPrivileges?: SinglePrivilege[];
}

export function createErrorFromResponse(response: RexApiResponse): PmApiError {
  const e: PmApiError = new Error(response?.data?.message);

  e.statusCode = response?.status;
  e.statusText = response?.statusText;

  if (response?.data?.trace) {
    e.trace = response?.data?.trace
      .map((trace) => `${trace.function} ${trace.file}:${trace.line}`)
      .join('\n');
  }

  if (response.data?.errors) {
    e.errors = response.data?.errors;
  }
  return e;
}
