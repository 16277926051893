import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';
import Box from '@rexlabs/box';
import { Field } from '@rexlabs/form';

import { SimpleFileUploadInputWithErrorHandling } from 'view/components/inputs/file-upload-input/simple-file-upload-input-with-error-handling';

import { UploadBillsFormValues } from '../types/UploadBillsFormValues';

const validate = {
  definitions: {
    bills: {
      name: 'bill file',
      rules: 'required'
    }
  },
  messages: {
    required: 'Please upload a bill file.'
  }
};

export function getUploadBillsBlock(
  allowMultipleUploads = true
): BlockConfig<any, any, UploadBillsFormValues> {
  return {
    id: 'upload-bills',
    title: 'Upload bills',
    validate,
    Edit: () => {
      return (
        <Box flexDirection='column' gap={25}>
          <Field<typeof SimpleFileUploadInputWithErrorHandling>
            id='bills'
            name='bills'
            Input={SimpleFileUploadInputWithErrorHandling}
            inputProps={{
              multi: allowMultipleUploads,
              accept: 'application/pdf'
            }}
          />
          <div>Supported file format: PDF.</div>
        </Box>
      );
    }
  };
}
