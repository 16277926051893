import React from 'react';

import { RecordTable, RecordTableProps } from 'view/components/table';

import { notesModel } from '../models/notes-model';
import { columns } from '../utils/note-columns';

export const defaultNotesRecordTableHashParamKey = 'notes-record-table';

type NoteTableProps = RecordTableProps<typeof notesModel>;

type RequiredTableProps = Required<
  Pick<NoteTableProps, 'getActionMenuItems' | 'Empty' | 'getRowLinkProps'>
>;

type PartialTableProps = Partial<Pick<NoteTableProps, 'hashParamKey'>>;

type NotesListProps = RequiredTableProps &
  PartialTableProps & {
    getQuery: NoteTableProps['getQuery'];
    id?: string;
  };

export function NotesList({
  hashParamKey = defaultNotesRecordTableHashParamKey,
  Empty,
  getActionMenuItems,
  getQuery,
  getRowLinkProps,
  id = 'notes-list'
}: NotesListProps) {
  // NOTE: While our table will show the empty state if there is no data, we should also show the empty state if there is no query.
  // In some cases (bank transactions), we may not have an id to query for notes, so we should show the empty state in that case.
  if (!getQuery) {
    return <Empty />;
  }

  return (
    <RecordTable
      id={id}
      hashParamKey={hashParamKey}
      withPagination
      getRowLinkProps={getRowLinkProps}
      columns={columns}
      getQuery={getQuery}
      getActionMenuItems={getActionMenuItems}
      Empty={Empty}
      emptyWithoutTable={true}
    />
  );
}
