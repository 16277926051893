import { useModelActions } from '@rexlabs/model-generator';
import { Task } from 'src/modules/tasks/common/types/Task';
import {
  sharedIncludes,
  taskIncludes,
  tasksModel
} from 'src/modules/tasks/common/models/tasks-model';
import { getInvoicePayableBySuggestedItems } from 'src/modules/tasks/common/utils/get-invoice-payable-by-suggested-items';
import { useCreateInvoiceDialog } from 'src/modules/invoices/hooks/use-create-invoice-dialog';
import { getCreateInvoiceInitialValues } from '../../utils/get-create-invoice-initial-values';

export function useGetAddBillAction() {
  const openInvoiceDialog = useCreateInvoiceDialog();
  const { refreshItem } = useModelActions(tasksModel);

  return ({ data }: { data: Task<'task_maintenance'> }) => ({
    label: 'Create bill',
    group: 'create-bill',
    handleAction: () => {
      const initialValues = getCreateInvoiceInitialValues(data);

      openInvoiceDialog({
        initialValues,
        suggestions: {
          payableBy: getInvoicePayableBySuggestedItems({
            property: data?.property
          })
        },
        onSave: () =>
          refreshItem({
            id: data?.id,
            args: {
              includes: [
                taskIncludes?.maintenanceDetails,
                ...sharedIncludes,
                'property.active_property_ownership.ownership.owners.contact',
                'property.active_property_tenancy.tenancy.tenants.contact'
              ].join(',')
            }
          })
      });
    }
  });
}
