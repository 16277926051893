import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';
import Box from '@rexlabs/box';
import { Value } from 'view/components/values';
import { Field } from '@rexlabs/form';
import { useToken } from '@rexlabs/styling';
import { TextArea, TextInput } from '@rexlabs/text-input';
import { Column, Grid } from 'view/components/@luna/form/grid';
import { PropertySelect } from 'view/components/inputs/selects/v4-selects/property-select';
import { RelatesToSelect } from 'src/modules/tasks/common/components/relates-to-select';
import { TodoTask } from '../types/TodoTask';
import { TodoFormData } from '../types/TodoFormData';
import { TaskStatusSelect } from '../../common/components/task-status-select';
import { ManagedBySelect } from '../../common/components/managed-by-select';
import { getLabelForManagedBy } from '../../common/utils/get-label-for-managed-by';

const validate = {
  definitions: {
    summary: { name: 'summary', rules: 'required|max:80' },
    status: { name: 'progress', rules: 'required' },
    managed_by: { name: 'managed by', rules: 'required' }
  }
};

export const todoDetailsBlock: BlockConfig<TodoTask, any, TodoFormData> = {
  id: 'details',
  title: 'Details',
  validate,
  View: ({ data }) => {
    return (
      <Grid columns={2}>
        <Column width={2}>
          <Value label='Description' value={data?.description} />
        </Column>
        <Value label='Property' value={data?.property?.address_string} />
        <Value
          label='Relates to'
          value={data?.details?.relates_to?.display_name}
        />
        <Value
          label='Managed by'
          value={getLabelForManagedBy(data?.managed_by)}
        />
      </Grid>
    );
  },
  Edit: ({ values, editMode }) => {
    const token = useToken();
    return (
      <Box sy={token('spacing.xxl')}>
        <Grid columns={2}>
          <Field
            id='summary'
            name='summary'
            label='Summary'
            Input={TextInput}
          />
        </Grid>
        <Box sy={token('spacing.xxl')}>
          <Field
            id='description'
            name='description'
            label='Description'
            Input={TextArea}
          />
        </Box>
        <Grid columns={2}>
          <Box sy={token('spacing.xxl')} pt={token('spacing.m')}>
            <Field
              id='property'
              name='property'
              label='Property'
              Input={PropertySelect}
            />
            <Grid columns={2}>
              <Field
                id='managed_by'
                name='managed_by'
                label='Managed by'
                Input={ManagedBySelect}
              />
              {!editMode && (
                <Field
                  id='status'
                  name='status'
                  label='Progress'
                  Input={TaskStatusSelect}
                />
              )}
            </Grid>
          </Box>
          <Field
            id='relates_to'
            name='relates_to'
            label='Relates to'
            Input={RelatesToSelect}
            inputProps={{
              objectTypes: ['contact', 'tenancy', 'ownership'],
              propertyId: values?.property?.id
            }}
          />
        </Grid>
      </Box>
    );
  }
};
