import React from 'react';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';

import { useModelActions } from '@rexlabs/model-generator';
import { useToast } from 'view/components/@luna/notifications/toast';
import { commissionTemplatesModel } from '../models/commission-templates-model';
import { mapUpsertSupplierCommissionTemplatePayload } from '../mappers/map-upsert-supplier-commission-template';

export function useGetCreateSupplierCommissionTemplateHandler(): RecordSubmitHandler {
  const { addToast } = useToast();
  const { createItem, refreshLists } = useModelActions(
    commissionTemplatesModel
  );

  return React.useCallback(async ({ values }) => {
    const payload = mapUpsertSupplierCommissionTemplatePayload(values);

    await createItem({
      data: payload
    });

    addToast({
      description: (
        <>
          Successfully created - <b>{values.name}</b>
        </>
      )
    });

    refreshLists();

    return true;
  }, []);
}
