const formatOptions = ['paragraphFormat'];

const signatureOptions = ['html'];

const textOptions = [
  'fontFamily',
  'fontSize',
  'bold',
  'italic',
  'underline',
  'strikeThrough',
  'subscript',
  'superscript',
  'textColor',
  'backgroundColor',
  'clearFormatting'
];

const paragraphOptions = [
  'formatOL',
  'formatUL',
  'alignLeft',
  'alignCenter',
  'alignRight',
  'alignJustify',
  'lineHeight',
  'indent',
  'outdent'
];

const insertOptionsDefault = [
  'insertLink',
  'insertImage',
  'insertTable',
  'emoticons',
  'insertHR',
  'quote',
  'specialCharacters'
];

const miscOptionsDefault = ['|', 'undo', 'redo', 'fullscreen', 'html'];

export function getToolbarButtons(isSignature, hasImages: boolean) {
  return {
    toolbarButtons: getToolbarButtonsSection({
      sectionButtonLength: 3,
      isSignature,
      showUndo: true,
      hasImages
    }),
    // between input widths 768 and 992px
    toolbarButtonsSM: getToolbarButtonsSection({
      sectionButtonLength: 1,
      isSignature,
      showUndo: false,
      hasImages
    }),
    // smaller than 768px input width (sadly there's nothing smaller than this)
    toolbarButtonsXS: getToolbarButtonsSection({
      sectionButtonLength: 0,
      isSignature,
      showUndo: false,
      hasImages
    })
  };
}

function getToolbarButtonsSection({
  sectionButtonLength,
  showUndo,
  isSignature,
  hasImages
}) {
  const miscOptions = isSignature
    ? ['undo', 'redo', 'fullscreen']
    : miscOptionsDefault;

  const insertOptions = hasImages
    ? insertOptionsDefault
    : insertOptionsDefault.filter((option) => option !== 'insertImage');

  return {
    paragraphFormat: {
      buttons: formatOptions,
      buttonsVisible: formatOptions.length
    },
    moreText: {
      buttons: textOptions,
      buttonsVisible: sectionButtonLength
    },
    moreParagraph: {
      buttons: paragraphOptions,
      buttonsVisible: sectionButtonLength
    },
    moreRich: {
      buttons: insertOptions,
      buttonsVisible: sectionButtonLength
    },
    moreMisc: {
      buttons: miscOptions,
      buttonsVisible: showUndo ? 2 : 0,
      align: 'right'
    },
    ...(isSignature
      ? {
          html: {
            buttons: signatureOptions,
            buttonsVisible: signatureOptions.length,
            align: 'right'
          }
        }
      : {})
  };
}
