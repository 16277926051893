import 'froala-editor/js/froala_editor.pkgd.min.js';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/quote.min.js';
import 'froala-editor/js/plugins/quick_insert.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/draggable.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/url.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/emoticons.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/entities.min.js';
import 'froala-editor/js/plugins/inline_style.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/js/plugins/fullscreen.min.js';
import 'froala-editor/js/plugins/special_characters.min.js';
import 'froala-editor/js/plugins/line_height.min.js';

import '../froala-editor.css';

import React, { useMemo } from 'react';
import FroalaEditor from 'react-froala-wysiwyg';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { CardErrorBoundary } from 'view/components/record-screen/cards/error-boundary';
import { getToolbarButtons } from 'view/components/@luna/inputs/froala-editor/utils/get-toolbar-buttons';
import { useSessionState } from 'src/modules/common/hooks/session-state';

import pmConfig from 'src/config';

const defaultStyles = StyleSheet({
  container: {
    minHeight: '20rem',
    position: 'relative' // this is needed for scrollableContainer to position popups properly
  }
});

interface FakeEvent {
  target: {
    value: string | null;
  };
}

interface FroalaInputProps {
  value: string;
  onChange: (e: FakeEvent) => void;
  onBlur: (e: FakeEvent) => void;
  onClick?: () => void;
  onImageAdded?: (imageId: string) => void;
  onImageRemoved?: (imageId: string) => void;
  isSignature?: boolean;
}

function FroalaInput(
  {
    value,
    onChange,
    onBlur,
    onClick,
    onImageAdded,
    onImageRemoved,
    isSignature
  }: FroalaInputProps,
  ref
) {
  const hasImages = !!onImageAdded && !!onImageRemoved;

  const s = useStyles(defaultStyles);

  const sessionData = useSessionState();

  const toolbarButtons = getToolbarButtons(isSignature, hasImages);

  const config = useMemo(
    () => ({
      key: pmConfig['FROALA_KEY'],
      attribution: false,
      placeholderText: 'Type here...',
      useClasses: false,
      heightMin: 200,
      linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
      linkInsertButtons: [],
      iframe: true,
      iframeStyle: 'table.presentation td{border-width:0;}',
      events: {
        click: () => {
          onClick?.();
        },
        'image.removed': function (imageArray: HTMLImageElement[]) {
          // Froala uses the id given by the response as the html id
          const imageId = imageArray[0].id;
          onImageRemoved?.(imageId);
        },
        'image.loaded': function (imageArray: HTMLImageElement[]) {
          const imageId = imageArray[0].id;
          onImageAdded?.(imageId);
        }
      },
      imageUploadURL: `${sessionData.activeSilo?.base_url}/files?include=link`,
      scrollableContainer: '#froala-wrapper', // this is needed for popups to position properly in dialogs
      imageUploadParams: {
        is_public: 1
      },
      requestHeaders: {
        Authorization: `Bearer ${sessionData.token}`,
        // Accept: 'multipart/form-data',
        'X-App-Silo': sessionData.activeSilo?.id
      },
      imageMaxSize: 1024 * 1024 * 15,
      tableStyles: {
        presentation: 'Remove Outlines'
      },
      pluginsEnabled: [
        'table',
        'quote',
        'quickInsert',
        'paragraphFormat',
        'draggable',
        'align',
        'lists',
        'link',
        'url',
        'image',
        'emoticons',
        'colors',
        'entities',
        'inlineStyle',
        'fontFamily',
        'fontSize',
        'codeView',
        'fullscreen',
        'specialCharacters',
        'lineHeight'
      ],
      ...toolbarButtons
    }),
    [sessionData, onImageAdded, onImageRemoved, onClick]
  );

  function handleChange(model: string) {
    onChange?.({ target: { value: model } });
    onBlur?.({ target: { value: model } });
  }

  return (
    // This bug (see link) occurs when opening the message edit dialog from the preview dialog
    // Error boundary prevents it from crashing the app and appears to prevent any compounding issues
    // aside from some typing lag initially when opening the dialog from this flow
    // https://github.com/froala/wysiwyg-editor/issues/4407
    <CardErrorBoundary>
      <Box
        width={'100%'}
        {...s('container')}
        id='froala-wrapper'
        data-testid='editor-input'
        style={{ position: 'relative' }}
        onClick={onClick}
      >
        <FroalaEditor
          tag='textarea'
          model={value}
          onModelChange={handleChange}
          config={config}
          ref={ref}
          data-testid={'editor-input'}
        />
      </Box>
    </CardErrorBoundary>
  );
}

export const FroalaEditorInput = React.forwardRef(FroalaInput);
