import {
  BaseModelGeneratorModel,
  CustomAction,
  DefaultEntityMethods,
  Includes,
  ModelGeneratorData,
  touchEtag
} from '@rexlabs/model-generator';

import { produce } from 'immer';

import { Generator } from 'data/models/generator';
import _ from 'lodash';
import { Author } from 'src/modules/authors/types/author-types';
import { Bill } from 'src/modules/bill-processing/types/Bill';
import { ChartOfAccountsAccount } from 'src/modules/chart-of-accounts/types/chart-of-account-types';
import { auditableConfig } from 'src/modules/common/auditable/auditable-config';
import { contactsModel } from 'src/modules/contacts/models/contacts';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { Property } from 'src/modules/properties/types/property-types';
import { tasksModel } from 'src/modules/tasks/common/models/tasks-model';
import { MaintenanceTask } from 'src/modules/tasks/maintenance/types/MaintenanceTask';
import { WorkOrderTask } from 'src/modules/tasks/work-orders/types/WorkOrderTask';
import { usersModel } from 'src/modules/users/models/users';
import { api } from 'utils/api/api-client';
import { InvoiceStatus } from 'utils/static-value-lists/invoice-status';
import {
  BaseFields,
  Payable,
  RecordReference,
  RecordTypes,
  TimestampAttributes,
  ValueListValue
} from '../../types';
import { ContactPaymentMethod } from '../contacts/payment-methods';
import { Ownership, ownershipsModel } from '../ownerships';
import { Tenancy, tenancyModel } from '../tenancies';
import { BankAccount } from './bank-accounts';
import { TaxType } from './tax-types';

export interface PaymentSourceRecord {
  type: { id: 'credit_note' | 'trust_journal_entry' };
  date: string;
  id: string;
}

export type InvoicePayment = {
  id: string;
  created_at: string;
  updated_at: string;
  amount: number;
  invoice?: Invoice;
  type: ValueListValue<'credit' | 'payment'>;
  source_record?: PaymentSourceRecord;
};

export interface TaxSummaryItem {
  tax_type: TaxType;
  amount_inc_tax: number;
  amount_exc_tax: number;
  amount_tax: number;
}

export interface TaxItem {
  id: string;
  tax_type?: string;
  label: string;
  rate: number;
}

export interface InvoiceLineItem
  extends BaseModelGeneratorModel,
    TimestampAttributes {
  amount: number;
  amount_exc_tax: number;
  amount_inc_tax: number;
  amount_tax: number;
  description: string;
  tax_type: TaxItem;
  payable_by_property?: Property | null;
  payable_to_property?: Property | null;
  payable_by_chart_of_accounts_account?: ChartOfAccountsAccount | null;
  payable_to_chart_of_accounts_account?: ChartOfAccountsAccount | null;
}

export type Invoice = BaseFields<typeof RecordTypes.Invoice> &
  RecordReference &
  TimestampAttributes & {
    payable_by: Payable;
    payable_to: Payable;
    status: ValueListValue<InvoiceStatus>;
    bill_priority?: ValueListValue<'high' | 'normal' | 'low'>;
    bill_reference?: string;
    description: string;
    invoice_date: string;
    due_date: string;
    do_not_pay_before_date: string | null;
    release_amount_pending_disbursement: boolean;

    //Amounts
    amount_total_inc_tax: number;
    amount_total_exc_tax: number;
    amount_total_tax: number;
    amount_paid: number | null;
    amount_owing: number;
    amount_pending_disbursement: number;

    is_tax_included: boolean;
    tax_summary: TaxSummaryItem[];
    line_items?: InvoiceLineItem[];

    reimbursed_by_invoices?: Invoice[];
    reimbursement_for_invoice?: Invoice;

    property?: Property;
    payments?: Includes<InvoicePayment[]>;
    bank_account?: BankAccount;

    payable_by_ownership?: Ownership;
    payable_to_ownership?: Ownership;
    payable_by_contact?: Contact;
    payable_to_contact?: Contact;
    payable_by_tenancy?: Tenancy;
    payable_to_tenancy?: Tenancy;

    specific_disbursement_payment_method?: ContactPaymentMethod;

    status_reason?: string;
    voided_at?: Date | null;
    voided_by?: Author;
    type_id?: string;
    type: { id: string; label: string };

    triggered_by_invoice_payment?: InvoicePayment;

    task?: MaintenanceTask | WorkOrderTask;

    uploaded_bill?: Bill;
    notes: string | null;

    commission_amount_exc_tax: null | number;
    commission_amount_inc_tax: null | number;
    commission_amount_tax: null | number;
    commission_description: null | string;
  };

export const config = {
  entities: {
    related: {
      line_items: {
        include: [
          'line_items.id',
          'line_items.payable_by_property',
          'line_items.payable_to_property',
          'line_items.description',
          'line_items.amount',
          'line_items.tax_type',
          'line_items.payable_by_chart_of_accounts_account',
          'line_items.payable_to_chart_of_accounts_account'
        ].join(',')
      },
      reimbursement_for_invoice: {
        include: 'reimbursement_for_invoice'
      },
      reimbursed_by_invoices: {
        include: 'reimbursed_by_invoices'
      },
      tax_summary: {
        include: 'tax_summary'
      },
      property: {
        include: 'property'
      },
      payments: {
        include:
          'payments.journal_entry_line_item,payments.invoice,payments.source_record'
      },
      payable_by_ownership: {
        include: 'payable_by_ownership',
        model: ownershipsModel
      },
      payable_to_ownership: {
        include: 'payable_to_ownership',
        model: ownershipsModel
      },
      payable_by_contact: {
        include: 'payable_by_contact',
        model: contactsModel
      },
      payable_to_contact: {
        include: 'payable_to_contact',
        model: contactsModel
      },
      payable_by_tenancy: {
        include: 'payable_by_tenancy',
        model: tenancyModel
      },
      payable_to_tenancy: {
        include: 'payable_to_tenancy',
        model: tenancyModel
      },
      specific_disbursement_payment_method: {
        include: 'specific_disbursement_payment_method'
      },
      bank_account: {
        include: 'bank_account'
      },
      voided_by: {
        include: 'voided_by',
        model: usersModel
      },
      triggered_by_invoice_payment: {
        include: 'triggered_by_invoice_payment.invoice'
      },
      task: {
        include: 'task',
        model: tasksModel
      },
      uploaded_bill: {
        include: ['uploaded_bill', 'uploaded_bill.file'].join(',')
      },
      ...auditableConfig
    }
  }
};

type ChangeStatusActionPayload = {
  invoiceId: string;
  data: any;
};

interface DownloadItemConfig {
  invoiceId: string;
  format?: 'pdf' | 'html';
  reportId?: 'tax-invoice';
}

const actionCreators = {
  changeStatus: {
    request: ({ invoiceId, data }: { invoiceId: string; data: any }) => {
      return api.put(`/financials/invoices/${invoiceId}/status`, data);
    },
    reduce: {
      initial: _.identity,
      success: (state: any, action: any) => {
        const invoiceId = action.payload.data.id;
        return produce(state, (newState) => {
          newState.items[invoiceId].data.status = action.payload.data.status;
          newState.items[invoiceId] = touchEtag(newState.items[invoiceId]);
        });
      },
      failure: _.identity
    }
  } as CustomAction<ChangeStatusActionPayload, unknown>,
  downloadItem: {
    request: ({
      invoiceId,
      format = 'pdf',
      reportId = 'tax-invoice'
    }: DownloadItemConfig) =>
      api.post(`/reporting/${reportId}`, {
        invoice: {
          id: invoiceId
        },
        format,
        disposition: 'download_link'
      }),
    reduce: _.identity
  },
  bulkDownloadItems: {
    request: ({ data, format = 'pdf', reportId = 'tax-invoice' }) =>
      api.post(`/reporting/${reportId}/bulk`, {
        data,
        format,
        disposition: 'download_link' // this is hardcoded because the hook that downloads the report needs this to be set
      }),
    reduce: _.identity
  }
};

export type InvoicesModel = Invoice &
  DefaultEntityMethods<Invoice> & {
    line_items: ModelGeneratorData<InvoiceLineItem>;
  };

export const financialsInvoicesModel = new Generator<
  Invoice,
  typeof actionCreators
>('financials/invoices', config).createEntityModel({ actionCreators });
