import React from 'react';
import { useDialog, useErrorDialog } from '@rexlabs/dialog';
import { useQueryClient } from 'react-query';

import { trustJournalEntryModel } from 'src/modules/trust-journal-entries/models/trust-journal-entries';
import { useRecordCreatedToast } from 'src/modules/common/toasts/hooks/use-record-created-toast';

import InvoicesIcon from 'view/components/icons/invoices';

import { useSettings } from 'src/modules/settings/hooks/useSettings';
import { usePermission } from 'src/modules/authorization/roles/hooks/use-permission';
import { AccessDeniedDialog } from 'view/components/dialogs/access-denied-dialog';
import { BatchReceiptingItem } from '../../types';
import { useBankAccountState } from '../use-bank-account-state';
import { useBatchReceiptingStatsSummary } from '../use-batch-receipting-stats-summary';
import { bankStatementTransactionsQueryKey } from '../use-bank-statement-transactions-query';
import { useCreateTrustJournalEntryAndLinkTransaction } from '../use-create-trust-journal-entry-and-link-transaction';

export function useGetReceiptToRentAction() {
  const { bankAccount } = useBankAccountState();
  const { isStandardUser, roleName } = usePermission();

  const { refetch: refetchStatsSummary } = useBatchReceiptingStatsSummary(
    bankAccount?.id
  );

  const { open: openErrorDialog } = useErrorDialog();

  const queryClient = useQueryClient();
  const accessDeniedDialog = useDialog(AccessDeniedDialog);

  const {
    createTrustJournalEntryAndLinkTransaction
  } = useCreateTrustJournalEntryAndLinkTransaction();
  const addToast = useRecordCreatedToast(trustJournalEntryModel);

  const settings = useSettings();
  const batchReceiptingMatchSetting =
    settings?.['batch-receipting-match-setting'];

  return (item: BatchReceiptingItem) => {
    return {
      label:
        batchReceiptingMatchSetting?.id === 'all_invoices' ||
        item.purpose?.id === 'deposit'
          ? 'Receipt'
          : 'Receipt to rent',
      Icon: InvoicesIcon,
      onClick: async () => {
        if (isStandardUser) {
          return accessDeniedDialog.open({
            role: roleName
          });
        }

        try {
          const response = await createTrustJournalEntryAndLinkTransaction(
            item
          );

          addToast(
            response.data[`trust_journal_entry-${item.id}`],
            <>
              Processing receipt for the{' '}
              <b>{item?.prioritised_match?.folio?.display_name}</b> folio
            </>
          );

          await queryClient.refetchQueries({
            queryKey: [bankStatementTransactionsQueryKey],
            refetchPage: () => true
          });

          await refetchStatsSummary();

          return;
        } catch (error) {
          openErrorDialog(error);
        }
      }
    };
  };
}
