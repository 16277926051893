import { ListTable, TabbedTable } from 'view/components/table';
import React, { useMemo } from 'react';
import { query } from '@rexlabs/model-generator';
import { useMediaQuery } from '@rexlabs/breakpoints';
import ROUTES from 'routes/app';
import { useTrustJournalEntryActions } from 'src/modules/trust-journal-entries/actions/use-trust-journal-entry-actions';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { TrustJournalEntry } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';
import { trustJournalEntryModel } from 'src/modules/trust-journal-entries/models/trust-journal-entries';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { Tab } from 'view/components/table/tabbed';
import { getTrustJournalEntryColumns } from '../utils/get-trust-journal-entry-columns';

export const getTrustJournalEntriesQuery = () => query`{
    ${trustJournalEntryModel} {
      id
      payment_information
      created_by
      updated_by
      contacts
      refunded_by
      transfer_source {
        trust_ledger
      }
    }
  }`;

const columns = getTrustJournalEntryColumns();

function getRowLinkProps({ item }) {
  return {
    to: ROUTES.TRUST_JOURNAL_ENTRY,
    params: { trustJournalEntryId: item.id }
  };
}

export interface TrustJournalEntriesTableProps {
  bankAccountId: string;
}

export function TrustJournalEntriesTable({
  bankAccountId
}: TrustJournalEntriesTableProps) {
  const isSmallScreen = useMediaQuery({ maxWidth: 'xl' });
  const getQuery = useMemo(() => () => getTrustJournalEntriesQuery(), []);
  const getActions = useTrustJournalEntryActions();
  const { getSort, getFilters } = useTableFilters('trust-journal-entries');

  const tabs = useMemo<Tab[]>(() => {
    const defaultForcedFilters = [
      {
        field: 'bank_account_id',
        op: 'eq',
        value: bankAccountId
      }
    ];

    const id = 'trust-journal-entries';

    const commonTableProps = {
      id,
      Table: ListTable,
      getQuery: getQuery,
      columns,
      initialHiddenColumns: [
        'created_by',
        'created_at',
        'updated_at',
        'updated_by',
        'payment_method_id',
        ...(isSmallScreen ? ['description'] : [])
      ],
      alwaysExpandSearch: true,

      /**
       *  Note: This table persists filters and sorting to localstorage and now we have changed the
       *  default sorting from `created_at` to `date_of` which needs to be updated for the users, so for
       *  now we will ignore the previous cached table settings and after 1-2 weeks we will need to remove
       *  this blacklist to take advantage of persistance.
       */
      blacklistTableSettingKeys: [id],
      shouldPersistTableSettings: false,

      initialSortBy: [{ id: 'date_of', label: 'Date of', desc: true }],
      getRowLinkProps: getRowLinkProps,
      suggestedFilters: ['status_id', 'date_of'],
      getSort,
      getFilters,
      getActionMenuItems: ({ item }: { item: TrustJournalEntry }) =>
        transformActionDeclarationsToActionMenuItems(getActions(item))
    };

    return [
      {
        ...commonTableProps,
        name: 'all',
        label: 'All',
        forcedGlobalFilter: defaultForcedFilters
      },
      {
        ...commonTableProps,
        name: 'receipts',
        label: 'Receipts',
        forcedGlobalFilter: [
          ...defaultForcedFilters,
          {
            field: 'type_id',
            op: 'in',
            value: ['receipt', 'reverse_receipt']
          }
        ]
      },
      {
        ...commonTableProps,
        name: 'withdrawals',
        label: 'Withdrawals',
        forcedGlobalFilter: [
          ...defaultForcedFilters,
          {
            field: 'type_id',
            op: 'in',
            value: ['withdrawal', 'reverse_withdrawal']
          }
        ]
      },
      {
        ...commonTableProps,
        name: 'transfers',
        label: 'Transfers',
        forcedGlobalFilter: [
          ...defaultForcedFilters,
          {
            field: 'type_id',
            op: 'in',
            value: ['transfer', 'reverse_transfer']
          }
        ]
      }
    ];
  }, [getQuery, bankAccountId]);

  return <TabbedTable tabs={tabs} />;
}
