import React, { useEffect } from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { TrustJournalEntry } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';
import { NumberBadge } from 'view/components/badge';
import { Tabs } from '@rexlabs/tabs';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { useValueListQuery } from 'src/lib/react-query/hooks/use-value-list-query';
import { AvailableMatchesTable } from '../components/available-matches-table';
import { BankStatementTransactionImport } from '../types/bank-statement-transaction-import';

export const availableMatchesBlock: BlockConfig<{
  singleReceipts: TrustJournalEntry[];
  paymentGatewayFiles: BankStatementTransactionImport[];
}> = {
  id: 'available-matches',
  title: 'Available Matches',
  Edit: ({ data, blockProps }) => {
    const { hasFeature } = useFeatureFlags();
    const { data: paymentGatewayTypes } = useValueListQuery('payment_gateway');

    const hasPaymentGatewaysFeature = hasFeature(FLAGS.PAYMENT_GATEWAYS);

    const hasPaymentGatewayTypes =
      paymentGatewayTypes && paymentGatewayTypes.length > 0;

    const {
      availableMatches,
      setAvailableMatches,
      selectedMatches,
      singleReceiptsDataIsLoading,
      paymentGatewayFilesDataIsLoading
    } = blockProps;

    const availableSingleReceipts = availableMatches.singleReceipts ?? [];
    const availablePaymentGatewayFiles =
      availableMatches.paymentGatewayFiles ?? [];
    const allAvailable = [
      ...availableSingleReceipts,
      ...availablePaymentGatewayFiles
    ];

    const allAvailableIsLoading =
      singleReceiptsDataIsLoading || paymentGatewayFilesDataIsLoading;

    useEffect(() => {
      setAvailableMatches({
        singleReceipts: (data?.singleReceipts || [])?.filter(
          (singleReceipt) =>
            !selectedMatches.find(
              (selectedMatch) =>
                selectedMatch.id === singleReceipt.id &&
                selectedMatch.__record_type === 'trust_journal_entry'
            )
        ),
        paymentGatewayFiles: (data?.paymentGatewayFiles || [])?.filter(
          (paymentGatewayFile) =>
            !selectedMatches.find(
              (selectedMatch) =>
                selectedMatch.id === paymentGatewayFile.id &&
                selectedMatch.__record_type ===
                  'bank_statement_transaction_import'
            )
        )
      });
    }, [setAvailableMatches, allAvailableIsLoading]);

    const singleReceiptsTab = React.useCallback(() => {
      return (
        <AvailableMatchesTable
          isLoading={allAvailableIsLoading}
          data={availableSingleReceipts}
          blockProps={blockProps}
        />
      );
    }, [availableMatches?.singleReceipts?.length, allAvailableIsLoading]);

    const paymentGatewaysTab = React.useCallback(() => {
      return (
        <AvailableMatchesTable
          isLoading={allAvailableIsLoading}
          data={availablePaymentGatewayFiles}
          blockProps={blockProps}
        />
      );
    }, [availableMatches?.paymentGatewayFiles?.length, allAvailableIsLoading]);

    const allTab = React.useCallback(() => {
      return (
        <AvailableMatchesTable
          isLoading={allAvailableIsLoading}
          data={allAvailable}
          blockProps={blockProps}
        />
      );
    }, [allAvailable?.length, allAvailableIsLoading]);

    return (
      <Tabs
        items={[
          {
            label: 'All',
            name: 'all',
            Tab: allTab,
            IconEnd: (props) => (
              <NumberBadge
                number={!allAvailableIsLoading ? allAvailable?.length ?? 0 : 0}
                isActive={props.isActive}
              />
            )
          },
          {
            label: 'Single receipts',
            name: 'single-receipts',
            Tab: singleReceiptsTab,
            IconEnd: (props) => (
              <NumberBadge
                number={
                  !allAvailableIsLoading
                    ? availableSingleReceipts?.length ?? 0
                    : 0
                }
                isActive={props.isActive}
              />
            )
          },
          ...(hasPaymentGatewaysFeature && hasPaymentGatewayTypes
            ? [
                {
                  label: 'Payment gateways',
                  name: 'payment-gateways',
                  Tab: paymentGatewaysTab,
                  IconEnd: (props) => (
                    <NumberBadge
                      number={
                        !allAvailableIsLoading
                          ? availablePaymentGatewayFiles?.length ?? 0
                          : 0
                      }
                      isActive={props.isActive}
                    />
                  )
                }
              ]
            : [])
        ]}
      />
    );
  }
};
