import { useDialog } from '@rexlabs/dialog';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { CreatePortfolioRoleRecordDialog } from '../../dialogs/create-portfolio-role-record-dialog';

export function useGetCreatePortfolioRoleAction() {
  const { open } = useDialog(CreatePortfolioRoleRecordDialog);
  return (): ActionDeclaration => {
    return {
      id: 'create-portfolio-role',
      group: 'portfolio-roles',
      label: 'Create Portfolio role',
      handleAction: async () => {
        open();
      }
    };
  };
}
