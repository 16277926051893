import { DialogProps } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import * as React from 'react';
import { useRecordCreatedToast } from 'src/modules/common/toasts/hooks/use-record-created-toast';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { roleDetailsBlock } from '../blocks/role-details-block';
import { rolesModel } from '../models/roles-model';

export interface CreateRoleRecordDialogProps extends DialogProps {
  initialValues: {
    // TODO: add any desired props here
  };
}

const content = [
  {
    id: 'add-role',
    label: 'Add Role',
    blocks: [roleDetailsBlock]
  }
];

export function CreateRoleRecordDialog({
  onClose,
  initialValues
}: CreateRoleRecordDialogProps) {
  const { createItem, refreshLists } = useModelActions(rolesModel);
  const addToast = useRecordCreatedToast(rolesModel, { actions: [] });

  const handleSubmit = async ({ values }) => {
    // TODO: make any modifications to the data here before submitting
    const dataToSubmit = { ...values };

    const { data: role } = await createItem({ data: dataToSubmit });

    addToast(role);

    return refreshLists();
  };

  return (
    <RecordDialog
      title='Create Role'
      data={initialValues}
      content={content}
      onClose={onClose}
      handleSubmit={handleSubmit}
    />
  );
}
