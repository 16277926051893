import * as React from 'react';
import { TitleBlock } from 'view/components/@luna/title-block';
import { RecordScreen } from 'view/components/record-screen';
import { useContent } from '../data/content';

export function ReportingListScreen() {
  const content = useContent();
  return (
    <RecordScreen
      privilege={'reporting.read'}
      content={content}
      titleBlock={<TitleBlock title='Reporting' />}
    />
  );
}
