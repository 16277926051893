import { uniqBy } from 'lodash';

import { useConfirmationDialog, useDialog } from '@rexlabs/dialog';
import {
  BaseModelGeneratorModel,
  useModelActions
} from '@rexlabs/model-generator';

import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { MessageIssue } from 'src/modules/communications/common/types/message-issue';

import { FLAGS } from 'utils/feature-flags';
import { pluralize } from 'utils/formatters';
import { messagesModel } from '../../models/messages-model';
import { FixMessageIssuesRecordDialog } from '../../dialogs/fix-message-issues-record-dialog';
import { messageBatchesModel } from '../../models/message-batches-model';
import { Message } from '../../types/Message';

export function useGetBulkPrintLetterAction(withConfirmation = true) {
  const { refreshLists, validateItems } = useModelActions(messagesModel);
  const { sendBatch } = useModelActions(messageBatchesModel);
  const { open: openFixIssuesRecordDialog } = useDialog(
    FixMessageIssuesRecordDialog
  );

  const { open: openConfirmation } = useConfirmationDialog();

  return (selectedItems: BaseModelGeneratorModel[]): ActionDeclaration => {
    return {
      flag: FLAGS.MESSAGES,
      intent: 'primary',
      id: 'bulk-print-letter',
      group: 'messages',
      label: `Print ${pluralize('letter', selectedItems.length)}`,
      handleAction: async () => {
        const validateRecipientResponse = await validateItems({
          messageIds: selectedItems.map((item) => item.id),
          args: {
            include: [
              'contact',
              'message',
              'message.context',
              'message.inline_attachments',
              'missing_compliance_type'
            ].join(',')
          }
        });

        const isValidRecipients =
          validateRecipientResponse !== false &&
          validateRecipientResponse.data.length === 0;

        if (!isValidRecipients) {
          const deduplicatedContactIssues = uniqBy(
            validateRecipientResponse.data,
            (issue: MessageIssue) =>
              `${issue.contact.id} ${issue.message?.forced_channel_type?.id}`
          );
          return openFixIssuesRecordDialog({
            data: {
              messageIssues: deduplicatedContactIssues
            }
          });
        }

        async function onConfirm() {
          await sendBatch({ messages: selectedItems as Message[] });
          return refreshLists();
        }

        if (!withConfirmation) {
          return onConfirm();
        }

        openConfirmation({
          title: 'Print letters',
          message: `Are you sure you want to print ${
            selectedItems.length
          } ${pluralize('letter', selectedItems.length)}?`,
          onConfirm
        });
      }
    };
  };
}
