import { CommunicationContextType } from 'src/modules/communications/common/types/communication-context-type';
import {
  ContactMessageTriggerKey,
  OwnershipMessageTriggerKey,
  TenancyMessageTriggerKey,
  MessageTriggerKey
} from '../types/MessageTriggerKey';
import { TabId, tabIds } from '../types/MessageTriggerSettingsTabId';

type MessageTriggerKeyFromTabId<T extends TabId> = T extends 'contact'
  ? ContactMessageTriggerKey
  : T extends 'ownership'
  ? OwnershipMessageTriggerKey
  : T extends 'tenancy'
  ? TenancyMessageTriggerKey
  : never;

export type FieldMapItem = {
  label: string;
  context_type_id: CommunicationContextType;
  recipient_groups?: string[];
};

type FieldLabelMap<T extends TabId> = Record<
  MessageTriggerKeyFromTabId<T>,
  FieldMapItem
>;

const contactMessageTriggerMap: FieldLabelMap<'contact'> = {
  'contact-disbursement-template': {
    label: 'On disbursement',
    context_type_id: 'contact_disbursement'
  },
  'contact-payment-receipt-template': {
    label: 'Payment receipt created',
    context_type_id: 'contact_payment_receipt'
  }
};

const ownershipMessageTriggerMap: FieldLabelMap<'ownership'> = {
  'ownership-disbursement-template': {
    label: 'On disbursement',
    context_type_id: 'ownership_disbursement'
  },
  'periodic-ownership-statement-template': {
    label: 'Periodic income statement issued',
    context_type_id: 'periodic_ownership_statement'
  },
  'yearly-ownership-statement-template': {
    label: 'Income financial year statement issued',
    context_type_id: 'yearly_ownership_statement'
  },
  'inspection-template': {
    label: 'Inspection report created',
    context_type_id: 'task_inspection'
  },
  'ownership-invoice-template': {
    label: 'Invoice created (and email included)',
    context_type_id: 'invoice',
    recipient_groups: ['payable_by']
  }
};

const tenancyMessageTriggerMap: FieldLabelMap<'tenancy'> = {
  'tenancy-invoice-template': {
    label: 'Invoice created (and email included)',
    context_type_id: 'invoice',
    recipient_groups: ['payable_by', 'payable_by_tenancy_tenants']
  }
};

export function getMessageTriggerSettingsMapForTabId<T extends TabId>(
  tabId: T
): FieldLabelMap<T> {
  const messageTriggerMap: Record<TabId, FieldLabelMap<TabId>> = {
    contact: contactMessageTriggerMap,
    ownership: ownershipMessageTriggerMap,
    tenancy: tenancyMessageTriggerMap
  };
  return messageTriggerMap[tabId];
}

export const messageTriggerSettingsMap = tabIds.reduce<
  Record<MessageTriggerKey, FieldMapItem>
>((acc, tabId) => {
  const fieldMap = getMessageTriggerSettingsMapForTabId(tabId);

  return { ...acc, ...fieldMap };
}, {} as Record<MessageTriggerKey, FieldMapItem>);
