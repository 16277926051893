import { ContentConfig } from 'view/components/record-screen/types';

import { messageTriggerSettingsBlockFactory } from 'src/modules/communications/messages/settings/message-triggers/factories/message-trigger-settings-block-factory';
import { pluralize } from 'utils/formatters';
import { capitalize, map } from 'lodash';
import { getMessageTriggerSettingsMapForTabId } from 'src/modules/communications/messages/settings/message-triggers/utils/field-map';
import { messageTriggersWarning } from '../blocks/message-triggers-warning-block';
import { tabIds } from '../types/MessageTriggerSettingsTabId';

export const content: ContentConfig = [
  {
    label: 'Message trigger settings',
    items: [
      ...tabIds.map((tabId) => {
        const fields = getMessageTriggerSettingsMapForTabId(tabId);
        const blocks = map(fields, (value, key) =>
          messageTriggerSettingsBlockFactory(key, value)
        );
        return {
          id: tabId,
          label: capitalize(pluralize(tabId, 2)),
          blocks: [messageTriggersWarning, ...blocks]
        };
      })
    ]
  }
];
