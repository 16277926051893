import { FLAGS } from 'utils/feature-flags';

import { ContentConfig } from 'view/components/record-screen/types';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';

import { invoiceDetailsBlock } from '../blocks/invoice-details-block';
import { reimbursementInvoicesBlock } from '../blocks/reimbursement-invoices';
import { commissionDetailsBlock } from '../blocks/commission-details-block';

export const useContent: (isSupplierInvoice: boolean) => ContentConfig = (
  isSupplierInvoice
) => {
  const { hasFeature } = useFeatureFlags();
  return [
    {
      label: 'General',
      items: [
        {
          id: 'overview',
          label: 'Overview',
          blocks: [
            invoiceDetailsBlock,
            ...(!!hasFeature(FLAGS.SUPPLIER_COMMISSIONS) && isSupplierInvoice
              ? [commissionDetailsBlock]
              : []),

            reimbursementInvoicesBlock
          ]
        }
      ]
    }
  ];
};
