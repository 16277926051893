import React from 'react';
import { useWhereabouts } from '@rexlabs/whereabouts';
import { Columns } from 'src/view/components/table';
import { SummaryCell } from 'src/modules/tasks/common/components/summary-cell';
import { MoveIn } from 'src/modules/tasks/move-in/types/MoveIn';
import { ProgressDropdown } from 'src/modules/tasks/common/components/progress-dropdown';
import { Trans } from 'react-i18next';
import {
  closedDate,
  createdBy,
  createdDate,
  managedBy
} from '../../common/utils/common-columns';

const priority = {
  id: 'priority_id',
  type: 'value',
  Header: 'Priority',
  accessor: (item: MoveIn) => item.priority,
  toCsv: (item: MoveIn) => item?.priority?.label
} as const;

const taskMoveInStatusId = {
  id: 'task_move_in_status_id',
  Header: 'Progress',
  Cell: ProgressDropdown,
  accessor: (item: MoveIn) => item,
  width: 200,
  toCsv: (item: MoveIn) => item?.status?.label || ''
};

const moveInDate = {
  id: 'move_in_date',
  Header: () => (
    <Trans i18nKey={'tasks.move-in.move-in-date'}>Move in date</Trans>
  ),
  accessor: (item: MoveIn) => item.details?.move_in_date,
  type: 'date',
  width: 140
} as const;

const followUpDate = {
  id: 'follow_up_date',
  Header: 'Follow up date',
  accessor: (item: MoveIn) => item.follow_up_date,
  type: 'date',
  width: 140
} as const;

const summary = {
  id: 'summary',
  Header: 'Summary',
  Cell: SummaryCell,
  accessor: (item: MoveIn) => item,
  width: 380,
  toCsv: (item: MoveIn) => item.summary
};

export function useMoveInColumns(): Columns {
  const whereabouts = useWhereabouts();
  const tab = whereabouts.hashQuery?.tab;

  if (tab === 'closed') {
    return [
      summary,
      closedDate,
      moveInDate,
      managedBy,
      priority,
      createdBy,
      createdDate
    ];
  }

  return [
    summary,
    followUpDate,
    moveInDate,
    taskMoveInStatusId,
    managedBy,
    priority,
    createdBy,
    createdDate
  ];
}
