import * as React from 'react';
import { ListTable } from 'view/components/table';
import { Card } from 'view/components/card';
import { ListScreen } from 'view/components/list-screen/list-screen';
import { query } from '@rexlabs/model-generator';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { CUSTOM_REPORT_ROUTES } from '../routes';

import { customReportColumns } from '../utils/custom-report-columns';
import { useGetCustomReportActions } from '../hooks/action-menu-items/use-get-custom-report-actions';
import { customReportsModel } from '../models/custom-reports-model';
import { useGetCreateCustomReportAction } from '../hooks/action-declarations/use-get-create-custom-report-action';

// TODO: add required includes
const customReportQuery = query`{
  ${customReportsModel} {
    id
  }
}`;

/**
 * Tabbed List Table
 * Model Generator Variant
 */
export function CustomReportList() {
  const getCustomReportActions = useGetCustomReportActions();
  const getCreateCustomReportAction = useGetCreateCustomReportAction();

  const { getSort, getFilters } = useTableFilters('custom-reports');

  const getRowLinkProps = ({ item }) => {
    return {
      to: CUSTOM_REPORT_ROUTES.CUSTOM_REPORT_DETAILS,
      params: { customReportId: item.id }
    };
  };

  const getActionMenuItems = React.useCallback(
    ({ item }) => {
      const actions = getCustomReportActions(item);
      return transformActionDeclarationsToActionMenuItems(actions);
    },
    [getCustomReportActions]
  );

  return (
    <ListScreen
      title='Custom reports'
      actions={[getCreateCustomReportAction()]}
    >
      <Card>
        <ListTable
          id='custom-reports'
          columns={customReportColumns}
          getQuery={() => customReportQuery}
          getRowLinkProps={getRowLinkProps}
          getActionMenuItems={getActionMenuItems}
          getFilters={getFilters}
          getSort={getSort}
        />
      </Card>
    </ListScreen>
  );
}
