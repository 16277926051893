import { getSearchResultItemFromManagedBy } from '../../common/utils/get-search-result-item-from-managed-by';
import { CustomTask } from '../types/CustomTask';
import { CustomTaskFormData } from '../types/CustomTaskFormData';

export function mapCustomTaskToCustomTaskFormData(
  task: CustomTask
): CustomTaskFormData {
  return {
    summary: task.summary || null,
    description: task.description || null,
    due_date: task.due_date ? task.due_date : null,
    follow_up_date: task.follow_up_date ? task.follow_up_date : null,
    managed_by: getSearchResultItemFromManagedBy(task.managed_by),
    priority: task.priority || null,
    property: task.property || null,
    status: task.status || null,
    // TODO: custom fields
    custom_field_values: task.custom_field_values || {}
  };
}
