import { Field } from '@rexlabs/form';
import invariant from 'invariant';
import React from 'react';
import { FroalaEditorInput } from 'view/components/@luna/inputs/froala-editor/components/froala-editor-input';
import { CustomField } from '../types/CustomField';

export const CustomFieldEditInput = React.memo(
  ({ field }: { field: CustomField }) => {
    // TODO: add other field types here when needed

    invariant(
      field.id || field.derived_from_custom_field.id,
      'Field must have an id or a dervied_from_custom_field.id'
    );

    return (
      <Field
        label={field.label}
        Input={FroalaEditorInput}
        name={`custom_field_values.${
          field.id ?? field.derived_from_custom_field.id
        }`}
      />
    );
  }
);
