import { useModelActions } from '@rexlabs/model-generator';
import { RecordTypes } from 'data/models/types';
import React from 'react';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { statementsModel } from 'src/modules/statements/models/statements';
import { Statement } from 'src/modules/statements/types';
import { getRecordLabel } from 'utils/records/get-record-label';
import { ActionMenuSubtitle } from 'view/components/action-menu/action-menu-subtitle';
import Icons from '@rexlabs/icons';
import { useModelDownloadAction } from 'src/modules/common/actions/reporting/use-model-download-action';
import { ReportFormat } from 'src/modules/common/types/reporting';

type GetStatementViewActions = (
  statement: Statement
) => {
  summaryDownloadActions: ActionDeclaration[];
  detailedDownloadActions: ActionDeclaration[];
};

export function useGetStatementDownloadActions(): GetStatementViewActions {
  const { Download } = Icons;
  const { downloadItem } = useModelActions(statementsModel);
  const modelDownloadAction = useModelDownloadAction(statementsModel);
  const formats: ReportFormat[] = ['pdf', 'csv'];

  return function (statement: Statement) {
    const summaryDownloadActions =
      statement.recipients.items.length > 0
        ? statement.recipients.items
            .map((contact) =>
              formats.map((format) =>
                modelDownloadAction({
                  fetcher: () =>
                    downloadItem({
                      statementId: statement.id,
                      recipientId: contact.id,
                      format
                    }),
                  toastArgs: {
                    title: `Statement for ${contact.display_name}`
                  },
                  id: `download-summary-${format}-${contact.display_name}`,
                  label: `${getRecordLabel({
                    type: RecordTypes.Contact,
                    object: contact
                  })} (${format.toUpperCase()})`,
                  record: statement,
                  group: 'summary-income-statements',
                  groupSubtitle: (
                    <ActionMenuSubtitle
                      subtitle={'Summary income statement'}
                      Icon={Download}
                    />
                  )
                })
              )
            )
            .flat()
        : [];

    const detailedDownloadActions =
      statement.recipients.items.length > 0
        ? statement.recipients.items
            .map((contact) =>
              formats.map((format) =>
                modelDownloadAction({
                  fetcher: () =>
                    downloadItem({
                      reportId: 'income-detailed',
                      statementId: statement.id,
                      recipientId: contact.id,
                      format
                    }),
                  label: `${getRecordLabel({
                    type: RecordTypes.Contact,
                    object: contact
                  })} (${format.toUpperCase()})`,
                  record: statement,
                  toastArgs: {
                    title: `Statement for ${contact.display_name}`
                  },
                  id: `download-detailed-${format}-${contact.display_name}`,
                  group: 'detailed-income-statements',
                  groupSubtitle: (
                    <ActionMenuSubtitle
                      subtitle={'Detailed income statement'}
                      Icon={Download}
                    />
                  )
                })
              )
            )
            .flat()
        : [];

    return { summaryDownloadActions, detailedDownloadActions };
  };
}
