import { useDialog } from '@rexlabs/dialog';

import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

import AddIcon from 'view/components/icons/add';
import { CreateCustomTaskTemplateDialog } from '../../dialogs/create-custom-task-template';

export function useGetCreateCustomTaskTemplateAction() {
  const createCustomTaskTemplateDialog = useDialog(
    CreateCustomTaskTemplateDialog
  );

  return (): SingleActionDeclaration => {
    return {
      id: 'create-custom-task-template',
      intent: 'primary',
      group: 'custom-task-templates',
      label: 'Create custom task type',
      Icon: AddIcon,
      handleAction: async () => {
        createCustomTaskTemplateDialog.open();
      }
    };
  };
}
