import React, { useMemo } from 'react';
import { query, useEntityQuery } from '@rexlabs/model-generator';

import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { RecordScreen } from 'view/components/record-screen';

import { useRecordScreenSubmitHandler } from 'view/hooks/use-record-screen-submit-handler';
import { TaskRightBarOverride } from 'src/modules/tasks/common/components/right-bar-override';
import { mapMoveOutFormDataToTaskCreateRequest } from '../mappers/map-move-out-form-data-to-task-create-request';
import { content } from '../data/content';
import { MoveOutTitleBlock } from '../components/move-out-title-block';
import { tasksModel } from '../../common/models/tasks-model';
import { getSearchResultItemFromManagedBy } from '../../common/utils/get-search-result-item-from-managed-by';

const getMoveOutQuery = (id: string) => query`{
  ${tasksModel} (id: ${id}) {
    id
    property {
      active_property_ownership {
        ownership {
          id
          owners
        }
      }
    }
    managed_by
    moveOutDetails
    task_links
    created_by
    updated_by
  }
}`;

interface MoveOutDetailsProps {
  moveOutId?: string;
}

export function MoveOutDetails({ moveOutId }: MoveOutDetailsProps) {
  const breadcrumbs = [{ type: BREADCRUMBS.MOVE_OUT }];
  const query = useMemo(() => getMoveOutQuery(moveOutId!), [moveOutId]);

  const { status, data, actions } = useEntityQuery(query, {
    throwOnError: false
  });

  const handleSubmit = useRecordScreenSubmitHandler(
    async ({ changedValues }) => {
      const dataToSubmit = mapMoveOutFormDataToTaskCreateRequest(changedValues);

      await actions.updateItem({
        id: moveOutId,
        data: dataToSubmit
      });
    }
  );

  const titleBlock = <MoveOutTitleBlock moveOut={data!} />;

  const initialValues = useMemo(
    () =>
      data
        ? {
            ...data,
            managed_by: getSearchResultItemFromManagedBy(data.managed_by)
          }
        : undefined,
    [data]
  );

  return (
    <RecordScreen
      privilege={'tasks.move-out.read'}
      isLoading={status === 'loading'}
      data={data}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      content={content}
      titleBlock={titleBlock}
      breadcrumbs={breadcrumbs}
      RightBarOverride={TaskRightBarOverride}
    />
  );
}
