import React, { ComponentType, useCallback, useMemo, useState } from 'react';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import {
  ButtonGroupProps,
  DialogContentConfig
} from 'view/components/record-screen/dialog/types';
import {
  query,
  useEntityListQuery,
  fragment,
  useModelActions
} from '@rexlabs/model-generator';
import { trustJournalEntryModel } from 'src/modules/trust-journal-entries/models/trust-journal-entries';
import { toQuri } from '@rexlabs/quri';

import { BankAccount } from 'data/models/entities/financials/bank-accounts';
import { useToast } from 'view/components/@luna/notifications/toast';
import { pluralize } from 'utils/formatters';
import { useQueryClient } from 'react-query';
import { TrustJournalEntry } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';
import { availableMatchesBlock } from '../blocks/available-matches';
import { selectedMatchesBlock } from '../blocks/selected-matches';
import { searchReceiptsBlock } from '../blocks/search-for-receipts';
import { bankStatementTransactionsModel } from '../models/bank-statement-transaction-model';
import { bankStatementTransactionsQueryKey } from '../hooks/use-bank-statement-transactions-query';
import { bankStatementTransactionImportsModel } from '../models/bank-statement-transaction-imports-model';
import { BankStatementTransactionImport } from '../types/bank-statement-transaction-import';
import {
  MatchToExistingButtonGroup,
  MatchToExistingButtonGroupProps
} from '../components/match-to-existing-dialog-button-group';

const content: DialogContentConfig = [
  {
    id: 'match-to-existing-receipt',
    label: 'Match to existing receipt',
    blocks: [searchReceiptsBlock, availableMatchesBlock, selectedMatchesBlock]
  }
];

const getTrustJournalEntriesQuery = (filter: string, searchValue: string) => {
  const trustJournalEntryQueryFragment = fragment`
    id
    payment_information
    contacts
  `;

  return searchValue
    ? query`{
      ${trustJournalEntryModel} (search: ${searchValue}, q: ${filter}){
        ${trustJournalEntryQueryFragment}
      }
    }`
    : query`{
    ${trustJournalEntryModel} (q: ${filter}){
      ${trustJournalEntryQueryFragment}
    }
  }`;
};

const getPaymentGatewayFilesQuery = (filter: string, searchValue: string) => {
  const paymentGatewayFilesQueryFragment = fragment`
    id
    file
    payment_gateway
  `;

  return searchValue
    ? query`{
      ${bankStatementTransactionImportsModel} (search: ${searchValue}, q: ${filter}){
        ${paymentGatewayFilesQueryFragment}
      }
    }`
    : query`{
    ${bankStatementTransactionImportsModel} (q: ${filter}){
      ${paymentGatewayFilesQueryFragment}
    }
  }`;
};

interface MatchToExistingDialogProps {
  data: {
    amount: number;
    reference?: string;
    dateOf?: string;
    bankAccount?: BankAccount;
    bankStatementTransactionId?: string;
  };
  closeParentDialog?: () => void;
  onClose?: () => void;
}

export function MatchToExistingDialog({
  onClose,
  closeParentDialog,
  data: { amount, reference = '', bankStatementTransactionId }
}: MatchToExistingDialogProps) {
  const [searchValue, setSearchValue] = React.useState(reference);
  const [selectedMatches, setSelectedMatches] = useState<
    Array<TrustJournalEntry | BankStatementTransactionImport> | undefined
  >([]);

  const [availableMatches, setAvailableMatches] = useState<{
    singleReceipts: TrustJournalEntry[];
    paymentGatewayFiles: BankStatementTransactionImport[];
  }>({ singleReceipts: [], paymentGatewayFiles: [] });

  const { matchBankStatementTransaction } = useModelActions(
    bankStatementTransactionsModel
  );

  const { addToast } = useToast();

  const queryClient = useQueryClient();

  const handleSubmit = useCallback(
    async ({ values }) => {
      const {
        selected_receipts: selectedReceipts,
        bank_statement_transaction_id: bankStatementTransactionId
      } = values;

      const response = await matchBankStatementTransaction({
        bankStatementTransactionId: bankStatementTransactionId,
        trustJournalEntryIds: selectedReceipts.map(
          (selectedReceipt) => selectedReceipt.id
        )
      });

      await addToast({
        description: (
          <>
            The bank transaction has been successfully matched against{' '}
            {selectedReceipts.length} trust journal{' '}
            {pluralize('entry', selectedReceipts.length, false)}
          </>
        )
      });

      await queryClient.refetchQueries({
        queryKey: [bankStatementTransactionsQueryKey],
        refetchPage: () => true
      });

      return response.data;
    },
    [addToast, matchBankStatementTransaction, queryClient]
  );

  const singleReceiptsQueryFilter = toQuri([
    {
      field: 'type_id',
      op: '==',
      value: 'receipt'
    },
    {
      field: 'bank_statement_transaction_id',
      op: 'eq',
      value: 'null'
    },
    {
      field: 'status_id',
      op: 'eq',
      value: 'active'
    }
  ]);

  const paymentGatewayFilesQueryFilter = toQuri([
    {
      field: 'type_id',
      op: '==',
      value: 'payment_gateway'
    }
  ]);

  const singleReceiptsQuery = useMemo(
    () => getTrustJournalEntriesQuery(singleReceiptsQueryFilter, searchValue),
    [searchValue, singleReceiptsQueryFilter]
  );

  const {
    data: singleReceiptsData,
    status: singleReceiptsStatus
  } = useEntityListQuery(singleReceiptsQuery);

  const paymentGatewayFilesQuery = useMemo(
    () =>
      getPaymentGatewayFilesQuery(paymentGatewayFilesQueryFilter, searchValue),
    [searchValue, paymentGatewayFilesQueryFilter]
  );

  const {
    data: paymentGatewayFilesData,
    status: paymentGatewayFilesStatus
  } = useEntityListQuery(paymentGatewayFilesQuery);

  return (
    <RecordDialog
      title={'Match to existing'}
      data={{
        singleReceipts: singleReceiptsData,
        paymentGatewayFiles: paymentGatewayFilesData
      }}
      handleSubmit={handleSubmit}
      content={content}
      ButtonGroup={
        MatchToExistingButtonGroup as ComponentType<ButtonGroupProps>
      }
      buttonGroupProps={
        {
          closeParentDialog,
          selectedMatches,
          amount
        } as MatchToExistingButtonGroupProps
      }
      onClose={onClose}
      size='l'
      blockProps={{
        amount,
        reference,
        setSelectedMatches,
        selectedMatches,
        availableMatches,
        setAvailableMatches,
        searchValue,
        setSearchValue,
        bankStatementTransactionId,
        singleReceiptsDataIsLoading: singleReceiptsStatus === 'loading',
        paymentGatewayFilesDataIsLoading:
          paymentGatewayFilesStatus === 'loading'
      }}
    />
  );
}
