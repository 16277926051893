import React, { useMemo } from 'react';

import { Message } from '@rexlabs/notifications';

import { OutlineButton } from '@rexlabs/button';
import { Card } from 'view/components/@luna/card';
import { Grid } from 'view/components/@luna/form/grid';

import { formatDate } from 'utils/dates/format';
import { BlockConfig } from 'view/components/record-screen/types';
import { Value } from 'view/components/values';
import { WorkInProgress } from 'view/components/work-in-progress';

import Box from '@rexlabs/box';
import { Action } from '@rexlabs/notifications/lib/types/message/action-buttons';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { push, useWhereabouts } from '@rexlabs/whereabouts';
import { startCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import ROUTES from 'routes/app';
import TenancyIllustration from 'src/assets/illustrations/tenancy.svg';
import { FlattenedProperty } from 'src/modules/properties/types/property-types';
import { PropertyTenancyTitleBlock } from 'src/modules/property-tenancies/components/property-tenancy-title-block';
import { PropertyTenancy } from 'src/modules/property-tenancies/types/property-tenancy-types';
import {
  getPaidToDateDescription,
  getPaidToPeriodDescription
} from 'src/modules/property-tenancies/utils/get-rent-position-description';
import { useSettingsQuery } from 'src/modules/settings/hooks/useSettingsQuery';
import { formatCurrency } from 'utils/formatters';
import { Divider } from 'view/components/@luna/divider/divider';
import { StatBlockProps, StatBlocks } from 'view/components/@luna/stat-block';
import { TitleBlockProps } from 'view/components/cards/title/title-block';
import { PropertyTenancySelect } from 'view/components/inputs/selects/v4-selects/property-tenancy-select';
import { getRentPerPeriodString } from 'src/modules/financials/utils/get-rent-per-period-string';
import { RentInvoiceRegenerationBanner } from 'src/modules/property-tenancies/components/rent-invoice-regeneration-banner';
import { CustomTitleHeader } from '../../properties/components/custom-title-header';
import { propertyDetailContext } from '../../properties/screens/property-details';
import { useGetCreatePropertyTenancyAction } from '../actions/use-get-create-property-tenancy-action';

const defaultStyles = StyleSheet({
  propertyTenancySelect: {
    width: '300px'
  },
  propertyTenancyHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap'
  }
});

// TODO: Finish hooking up record summary data
export const propertyTenancySummaryBlock: BlockConfig<FlattenedProperty> = {
  id: 'property-tenancy-summary',
  View: ({ data }) => {
    const { t } = useTranslation();
    const s = useStyles(defaultStyles);

    const { selected_property_tenancy } = data || {};
    const { data: settings } = useSettingsQuery();

    const getCreatePropertyTenancyAction = useGetCreatePropertyTenancyAction();
    const { query } = useWhereabouts();

    const isOverviewTab = query?.tab === 'overview' || query?.tab == null;

    const { setSelectedPropertyTenancy } = React.useContext(
      propertyDetailContext
    );

    const stats: TitleBlockProps['stats'] = useMemo(() => {
      const rentArrears = selected_property_tenancy?.rent_arrears?.amount ?? 0;

      const securityDepositTotal =
        selected_property_tenancy?.security_deposits?.reduce(
          (sum, item) => sum + item.amount_expected,
          0
        ) ?? 0;
      const securityDepositDue =
        selected_property_tenancy?.security_deposits?.reduce(
          (sum, item) => sum + item.amount_due,
          0
        ) ?? 0;

      const trustBalance =
        selected_property_tenancy?.summary?.available_trust_balance_amount ?? 0;

      const rentPosition = selected_property_tenancy?.rent_position;

      const rentSchedules = selected_property_tenancy?.rent_schedule;

      const currentRentSchedule =
        rentSchedules &&
        rentSchedules.find((schedule) => schedule.status.id === 'current');

      const currentRent = currentRentSchedule?.amount_total_inc_tax;
      const currentFrequency = currentRentSchedule?.frequency;

      const paidToDateSetting =
        settings['rent-paid-to-date']?.id || 'effective_with_surplus';

      return [
        {
          label: 'Rent arrears',
          value: formatCurrency(rentArrears),
          intent: rentArrears > 0 ? 'danger' : 'success',
          'data-testid': 'rent-arrears-stat'
        },
        ...([
          'effective_with_surplus',
          'effective_and_periodic_with_surplus'
        ].includes(paidToDateSetting)
          ? [
              {
                label: 'Effective paid to date',
                value: getPaidToDateDescription({ rentPosition }),
                intent: 'neutral',
                'data-testid': 'effective-paid-to-date-stat'
              }
            ]
          : []),

        ...([
          'periodic_with_surplus',
          'effective_and_periodic_with_surplus'
        ].includes(paidToDateSetting)
          ? [
              {
                label: 'Paid to period',
                value: getPaidToPeriodDescription({
                  rentPosition
                }),
                intent: 'neutral',
                'data-testid': 'paid-to-period-stat'
              }
            ]
          : []),
        {
          label: 'Security deposit (total / due)',
          value: `${formatCurrency(securityDepositTotal)} / ${formatCurrency(
            securityDepositDue
          )}`,
          intent: 'neutral',
          'data-testid': 'security-deposit-stat'
        },

        {
          label: t('financials.trust.available-trust-balance.label.singular'),
          value: formatCurrency(trustBalance),
          intent: 'neutral',
          'data-testid': 'available-trust-balance-stat'
        },
        {
          label: 'Current rent',
          value:
            currentRent && currentFrequency
              ? getRentPerPeriodString({
                  rent: currentRent,
                  frequency: currentFrequency
                })
              : undefined,
          intent: 'neutral',
          'data-testid': 'current-rent-stat'
        }
      ] as StatBlockProps[];
    }, [t, selected_property_tenancy, settings]);

    if (selected_property_tenancy?.id) {
      const titleBlock = (
        <PropertyTenancyTitleBlock
          isCompact={true}
          hideViewAction={false}
          dropdownActionIntent='tertiary'
          propertyTenancy={selected_property_tenancy}
          CustomTitleHeader={CustomTitleHeader}
        />
      );

      return (
        <>
          <RentInvoiceRegenerationBanner
            propertyTenancy={selected_property_tenancy}
          />
          <Box>
            <Box {...s('propertyTenancyHeader')}>
              <CustomTitleHeader>
                {startCase(t('lease-agreements.label.plural') as string)}
              </CustomTitleHeader>
              {isOverviewTab ? (
                <OutlineButton
                  onClick={() => {
                    push(ROUTES.PROPERTY, {
                      query: {
                        tab: 'lease-agreements'
                      },
                      params: { propertyId: data?.id }
                    });
                  }}
                >
                  View agreement
                </OutlineButton>
              ) : (
                <Box {...s('propertyTenancySelect')}>
                  <PropertyTenancySelect
                    value={data?.selected_property_tenancy}
                    getItems={() => data!.property_tenancies!.items}
                    onChange={(e) => {
                      const propertyTenancy: PropertyTenancy = e.target.value;

                      if (propertyTenancy) {
                        setSelectedPropertyTenancy?.(e.target.value);

                        push(ROUTES.PROPERTY, {
                          query: {
                            tab: 'lease-agreements',
                            leaseAgreement: propertyTenancy.id
                          },
                          params: { propertyId: propertyTenancy.property_id }
                        });
                      }
                    }}
                    dialogProps={{ property: data }}
                  />
                </Box>
              )}
            </Box>
            <Divider />

            {titleBlock}

            <Box marginBottom={32}>
              <StatBlocks stats={stats} shouldWrap={true}></StatBlocks>
            </Box>

            {isOverviewTab && (
              <Grid columns={2}>
                <Value
                  label='Agreement start date'
                  value={
                    selected_property_tenancy.agreement_start_date
                      ? formatDate(
                          selected_property_tenancy.agreement_start_date
                        )
                      : null
                  }
                />
                <Value
                  label='Agreement end date'
                  value={
                    selected_property_tenancy.agreement_end_date
                      ? formatDate(selected_property_tenancy.agreement_end_date)
                      : null
                  }
                />
                <WorkInProgress>
                  <Value label='Current rent amount' />
                </WorkInProgress>
                <WorkInProgress>
                  <Value label='Next rent amount' />
                </WorkInProgress>
                <WorkInProgress>
                  <Value label='Total deposit required' />
                </WorkInProgress>
              </Grid>
            )}
          </Box>
        </>
      );
    }

    return (
      <Card>
        <Message
          title={t('lease-agreements.setup-new.title')}
          Illustration={TenancyIllustration}
          actions={[
            {
              ...getCreatePropertyTenancyAction({ property: data }),
              type: 'primary'
            } as Action
          ]}
        >
          {t('lease-agreements.setup-new.message')}
        </Message>
      </Card>
    );
  }
};
