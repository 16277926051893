import { ContentConfig } from 'view/components/record-screen/types';
import { portfolioRoleDetailsBlock } from '../blocks/portfolio-role-details-block';

export const content: ContentConfig = [
  {
    label: 'General',
    items: [
      {
        id: 'overview',
        label: 'Overview',
        blocks: [portfolioRoleDetailsBlock]
      }
    ]
  }
];
