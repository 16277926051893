import React, { useCallback } from 'react';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';

import { useModelActions } from '@rexlabs/model-generator';
import { RecordType } from 'data/models/types';
import dayjs from 'dayjs';
import { trustJournalEntryModel } from 'src/modules/trust-journal-entries/models/trust-journal-entries';
import { TrustJournalEntry } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';
import { getRecordTypeName } from 'utils/records/get-record-type-name';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { bankTransactionDetailsBlock } from 'src/modules/banking/common/blocks/bank-transaction-details';
import { bacsBankWithdrawalDetailsBlock } from 'src/modules/banking/bank-withdrawals/blocks/bacs-bank-withdrawal-details';

const content: DialogContentConfig = [
  {
    id: 'basics',
    label: 'Basics',
    blocks: [bankTransactionDetailsBlock, bacsBankWithdrawalDetailsBlock]
  }
];

interface CreateBacsBankWithdrawalDialogProps {
  updateListId?: string;
  onClose?: () => void;
  onCreate: (dateOf: Date, bacsProcessingDate: Date) => Promise<void>;
  trustJournalEntries: TrustJournalEntry[];
  recordType: RecordType;
}
export function CreateBacsBankWithdrawalDialog({
  updateListId,
  onCreate,
  onClose,
  trustJournalEntries,
  recordType
}: CreateBacsBankWithdrawalDialogProps) {
  const { refreshList } = useModelActions(trustJournalEntryModel);
  const handleSubmit: RecordSubmitHandler = useCallback(
    async ({ values }) => {
      await onCreate(values.date_of, values.bacs_processing_date);

      if (updateListId) {
        await refreshList({ id: updateListId });
      }

      return true;
    },
    [refreshList, updateListId, onCreate]
  );

  const initialData = {
    date_of: dayjs().format('YYYY-MM-DD'),
    bacs_processing_date: dayjs().format('YYYY-MM-DD')
  };

  return (
    <RecordDialog
      onClose={onClose}
      data={initialData}
      blockProps={{ trustJournalEntries, recordType }}
      title={`Create ${getRecordTypeName(recordType)}`}
      handleSubmit={handleSubmit}
      content={content}
      submitLabel={`Create ${getRecordTypeName(recordType)}`}
    />
  );
}
