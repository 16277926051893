import { Columns } from 'view/components/table';
import React from 'react';
import { formatDate } from 'utils/dates/format';
import { TwoTierCell } from 'view/components/cells/two-tier-cell';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles, useToken } from '@rexlabs/styling';
import { ColoredCard } from 'view/components/cards/colored-card';
import WarningCircleIcon from 'view/components/icons/warning-circle';
import CheckIcon from 'view/components/icons/check';
import CrossSmallIcon from 'view/components/icons/cross';
import { MoveFundsIcon } from 'view/components/icons/move-funds';

import { IconActionCell } from 'src/modules/common/components/cells/icon-action-cell';
import { noop } from 'lodash';
import { LinkButton } from '@rexlabs/button';
import { SplitIcon } from 'view/components/icons/split';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { BatchReceiptingItem } from '../types';
import { getBatchReceiptingItem } from '../hooks/use-map-batch-receipting-items';
import { useBatchReceiptingButtonConfig } from '../hooks/use-batch-receipting-button-config';
import { openSplitBankTransactionsDialog } from '../hooks/actions/use-get-split-transaction-view-edit-action';
import { Intent } from '../components/batch-receipting-card-layout';
import { BankStatementTransactionTypeIcon } from '../components/bank-statement-transaction-type-icon';
import { BATCH_RECEIPTING_FLAGS } from '../feature-flags';
import { BatchReceiptingLineItemAction } from '../components/batch-receipting-line-item';
import { getReceiptDescription } from './get-receipt-description';
import { getReceiptingInstructionDescription } from './get-receipting-instruction-description';

function SummaryCell({ value }: { value: BatchReceiptingItem }) {
  return (
    <TwoTierCell
      text={value.description}
      subtext={formatDate(value.paid_date)}
    />
  );
}

function PayToCell({ value }: { value: BatchReceiptingItem }) {
  const prioritisedMatch = value?.prioritised_match;

  const receiptDescription = getReceiptDescription(
    prioritisedMatch?.rent_invoice_count || 0,
    prioritisedMatch?.non_rent_invoice_count || 0
  );

  return (
    <TwoTierCell
      text={
        value.prepayment_bucket_reference
          ? value.prepayment_bucket?.name
          : receiptDescription
      }
      subtext={
        getReceiptingInstructionDescription({
          summary: prioritisedMatch?.folio?.display_name,
          receiptingInstruction: prioritisedMatch?.receipting_instruction
        }) || '-'
      }
    />
  );
}

function BatchReceiptingStatusIcon({ intent }: { intent: Intent }) {
  const token = useToken();

  switch (intent) {
    case 'success':
      return <CheckIcon color={token('palette.green.900')} />;
    case 'danger':
      return <CrossSmallIcon />;
    case 'warning':
      return <WarningCircleIcon />;
    default:
      return <MoveFundsIcon color={token('palette.grey.700')} />;
  }
}

function StatusCell({ value }) {
  const styles = StyleSheet({
    displayCell: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0
    }
  });

  const s = useStyles(styles);

  const getActions = useBatchReceiptingButtonConfig();
  const { intent } = getBatchReceiptingItem(value, getActions);

  return (
    <Box flexDirection='row' {...s('displayCell')}>
      <ColoredCard
        style={{ borderRadius: 0, justifyContent: 'center' }}
        intent={intent}
      >
        <Box display='flex' alignItems='center' justifyContent='center'>
          <BatchReceiptingStatusIcon intent={intent} />
        </Box>
      </ColoredCard>
    </Box>
  );
}

function SubTransactionCell({ value }) {
  return value.parent_transaction_id != null ? (
    <LinkButton
      IconLeft={SplitIcon}
      onClick={() => openSplitBankTransactionsDialog(value)}
    />
  ) : null;
}

function TransactionTypeCell({ value }: { value: BatchReceiptingItem }) {
  const type = value?.type;

  return (
    <IconActionCell
      tooltipLabel={type?.label}
      Icon={() => <BankStatementTransactionTypeIcon type={type?.id} />}
      onClick={
        type?.id === 'split_transactions'
          ? () => openSplitBankTransactionsDialog(value)
          : noop
      }
    />
  );
}

export function useBankStatementTransactionColumns(): Columns<BatchReceiptingItem> {
  const { hasFeature } = useFeatureFlags();

  const hasPaymentGatewaysFeature = hasFeature(
    BATCH_RECEIPTING_FLAGS.PAYMENT_GATEWAYS
  );

  const getActions = useBatchReceiptingButtonConfig();

  return [
    hasPaymentGatewaysFeature
      ? {
          id: 'transaction_type',
          accessor: (item) => item,
          width: 50,
          Cell: TransactionTypeCell
        }
      : {
          id: 'is_sub_transaction',
          accessor: (item) => item,
          width: 50,
          Cell: SubTransactionCell
        },
    {
      id: 'description',
      Header: 'Summary',
      accessor: (item) => item,
      Cell: SummaryCell
    },
    { id: 'reference', Header: 'Reference' },
    {
      id: 'amount',
      Header: 'Amount',
      type: 'currency',
      width: 120,
      cellProps: {
        align: 'right'
      }
    },
    {
      id: 'status',
      Cell: StatusCell,
      accessor: (item) => item,
      width: 60
    },
    {
      Header: 'Matched to folio',
      accessor: (item) => {
        return item?.prioritised_match?.folio?.display_name || '-';
      }
    },
    {
      id: 'to_pay',
      Header: 'To pay',
      accessor: (item) => item,
      Cell: PayToCell
    },
    {
      id: 'action',
      accessor: (item) => item,
      Cell({ value }) {
        const { SplitActionButton } = getBatchReceiptingItem(value, getActions);
        const { primaryAction, actions } = getActions(value);

        return (
          <BatchReceiptingLineItemAction
            SplitActionButton={SplitActionButton}
            primaryAction={primaryAction}
            actions={actions}
          />
        );
      }
    }
  ];
}
