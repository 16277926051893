import React, { ComponentType } from 'react';
import { get, isEmpty } from 'lodash';

import Box from '@rexlabs/box';
import { Field, FieldProps } from '@rexlabs/form';
import { useToken } from '@rexlabs/styling';
import { Small } from '@rexlabs/text';

import { SearchResultItem } from 'utils/api/get-search-results';

import { RecordType } from 'data/models/types';

import { getRecordObject } from 'src/modules/common/utils/Records/get-record-object';
import { getViewRecordRouteDetails } from 'src/modules/common/utils/Records/get-record-routes';

import { OpenIcon } from '../icons/open';

type FieldWithRecordLinkProps<I extends ComponentType> = Omit<
  FieldProps<I>,
  'HelpContent'
> & { values: Record<string, any> };

/**
 * Use this component when you would like to render a link to view a record in a field.
 *
 * This component is a wrapper around the Field component that replaces the HelpContent prop with a link to view a record.
 * There is a safety check to ensure that the value is a record object before trying to get the record link. If there is no record object,
 * the link will not be displayed, so if a engineer uses this component incorrectly, it will still work as a normal Field component.
 */
export function FieldWithRecordLink<I extends ComponentType>({
  values,
  ...fieldProps
}: FieldWithRecordLinkProps<I>) {
  const fieldValue: SearchResultItem | undefined = get(values, fieldProps.name);

  const href = getRecordLink(fieldValue);

  return (
    <Field
      HelpContent={() => (href ? <SmallRecordLink href={href} /> : null)}
      {...fieldProps}
    />
  );
}

function SmallRecordLink({ href }: { href: string }) {
  const token = useToken();
  return (
    <a href={href} target='_blank' rel='noopener noreferrer nofollow'>
      <Box
        flexDirection='row'
        alignItems='center'
        columnGap={token('spacing.xxs')}
      >
        <Small as='span'>View record</Small> <OpenIcon size='s' />
      </Box>
    </a>
  );
}

/**
 * This function gets a link to a record based on the value passed into it.
 * Because we don't know if the value is a record object, a search result item, or any other select value,
 * we need to check both and return the link if it exists.
 */
function getRecordLink(value: any) {
  const fieldValueId = value?.id;

  const fieldValueTypeId: RecordType | undefined =
    // if select is not using search
    get(value, '__record_type') ||
    // if select is using search
    get(value, 'type.id');

  // We will either have a record object, an empty object or undefined
  const recordObject =
    fieldValueTypeId && fieldValueId
      ? getRecordObject({ id: fieldValueId }, fieldValueTypeId)
      : undefined;

  return (
    // If we have a record object, we can get the path to the record
    !isEmpty(recordObject) && getViewRecordRouteDetails(recordObject)?.path
  );
}
