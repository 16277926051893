import { TaskType } from 'src/modules/tasks/common/types/TaskType';
import { ChecklistTemplateItemEditFormDueDate } from '../../types/ChecklistTemplatesForm';
import { useChecklistItemRelatedDatesQuery } from '../use-checklist-item-related-dates-query';
import { getOffsetRelatedDatesFromRelatedDatesData } from '../../utils/get-offset-related-dates-from-related-dates-data';

export function useDefaultDueDate(
  taskType: TaskType
): [boolean, ChecklistTemplateItemEditFormDueDate] {
  const {
    data: relatedDatesData,
    isLoading
  } = useChecklistItemRelatedDatesQuery(taskType);

  const relatedDateOptions = relatedDatesData?.data?.related_dates;

  const offsetRelatedDates =
    relatedDatesData &&
    getOffsetRelatedDatesFromRelatedDatesData(relatedDatesData.data);

  return [
    isLoading,
    {
      due_date_type: 'none',
      related_date: relatedDateOptions?.[0] ?? null,
      offset_related_date: {
        count: 1,
        unit: {
          id: 'days',
          label: 'Day(s)'
        },
        offset: offsetRelatedDates?.[0] ?? null
      }
    }
  ];
}
