import React from 'react';
import { merge } from 'lodash';

import { useModelActions } from '@rexlabs/model-generator';

import { useGetMapContactFormToData } from 'src/modules/contacts/mappers/use-get-map-contact-form-to-data';
import { contactsModel } from 'src/modules/contacts/models/contacts';

import { BlockConfig } from 'view/components/record-screen/types';

import { MessageIssue } from '../../common/types/message-issue';
import { MessageIssueDisclosureField } from './message-issue-disclosure-field';

export function ContactMessageIssueDisclosureField({
  issue,
  fieldKey,
  setFieldValue,
  block,
  updateText
}: {
  fieldKey: string;
  issue: MessageIssue;
  setFieldValue?: (key, value) => void;
  block: BlockConfig;
  updateText: React.ReactNode;
}) {
  const { updateItem: updateContact } = useModelActions(contactsModel);
  const mapContactFormToData = useGetMapContactFormToData();

  const onSubmit = async (updatedValues) => {
    // we need to do this merge because of how the mapper is orchestrated to pull from the first arg.
    const contact = merge({}, issue.contact, updatedValues);

    const mappedValues = await mapContactFormToData(contact, updatedValues);

    await updateContact({
      id: issue.contact.id,
      data: mappedValues
    });
  };

  return (
    <MessageIssueDisclosureField
      block={block}
      issueRelatesTo={issue.contact}
      issueRelatesToType={'contact'}
      issueText={issue.issue.label}
      fieldKey={fieldKey}
      updateText={updateText}
      setValue={(value) => {
        setFieldValue?.(fieldKey, value);
      }}
      onSubmit={onSubmit}
    />
  );
}
