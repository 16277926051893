import { useModelActions } from '@rexlabs/model-generator';
import { useErrorDialog } from '@rexlabs/dialog';

import { useToast } from 'view/components/@luna/notifications/toast';

import { deferredFeeLineItemModel } from '../models/financials-deferred-fee-line-items';
import {
  ManualAgencyCommissionLineItemCreateRequest,
  ManualSupplierCommissionLineItemCreateRequest
} from '../types/ManualDeferredFeeLineItemCreateRequest';

type CreateManualDeferredFeeEntryActionProps = {
  payload:
    | ManualSupplierCommissionLineItemCreateRequest
    | ManualAgencyCommissionLineItemCreateRequest;
};

export function useDeferredFeeSubmitHandler() {
  const { addToast } = useToast();

  const { createItem, refreshLists } = useModelActions(
    deferredFeeLineItemModel
  );

  const { open: openErrorDialog } = useErrorDialog();
  return async ({ payload }: CreateManualDeferredFeeEntryActionProps) => {
    try {
      const { data: journalEntry } = await createItem({
        data: payload
      });

      addToast({
        description: 'Entry created successfully',
        type: 'success'
      });

      refreshLists();

      return journalEntry;
    } catch (error) {
      openErrorDialog(error);
    }
  };
}
