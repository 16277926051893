import { valueListFactory } from 'src/lib/testing/factories/value-list-factory';
import { FolioCommissionTemplateTier } from '../types/FolioCommissionTemplate';
import { FolioCommissionTemplateTierFormData } from '../types/FolioCommissionTemplateFormData';

export function mapTierFormDataToRequest(
  tiers: FolioCommissionTemplateTierFormData[]
): Array<FolioCommissionTemplateTier> {
  return tiers.map((tier, index) => {
    const commissionAmount = tier.amount;
    const commissionAmountTypeId = commissionAmount?.type?.id;
    const range = tier.range;
    const isLastTier = tiers.length === index + 1;

    const from = range?.from;
    const to = range?.to;
    const amount = commissionAmount?.value;

    return {
      from: from,
      to: !isLastTier ? to : null,
      amount: amount,
      type: valueListFactory(
        commissionAmountTypeId === 'fixed_amount' ? 'flat_fee' : 'percentage'
      )
    };
  });
}
