import { BulkAction } from '@rexlabs/table/lib/types/bulk-actions/simple';
import { useModelActions } from '@rexlabs/model-generator';
import { useDialog, useErrorDialog } from '@rexlabs/dialog';

import { pluralize } from 'utils/formatters';

import EmailIcon from 'view/components/icons/email';

import { MessageDetailsMissingDialog } from 'src/modules/communications/messages/dialogs/message-details-missing-dialog';
import { Message } from 'src/modules/communications/messages/types/Message';
import { SendMessagesConfirmationDialog } from 'src/modules/communications/messages/dialogs/send-messages-confirmation-dialog';
import { useEstimateMessagesAndSend } from 'src/modules/communications/messages/hooks/use-estimate-messages-and-send';

import { messagesModel } from '../../models/messages-model';

/**
 * An action that orchestrates validating and estimating (sms credits) for a number of messages before sending them.
 * Note currently this applies only to emails and SMS messages, not letters.
 * Current flow:
 * If validating and estimating is successful, we open the send confirmation dialog.
 * Otherwise, if validation fails, we open a dialog that allows the user to either send the valid messages or fix the issues.
 * If there are sms messages, and they don't have enough credits, then we let the user send any emails.
 * (Finally if estimation fails and there aren't any emails then they can't send any messages at this point.)
 */
export function useGetBulkSendMessageAction() {
  const { open: openMissingDetailsDialog } = useDialog(
    MessageDetailsMissingDialog
  );
  const { open: openSendConfirmation } = useDialog(
    SendMessagesConfirmationDialog
  );
  const estimateAndSend = useEstimateMessagesAndSend();
  const { validateItems } = useModelActions(messagesModel);
  const { open: openErrorDialog } = useErrorDialog();
  return (messages: Message[]): BulkAction => {
    return {
      label: `Send ${pluralize('messages', messages.length, true)}`,
      type: 'secondary',
      handleAction: async () => {
        try {
          const issues = await validateItems({
            messageIds: messages.map((m) => m.id),
            args: {
              include: [
                'contact',
                'message',
                'message.context',
                'message.inline_attachments',
                'missing_compliance_type'
              ].join(',')
            }
          });

          if (issues.data.length) {
            return openMissingDetailsDialog({ issues: issues.data, messages });
          }

          return estimateAndSend(messages, (messages) =>
            openSendConfirmation({ messages })
          );
        } catch (e) {
          openErrorDialog(e);
        }
      },
      Icon: EmailIcon
    };
  };
}
