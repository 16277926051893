import React from 'react';

import { NormalisedItem, Select, SelectProps, useItems } from '@rexlabs/select';
import { RecordType } from 'data/models/types';
import {
  getSearchResults,
  SearchResultItem
} from 'utils/api/get-search-results';

export const normaliser = (item): NormalisedItem => {
  return {
    id: item.id,
    label: item.label,
    type: item.type.id,
    heading: item.type.label
  };
};

export type ManagedBySelectProps = SelectProps<SearchResultItem> & {
  objectTypes?: RecordType[];
};

export const defaultObjectTypes: RecordType[] = ['contact', 'user_group'];

export function ManagedBySelect(props: ManagedBySelectProps) {
  const { value, objectTypes = defaultObjectTypes } = props;

  const existingItems = React.useMemo(() => (value ? [value] : []), [value]);

  const getSuggestedItems = React.useCallback(() => existingItems, [
    existingItems
  ]);

  const getItems = React.useCallback(
    async (term) => {
      const searchResults = await getSearchResults(term, {
        objectTypes,
        filters: [
          {
            field: 'roles',
            op: 'in',
            value: ['user']
          }
        ]
      });

      return searchResults;
    },
    [objectTypes]
  );

  const { getSelectProps } = useItems({
    getItems,
    getSuggestedItems
  });

  return (
    // @ts-ignore
    <Select
      {...props}
      {...getSelectProps()}
      value={value}
      normaliser={normaliser}
    />
  );
}
