import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';
import { PrepaymentBucket } from 'src/modules/prepayments/types/prepayment-bucket';
import { Column, Grid } from 'view/components/@luna/form/grid';
import { Checkbox } from '@rexlabs/checkbox';
import { Field } from '@rexlabs/form';
import InfoCircleIcon from 'view/components/icons/info';
import { InfoBanner } from '@rexlabs/notifications';
import { Value } from 'view/components/values';
import { TextInput } from '@rexlabs/text-input';

export const rentPrepaymentBlock: BlockConfig<
  PrepaymentBucket,
  any,
  { auto_allocate_funds: boolean }
> = {
  id: 'rent-prepayment-details',
  Edit: ({ data }) => {
    return (
      <Grid columns={2}>
        <Value label={'Name'} value={data?.name} />
        <Field name='reference_code' label='Reference' Input={TextInput} />
        <Column width={2}>
          <Value label='Description' value={data?.description} />
        </Column>
        <Column width={2}>
          <Field
            name='auto_allocate_funds'
            optional={false}
            Input={Checkbox}
            inputProps={{
              label:
                'Automatically allocate funds to invoices using the specified chart of account(s)'
            }}
          />
        </Column>
        <Column width={2}>
          <InfoBanner
            data-testid='rent-info-banner'
            Icon={InfoCircleIcon}
            description={
              'Funds will automatically be applied to rent as it becomes due as defined by the rent schedule.'
            }
          />
        </Column>
      </Grid>
    );
  }
};
