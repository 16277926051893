import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'routes/app';
import { createTableRouteConfig } from 'src/modules/common/utils/create-table-route-config';
import { FolioCommissionTemplate } from '../../types/FolioCommissionTemplate';

export function useGetAppliedToSupplierCommissionTemplateAction() {
  return (item: FolioCommissionTemplate): SingleActionDeclaration => {
    return {
      label: 'Applied to',
      handleAction: async () => {
        push(
          ROUTES.CONTACTS_LIST,
          createTableRouteConfig(
            [
              {
                field: 'commission_template_id',
                op: 'eq',
                value: {
                  // @ts-ignore - can be an object
                  label: item.name,
                  value: item.id
                }
              }
            ],
            'all'
          )
        );
      }
    };
  };
}
