import React, { useCallback, useEffect } from 'react';
import { api } from 'utils/api/api-client';
import { useDialog } from '@rexlabs/dialog';
import { RexApiResponse } from '@rexlabs/api-client';
import {
  createErrorFromResponse,
  PmApiError
} from 'utils/api/create-error-from-response';
import { NoPermissionToPerformActionDialog } from 'view/components/dialogs/no-permission-to-perform-action-dialog';

type Interceptor = Parameters<typeof api.addResponseInterceptor>[0];

export function isPrivilegeErrorResponse(response: RexApiResponse) {
  return response.status === 403;
}

function addPrivilegeInfoToError(error: PmApiError, response: RexApiResponse) {
  error.requiredPrivileges = response.data?.required_privileges || [];

  const unmetPrivilegesString = error.requiredPrivileges?.length
    ? `: ${error.requiredPrivileges.join(', ')}`
    : '';

  error.message = `You are not authorized to perform this action due to unmet privileges${unmetPrivilegesString}. Please contact your administrator for assistance.`;

  return error;
}

export function PrivilegeInterceptor() {
  const errorDialog = useDialog(NoPermissionToPerformActionDialog);

  const interceptor: Interceptor = useCallback<Interceptor>(
    ((response) => {
      if (!isPrivilegeErrorResponse(response)) {
        return response;
      }

      return new Promise<void>((resolve, reject) => {
        const initialError = createErrorFromResponse(response);
        const privilegeError = addPrivilegeInfoToError(initialError, response);

        if (response.config.method === 'get') {
          reject(privilegeError);
          return;
        }

        errorDialog.open({
          error: privilegeError
        });
      });
    }) as Interceptor,
    [errorDialog]
  );
  useEffect(() => {
    api.addResponseInterceptor(interceptor);
    return () => {
      // @ts-ignore
      api.responseInterceptors = api.responseInterceptors.filter(
        (i) => interceptor !== i
      );
    };
  }, [interceptor]);

  return <></>;
}
