import { reduce } from 'lodash';

import dayjs from 'dayjs';

import { SearchResultItem } from 'utils/api/get-search-results';
import { parseUploadedFileMeta } from 'utils/files';

import { PropertyTenancy } from 'src/modules/property-tenancies/types/property-tenancy-types';
import { getSearchResultItemFromObjectAndModel } from 'src/modules/common/utils/search-result-items/get-search-result-items-from-object-and-model';
import { propertyTenancyModel } from 'src/modules/property-tenancies/models/property-tenancy-model';
import { valueListFactory } from 'src/lib/testing/factories/value-list-factory';

import { mapTaskToRoutineAreasFormField } from 'src/modules/tasks/inspections/mappers/map-task-to-routine-areas-form-field';
import { InspectionFormData } from '../types/InspectionFormData';
import { InspectionTask } from '../types/InspectionTask';
import { getSearchResultItemFromManagedBy } from '../../common/utils/get-search-result-item-from-managed-by';

const propertyTenancyToSearchResultItem = (
  propertyTenancy: PropertyTenancy
): SearchResultItem<PropertyTenancy> => {
  const base = getSearchResultItemFromObjectAndModel(
    propertyTenancy,
    propertyTenancyModel
  ) as SearchResultItem<PropertyTenancy>;

  return {
    ...base,
    label: propertyTenancy.tenancy?.display_name ?? base.label
  };
};

export function mapInspectionTaskToInspectionFormData(
  task: InspectionTask
): InspectionFormData {
  return {
    summary: task.summary || null,
    description: task.description || null,
    due_date: task.due_date ? task.due_date : null,
    follow_up_date: task.follow_up_date ? task.follow_up_date : null,
    managed_by: getSearchResultItemFromManagedBy(task.managed_by),
    priority: task.priority || null,
    property: task.property || null,
    status: task?.details?.status || null,
    property_tenancy: task?.details?.property_tenancy
      ? propertyTenancyToSearchResultItem(task?.details?.property_tenancy)
      : null,
    inspection_type: task?.details?.type?.id,
    inspection_date: task?.details?.scheduled_at
      ? dayjs(task?.details?.scheduled_at).tz().format('YYYY-MM-DD')
      : null,
    start_time: task?.details?.scheduled_at
      ? dayjs(task?.details?.scheduled_at).tz().format('HH:mm:ss')
      : null,
    duration: task?.details?.scheduled_duration
      ? valueListFactory(task?.details?.scheduled_duration?.toString())
      : null,
    rooms: reduce(
      task?.details?.rooms?.data || [],
      (acc, room) => {
        acc[room.id] = {
          comments: room.notes,
          condition: room.room_condition,
          images: parseUploadedFileMeta(room.images.data || [])
        };
        return acc;
      },
      {}
    ),
    routine_areas: mapTaskToRoutineAreasFormField(task)
  };
}
