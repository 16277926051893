import { Generator } from 'data/models/generator';
import { auditableConfig } from 'src/modules/common/auditable/auditable-config';
import { PortfolioRole } from '../types/PortfolioRole';

const config = {
  entities: {
    related: {
      ...auditableConfig
    }
  }
};

export const portfolioRolesModel = new Generator<PortfolioRole>(
  'portfolio-roles',
  config
).createEntityModel();
