import { Contact } from 'src/modules/contacts/types/contact-types';
import { UserGroup } from 'src/modules/user-groups/types/UserGroup';
import { SearchResultItem } from 'utils/api/get-search-results';
import { slugToSentenceCase } from 'src/modules/communications/messages/utils/slug-to-sentence-case';
import { PortfolioRole } from 'src/modules/portfolio-roles/types/PortfolioRole';
import { getLabelForManagedBy } from './get-label-for-managed-by';

export const getSearchResultItemFromManagedBy = (
  managedBy?: Contact | UserGroup | PortfolioRole | null
): null | SearchResultItem<Contact | UserGroup | PortfolioRole> => {
  if (!managedBy) {
    return null;
  }
  const label = getLabelForManagedBy(managedBy);
  return {
    id: managedBy.id,
    label,
    type: {
      id: managedBy.__record_type,
      label: slugToSentenceCase(managedBy.__record_type)
    },
    record: managedBy
  };
};
