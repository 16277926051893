import { BlockConfig } from 'view/components/record-screen/types';
import React from 'react';
import { TrustJournalEntryLineItemTable } from '../components/trust-journal-entry-line-item-table';

export const trustJournalEntryLineItemBlock: BlockConfig = {
  id: 'trust-journal-entry-lime-item-table-block',
  title: 'Line items',
  View: ({ data }) => {
    return (
      <TrustJournalEntryLineItemTable
        items={data.ledger_line_items.items}
        isLoading={false}
      />
    );
  }
};
