import React from 'react';
import { CustomFieldViewField } from 'src/modules/custom-fields/components/custom-field-view-field';
import { Grid } from 'view/components/@luna/form/grid';
import { BlockConfig } from 'view/components/record-screen/types';
import { CustomFieldEditInput } from 'src/modules/custom-fields/components/custom-field-edit-input';
import { CustomTask } from '../types/CustomTask';
import { CustomTaskFormData } from '../types/CustomTaskFormData';

export const customTaskCustomFieldsBlock: BlockConfig<
  CustomTask,
  unknown,
  CustomTaskFormData
> = {
  id: `custom-fields`,
  title: 'Custom fields',
  View: ({ data }) => {
    return (
      <Grid columns={1}>
        {data?.custom_fields?.data
          .filter((field) => field.id !== null)
          .map((field) => (
            <CustomFieldViewField
              key={field.id}
              field={field}
              value={data.custom_field_values?.[field.id!]}
            />
          ))}
      </Grid>
    );
  },
  Edit: ({ data }) => {
    return (
      <Grid columns={1}>
        {data?.custom_fields?.data?.map((field) => (
          <CustomFieldEditInput key={field.id} field={field} />
        ))}
      </Grid>
    );
  }
};
