import React, { useMemo } from 'react';
import {
  EntityModel,
  query,
  Query,
  useEntityQuery
} from '@rexlabs/model-generator';
import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { RecordScreen } from 'view/components/record-screen';
import { useRecordScreenSubmitHandler } from 'view/hooks/use-record-screen-submit-handler';
import invariant from 'invariant';
import { tasksModel } from '../../common/models/tasks-model';
import { customTaskQueryIncludeFragment } from '../data/custom-task-query-include-fragment';
import { TaskRightBarOverride } from '../../common/components/right-bar-override';
import { content } from '../data/custom-task-content';
import { mapCustomTaskToCustomTaskFormData } from '../mappers/map-custom-task-to-custom-task-form-data';
import { mapCustomTaskFormDataToTaskCreateRequest } from '../mappers/map-custom-task-form-data-to-task-create-request';
import { CustomTask } from '../types/CustomTask';
import { CustomTaskFormData } from '../types/CustomTaskFormData';
import { CustomTaskTitleBlock } from '../components/custom-task-title-block';

const getCustomTaskQuery = (id: string) => query`{
  ${tasksModel} (id: ${id}) {
    ${customTaskQueryIncludeFragment}
  }
}`;

interface CustomTaskDetailsProps {
  customTaskId?: string;
}

export function CustomTaskDetails({ customTaskId }: CustomTaskDetailsProps) {
  const query = useMemo(() => getCustomTaskQuery(customTaskId!), [
    customTaskId
  ]);

  const { status, data, actions } = useEntityQuery<
    Query<EntityModel<CustomTask>>
  >(query, {
    throwOnError: false
  });

  const breadcrumbs = [
    {
      type: data?.details?.custom_task_template?.id ?? BREADCRUMBS.CUSTOM_TASKS
    }
  ];

  const handleSubmit = useRecordScreenSubmitHandler(
    async ({ changedValues }) => {
      invariant(
        data?.details?.custom_task_template,
        'Custom task template is required'
      );

      const dataToSubmit = await mapCustomTaskFormDataToTaskCreateRequest(
        changedValues,
        data.details.custom_task_template
      );
      await actions.updateItem({
        id: customTaskId,
        data: dataToSubmit
      });
    }
  );

  const titleBlock = <CustomTaskTitleBlock customTask={data} />;

  const initialValues: Partial<CustomTaskFormData> | undefined = data
    ? mapCustomTaskToCustomTaskFormData(data!)
    : undefined;

  return (
    <RecordScreen
      privilege={'tasks.custom-tasks.read'}
      isLoading={status === 'loading'}
      data={data}
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      content={content}
      titleBlock={titleBlock}
      breadcrumbs={breadcrumbs}
      RightBarOverride={TaskRightBarOverride}
    />
  );
}
