import React from 'react';
import { Helmet } from 'react-helmet';

import { Breadcrumbs } from 'view/components/@luna/breadcrumbs';
import { ListTitleCard, ListTitleCardProps } from 'view/components/cards/title';

import { padding, StyleSheet, useStyles } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import {
  Privilege,
  usePrivileges
} from 'src/modules/authorization/roles/hooks/use-privileges';
import { ListLayout } from '../layouts/list-layout/list-layout';
import { CardErrorBoundary } from '../record-screen/cards/error-boundary';
import { BreadcrumbsProps } from '../@luna/breadcrumbs/breadcrumbs';
import { ActionsProps } from '../@luna/title-block/actions';
import { UnauthorizedEmptyCard } from '../record-screen/cards/unauthorized-empty-card';

interface ListScreenComponentProps
  extends Omit<ListTitleCardProps, 'children'>,
    Pick<ActionsProps, 'amountDisplayedAsButtons'> {
  breadcrumbs?: BreadcrumbsProps['breadcrumbs'];
  children: React.ReactNode;
  privilege?: Privilege;
  titleRightContent?: React.ReactNode;
}

const defaultStyles = StyleSheet({
  breadcrumbs: {
    ...padding.styles({
      bottom: 'l'
    })
  }
});

export function ListScreen({
  children,
  breadcrumbs = [],
  title,
  privilege,
  titleRightContent,
  ...headerProps
}: ListScreenComponentProps) {
  const s = useStyles(defaultStyles);
  const { hasPrivilege } = usePrivileges();

  if (privilege && !hasPrivilege(privilege)) {
    return <UnauthorizedEmptyCard requiredPrivilege={privilege} />;
  }

  return (
    <>
      <Helmet title={title} />
      <ListLayout
        header={
          <>
            <Box {...s('breadcrumbs')}>
              <Breadcrumbs breadcrumbs={breadcrumbs} />
            </Box>
            <ListTitleCard
              title={title}
              rightContent={titleRightContent}
              {...headerProps}
            />
          </>
        }
      >
        <CardErrorBoundary>{children}</CardErrorBoundary>
      </ListLayout>
    </>
  );
}
