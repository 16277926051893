import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { Field } from '@rexlabs/form';
import { CentAmountInput } from 'view/components/inputs/cent-amount';
import { TextInput } from '@rexlabs/text-input';

export const securityDepositPaymentBlock: BlockConfig = {
  id: 'security-deposit-payment',
  validate: {
    definitions: {
      amount: {
        rules: 'required'
      }
    }
  },
  Edit: () => (
    <Grid columns={2}>
      <Field name='amount' label='Amount' Input={CentAmountInput} />

      <Field
        name='description'
        label='Description'
        Input={TextInput}
        description='Leave blank and we’ll create this for you'
      />
    </Grid>
  )
};
