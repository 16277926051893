import { LinkButton } from '@rexlabs/button';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import React from 'react';
import SubtractIcon from 'view/components/icons/subtract';

export const styles = StyleSheet({
  actionLink: {
    color: ({ token }) => token('color.textStyle.primary.idle.default')
  }
});

export const DeSelectMatchActionCell = (props) => {
  const s = useStyles(styles);

  const { setSelectedMatches, setAvailableMatches, item } = props.value;

  const handleClick = () => {
    setSelectedMatches((previousSelectedMatches) =>
      previousSelectedMatches.filter(
        (selectedMatch) =>
          !(
            selectedMatch.id === item.id &&
            selectedMatch.__record_type === item.__record_type
          )
      )
    );
    setAvailableMatches((previousAvailableMatches) => {
      return {
        singleReceipts:
          item.__record_type === 'trust_journal_entry'
            ? previousAvailableMatches.singleReceipts.concat(item)
            : previousAvailableMatches.singleReceipts,
        paymentGatewayFiles:
          item.__record_type === 'bank_statement_transaction_import'
            ? previousAvailableMatches.paymentGatewayFiles.concat(item)
            : previousAvailableMatches.paymentGatewayFiles
      };
    });
  };

  return (
    <LinkButton
      IconLeft={SubtractIcon}
      data-testId={'deselect-match-action-button'}
      {...s('actionLink')}
      onClick={handleClick}
    />
  );
};
