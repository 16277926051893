import { PortfolioMember } from '../../models/portfolio-members-model';
import { Portfolio } from '../../models/portfolios-model';
import { useGetRemovePortfolioMemberAction } from '../action-declarations/use-get-remove-portfolio-member-action';
import { useGetSetPortfolioMemberAsPrimaryAction } from '../action-declarations/use-get-set-portfolio-member-as-primary-action';

export function useGetPortfolioMemberActions({
  portfolio
}: {
  portfolio: Portfolio;
}) {
  const getRemovePortfolioMemberAction = useGetRemovePortfolioMemberAction({
    portfolio
  });
  const getSetPortfolioMemberAsPrimaryAction = useGetSetPortfolioMemberAsPrimaryAction(
    { portfolioId: portfolio.id }
  );

  return (portfolioMember?: PortfolioMember) => {
    if (!portfolioMember) return [];

    return [
      ...(!portfolioMember.is_primary &&
      portfolioMember.portfolio_role?.system_purpose?.id === 'property_manager'
        ? [getSetPortfolioMemberAsPrimaryAction(portfolioMember)]
        : []),
      getRemovePortfolioMemberAction(portfolioMember)
    ];
  };
}
