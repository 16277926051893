import React from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';

import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';

import { ReceiptPaymentMethodSelect } from 'view/components/inputs/selects/receipt-payment-method';
import { Value } from 'view/components/values';

type IncomingPaymentType =
  | 'cash'
  | 'cheque'
  | 'bank_cheque'
  | 'card'
  | 'eft'
  | 'money_order';

export const receiptFundsPaymentFormValidationRules = {
  'payment_information.method': { rules: 'required' },
  'payment_information.drawer': {
    rules:
      'required_if:payment_information.method.id,cheque|required_if:payment_information.method.id,bank_cheque'
  },
  'payment_information.reference': {
    rules:
      'required_if:payment_information.method.id,cheque|required_if:payment_information.method.id,bank_cheque|required_if:payment_information.method.id,money_order'
  },
  'payment_information.issuer': {
    rules:
      'required_if:payment_information.method.id,cheque|required_if:payment_information.method.id,bank_cheque'
  },
  'payment_information.branch_code': {
    rules:
      'required_if:payment_information.method.id,cheque|required_if:payment_information.method.id,bank_cheque'
  }
};

export function ReceiptFundsPaymentForm({ values, blockProps }) {
  const { t } = useTranslation();

  const paymentId = get(values, 'payment_information.method.id') as
    | IncomingPaymentType
    | undefined;

  const isReferenceNeeded =
    paymentId === 'card' ||
    paymentId === 'cash' ||
    paymentId === 'eft' ||
    paymentId === 'money_order';

  const isDrawerNeeded = paymentId === 'bank_cheque' || paymentId === 'cheque';
  const isCheckNumberNeeded =
    paymentId === 'bank_cheque' || paymentId === 'cheque';
  const isBankNeeded = paymentId === 'bank_cheque' || paymentId === 'cheque';
  const isBranchCodeNeeded =
    paymentId === 'bank_cheque' || paymentId === 'cheque';
  const isMoneyOrderNumberNeeded = paymentId === 'money_order';

  return (
    <>
      <Field
        name='payment_information.method'
        label='Payment method'
        Input={ReceiptPaymentMethodSelect}
        optional={false}
      />
      {isReferenceNeeded &&
        (blockProps?.renderBatchReceiptingDialog ? (
          <Value
            value={values?.payment_information?.reference}
            label='Reference'
          />
        ) : (
          <Field
            name='payment_information.reference'
            label='Reference'
            optional={paymentId === 'money_order' ? false : true}
            Input={TextInput}
          />
        ))}
      {isDrawerNeeded && (
        <Field
          name='payment_information.drawer'
          label='Drawer'
          optional={isDrawerNeeded ? false : true}
          Input={TextInput}
        />
      )}
      {isCheckNumberNeeded && (
        <Field
          name='payment_information.reference'
          label='Cheque number'
          optional={isCheckNumberNeeded ? false : true}
          Input={TextInput}
        />
      )}
      {isBankNeeded && (
        <Field
          name='payment_information.issuer'
          label='Bank'
          optional={isBankNeeded ? false : true}
          Input={TextInput}
        />
      )}
      {isBranchCodeNeeded && (
        <Field
          name='payment_information.branch_code'
          label={t('bank-accounts.branch-code.label.singular') as string}
          optional={isBranchCodeNeeded ? false : true}
          Input={TextInput}
        />
      )}
      {isMoneyOrderNumberNeeded && (
        <Field
          name='payment_information.issuer'
          label='Money order number'
          Input={TextInput}
        />
      )}
    </>
  );
}
