import { useDialog } from '@rexlabs/dialog';
import React from 'react';
import { SearchResultItem } from 'utils/api/get-search-results';
import { BlockConfig } from 'view/components/record-screen/types';
import { ReactFormsProps } from '@rexlabs/form';
import { InvoiceDetailsForm } from '../components/invoice-details-form';
import { ReimbursementWarningDialog } from '../dialogs/reimbursement-warning-dialog';
import { InvoiceBlockProps } from '../types/invoice-block';

type ValidationRules = ReactFormsProps<any, any>['validate'];

const defaultValidationRules = {
  definitions: {
    specific_disbursement_payment_method: {
      rules: 'required'
    },
    'payable_to.object': { name: 'payable to', rules: 'required' },
    'payable_by.object': { name: 'payable by', rules: 'required' },
    description: { rules: 'required' },
    due_date: { rules: 'required' },
    is_tax_included: { rules: 'required' },
    bank_account: { name: 'bank account', rules: 'required' }
  }
};

function getInvoiceDetailsBlock(
  validate: ValidationRules = defaultValidationRules
): BlockConfig<any, InvoiceBlockProps> {
  return {
    id: 'invoice-details',
    validate,
    Edit: ({ blockProps, forms, setFieldValue }) => {
      const isFirstEditRef = React.useRef(true);

      const reimbursementWarningDialog = useDialog(ReimbursementWarningDialog);

      const handleDescriptionBlur = React.useCallback((value) => {
        const lineItemsForm = forms?.['line-items'];
        const reimbursementDetailsForm = forms?.['reimbursement-details'];

        if (reimbursementDetailsForm) {
          reimbursementDetailsForm.setFieldValue(
            'reimbursed_description',
            `Reimbursement for ${value ?? ''}`
          );
        }

        if (
          !lineItemsForm ||
          !isFirstEditRef.current ||
          blockProps?.dialogType === 'draft'
        )
          return;

        const { setFieldValue } = lineItemsForm;

        setFieldValue('line_items.0.description', value);
        isFirstEditRef.current = false;
      }, []);

      const warnReimbursementChanges = ({
        changedField,
        fieldName,
        previousItem,
        callback
      }: {
        changedField: string;
        fieldName: string;
        previousItem?: SearchResultItem;
        callback?: () => void;
      }) => {
        const hasReimbursement =
          forms?.['reimbursement-details']?.values?.reimbursed_by?.object !=
          null;

        if (!hasReimbursement) {
          callback?.();

          return;
        }

        reimbursementWarningDialog.open({
          changedField,
          onSubmit: () => {
            forms?.['reimbursement-details']?.resetForm();
            forms?.['reimbursement-line-items']?.resetForm();

            forms?.['reimbursement-line-items']?.setFieldValue(
              'reimbursed_line_items',
              []
            );

            callback?.();
          },
          onCancel: () => {
            setFieldValue?.(fieldName, previousItem);
          }
        });
      };

      const handlePayableToBlur = (event, previousItem) => {
        warnReimbursementChanges({
          changedField: 'Payable to',
          fieldName: 'payable_to.object',
          previousItem
        });

        const lineItemsForm = forms?.['line-items'];

        if (!lineItemsForm) return;

        const { setFieldValue, values } = lineItemsForm;
        const hasLineItems = values?.line_items?.length > 0;

        if (hasLineItems) return;

        setFieldValue!('line_items', [{}]);
      };

      const handlePayableByBlur = (event, previousItem) => {
        warnReimbursementChanges({
          changedField: 'Payable by',
          fieldName: 'payable_by.object',
          previousItem,
          callback() {
            const reimbursementDetailsForm = forms?.['reimbursement-details'];

            if (reimbursementDetailsForm) {
              reimbursementDetailsForm.setFieldValue(
                'reimbursed_to.object',
                event.target.value
              );
            }
          }
        });
      };

      const handleBillReferenceBlur = (event) => {
        const reimbursementDetailsForm = forms?.['reimbursement-details'];
        if (!reimbursementDetailsForm) return;

        reimbursementDetailsForm.setFieldValue(
          'reimbursed_bill_reference',
          event.target.value
        );
      };

      return (
        <div
          style={{
            display: blockProps?.activeTab === 'invoice' ? 'block' : 'none'
          }}
        >
          <InvoiceDetailsForm
            columns={2}
            hideInvoiceMoreOptions={blockProps?.dialogType === 'draft'}
            suggestions={blockProps?.suggestions}
            onDescriptionBlur={handleDescriptionBlur}
            onPayableToBlur={handlePayableToBlur}
            onPayableByBlur={handlePayableByBlur}
            onBillReferenceBlur={handleBillReferenceBlur}
          />
        </div>
      );
    }
  };
}

export const invoiceDetailsBlock = getInvoiceDetailsBlock();

export const useInvoiceDetailsBlock = (validate?: ValidationRules) =>
  React.useMemo(() => getInvoiceDetailsBlock(validate), []);
