import { LeaseReviewFormData } from '../types/LeaseReviewFormData';
import { LeaseReview } from '../types/LeaseReview';
import { getSearchResultItemFromManagedBy } from '../../common/utils/get-search-result-item-from-managed-by';

const getDetailItems = (
  details: LeaseReview['details']
): LeaseReviewFormData['details'] => {
  return {
    property_tenancy: details?.property_tenancy || null,
    status: details?.status
  };
};

export function mapLeaseReviewTaskToLeaseReviewFormData(
  task: LeaseReview
): LeaseReviewFormData {
  const { details, property, managed_by, ...taskData } = task;

  return {
    ...taskData,
    property: property || null,
    managed_by: getSearchResultItemFromManagedBy(managed_by),
    details: {
      ...getDetailItems(details)
    }
  };
}
