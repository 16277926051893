import React from 'react';
import { CurrencyValue } from 'view/components/values/currency';
import { Value } from 'view/components/values';
import {
  FLAT_FEE,
  FolioCommissionTemplateStructure,
  PERCENTAGE
} from '../types/FolioCommissionTemplate';

const COMMISSION_VALUES = {
  [FLAT_FEE]: CurrencyValue,
  [PERCENTAGE]: ({ value, ...rest }) => <Value {...rest} value={`${value}%`} />
};

export interface CommissionValueProps {
  structureType: FolioCommissionTemplateStructure;
  value: number;
}

export function CommissionValue({
  structureType,
  value
}: CommissionValueProps) {
  const DisplayValue = COMMISSION_VALUES[structureType];

  return <DisplayValue label='Commission amount' value={value} />;
}
