import React, { useMemo } from 'react';
import { useWhereabouts } from '@rexlabs/whereabouts';

import { BlockConfig } from 'view/components/record-screen/types';

import { RecordTaskTabbedTable } from 'src/modules/tasks/common/components/record-task-tabbed-table';
import { useTaskColumnsForSupplier } from 'src/modules/tasks/common/hooks/use-task-list-columns';
import { ActionButtons } from 'view/components/action-buttons';
import { useDialog } from '@rexlabs/dialog';
import { CreateTaskDialog } from 'src/modules/tasks/common/dialogs/create-task-dialog';
import { toQuri } from '@rexlabs/quri';
import { Contact } from 'src/modules/contacts/types/contact-types';

export const getFilterBySupplierId = (supplierId?: string) => {
  return supplierId
    ? [
        {
          field: 'supplier',
          op: 'or',
          logicalOperator: 'or' as const,
          value: [
            {
              field: 'task_quote_supplier_id',
              op: 'eq',
              value: supplierId
            },
            {
              field: 'task_work_order_work_done_by_id',
              op: 'eq',
              value: supplierId
            },
            {
              field: 'task_supplier_compliance_supplier_id',
              op: 'eq',
              value: supplierId
            }
          ]
        }
      ]
    : [];
};

export const assignedTasksBlock: BlockConfig<Contact> = {
  id: 'assigned-tasks',
  title: 'Assigned tasks',

  View: ({ data }) => {
    const whereabouts = useWhereabouts();
    const tab = whereabouts.hashQuery?.tab;

    const filter = useMemo(() => getFilterBySupplierId(data?.id), [data?.id]);
    const columns = useTaskColumnsForSupplier();

    return (
      <RecordTaskTabbedTable
        key={tab}
        columns={columns}
        filters={filter}
        normaliseGlobalFilter={normaliseGlobalFilter}
      />
    );
  },
  Actions: ({ data }) => {
    const { open: openCreateTaskDialog } = useDialog(CreateTaskDialog);

    if (!data) {
      return null;
    }

    return (
      <ActionButtons
        actions={[
          {
            label: 'Create task',
            onClick: () => {
              openCreateTaskDialog({});
            }
          }
        ]}
      />
    );
  }
};

function normaliseGlobalFilter(filters) {
  const flattenedFilter = Object.values(filters).flatMap(
    (filterArray: any) => filterArray[0]
  );

  return toQuri(flattenedFilter);
}
