import { ValueListValue } from 'data/models/types';
import { lowerCase } from 'lodash';
import { SearchResultItem } from 'utils/api/get-search-results';
import { ChecklistTemplateItem } from '../types/ChecklistTemplate';
import { ChecklistTemplateItemEditForm } from '../types/ChecklistTemplatesForm';
import { Assignee } from '../../common/components/checklist-assignee-select';

function getOffset({
  offsetDirection,
  relatedDate
}: {
  offsetDirection: ValueListValue<string>;
  relatedDate: ValueListValue<string>;
}): ValueListValue<string> {
  return {
    id: `${offsetDirection.id}_${relatedDate.id}`,
    label: `${offsetDirection.label} ${lowerCase(relatedDate.label)}`
  };
}

/**
 * Converts an checklist template item to an edit checklist template item form data object.
 */
export function mapChecklistTemplateItemToChecklistTemplateItemFormData({
  checklistTemplateItem,
  defaultRelatedDate,
  defaultRelatedDateOffset
}: {
  checklistTemplateItem: Partial<ChecklistTemplateItem>;
  defaultRelatedDate: ValueListValue<string> | null;
  defaultRelatedDateOffset: ValueListValue<string> | null;
}): Partial<ChecklistTemplateItemEditForm> {
  const dueDateData = checklistTemplateItem.due_date_data;
  const offsetDirection = dueDateData?.offset_direction;
  const relatedDate = dueDateData?.related_date;
  const itemId = checklistTemplateItem.id;
  return {
    ...(itemId ? { id: itemId } : {}),
    name: checklistTemplateItem.label,
    type: checklistTemplateItem.type,
    blocks_subsequent_items: checklistTemplateItem.blocks_subsequent_items,
    restricted_to_assignee: checklistTemplateItem.restricted_to_assignee,
    // This is already getting remapped earlier in the process
    // It would be good to clean this up, but the whole request-pipeline setup is super complicated and hard to follow
    // @see frontend/src/modules/tasks/settings/mappers/map-checklist-template-to-checklist-template-form-data.ts
    assignee:
      ((checklistTemplateItem.assignee as unknown) as SearchResultItem<Assignee>) ??
      null,
    has_outcome: !!checklistTemplateItem.has_outcome,
    outcome_data: checklistTemplateItem.outcome_data,
    due_date: {
      due_date_type: checklistTemplateItem.due_date_type?.id || 'none',
      related_date: dueDateData?.related_date || defaultRelatedDate,
      offset_related_date: {
        count: dueDateData?.offset_amount || 1,
        unit: dueDateData?.offset_unit || { id: 'days', label: 'Day(s)' },
        offset:
          offsetDirection && relatedDate
            ? getOffset({ offsetDirection, relatedDate })
            : defaultRelatedDateOffset
      }
    }
  };
}
