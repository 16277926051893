import React, { useMemo } from 'react';
import { query, useEntityQuery } from '@rexlabs/model-generator';

import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { RecordScreen } from 'view/components/record-screen';

import { useRecordScreenSubmitHandler } from 'view/hooks/use-record-screen-submit-handler';
import { mapUserFormDataToUpdateRequest } from 'src/modules/users/mappers/map-user-form-data-to-update-request';
import { mapUserToFormData } from 'src/modules/users/mappers/map-user-to-form-data';
import { useConfirmationDialog } from '@rexlabs/dialog';
import { useApiClient } from 'src/lib/axios/hooks/use-api-client';
import { getUserRedirectUri } from 'src/modules/users/utils/get-user-redirect-uri';
import { useToast } from 'view/components/@luna/notifications/toast';
import { userIncludes, usersModel } from '../models/users';
import { useContent } from '../data/details-content';
import { UserTitleBlock } from '../components/user-title-block';

// Another model generator bug...
// If the query is the same as the list view query the request is not sent. HOWEVER model generator does not keep the
// related models hence we need to add a dummy_value too the query so it re-fetches
const getUserQuery = (id: string) => {
  return query`{
  ${usersModel} (id: ${id}) {
    id
    dummy_value
    contact {
      primary_email
    }
    role
    linked_files
    portfolios {
      summary
    }
  }
}`;
};

interface UserDetailsScreenProps {
  userId: string;
}

export function UserDetailsScreen({ userId }: UserDetailsScreenProps) {
  const breadcrumbs = [{ type: BREADCRUMBS.USER }];
  const axios = useApiClient();
  const { addToast } = useToast();
  const query = useMemo(() => getUserQuery(userId), [userId]);
  const { open: openConfirmationDialog } = useConfirmationDialog();

  const { data: user, status, actions } = useEntityQuery(query, {
    throwOnError: false
  });

  const content = useContent();

  const formData = useMemo(() => user && mapUserToFormData(user), [user]);

  const handleSubmit = useRecordScreenSubmitHandler(
    async ({ changedValues }) => {
      const dataToSubmit = mapUserFormDataToUpdateRequest(
        changedValues,
        formData
      );
      const { data: newUser } = await actions.updateItem({
        id: user?.id,
        data: dataToSubmit,
        args: {
          include: userIncludes
        }
      });

      const shouldPromptToResendInvite =
        changedValues.email && user?.invited_at && user?.accepted_at === null;

      if (shouldPromptToResendInvite) {
        openConfirmationDialog({
          title: 'Email updated',
          message:
            "The user's email has been updated, and the existing invitation has been invalidated. Would you like to resend the invite?",
          confirmText: 'Resend invite',
          cancelText: "Don't resend",
          onConfirm: async () => {
            const res = await axios('post', `/user-invites/`, {
              redirect_uri: getUserRedirectUri(),
              user: {
                id: user.id
              }
            });

            if (res !== false) {
              addToast({
                description: (
                  <>
                    Invitation sent to{' '}
                    <b>{newUser.contact?.primary_email?.email_address}</b>
                  </>
                )
              });
            }
          }
        });
      }

      return Promise.all([
        actions.refreshItem({
          id: user?.id,
          args: {
            include: userIncludes
          }
        }),
        actions.refreshLists()
      ]);
    }
  );

  return (
    <RecordScreen
      privilege={'users.update'}
      isLoading={status === 'loading'}
      data={formData}
      handleSubmit={handleSubmit}
      content={content}
      titleBlock={<UserTitleBlock user={user!} />}
      breadcrumbs={breadcrumbs}
    />
  );
}
