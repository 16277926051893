import React, { useMemo } from 'react';

import {
  query,
  useEntityQuery,
  useModelActions
} from '@rexlabs/model-generator';

import { PendingDisbursementTitleBlock } from 'src/modules/disbursements/components/pending-disbursement-title-block';

import { Ownership, ownershipsModel } from 'data/models/entities/ownerships';
import { financialsPendingDisbursementsOwnershipsModel } from 'data/models/entities/financials/pending-disbursements/pending-ownership-disbursement';

import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { RecordScreen } from 'view/components/record-screen';
import { useRecordScreenSubmitHandler } from 'view/hooks/use-record-screen-submit-handler';

import { usePendingOwnershipDisbursementContent } from '../data/use-pending-ownership-disbursement-content';

export const getPendingOwnershipDisbursementQuery = (id: string) => query`{
  ${financialsPendingDisbursementsOwnershipsModel} (id: ${id}) {
    id
    payment_methods
    object {
      disbursement_withheld_funds
      trust_summary
      financial_summary
      disbursement_payment_methods {
        contact {
          tax
        }
        contact_payment_method {
          payment_method
        }
      }
      owners
    }
    last_disbursement
    disburseable_direct_debit_invoices
    disburseable_bpay_invoices
    other_disburseable_invoices
    financial_summary
    disburse_summary
    withheld_funds
    trust_journal_entry_preview {
      paid_to
    }
    ownership_tax_transactions {
      contact
    }
    disbursement_snapshot_summary
  }
}`;

interface PendingOwnershipDisbursementDetailsScreenProps {
  pendingOwnershipDisbursementId: string;
}

export function PendingOwnershipDisbursementDetailsScreen({
  pendingOwnershipDisbursementId
}: PendingOwnershipDisbursementDetailsScreenProps) {
  const breadcrumbs = [{ type: BREADCRUMBS.DISBURSEMENT }];
  const query = useMemo(
    () => getPendingOwnershipDisbursementQuery(pendingOwnershipDisbursementId),
    [pendingOwnershipDisbursementId]
  );

  const { status, data, actions } = useEntityQuery(query);
  const { updateItem } = useModelActions(ownershipsModel);

  const content = usePendingOwnershipDisbursementContent();

  const handleSubmit = useRecordScreenSubmitHandler(
    async ({ changedValues }) => {
      const dataToSubmit: Partial<Ownership> = changedValues;

      if (changedValues?.object?.disbursement_payment_methods) {
        dataToSubmit.disbursement_payment_methods =
          changedValues?.object?.disbursement_payment_methods;
      }

      await updateItem({
        id: pendingOwnershipDisbursementId,
        data: dataToSubmit
      });

      return actions.refreshItem({
        id: pendingOwnershipDisbursementId
      });
    }
  );

  return (
    <RecordScreen
      privilege={'disbursements.read'}
      isLoading={status === 'loading'}
      data={data}
      content={content}
      titleBlock={<PendingDisbursementTitleBlock pendingDisbursement={data!} />}
      breadcrumbs={breadcrumbs}
      handleSubmit={handleSubmit}
    />
  );
}
