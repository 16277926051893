/*
|-------------------------------------------------------------------------------
| API Client
|-------------------------------------------------------------------------------
|
| The singleton API client is created with @rexlabs/api-client. It includes:
|  - Base URL sourced from the env config
|  - Default headers for laravel servers
|  - Middleware (interceptors) for common tasks
|
*/

import _ from 'lodash';
import { create } from '@rexlabs/api-client';
import config from 'src/config';
import { isCustomValidationErrorResponse } from 'src/modules/custom-validation/components/custom-validation-interceptor';
import { createErrorFromResponse } from 'utils/api/create-error-from-response';
import { isPrivilegeErrorResponse } from 'src/modules/system/components/privilege-interceptor';
import { isMetricWriteRuleErrorResponse } from 'src/modules/metric-write-rules/components/metric-write-rule-interceptor';

/**
 * Flattens res.data.error or res.data.data to res.data. Also hoists
 * res.data.meta to res.meta.
 */
function flattenResponseData(response) {
  if (_.isPlainObject(response.data)) {
    response.pagination = response.data.pagination;
    response.data = response.data.error || response.data.data || response.data;
  }
  return response;
}

function printError(response) {
  // 401 errors are handled by the interceptor in auth-layer, so ignore them here
  if (
    response.problem &&
    response.status !== 401 &&
    !isCustomValidationErrorResponse(response) &&
    !isMetricWriteRuleErrorResponse(response) &&
    !isPrivilegeErrorResponse(response)
  ) {
    const e = createErrorFromResponse(response);
    return Promise.reject(e);
  }
  return response;
}

/**
 * TODO: The api client is not conducive to changing the baseURL after it has been instantiated, as this wasn't part of the original design.
 * This approach seems to work for now, but in the future if we need to do any more changes to this file, we should consider refactoring the api client to be more modular.
 * The risk with this is that it is used in a lot of touch-points, so we need to be careful, and it is probably too risky to do alongside the region work.
 */

const clientConfig = {
  baseURL: config.API_URL,
  responseType: 'json'
};

export let api = create(clientConfig);

/**
 * Initializes the API client with default headers and middleware.
 */
function init() {
  api.setHeader('Accept', 'application/json');
  api.setHeader('Content-Type', 'application/json');
  api.addResponseInterceptor(flattenResponseData);
  api.addResponseInterceptor(printError);
}

export function setAuthToken(apiToken) {
  api.setHeader('Authorization', `Bearer ${apiToken}`);
}

export function setSiloId(siloId) {
  api.setHeader('X-App-Silo', siloId);
}

/**
 * Resets the API client to its initial state.
 * This is needed so that when we log out, the API client is reset to its initial state, and not connected the the previous region.
 */
export function resetApiClient() {
  api = create(clientConfig);
  init();
}

export function setBaseUrl(baseURL) {
  api.defaults = {
    ...api.defaults,
    baseURL
  };

  api.axiosInstance.defaults.baseURL = baseURL;
}

// Init needs to be called here once so that the api client is ready to use
init();
