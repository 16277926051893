import React from 'react';
import { Invoice } from 'data/models/entities/financials/invoices';
import { BlockConfig } from 'view/components/record-screen/types';
import { InvoiceCommissionRecordTable } from '../components/invoice-commission-record-table';

export const commissionDetailsBlock: BlockConfig<Invoice> = {
  id: 'commission-details',
  title: 'Commission',
  View: ({ data }) => {
    const {
      commission_amount_exc_tax = null,
      commission_amount_inc_tax = null,
      commission_amount_tax = null,
      commission_description = null
    } = data || {};

    return (
      <InvoiceCommissionRecordTable
        items={[
          {
            commission_amount_exc_tax,
            commission_amount_inc_tax,
            commission_amount_tax,
            commission_description
          }
        ]}
      />
    );
  }
};
