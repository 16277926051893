import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import Box from '@rexlabs/box';
import { PDFViewer } from 'view/components/pdf-viewer';
import { ViewDocumentProps } from '../dialogs/view-document-dialog';

type DocumentBlock = BlockConfig<ViewDocumentProps>;

export const imageDocumentViewBlock: DocumentBlock = {
  id: 'document-image',
  Edit: ({ data }) => {
    return <img src={data?.fileUrl} style={{ maxWidth: '100%' }} />;
  }
};

export const pdfDocumentViewBlock: DocumentBlock = {
  id: 'document-pdf',
  Edit: ({ data }) => {
    if (data?.fileUrl == undefined) {
      return <Box>No file preview.</Box>;
    }

    return <PDFViewer fileUrl={data.fileUrl} />;
  }
};
