import React from 'react';
import { CommissionRule } from 'src/modules/contacts/types/contact-types';
import { Column, RecordTable } from 'view/components/table';

const columns: Column<CommissionRule>[] = [
  {
    id: 'account_code',
    Header: 'Invoice account code',
    accessor: (item) => item.chart_of_accounts_account?.name
  },

  {
    id: 'commission_structure',
    Header: 'Commission structure',
    accessor: (item) => item.commission_template?.name
  }
];

interface AccountCodesOverridesTableProps {
  items: Array<any>;
}

export function AccountCodesOverridesTable({
  items
}: AccountCodesOverridesTableProps) {
  return <RecordTable isLoading={false} columns={columns} items={items} />;
}
