import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Column, Grid } from '@rexlabs/grid';
import { Value } from 'view/components/values';
import { Field } from '@rexlabs/form';
import { ChartOfAccountsAccountSelect } from 'view/components/inputs/selects/v4-selects/chart-of-accounts-account';
import { InfoBanner } from 'view/components/@luna/notifications/banner';
import InfoCircleIcon from 'view/components/icons/info';
import { RadioGroupInput } from '@rexlabs/radio-input';

const BANNER_MAP = {
  true: <IncludingTaxInfo />,
  false: <ExcludingTaxInfo />
};

const INCLUDE_CHART_OF_ACCOUNT = 'Including Chart of Account Tax Type';
const EXCLUDE_CHART_OF_ACCOUNT = 'Excluding Chart of Account Tax Type';

export const SUPPLIER_COMMISSION_SETTINGS_BLOCK_ID =
  'supplier-commission-settings';

export const supplierCommissionSettingsBlock: BlockConfig = {
  id: SUPPLIER_COMMISSION_SETTINGS_BLOCK_ID,
  title: 'Supplier commission settings',
  validate: {
    definitions: {
      chart_of_accounts_account: { rules: 'required' },
      commission_amount_includes_tax: { rules: 'required' }
    }
  },
  View: ({ data }) => {
    return (
      <Grid columns={4}>
        <Column width={2}>
          <Value
            label='Default account code for billing supplier commission'
            value={data?.chart_of_accounts_account?.name}
          />
        </Column>

        <Column width={2}>
          <Value
            label='Supplier Commission Tax'
            value={
              data?.commission_amount_includes_tax
                ? INCLUDE_CHART_OF_ACCOUNT
                : EXCLUDE_CHART_OF_ACCOUNT
            }
          />
        </Column>

        {BANNER_MAP[data.commission_amount_includes_tax]}
      </Grid>
    );
  },
  Edit: ({ values }) => {
    return (
      <Grid columns={4}>
        <Column width={2}>
          <Field
            name='chart_of_accounts_account'
            id='chart_of_accounts_account'
            label='Default account code for billing supplier commission'
            Input={ChartOfAccountsAccountSelect}
            inputProps={{
              disableFixture: false,
              filterOnChartOfAccountCategories: ['income'],
              dialogProps: {
                disableAccountCategory: true,
                initialValues: {
                  account_category: {
                    id: 'income',
                    label: 'Income'
                  }
                }
              }
            }}
          />
        </Column>

        <Column width={4}>
          <Field
            id='commission_amount_includes_tax'
            label='Supplier Commission Tax'
            name='commission_amount_includes_tax'
            Input={RadioGroupInput}
            inputProps={{
              options: [
                {
                  label: INCLUDE_CHART_OF_ACCOUNT,
                  value: true
                },
                {
                  label: EXCLUDE_CHART_OF_ACCOUNT,
                  value: false
                }
              ],
              orientation: 'horizontal'
            }}
          />
        </Column>

        {BANNER_MAP[values.commission_amount_includes_tax]}
      </Grid>
    );
  }
};

// Helpers

function IncludingTaxInfo() {
  return (
    <InfoBanner
      Icon={InfoCircleIcon}
      description={
        <>
          When you select the &apos;Include Tax on Supplier Commission&apos;
          option, the total amount calculated from the supplier&apos;s invoice
          will automatically include tax where a commission has been applied. If
          you prefer not to include tax at the time of invoice creation, this
          setting can be adjusted.
        </>
      }
    />
  );
}

function ExcludingTaxInfo() {
  return (
    <InfoBanner
      Icon={InfoCircleIcon}
      description={
        <>
          When the &apos;Exclude Tax on Supplier Commission&apos; option is
          selected, the total amount calculated from the supplier&apos;s invoice
          will not include tax on the applied commission. If you wish to include
          tax, you can update this setting when creating the invoice.
        </>
      }
    />
  );
}
