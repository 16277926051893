import {
  CellProps,
  ColumnConfig,
  DateCell,
  RecordCell,
  TagCell,
  TextCell
} from '@rexlabs/table';
import { formatDate } from 'utils/dates/format';
import AttachmentCell from 'view/components/cells/attachment';
import { Author } from 'src/modules/authors/types/author-types';
import ROUTES from 'routes/app';
import { AccountingJournalEntry } from 'src/modules/account-journal-entries/types/account-journal-entry-types';
import { Truncate } from '@rexlabs/text';
import { Invoice } from 'data/models/entities/financials/invoices';
import { FileSize } from 'view/components/cells/file-size';
import React, { ComponentType, PropsWithChildren } from 'react';
import { Tag } from '@rexlabs/tag';
import PercentageCell from 'view/components/cells/percentage';
import { RecordType, RecordTypes, ValueListValue } from 'data/models/types';
import { getAlfredRecordCell } from 'view/components/table/cells/get-alfred-record-cell';
import { formatCurrency } from 'utils/formatters';
import { DateTimeCell } from 'src/modules/common/components/cells/date-time-cell';
import { TwoTierCell } from 'view/components/cells/two-tier-cell';
import { Property } from 'src/modules/properties/types/property-types';
import { RecordLink } from 'view/components/record-link/record-link';

type AlfredRecordCells = {
  [key in RecordType]: any;
};

const alfredRecordCells = Object.fromEntries(
  Object.values(RecordTypes).map((recordType) => [
    recordType,
    getAlfredRecordCell(recordType)
  ])
) as AlfredRecordCells;

export interface AlfredColumnConfig<T> extends ColumnConfig<T> {
  type?: keyof typeof columnTypes;
  toCsv?: (value: any) => string;
  headerString?: string;
  excludeFromExport?: boolean;
}

export const columnTypes = {
  ...alfredRecordCells,
  text: {
    Cell: TextCell
  },

  currency: {
    toCsv: (item: number) => {
      return (item / 100).toString();
    },
    Cell: ({ value }: CellProps) => <>{value ? formatCurrency(value) : '--'}</>
  },

  date: {
    Cell: DateCell
  },

  datetime: {
    Cell: DateTimeCell
  },

  date_range: {
    Cell: ({ value }: CellProps) => (
      <>
        {formatDate(value.from)} - {formatDate(value.to)}
      </>
    ),
    toCsv: (item) => `${formatDate(item.from)} - ${formatDate(item.to)}`
  },

  attachment: {
    Cell: AttachmentCell
  },

  author: {
    Cell: RecordCell,
    toCsv: (item: Author) => {
      return item.name;
    },
    cellProps: {
      labelKey: 'name',
      getCellLinkProps: (value: Author) => {
        if (!value?.user) {
          return null;
        }
        return {
          to: ROUTES.USER,
          params: {
            userId: value.user?.id
          }
        };
      }
    }
  },

  accounting_journal_entry: {
    Cell: RecordCell,
    cellProps: {
      labelKey: 'record_reference',
      getCellLinkProps: (value: AccountingJournalEntry) => {
        return {
          to: ROUTES.ACCOUNTING_JOURNAL_ENTRY,
          params: {
            accountingJournalEntryId: value.id
          }
        };
      }
    }
  },

  property: {
    Cell: ({ value }: { value: Property | undefined }) =>
      value ? (
        <TwoTierCell
          text={
            <RecordLink
              onClick={(e) => e.stopPropagation()}
              type='property'
              id={value.id}
            >
              {value.display_name}
            </RecordLink>
          }
          subtext={value.record_reference}
        />
      ) : (
        <>--</>
      )
  },

  'external-link': {
    Cell: ({ value }: CellProps) => (
      <Truncate>
        <a href={value} target='_blank' rel='noopener noreferrer nofollow'>
          {value}
        </a>
      </Truncate>
    )
  },

  record: {
    cellProps: {
      labelKey: 'label',
      getCellLinkProps: () => undefined
    },
    Cell: RecordCell
  },

  invoice: {
    Cell: RecordCell,
    cellProps: {
      labelKey: 'record_reference',
      getCellLinkProps: (value: Invoice) => {
        if (!value?.id) {
          return null;
        }

        return {
          to: ROUTES.INVOICE,
          params: {
            invoiceId: value.id
          }
        };
      }
    }
  },

  fileSize: {
    Cell: FileSize as ComponentType<PropsWithChildren<CellProps<any, any>>>
  },

  value: {
    toCsv: (value: ValueListValue<any>) => {
      return value?.label ?? '-';
    },
    Cell: ({ value }: CellProps) => <Truncate>{value?.label ?? '-'}</Truncate>
  },

  tagValue: {
    Cell: ({ value }: CellProps) => (
      <TagCell>
        <Tag>{value?.label}</Tag>
      </TagCell>
    )
  },

  tagValues: {
    Cell: ({ value }: CellProps) => (
      <TagCell>
        {value.map((value) => (
          <Tag key={value.id}>{value?.label}</Tag>
        ))}
      </TagCell>
    )
  },

  tag: {
    Cell: ({ value }: CellProps) => (
      <TagCell>
        <Tag>{value}</Tag>
      </TagCell>
    )
  },

  percentage: {
    Cell: PercentageCell
  },

  boolean: {
    Cell: ({ value }: CellProps) => <Truncate>{value ? 'Yes' : 'No'}</Truncate>
  },

  two_tier: {
    Cell: ({ value }: CellProps) => {
      const { text, subtext } = value;

      return <TwoTierCell text={text ?? '--'} subtext={subtext ?? ''} />;
    },
    toCsv: (item) => {
      const { text, subtext } = item;
      return !text && !subtext ? '--' : `${text ?? '--'} - ${subtext ?? '--'}`;
    }
  }
};
