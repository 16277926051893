import React, { useEffect, useMemo, useRef } from 'react';
import { query, useEntityQuery } from '@rexlabs/model-generator';

import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { RecordScreen } from 'view/components/record-screen';

import { useRecordScreenSubmitHandler } from 'view/hooks/use-record-screen-submit-handler';
import { ResourceId, useTableFilters } from 'view/hooks/use-table-filters';
import { AgGridReact } from 'ag-grid-react';
import { TableProvider, useUrlState } from '@rexlabs/table';
import { noop } from 'lodash';
import { GlobalFilter } from '@rexlabs/table/lib/types/context';
import LoadingState from 'view/components/states/loading';
import { content } from '../data/content';
import { customReportsModel } from '../models/custom-reports-model';
import { GridRefContext } from '../../screens/table-report-record';
import { CustomReportTitleBlock } from '../components/custom-report-title-block';
import { CustomReport } from '../types/CustomReport';

const getCustomReportQuery = (id: string) => query`{
  ${customReportsModel} (id: ${id}) {
    id
    required_privileges
  }
}`;

interface CustomReportDetailsProps {
  customReportId?: string;
}

export const CustomReportDetails = ({
  customReportId
}: CustomReportDetailsProps) => {
  const breadcrumbs = [{ type: BREADCRUMBS.CUSTOM_REPORT }];
  const query = useMemo(() => getCustomReportQuery(customReportId!), [
    customReportId
  ]);

  const { status, data, actions } = useEntityQuery(query, {
    throwOnError: false
  });

  // Set the filter string into the url for the table to pick up
  useEffect(() => {
    if (data?.filters) {
      // set the uri hash `filters` key to the value of the filter string, url escaped
      window.location.hash = `filters=${encodeURIComponent(
        JSON.stringify(data.filters)
      )}`;
    }
  }, [data]);

  const handleSubmit = useRecordScreenSubmitHandler<CustomReport>(
    ({ values, changedValues }) => {
      // If the resource changed, but the filters, sorts, or table state did not, we need to null them out
      const resourceChanged = !!changedValues.resource;
      const data: Partial<CustomReport> = {
        ...values,
        filters: resourceChanged
          ? changedValues.filters ?? null
          : values.filters,
        sorts: resourceChanged ? changedValues.sorts ?? null : values.sorts,
        table_state: resourceChanged
          ? changedValues.table_state ?? null
          : values.table_state
      };
      return actions.updateItem({
        id: customReportId,
        data
      });
    }
  );

  const handleSaveTableState = async (
    tableState: CustomReport['table_state'],
    filters: CustomReport['filters'],
    sorts: CustomReport['sorts']
  ) => {
    return actions.updateItem({
      id: customReportId,
      data: {
        table_state: tableState,
        filters: filters,
        sorts: sorts
      }
    });
  };

  const titleBlock = <CustomReportTitleBlock customReport={data} />;

  if (!data) {
    return <LoadingState />;
  }

  return (
    <CustomReportTableProvider
      resourceId={data?.resource.id}
      filters={data.filters ?? undefined}
      sorts={data.sorts ?? undefined}
    >
      <CustomReportProvider value={{ handleSaveTableState }}>
        <RecordScreen
          isLoading={status === 'loading'}
          data={data}
          handleSubmit={handleSubmit}
          content={content}
          titleBlock={titleBlock}
          breadcrumbs={breadcrumbs}
        />
      </CustomReportProvider>
    </CustomReportTableProvider>
  );
};

type FilterConfig = [resource: ResourceId];

type TableProviderProps = {
  resourceId: string;
  children: React.ReactNode;
  filters?: CustomReport['filters'];
  sorts?: CustomReport['sorts'];
};

const CustomReportTableProvider = ({
  resourceId,
  children,
  filters,
  sorts
}: TableProviderProps) => {
  const filterConfig: FilterConfig = [resourceId as ResourceId];

  const { getFilters, getSort } = useTableFilters(...filterConfig);

  const gridRef = useRef<AgGridReact<any> | null>();

  const initialGlobalFilter = useMemo(() => {
    if (filters) {
      return filters;
    }
    return undefined;
  }, [filters]);

  const filter = Object.keys(initialGlobalFilter ?? {}).map<GlobalFilter>(
    (key: string) => {
      // todo: handle multiple filters
      const filtersForKey = initialGlobalFilter?.[key][0];
      return {
        field: key,
        op: filtersForKey.op,
        value: filtersForKey.value,
        logicalOperator: filtersForKey.logicalOperator
      };
    }
  );

  const { getTableProps: getUrlStateProps } = useUrlState({
    initialGlobalFilter: filter,
    initialSortBy: sorts ?? undefined
  });

  return (
    <GridRefContext.Provider value={gridRef}>
      <TableProvider
        {...getUrlStateProps()}
        getFilters={getFilters}
        getSort={getSort}
      >
        {children}
      </TableProvider>
    </GridRefContext.Provider>
  );
};

export const CustomReportContext = React.createContext({
  handleSaveTableState: noop
});

const CustomReportProvider = CustomReportContext.Provider;
