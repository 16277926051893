import { Columns } from 'src/view/components/table';

export const userGroupColumns: Columns = [
  {
    id: 'label',
    Header: 'Label',
    width: 120
  },
  {
    id: 'description',
    Header: 'Description'
  }
];
