import React from 'react';
import { BoxProps } from '@rexlabs/box';
import { Grid } from 'view/components/@luna/form/grid';
import { Value } from 'view/components/values';
import { CurrencyValue } from 'view/components/values/currency';
import { formatCurrency } from 'utils/formatters';
import {
  FolioCommissionTemplateStructure,
  FolioCommissionTemplateTier
} from '../types/FolioCommissionTemplate';
import { TemplateTierLineItemContainer } from './template-tier-line-item-container';

interface ViewTemplateTierLineItemProps extends BoxProps {
  tier: FolioCommissionTemplateTier;
  templateStructureType: Extract<
    FolioCommissionTemplateStructure,
    'progressive' | 'tiered'
  >;
}

export function ViewTemplateTierLineItem({
  tier,
  templateStructureType
}: ViewTemplateTierLineItemProps) {
  const commissionLabel = 'Apply commission';

  return (
    <TemplateTierLineItemContainer>
      <Grid columns={2}>
        <Value
          label={
            templateStructureType === 'tiered'
              ? 'For invoices'
              : 'For invoice amount'
          }
          value={
            tier.to === null
              ? `${formatCurrency(tier.from)} and over`
              : `${formatCurrency(tier.from)} - ${formatCurrency(tier.to)}`
          }
        />
        {tier?.type?.id === 'flat_fee' ? (
          <CurrencyValue label={commissionLabel} value={tier?.amount} />
        ) : (
          <Value label={commissionLabel} value={`${tier?.amount}%`} />
        )}
      </Grid>
    </TemplateTierLineItemContainer>
  );
}
