import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { RecordTable } from 'view/components/table';
import { getTrustJournalEntryColumns } from 'src/modules/trust-ledgers/utils/get-trust-journal-entry-columns';
import { BankWithdrawal } from 'src/modules/banking/bank-withdrawals/types/bank-withdrawal-types';
import { Trans } from 'react-i18next';

const columns = getTrustJournalEntryColumns([
  'contact',
  'status_id',
  'description',
  'account_number',
  'date_of',
  'type_id',
  'amount',
  'payment_method_id'
]);

export const bankWithdrawalTrustJournalEntriesBlock: BlockConfig<BankWithdrawal> = {
  id: 'trust-journal-entries',
  title: () => <Trans i18nKey='trust-journal-entries.label.plural' />,
  View: ({ data }) => {
    return (
      <RecordTable
        isLoading={false}
        withToolbar={false}
        withPagination={false}
        items={data?.line_items?.items.map((el) => {
          return el.trust_journal_entry;
        })}
        id='trust-journal-entries'
        columns={columns}
      />
    );
  }
};
