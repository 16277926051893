import { useQuery } from 'react-query';

import {
  CommissionRule,
  Contact
} from 'src/modules/contacts/types/contact-types';
import { valueListFactory } from 'src/lib/testing/factories/value-list-factory';

import { postCommissionPreview } from '../utils/post-commission-preview';
import { InvoiceLineItemFormValues } from '../../invoices/components/invoice-line-item';

export function useGetCommissionPreviewQuery({
  contact,
  lineItems,
  commissionRule,
  isTaxIncluded
}: {
  contact?: Contact;
  lineItems: InvoiceLineItemFormValues[];
  commissionRule?: Partial<CommissionRule>;
  isTaxIncluded: boolean;
}) {
  const requiredLineItemKeys = ['tax_type', 'amount'];

  const validLineItems = lineItems.filter((lineItem) =>
    requiredLineItemKeys.every((key) => !!lineItem[key])
  );

  const isCommissionRuleValid = !!(
    commissionRule?.commission_template ||
    (commissionRule?.structure_type && commissionRule?.commission_amount) ||
    (!commissionRule?.commission_template &&
      !commissionRule?.structure_type &&
      !commissionRule?.commission_amount)
  );

  return useQuery(
    [
      `supplierCommission-${contact?.id}`,
      {
        validLineItems,
        commissionRule,
        isTaxIncluded
      }
    ],

    async () =>
      contact &&
      postCommissionPreview({
        ...(commissionRule?.structure_type?.id
          ? // If we have a structure type, we want to use that, not the template
            {
              commission_structure_type: commissionRule?.structure_type,
              commission_amount: commissionRule?.commission_amount || 0,
              commission_template: null
            }
          : // Otherwise, we want to use the template
            {
              commission_template: commissionRule?.commission_template || null
            }),
        is_tax_included: isTaxIncluded,
        line_items: validLineItems,
        payable_to: {
          object: {
            id: contact.id,
            type: valueListFactory(contact.__record_type)
          }
        }
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: !!contact && isCommissionRuleValid
    }
  );
}
