import React, { FC } from 'react';
import {
  RecordOption,
  Select,
  SelectProps,
  UseItemsArgs
} from '@rexlabs/select';

import { normaliseInvoice } from 'utils/normaliser/invoices';
import { getInvoices, GetInvoicesArgs } from 'utils/api/get-invoices';
import { Invoice } from 'data/models/entities/financials/invoices';
import { priorityMap } from 'src/modules/invoices/components/invoice-priority-select';
import { useItems } from 'view/hooks/use-items';

type InvoiceSelectProps = SelectProps<Invoice> &
  Pick<UseItemsArgs<Invoice>, 'getSuggestedItems'> &
  GetInvoicesArgs;

export const InvoiceSelect: FC<InvoiceSelectProps> = ({
  filter,
  includes,
  ...props
}) => {
  const getItems = React.useCallback(
    (searchTerm: string) => getInvoices({ searchTerm, filter, includes }),
    [filter, includes]
  );
  const { getSelectProps } = useItems<Invoice>({
    getSuggestedItems: props.getSuggestedItems,
    getItems
  });

  const PrefixIcon = React.useMemo(() => {
    if (typeof props.value !== 'object' || Array.isArray(props.value)) return;

    const prefixIconId = props.value?.bill_priority?.id;

    return prefixIconId ? priorityMap[prefixIconId] : null;
  }, [props.value]);

  return (
    <Select
      {...props}
      {...getSelectProps()}
      normaliser={normaliseInvoice}
      ValuePrefix={PrefixIcon}
      Option={RecordOption}
    />
  );
};
