import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useDialog } from '@rexlabs/dialog';
import { Portfolio } from '../../models/portfolios-model';
import { PortfolioMember } from '../../models/portfolio-members-model';
import { RemoveMemberConfirmationDialog } from '../../dialogs/remove-member-confirmation-dialog';
import { RemoveAndSetNewPrimaryMemberConfirmationDialog } from '../../dialogs/remove-and-set-new-primary-member-confirmation-dialog';
import { RemoveAndAddNewPrimaryMemberConfirmationDialog } from '../../dialogs/remove-and-add-new-primary-member-confirmation-dialog';

export function useGetRemovePortfolioMemberAction({
  portfolio
}: {
  portfolio: Portfolio;
}) {
  const { open: openRemoveMemberConfirmationDialog } = useDialog(
    RemoveMemberConfirmationDialog
  );
  const {
    open: openRemoveAndSetNewPrimaryMemberConfirmationDialog
  } = useDialog(RemoveAndSetNewPrimaryMemberConfirmationDialog);
  const { open: openRemoveAndAddMemberConfirmationDialog } = useDialog(
    RemoveAndAddNewPrimaryMemberConfirmationDialog
  );

  return (portfolioMember: PortfolioMember): ActionDeclaration => {
    return {
      id: 'remove-portfolio-member',
      label: 'Remove member',
      intent: 'danger',
      handleAction: async () => {
        const memberIsPrimaryMember = portfolioMember.is_primary;
        const portfolioHasNonPrimaryMemberWithRoleOfPropertyManager = portfolio.members?.data.find(
          (member) =>
            !member.is_primary &&
            member.portfolio_role?.system_purpose?.id === 'property_manager'
        );

        if (memberIsPrimaryMember) {
          if (portfolioHasNonPrimaryMemberWithRoleOfPropertyManager) {
            return openRemoveAndSetNewPrimaryMemberConfirmationDialog({
              portfolioMember,
              portfolio
            });
          }
          return openRemoveAndAddMemberConfirmationDialog({
            portfolioMember,
            portfolioId: portfolio.id
          });
        }

        return openRemoveMemberConfirmationDialog({
          portfolioMember,
          portfolioId: portfolio.id
        });
      }
    };
  };
}
