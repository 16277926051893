import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';

import { UploadBankTransactionsFormValues } from '../types';
import { UploadBankTransactionsForm } from '../components/upload-bank-transactions-form';

export const uploadBankTransactionsBlock: BlockConfig<
  any,
  any,
  UploadBankTransactionsFormValues
> = {
  id: 'uploadBankTransactions',
  validate: {
    definitions: {
      upload_bank_transactions_file: {
        name: 'bank transaction file',
        rules: 'required'
      }
    },
    messages: {
      required: 'Please upload a bank transaction file'
    }
  },
  Edit: ({ values, setValues, setFieldValue }) => {
    return (
      <UploadBankTransactionsForm
        values={values}
        setValues={setValues}
        setFieldValue={setFieldValue}
        fileType='transactions'
      />
    );
  }
};
