import React, { useCallback } from 'react';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';
import { useToast } from 'view/components/@luna/notifications/toast';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useModelActions } from '@rexlabs/model-generator';
import {
  PortfolioMember,
  portfolioMembersModel
} from '../models/portfolio-members-model';
import { removeAndAddNewPrimaryMemberBlock } from '../blocks/remove-and-add-new-primary-member';
import { portfoliosModel } from '../models/portfolios-model';
import { usePortfolioRoles } from '../hooks/use-portfolio-roles';

const content: DialogContentConfig = [
  {
    id: 'remove-and-add-new-primary-property-manager',
    label: 'Remove and add new primary property manager',
    blocks: [removeAndAddNewPrimaryMemberBlock]
  }
];

export interface RemoveAndAddNewPrimaryMemberConfirmationDialog {
  onClose?: () => void;
  portfolioMember: PortfolioMember;
  portfolioId: string;
}

export function RemoveAndAddNewPrimaryMemberConfirmationDialog({
  onClose,
  portfolioMember,
  portfolioId
}: RemoveAndAddNewPrimaryMemberConfirmationDialog) {
  const { addToast } = useToast();
  const { refreshItem: refreshPortfolio } = useModelActions(portfoliosModel);
  const { trashItem, createItem } = useModelActions(portfolioMembersModel);
  const { data: roles } = usePortfolioRoles();

  const managerRole = roles?.find(
    (role) => role.system_purpose?.id === 'property_manager'
  );

  const handleSubmit: RecordSubmitHandler = useCallback(
    async ({ values }) => {
      await createItem({
        data: {
          is_primary: true,
          portfolio: { id: portfolioId },
          user: { id: values.user.id },
          portfolio_role: { id: managerRole?.id }
        }
      });
      await trashItem({ id: portfolioMember.id });

      addToast({
        type: 'success',
        description: 'Member successfully removed'
      });

      await refreshPortfolio({ id: portfolioId });

      return true;
    },
    [
      addToast,
      createItem,
      managerRole?.id,
      portfolioId,
      portfolioMember.id,
      refreshPortfolio,
      trashItem
    ]
  );

  return (
    <RecordDialog
      onClose={onClose}
      blockProps={{
        portfolioMember,
        portfolioId
      }}
      title={'Remove member'}
      handleSubmit={handleSubmit}
      content={content}
      submitLabel={'Continue'}
      size='s'
    />
  );
}
