import { DialogProps } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import * as React from 'react';
import { useRecordCreatedToast } from 'src/modules/common/toasts/hooks/use-record-created-toast';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { customReportDetailsBlock } from '../blocks/custom-report-details-block';
import { customReportsModel } from '../models/custom-reports-model';

export interface CreateCustomReportRecordDialogProps extends DialogProps {
  initialValues: {
    // TODO: add any desired props here
  };
}

const content = [
  {
    id: 'add-custom-report',
    label: 'Add Custom Report',
    blocks: [customReportDetailsBlock]
  }
];

export function CreateCustomReportRecordDialog({
  onClose,
  initialValues
}: CreateCustomReportRecordDialogProps) {
  const { createItem, refreshLists } = useModelActions(customReportsModel);
  const addToast = useRecordCreatedToast(customReportsModel, { actions: [] });

  const handleSubmit = async ({ values }) => {
    // TODO: make any modifications to the data here before submitting
    const dataToSubmit = { ...values };

    const { data: customReport } = await createItem({ data: dataToSubmit });

    addToast(customReport);

    return refreshLists();
  };

  return (
    <RecordDialog
      title='Create custom report'
      data={initialValues}
      content={content}
      onClose={onClose}
      handleSubmit={handleSubmit}
    />
  );
}
