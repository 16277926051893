import * as React from 'react';
import dayjs from 'dayjs';
import { query } from '@rexlabs/model-generator';

import ROUTES from 'routes/app';
import { Card } from 'view/components/card';
import { ListTable, TabbedTable } from 'view/components/table';
import { ListScreen } from 'view/components/list-screen/list-screen';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { useAppWideFilterContext } from 'src/modules/app-wide-filters/contexts/app-wide-filter-context';
import { useMaintenanceColumns } from '../hooks/use-maintenance-columns';

import { useGetCreateMaintenanceAction } from '../hooks/action-declarations/use-get-create-maintenance-action';
import { useGetMaintenanceActions } from '../hooks/action-menu-items/use-get-maintenance-actions';
import { tasksModel } from '../../common/models/tasks-model';
import { TaskType } from '../../common/types/TaskType';
import { TaskTableEmptyState } from '../../common/components/task-table-empty-state';
import { initialHiddenColumns } from '../../common/utils/initial-hidden-columns';

const filterOpen = {
  field: 'closed_at',
  op: 'eq',
  value: 'null'
};

const filterClosed = {
  field: 'closed_at',
  op: 'neq',
  value: 'null'
};

export const maintenanceQuery = query`{
  ${tasksModel} {
    maintenanceDetails
    property {
      active_property_ownership {
        ownership {
          owners
        }
      }
      active_property_tenancy {
        tenancy
      }
    }
    managed_by
    created_by
  }
}`;

export function MaintenanceList() {
  const { appWideFilterKey } = useAppWideFilterContext();

  const getCreateMaintenanceAction = useGetCreateMaintenanceAction();

  const getMaintenanceActions = useGetMaintenanceActions();
  const getRowLinkProps = ({ item }) => {
    return {
      to: ROUTES.MAINTENANCE_TASKS_DETAILS,
      params: { maintenanceId: item.id }
    };
  };

  const { getSort, getFilters } = useTableFilters('tasks', {
    type_id: 'task_maintenance' as TaskType
  });

  const getActionMenuItems = React.useCallback(
    ({ item }) => getMaintenanceActions(item),
    [getMaintenanceActions]
  );

  const maintenanceColumns = useMaintenanceColumns();
  const columnDependency = JSON.stringify(maintenanceColumns);

  const tabs = React.useMemo(() => {
    const commonProps = {
      id: 'task_maintenance',
      columns: maintenanceColumns,
      getQuery: () => maintenanceQuery,
      Table: ListTable,
      getRowLinkProps,
      getActionMenuItems,
      getFilters,
      getSort,
      forcedGlobalFilter: [
        {
          field: 'type_id',
          op: 'eq',
          value: { value: 'task_maintenance', label: 'Maintenance' }
        }
      ],
      shouldUseAppWideFilter: true,
      initialHiddenColumns: initialHiddenColumns
    };

    const now = dayjs().format('YYYY-MM-DD');

    return [
      {
        ...commonProps,
        name: 'open',
        label: 'Open',
        Empty: () => <TaskTableEmptyState label='Open' />,
        forcedGlobalFilter: [...commonProps.forcedGlobalFilter, filterOpen]
      },
      {
        ...commonProps,
        name: 'follow_up',
        label: 'Follow up',
        Empty: () => <TaskTableEmptyState label='Follow up' />,
        forcedGlobalFilter: [
          ...commonProps.forcedGlobalFilter,
          filterOpen,
          {
            field: 'follow_up_date',
            op: 'lte',
            value: now
          }
        ]
      },
      {
        ...commonProps,
        name: 'due',
        label: 'Due',
        Empty: () => <TaskTableEmptyState label='Due' />,
        forcedGlobalFilter: [
          ...commonProps.forcedGlobalFilter,
          filterOpen,
          {
            field: 'due_date',
            op: 'eq',
            value: now
          }
        ]
      },
      {
        ...commonProps,
        name: 'overdue',
        label: 'Overdue',
        Empty: () => <TaskTableEmptyState label='Overdue' />,
        forcedGlobalFilter: [
          ...commonProps.forcedGlobalFilter,
          filterOpen,
          {
            field: 'due_date',
            op: 'lt',
            value: now
          }
        ]
      },
      {
        ...commonProps,
        name: 'closed',
        label: 'Closed',
        Empty: () => <TaskTableEmptyState label='Closed' />,
        forcedGlobalFilter: [...commonProps.forcedGlobalFilter, filterClosed]
      }
    ];
  }, [columnDependency, getFilters]);

  return (
    <ListScreen
      privilege={'tasks.maintenances.read'}
      key={appWideFilterKey}
      title='Maintenance'
      recordType='task_maintenance'
      actions={[getCreateMaintenanceAction()]}
    >
      <Card>
        <TabbedTable tabs={tabs} />
      </Card>
    </ListScreen>
  );
}
