import React from 'react';
import { ErrorScreen } from '@rexlabs/error-boundary/lib/error-screen';

const defaultTitle = "You don't have permission to access this page";
const defaultMessage = (
  <>
    <p>
      You don&apos;t have permission to access this page. If you think you
      should have access, please contact your system administrator.
    </p>
    <p>
      Return to the Dashboard or use the global search to find your way back to
      familiar territory.
    </p>
  </>
);

export interface UnauthorizedScreenProps {
  title?: string;
  message?: React.ReactNode;
}

export function UnauthorizedScreen({
  title,
  message
}: UnauthorizedScreenProps) {
  return (
    <ErrorScreen
      status='403 — Unauthorized'
      title={<span style={{ lineHeight: 1 }}>{title || defaultTitle}</span>}
      message={message || defaultMessage}
    />
  );
}
