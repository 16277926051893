import React from 'react';

import Box from '@rexlabs/box';
import { Body } from '@rexlabs/text';

import ArrowDownFilledIcon from 'view/components/icons/arrow-down';

export function ArrowDownLabelButton({
  label,
  ref,
  ...props
}: {
  label: React.ReactNode;
  ref?: React.Ref<HTMLDivElement>;
}) {
  return (
    <Box
      {...props}
      flexDirection='row'
      sx='.4rem'
      alignItems='center'
      justifyContent='flex-end'
      style={{ cursor: 'pointer' }}
      ref={ref}
    >
      <Body as='span'>{label}</Body>
      <ArrowDownFilledIcon style={{ height: '1.2rem', width: 'auto' }} />
    </Box>
  );
}
