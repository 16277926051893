import { ChannelTypeId } from 'src/modules/communications/common/types/channel';
import { upperFirst } from 'lodash';

export const getLabelForChannelTypeId = (channelType: ChannelTypeId) => {
  if (channelType === 'sms') {
    return 'SMS';
  }

  return upperFirst(channelType);
};
