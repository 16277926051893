import {
  RequestPipelineRequestWithId,
  postRequestPipelineRequests
} from 'utils/api/post-request-pipeline';

import { TaskCreateRequest } from '../types/TaskCreateRequest';
import { TaskType } from '../types/TaskType';
import { Task } from '../types/Task';
import { ChecklistTemplate } from '../../settings/types/ChecklistTemplate';
import { TodoFormData } from '../../todos/types/TodoFormData';
import { MaintenanceFormData } from '../../maintenance/types/MaintenanceFormData';
import { InspectionFormData } from '../../inspections/types/InspectionFormData';
import { LeaseReviewFormData } from '../../lease-review/types/LeaseReviewFormData';
import { MoveInFormData } from '../../move-in/types/MoveInFormData';
import { MoveOutFormData } from '../../move-out/types/MoveOutFormData';
import { WorkOrderFormData } from '../../work-orders/types/WorkOrderFormData';
import { CustomTaskFormData } from '../../custom/types/CustomTaskFormData';

type TaskForm<TType extends TaskType> = TType extends 'todo'
  ? TodoFormData
  : TType extends 'task_work_order'
  ? WorkOrderFormData
  : TType extends 'task_maintenance'
  ? MaintenanceFormData
  : TType extends 'task_inspection'
  ? InspectionFormData
  : TType extends 'task_lease_review'
  ? LeaseReviewFormData
  : TType extends 'task_move_in'
  ? MoveInFormData
  : TType extends 'task_move_out'
  ? MoveOutFormData
  : TType extends 'task_custom_task'
  ? CustomTaskFormData
  : never;

// This function takes the values from a create task form, creates a pipeline request,
// then returns the created task.
export async function createTaskAndLinkChecklists<TType extends TaskType>(
  values: TaskForm<TType>,
  formMapper: (
    values: TaskForm<TType>
  ) => Partial<TaskCreateRequest> | Promise<Partial<TaskCreateRequest>>
): Promise<Task<TType>> {
  const dataToSubmit = await formMapper(values);
  const taskRequest: RequestPipelineRequestWithId = {
    id: 'task',
    path: '/api/v1/tasks',
    method: 'POST',
    json: dataToSubmit
  };
  const checklistRequests: RequestPipelineRequestWithId[] = (
    values?.checklists || []
  ).map((checklist) => ({
    id: `checklist-${checklist.id}`,
    path: `/api/v1/tasks/checklist-templates/${checklist.id}/instantiate`,
    method: 'POST',
    json: {
      task: {
        id: '{{$.task.id}}'
      }
    }
  }));

  const pipelineRequests = [taskRequest, ...checklistRequests];

  const { data } = await postRequestPipelineRequests<
    typeof pipelineRequests,
    Task<TType> | ChecklistTemplate
  >(pipelineRequests);

  const taskData = Object.values(data).filter((item) => {
    return 'type' in item;
  })[0] as Task<TType>;

  return taskData;
}
