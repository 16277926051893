import React from 'react';
import { useConfirmationDialog, useErrorDialog } from '@rexlabs/dialog';
import { useMutation, useQueryClient } from 'react-query';
import { useToast } from 'view/components/@luna/notifications/toast';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { api } from 'utils/api/api-client';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'routes/app';
import { MetricWriteRule } from 'src/modules/metric-write-rules/types/MetricWriteRule';
import { METRIC_WRITE_RULES_QUERY_KEY } from 'src/modules/metric-write-rules/hooks/use-metric-write-rules';

type UseGetDeleteMetricWriteRuleActionArgs = {
  redirectToListScreen?: boolean;
};

export function useGetDeleteMetricWriteRuleAction({
  redirectToListScreen
}: UseGetDeleteMetricWriteRuleActionArgs = {}) {
  const { open: openConfirmationDialog } = useConfirmationDialog();
  const { open: openErrorDialog } = useErrorDialog();

  const { addToast } = useToast();
  const queryClient = useQueryClient();

  const deleteMetricWriteRuleMutation = useMutation(
    (id: string) => api.delete(`/metric-write-rules/${id}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(METRIC_WRITE_RULES_QUERY_KEY);
      }
    }
  );

  return (metricWriteRule: MetricWriteRule): ActionDeclaration => {
    return {
      id: 'delete-metric-write-rule',
      group: 'delete',
      label: 'Delete',
      intent: 'danger',
      handleAction: () =>
        openConfirmationDialog({
          title: 'Delete Metric Write Rule',
          destructive: true,
          size: 's',
          confirmText: 'Delete',
          message: (
            <div>
              Are you sure you wish to delete the metric write rule{' '}
              <strong>{metricWriteRule.name}</strong>?
            </div>
          ),
          onConfirm: async () => {
            try {
              await deleteMetricWriteRuleMutation.mutateAsync(
                metricWriteRule.id
              );

              addToast({
                type: 'success',
                description: (
                  <>
                    <b>{metricWriteRule.name}</b> has been deleted
                  </>
                )
              });

              if (redirectToListScreen) {
                push(ROUTES.METRIC_WRITE_RULE_LIST);
              }
            } catch (e) {
              openErrorDialog(e);
            }
          }
        })
    };
  };
}
