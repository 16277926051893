import { Forms } from '@rexlabs/form';
import { useToken } from '@rexlabs/styling';
import TabBar from '@rexlabs/tabs/module/tab-bar';
import React from 'react';
import { Banner } from 'src/modules/common/components/banner';
import { NoteCountBadge } from 'src/modules/note/components/note-count-badge';
import InfoCircleIcon from 'view/components/icons/info';
import { BlockConfig } from 'view/components/record-screen/types';
import { FLAGS } from 'utils/feature-flags';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { InvoiceDialogType } from '../types/invoice-block';

export const invoiceTabs: BlockConfig<
  any,
  {
    activeTab: string;
    setActiveTab: (name: string) => void;
    isFirstChange: boolean;
    handleFirstChange: () => void;
    dialogType: InvoiceDialogType;
  }
> = {
  id: 'tabs',
  Edit: ({ blockProps, forms }) => {
    const { hasFeature } = useFeatureFlags();
    const hasQuickInvoicesFeature = hasFeature(FLAGS.QUICK_INVOICES);

    const {
      activeTab,
      setActiveTab,
      isFirstChange,
      handleFirstChange,
      dialogType
    } = blockProps!;

    const showReimbursementTab =
      dialogType === 'invoice' || dialogType === 'reimbursement';

    const hasInvoiceError = isFormsValid(forms!, [
      'invoice-details',
      'line-items'
    ]);

    const hasReimbursementError = isFormsValid(forms!, [
      'reimbursement-details',
      'reimbursement-line-items'
    ]);

    const hasError = showReimbursementTab
      ? hasReimbursementError || hasInvoiceError
      : hasInvoiceError;

    const uploadedBillId =
      forms?.['invoice-details']?.initialValues?.uploaded_bill?.id;

    return (
      <>
        {hasError && (
          <Banner intent='danger' align='start' Icon={InfoCircleIcon}>
            <div>
              There are errors in the{' '}
              {getErrorMessage(hasInvoiceError, hasReimbursementError)} details
            </div>
          </Banner>
        )}

        <TabBar
          // re-render so active tab is correctly rendered when there is an icon
          key={`${hasInvoiceError}-${hasReimbursementError}`}
          activeTab={activeTab}
          onChange={({ name }) => {
            setActiveTab(name);

            if (isFirstChange) return;
            handleFirstChange();

            if (showReimbursementTab) {
              forms!['reimbursement-details'].setFieldValue(
                'reimbursed_bill_priority',
                forms!['invoice-details'].values.bill_priority
              );

              forms!['reimbursement-details'].setFieldValue(
                'reimbursed_do_not_pay_before_date',
                forms!['invoice-details'].values.do_not_pay_before_date
              );

              forms!['reimbursement-details'].setFieldValue(
                'reimbursed_bank_account',
                forms!['invoice-details'].values.bank_account
              );
            }
          }}
          items={[
            {
              label: 'Invoice',
              name: 'invoice',
              IconStart: hasInvoiceError ? ErrorIcon : null
            },
            ...(showReimbursementTab
              ? [
                  {
                    label: 'Reimbursement invoice',
                    name: 'reimbursement',
                    IconStart: hasReimbursementError ? ErrorIcon : null
                  }
                ]
              : []),
            ...(hasQuickInvoicesFeature && uploadedBillId
              ? [
                  {
                    name: 'notes',
                    label: 'Notes',
                    IconEnd: (props) => (
                      <NoteCountBadge
                        filter={[
                          {
                            field: 'uploaded_bill_id',
                            op: 'eq',
                            value: uploadedBillId
                          }
                        ]}
                        {...props}
                      />
                    )
                  }
                ]
              : [])
          ]}
        />
      </>
    );
  }
};

function ErrorIcon() {
  const token = useToken();

  return <InfoCircleIcon color={token('palette.red.900')} />;
}

function isFormsValid(forms: Forms, formNames: Array<string>) {
  return formNames.some((formName) => {
    return !forms?.[formName]?.isValid;
  });
}

// @ts-ignore
const formatter = new Intl.ListFormat('en', {
  style: 'long',
  type: 'conjunction'
});

function getErrorMessage(hasInvoiceError, hasReimbursementError) {
  const errors: Array<string> = [];

  if (hasInvoiceError) {
    errors.push('invoice');
  }

  if (hasReimbursementError) {
    errors.push('reimbursement invoice');
  }

  const errorArrayJsx = formatter.formatToParts(errors).map((part) => {
    if (part.type === 'literal') return part.value;

    return <b key={part.value}>{part.value}</b>;
  });

  return errorArrayJsx;
}
