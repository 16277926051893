import { Forms } from '@rexlabs/form';
import { parseUploadedFileMeta } from 'utils/files';
import { Property } from 'src/modules/properties/types/property-types';
import { getSearchResultItemFromObjectAndModel } from 'src/modules/common/utils/search-result-items/get-search-result-items-from-object-and-model';
import { ownershipsModel } from 'data/models/entities/ownerships';
import { MaintenanceTask } from '../../maintenance/types/MaintenanceTask';
import { QuoteTask } from '../../quotes/types/QuoteTask';
import { getSearchResultItemFromManagedBy } from '../../common/utils/get-search-result-item-from-managed-by';

export function getCreateWorkOrderChangeHandlers(forms?: Forms) {
  const values = forms?.['details'].values;
  const workOrderDetailsValues = forms?.['work-order-details'].values;
  const isSupplierWorkOrder = values?.details.work_done_by_type === 'supplier';

  function handlePropertyChange({ target }) {
    if (!forms) {
      return;
    }

    const property: Property = target.value;
    if (values?.managed_by?.id) {
      forms?.['details'].setFieldValue?.('managed_by', null);
    }
    if (values?.parent_task?.id) {
      forms?.['details'].setFieldValue?.('parent_task', null);
      handleRelatedTaskChange({ target: { value: null } });
    }

    const ownerId = workOrderDetailsValues?.details.owner?.id;
    if (ownerId && values?.details.work_done_by_type === 'owner') {
      const owners = property?.active_property_ownership?.ownership?.owners;

      // reset the owner field if the owner is not part of the active property ownership
      if (owners && owners.find((owner) => owner.contact.id === ownerId)) {
        return;
      }
      forms?.['work-order-details'].setFieldValue?.('details.owner', null);
    }

    // if it is a supplier work order, reset the 'bill to' field
    if (isSupplierWorkOrder) {
      forms?.['work-order-details'].setFieldValue?.(
        'details.bill_to',
        property?.active_property_ownership?.ownership
          ? getSearchResultItemFromObjectAndModel(
              property?.active_property_ownership?.ownership,
              ownershipsModel
            )
          : null
      );
    }
  }

  function setValuesFromQuoteTask({
    quoteTask
  }: {
    quoteTask: QuoteTask | null;
  }) {
    if (!forms) {
      return;
    }

    const workOrderDetailsForm = forms?.['work-order-details'];

    if (!workOrderDetailsForm) {
      return;
    }
    workOrderDetailsForm.setFieldValue?.(
      'details.amount',
      quoteTask?.details?.amount || null
    );
    workOrderDetailsForm.setFieldValue?.(
      'details.supplier',
      quoteTask?.details?.supplier || null
    );
  }

  function handleRelatedTaskChange({ target }) {
    if (!forms) {
      return;
    }

    const parentTask: MaintenanceTask = target.value;

    const detailsBlockId = 'details';
    const imagesBlockId = 'related-images';
    const workOrderDetailsBlockId = 'work-order-details';
    const priorityBlockId = 'priority';

    const mapper = [
      {
        blockId: detailsBlockId,
        field: 'property',
        value: parentTask?.property || null
      },
      {
        blockId: detailsBlockId,
        field: 'managed_by',
        value: getSearchResultItemFromManagedBy(parentTask?.managed_by)
      },
      {
        blockId: detailsBlockId,
        field: 'summary',
        value: parentTask?.summary
          ? `Work order for ${parentTask?.summary}`
          : null
      },
      {
        blockId: detailsBlockId,
        field: 'description',
        value: parentTask?.description || null
      },
      // only reset the associated quote field if it is a supplier work order
      ...(isSupplierWorkOrder
        ? [
            {
              blockId: detailsBlockId,
              field: 'details.quote',
              value: null
            }
          ]
        : []),
      {
        blockId: imagesBlockId,
        field: 'details.images',
        value: parseUploadedFileMeta(parentTask?.details?.images || [])
      },
      ...(isSupplierWorkOrder
        ? [
            {
              blockId: workOrderDetailsBlockId,
              field: 'details.access_provided_by',
              value: parentTask?.details?.access_provided_by || null
            }
          ]
        : []),
      {
        blockId: priorityBlockId,
        field: 'priority',
        value: parentTask?.priority || { id: 'normal', label: 'Normal' }
      }
    ];

    // if it is a supplier work order and there is currently
    // an associated quote selected, clear all values that were
    // from the associated quote
    isSupplierWorkOrder && setValuesFromQuoteTask({ quoteTask: null });

    // set values from the related task
    mapper.forEach((item) => {
      forms?.[item.blockId]?.setFieldValue(item.field, item.value);
    });
  }

  function handleQuoteChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!forms) {
      return;
    }

    const quoteTask = (event.target.value as unknown) as QuoteTask;
    const parentTask = quoteTask?.parent_task as MaintenanceTask;

    // if the parent task of the quote is different to currently
    // selected parent task set the parent task to the new parent task
    if (parentTask?.id !== values?.parent_task?.id) {
      forms?.['details'].setFieldValue?.('parent_task', parentTask || null);
      handleRelatedTaskChange({ target: { value: parentTask } });
    }

    // set the values which should be inherited directly from the quote
    setValuesFromQuoteTask({ quoteTask });
  }

  return {
    handlePropertyChange,
    handleRelatedTaskChange,
    handleQuoteChange
  };
}
