import { useDialog } from '@rexlabs/dialog';
import { TaskType } from '../../common/types/TaskType';
import { SmartChecklistTemplateDialog } from '../dialogs/smart-checklist-template';
import { useCreateChecklistTemplateAction } from './action-declarations/checklist-template/use-create-checklist-template-action';
import { useGetIsSmartChecklist } from './use-get-is-smart-checklist';

export function useOpenCreateChecklistTemplateDialog({
  type,
  label,
  customTaskTypeId
}: {
  type: TaskType;
  label: string;
  customTaskTypeId?: string;
}) {
  const checklist = useCreateChecklistTemplateAction(type);
  const smartChecklistDialog = useDialog(SmartChecklistTemplateDialog);
  const getIsSmartChecklist = useGetIsSmartChecklist();

  return () => {
    const title = `Create ${label.toLowerCase()} checklist`;

    if (getIsSmartChecklist(type)) {
      return smartChecklistDialog.open({
        title,
        taskTypeId: type,
        customTaskTypeId
      });
    }

    return checklist.open({
      title,
      onSubmit: checklist.onSubmit,
      taskTypeId: type,
      initialValues: {
        checklist_template_items: []
      }
    });
  };
}
