import * as React from 'react';
import { BankStatementTransactionImport } from '../types/bank-statement-transaction-import';

interface BatchReceiptingHeaderValuesContextProps {
  transactionFiles: BankStatementTransactionImport | null;
}

const BatchReceiptingHeaderValuesContext = React.createContext<BatchReceiptingHeaderValuesContextProps>(
  {
    transactionFiles: null
  }
);

export const BatchReceiptingHeaderValuesProvider = (
  props: React.PropsWithChildren<BatchReceiptingHeaderValuesContextProps>
) => {
  const { children, transactionFiles } = props;

  return (
    <BatchReceiptingHeaderValuesContext.Provider value={{ transactionFiles }}>
      {children}
    </BatchReceiptingHeaderValuesContext.Provider>
  );
};

export const useBatchReceiptingHeaderValues = () => {
  return React.useContext(BatchReceiptingHeaderValuesContext);
};
