import { useModelActions } from '@rexlabs/model-generator';

import {
  StatusChangeActionArgs,
  useStatusChangeAction
} from 'src/modules/common/actions/status/use-status-change-action';
import { invokeActionDeclaration } from 'src/modules/common/actions/utils/invoke-action-declaration';
import { financialsUploadedBillsModel } from 'src/modules/bill-processing/models/uploaded-bills';
import { InitialInvoiceValues } from 'src/modules/bill-processing/types/InitialInvoiceValues';
import { useGetCreateDraftInvoiceAction } from 'src/modules/bill-processing/hooks/action-declarations/use-get-create-draft-invoice-action';
import { WorkOrderTask } from 'src/modules/tasks/work-orders/types/WorkOrderTask';
import { WorkOrderStatus } from 'src/modules/tasks/work-orders/types/WorkOrderStatus';
import { MaintenanceTaskStatus } from 'src/modules/tasks/maintenance/types/MaintenanceStatus';
import { contactsModel } from 'src/modules/contacts/models/contacts';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { getSearchResultItemFromObjectAndModel } from 'src/modules/common/utils/search-result-items/get-search-result-items-from-object-and-model';
import { getWorkOrderStatusChangeTransformPayload } from 'src/modules/tasks/work-orders/utils/get-work-order-status-change-transform-payload';

import { SearchResultItem } from 'utils/api/get-search-results';

import { tasksModel } from '../../models/tasks-model';
import { mapTaskToInvoiceValues } from '../../mappers/map-task-to-invoice-values';
import { MaintenanceTask } from '../../../maintenance/types/MaintenanceTask';

const closeWorkOrderStatus = 'bill_added' as WorkOrderStatus;
const closeMaintenanceTaskStatus = 'work_finished' as MaintenanceTaskStatus;

type NewStatus<
  T extends DraftInvoiceRelatedRecordType
> = T extends MaintenanceTask
  ? typeof closeMaintenanceTaskStatus
  : T extends WorkOrderTask
  ? typeof closeWorkOrderStatus
  : never;

type DraftInvoiceRelatedRecordType = MaintenanceTask | WorkOrderTask;

export function useGetCreateDraftInvoiceFromTaskAction() {
  const getStatusChangeAction = useStatusChangeAction(tasksModel);
  const { refreshLists } = useModelActions(financialsUploadedBillsModel);
  const getCreateDraftInvoiceAction = useGetCreateDraftInvoiceAction();

  return <T extends DraftInvoiceRelatedRecordType>({
    task,
    newStatus
  }: {
    task?: DraftInvoiceRelatedRecordType;
    newStatus: NewStatus<T>;
  }) => {
    const { type } = task || {};
    const initialInvoiceValues: InitialInvoiceValues = mapTaskToInvoiceValues(
      task
    );

    const transformPayload =
      task?.type.id === 'task_work_order'
        ? getWorkOrderStatusChangeTransformPayload(task as WorkOrderTask)
        : (status) => {
            return {
              type,
              details: {
                status: {
                  id: status
                }
              }
            };
          };

    const afterBillsUpload = task
      ? () => {
          invokeActionDeclaration<StatusChangeActionArgs<typeof newStatus>[]>(
            getStatusChangeAction,
            {
              record: task,
              status: newStatus,
              dialogOptions: {
                type: 'none',
                afterAction: () => refreshLists()
              },
              generalOptions: { avoidListRefresh: true },
              transformPayload
            }
          );
        }
      : undefined;

    return getCreateDraftInvoiceAction({
      initialInvoiceValues,
      afterBillsUpload,
      invoiceSuggestedContacts: {
        payableTo:
          task?.type.id === 'task_maintenance'
            ? getPayableToContactsForMaintenanceTask(task as MaintenanceTask)
            : []
      }
    });
  };
}

function getPayableToContactsForMaintenanceTask(
  task?: MaintenanceTask
): SearchResultItem<Contact>[] {
  if (!task) {
    return [];
  }

  // Get all work order tasks from the maintenance task
  const workOrderTasks = (task?.child_tasks?.data || []).filter(
    (childTask) => childTask.type.id === 'task_work_order'
  ) as WorkOrderTask[];

  // Get contacts from the work order tasks
  const contacts =
    workOrderTasks.map((workOrderTask) =>
      getSearchResultItemFromObjectAndModel(
        workOrderTask.details?.work_done_by,
        contactsModel
      )
    ) ?? [];
  // Filter out null contacts
  return contacts.filter(
    (contact) => contact !== null
  ) as SearchResultItem<Contact>[];
}
