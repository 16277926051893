import { paymentHistoryModel } from 'src/modules/payment-history/models/payment-history-model';
import { query } from '@rexlabs/model-generator';

export function paymentHistoryQuery() {
  return query`{
    ${paymentHistoryModel} {
      id
    }
  }`;
}
