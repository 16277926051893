import React from 'react';
import { capitalize, get } from 'lodash';

import { TextArea, TextInput } from '@rexlabs/text-input';
import { Field } from '@rexlabs/form';
import { Checkbox } from '@rexlabs/checkbox';

import { ValueListItem } from 'utils/normaliser/value-list-item';

import { ComplianceType } from 'src/modules/compliance/common/models/compliance-types';
import { useSettings } from 'src/modules/settings/hooks/useSettings';

import { PropertyComplianceCategory } from 'data/models/entities/settings/property-compliance-categories';

import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { PropertyComplianceObjectValueSelect } from 'view/components/inputs/selects/property-compliance-object-value';
import { FrequencySplitInput } from 'view/components/inputs/frequency-input/frequency-split-input';
import { PropertyComplianceCategorySelect } from 'view/components/inputs/selects/v4-selects/property-compliance-category';

export type PropertyComplianceRequirementDetailsBlockFormValues = ComplianceType & {
  include_in_categories: PropertyComplianceCategory[];
  is_default_task_creation: boolean;
  task_expiration_frequency: ValueListItem;
};

export const propertyComplianceRequirementDetailsBlock: BlockConfig<
  any,
  any,
  PropertyComplianceRequirementDetailsBlockFormValues
> = {
  id: 'details',
  title: 'Details',
  Edit: ({ values, setFieldValue }) => {
    const settings = useSettings();
    const defaultTaskCreationSettings = settings?.['property-compliance-task'];
    return (
      <Grid columns={1}>
        <Grid columns={2}>
          <Field name='label' label='Name' Input={TextInput} />
          <Field
            id='category'
            name='category'
            label='Type'
            Input={PropertyComplianceObjectValueSelect}
          />
        </Grid>
        <Field
          name='description'
          label='Description'
          Input={TextArea}
          inputProps={{ autoResize: false }}
        />

        <Field
          id='include_in_categories'
          name='include_in_categories'
          label='Include in categories'
          Input={PropertyComplianceCategorySelect}
          inputProps={{ multi: true }}
        />

        <Field
          id='is_default_task_creation'
          name='is_default_task_creation'
          onChange={(event) => {
            const isChecked = event.target.checked;

            if (!isChecked) return;

            setFieldValue?.('task_expiration_frequency', {
              count: defaultTaskCreationSettings.count,
              unit: {
                id: defaultTaskCreationSettings.unit,
                label: capitalize(defaultTaskCreationSettings.unit)
              }
            });
          }}
          inputProps={{
            label: 'Use default settings for task creation'
          }}
          optional={false}
          Input={Checkbox}
        />
        <Field
          name='task_expiration_frequency'
          label='Create task'
          Input={FrequencySplitInput}
          optional={false}
          inputProps={{
            inputLabel: 'before the requirement expires',
            disabled: get(values, 'is_default_task_creation')
          }}
        />
      </Grid>
    );
  },
  validate: {
    definitions: {
      label: { name: 'name', rules: 'required' },
      category: { name: 'type', rules: 'required' },
      task_expiration_frequency: {
        name: 'create task',
        rules: 'required'
      }
    }
  }
};
