import { RecordObject } from 'data/models/types';
import { truncate } from 'lodash';
import { formatDate } from 'utils/dates/format';

/**
 * The label for the record, commonly used in the tables as they are shorter than the title
 */
export function getRecordLabel(recordObject: RecordObject): string {
  switch (recordObject.type) {
    case 'role':
      return recordObject.object.name;
    case 'contact':
    case 'property':
    case 'ownership':
    case 'property_tenancy':
    case 'property_ownership':
    case 'tenancy':
      return recordObject.object?.display_name;

    case 'message_template':
      return recordObject.object.name;

    case 'message': {
      const channel = recordObject.object.channels?.[0];
      switch (recordObject.object.forced_channel_type?.id) {
        case 'email':
          return channel.resolved_email_subject;
        case 'sms':
          return 'SMS';
        case 'letter':
          return 'Letter';
        default:
          return recordObject.type;
      }
    }

    case 'channel_message_recipient': {
      const isSMS =
        recordObject.object.channel_message?.message.channels?.[0]?.channel_type
          ?.id === 'sms';

      return isSMS
        ? 'SMS'
        : recordObject.object.channel_message?.message.channels?.[0]
            .resolved_email_subject ?? '';
    }

    case 'invoice':
    case 'trust_journal_entry':
    case 'bank_withdrawal':
    case 'bank_deposit':
    case 'credit_note':
    case 'accounting_journal_entry':
    case 'security_deposit':
      return recordObject.object.record_reference;

    case 'agency_fee':
    case 'bank_account':
    case 'chart_of_accounts_account':
    case 'trust_ledger':
    case 'communication_template':
    case 'email_layout':
    case 'trust_account':
    case 'letter_layout':
    case 'author':
    case 'document':
    case 'property_area_type':
    case 'property_area':
    case 'service_package':
    case 'prepayment_bucket':
    case 'custom_validation':
    case 'metric_write_rule':
    case 'commission_template':
      return recordObject.object.name;

    case 'uploaded_bill':
      return recordObject.object.file?.name ?? '';

    case 'task':
    case 'todo':
    case 'task_maintenance':
    case 'task_lease_review':
    case 'task_move_in':
    case 'task_move_out':
    case 'task_property_compliance':
    case 'task_supplier_compliance':
    case 'task_work_order':
    case 'task_quote':
    case 'task_custom_task':
      return recordObject?.object?.summary;

    case 'task_inspection':
      return recordObject?.object?.details?.type?.label ?? '';

    case 'task_arrears':
      return (
        recordObject?.object?.details?.property_tenancy?.tenancy
          ?.display_name ?? ''
      );

    case 'user':
      return (
        recordObject.object?.display_name ??
        recordObject.object?.contact?.display_name ??
        ''
      );

    case 'profile_settings':
    case 'universal_user':
      return `${recordObject.object.given_name} ${recordObject.object.family_name}`;

    case 'reconciliation':
      return `${recordObject.object.record_reference} (${formatDate(
        recordObject.object.created_at!
      )})`;

    // TODO: need to have a standardised display name for this. likely need to change it to `label` or something to avoid collision with the stricter `display_name` concept
    case 'statement':
      return recordObject.object.display_name;
    case 'disbursement':
      return recordObject.object.object?.label;

    case 'checklist':
    case 'portfolio':
    case 'portfolio_role':
    case 'supplier_compliance_category':
    case 'property_compliance_category':
    case 'property_compliance_type':
    case 'checklist_template':
      return recordObject.object.label;
    case 'note':
      return truncate(recordObject.object.content, { length: 50 });
    case 'deferred_fee_line_item':
      return recordObject.object.description;
    case 'user_group':
      return recordObject.object.label;

    case 'custom_report':
      return recordObject.object.label;
    default:
      console.warn(`There is no label defined for ${recordObject.type}`);
      return recordObject.object.id;
  }
}
