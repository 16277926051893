import { query } from '@rexlabs/model-generator';
import React, { useMemo } from 'react';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';

import { ListTable, TabbedTable } from 'view/components/table';
import { Tab } from 'view/components/table/tabbed';
import { useTableFilters } from 'view/hooks/use-table-filters';

import { useAppWideFilterContext } from 'src/modules/app-wide-filters/contexts/app-wide-filter-context';
import { tasksModel } from '../../common/models/tasks-model';
import { getTaskTabbedTableTabs } from '../../common/utils/get-task-tabbed-table-tabs';
import { useGetQuoteActions } from '../hooks/action-menu-items/use-get-quote-actions';
import { QUOTE_ROUTES } from '../routes';
import { useQuoteColumns } from '../utils/quote-columns';
import { TaskType } from '../../common/types/TaskType';
import { initialHiddenColumns } from '../../common/utils/initial-hidden-columns';

const quoteQuery = query`{
    ${tasksModel} {
      id
      managed_by
      property {
        active_property_ownership {
          ownership {
            owners
          }
        }
        active_property_tenancy {
            tenancy
        }
      }
      parent_task
      quoteDetails
      created_by
    }
  }`;

export function QuotesTabbedTable({
  shouldUseAppWideFilter = true
}: { shouldUseAppWideFilter?: boolean } = {}) {
  const { appWideFilterKey } = useAppWideFilterContext();

  const getQuoteActions = useGetQuoteActions();

  const getRowLinkProps = ({ item }) => {
    return {
      to: QUOTE_ROUTES.QUOTE_DETAILS,
      params: { quoteId: item.id }
    };
  };

  const { getSort, getFilters } = useTableFilters('tasks', {
    type_id: 'task_quote' as TaskType
  });

  const getActionMenuItems = React.useCallback(
    ({ item }) => {
      const actions = getQuoteActions(item);
      return transformActionDeclarationsToActionMenuItems(actions);
    },
    [getQuoteActions]
  );

  const quoteColumns = useQuoteColumns();

  const columnDependency = JSON.stringify(quoteColumns);

  const commonTabProps = useMemo(
    () => ({
      id: 'quotes',
      Table: ListTable,
      forcedGlobalFilter: [
        {
          field: 'type_id',
          op: 'eq',
          value: { value: 'task_quote', label: 'Quote' }
        }
      ],
      columns: quoteColumns,
      getRowLinkProps,
      getActionMenuItems,
      getFilters,
      getSort,
      getQuery: () => quoteQuery,
      shouldUseAppWideFilter: shouldUseAppWideFilter,
      initialHiddenColumns: initialHiddenColumns
    }),
    [columnDependency, getFilters]
  );

  const tabs = useMemo<Tab[]>(() => getTaskTabbedTableTabs(commonTabProps), [
    commonTabProps
  ]);

  return (
    <TabbedTable
      tabs={tabs}
      key={shouldUseAppWideFilter ? appWideFilterKey : undefined}
    />
  );
}
