import { useWhereabouts } from '@rexlabs/whereabouts';
import * as React from 'react';

import { Card } from 'view/components/card';
import { ListScreen } from 'view/components/list-screen/list-screen';
import { pluralize } from 'utils/formatters';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import LoadingState from 'view/components/states/loading';
import { useCustomTaskTemplates } from '../../settings/hooks/custom-task-templates/use-custom-task-templates';
import { CustomTaskTemplate } from '../../settings/types/CustomTaskTemplate';
import { CustomTasksTabbedTable } from '../components/custom-tasks-tabbed-table';
import { useGetCreateCustomTaskAction } from '../hooks/use-get-create-custom-task-action';

const TYPE_PATTERN = /type=(.*)/;

export function CustomTasksList() {
  const [
    customTaskTemplate,
    setCustomTaskTemplate
  ] = React.useState<CustomTaskTemplate | null>(null);
  const getCreateCustomTaskAction = useGetCreateCustomTaskAction({
    customTaskTemplate
  });
  const { hash } = useWhereabouts();
  const customTaskTemplatesQuery = useCustomTaskTemplates();

  React.useEffect(() => {
    if (hash) {
      // hash is like 'type=id'. extract id, which is a uuid, only if hash matches pattern
      const match = hash.match(TYPE_PATTERN);
      const id = match && match[1];
      if (id) {
        const template = customTaskTemplatesQuery.data?.find(
          (template) => template.id === id
        );
        if (template) {
          setCustomTaskTemplate(template);
        }
      }
    } else {
      setCustomTaskTemplate(null);
    }
  }, [customTaskTemplatesQuery.data, hash]);

  const title = customTaskTemplate
    ? pluralize(customTaskTemplate.label, 2) // two, because we want a plural
    : 'Custom tasks';

  // If there is a hash, but no custom task template, we are still loading... or have an invalid hash
  if (hash && hash.match(TYPE_PATTERN) && !customTaskTemplate) {
    return <LoadingState />;
  }

  return (
    <ListScreen
      privilege={'tasks.custom-tasks.read'}
      title={title}
      actions={
        [getCreateCustomTaskAction?.()].filter(
          Boolean
        ) as SingleActionDeclaration[]
      }
    >
      <Card>
        <CustomTasksTabbedTable customTaskTemplate={customTaskTemplate} />
      </Card>
    </ListScreen>
  );
}
