import { parseUploadedFileMeta } from 'utils/files';
import { FinancialEntity } from 'src/modules/financials/utils/financial-entity-action-group/use-get-financial-entity-actions';
import { SearchResultItem } from 'utils/api/get-search-results';
import { MaintenanceFormData } from '../types/MaintenanceFormData';
import { MaintenanceTask } from '../types/MaintenanceTask';
import { MaintenanceDetails } from '../types/MaintenanceDetails';
import { getSearchResultItemFromManagedBy } from '../../common/utils/get-search-result-item-from-managed-by';

const getFieldsFromDetails = (
  details: MaintenanceTask['details']
): Partial<MaintenanceFormData['details']> => {
  const { images, reported_by, ...fields } = details!;

  return {
    ...fields,
    attachments: parseUploadedFileMeta(images),
    reported_by: details?.reported_by
      ? getSearchResultItemFromDetails(reported_by)
      : null,
    issue_relates_to_appliance: !!details?.appliance
  };
};

export function mapMaintenanceTaskToMaintenanceFormData(
  task: MaintenanceTask
): MaintenanceFormData {
  const { details, property, managed_by, ...taskDetails } = task;

  return {
    ...taskDetails,
    property: property ?? null,
    managed_by: getSearchResultItemFromManagedBy(managed_by),
    details: {
      ...getFieldsFromDetails(details || {})
    }
  };
}

const getSearchResultItemFromDetails = (
  reportedBy: MaintenanceDetails['reported_by']
): SearchResultItem<FinancialEntity> => {
  return {
    id: reportedBy?.id || '',
    label: reportedBy?.display_name || '',
    type: {
      id: reportedBy?.id || '',
      label: reportedBy?.display_name || ''
    },
    record: reportedBy as FinancialEntity
  };
};
