import React from 'react';
import { lowerCase } from 'lodash';

import { Body } from '@rexlabs/text';
import { useModelActions } from '@rexlabs/model-generator';
import { useConfirmationDialog, useErrorDialog } from '@rexlabs/dialog';

import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { useRecordCreatedToast } from 'src/modules/common/toasts/hooks/use-record-created-toast';

import { api } from 'utils/api/api-client';

import { financialsInvoicesModel } from 'data/models/entities/financials/invoices';
import { Ownership } from 'data/models/entities/ownerships';

import { deferredFeeLineItemModel } from '../../models/financials-deferred-fee-line-items';

type GenerateSupplierInvoiceActionProps = {
  type: 'supplier_commission';
  folio: Contact;
};

type GenerateAgencyFeeActionProps = {
  type: 'agency_fee';
  folio: Ownership;
};

type GenerateDeferredFeeInvoiceActionProps =
  | GenerateSupplierInvoiceActionProps
  | GenerateAgencyFeeActionProps;

export function useGetGenerateDeferredFeeInvoiceAction() {
  const addCreatedToast = useRecordCreatedToast(financialsInvoicesModel);
  const { refreshLists } = useModelActions(deferredFeeLineItemModel);
  const errorDialog = useErrorDialog();
  const confirmationDialog = useConfirmationDialog();

  return ({
    folio,
    type
  }: GenerateDeferredFeeInvoiceActionProps): SingleActionDeclaration => ({
    label: 'Generate invoice',
    handleAction: () => {
      const typeLabel = lowerCase(type);
      confirmationDialog.open({
        title: 'Generate invoice',
        confirmText: 'Yes, generate invoice',
        message: (
          <>
            <Body>
              Generating an invoice will consolidate all unbilled{' '}
              {type === 'agency_fee' ? 'agency' : typeLabel} fees into a single
              invoice.
            </Body>
            <Body>
              Are you sure you want to proceed with generating{' '}
              {typeLabel[0] === 'a' ? 'an' : 'a'} {typeLabel} invoice for{' '}
              {folio.display_name}?
            </Body>
          </>
        ),
        onConfirm: async () => {
          try {
            const response = await api.post(
              'financials/deferred-fees/invoices',
              {
                folio: { id: folio.id, type: { id: folio.__record_type } },
                type: { id: type }
              }
            );

            addCreatedToast(response.data);

            await refreshLists();
          } catch (error) {
            errorDialog.open(error);
          }
        }
      });
    }
  });
}
