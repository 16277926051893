import { PercentageInput } from 'view/components/inputs/percentage-input';
import { Field } from '@rexlabs/form';
import React from 'react';
import { CentAmountInput } from 'view/components/inputs/cent-amount';
import {
  FLAT_FEE,
  FolioCommissionTemplateStructure,
  PERCENTAGE
} from '../types/FolioCommissionTemplate';

export const COMMISSION_INPUTS_NAME = {
  [FLAT_FEE]: 'commission_amount_currency',
  [PERCENTAGE]: 'commission_amount_percent'
};

export interface CommissionInputProps {
  structureType?: FolioCommissionTemplateStructure;
  label?: string;
}

export function CommissionInput({
  structureType,
  label = 'Commission'
}: CommissionInputProps) {
  return (
    <>
      <div
        style={
          structureType === 'flat_fee'
            ? { display: 'contents' }
            : { display: 'none' }
        }
      >
        <Field
          name={COMMISSION_INPUTS_NAME[FLAT_FEE]}
          label={label}
          Input={CentAmountInput}
          optional={false}
        />
      </div>

      <div
        style={
          structureType === 'percentage'
            ? { display: 'contents' }
            : { display: 'none' }
        }
      >
        <Field
          name={COMMISSION_INPUTS_NAME[PERCENTAGE]}
          label={label}
          Input={PercentageInput}
          optional={false}
        />
      </div>
    </>
  );
}
