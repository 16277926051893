import { propertyTenancyModel } from 'src/modules/property-tenancies/models/property-tenancy-model';
import { useStatusChangeAction } from 'src/modules/common/actions/status/use-status-change-action';
import {
  PropertyTenancy,
  PropertyTenancyStatusType
} from 'src/modules/property-tenancies/types/property-tenancy-types';
import { useConfirmationDialog } from '@rexlabs/dialog';
import { Body } from '@rexlabs/text';
import React from 'react';
import { invokeActionDeclaration } from 'src/modules/common/actions/utils/invoke-action-declaration';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { api } from 'utils/api/api-client';
import { PreviewData } from 'src/modules/properties/types/preview-data';
import { Banner } from 'src/modules/common/components/banner';
import InfoCircleIcon from 'view/components/icons/info';

type Props = {
  propertyTenancy: PropertyTenancy;
  status: PropertyTenancyStatusType;
  title: string;
  afterStatusChange: () => void;
};

const styles = StyleSheet({
  list: {
    listStyleType: 'disc',
    paddingLeft: 12,
    marginLeft: 12
  }
});

const LabelMap: Record<PropertyTenancyStatusType, string> = {
  active: 'Set as active',
  draft: 'Set as draft',
  incoming: 'Set as incoming',
  archived: 'Archive',
  outgoing: 'Set as outgoing'
};

export const useSetPropertyTenancyStatusAction = () => {
  const setStatusAction = useStatusChangeAction(propertyTenancyModel);
  const { open: openConfirmationDialog } = useConfirmationDialog();
  const s = useStyles(styles);

  return ({ propertyTenancy, status, title, afterStatusChange }: Props) => ({
    id: `set-status-${status}-property-tenancy`,
    group: 'status',
    label: LabelMap[status],
    handleAction: async () => {
      const { data: previewData } = await api.get<PreviewData>(
        `/property-tenancies/${propertyTenancy.id}/regenerate-invoices`
      );

      openConfirmationDialog({
        title: `Set ${title} to ${status}`,
        message: (
          <>
            {previewData.invoicesToCreate === 0 && (
              <Banner
                intent={'information'}
                align={'start'}
                Icon={InfoCircleIcon}
              >
                Please check there is an active property ownership and/or the
                rent schedule has been configured correctly.
              </Banner>
            )}
            <Body>
              The following changes will be made to the rent invoices:
            </Body>
            <ul {...s('list')}>
              <li>{previewData.invoicesToCreate} invoices will be generated</li>

              {previewData.invoicesToCancel > 0 && (
                <li>
                  {previewData.invoicesToCancel} invoices will be generated
                </li>
              )}
            </ul>

            <Body>Do you want to continue?</Body>
          </>
        ),
        confirmText: `Yes, set to ${status}`,
        cancelText: 'Cancel',
        onConfirm: async () => {
          await invokeActionDeclaration(() =>
            setStatusAction<PropertyTenancyStatusType>({
              record: propertyTenancy,
              status: status,
              dialogOptions: {
                type: 'none',
                afterAction: afterStatusChange
              }
            })
          );
        }
      });
    }
  });
};
