import * as React from 'react';
import { ListTable, TabbedTable } from 'view/components/table';
import { Card } from 'view/components/card';
import { ListScreen } from 'view/components/list-screen/list-screen';
import { Tab } from 'view/components/table/tabbed';
import { query } from '@rexlabs/model-generator';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { getTaskTabbedTableTabs } from 'src/modules/tasks/common/utils/get-task-tabbed-table-tabs';
import { useAppWideFilterContext } from 'src/modules/app-wide-filters/contexts/app-wide-filter-context';
import { PROPERTY_COMPLIANCE_TASK_ROUTES } from '../routes';

import { usePropertyComplianceColumns } from '../hooks/use-property-compliance-columns';
import { useGetPropertyComplianceActions } from '../hooks/action-menu-items/use-get-property-compliance-actions';
import { tasksModel } from '../../common/models/tasks-model';
import { TaskType } from '../../common/types/TaskType';
import { initialHiddenColumns } from '../../common/utils/initial-hidden-columns';

const getPropertyComplianceTasksQuery = () => query`{
  ${tasksModel} {
    id
    propertyComplianceDetails
    property
    managed_by
    task_links
    created_by
  }
}`;

export function PropertyComplianceList() {
  const { appWideFilterKey } = useAppWideFilterContext();

  const { getSort, getFilters } = useTableFilters('tasks', {
    type_id: 'task_property_compliance' as TaskType
  });

  const getPropertyComplianceActions = useGetPropertyComplianceActions();

  const getRowLinkProps = ({ item }) => {
    return {
      to: PROPERTY_COMPLIANCE_TASK_ROUTES.PROPERTY_COMPLIANCE_TASKS_DETAILS,
      params: { taskId: item.id }
    };
  };

  const getActionMenuItems = React.useCallback(
    ({ item }) => getPropertyComplianceActions(item),
    [getPropertyComplianceActions]
  );

  const propertyComplianceColumns = usePropertyComplianceColumns();
  const columnDependency = JSON.stringify(propertyComplianceColumns);

  const commonTabProps = React.useMemo(
    () => ({
      id: 'propertyCompliance',
      columns: propertyComplianceColumns,
      getQuery: getPropertyComplianceTasksQuery,
      Table: ListTable,
      getRowLinkProps,
      getActionMenuItems,
      forcedGlobalFilter: [
        {
          field: 'type_id',
          op: 'eq',
          value: {
            value: 'task_property_compliance',
            label: 'Property Compliance'
          }
        }
      ],
      getFilters,
      getSort,
      shouldUseAppWideFilter: true,
      initialHiddenColumns: initialHiddenColumns
    }),
    [columnDependency, getFilters]
  );

  const tabs = React.useMemo<Tab[]>(
    () => getTaskTabbedTableTabs(commonTabProps),
    [commonTabProps]
  );

  return (
    <ListScreen
      privilege={'tasks.property-compliance.read'}
      title='Property compliance'
      key={appWideFilterKey}
    >
      <Card>
        <TabbedTable tabs={tabs} />
      </Card>
    </ListScreen>
  );
}
