import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';
import { Value } from 'view/components/values';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import { Grid } from 'view/components/@luna/form/grid';
import { TextArea } from 'view/components/inputs/text-area/text-area';
import { PortfolioRole } from '../types/PortfolioRole';

const validate = {
  definitions: {
    label: {
      name: 'label',
      rules: 'required'
    },
    description: {
      name: 'description',
      rules: 'required'
    }
  }
};

export const portfolioRoleDetailsBlock: BlockConfig<PortfolioRole> = {
  id: 'details',
  title: 'Details',
  validate,
  View: ({ data }) => {
    return (
      <Grid columns={1}>
        <Value label='Label' value={data?.label} />
        <Value label='Description' value={data?.description} />
      </Grid>
    );
  },
  isEditable: (item) => item.system_purpose === null,
  Edit: (_props) => {
    return (
      <Grid columns={1}>
        <Field name='label' label='Label' Input={TextInput} />
        <Field name='description' label='Description' Input={TextArea} />
      </Grid>
    );
  }
};
