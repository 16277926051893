import React from 'react';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { ContactFlag } from 'view/components/record-flags/contact-flag';
import { UserGroup } from 'src/modules/user-groups/types/UserGroup';
import { itemIsContact } from 'src/modules/tasks/common/components/checklist-assignee-select';
import { RecordFlag } from 'view/components/record-flags/record-flag';
import { EntryLink } from '../components/entry-link';
import { EntryList } from '../components/entry-list';
import { EmptySection } from '../empty/base';

export interface ManagedBySectionProps {
  manager: Contact | UserGroup | 'empty';
}

export function ManagedBySection({ manager }: ManagedBySectionProps) {
  if (manager === 'empty') {
    return (
      <EmptySection headline='' buttonLabel=''>
        There is no managed by contact related to this record.
      </EmptySection>
    );
  }

  return (
    <EntryList>
      <EntryLink type={manager.__record_type} id={manager.id}>
        {itemIsContact(manager) ? (
          <ContactFlag contact={manager} />
        ) : (
          <RecordFlag record={manager} recordType={manager.__record_type} />
        )}
      </EntryLink>
    </EntryList>
  );
}
