import * as React from 'react';
import { ListTable } from 'view/components/table';
import { Card } from 'view/components/card';
import { ListScreen } from 'view/components/list-screen/list-screen';
import { query } from '@rexlabs/model-generator';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { USER_GROUP_ROUTES } from '../routes';

import { userGroupColumns } from '../utils/user-group-columns';
import { useGetUserGroupActions } from '../hooks/action-menu-items/use-get-user-group-actions';
import { userGroupsModel } from '../models/user-groups-model';
import { useGetCreateUserGroupAction } from '../hooks/action-declarations/use-get-create-user-group-action';

const userGroupQuery = query`{
  ${userGroupsModel} {
    id
  }
}`;

/**
 * Tabbed List Table
 * Model Generator Variant
 */
export function UserGroupList() {
  const getUserGroupActions = useGetUserGroupActions();
  const getCreateUserGroupAction = useGetCreateUserGroupAction();

  const { getSort, getFilters } = useTableFilters('user-groups');

  const getRowLinkProps = ({ item }) => {
    return {
      to: USER_GROUP_ROUTES.USER_GROUP_DETAILS,
      params: { userGroupId: item.id }
    };
  };

  const getActionMenuItems = React.useCallback(
    ({ item }) => {
      const actions = getUserGroupActions(item);
      return transformActionDeclarationsToActionMenuItems(actions);
    },
    [getUserGroupActions]
  );

  return (
    <ListScreen
      privilege={'user-groups.read'}
      title='User groups'
      actions={[getCreateUserGroupAction()]}
    >
      <Card>
        <ListTable
          id='user-groups'
          columns={userGroupColumns}
          getQuery={() => userGroupQuery}
          getRowLinkProps={getRowLinkProps}
          getActionMenuItems={getActionMenuItems}
          getFilters={getFilters}
          getSort={getSort}
        />
      </Card>
    </ListScreen>
  );
}
