import React, { useEffect, useRef } from 'react';
import { Field } from '@rexlabs/form';
import { AddressInput, TextInput } from '@rexlabs/text-input';

import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { Value } from 'view/components/values';

import { AddressCountrySelect } from 'view/components/inputs/selects/v4-selects/address-country-select';
import { ValueListValue } from 'data/models/types';
import { useTranslation } from 'react-i18next';
import { GhostButton } from '@rexlabs/button';
import { useDialog } from '@rexlabs/dialog';
import { merge } from 'lodash';
import {
  AddressAreaType,
  AddressCountry,
  Property
} from '../types/property-types';
import { useAddressSpecificationQuery } from '../hooks/use-address-specification-query';
import { getValueFromStringOrValueListValue } from '../utils/get-value-from-string-or-value-list-value';
import { getInputForAddressArea } from '../utils/get-input-for-address-area';
import { usePropertyPostcodeFinderFlag } from '../hooks/use-property-postcode-finder-flag';
import { PostcodeFinderDialog } from '../dialogs/postcode-finder-dialog';
import { propertyDetailContext } from '../screens/property-details';
import { mapPostcodeFinderFormToPropertyAddress } from '../utils/map-postcode-finder-form-to-property-address';

function requiredForCountry(
  countryId: AddressCountry,
  otherRules?: string | string[]
) {
  const baseRule = `required_if:address.country.id,${countryId}`;
  if (otherRules && !Array.isArray(otherRules)) {
    return baseRule + `|${otherRules}`;
  } else if (Array.isArray(otherRules)) {
    return baseRule + `|${otherRules.join('|')}`;
  }
  return baseRule;
}

// TODO: not sure how we can get this from the spec, seeing as the spec comes from a hook.
// Might be good to refactor this to a block hook later so that we can do that
const validate = {
  definitions: {
    'address.line_1': { name: 'street address', rules: 'required' },
    'address.suburb': {
      name: 'city/suburb',
      rules: requiredForCountry('AUS', ['string'])
    },
    'address.state': {
      name: 'state',
      rules: requiredForCountry('AUS')
    },
    'address.locality': {
      name: 'locality',
      rules: 'string' // GBR, but not required
    },
    'address.post_town': {
      name: 'town/city',
      rules: requiredForCountry('GBR', ['string'])
    },
    'address.postal_code': { rules: 'required', name: 'postcode' },
    'address.country': { rules: 'required', name: 'country' }
  },
  messages: {
    required_if: 'The :attribute field is required.'
  }
};
type DetailsBlockFormValues = Partial<
  Pick<Property, 'address' | 'address_string'>
>;

type AreaData = Partial<
  Record<AddressAreaType, string | ValueListValue<string>>
>;

export const detailsBlock: BlockConfig<
  Property,
  any,
  DetailsBlockFormValues
> = {
  id: 'details',
  title: 'Property address',
  validate,
  View: ({ data }) => {
    const { t } = useTranslation();
    const addressSpecQuery = useAddressSpecificationQuery(
      data?.address?.country?.id
    );

    // Extract the area data to an object keyed by the type id for easy rendering
    const areaData: AreaData = (data?.address?.areas || []).reduce(
      (acc, curr) => {
        acc[curr.type.id] = curr.value;
        return acc;
      },
      {} as AreaData
    );

    return (
      <>
        {/* TODO: we need to conditionally show UI here based on the country */}
        {data?.address ? (
          <Grid columns={1}>
            <Grid columns={1}>
              <Value
                value={
                  data?.address.line_1
                    ? data?.address.line_1
                    : data.address_string
                } // use address.line_1 if set, otherwise, it should have the legacy address string. This is deliberately NOT using null coalescing, because that doesn't handle empty strings
                label='Street address'
              />
              <Value
                value={data?.address.line_2}
                label={
                  t(
                    'addresses.areas.line_2.label',
                    'Unit, suite, level'
                  ) as string
                }
              />
            </Grid>
            <Grid columns={2}>
              {addressSpecQuery.data?.area_components.map((areaComponent) => {
                return (
                  <Value
                    key={areaComponent.type.id}
                    value={getValueFromStringOrValueListValue(
                      areaData[areaComponent.type.id],
                      { preferLabel: true, defaultValue: '--' }
                    )}
                    label={getComponentLabel({
                      component: areaComponent.type,
                      country: data?.address?.country
                    })}
                  />
                );
              })}
              <Value
                value={data?.address.postal_code}
                label={
                  addressSpecQuery.data?.postal_code_details.label || 'Postcode'
                }
              />
              <Value value={data?.address.country?.label} label='Country' />
            </Grid>
          </Grid>
        ) : (
          <Grid columns={1}>
            <Value value={data?.address_string} label='Address' />
          </Grid>
        )}
      </>
    );
  },
  Edit: ({ setFieldValue, values, blockProps }) => {
    const propertyDetailCtx = React.useContext(propertyDetailContext);

    const allowPostcodeFinder =
      blockProps?.allowPostcodeFinder ?? propertyDetailCtx.allowPostcodeFinder;

    const propertyPostcodeFinderFlag = usePropertyPostcodeFinderFlag();
    const postcodeFinderDialog = useDialog(PostcodeFinderDialog, {
      target: 'nested-dialogs'
    });

    const { t } = useTranslation();
    // HACK: This is a hack to show the new fields, if the property only has the address field.
    // We want to show the address in the address_line_1 so at least the user has a guide to
    // update the address using the new fields. We can probably remove this once we have migrated
    // the old addresses to the new fields.
    useEffect(() => {
      if (values?.address_string && !values?.address?.line_1) {
        return setFieldValue?.('address.line_1', values?.address_string);
      }
    }, []);

    const addressSpecQuery = useAddressSpecificationQuery(
      values?.address?.country?.id
    );

    const handlePostcodeFinder = React.useCallback(() => {
      postcodeFinderDialog.open({
        handleSubmit({ values }) {
          const address = mapPostcodeFinderFormToPropertyAddress(values);
          setFieldValue?.('address', merge({}, values?.address, address));

          return values;
        }
      });
    }, [values?.address]);

    return (
      <>
        <Grid columns={1}>
          <Grid columns={1}>
            <Field
              name='address.line_1'
              label='Street address'
              Input={AddressInput}
              HelpContent={
                propertyPostcodeFinderFlag.isActive && allowPostcodeFinder
                  ? () => (
                      <SearchWithPostcodeButton
                        onClick={handlePostcodeFinder}
                      />
                    )
                  : React.Fragment
              }
              inputProps={{
                placeholder: 'Enter street address (eg. 123 Example street)'
              }}
              optional={false}
            />
            <Field
              name='address.line_2'
              label={
                t(
                  'addresses.areas.line_2.label',
                  'Unit, suite, level'
                ) as string
              }
              Input={TextInput}
            />
          </Grid>
          <Grid columns={2}>
            {/* Insert fields for each of the areas described in the address spec */}
            {addressSpecQuery.data?.area_components.map((areaComponent) => {
              return (
                <Field
                  key={areaComponent.type.id}
                  name={`address.${areaComponent.type.id}`}
                  label={getComponentLabel({
                    component: areaComponent.type,
                    country: values?.address?.country
                  })}
                  Input={getInputForAddressArea(areaComponent.type.id)}
                  optional={!areaComponent.required}
                />
              );
            })}
            <Field
              name='address.postal_code'
              label='Postcode'
              Input={TextInput}
              optional={false}
            />
            <Field
              label='Country'
              Input={AddressCountrySelect}
              name='address.country'
            />
          </Grid>
        </Grid>
      </>
    );
  }
};

// HACK: show 'Suburb' instead of 'City/Suburb' for NZ
// I don't want to mess up existing addresses on BE by adding an e.g. NZ Suburb address component
// and adding new NZ translations is significantly outside the scope of this piece of work
// so this is a quick fix to make the UI correct for now
interface GetComponentLabelParams {
  component?: ValueListValue<AddressAreaType>;
  country?: ValueListValue<AddressCountry>;
}

function getComponentLabel({ component, country }: GetComponentLabelParams) {
  if (component?.id === 'suburb' && country?.id === 'NZL') {
    return 'Suburb';
  }
  return component?.label;
}

// HACK: There is a problem when we have a button inside of a field component that is using a text input. For some
// reason, when the field is focused, and you click on the button, the button just focuses but does not perform the
// click action. Likely because there is event handling on the field to redirect any clicks anywhere in the field, to
// focus on the input. Will probably need to dig into Vivid to figure this out, but for now, this hack assumes that the
// user is hovering over the button to click it. This will cause the button to receive focus, and then the click will
// work as expected.
function SearchWithPostcodeButton({ onClick }: { onClick: () => void }) {
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <GhostButton
      ref={ref}
      size='xs'
      onMouseOver={() => ref.current?.focus()}
      onClick={onClick}
    >
      Search with a postcode
    </GhostButton>
  );
}
