import { usePrivileges } from 'src/modules/authorization/roles/hooks/use-privileges';
import dayjs from 'dayjs';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useDeferredFeeSubmitHandler } from 'src/modules/deferred-fees/hooks/use-deferred-fee-submit-handler';
import { FinancialEntity } from 'src/modules/financials/utils/financial-entity-action-group/use-get-financial-entity-actions';
import { EntityFee } from '../../types/EntityFee';

type GetChargeAgencyFeeActionProps = {
  entityFee: EntityFee;
  folio: FinancialEntity;
  propertyId?: string;
};
export function useGetChargeAgencyFeeAction() {
  const { hasPrivilege } = usePrivileges();
  const deferredFeeSubmitHandler = useDeferredFeeSubmitHandler();

  return ({
    folio,
    entityFee,
    propertyId
  }: GetChargeAgencyFeeActionProps): ActionDeclaration => ({
    id: 'charge-agency-fee',
    group: 'agency-fee',
    label: 'Charge agency fee',
    hidden: !hasPrivilege('deferred-fee-line-item.create'),
    handleAction: async () => {
      const journalEntry = await deferredFeeSubmitHandler({
        payload: {
          folio: {
            id: folio.id,
            type: { id: 'ownership' }
          },
          amount: entityFee.fee_amount.amount,
          date_of: dayjs().tz().format('YYYY-MM-DD'),
          type: {
            id: 'agency_fee'
          },
          description: entityFee.agency_fee!.name,
          ...(propertyId
            ? {
                payable_by_property: {
                  id: propertyId
                }
              }
            : {}),
          is_tax_included: entityFee.is_tax_included,
          payable_by_chart_of_accounts_account: {
            id: entityFee.agency_fee!.ownership_chart_of_accounts_account!.id
          },
          payable_to_chart_of_accounts_account: {
            id: entityFee.agency_fee!.agency_chart_of_accounts_account!.id
          },
          tax_type: { id: entityFee.tax_type!.id },
          summary_description: entityFee.agency_fee!.name
        }
      });
      return journalEntry;
    }
  });
}
