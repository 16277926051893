import React from 'react';
import LagRadar from '@rexlabs/lag-radar';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { DialogTarget } from '@rexlabs/dialog';
import { RenderMatchedRoutes } from '@rexlabs/whereabouts';

import { LoadingLayout } from 'view/layouts/loading';
import { NotFoundScreen } from 'src/modules/system/screens/not-found';
import { useFeatureFlags, useTrait } from 'view/components/@luna/feature-flags';
import { lazyNamed } from 'utils/lazy-named';
import AUTH_ROUTES from 'src/routes/auth';
import ROUTES from 'src/routes/app';

import { ScheduledMaintenance } from 'view/components/scheduled-maintenance';
import { DrawerTarget } from 'src/modules/drawer/components/drawer-target';
import { CustomValidationInterceptor } from 'src/modules/custom-validation/components/custom-validation-interceptor';
import { MetricWriteRuleInterceptor } from 'src/modules/metric-write-rules/components/metric-write-rule-interceptor';
import { PrivilegeInterceptor } from 'src/modules/system/components/privilege-interceptor';

const AuthenticationLayer = lazyNamed(
  () => import('view/components/authentication-layer'),
  'AuthenticationLayer'
);
const AuthLayout = lazyNamed(() => import('view/layouts/auth'), 'AuthLayout');
const AppLayout = lazyNamed(() => import('view/layouts/app'), 'AppLayout');

const defaultStyles = StyleSheet({
  lagRadar: {
    position: 'fixed',
    right: '3rem',
    bottom: '3rem',
    zIndex: 99999999,
    background: ({ token }) => token('palette.white'),
    boxShadow: ({ token }) => token('shadow.medium'),
    borderRadius: '.2rem',
    padding: ({ token }) => token('spacing.xs'),
    transition: 'opacity .2s',
    opacity: 0.5,
    pointerEvents: 'none',

    '&, & > div': {
      display: 'flex'
    }
  }
});

function PerfRadar() {
  const s = useStyles(defaultStyles);
  const [showPerfRadar] = useTrait('show_performance_radar');
  return showPerfRadar ? (
    <Box {...s('lagRadar')}>
      <LagRadar />
    </Box>
  ) : null;
}

export function AlfredApp() {
  const { hasFeature } = useFeatureFlags();
  const isScheduledMaintenanceOn = hasFeature('scheduled_maintenance');

  return (
    <>
      <DialogTarget />
      <DialogTarget name='nested-dialogs' />

      <DrawerTarget />

      <AuthenticationLayer
        loadingView={<LoadingLayout />}
        loggedInView={
          isScheduledMaintenanceOn ? (
            <AuthLayout>
              <ScheduledMaintenance />
            </AuthLayout>
          ) : (
            <AppLayout>
              <RenderMatchedRoutes routes={ROUTES} NotFound={NotFoundScreen} />
            </AppLayout>
          )
        }
        loggedOutView={
          <AuthLayout>
            {isScheduledMaintenanceOn ? (
              <ScheduledMaintenance />
            ) : (
              <RenderMatchedRoutes
                routes={AUTH_ROUTES}
                NotFound={AUTH_ROUTES.LOGIN.config.Component}
              />
            )}
          </AuthLayout>
        }
      />
      {/* These live here to avoid content rendering before the interceptors */}
      <CustomValidationInterceptor />
      <MetricWriteRuleInterceptor />
      <PrivilegeInterceptor />
      <PerfRadar />
    </>
  );
}
