import React from 'react';

import { DialogProps } from '@rexlabs/dialog';

import { RecordTypes } from 'data/models/types';
import { tenancyModel } from 'data/models/entities/tenancies';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';

import { selectTaskTypeBlock } from 'src/modules/tasks/common/blocks/select-task-block';
import { useGetCreateTodoAction } from 'src/modules/tasks/todos/hooks/action-declarations/use-get-create-todo-action';
import { useGetCreateLeaseReviewAction } from 'src/modules/tasks/lease-review/hooks/action-declarations/use-get-create-lease-review-action';
import { useGetCreateMaintenanceAction } from 'src/modules/tasks/maintenance/hooks/action-declarations/use-get-create-maintenance-action';
import { useGetCreateMoveInAction } from 'src/modules/tasks/move-in/hooks/action-declarations/use-get-create-move-in-action';
import { useGetCreateMoveOutAction } from 'src/modules/tasks/move-out/hooks/action-declarations/use-get-create-move-out-action';
import { useGetCreateInspectionAction } from 'src/modules/tasks/inspections/hooks/action-declarations/use-get-create-inspection-action';
import { useGetCreateQuoteAction } from 'src/modules/tasks/quotes/hooks/action-declarations/use-get-create-quote-action';
import { useGetCreateWorkOrderAction } from 'src/modules/tasks/work-orders/hooks/action-declarations/use-get-create-work-order-action';
import { invokeActionDeclaration } from 'src/modules/common/actions/utils/invoke-action-declaration';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { FinancialEntity } from 'src/modules/financials/utils/financial-entity-action-group/use-get-financial-entity-actions';
import { Property } from 'src/modules/properties/types/property-types';
import { propertyTenancyModel } from 'src/modules/property-tenancies/models/property-tenancy-model';
import { getSearchResultItemFromObjectAndModel } from 'src/modules/common/utils/search-result-items/get-search-result-items-from-object-and-model';

import { SearchResultItem } from 'utils/api/get-search-results';

import { useGetCreateCustomTaskAction } from 'src/modules/tasks/custom/hooks/use-get-create-custom-task-action';
import { useCustomTaskTemplates } from 'src/modules/tasks/settings/hooks/custom-task-templates/use-custom-task-templates';
import { UserGroup } from 'src/modules/user-groups/types/UserGroup';
import { Task } from '../types/Task';
import { TaskType } from '../types/TaskType';
import { TaskRelation } from '../types/TaskLink';
import { getSearchResultItemFromManagedBy } from '../utils/get-search-result-item-from-managed-by';

const content: DialogContentConfig = [
  {
    id: 'task-type',
    label: '',
    blocks: [selectTaskTypeBlock]
  }
];

type CreateTaskDialogProps = DialogProps & {
  onCreate?: (data: any) => void;
  initialSummaryValue?: string;
  initialPropertyValue?: Property;
  initialManagedByValue?: Contact | UserGroup;
  initialTaskRelationshipsValue?: Task<TaskType>[];
  initialReportedByValue?: SearchResultItem<FinancialEntity>;
};

export function CreateTaskDialog({
  onClose,
  onCreate,
  initialSummaryValue,
  initialPropertyValue,
  initialManagedByValue,
  initialTaskRelationshipsValue = [],
  initialReportedByValue
}: CreateTaskDialogProps) {
  const { data } = useCustomTaskTemplates();

  const initialManagedByValueSearchResult = getSearchResultItemFromManagedBy(
    initialManagedByValue
  );

  const taskLinks = initialTaskRelationshipsValue?.map((task) => ({
    child_task: task,
    relation: {
      id: 'relates_to',
      label: 'Relates to'
    } as TaskRelation
  }));

  const getCreateTodoAction = useGetCreateTodoAction(true);
  const getCreateMaintenanceAction = useGetCreateMaintenanceAction({
    onCreate,
    isNested: true,
    isFixture: true,
    initialValues: {
      summary: initialSummaryValue,
      property: initialPropertyValue,
      managed_by: initialManagedByValueSearchResult,
      task_links: taskLinks,
      details: {
        reported_by: initialReportedByValue
      }
    }
  });
  const getCreateLeaseReviewAction = useGetCreateLeaseReviewAction({
    onCreate,
    isNested: true,
    isFixture: true,
    initialValues: {
      description: initialSummaryValue,
      property: initialPropertyValue,
      managed_by: initialManagedByValueSearchResult,
      task_links: taskLinks
    }
  });
  const getCreateMoveInAction = useGetCreateMoveInAction({
    onCreate,
    isFixture: true,
    isNested: true,
    initialValues: {
      description: initialSummaryValue,
      property: initialPropertyValue,
      managed_by: initialManagedByValueSearchResult,
      task_links: taskLinks,
      details: {
        property_tenancy: initialPropertyValue?.active_property_tenancy
          ? {
              ...getSearchResultItemFromObjectAndModel(
                initialPropertyValue?.active_property_tenancy,
                propertyTenancyModel
              )!,
              label:
                initialPropertyValue?.active_property_tenancy?.tenancy
                  ?.display_name
            }
          : null
      }
    }
  });
  const getCreateMoveOutAction = useGetCreateMoveOutAction({
    onCreate,
    isNested: true,
    isFixture: true,
    initialValues: {
      description: initialSummaryValue,
      property: initialPropertyValue,
      managed_by: initialManagedByValueSearchResult,
      task_links: taskLinks,
      details: {
        property_tenancy: initialPropertyValue?.active_property_tenancy
          ? {
              ...getSearchResultItemFromObjectAndModel(
                initialPropertyValue?.active_property_tenancy,
                propertyTenancyModel
              )!,
              label:
                initialPropertyValue?.active_property_tenancy?.tenancy
                  ?.display_name
            }
          : null
      }
    }
  });
  const getCreateInspectionAction = useGetCreateInspectionAction({
    onCreate,
    isNested: true,
    isFixture: true,
    initialValues: {
      description: initialSummaryValue,
      property: initialPropertyValue,
      managed_by: initialManagedByValueSearchResult,
      task_links: taskLinks,
      property_tenancy: initialPropertyValue?.active_property_tenancy
        ? {
            ...getSearchResultItemFromObjectAndModel(
              initialPropertyValue?.active_property_tenancy,
              propertyTenancyModel
            )!,
            label:
              initialPropertyValue?.active_property_tenancy?.tenancy
                ?.display_name
          }
        : null
    }
  });
  const getCreateQuoteAction = useGetCreateQuoteAction({
    // NOTE: when creating a quote, you can create multiple quotes at once.
    // This means that the onCreate callback will be given an array of quotes.
    // WE may want to update the UI of the dialog to force creation of only one quote at a time.
    onCreate: (quotes) => onCreate?.(quotes[0]),
    isNested: true,
    initialValues: {
      summary: initialSummaryValue,
      property: initialPropertyValue,
      managed_by: initialManagedByValueSearchResult,
      task_links: taskLinks
    }
  });
  const getCreateWorkOrderAction = useGetCreateWorkOrderAction({
    onCreate,
    isNested: true,
    initialValues: {
      summary: initialSummaryValue,
      property: initialPropertyValue,
      managed_by: initialManagedByValueSearchResult,
      task_links: taskLinks
    }
  });

  const createCustomTaskAction = useGetCreateCustomTaskAction({
    customTaskTemplate: null, // we're passing the template in later when we call createCustomTaskAction
    onCreate,
    isNested: true,
    initialValues: {
      summary: initialSummaryValue,
      property: initialPropertyValue,
      managed_by: initialManagedByValueSearchResult,
      task_links: taskLinks
    }
  });

  let dialogAction;

  const handleSubmit = async ({ values }) => {
    switch (values.task_type) {
      case RecordTypes.Todo: {
        dialogAction = () =>
          getCreateTodoAction({
            onCreate,
            isFixture: true,
            initialValues: {
              summary: initialSummaryValue,
              property: initialPropertyValue,
              managed_by: initialManagedByValueSearchResult,
              task_links: taskLinks,
              relates_to: getSearchResultItemFromObjectAndModel(
                initialPropertyValue?.active_property_tenancy?.tenancy,
                tenancyModel
              )
            }
          });
        break;
      }

      case RecordTypes.TaskMaintenance: {
        dialogAction = getCreateMaintenanceAction;
        break;
      }

      case RecordTypes.TaskLeaseReview: {
        dialogAction = getCreateLeaseReviewAction;
        break;
      }

      case RecordTypes.TaskMoveIn: {
        dialogAction = getCreateMoveInAction;
        break;
      }

      case RecordTypes.TaskMoveOut: {
        dialogAction = getCreateMoveOutAction;
        break;
      }

      case RecordTypes.TaskInspection: {
        dialogAction = getCreateInspectionAction;
        break;
      }

      case RecordTypes.TaskQuote: {
        dialogAction = getCreateQuoteAction;
        break;
      }

      case RecordTypes.TaskWorkOrder: {
        dialogAction = getCreateWorkOrderAction;
        break;
      }

      default: {
        const customTaskTemplate = data?.find(
          (template) => template.id === values.task_type
        );

        if (customTaskTemplate) {
          dialogAction = () => createCustomTaskAction({ customTaskTemplate });
        } else {
          throw new Error(
            `Unknown ${values.task_type} : No create dialog defined`
          );
        }
      }
    }

    invokeActionDeclaration(dialogAction);

    return true;
  };

  return (
    <RecordDialog
      title='Create task'
      onClose={onClose}
      content={content}
      submitLabel='Continue'
      handleSubmit={handleSubmit}
    />
  );
}
