import React from 'react';
import { Field } from '@rexlabs/form';

import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import Box from '@rexlabs/box';
import { PortfolioMemberSelect } from 'view/components/inputs/selects/v4-selects/portfolio-member-select';

export const removeAndSetNewPrimaryMemberBlock: BlockConfig = {
  id: 'remove-and-set-new-primary-property-manager',
  validate: {
    definitions: {
      user: { rules: 'required', name: 'primary property manager' }
    }
  },
  Edit: ({ blockProps }) => (
    <Grid columns={1}>
      <Box>
        {`Do you want to remove ${blockProps.portfolioMember.user.display_name} from this portfolio? They will remain assigned to any tasks that were previously assigned to them.`}
      </Box>
      <Box>
        {
          'Please select a new primary property manager below before removing this member.'
        }
      </Box>
      <Field
        name='user'
        id='user'
        label='Set primary property manager'
        Input={PortfolioMemberSelect}
        inputProps={{
          items: blockProps.portfolio.members.data.filter(
            (member) =>
              member.id !== blockProps.portfolioMember.id &&
              member.portfolio_role.system_purpose?.id === 'property_manager'
          )
        }}
      />
    </Grid>
  )
};
