import * as React from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import {
  GhostIconButton,
  DestructiveButton,
  OutlineIconButton,
  OutlineButton
} from '@rexlabs/button';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult
} from 'react-beautiful-dnd';
import { useDialog } from '@rexlabs/dialog';
import { Box } from '@rexlabs/box';
import {
  Form,
  Field,
  ReactForms,
  FieldArray,
  ReactFormsProps,
  FieldArrayActions,
  FormActions,
  FieldArrayField
} from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import { Semibold } from '@rexlabs/text';
import { DragIcon } from 'src/view/components/icons/drag';
import { AddIcon } from 'view/components/icons/add';
import { CaptureReasonDialog } from 'src/modules/common/actions/status/components/capture-reason';
import EyeOpenIcon from 'view/components/icons/eye-open';
import { EyeClosedIcon } from 'view/components/icons/eye-closed';
import Tooltip from '@rexlabs/tooltip';
import EditIcon from 'view/components/icons/edit';
import { get } from 'lodash';
import { ChecklistItem } from '../types/Checklist';
import { ChecklistFormData } from '../types/ChecklistFormData';
import { EditSubtaskDialog } from '../dialogs/edit-subtask-dialog';
import { TaskType } from '../types/TaskType';
import { ChecklistAssigneeSelect } from './checklist-assignee-select';

const styles = StyleSheet({
  smallColumn: {
    width: 40
  },

  largeColumn: {
    width: 300,
    textAlign: 'left'
  },

  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: `0 12px`,

    '&  th': {
      paddingLeft: 8
    },

    '&  td': {
      verticalAlign: 'middle',
      padding: 8,
      background: ({ token }) => token('palette.grey.200')
    },

    '& tr > td': {
      '&:first-child': {
        borderRadius: '8px 0 0 8px'
      },

      '&:last-child': {
        borderRadius: '0 8px 8px 0'
      }
    }
  },

  dragHandle: {
    lineHeight: 0,
    appearance: 'none',
    padding: 0,
    margin: 0,
    border: 'none',
    background: 'transparent',

    '&:focus': {
      boxShadow: 'none'
    }
  }
});

const validate = {
  definitions: {
    'items.*.label': {
      name: 'label',
      rules: 'required'
    }
  }
};

type ChecklistItemWithId = ChecklistItem & { checklistId: string };
interface ChecklistEditProps {
  formId: string;
  onDelete?: () => void;
  data: Array<ChecklistItemWithId>;
  taskType: TaskType;
  handleSubmit: ReactFormsProps<ChecklistFormData, any>['handleSubmit'];
}

export const ChecklistEdit = ({
  data,
  taskType,
  formId,
  onDelete,
  handleSubmit
}: ChecklistEditProps) => {
  const s = useStyles(styles);

  const initialValues = React.useMemo<ChecklistFormData>(
    () => getInitialValues(data),
    [data]
  );

  return (
    <>
      <ReactForms<ChecklistFormData, any>
        initialValues={initialValues}
        handleSubmit={handleSubmit}
        validate={validate}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form name={formId}>
              <table {...s('table')}>
                <thead>
                  <tr>
                    <th aria-label='drag handle' {...s('smallColumn')} />
                    <th {...s('largeColumn')}>
                      <Semibold>Item name</Semibold>
                    </th>
                    <th {...s('largeColumn')}>
                      <Semibold>Assigned to</Semibold>
                    </th>
                    <th aria-label='table action' {...s('smallColumn')}></th>
                  </tr>
                </thead>

                <FieldArray
                  name='items'
                  render={(props) => (
                    <ChecklistEditRows
                      {...props}
                      values={values as ChecklistFormData}
                      setFieldValue={setFieldValue}
                      taskType={taskType}
                    />
                  )}
                />
              </table>
            </Form>
          );
        }}
      </ReactForms>

      <div>
        {typeof onDelete === 'function' ? (
          <OutlineButton onClick={onDelete}>Remove checklist</OutlineButton>
        ) : null}
      </div>
    </>
  );
};

interface ChecklistEditRowProps
  extends FieldArrayActions,
    Pick<FormActions<ChecklistFormData, any>, 'setFieldValue'> {
  fields: FieldArrayField[];
  values: ChecklistFormData;
  taskType: TaskType;
}

function ChecklistEditRows(props: ChecklistEditRowProps) {
  const { values, push, move, remove, fields, setFieldValue, taskType } = props;
  const s = useStyles(styles);

  const captureReasonDialog = useDialog(CaptureReasonDialog);
  const editSubtaskDialog = useDialog(EditSubtaskDialog);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    move(result.source.index, result.destination.index);
  };

  /**
   *  We do this to blur out from the TextInput field because value syncs with form context
   *  on onBlur and we manually force this way
   */
  const handleMouseDown = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.focus();
  };

  const handleAddItem = () => {
    push({
      label: values.new_label,
      assignee: values.new_assignee,
      is_templated: false,
      status: {
        id: 'active',
        label: 'Active'
      },
      status_reason: 'item active'
    });

    setFieldValue('new_label', null);
    setFieldValue('new_assignee', null);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable direction='vertical' droppableId='checklist'>
        {(provided) => (
          <Box
            ref={provided.innerRef}
            Container='tbody'
            {...provided.droppableProps}
          >
            {fields.map(({ field }, index) => {
              const fieldItem = values.items[index];
              const isActive = fieldItem.status?.id === 'active';

              const handleRemove = () => {
                if (fieldItem.id) {
                  setFieldValue(
                    'removeIds',
                    values.removeIds.concat(fieldItem.id)
                  );
                }

                remove(index);
              };

              const handleSubtaskSave = (subtaskValues) => {
                setFieldValue(field.name, subtaskValues);
              };

              const handleEdit = () => {
                editSubtaskDialog.open({
                  onRemove: handleRemove,
                  onSave: handleSubtaskSave,
                  taskType,
                  subtask: get(values, field.name)
                });
              };

              const handleStatusChange = (event) => {
                if (isActive) {
                  event.preventDefault();

                  captureReasonDialog.open({
                    SubmitButton: DestructiveButton,
                    title: 'Hide subtask',
                    submitLabel: 'Hide subtask',
                    description:
                      'Please provide a reason for hiding this subtask.',
                    onSubmit: async ({ reason }) => {
                      setFieldValue(`${field.name}.is_active`, false);
                      setFieldValue(`${field.name}.status_reason`, reason);
                      setFieldValue(`${field.name}.status`, {
                        id: 'inactive',
                        label: 'Inactive'
                      });

                      return Promise.resolve(null);
                    }
                  });
                } else {
                  setFieldValue(`${field.name}.status_reason`, null);
                  setFieldValue(`${field.name}.is_active`, true);
                  setFieldValue(`${field.name}.status`, {
                    id: 'active',
                    label: 'Active'
                  });
                }
              };

              return (
                <Draggable
                  draggableId={field.key}
                  key={field.key}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <tr
                      // @ts-ignore
                      ref={provided.innerRef}
                      key={field.key}
                      {...provided.draggableProps}
                      style={getDragStyle(
                        provided.draggableProps.style,
                        snapshot.isDragging
                      )}
                    >
                      <td {...s('smallColumn')}>
                        <button
                          type='button'
                          aria-label='drag item'
                          {...s('dragHandle')}
                          {...provided.dragHandleProps}
                          onMouseDown={handleMouseDown}
                        >
                          <DragIcon />
                        </button>
                      </td>

                      <td {...s('largeColumn')}>
                        {fieldItem.is_templated ? (
                          <span>{fieldItem.label}</span>
                        ) : (
                          <Field
                            id='item_name'
                            name={`${field.name}.label`}
                            Input={TextInput}
                            optional={false}
                          />
                        )}
                      </td>

                      <td {...s('largeColumn')}>
                        <Field
                          id='assignee'
                          name={`${field.name}.assignee`}
                          Input={ChecklistAssigneeSelect}
                          optional={false}
                        />
                      </td>

                      <td {...s('smallColumn')}>
                        {fieldItem.is_templated ? (
                          <Tooltip
                            Content={() =>
                              isActive
                                ? `This subtask is from a templated checklist and cannot be edited. It can be hidden from the checklist instead.`
                                : `Show this subtask in the checklist.`
                            }
                          >
                            <GhostIconButton
                              name={`${field.name}.is_active`}
                              data-testid='item_status'
                              Icon={isActive ? EyeOpenIcon : EyeClosedIcon}
                              onClick={handleStatusChange}
                            />
                          </Tooltip>
                        ) : (
                          <GhostIconButton
                            aria-label='edit checklist item'
                            Icon={EditIcon}
                            onClick={handleEdit}
                          />
                        )}
                      </td>
                    </tr>
                  )}
                </Draggable>
              );
            })}

            {provided.placeholder}
          </Box>
        )}
      </Droppable>

      <tr>
        <td />
        <td>
          <Field
            name='new_label'
            Input={TextInput}
            optional={false}
            inputProps={{
              'data-testid': 'new_label'
            }}
          />
        </td>
        <td>
          <Field
            id='new_assignee'
            name={`new_assignee`}
            Input={ChecklistAssigneeSelect}
            optional={false}
          />
        </td>
        <td>
          <OutlineIconButton
            aria-label='add checklist item'
            Icon={AddIcon}
            onClick={handleAddItem}
          />
        </td>
      </tr>
    </DragDropContext>
  );
}

function getDragStyle(styles, isDragging) {
  return {
    ...styles,
    top: isDragging ? styles.top - 20 : styles.top
  };
}

function getInitialValues(data: ChecklistItemWithId[]) {
  return {
    items: data.map((item) => ({
      ...item,
      // create a "fake" field here so we can map it to the UI component
      // TODO: rename this to assignee
      assignee: item.assignee ?? null
    })),
    removeIds: [],
    new_label: null,
    new_assignee: null
  };
}
