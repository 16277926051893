import { query } from '@rexlabs/model-generator';
import React, { useMemo } from 'react';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';

import { ListTable, TabbedTable } from 'view/components/table';
import { Tab } from 'view/components/table/tabbed';
import { useTableFilters } from 'view/hooks/use-table-filters';

import { useAppWideFilterContext } from 'src/modules/app-wide-filters/contexts/app-wide-filter-context';
import { tasksModel } from '../../common/models/tasks-model';
import { getTaskTabbedTableTabs } from '../../common/utils/get-task-tabbed-table-tabs';
import { useGetWorkOrderActions } from '../hooks/action-menu-items/use-get-work-order-actions';
import { WORK_ORDERS_ROUTES } from '../routes';
import { useWorkOrderColumns } from '../utils/work-order-columns';
import { TaskType } from '../../common/types/TaskType';
import { workOrderQueryIncludesFragment } from '../data/work-order-query-includes-fragment';
import { initialHiddenColumns } from '../../common/utils/initial-hidden-columns';

const workOrderQuery = query`{
  ${tasksModel} {
    ${workOrderQueryIncludesFragment}
  }
}`;

export function WorkOrdersTabbedTable({
  shouldUseAppWideFilter = true
}: { shouldUseAppWideFilter?: boolean } = {}) {
  const { appWideFilterKey } = useAppWideFilterContext();
  const getWorkOrderActions = useGetWorkOrderActions();

  const getRowLinkProps = ({ item }) => {
    return {
      to: WORK_ORDERS_ROUTES.WORK_ORDER_DETAILS,
      params: { workOrderId: item.id }
    };
  };

  const { getSort, getFilters } = useTableFilters('tasks', {
    type_id: 'task_work_order' as TaskType
  });

  const getActionMenuItems = React.useCallback(
    ({ item }) => {
      const actions = getWorkOrderActions(item);
      return transformActionDeclarationsToActionMenuItems(actions);
    },
    [getWorkOrderActions]
  );

  const workOrderColumns = useWorkOrderColumns();

  const columnDependency = JSON.stringify(workOrderColumns);

  const commonTabProps = useMemo(
    () => ({
      id: 'work-orders',
      Table: ListTable,
      forcedGlobalFilter: [
        {
          field: 'type_id',
          op: 'eq',
          value: { value: 'task_work_order', label: 'Work Order' }
        }
      ],
      columns: workOrderColumns,
      getRowLinkProps,
      getActionMenuItems,
      getFilters,
      getSort,
      getQuery: () => workOrderQuery,
      shouldUseAppWideFilter: shouldUseAppWideFilter,
      initialHiddenColumns: initialHiddenColumns
    }),
    [columnDependency, getFilters]
  );

  const tabs = useMemo<Tab[]>(() => getTaskTabbedTableTabs(commonTabProps), [
    commonTabProps
  ]);

  return (
    <TabbedTable
      tabs={tabs}
      key={shouldUseAppWideFilter ? appWideFilterKey : undefined}
    />
  );
}
