import { useSettings } from 'src/modules/settings/hooks/useSettings';

import {
  FLAT_FEE,
  PERCENTAGE
} from 'src/modules/supplier-commission/types/FolioCommissionTemplate';
import { COMMISSION_INPUTS_NAME } from 'src/modules/supplier-commission/components/commission-field';
import { pick } from 'lodash';
import { CommissionRulesFormValues, Contact } from '../types/contact-types';

export function useGetMapContactDataToForm() {
  const { 'non-resident-tax-rate': defaultTaxRate } = useSettings();

  return (
    data?: Contact
  ): (Contact & CommissionRulesFormValues) | undefined => {
    return !data
      ? data
      : {
          ...data,
          ...mapCommissionFormData(data.commission_rules),
          tax: {
            ...data.tax,
            non_resident: !!data?.tax?.non_resident,
            non_resident_withhold_tax: !!data?.tax?.non_resident_withhold_tax,
            non_resident_tax_rate:
              (data?.tax?.non_resident_tax_rate || defaultTaxRate) * 100
          }
        };
  };
}

function mapCommissionFormData(
  data: Contact['commission_rules']
): CommissionRulesFormValues {
  if (!data || !Array.isArray(data))
    return {
      has_more_rules: false,
      rules: [],
      commission_structure: null
    };

  const rules = data
    .filter((rule) => rule.is_default === false)
    .map((rule) =>
      pick(rule, [
        'chart_of_accounts_account',
        'commission_template',
        'is_default',
        'id'
      ])
    );
  const defaultRule = data.find((rule) => rule.is_default === true);

  const result: CommissionRulesFormValues = {
    rules,
    // Checkbox has to be enabled when there is atleast 1 non default rule
    has_more_rules: rules.length > 0
  };

  // No defaults, we can ignore the rest
  if (!defaultRule) return result;

  // No need to add commission amount if it is a template
  if (defaultRule.commission_template != null) {
    result.commission_structure = defaultRule.commission_template;

    return result;
  }

  result.commission_structure = defaultRule.structure_type;
  result.default_rule_id = defaultRule.id;

  const fieldKey =
    defaultRule.structure_type!.id === FLAT_FEE
      ? COMMISSION_INPUTS_NAME[FLAT_FEE]
      : COMMISSION_INPUTS_NAME[PERCENTAGE];

  result[fieldKey] = defaultRule.commission_amount;

  return result;
}
