import { StyleSheet, useStyles } from '@rexlabs/styling';
import { Small } from '@rexlabs/text';
import React, { PropsWithChildren, useContext } from 'react';
import Box from '@rexlabs/box';
import ActionMenu, { ActionMenuItem } from '@rexlabs/action-menu';
import { GhostIconButton } from '@rexlabs/button';
import KebabIcon from 'view/components/icons/kebab';
import { DraggableProvided } from 'react-beautiful-dnd';
import { ChecklistTemplateItem as ChecklistTemplateItemType } from '../../types/ChecklistTemplate';
import { ChecklistTemplateItemIdentifierContext } from './checklist-template-item-identifier-provider';
import { smartChecklistContext } from './smart-checklist-context';

const greyShade = 'palette.grey.600';

const styles = StyleSheet({
  identifier: {
    borderRadius: '100%',
    background: '#C2E5F7',
    color: '#0F2C65',
    width: 20,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
    fontWeight: 'bold'
  },
  longText: {
    fontSize: 10
  },
  item: {
    flex: 1,
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridColumnGap: '8px',
    alignItems: 'center',
    fontSize: 14,
    padding: 8,
    '& span': {
      color: ({ token }) => token('color.textStyle.body.primary')
    }
  },
  pipeOuter: {
    display: 'block',
    position: 'relative',
    height: '100%'
  },
  decorativePipe: {
    position: 'relative',
    width: 14,
    left: 10,
    height: '100%',
    borderWidth: 0,
    borderStyle: 'solid',
    borderColor: ({ token }) => token(greyShade),
    borderLeftWidth: 1
  },
  decorativePipeForMetadata: {
    height: 'calc(100% - 20px)',
    borderBottomWidth: 1,
    borderBottomLeftRadius: 4
  },
  decorativePipeForChild: {
    position: 'relative',
    width: 14,
    left: 10,
    height: '100%',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: 14,
      height: 36, // the depth of the "hook" part of the pipe
      borderWidth: 0,
      borderStyle: 'solid',
      borderColor: ({ token }) => token(greyShade),
      borderLeftWidth: 1,
      borderBottomWidth: 1,
      borderBottomLeftRadius: 4
    }
  },
  nonFinalChildPipe: {
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: 14,
      height: '100%',
      borderWidth: 0,
      borderStyle: 'solid',
      borderColor: ({ token }) => token(greyShade),
      borderLeftWidth: 1
    }
  },
  childWrapper: {
    margin: '12px 0'
  },
  hiddenPipe: {
    visibility: 'hidden'
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    '& > *': {
      display: 'block'
    }
  },
  subtext: {
    color: ({ token }) => token('color.textStyle.body.subtext'),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    paddingTop: 0
  },
  subtextIcon: {
    marginRight: 8
  },
  itemBody: {
    height: 40
  },
  invisible: {
    visibility: 'hidden'
  },
  tag: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    paddingTop: 0
  }
});

export type ChecklistTemplateItemTag = {
  label: string;
  Icon?: React.ComponentType;
};

export type ChecklistTemplateItemProps = {
  title: string;
  tags?: ChecklistTemplateItemTag[];
  subtext?: React.ReactNode;
  SubtextIcon?: React.ComponentType;
  hidePipe?: boolean;
  item?: ChecklistTemplateItemType;
  renderDragHandle?: () => React.ReactNode;
  editMode?: boolean;
  actions?: ActionMenuItem[];
  draggableProvided: DraggableProvided;
};

export function Identifier({ item }: { item?: ChecklistTemplateItemType }) {
  const s = useStyles(styles);
  const { getIdentifierForItem } = useContext(
    ChecklistTemplateItemIdentifierContext
  );
  const text = getIdentifierForItem?.(item);
  return (
    <div {...s('identifier', text.length > 1 ? 'longText' : '')}>{text}</div>
  );
}

export const ChecklistTemplateItem = React.forwardRef<
  HTMLElement,
  PropsWithChildren<ChecklistTemplateItemProps>
>(
  (
    {
      title,
      tags,
      subtext,
      SubtextIcon,
      hidePipe,
      children,
      item,
      renderDragHandle,
      actions,
      draggableProvided
    },
    ref
  ) => {
    const { isEditing } = useContext(smartChecklistContext);
    const s = useStyles(styles);

    const hasChildren = !!children;
    const countOfChildren = React.Children.count(children);

    return (
      <Box
        display='flex'
        flexDirection='row'
        ref={ref}
        {...s('itemWrapper')}
        {...draggableProvided.draggableProps}
      >
        {/* This component will be used with drag+drop functionality, so this slot should be used for the handle. */}
        {renderDragHandle && (
          <Box
            display='flex'
            flexDirection='column'
            width={16}
            justifyContent='center'
            height={56}
            {...s('dragHandle', !isEditing ? 'invisible' : '')}
            {...draggableProvided.dragHandleProps}
          >
            {renderDragHandle()}
          </Box>
        )}
        <div {...s('item')}>
          <Identifier item={item} />
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            alignItems='center'
            {...s('itemBody')}
            {...draggableProvided.dragHandleProps}
          >
            <span>{title}</span>
            <div {...s(!isEditing ? 'invisible' : '')}>
              <ActionMenu
                Button={GhostIconButton}
                Icon={KebabIcon}
                items={actions ?? []}
              />
            </div>
          </Box>
          <div {...s('pipeOuter', hidePipe ? 'hiddenPipe' : '')}>
            <div
              {...s(
                'decorativePipe',
                // If there are no children, the pipe should be rounded at the bottom at the metadata section
                !hasChildren ? 'decorativePipeForMetadata' : ''
              )}
            />
          </div>
          <div {...s('details')}>
            {(tags?.length || 0) > 0 ? (
              <Box gap={8}>
                {tags?.map((tag, index) => {
                  return (
                    <Small key={index} grey {...s('tag')}>
                      {tag.Icon ? <tag.Icon /> : null}
                      {tag.label}
                    </Small>
                  );
                })}
              </Box>
            ) : (
              <div />
            )}
            {subtext && (
              <div>
                <Small {...s('subtext')}>
                  {SubtextIcon ? (
                    <span {...s('subtextIcon')}>
                      <SubtextIcon />
                    </span>
                  ) : null}
                  <span>{subtext}</span>
                </Small>
              </div>
            )}
          </div>
          <>
            {
              // map the children and return then wrapped in divs each
              React.Children.map(children, (child, index) => {
                const isFinalChild = index === countOfChildren - 1;
                return (
                  <>
                    <div
                      {...s(
                        'decorativePipeForChild',
                        !isFinalChild ? 'nonFinalChildPipe' : ''
                      )}
                    />
                    <div {...s('childWrapper')}>{child}</div>
                  </>
                );
              })
            }
          </>
        </div>
      </Box>
    );
  }
);
