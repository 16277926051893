import React, { useCallback } from 'react';
import {
  TableProps,
  ToolbarProps,
  useTableQuery,
  UseTableQueryArgs,
  useUrlState,
  UseUrlStateArgs
} from '@rexlabs/table';

import { AnyModel, ModelTypeFromAnyModel } from '@rexlabs/model-generator';
import { Columns, TableProvider, TableProviderProps } from './provider';
import { Table } from './table';

const PAGE_SIZES = [10, 20, 50, 100];

export type RecordTableProps<Model extends AnyModel<any, any>> = ToolbarProps &
  TableProps &
  UseTableQueryArgs<Model> &
  UseUrlStateArgs &
  Partial<Omit<TableProviderProps<ModelTypeFromAnyModel<Model>>, 'columns'>> & {
    columns?: Columns;
    withToolbar?: boolean;
    withPagination?: boolean;
    emptyWithoutTable?: boolean;
  };

export function RecordTable<Model extends AnyModel<any, any>>({
  withPagination,
  withToolbar,

  hiddenFilters,
  suggestedFilters,

  droppableId,
  Empty,
  Loading,
  striped,
  emptyWithoutTable,
  hashParamKey,
  pageSizes = PAGE_SIZES,
  initialSortBy,
  initialGlobalFilter,
  initialPageSize,
  initialPage,
  getQuery,
  getActionMenuItems: propGetActionMenuItems,
  ...props
}: RecordTableProps<Model>) {
  const { getTableProps: getUrlStateProps } = useUrlState({
    hashParamKey,
    initialSortBy,
    initialGlobalFilter,
    pageSizes,
    initialPageSize,
    initialPage
  });
  const { entity, getTableProps: getTableQueryProps } = useTableQuery({
    getQuery
  });

  const tableStateProps = getTableQueryProps();

  const getActionMenuItems = useCallback(
    ({ item }) => {
      return propGetActionMenuItems?.({ entity, item });
    },
    [entity, propGetActionMenuItems]
  );

  return (
    <TableProvider
      {...getUrlStateProps()}
      {...getTableQueryProps()}
      {...props}
      getActionMenuItems={
        propGetActionMenuItems ? getActionMenuItems : undefined
      }
    >
      <Table
        emptyWithoutTable={emptyWithoutTable}
        withToolbar={withToolbar}
        withPagination={withPagination}
        toolbarProps={{
          alwaysExpandSearch: false,
          suggestedFilters,
          hiddenFilters
        }}
        tableProps={{
          droppableId,
          Loading,
          Empty,
          striped
        }}
        tableStateProps={tableStateProps}
      />
    </TableProvider>
  );
}
