import React, { useMemo } from 'react';
import { query, useEntityQuery } from '@rexlabs/model-generator';

import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { RecordScreen } from 'view/components/record-screen';

import { useRecordScreenSubmitHandler } from 'view/hooks/use-record-screen-submit-handler';
import { content } from '../data/content';
import { UserGroupTitleBlock } from '../components/user-group-title-block';
import { userGroupsModel } from '../models/user-groups-model';

const getUserGroupQuery = (id: string) => query`{
  ${userGroupsModel} (id: ${id}) {
    id
    users
  }
}`;

interface UserGroupDetailsProps {
  userGroupId?: string;
}

export function UserGroupDetails({ userGroupId }: UserGroupDetailsProps) {
  const breadcrumbs = [{ type: BREADCRUMBS.USER_GROUP }];
  const query = useMemo(() => getUserGroupQuery(userGroupId!), [userGroupId]);

  const { status, data, actions } = useEntityQuery(query, {
    throwOnError: false
  });

  const handleSubmit = useRecordScreenSubmitHandler(({ changedValues }) => {
    return actions.updateItem({
      id: userGroupId,
      data: changedValues
    });
  });

  const titleBlock = <UserGroupTitleBlock userGroup={data} />;

  return (
    <RecordScreen
      isLoading={status === 'loading'}
      data={data}
      handleSubmit={handleSubmit}
      content={content}
      titleBlock={titleBlock}
      breadcrumbs={breadcrumbs}
    />
  );
}
