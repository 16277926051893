import { useState } from 'react';
import { FilterConfig, SortConfig } from '@rexlabs/table';

import { api } from 'utils/api/api-client';
import { formatCurrency } from 'utils/formatters';

import { identity } from 'lodash';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';

// Add a filter here to hide it behind the `wip_actions` feature flag
// More docs in use-table-filters-feature-flagging.md
const WIPList = [
  'accounting-journal-entry-line-items.object_id',
  'invoices.task_id',
  'trust-journal-entries.bank_account_id',
  'trust-ledgers.object_id',
  'trust-ledgers.bank_account_id',
  'uploaded-bills.created_by_id',
  'uploaded-bills.updated_by_id',
  'reconciliations.bank_account_id',
  'bank-withdrawals.bank_account_id',
  'bank-deposits.bank_account_id',
  'payment-histories.object_id',
  'chart-of-accounts-accounts.default_tax_type_id',
  'tasks.created_by_id',
  'tasks.updated_by_id',
  'portfolios.created_by_id',
  'portfolios.updated_by_id',
  'statements.created_by_id',
  'statements.updated_by_id',
  'inspection-runs.created_by_id',
  'inspection-runs.updated_by_id',
  'messages.created_by_id',
  'messages.updated_by_id',
  'message-templates.created_by_id',
  'message-templates.updated_by_id',
  'disbursements.created_by_id',
  'disbursements.updated_by_id',
  'invoices.created_by_id',
  'invoices.updated_by_id',
  'security-deposits.created_by_id',
  'security-deposits.updated_by_id',
  'service-packages.created_by_id',
  'service-packages.updated_by_id',
  'deferred-fee-line-items.reversed_by_id',
  'deferred-fee-line-items.reverse_id',
  'portfolio-roles.created_by_id',
  'portfolio-roles.updated_by_id'
];

const OPERATORS = {
  eq: 'Equals',
  neq: 'Not equals',
  gt: 'Greater than',
  gte: 'Greater than or equal to',
  lt: 'Less than',
  lte: 'Less than or equal to',
  in: 'In',
  nin: 'Not in',
  match: 'Match',
  contains: 'Contains',

  '==': 'Equals',
  '!=': 'Not equals',
  '>': 'Greater than',
  '>=': 'Greater than or equal to',
  '<': 'Less than',
  '<=': 'Less than or equal to'

  // TODO: we need to UX and implement the logic for these
  // between: 'Between',
  // nbetween: 'Not between',
  // empty: 'Is empty',
  // nempty: 'Is not empty',
} as const;

type Operator = keyof typeof OPERATORS;

type ApiFilter = {
  id: string;
  label: string;
  type: {
    id: string;
    label: string;
  };
  config: any;
  operators: Operator[];
};

type ApiSort = {
  id: string;
  label: string;
};

type FilterResponse = {
  id: string;
  name: string;
  filterable_by?: ApiFilter[];
  sortable_by?: ApiSort[];
};

type ListSortFilterState = {
  getFilters: () => FilterConfig[];
  getSort: () => SortConfig[];
};

type ListSortFilterPromiseState = {
  getFilters: () => Promise<FilterConfig[]>;
  getSort: () => Promise<SortConfig[]>;
};

type State = ListSortFilterState | ListSortFilterPromiseState;

type Cache = {
  [key: string]: State;
};
export const cache: Cache = {};

function getType(filter: ApiFilter): { type: string; typeProps?: any } {
  switch (filter.type?.id) {
    case 'uuid':
    case 'string':
      return { type: 'text' };
    case 'timestamp':
      return { type: 'timestamp' };
    case 'date':
      return { type: 'date' };
    case 'bool':
      return { type: 'boolean' };
    case 'currency':
      return { type: 'currency' };
    case 'value_list':
      return {
        type: 'valueListSelect',
        typeProps: { type: filter.config?.value_list?.id }
      };
    case 'static_value_list':
      return {
        type: 'dropdown',
        typeProps: { options: filter.config?.options }
      };
    case 'resource':
      return {
        type: 'recordSelect',
        typeProps: { recordType: filter.config?.resource_type?.id }
      };
    default:
      console.warn(`Unknown filter type: ${filter.type?.id}`);
      return { type: 'text' };
  }
}

function getFormatter(filter: ApiFilter): FilterConfig['formatValue'] {
  switch (filter.type?.id) {
    case 'currency':
      return (value) => formatCurrency(value);
    default:
      return identity;
  }
}

function reduceFilters(all: FilterConfig[], filter: ApiFilter): FilterConfig[] {
  let matchTypes = filter.operators
    .filter((key) => {
      if (!OPERATORS[key]) {
        console.warn(`Unknown operator: ${key}`);
        return false;
      }
      return true;
    })
    .map((key) => ({ value: key, label: OPERATORS[key] }));

  if (!matchTypes.length) {
    // Ignore filters that don't have any supported match type for now
    return all;
  }

  // TODO: this is a hacky workaround for now, ideally we come up with a better
  // solution driven by the BE
  if (matchTypes.find(({ value }) => value === 'match')) {
    matchTypes = [{ value: 'contains', label: 'Contains' }, ...matchTypes];
  }

  all.push({
    id: filter.id,
    ...getType(filter),
    label: filter.label,
    matchTypes,
    formatValue: getFormatter(filter)
  });

  return all;
}

function reduceSort(all: SortConfig[], sort: ApiSort): SortConfig[] {
  all.push({
    id: sort.id,
    label: sort.label,
    type: 'undefined' // TODO: fix type in table
  });
  return all;
}

function getFiltersForFlag({ resourceId, filters, showWIP }) {
  if (showWIP) {
    return filters;
  }
  return filters.filter((filter) => {
    const filterKey = `${resourceId}.${filter.id}`;
    return !WIPList.includes(filterKey);
  });
}

export async function fetchAllFilters({
  showWIP = false
} = {}): Promise<Cache> {
  let meta;

  const metaString = localStorage.getItem('meta');
  if (metaString) {
    meta = JSON.parse(metaString);
  } else {
    const response = await api.get<FilterResponse[]>(
      `/meta/resources?include=filterable_by,sortable_by`
    );

    localStorage.setItem('meta', JSON.stringify(response.data));

    meta = response.data;
  }
  meta?.forEach((item) => {
    cache[item.id] = {
      getFilters: () =>
        getFiltersForFlag({
          filters: item.filterable_by?.reduce?.(reduceFilters, []) || [],
          resourceId: item.id,
          showWIP
        }),
      getSort: () => item.sortable_by?.reduce?.(reduceSort, []) || []
    };
  });

  return cache;
}

// NOTE: "sample" is only available in dev/test environments
export type ResourceId =
  | 'user-groups'
  | 'portfolio-roles'
  | 'roles'
  | 'custom-reports'
  | 'audit-logs'
  | 'checklist-items'
  | 'prepayment-buckets'
  | 'property-area-types'
  | 'quotes'
  | 'appliances'
  | 'uploaded-bills'
  | 'tasks'
  | 'messages'
  | 'message-templates'
  | 'message-batches'
  | 'channel-message-recipients'
  | 'notes'
  | 'sample'
  | 'maintenance-jobs'
  | 'contacts'
  | 'properties'
  | 'ownerships'
  | 'recurring-invoices'
  | 'credit-notes'
  | 'bank-accounts'
  | 'invoices'
  | 'inspections'
  | 'bank-deposits'
  | 'bank-withdrawals'
  | 'reconciliations'
  | 'trust-journal-entries'
  | 'tenancies'
  | 'pending-disbursement-contacts'
  | 'pending-disbursement-ownerships'
  | 'disbursements'
  | 'property-tenancies'
  | 'property-ownerships'
  | 'chart-of-accounts-accounts'
  | 'trust-ledgers'
  | 'security-deposits'
  | 'reconciliation-adjustments'
  | 'users'
  | 'portfolios'
  | 'statements'
  | 'payment-histories'
  | 'accounting-journal-entry-line-items'
  | 'inspection-runs'
  | 'account-credit-logs'
  | 'service-packages'
  | 'agency-fees'
  | 'bank-statement-transactions'
  | 'bank-statement-transaction-imports'
  | 'commission-templates'
  | 'deferred-fee-line-items'
  | 'custom-validations';

// TODO: extract, test
function stringifyArgs(args: Record<string, string>) {
  return Object.keys(args)
    .map((key) => `${key}=${args[key]}`)
    .join('&');
}

// TODO: clean up, rn the hook handles fetching the data if needed, but we decided
// to fetch all filters on session init so this is now redundant?
export function useTableFilters(id: ResourceId, args?: Record<string, string>) {
  const lsKey = `@rexlabs/alfred/table/filters/${id}${
    args ? `?${stringifyArgs(args)}` : ''
  }`;
  const { hasFeature } = useFeatureFlags();

  let initialState = {};
  let promise;
  let isLoading = false;

  const cacheKey = args ? `${id}?${stringifyArgs(args)}` : id;

  if (cache[cacheKey]) {
    // Use the state from the cache if it exists, which will ensure we only
    // do the API call once per page load
    initialState = cache[cacheKey];
  } else {
    // If it's not yet in the cache, trigger the API call to get the latest
    // filters and sorting information
    promise = api.get<FilterResponse>(
      `/meta/resources/${id}?include=filterable_by,sortable_by${
        args ? `&${stringifyArgs(args)}` : ''
      }`
    );

    // Check the local storage
    const ls = window.localStorage?.getItem?.(lsKey);
    if (ls) {
      // If we have the information there use that for the
      // initial values, we'll still update the value in the background
      const parsed = JSON.parse(ls);
      initialState = {
        getFilters: () =>
          getFiltersForFlag({
            resourceId: id,
            filters: parsed.filters,
            showWIP: hasFeature(FLAGS.WIP_ACTIONS)
          }),
        getSort: () => parsed.sort
      };
    } else {
      // If we have the information neither in the cache nor in local storage
      // we return the promise from the API call to show the loading state in
      // the table toolbar
      isLoading = true;
      initialState = { getFilters: () => promise, getSort: () => promise };
    }
  }

  cache[cacheKey] = initialState as State;
  const [state, setState] = useState<State>(initialState as State);

  // If we triggered the API call, we want to update the cache
  if (promise) {
    promise.then((response) => {
      const filters = getFiltersForFlag({
        resourceId: id,
        filters:
          response.data?.filterable_by?.reduce?.(reduceFilters, []) || [],
        showWIP: hasFeature(FLAGS.WIP_ACTIONS)
      });

      const value = {
        filters,
        sort: response.data?.sortable_by?.reduce?.(reduceSort, []) || []
      };
      window.localStorage?.setItem?.(lsKey, JSON.stringify(value));
      cache[cacheKey] = {
        getFilters: () => value.filters,
        getSort: () => value.sort
      };
      // If we also couldn't hydrate the state from local storage, we also
      // want to update the state
      if (isLoading) {
        setState(cache[cacheKey]);
      }
    });
  }

  return state;
}
