import { Field } from '@rexlabs/form';
import React, { ComponentProps } from 'react';
import { Grid } from 'view/components/@luna/form/grid';
import { RemoveIconButton } from 'src/modules/common/components/remove-icon-button';
import Box from '@rexlabs/box';
import { useToken } from '@rexlabs/styling';
import { FolioCommissionTemplateStructure } from '../types/FolioCommissionTemplate';
import { AmountRangeInput } from './inputs/amount-range-input';
import { TierAmountSplitInput } from './inputs/tier-amount-split-input';
import { TemplateTierLineItemContainer } from './template-tier-line-item-container';

interface EditTemplateTierLineItemProps extends ComponentProps<'div'> {
  lineItemName: string;
  isLastTier?: boolean;
  templateStructureType: Extract<
    FolioCommissionTemplateStructure,
    'progressive' | 'tiered'
  >;
  onRemove?: () => void;
}

export function EditTemplateTierLineItem({
  lineItemName,
  isLastTier = false,
  templateStructureType,
  onRemove
}: EditTemplateTierLineItemProps) {
  const token = useToken();

  return (
    <TemplateTierLineItemContainer key={lineItemName}>
      <Grid columns={2}>
        <Field
          id={`${lineItemName}.range`}
          name={`${lineItemName}.range`}
          label={
            templateStructureType === 'tiered'
              ? 'For invoices'
              : 'For invoice amount'
          }
          Input={AmountRangeInput}
          optional={false}
          inputProps={{
            hideUpperLimit: isLastTier
          }}
        />
        <Box display='flex' flexDirection='row' alignItems='center' sx='1rem'>
          <Field
            id={`${lineItemName}.amount`}
            name={`${lineItemName}.amount`}
            label='Commission'
            Input={TierAmountSplitInput}
            optional={false}
          />
          <Box pt={token('spacing.m')}>
            <RemoveIconButton onClick={onRemove} isDisabled={!onRemove} />
          </Box>
        </Box>
      </Grid>
    </TemplateTierLineItemContainer>
  );
}
