import React, { useMemo } from 'react';
import { query, useEntityQuery } from '@rexlabs/model-generator';

import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { RecordScreen } from 'view/components/record-screen';
import { useRecordScreenSubmitHandler } from 'view/hooks/use-record-screen-submit-handler';
import { TaskRightBarOverride } from 'src/modules/tasks/common/components/right-bar-override';

import { tasksModel } from '../../common/models/tasks-model';
import { useContent } from '../data/content';
import { MaintenanceTitleBlock } from '../components/maintenance-title-block';
import { MaintenanceFormData } from '../types/MaintenanceFormData';
import { MaintenanceTask } from '../types/MaintenanceTask';
import { mapMaintenanceTaskToMaintenanceFormData } from '../mappers/map-maintenance-task-to-maintenance-form-data';
import { mapMaintenanceFormDataToTaskCreateRequest } from '../mappers/map-maintenance-form-data-to-task-create-request';

const getMaintenanceQuery = (id: string) => query`{
  ${tasksModel} (id: ${id}) {
    id
    details
    maintenanceDetails
    property {
        address
        active_property_ownership {
          maintenance_instructions
          ownership {
            owners
            disbursement_withheld_funds
          }
        }
        active_property_tenancy {
            tenancy {
              tenants
            }
        }
    }
    managed_by
    child_tasks 
    task_links
    created_by
    updated_by
  }
}`;

interface MaintenanceDetailsProps {
  maintenanceId?: string;
}

export function MaintenanceDetails({ maintenanceId }: MaintenanceDetailsProps) {
  const breadcrumbs = [{ type: BREADCRUMBS.TASK_MAINTENANCE }];
  const query = useMemo(() => getMaintenanceQuery(maintenanceId!), [
    maintenanceId
  ]);

  const { status, data: rawData, actions } = useEntityQuery(query, {
    throwOnError: false
  });

  const handleSubmit = useRecordScreenSubmitHandler<MaintenanceFormData>(
    async ({ changedValues, values }) => {
      const dataToSubmit = await mapMaintenanceFormDataToTaskCreateRequest({
        ...changedValues,
        details: {
          ...changedValues.details,
          // supplying this since we delete the appliance if this isn't true
          issue_relates_to_appliance:
            values?.details?.issue_relates_to_appliance,
          // We need to include the amount and the reason for the withheld funds
          // as updating one without the other will cause the other to be deleted
          ...(changedValues.details?.disbursement_withheld_funds
            ? {
                disbursement_withheld_funds: {
                  ...values.details.disbursement_withheld_funds,
                  ...changedValues.details?.disbursement_withheld_funds
                }
              }
            : {})
        }
      });

      await actions.updateItem({
        id: maintenanceId,
        data: dataToSubmit
      });
    }
  );

  const data: MaintenanceTask | undefined = rawData;
  const titleBlock = <MaintenanceTitleBlock maintenance={data} />;

  const content = useContent(data);

  const initialValues: MaintenanceFormData | undefined = data
    ? mapMaintenanceTaskToMaintenanceFormData(data!)
    : undefined;

  return (
    <RecordScreen
      privilege={'tasks.maintenances.read'}
      isLoading={status === 'loading'}
      data={data}
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      content={content}
      titleBlock={titleBlock}
      breadcrumbs={breadcrumbs}
      RightBarOverride={TaskRightBarOverride}
    />
  );
}
