import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Column, Grid } from '@rexlabs/grid';
import { Field, FieldArray, HiddenField } from '@rexlabs/form';
import { Value } from 'view/components/values';
import { EmptyCard } from 'view/components/record-screen/cards/empty-card';
import { Message } from '@rexlabs/notifications';
import EmptyTable from 'assets/illustrations/empty-table.svg';
import { InfoBanner } from 'view/components/@luna/notifications/banner';
import InfoCircleIcon from 'view/components/icons/info';
import { CommissionStructureSelect } from 'view/components/inputs/selects/v4-selects/commission-structure-select';
import { Checkbox } from '@rexlabs/checkbox';
import Tooltip from '@rexlabs/tooltip';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { OutlineButton } from '@rexlabs/button';
import { ChartOfAccountsAccountSelect } from 'view/components/inputs/selects/v4-selects/chart-of-accounts-account';
import AddIcon from 'view/components/icons/add';
import { LineItemRemoveButton } from 'view/components/line-items/line-item-remove-button';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { CommissionInput } from '../components/commission-field';
import { FLAT_FEE, PERCENTAGE } from '../types/FolioCommissionTemplate';
import { CommissionValue } from '../components/commission-value';
import { AccountCodesOverridesTable } from '../components/account-codes-overrides-table';

const styles = StyleSheet({
  lineItemRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr 40px',
    columnGap: 16
  }
});

export const SUPPLIER_COMMISSION_ACCOUNT_CODES_BLOCK_ID =
  'supplier_commission_account_codes';

export const supplierCommissionAccountCodesBlock: BlockConfig<Contact> = {
  id: SUPPLIER_COMMISSION_ACCOUNT_CODES_BLOCK_ID,
  title: 'Supplier commission',

  validate: {
    definitions: {
      commission_amount_currency: {
        rules: `required_if:commission_structure.id,${FLAT_FEE}|min:0`
      },
      commission_amount_percent: {
        rules: `required_if:commission_structure.id,${PERCENTAGE}|min:0`
      },
      'rules.*.chart_of_accounts_account': {
        rules: 'required',
        name: 'account code'
      },
      'rules.*.commission_template': {
        rules: 'required',
        name: 'commission structure'
      }
    }
  },

  showEmpty: (data) => {
    const hasCommissionRules = Array.isArray(data.commission_rules);

    if (!hasCommissionRules) return true;

    return data.commission_rules!.length === 0;
  },

  Empty: ({ onEditClick }) => {
    return (
      <EmptyCard>
        <Message
          title={'No supplier commission structure'}
          Illustration={EmptyTable}
          actions={[
            {
              label: 'Add default commission structure',
              type: 'primary',
              handleAction: onEditClick
            }
          ]}
        ></Message>
      </EmptyCard>
    );
  },

  View: ({ data }) => {
    const viewData = React.useMemo(
      () => mapCommissionViewData(data?.commission_rules ?? []),
      [data?.commission_rules]
    );

    const hasCommissionAmount = viewData.commission_amount != null;
    const hasAccountOverrides = viewData.rules.length > 0;

    return (
      <Grid columns={4}>
        <Column width={2}>
          <Value
            label='Default commission structure'
            value={viewData.default_commission_structure_name}
          />
        </Column>

        <Column width={2}>
          {hasCommissionAmount ? (
            <CommissionValue
              structureType={viewData.structure_type!.id}
              value={viewData.commission_amount!}
            />
          ) : null}
        </Column>

        {hasAccountOverrides ? (
          <Column width={4}>
            <AccountCodesOverridesTable items={viewData.rules} />
          </Column>
        ) : null}
      </Grid>
    );
  },

  Edit: ({ values, setFieldValue }) => {
    const s = useStyles(styles);

    const structureType = values.commission_structure?.id;
    const hasCommissionAmount = [FLAT_FEE, PERCENTAGE].includes(structureType);

    const defaultRuleValue = {
      commission_template: null,
      chart_of_accounts_account: null,
      is_default: false
    };

    const handleMoreRulesBlur = React.useCallback((event) => {
      setFieldValue?.('rules', event.target.checked ? [defaultRuleValue] : []);
    }, []);

    return (
      <Grid columns={4}>
        <Column width={4}>
          <InfoBanner
            Icon={InfoCircleIcon}
            description={
              <>
                Commission is collected from the total of any invoices payable
                to this supplier at the time of disbursement. The commission
                amount can be modified when creating an invoice.
              </>
            }
          />
        </Column>

        <HiddenField name='default_rule_id' />

        <Column width={2}>
          <Field<typeof CommissionStructureSelect>
            name='commission_structure'
            label='Default commission structure'
            Input={CommissionStructureSelect}
            optional={false}
            inputProps={{
              hasStructureTypes: true
            }}
          />
        </Column>

        <Column width={2}>
          {hasCommissionAmount ? (
            <CommissionInput structureType={structureType} />
          ) : null}
        </Column>

        <Column width={4}>
          <Tooltip
            Content={() =>
              'If an invoice has multiple account codes that match set commission structures, the invoice will fall back to the default commission structure above.'
            }
          >
            <div style={{ width: 'max-content' }}>
              <Field<typeof Checkbox>
                name='has_more_rules'
                optional={false}
                onBlur={handleMoreRulesBlur}
                Input={Checkbox}
                inputProps={{
                  label:
                    'Apply commission structures based on invoice account code'
                }}
              />
            </div>
          </Tooltip>
        </Column>

        {values.has_more_rules ? (
          <FieldArray name='rules'>
            {function Rules({ fields, push }) {
              return (
                <>
                  {fields.map(({ field, actions }) => (
                    <Column key={field.name} width={4}>
                      <div {...s('lineItemRow')}>
                        <Field
                          name={`${field.name}.chart_of_accounts_account`}
                          label='Invoice account code'
                          Input={ChartOfAccountsAccountSelect}
                        />

                        <Field
                          name={`${field.name}.commission_template`}
                          label='Commission structure'
                          Input={CommissionStructureSelect}
                        />

                        <HiddenField name={`${field.name}.is_default`} />
                        <HiddenField name={`${field.name}.id`} />

                        <Box marginTop={20}>
                          <LineItemRemoveButton
                            onClick={() => actions.remove()}
                            isDisabled={fields.length === 1}
                          />
                        </Box>
                      </div>
                    </Column>
                  ))}

                  <Column>
                    <div>
                      <OutlineButton
                        IconLeft={AddIcon}
                        onClick={() => push(defaultRuleValue)}
                      >
                        Add another account code
                      </OutlineButton>
                    </div>
                  </Column>
                </>
              );
            }}
          </FieldArray>
        ) : null}
      </Grid>
    );
  }
};

// Helpers

type CommissionRules = NonNullable<Contact['commission_rules']>;

interface ViewCommissionData {
  default_commission_structure_name?: string;
  commission_amount?: number | null;
  structure_type?: CommissionRules[number]['structure_type'];
  rules: CommissionRules;
}

function mapCommissionViewData(data: CommissionRules) {
  const result: ViewCommissionData = {
    rules: data.filter((rule) => rule.is_default === false)
  };

  const defaultRule = data.find((rule) => rule.is_default === true);

  if (defaultRule) {
    result.default_commission_structure_name =
      defaultRule.structure_type?.label ??
      defaultRule.commission_template?.name;

    result.structure_type = defaultRule.structure_type;
    result.commission_amount = defaultRule.commission_amount;
  }

  return result;
}
