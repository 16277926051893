import { ReactTableContext } from '@rexlabs/table';
import { IServerSideDatasource } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import invariant from 'invariant';
import { api } from 'utils/api/api-client';
import { getVisibleColumnIds } from '../utils/get-visible-column-ids';
import { getIncludesFromColumnIds } from '../utils/get-includes-from-column-ids';
import { urlQueryFromTableState } from '../../blocks/table-report-block';

export type HandlesIncludes = {
  fetchedIncludes: string[];
};

export function useDatasource(
  resourceId: string | null,
  gridRef: React.RefObject<AgGridReact | null | undefined> | null,
  tableContext: React.ContextType<typeof ReactTableContext>
) {
  if (!resourceId) {
    return null;
  }
  const datasource: IServerSideDatasource & HandlesIncludes = {
    fetchedIncludes: [],
    getRows(params) {
      invariant(gridRef?.current, 'Grid ref is not set');

      const visibleColumns = getVisibleColumnIds(gridRef.current.api as any);
      const includes = getIncludesFromColumnIds(visibleColumns);

      this.fetchedIncludes = includes;

      const urlQuery = urlQueryFromTableState(tableContext.state);
      const queryObj = Object.fromEntries(urlQuery);

      return api
        .post(`/custom-reports/rows/${resourceId}`, params.request, {
          params: {
            ...queryObj,
            includes: includes.length > 0 ? includes.join(',') : undefined
          }
        })
        .then((response) => {
          params.success({
            rowData: response.data,
            rowCount: response['pagination'].total
          });
        });
    }
  };

  return datasource;
}
