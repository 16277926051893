import { useDialog } from '@rexlabs/dialog';
import { SecurityDeposit } from 'data/models/entities/financials/security-deposits';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { CreateSecurityDepositPaymentDialog } from '../../dialogs/create-security-deposit-payment';

export function useGetCreateSecurityDepositPaymentAction() {
  const { open } = useDialog(CreateSecurityDepositPaymentDialog);

  return (securityDeposit: SecurityDeposit): ActionDeclaration => {
    return {
      id: 'create-deposit-payment',
      group: 'create',
      label: 'Create deposit payment',
      handleAction: () =>
        open({
          securityDeposit
        })
    };
  };
}
