import { AxiosResponse } from 'axios';
import React from 'react';

import { useErrorDialog } from '@rexlabs/dialog';
import {
  FileSelectEvent,
  PreviewFileUploadInput
} from '@rexlabs/file-upload-input';
import { Field, HiddenField } from '@rexlabs/form';
import { useModelActions } from '@rexlabs/model-generator';

import { api } from 'utils/api/api-client';
import { formatCurrency } from 'utils/formatters';

import { InfoBanner } from 'view/components/@luna/notifications/banner';
import InfoCircleIcon from 'view/components/icons/info';

import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { PaymentGatewayTypeSelect } from 'view/components/inputs/selects/v4-selects/payment-gateway-type-select';
import { Grid } from 'view/components/@luna/form/grid';
import { HelpBanner } from '../components/help-banner';
import { bankStatementTransactionsModel } from '../models/bank-statement-transaction-model';
import { BankTransactionFilePreview, UploadFile } from '../types';

function getInfoBannerDescription({ fileName, count, amount }) {
  const fileNameString = <b>{`'${fileName}'`}</b>;
  const countString = <b>{count} transactions</b>;
  const amountString = <b>{formatCurrency(amount)}</b>;
  return (
    <>
      Uploaded {fileNameString} containing {countString} for a total of{' '}
      {amountString}.
    </>
  );
}

export function UploadBankTransactionsForm({
  values,
  setFieldValue,
  setValues,
  fileType
}: {
  values: any;
  setFieldValue: any;
  setValues: any;
  fileType: 'transactions' | 'payment_gateway';
}) {
  const { hasFeature } = useFeatureFlags();
  const hasPaymentGatewaysFeature = hasFeature(FLAGS.PAYMENT_GATEWAYS);

  const blockValues = values;

  const { previewBankUpload } = useModelActions(bankStatementTransactionsModel);
  const { open: openErrorDialog } = useErrorDialog();

  async function onFileSelect({ file }: FileSelectEvent) {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const uploadedFile: AxiosResponse<UploadFile> = await api.post(
        '/files',
        formData
      );

      const filePreview: AxiosResponse<BankTransactionFilePreview> = await previewBankUpload(
        uploadedFile.data.id
      );

      setFieldValue?.('bank_transactions_file_preview', filePreview.data);

      return uploadedFile;
    } catch (error) {
      console.error(error);
      openErrorDialog(error);

      return setValues?.({
        upload_bank_transactions_file: '',
        bank_transactions_file_preview: ''
      });
    }
  }

  return (
    <Grid columns={1}>
      <HelpBanner />
      <Field
        id='file-input'
        Input={PreviewFileUploadInput}
        name='upload_bank_transactions_file'
        inputProps={{ onFileSelect }}
        optional={false}
      />
      <HiddenField name='bank_transactions_file_preview' />
      <div>
        {hasPaymentGatewaysFeature && fileType === 'payment_gateway'
          ? 'Supported file formats: CSV.'
          : 'Supported file formats: QIF, CSV.'}
      </div>
      {blockValues?.bank_transactions_file_preview && (
        <InfoBanner
          Icon={InfoCircleIcon}
          description={getInfoBannerDescription({
            fileName: blockValues?.upload_bank_transactions_file?.file?.name,
            amount: blockValues?.bank_transactions_file_preview?.amount,
            count: blockValues?.bank_transactions_file_preview?.count
          })}
        />
      )}
      {hasPaymentGatewaysFeature && fileType === 'payment_gateway' && (
        <Field
          id='file-payment_gateway_type'
          label='Payment gateway type'
          Input={PaymentGatewayTypeSelect}
          name='payment_gateway_type'
        />
      )}
    </Grid>
  );
}
