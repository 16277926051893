import { ValueListValue } from 'data/models/types';

export type RoleFormData = {
  name: string;
  description: string;
  privileges: Array<
    {
      id: string;
      value: boolean;
    }[]
  >;
  privilege_sets: ValueListValue<string>[];
};

export type RoleRequestData = {
  name: string;
  description: string;
  privileges: Array<{ id: string; value: boolean }>;
  privilege_sets: Array<{ id: string; value: string }>;
};

export function mapRoleFormDataToRequestData(
  values: Partial<RoleFormData>
): Partial<RoleRequestData> {
  const privileges = values.privileges || {};
  // privs are a deeply nested object, but we want to collapse all the keys down to dot notation single object
  const flattenedPrivileges = Object.keys(privileges).reduce(
    (acc, category) => {
      const categoryPrivileges = privileges[category];
      Object.keys(categoryPrivileges).forEach((privilege) => {
        acc[`${category}.${privilege}`] = categoryPrivileges[privilege];
      });
      return acc;
    },
    {}
  );

  const privilegesArray = Object.keys(flattenedPrivileges)
    .map((key) => ({
      id: key,
      value: flattenedPrivileges[key]
    }))
    .filter(({ value }) => value === true || value === false);

  // sets
  const privilegeSets = values.privilege_sets || {};

  const privilegeSetsArray = Object.keys(privilegeSets)
    .map((key) => ({
      id: key,
      value: privilegeSets[key]?.id
    }))
    .filter(({ value }) => !!value);

  return {
    ...values,
    privileges: privilegesArray,
    privilege_sets: privilegeSetsArray
  };
}
