import { query } from '@rexlabs/model-generator';
import { deferredFeeLineItemModel } from 'src/modules/deferred-fees/models/financials-deferred-fee-line-items';

export const getDeferredFeeLineItemsQuery = () => query`{
  ${deferredFeeLineItemModel} {
      id
      payable_to_chart_of_accounts_account
      folio
      tax_type
      source_object
      payment_invoice
      payable_by_property
  }
}`;
