import * as React from 'react';

import { useGetUploadBillsAction } from '../hooks/action-declarations/use-get-upload-bills-action';
import { BillProcessingListEmptyState } from './bill-processing-list-empty-state';

export function DefaultBillProcessingListEmptyState({ children }) {
  const uploadBillsAction = useGetUploadBillsAction();

  return (
    <BillProcessingListEmptyState actions={[uploadBillsAction]}>
      {children}
    </BillProcessingListEmptyState>
  );
}
