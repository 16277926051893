import { useDialog } from '@rexlabs/dialog';

import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

import {
  UploadBillsDialog,
  UploadBillsDialogProps
} from '../../dialogs/upload-bills-dialog';

export type GetCreateDraftInvoiceActionArgs = Pick<
  UploadBillsDialogProps,
  'initialInvoiceValues' | 'afterBillsUpload' | 'invoiceSuggestedContacts'
>;

export function useGetCreateDraftInvoiceAction(): (
  args: GetCreateDraftInvoiceActionArgs
) => SingleActionDeclaration {
  const { open } = useDialog(UploadBillsDialog);

  return ({
    initialInvoiceValues,
    afterBillsUpload,
    invoiceSuggestedContacts
  }) => ({
    id: 'create-draft-invoice',
    intent: 'primary',
    label: 'Create draft invoice',
    handleAction: async () => {
      open({
        allowMultipleBillUploads: false,
        initialInvoiceValues,
        afterBillsUpload,
        invoiceSuggestedContacts
      });
    }
  });
}
