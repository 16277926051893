import { query } from '@rexlabs/model-generator';
import React, { useMemo } from 'react';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';

import { ListTable, TabbedTable } from 'view/components/table';
import { Tab } from 'view/components/table/tabbed';
import { useTableFilters } from 'view/hooks/use-table-filters';

import { useAppWideFilterContext } from 'src/modules/app-wide-filters/contexts/app-wide-filter-context';
import { tasksModel } from '../../common/models/tasks-model';
import { getTaskTabbedTableTabs } from '../../common/utils/get-task-tabbed-table-tabs';
import { TaskType } from '../../common/types/TaskType';
import { useCustomTaskColumns } from '../hooks/use-custom-task-columns';
import { CustomTaskTemplate } from '../../settings/types/CustomTaskTemplate';
import { CUSTOM_TASKS_ROUTES } from '../routes';
import { customTaskQueryIncludeFragment } from '../data/custom-task-query-include-fragment';
import { useGetCustomTaskActions } from '../hooks/use-get-custom-task-actions';
import { initialHiddenColumns } from '../../common/utils/initial-hidden-columns';

const customTasksQuery = query`{
  ${tasksModel} {
    ${customTaskQueryIncludeFragment}
  }
}`;

export function CustomTasksTabbedTable({
  shouldUseAppWideFilter = true,
  customTaskTemplate
}: {
  shouldUseAppWideFilter?: boolean;
  customTaskTemplate: CustomTaskTemplate | null;
}) {
  const { appWideFilterKey } = useAppWideFilterContext();
  const getCustomTaskActions = useGetCustomTaskActions();

  const getRowLinkProps = ({ item }) => {
    return {
      to: CUSTOM_TASKS_ROUTES.CUSTOM_TASK_DETAILS,
      params: { customTaskId: item.id }
    };
  };

  const { getSort, getFilters } = useTableFilters('tasks', {
    type_id: 'task_custom_task' as TaskType
  });

  const getActionMenuItems = React.useCallback(
    ({ item }) => {
      const actions = getCustomTaskActions(item);
      return transformActionDeclarationsToActionMenuItems(actions);
    },
    [getCustomTaskActions]
  );

  const columns = useCustomTaskColumns(customTaskTemplate);

  const commonTabProps = useMemo(
    () => ({
      id: 'custom-tasks',
      Table: ListTable,
      forcedGlobalFilter: [
        {
          field: 'type_id',
          op: 'eq',
          value: { value: 'task_custom_task', label: 'Custom task' }
        },
        ...(customTaskTemplate?.type?.id
          ? [
              {
                field: 'task_custom_task_type_id',
                op: 'eq',
                value: customTaskTemplate.type?.id
              }
            ]
          : [])
      ],
      columns,
      getRowLinkProps,
      getActionMenuItems,
      getFilters,
      getSort,
      getQuery: () => customTasksQuery,
      shouldUseAppWideFilter: shouldUseAppWideFilter,
      initialHiddenColumns: initialHiddenColumns
    }),
    [
      customTaskTemplate?.type?.id,
      columns,
      getActionMenuItems,
      getFilters,
      getSort,
      shouldUseAppWideFilter
    ]
  );

  const tabs = useMemo<Tab[]>(() => getTaskTabbedTableTabs(commonTabProps), [
    commonTabProps
  ]);

  return (
    <TabbedTable
      tabs={tabs}
      key={shouldUseAppWideFilter ? appWideFilterKey : undefined}
    />
  );
}
