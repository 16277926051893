import { PrepaymentBucket } from 'src/modules/prepayments/types/prepayment-bucket';
import { PrepaymentBucketForm } from 'src/modules/prepayments/types/prepayment-bucket-form';

export function mapPrepaymentBucketToForm(
  prepaymentBucket: PrepaymentBucket
): PrepaymentBucketForm {
  return {
    ...prepaymentBucket,
    reference_code: prepaymentBucket.reference_code || undefined,
    description: prepaymentBucket.description || undefined,
    chart_of_accounts_accounts:
      prepaymentBucket?.chart_of_accounts_accounts?.data || []
  };
}
