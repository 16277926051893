import * as React from 'react';
import { Select, SelectProps } from '@rexlabs/select';
import { useQueries, UseQueryResult } from 'react-query';
import { getValueListItems } from 'utils/api/get-value-list-items';
import { BaseModelGeneratorModel } from '@rexlabs/model-generator';
import { getAllRecords } from './all-record-items-select';

export interface CommissionStructureSelectProps {
  hasStructureTypes?: boolean;
}

export function CommissionStructureSelect<T extends BaseModelGeneratorModel>(
  props: CommissionStructureSelectProps & SelectProps<T>
) {
  const {
    hasStructureTypes = false,
    helperText: propHelperText,
    ...rest
  } = props;

  const result = useQueries([
    {
      queryKey: [
        'value_list',
        'commission_rule_structure_type',
        hasStructureTypes
      ],
      refetchOnWindowFocus: false,
      enabled: hasStructureTypes,
      queryFn: () => getValueListItems('commission_rule_structure_type')
    },
    {
      queryKey: ['all-commission-templates'],
      refetchOnWindowFocus: false,
      queryFn: () => getAllRecords('financials/commission-templates')
    }
  ]);

  const items = getItems(result) as T[];
  const isLoading = isQueriesLoading(result);

  const helperText = isLoading
    ? 'Loading...'
    : items.length === 0
    ? 'No results found'
    : propHelperText || 'Select result';

  return (
    <Select
      {...rest}
      id='commission-templates'
      helperText={helperText}
      deselectable={true}
      normaliser={normaliser}
      items={items}
    />
  );
}

// Helpers

function normaliser(item) {
  if (item.__record_type === 'commission_template') {
    return {
      id: item.id,
      label: item.name,
      type: 'templates',
      heading: 'Templates'
    };
  }

  return {
    id: item.id,
    label: item.label
  };
}

function getItems(result: UseQueryResult[]) {
  if (isQueriesLoading(result)) return [];

  return result.flatMap((query) => query.data).filter(Boolean);
}

function isQueriesLoading(result: UseQueryResult[]) {
  return result.some((query) => query.isLoading);
}
