import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useDeleteAction } from 'src/modules/common/actions/delete/use-delete-action';
import { FolioCommissionTemplate } from '../../types/FolioCommissionTemplate';
import { commissionTemplatesModel } from '../../models/commission-templates-model';

export function useGetDeleteSupplierCommissionTemplateAction() {
  const getDeleteAction = useDeleteAction(commissionTemplatesModel);

  return (item: FolioCommissionTemplate): SingleActionDeclaration => {
    return getDeleteAction(item);
  };
}
