import React, { useMemo } from 'react';
import { query, useEntityQuery } from '@rexlabs/model-generator';

import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { RecordScreen } from 'view/components/record-screen';

import {
  Disbursement,
  financialsDisbursementsModel
} from 'data/models/entities/financials/disbursements/disbursements';
import { DisbursementVoidedBanner } from 'src/modules/disbursements/components/voided-banner';
import { CompletedDisbursementTitleBlock } from '../components/completed-disbursement-title-block';
import { useCompletedDisbursementContent } from '../data/completed-disbursement-content';

const getCompletedDisbursementQuery = (id: string) => query`{
  ${financialsDisbursementsModel} (id: ${id}) {
    id
    object
    record_reference
    invoice_withdrawal_line_items {
      invoice {
        specific_disbursement_payment_method
      }
      trust_journal_entry
    }
    payment_methods
    created_by
    trust_journal_entries {
      payment_information
      line_items {
        trust_ledger
      }
    }
    bill_payment_line_items {
      trust_journal_entry
      invoice
    }
    general_payout_line_items {
      invoice
      trust_journal_entry {
        contact
        payment_information
      }
    }
    ownership {
      owners
    }
    contact
    voided_by
    disbursement_snapshot_summary
  }
}`;

interface CompletedDisbursementDetailsScreenProps {
  completedDisbursementId: string;
}

export function CompletedDisbursementDetailsScreen({
  completedDisbursementId
}: CompletedDisbursementDetailsScreenProps) {
  const breadcrumbs = [{ type: BREADCRUMBS.DISBURSEMENT }];
  const query = useMemo(
    () => getCompletedDisbursementQuery(completedDisbursementId),
    [completedDisbursementId]
  );

  const { status, data } = useEntityQuery(query);

  const hasDisbursementSnapShot = data?.disbursement_snapshot_summary;

  const content = useCompletedDisbursementContent(hasDisbursementSnapShot);

  return (
    <RecordScreen
      privilege={'disbursements.read'}
      isLoading={status === 'loading'}
      data={data}
      content={content}
      titleBlock={
        <CompletedDisbursementTitleBlock disbursement={data as Disbursement} />
      }
      breadcrumbs={breadcrumbs}
      banner={data && <DisbursementVoidedBanner record={data} />}
    />
  );
}
