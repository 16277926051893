import { useSessionState } from 'src/modules/common/hooks/session-state';
import { Contact } from 'src/modules/contacts/types/contact-types';

import { Ownership } from 'data/models/entities/ownerships';

import { SummaryRow } from '../../../view/components/summary-table';
import { SnapshotSummaryDisbursement } from '../types/SnapshotSummaryDisbursement';
import { getPayoutAmountToOwnersSummaryItem } from '../utils/get-payout-amount-to-owners-summary-item';
import { getTaxWithheldSummaryItem } from '../utils/get-tax-withheld-summary-item';

export function useThisDisbursementSummary(
  data?: SnapshotSummaryDisbursement,
  object?: Contact | Ownership
): SummaryRow[] {
  const session = useSessionState();

  if (data === undefined || object === undefined) return [];

  const isOwnership = object.__record_type === 'ownership';

  const showTaxWithheld =
    session.activeSilo?.country.id === 'GBR' && isOwnership;

  return [
    {
      description: 'Specific invoice payouts',
      amount: data.specific_invoice_payouts || 0,
      id: 'specific_invoice_payouts'
    },
    {
      description: 'Bills',
      amount: data.bill_payouts || 0,
      id: 'bills'
    },
    ...(isOwnership
      ? [
          {
            description: 'Fees',
            amount: data.fee_payouts || 0,
            id: 'fees'
          }
        ]
      : []),
    ...(showTaxWithheld
      ? [
          getTaxWithheldSummaryItem(
            'New HMRC tax withheld to date',
            data.total_tax_withheld || 0,
            data.tax_withheld_by_owner,
            object
          )
        ]
      : []),
    ...data.deferred_fees_by_type.map((deferredFeesData) => ({
      description: `Pending ${deferredFeesData.type.label.toLowerCase()}`,
      amount: deferredFeesData.total_amount,
      id: deferredFeesData.type.id
    })),
    getPayoutAmountToOwnersSummaryItem(
      'Payout amount',
      data.total_payout_amount || 0,
      data.payout_amount_by_contact,
      object
    )
  ];
}
