import * as React from 'react';
import { ListTable, TabbedTable } from 'view/components/table';
import { Card } from 'view/components/card';
import { ListScreen } from 'view/components/list-screen/list-screen';
import { Tab } from 'view/components/table/tabbed';
import { query } from '@rexlabs/model-generator';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { getTaskTabbedTableTabs } from 'src/modules/tasks/common/utils/get-task-tabbed-table-tabs';
import { useAppWideFilterContext } from 'src/modules/app-wide-filters/contexts/app-wide-filter-context';
import { SUPPLIER_COMPLIANCE_TASK_ROUTES } from '../routes';

import { useSupplierComplianceColumns } from '../hooks/use-supplier-compliance-columns';
import { useGetSupplierComplianceActions } from '../hooks/action-menu-items/use-get-supplier-compliance-actions';
import { tasksModel } from '../../common/models/tasks-model';
import { TaskType } from '../../common/types/TaskType';
import { initialHiddenColumns } from '../../common/utils/initial-hidden-columns';

const getSupplierComplianceTasksQuery = () => query`{
  ${tasksModel} {
    id
    supplierComplianceDetails
    property
    managed_by
    task_links
    created_by
  }
}`;

export function SupplierComplianceList() {
  const { appWideFilterKey } = useAppWideFilterContext();
  const { getSort, getFilters } = useTableFilters('tasks', {
    type_id: 'task_supplier_compliance' as TaskType
  });

  const getSupplierComplianceActions = useGetSupplierComplianceActions();

  const getRowLinkProps = ({ item }) => {
    return {
      to: SUPPLIER_COMPLIANCE_TASK_ROUTES.SUPPLIER_COMPLIANCE_TASKS_DETAILS,
      params: { taskId: item.id }
    };
  };

  const getActionMenuItems = React.useCallback(
    ({ item }) => getSupplierComplianceActions(item),
    [getSupplierComplianceActions]
  );

  const supplierComplianceColumns = useSupplierComplianceColumns();
  const columnDependency = JSON.stringify(supplierComplianceColumns);

  const commonTabProps = React.useMemo(
    () => ({
      id: 'supplierCompliance',
      columns: supplierComplianceColumns,
      getQuery: getSupplierComplianceTasksQuery,
      Table: ListTable,
      getRowLinkProps,
      getActionMenuItems,
      forcedGlobalFilter: [
        {
          field: 'type_id',
          op: 'eq',
          value: {
            value: 'task_supplier_compliance',
            label: 'Supplier Compliance'
          }
        }
      ],
      getFilters,
      getSort,
      shouldUseAppWideFilter: true,
      initialHiddenColumns: initialHiddenColumns
    }),
    [columnDependency, getFilters]
  );

  const tabs = React.useMemo<Tab[]>(
    () => getTaskTabbedTableTabs(commonTabProps),
    [commonTabProps]
  );

  return (
    <ListScreen
      privilege={'tasks.supplier-compliance.read'}
      title='Supplier compliance'
      key={appWideFilterKey}
    >
      <Card>
        <TabbedTable tabs={tabs} />
      </Card>
    </ListScreen>
  );
}
