import { isArray, isEmpty } from 'lodash';
import { AxiosResponse } from 'axios';

import { useDialog, useErrorDialog } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';

import { usePermission } from 'src/modules/authorization/roles/hooks/use-permission';
import { InvoiceBlockProps } from 'src/modules/invoices/types/invoice-block';

import { pluralize } from 'utils/formatters';
import { FLAGS } from 'utils/feature-flags';
import { getUploadedFileMeta } from 'utils/files';
import { filterNullValues } from 'utils/filter-null-values';
import { getAllIncludesStringFromModel } from 'utils/api/get-all-includes-string-from-model';

import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { useToast } from 'view/components/@luna/notifications/toast';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';

import { financialsUploadedBillsModel } from '../models/uploaded-bills';
import { UploadBillsFormValues } from '../types/UploadBillsFormValues';
import { Bill } from '../types/Bill';
import { ProcessBillsDialog } from '../dialogs/process-bills-dialog';
import { ProcessBillsDraftDialog } from '../dialogs/process-bills-draft-dialog';
import { InitialInvoiceValues } from '../types/InitialInvoiceValues';

type GetUploadBillSubmitHandlers = {
  processNowSubmitHandler: RecordSubmitHandler<UploadBillsFormValues>;
  processLaterSubmitHandler: RecordSubmitHandler<UploadBillsFormValues>;
};

export function useUploadBillSubmitHandlers({
  initialInvoiceValues,
  invoiceSuggestedContacts
}: {
  initialInvoiceValues?: InitialInvoiceValues;
  invoiceSuggestedContacts?: InvoiceBlockProps['suggestions'];
}): GetUploadBillSubmitHandlers {
  const { addToast } = useToast();
  const { isStandardUser } = usePermission();
  const { hasFeature } = useFeatureFlags();

  const { open: openErrorDialog } = useErrorDialog();
  const { importBills, refreshLists, updateItem } = useModelActions(
    financialsUploadedBillsModel
  );
  const processBillsDialog = useDialog(ProcessBillsDialog);
  const processBillsDraftDialog = useDialog(ProcessBillsDraftDialog);

  const billsDialog =
    isStandardUser && hasFeature(FLAGS.QUICK_INVOICES)
      ? processBillsDraftDialog
      : processBillsDialog;

  const uploadBills = async (values: UploadBillsFormValues) => {
    const billsData = await resolveBillsFromFormValues(values);
    const { data: createdBills } = (await importBills({
      fileIds: billsData.map((bill) => bill.file.id)
    })) as AxiosResponse<Bill[]>;

    const updatedBills: Bill[] = [];
    // After adding the bill, we can attach to initial invoice values
    if (!isEmpty(initialInvoiceValues)) {
      for (const bill of createdBills) {
        const { data: updatedBill } = await updateItem({
          id: bill.id,
          data: {
            draft_invoice: {
              ...filterNullValues(initialInvoiceValues)
            }
          },
          args: {
            include: getAllIncludesStringFromModel(financialsUploadedBillsModel)
          }
        });
        updatedBills.push(updatedBill);
      }
    }

    return updatedBills.length ? updatedBills : createdBills;
  };

  const processNowSubmitHandler: RecordSubmitHandler<UploadBillsFormValues> = async ({
    values
  }) => {
    try {
      const uploadedBillsData = await uploadBills(values);

      // Open the process bills dialog
      billsDialog.open({
        billsToBeProcessed: uploadedBillsData,
        suggestions: invoiceSuggestedContacts
      });
      return uploadedBillsData;
    } catch (error) {
      openErrorDialog(error);
    }
  };

  const processLaterSubmitHandler: RecordSubmitHandler<UploadBillsFormValues> = async ({
    values
  }) => {
    const billData = await resolveBillsFromFormValues(values);

    try {
      const uploadedBillsData = await uploadBills(values);

      await refreshLists();

      addToast({
        description: `You have successfully uploaded ${pluralize(
          'bills',
          billData.length,
          true
        )}`
      });

      return uploadedBillsData;
    } catch (error) {
      openErrorDialog(error);
    }
  };

  return {
    processLaterSubmitHandler,
    processNowSubmitHandler
  };
}

function resolveBillsFromFormValues(values: UploadBillsFormValues) {
  const bills = isArray(values?.bills) ? values?.bills : [values?.bills];

  return getUploadedFileMeta(bills);
}
