import { useDialog } from '@rexlabs/dialog';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useQueryClient } from 'react-query';
import { CUSTOM_VALIDATIONS_QUERY_KEY } from 'src/modules/custom-validations/hooks/use-custom-validations';
import { CreateCustomValidationDialog } from '../../dialogs/create-custom-validation-dialog';

export function useGetCreateCustomValidationAction() {
  const { open: openCreateCustomValidationDialog } = useDialog(
    CreateCustomValidationDialog
  );
  const queryClient = useQueryClient();

  return (): ActionDeclaration => {
    return {
      intent: 'primary',
      id: 'create-custom-validation',
      group: 'custom-validations',
      label: 'Create Custom Validation',
      handleAction: async () => {
        openCreateCustomValidationDialog({
          onClose: () => {
            queryClient.invalidateQueries(CUSTOM_VALIDATIONS_QUERY_KEY);
          }
        });
      }
    };
  };
}
