import React from 'react';
import { DateInput } from 'view/components/@luna/inputs/date-input/date-input';
import { filterInputs } from 'view/components/table/toolbar';
import { TimestampDateInput } from 'view/components/table/toolbar/inputs/timestamp-date-input';

const TimestampInputWithExpressions = (props) => {
  return <TimestampDateInput {...props} allowExpressions />;
};

const DateInputWithExpressions = (props) => {
  return <DateInput {...props} allowExpressions />;
};

export const customReportFilterInputs = {
  ...filterInputs,
  timestamp: TimestampInputWithExpressions,
  date: DateInputWithExpressions
};
