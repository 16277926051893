import * as React from 'react';
import { TagConfig } from 'view/components/@luna/title-block';
import { ProgressDropdown } from 'src/modules/tasks/common/components/progress-dropdown';
import { CustomTask } from '../types/CustomTask';

// gotta be memo'd otherwise we get infinite rerenders
export function useCustomTaskStatusTag(task?: CustomTask): TagConfig {
  const Tag = React.useMemo(() => () => <ProgressDropdown value={task} />, [
    task?.status?.id
  ]);

  return {
    label: task?.status?.label || '',
    Tag
  };
}
