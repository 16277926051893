import React, { useMemo } from 'react';

import { query, useEntityQuery } from '@rexlabs/model-generator';

import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { RecordScreen } from 'view/components/record-screen';
import { useRecordScreenSubmitHandler } from 'view/hooks/use-record-screen-submit-handler';

import { useWorkOrderContent } from '../data/content';
import { WorkOrderTitleBlock } from '../components/work-order-title-block';
import { tasksModel } from '../../common/models/tasks-model';
import { WorkOrderFormData } from '../types/WorkOrderFormData';
import { mapWorkOrderTaskToWorkOrderFormData } from '../mappers/map-work-order-task-to-work-order-form-data';
import { mapWorkOrderFormDataToTaskEditRequest } from '../mappers/map-work-order-form-data-to-task-edit-request';
import { TaskRightBarOverride } from '../../common/components/right-bar-override';
import { workOrderQueryIncludesFragment } from '../data/work-order-query-includes-fragment';

const getWorkOrderQuery = (id: string) => query`{
  ${tasksModel} (id: ${id}) {
    ${workOrderQueryIncludesFragment}
  }
}`;

interface WorkOrderDetailsProps {
  workOrderId?: string;
}

export function WorkOrderDetails({ workOrderId }: WorkOrderDetailsProps) {
  const breadcrumbs = [{ type: BREADCRUMBS.WORK_ORDERS }];
  const query = useMemo(() => getWorkOrderQuery(workOrderId!), [workOrderId]);

  const { status, data, actions } = useEntityQuery(query, {
    throwOnError: false
  });

  const handleSubmit = useRecordScreenSubmitHandler(
    async ({ changedValues }) => {
      const dataToSubmit = await mapWorkOrderFormDataToTaskEditRequest(
        changedValues,
        data!
      );

      await actions.updateItem({
        id: workOrderId,
        data: dataToSubmit
      });
    }
  );

  const titleBlock = <WorkOrderTitleBlock workOrder={data} />;

  const initialValues: Partial<WorkOrderFormData> | undefined = data
    ? mapWorkOrderTaskToWorkOrderFormData(data!)
    : undefined;

  const content = useWorkOrderContent(data);

  return (
    <RecordScreen
      privilege={'tasks.work-orders.read'}
      isLoading={status === 'loading'}
      data={data}
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      content={content}
      titleBlock={titleBlock}
      breadcrumbs={breadcrumbs}
      RightBarOverride={TaskRightBarOverride}
    />
  );
}
