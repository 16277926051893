import * as React from 'react';

import { Select, SelectProps } from '@rexlabs/select';

import LocationMarkerIcon from 'view/components/icons/location-marker';
import { api } from 'utils/api/api-client';
import { useQuery } from 'react-query';

interface AddressCountrySelectProps {
  postcode?: string;
}

export function AddressLookupSelect(
  props: AddressCountrySelectProps & SelectProps<AddressLookup>
) {
  const { postcode, ...inputProps } = props;

  const isPostcodeDisabled =
    typeof postcode !== 'string' || postcode?.length === 0;

  const addressQuery = useQuery(
    ['address', postcode],
    () => fetchAddresses(postcode),
    {
      enabled: postcode != null
    }
  );

  return (
    <Select
      {...inputProps}
      items={addressQuery.data}
      helperText={addressQuery.isLoading ? 'Loading...' : undefined}
      searchable={false}
      ValuePrefix={LocationMarkerIcon}
      disabled={isPostcodeDisabled}
      normaliser={normaliser}
    />
  );
}

// Helper

function normaliser(item) {
  return {
    id: item.formats.single_line,
    label: item.formats.single_line
  };
}

export type AddressLookup = {
  building_name: string;
  building_number?: string;
  dependent_locality?: string;
  double_dependent_locality?: string;
  sub_building_name?: string;
  formats: {
    postal_address: string;
    single_line: string;
  };
  main_street: string;
  post_town: string;
  postcode: string;
};

async function fetchAddresses(term) {
  const queryParams = new URLSearchParams();

  queryParams.append('search', term);

  const response = await api.get<[{ data: AddressLookup[] }]>(
    `/address-lookup`,
    queryParams
  );

  return response.data[0].data;
}
