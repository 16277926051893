import * as React from 'react';

import {
  FreeformDialogProps,
  NormalisedItem,
  Select,
  SelectProps,
  useFreeformDialog,
  UseFreeformDialogArgs,
  UseItemsArgs
} from '@rexlabs/select';

import { ChecklistTemplate } from 'src/modules/tasks/settings/types/ChecklistTemplate';

import { api } from 'utils/api/api-client';
import { getRecordLabel } from 'utils/records/get-record-label';
import { Quri, toQuri } from '@rexlabs/quri';
import { ChecklistTemplateDialog } from 'src/modules/tasks/settings/dialogs/checklist-template';
import { useCreateChecklistTemplateAction } from 'src/modules/tasks/settings/hooks/action-declarations/checklist-template/use-create-checklist-template-action';
import { useApiValueList } from 'view/hooks/api/use-api-value-list';
import { useItems } from 'view/hooks/use-items';

export type ChecklistTemplateSelectProps = SelectProps<ChecklistTemplate> &
  Pick<UseItemsArgs<ChecklistTemplate>, 'getSuggestedItems'> & {
    dialogProps?: UseFreeformDialogArgs<ChecklistTemplate>['props'];
    disableFixture?: boolean;
    searchFilter?: Quri[];
  };

const normaliser = (item: ChecklistTemplate): NormalisedItem => ({
  id: item.id,
  label: getRecordLabel({ object: item, type: 'checklist_template' })
});

function FreeformChecklistTemplateDialog({
  selectItem,
  ...props
}: ChecklistTemplateSelectProps['dialogProps'] &
  FreeformDialogProps<Partial<ChecklistTemplate>>) {
  const { onSubmit } = useCreateChecklistTemplateAction(props.taskTypeId);

  const { data: taskTypes, isFetched } = useApiValueList('task_type');

  const label = isFetched
    ? taskTypes?.find((item) => item.id === props.taskTypeId)?.label || 'task'
    : 'task';

  const onCreate = React.useCallback(
    (data) => {
      selectItem(data);
      return data;
    },
    [selectItem]
  );

  return (
    <ChecklistTemplateDialog
      {...props}
      title={`Create ${label.toLowerCase()} checklist`}
      initialValues={{
        label: props.inputValue,
        checklist_template_items: []
      }}
      onSubmit={async (values) => onSubmit(values).then(onCreate)}
      onCreate={onCreate}
    />
  );
}

export const ChecklistTemplateSelect: React.FC<ChecklistTemplateSelectProps> = ({
  dialogProps,
  disableFixture = true,
  searchFilter,
  ...props
}) => {
  const getItems = React.useCallback(
    async (term) => {
      const checklists = await api.get<ChecklistTemplate[]>(
        `tasks/checklist-templates${
          searchFilter ? `?q=${toQuri(searchFilter)}` : ''
        }`,
        {
          search: term,
          per_page: 30
        }
      );

      return checklists.data;
    },
    [searchFilter]
  );

  const { getSelectProps: useItemsGetSelectProps } = useItems({
    getItems
  });

  const {
    getSelectProps: useFreeformDialogGetSelectProps
  } = useFreeformDialog<ChecklistTemplate>({
    props: dialogProps,
    Dialog: FreeformChecklistTemplateDialog
  });

  const selectProps = useItemsGetSelectProps();
  const selectPropsWithFixture = useItemsGetSelectProps(
    useFreeformDialogGetSelectProps()
  );

  return (
    <Select
      {...props}
      {...(disableFixture ? selectProps : selectPropsWithFixture)}
      normaliser={normaliser}
    />
  );
};
