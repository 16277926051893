import { useQuery } from 'react-query';
import { api } from 'utils/api/api-client';
import { RegularQuri, toQuri } from '@rexlabs/quri';
import { Integrations } from '../types/integrations';

export const integrationsQueryKey = 'integrations';

export const fetchIntegrations = async ({ queryKey }) => {
  const [, filters] = queryKey;

  const queryString = toQuri(filters);
  const queryParams = new URLSearchParams();

  queryParams.set('q', queryString);

  return api.get<Integrations[]>(`/integrations`, queryParams);
};

interface UseIntegrationsQueryProps {
  enabled?: boolean;
  filters?: RegularQuri[];
}

export function useIntegrationsQuery({
  enabled = true,
  filters = []
}: UseIntegrationsQueryProps = {}) {
  return useQuery([integrationsQueryKey, filters], fetchIntegrations, {
    staleTime: Infinity,
    enabled
  });
}
