import { Columns } from 'src/view/components/table';

// TODO: configure columns
export const roleColumns: Columns = [
  {
    id: 'id',
    Header: 'ID',
    width: 120
  },
  {
    id: 'name',
    Header: 'Name',
    width: 200
  },
  {
    id: 'description',
    Header: 'Description',
    width: 200
  }
];
