import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Value } from 'view/components/values';
import { BankWithdrawal } from 'src/modules/banking/bank-withdrawals/types/bank-withdrawal-types';
import { Grid } from 'view/components/@luna/form/grid';
import { DateValue } from 'view/components/values/date';

export const detailsBlock: BlockConfig<BankWithdrawal> = {
  id: 'details',
  title: 'Details',
  View: ({ data }) => (
    <Grid columns={3}>
      <DateValue label='Date' value={data?.date_of} />
      {data?.bacs_processing_date && (
        <DateValue
          label='BACS Processing Date'
          value={data?.bacs_processing_date}
        />
      )}
      <Value label='Type' value={data?.type.label} />
      <Value label='Bank account' value={data?.bank_account?.name} />
      <Value label='Status' value={data?.status.label} />
      {data?.status_reason && (
        <Value label='Reason' value={data?.status_reason} />
      )}
    </Grid>
  )
};
