import React, { useMemo } from 'react';
import { query, useEntityQuery } from '@rexlabs/model-generator';

import { RecordScreen } from 'view/components/record-screen';

import {
  CreditNote,
  financialsCreditNotesModel
} from 'data/models/entities/financials/credit-notes';
import { useRecordScreenSubmitHandler } from 'view/hooks/use-record-screen-submit-handler';

import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { VoidedBanner } from 'src/modules/common/components/voided-banner';
import { content } from '../data/content';
import { CreditNoteTitleBlock } from '../components/credit-note-title-block';

const getCreditNoteQuery = (id: string) => query`{
  ${financialsCreditNotesModel} (id: ${id}) {
    id
    line_items
    tax_summary
    voided_by
    application
  }
}`;

interface CreditNoteDetailsScreenProps {
  creditNoteId?: string;
}

export function CreditNoteDetailsScreen({
  creditNoteId
}: CreditNoteDetailsScreenProps) {
  const breadcrumbs = [{ type: BREADCRUMBS.CREDIT_NOTE }];
  const query = useMemo(() => getCreditNoteQuery(creditNoteId!), [
    creditNoteId
  ]);
  const { data, status, actions } = useEntityQuery(query);

  const handleSubmit = useRecordScreenSubmitHandler(({ changedValues }) =>
    actions.updateItem({ id: creditNoteId, data: changedValues })
  );

  return (
    <RecordScreen
      privilege={'credit-notes.read'}
      isLoading={status === 'loading'}
      data={data}
      handleSubmit={handleSubmit}
      content={content}
      titleBlock={
        <CreditNoteTitleBlock
          creditNote={data as CreditNote}
          refreshItem={async () => {
            await actions.refreshItem({ id: creditNoteId });
          }}
        />
      }
      breadcrumbs={breadcrumbs}
      banner={data?.status.id === 'void' && <VoidedBanner record={data} />}
    />
  );
}
