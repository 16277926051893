import { useDialog } from '@rexlabs/dialog';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { usePermission } from 'src/modules/authorization/roles/hooks/use-permission';
import { AccessDeniedDialog } from 'view/components/dialogs/access-denied-dialog';
import { UploadBankTransactionDialog } from '../dialogs/upload-bank-transactions';
import { useImportBankTransactions } from './use-import-bank-transaction';

export function useGetUploadBankTransactionsAction(): Required<
  Pick<SingleActionDeclaration, 'id' | 'label' | 'handleAction'>
> {
  const { isStandardUser, roleName } = usePermission();

  const accessDeniedDialog = useDialog(AccessDeniedDialog);
  const uploadBankTransactionDialog = useDialog(UploadBankTransactionDialog);

  const { onHandleSubmit } = useImportBankTransactions({
    type: 'bank_statement'
  });

  return {
    id: 'upload-bank-transactions',
    label: 'Upload bank statement',
    handleAction: () => {
      if (isStandardUser) {
        return accessDeniedDialog.open({
          role: roleName
        });
      }

      uploadBankTransactionDialog.open({ handleSubmit: onHandleSubmit });
    }
  };
}
