import * as React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { Field, HiddenField } from '@rexlabs/form';
import { CustomFieldEditInput } from 'src/modules/custom-fields/components/custom-field-edit-input';
import { CustomFieldViewField } from 'src/modules/custom-fields/components/custom-field-view-field';
import { Value } from 'view/components/values';
import { TextInput } from '@rexlabs/text-input';
import { CustomTaskTemplate } from '../types/CustomTaskTemplate';

interface CreateCustomTaskTemplateBlockProps {
  customTaskTemplate: CustomTaskTemplate;
}

export function createCustomTaskTemplateDetailsBlock(
  args: CreateCustomTaskTemplateBlockProps
): BlockConfig {
  const { customTaskTemplate } = args;

  return {
    id: `custom-task-template-details-${customTaskTemplate.id}`,
    title: `${customTaskTemplate.label} details`,
    validate: {
      definitions: {
        'custom_task_template.label': { rules: 'required' }
      }
    },
    View: () => {
      return (
        <Grid columns={1}>
          <Value label={'Label'} value={customTaskTemplate.label} />
          {customTaskTemplate.custom_fields?.data
            .filter((field) => field.id !== null)
            .map((field) => (
              <CustomFieldViewField
                key={field.id}
                field={field}
                value={customTaskTemplate.custom_field_values?.[field.id!]}
              />
            ))}
        </Grid>
      );
    },
    Edit: ({ setFieldValue, values }) => {
      // Because all custom tasks render the same block, we need to make sure the correct one is loaded into the form context
      React.useEffect(() => {
        if (values.custom_task_template?.id !== customTaskTemplate.id) {
          setFieldValue?.('custom_task_template', customTaskTemplate);
          setFieldValue?.(
            'custom_field_values',
            customTaskTemplate.custom_field_values
          );
        }
      }, [setFieldValue, values, values.custom_task_template?.id]);

      return (
        // Need to have this key here to force a re-render when the custom task template changes, so that the values will update according to the above useEffect.
        <Grid columns={1} key={values.custom_task_template?.id}>
          <Field
            name={'custom_task_template.label'}
            Input={TextInput}
            label={'Label'}
          />
          <HiddenField name={'custom_task_template'} />
          <HiddenField name={'custom_field_values'} />
          {customTaskTemplate.custom_fields?.data.map((field) => (
            <CustomFieldEditInput key={field.id} field={field} />
          ))}
        </Grid>
      );
    }
  };
}
