import React from 'react';

import { useModelActions } from '@rexlabs/model-generator';

import { Bill } from 'src/modules/bill-processing/types/Bill';
import {
  InvoiceBillFormValues,
  invoiceViewerBlock
} from 'src/modules/invoices/blocks/invoice-viewer';
import { InvoiceDetailsFormValues } from 'src/modules/invoices/components/invoice-details-form';
import { useInvoiceDetailsBlock } from 'src/modules/invoices/blocks/invoice-details';
import {
  useInvoiceLineItemsBlock,
  InvoiceLineItemsFormValues
} from 'src/modules/invoices/blocks/invoice-line-items';
import { AWAITING_PAYMENT_INVOICE_STATUS } from 'utils/static-value-lists/invoice-status';
import { invoiceTabs } from 'src/modules/invoices/blocks/tab-block';
import { useDraftInvoiceSubmitHandler } from 'src/modules/invoices/hooks/use-draft-invoice-submit-handler';
import { useGetResetTableUrlParams } from 'view/components/table/hooks/use-get-reset-table-url-params';
import { defaultNotesRecordTableHashParamKey } from 'src/modules/note/components/notes-list';
import { InvoiceBlockProps } from 'src/modules/invoices/types/invoice-block';
import { MultiItemDialog } from '../../../view/components/dialogs/multi-item-dialog/multi-item-dialog';
import { financialsUploadedBillsModel } from '../models/uploaded-bills';
import { ProcessBillsButtonGroup } from '../components/process-bill-button-group';
import { addNoteBlock } from '../blocks/add-note-block';
import { billProcessingNotesTableBlock } from '../components/bill-processing-notes-table';
import { InitialInvoiceValues } from '../types/InitialInvoiceValues';

export type CreateInvoiceFormValues = InvoiceDetailsFormValues &
  Pick<InvoiceLineItemsFormValues, 'line_items'> &
  InvoiceBillFormValues;

type ProcessBillsDialogProps = {
  initialValues?: InitialInvoiceValues;
  isLoading?: boolean;
  onClose?: () => void;
  billsToBeProcessed: Bill[];
} & Pick<InvoiceBlockProps, 'suggestions'>;

export function ProcessBillsDraftDialog({
  initialValues: externalInitialValues,
  isLoading,
  onClose,
  billsToBeProcessed,
  suggestions,
  ...props
}: ProcessBillsDialogProps) {
  const isFirstChangeRef = React.useRef(false);
  const { refreshLists } = useModelActions(financialsUploadedBillsModel);

  const resetTableUrlParams = useGetResetTableUrlParams();

  const [activeTab, setActiveTab] = React.useState<'invoice' | 'notes'>(
    'invoice'
  );

  const invoiceDetailsBlock = useInvoiceDetailsBlock({ definitions: {} });
  const invoiceLineItemsBlock = useInvoiceLineItemsBlock({
    definitions: {}
  });

  const content = [
    {
      id: 'basics',
      label: 'Basics',
      blocks: [
        invoiceTabs,
        invoiceDetailsBlock,
        invoiceLineItemsBlock,
        billProcessingNotesTableBlock,
        addNoteBlock
      ],
      leftBlocks: [invoiceViewerBlock]
    }
  ];

  const initialValues = billsToBeProcessed.map((billToBeProcessed) => {
    return {
      status: AWAITING_PAYMENT_INVOICE_STATUS,
      is_tax_included: true,
      uploaded_bill: billToBeProcessed,
      ...(billToBeProcessed.draft_invoice ?? {}),
      ...externalInitialValues
    };
  });

  const handleFirstChange = React.useCallback(() => {
    isFirstChangeRef.current = true;
  }, []);

  const handleInvoiceSubmit = useDraftInvoiceSubmitHandler({
    isBillProcessingDialog: true,
    async onSave() {
      await refreshLists();
    }
  });

  const handleClose = React.useCallback(() => {
    resetTableUrlParams({
      resetPageTableKeys: [defaultNotesRecordTableHashParamKey]
    });

    refreshLists();

    onClose?.();
  }, []);

  return (
    <MultiItemDialog
      // This is provided to reset the initialValues when default bank account is available
      key={`${isLoading}`}
      content={content}
      data={initialValues}
      nextButtonLabel={'Skip this bill'}
      removeButtonLabel={'Remove bill'}
      recordTypePluralized={'bills'}
      subTitleSuffix={'to process'}
      submitLabel='Save'
      onClose={handleClose}
      title={'Create draft invoice'}
      isLoading={isLoading}
      handleSubmit={handleInvoiceSubmit}
      size={'xl'}
      ButtonGroup={ProcessBillsButtonGroup}
      blockProps={{
        activeTab,
        setActiveTab,
        isFirstChange: isFirstChangeRef.current,
        handleFirstChange: handleFirstChange,
        dialogType: 'draft',
        suggestions
      }}
      {...props}
    />
  );
}
