import React, { ReactNode, useMemo } from 'react';
import { LinkProps, push } from '@rexlabs/whereabouts';

import { Card, CardContent } from 'view/components/@luna/card';

import {
  TitleBlock,
  TitleBlockProps
} from 'view/components/cards/title/title-block';

import AddIcon from 'view/components/icons/add';
import {
  RecordIcon,
  RecordIconProps
} from 'view/components/record-icon/record-icon';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { ActionsProps } from 'view/components/@luna/title-block/actions';

export interface ListTitleCardProps
  extends Pick<TitleBlockProps, 'title' | 'actions' | 'stats'>,
    Pick<ActionsProps, 'amountDisplayedAsButtons'> {
  createLabel?: string;
  createLinkProps?: LinkProps;
  stats?: TitleBlockProps['stats'];
  recordType?: RecordIconProps['recordType'];
  rightContent?: ReactNode;
}

export function ListTitleCard({
  title,
  actions: propActions = [],
  createLabel,
  createLinkProps,
  stats,
  amountDisplayedAsButtons,
  recordType,
  rightContent
}: ListTitleCardProps) {
  const actions = useMemo(() => {
    let actions: ActionDeclaration[] = [];

    actions = actions.concat(propActions);

    if (createLabel) {
      actions.push({
        label: createLabel,
        intent: 'primary',
        Icon: recordType
          ? () => <RecordIcon recordType={recordType} />
          : AddIcon,
        handleAction: () => {
          if (typeof createLinkProps?.to === 'object') {
            push(createLinkProps.to);
          }
        }
      });
    }

    return actions;
  }, [createLabel, createLinkProps, propActions, recordType]);

  const titleBlock = useMemo(
    () => (
      <TitleBlock
        title={title}
        stats={stats}
        actions={actions}
        amountDisplayedAsButtons={amountDisplayedAsButtons}
        rightContent={rightContent}
      />
    ),
    [title, actions, stats, amountDisplayedAsButtons]
  );

  return (
    <Card Container='header'>
      <CardContent>{titleBlock}</CardContent>
    </Card>
  );
}
