import React from 'react';
import { Field } from '@rexlabs/form';

import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { UserSelect } from 'view/components/inputs/selects/v4-selects/user';
import { UserGroup } from '../types/UserGroup';

export const addMembersBlock: BlockConfig<UserGroup> = {
  id: 'add-members',
  title: 'Add members',
  validate: {
    definitions: {
      users: { rules: 'required' }
    }
  },
  Edit: ({ data }) => {
    const existingUserIds = data?.users?.data.map((user) => user.id) ?? [];

    return (
      <Grid columns={1}>
        <Field
          name='users'
          id='users'
          label='Add members'
          Input={UserSelect}
          inputProps={{
            multi: true,
            filter:
              existingUserIds?.length > 0
                ? `id.nin(${existingUserIds.join(',')})`
                : undefined
          }}
        />
      </Grid>
    );
  }
};
