import { Field, HiddenField } from '@rexlabs/form';
import { toQuri } from '@rexlabs/quri';
import { TextInput } from '@rexlabs/text-input';
import { ValueListValue } from 'data/models/types';
import React, { ComponentProps } from 'react';
import {
  ChartOfAccountCategory,
  ChartOfAccountsAccount
} from 'src/modules/chart-of-accounts/types/chart-of-account-types';
import { Property } from 'src/modules/properties/types/property-types';
import { SearchResultItem } from 'utils/api/get-search-results';
import { FinancialRecordType } from 'utils/financials/financial-objects';
import { isCOANeeded, isPropertyNeeded } from 'utils/invoices/line-items';
import { RecordData } from 'utils/types';
import { Column, Grid } from 'view/components/@luna/form/grid';
import { GridColumns } from 'view/components/@luna/form/grid/grid';
import { CentAmountInput } from 'view/components/inputs/cent-amount';
import { FilteredPropertySelect } from 'view/components/inputs/selects/filtered-property-select';
import { TaxTypeSelect } from 'view/components/inputs/selects/tax-type';
import { ChartOfAccountsAccountSelect } from 'view/components/inputs/selects/v4-selects/chart-of-accounts-account';

const getPropertyFilter = (ownershipId: string) => {
  return toQuri([
    {
      field: 'ownership_id',
      op: '==',
      value: ownershipId
    }
  ]);
};

export type InvoiceLineItemFormValues = {
  description: string;
  tax_type: ValueListValue<string>;
  amount: number;
  payable_to_chart_of_accounts_account?: ChartOfAccountsAccount;
  payable_to_property?: Property;
  payable_by_chart_of_accounts_account?: ChartOfAccountsAccount;
  payable_by_property?: Property;
};

interface InvoiceLineItemProps extends ComponentProps<'div'> {
  lineItemName: string;
  payableBy?: SearchResultItem;
  payableTo?: SearchResultItem;
  testId?: string;
  columns?: GridColumns;
  setFieldValue?: (field: string, value: any) => void;
}

export function InvoiceLineItem({
  lineItemName,
  payableBy,
  payableTo,
  testId,
  columns = 4,
  setFieldValue
}: InvoiceLineItemProps) {
  const isPayableToCOANeeded =
    payableTo &&
    isCOANeeded(
      payableTo.type.id as FinancialRecordType,
      payableTo.record as RecordData
    );

  const isPayableToPropertyNeeded =
    payableTo && isPropertyNeeded(payableTo.type.id as FinancialRecordType);

  const isPayableByCOANeeded =
    payableBy &&
    isCOANeeded(
      payableBy.type.id as FinancialRecordType,
      payableBy.record as RecordData
    );

  const isPayableByPropertyNeeded =
    payableBy && isPropertyNeeded(payableBy.type.id as FinancialRecordType);

  /**
   *  HACK: There is an issue with how we pass in initial values to forms. What we found
   *  here is that if we passed in 'payable_by' and then changed that value, the validation in this block
   *  would use the initial value, not the correct value in the form. Using the effect and the hidden fields,
   *  this hack makes sure that we always have the most recent values for field validation.
   */
  React.useEffect(() => {
    // validates payable_to_chart_of_accounts_account field
    setFieldValue?.('is_payable_to_COA_needed', isPayableToCOANeeded);
    // validates payable_to_property field
    setFieldValue?.('is_payable_to_property_needed', isPayableToPropertyNeeded);
    // validates payable_by_chart_of_accounts_account field
    setFieldValue?.('is_payable_by_COA_needed', isPayableByCOANeeded);
    // validates payable_by_property field
    setFieldValue?.('is_payable_by_property_needed', isPayableByPropertyNeeded);
  }, [
    isPayableToCOANeeded,
    isPayableToPropertyNeeded,
    isPayableByCOANeeded,
    isPayableByPropertyNeeded
  ]);

  /**
   * When isPayableByCOANeeded changes we want to reset the chart of accounts value
   * so it doesn't persist in the form values when it isn't needed
   */
  React.useEffect(() => {
    setFieldValue?.(
      `${lineItemName}.payable_by_chart_of_accounts_account`,
      null
    );
  }, [isPayableByCOANeeded, lineItemName, setFieldValue]);

  // When we change the chart of accounts account, we want to set the tax type to the default tax type, for that account.
  // This should work for both payable to and payable by, but payable by will only work if payable to is not needed.
  const onChartOfAccountsAccountChange = (
    chartOfAccountsAccount: ChartOfAccountsAccount,
    payableType: 'payable_by' | 'payable_to'
  ) => {
    const shouldChangeTaxType =
      (payableType === 'payable_by' && !isPayableToCOANeeded) ||
      payableType === 'payable_to';

    shouldChangeTaxType &&
      setFieldValue?.(
        `${lineItemName}.tax_type`,
        chartOfAccountsAccount.default_tax_type
      );
  };

  return (
    <Grid data-testid={testId} columns={columns}>
      <Column width={2}>
        <Field
          id={`${lineItemName}.description`}
          name={`${lineItemName}.description`}
          label='Description'
          Input={TextInput}
          inputProps={{
            placeholder: 'Type here...'
          }}
        />
      </Column>

      {isPayableToCOANeeded && (
        <Field
          id={`${lineItemName}.payable_to_chart_of_accounts_account`}
          name={`${lineItemName}.payable_to_chart_of_accounts_account`}
          optional={false}
          label='Invoice from account code'
          onChange={(e: any) =>
            onChartOfAccountsAccountChange(e.target.value, 'payable_to')
          }
          Input={ChartOfAccountsAccountSelect}
          inputProps={{
            disableFixture: false
          }}
        />
      )}
      {isPayableToPropertyNeeded && (
        <Field
          id={`${lineItemName}.payable_to_property`}
          name={`${lineItemName}.payable_to_property`}
          optional={false}
          label='Invoice from property'
          Input={FilteredPropertySelect}
          inputProps={{
            cacheKey: `payable_to_property_${payableTo.id}`,
            filter: getPropertyFilter(payableTo.id)
          }}
        />
      )}
      {isPayableByCOANeeded && (
        <Field
          name={`${lineItemName}.payable_by_chart_of_accounts_account`}
          id={`${lineItemName}.payable_by_chart_of_accounts_account`}
          optional={false}
          label='Bill to account code'
          Input={ChartOfAccountsAccountSelect}
          onChange={(e: any) =>
            onChartOfAccountsAccountChange(e.target.value, 'payable_by')
          }
          inputProps={{
            disableFixture: false,
            sortingOrder: [
              'expense',
              'income',
              'equity',
              'asset',
              'liability'
            ] as ChartOfAccountCategory[]
          }}
        />
      )}
      {isPayableByPropertyNeeded && (
        <Field
          id={`${lineItemName}.payable_by_property`}
          name={`${lineItemName}.payable_by_property`}
          optional={false}
          label='Bill to property'
          Input={FilteredPropertySelect}
          inputProps={{
            cacheKey: `payable_by_property_${payableBy.id}`,
            filter: getPropertyFilter(payableBy.id)
          }}
        />
      )}
      <Field
        id={`${lineItemName}.tax_type`}
        name={`${lineItemName}.tax_type`}
        label='Tax type'
        Input={TaxTypeSelect}
      />
      <Field
        id={`${lineItemName}.amount`}
        name={`${lineItemName}.amount`}
        label='Amount'
        Input={CentAmountInput}
      />

      <HiddenField name='is_payable_to_COA_needed' />
      <HiddenField name='is_payable_to_property_needed' />
      <HiddenField name='is_payable_by_COA_needed' />
      <HiddenField name='is_payable_by_property_needed' />
    </Grid>
  );
}
