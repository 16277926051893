import React from 'react';
import { useIntegrationsQuery } from 'src/modules/integrations/hooks/use-integrations-query';

export function usePropertyPostcodeFinderFlag() {
  const integrationQuery = useIntegrationsQuery({
    filters: [
      {
        field: 'integration_type_id',
        op: 'eq',
        value: 'constellation'
      }
    ]
  });

  const postcodeFinderUIFlag = React.useMemo(() => {
    const flag = integrationQuery.isFetched
      ? integrationQuery.data?.data[0].is_active
      : false;

    return flag;
  }, [integrationQuery]);

  return {
    isActive: postcodeFinderUIFlag,
    isLoading: integrationQuery.isLoading
  };
}
