import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { UploadBankTransactionsFormValues } from '../types';
import { UploadBankTransactionsForm } from '../components/upload-bank-transactions-form';

export const uploadPaymentGatewayBlock: BlockConfig<
  any,
  any,
  UploadBankTransactionsFormValues
> = {
  id: 'upload-payment-gateway-file',
  validate: {
    definitions: {
      upload_bank_transactions_file: {
        name: 'payment gateway file',
        rules: 'required'
      }
    }
  },
  Edit: ({ values, setValues, setFieldValue }) => {
    return (
      <UploadBankTransactionsForm
        values={values}
        setValues={setValues}
        setFieldValue={setFieldValue}
        fileType='payment_gateway'
      />
    );
  }
};
