import React from 'react';
import { Select } from '@rexlabs/select';
import { useCurrencySymbol } from 'utils/formatters';

export const AmountTypeSelect = (props) => {
  const currencySymbol = useCurrencySymbol();

  return (
    <Select
      items={[
        { id: 'percentage', label: '%' },
        { id: 'fixed_amount', label: currencySymbol ?? 'A$' }
      ]}
      normaliser={(item) => item}
      {...props}
    />
  );
};
