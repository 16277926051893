import { useCallback, useEffect } from 'react';
import { api } from 'utils/api/api-client';
import { useDialog } from '@rexlabs/dialog';
import { RexApiResponse } from '@rexlabs/api-client';
import { MetricWriteRuleErrorDialog } from 'src/modules/metric-write-rules/dialogs/metric-write-rule-error-dialog';

type Interceptor = Parameters<typeof api.addResponseInterceptor>[0];

export function isMetricWriteRuleErrorResponse(response: RexApiResponse) {
  return (
    response.status === 422 &&
    response.data.errors &&
    'metric_write_rules' in response.data.errors
  );
}

export function MetricWriteRuleInterceptor() {
  const metricWriteRulesErrorDialog = useDialog(MetricWriteRuleErrorDialog);

  const interceptor: Interceptor = useCallback<Interceptor>(
    ((response) => {
      if (!isMetricWriteRuleErrorResponse(response)) {
        return response;
      }

      return new Promise<RexApiResponse>((resolve, reject) => {
        metricWriteRulesErrorDialog.open({
          requestConfig: response.config,
          errors: response.data.errors.metric_write_rules,
          onSubmitSuccess: (r) => resolve(r),
          onSubmitFailure: (r) => reject(r),
          onCancel: () => {
            reject();
          }
        });
      });
    }) as Interceptor,
    [metricWriteRulesErrorDialog]
  );
  useEffect(() => {
    api.addResponseInterceptor(interceptor);
    return () => {
      // @ts-ignore
      api.responseInterceptors = api.responseInterceptors.filter(
        (i) => interceptor !== i
      );
    };
  }, [interceptor]);

  return null;
}
