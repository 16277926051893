import { BlockViewProps } from 'view/components/record-screen/types';
import { TaskType } from '../types/TaskType';
import { getDefaultTaskManagerContact } from './get-default-task-manager-contact';
import { getSearchResultItemFromManagedBy } from './get-search-result-item-from-managed-by';

export async function onPropertyChangeHandler({
  values,
  setFieldValue,
  taskType
}: Pick<BlockViewProps, 'values' | 'setFieldValue'> & {
  taskType: TaskType;
}) {
  const property = values?.property;

  if (property) {
    const defaultTaskManagerContact = await getDefaultTaskManagerContact({
      propertyId: property?.id,
      taskType: taskType
    });

    // TODO: This only supports contact managedBy for now (on the backend ,too). We could refactor this to handle user groups if needed, but it's not a priority.
    if (!values?.managed_by && defaultTaskManagerContact) {
      setFieldValue?.(
        'managed_by',
        getSearchResultItemFromManagedBy(defaultTaskManagerContact)
      );
    }
  }
}
