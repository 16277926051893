import React, { useEffect } from 'react';
import { AuthorSelect } from 'src/modules/authors/components/author-select';
import { ContactSelect } from 'view/components/inputs/selects/contact';
import { PortfolioSelect } from 'view/components/inputs/selects/portfolio';
import { OwnershipSelect } from 'view/components/inputs/selects/ownership';
import { PropertyOwnershipSelect } from 'view/components/inputs/selects/property-ownership';
import { PropertySelect } from 'view/components/inputs/selects/property-select';
import { PropertyTenancySelect } from 'view/components/inputs/selects/property-tenancy';
import { TenancySelect } from 'view/components/inputs/selects/tenancy';
import { ServicePackageSelect } from 'view/components/inputs/selects/v4-selects/service-package-select';
import { ChangeReasonSelect } from 'view/components/inputs/selects/v4-selects/change-reason-select';
import { pluralize } from 'utils/formatters';
import { BankStatementTransactionImportSelect } from 'view/components/inputs/selects/v4-selects/bank-statement-transaction-import-select';
import { CommissionStructureSelect } from 'view/components/inputs/selects/v4-selects/commission-structure-select';
import { FinancialEntitySelect } from 'view/components/inputs/selects/financial-entity-select';
import { BankAccountSelect } from 'src/modules/bank-accounts/components/bank-account-select';
import { UserRoleSelect } from 'view/components/inputs/selects/v4-selects/user-role-select';
import { Role } from 'src/modules/authorization/roles/types/Role';
import { PortfolioRoleSelect } from 'view/components/inputs/selects/v4-selects/portfolio-role';

type ExtendedRecord<Record> = Record & { label: string; value: string };

export const SELECTS = {
  contacts: ContactSelect,
  properties: PropertySelect,
  tenancies: TenancySelect,
  ownerships: OwnershipSelect,
  'property-ownerships': PropertyOwnershipSelect,
  'property-tenancies': PropertyTenancySelect,
  authors: AuthorSelect,
  portfolios: PortfolioSelect,
  'portfolio-roles': PortfolioRoleSelect,
  'change-reasons': ChangeReasonSelect,
  'service-packages': ServicePackageSelect,
  'bank-statement-transaction-imports': BankStatementTransactionImportSelect,
  'commission-templates': CommissionStructureSelect,
  'financial-entities': FinancialEntitySelect,
  'bank-accounts': (props) => (
    <BankAccountSelect
      {...props}
      mapper={(data) =>
        data?.map((item) => {
          return { ...item, label: item.name, value: item.id };
        })
      }
    />
  ),
  roles: (props) => (
    <UserRoleSelect<ExtendedRecord<Role>>
      {...props}
      mapper={(data: Role[]): ExtendedRecord<Role>[] =>
        data?.map((item) => {
          return { ...item, label: item.name, value: item.id };
        })
      }
    />
  )
} as const;

export function RecordSelect({
  recordType,
  op,
  value,
  onChange,
  onBlur,
  ...props
}) {
  const multi = !!op?.value && ['in', 'nin'].includes(op.value);
  let Select = SELECTS[recordType];

  if (!Select) {
    // try again with the plural form. This is because actual RecordTypes are singular, but this is expecting plural for some reason.
    Select = SELECTS[`${pluralize(recordType, 2)}`];
  }

  // HACK: this resets the select when toggling between multi select and single select
  // based on the operation type
  const val = value
    ? multi && !Array.isArray(value)
      ? value
        ? [value]
        : []
      : !multi && Array.isArray(value)
      ? null
      : value
    : value || null;

  useEffect(() => {
    if (val !== value) {
      onChange?.({ target: { value: val } });
      onBlur?.({ target: { value: val } });
    }
  }, [val, value]);

  if (!Select) {
    console.warn(`No select found for given record type: ${recordType}`);
    return null;
  }

  return (
    <Select
      multi={multi}
      onChange={onChange}
      onBlur={onBlur}
      value={val}
      denormaliser={(item) => ({
        ...item.value,
        value: item.value?.id,
        label: item.label
      })}
      {...props}
    />
  );
}
