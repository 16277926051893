import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import React from 'react';
import RefreshIcon from 'view/components/icons/refresh';

const defaultStyles = StyleSheet({
  resetButtonLabel: {
    color: ({ token }) => token('color.textStyle.body.hint'),
    textDecoration: 'underline',
    cursor: 'pointer'
  }
});

export function ResetButton({
  children,
  onClick
}: {
  children: React.ReactNode;
  onClick?: () => any;
}) {
  const s = useStyles(defaultStyles);

  return (
    <Box
      flexDirection='row'
      alignItems='centre'
      width='fit-content'
      justifyContent='flex-start'
      sx='0.5rem'
      onClick={onClick}
      {...s('resetButtonLabel')}
    >
      <RefreshIcon />
      <span>{children}</span>
    </Box>
  );
}
