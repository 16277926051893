import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useModelActions } from '@rexlabs/model-generator';
import { rolesModel } from 'src/modules/authorization/roles/models/roles-model';
import { usePrivileges } from 'src/modules/authorization/roles/hooks/use-privileges';
import { Role } from '../../types/Role';

export function useGetRoleActions() {
  const { duplicateItem, refreshLists } = useModelActions(rolesModel);
  const { hasPrivilege } = usePrivileges();

  return (role?: Role): ActionDeclaration[] => {
    if (!role) return [];

    const actions: ActionDeclaration[] = [
      {
        label: 'Duplicate',
        hidden: !hasPrivilege('roles.update'),
        handleAction: () => duplicateItem({ role }).then(() => refreshLists())
      }
    ];

    return actions;
  };
}
