import { Dialog, DialogProps } from '@rexlabs/dialog';
import React from 'react';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import Box from '@rexlabs/box';
import { noop } from 'lodash';
import InfoCircleIcon from 'view/components/icons/info';
import { StandardDialogFooter } from 'view/components/dialogs/components/standard-dialog-footer';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'routes/app';

interface AccessDeniedInvoiceDialogProps extends DialogProps {
  role: string;
}

export function AccessDeniedInvoiceDialog(
  props: AccessDeniedInvoiceDialogProps
) {
  const { role, onClose = noop } = props;

  return (
    <Dialog title='Request denied' TitleIcon={InfoCircleIcon}>
      <Box>
        You are currently a {role} user and do not have the necessary
        permissions to complete this action. You can create a new draft invoice
        from bill processing page.
      </Box>
      <StandardDialogFooter>
        <GhostButton
          onClick={() => {
            push(ROUTES.BILL_PROCESSING_LIST);

            onClose();
          }}
        >
          Go to bill processing
        </GhostButton>

        <PrimaryButton onClick={onClose}>Ok, I understand</PrimaryButton>
      </StandardDialogFooter>
    </Dialog>
  );
}
