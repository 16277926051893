import React, { forwardRef } from 'react';
import { CurrencyInput, CurrencyInputProps } from '@rexlabs/text-input';
import { fromCents, toCents, useCurrencySymbol } from 'utils/formatters';

export interface CentAmountInputProps
  extends Omit<CurrencyInputProps, 'onChange' | 'onBlur'> {
  onChange: (...args: any[]) => void;
  onBlur?: (...args: any[]) => void;
}

export const CentAmountInput = forwardRef<
  HTMLInputElement,
  CentAmountInputProps
>(function CentAmountInput({ value, onChange, onBlur, ...props }, ref) {
  const currencySymbol = useCurrencySymbol();

  const Prefix = () => <span>{currencySymbol}</span>;

  return (
    <CurrencyInput
      ref={ref}
      value={fromCents(value)}
      onChange={(e) => {
        onChange({
          target: {
            value: toCents(e.target?.value)
          }
        });
      }}
      onBlur={(e) => {
        onBlur?.({
          target: {
            value: toCents(e.target?.value)
          }
        });
      }}
      Prefix={props?.Prefix ?? Prefix}
      {...props}
    />
  );
});
