import React, { useMemo } from 'react';
import { TitleBlock } from 'view/components/cards/title/title-block';
import { RecordObject, RecordTypes } from 'data/models/types';
import { getRecordTitle } from 'utils/records/get-record-title';
import { TagConfig } from 'view/components/@luna/title-block';
import { useGetPortfolioRoleActions } from '../hooks/action-menu-items/use-get-portfolio-role-actions';
import { PortfolioRole } from '../types/PortfolioRole';

const type = RecordTypes.PortfolioRole;

type PortfolioRoleTitleBlockProps = {
  portfolioRole?: PortfolioRole;
};

export function PortfolioRoleTitleBlock({
  portfolioRole
}: PortfolioRoleTitleBlockProps) {
  const getPortfolioRoleActions = useGetPortfolioRoleActions();

  const title = getRecordTitle({ type, object: portfolioRole } as RecordObject);

  // Filter out the delete action from the header block
  const actions = useMemo(
    () =>
      getPortfolioRoleActions(portfolioRole).filter(
        (action) => !('intent' in action) || action.intent !== 'danger'
      ),
    [portfolioRole, getPortfolioRoleActions]
  );

  if (!portfolioRole) {
    return null;
  }

  const tags: TagConfig[] = [];

  return <TitleBlock title={title} type={type} actions={actions} tags={tags} />;
}
