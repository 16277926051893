import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Column, Grid } from '@rexlabs/grid';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import { ValueListSelect } from 'view/components/inputs/selects/value-list';
import {
  FLAT_FEE,
  FolioCommissionTemplateStructure,
  PERCENTAGE
} from '../types/FolioCommissionTemplate';
import { TieredTemplateBanner } from '../components/tiered-template-banner';
import { ProgressiveTemplateBanner } from '../components/progressive-template-banner';
import { EditTemplateTierLineItems } from '../components/edit-template-tier-line-items';
import { CommissionInput } from '../components/commission-field';

function TemplateContent({
  structureType
}: {
  structureType: FolioCommissionTemplateStructure;
}) {
  switch (structureType) {
    case 'flat_fee':
    case 'percentage':
      return (
        <Column width={2}>
          <CommissionInput structureType={structureType} />
        </Column>
      );
    case 'tiered':
      return (
        <Column width={4}>
          <EditTemplateTierLineItems templateStructureType='tiered'>
            <TieredTemplateBanner />
          </EditTemplateTierLineItems>
        </Column>
      );
    case 'progressive':
      return (
        <Column width={4}>
          <EditTemplateTierLineItems templateStructureType='progressive'>
            <ProgressiveTemplateBanner />
          </EditTemplateTierLineItems>
        </Column>
      );
  }
}

export const supplierCommissionDetailsBlock: BlockConfig = {
  id: 'supplier-commission-details',
  title: 'Details',
  validate: {
    definitions: {
      name: { rules: 'required' },
      structure_type: { rules: 'required' },
      commission_amount_currency: {
        rules: `required_if:structure_type.id,${FLAT_FEE}|min:0`
      },
      commission_amount_percent: {
        rules: `required_if:structure_type.id,${PERCENTAGE}|min:0`
      }
    }
  },
  Edit: ({ values, setFieldValue }) => {
    const structureType = values.structure_type?.id;

    const handleStructureBlur = React.useCallback(() => {
      setFieldValue?.('commission_amount_currency', undefined);
      setFieldValue?.('commission_amount_percent', undefined);
    }, [setFieldValue]);

    return (
      <Grid columns={4}>
        <Column width={2}>
          <Field
            name='name'
            label='Commission template name'
            Input={TextInput}
          />
        </Column>

        <Column width={2}>
          <Field
            name='structure_type'
            label='Structure'
            Input={ValueListSelect}
            onBlur={handleStructureBlur}
            inputProps={{
              type: 'commission_structure_type'
            }}
          />
        </Column>

        {structureType && <TemplateContent structureType={structureType} />}
      </Grid>
    );
  }
};
