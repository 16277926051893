import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { Field } from '@rexlabs/form';
import Box from '@rexlabs/box';
import { PostcodeSelect } from 'view/components/inputs/selects/v4-selects/postcode-select';
import {
  AddressLookupSelect,
  AddressLookup
} from 'view/components/inputs/selects/v4-selects/address-lookup-select';
import { useFocusOnInputElementIndex } from 'utils/form/use-focus-on-input-element-index';
import { Property } from '../types/property-types';

const validate = {
  definitions: {
    postal_code: {
      rules: 'required'
    },
    address: {
      rules: 'required'
    }
  }
};

export type PostcodeFormValues = {
  postal_code: string;
  address: AddressLookup;
};

export const postcodeBlock: BlockConfig<Property, any, PostcodeFormValues> = {
  id: 'details-postcode',
  title: 'Property address',
  validate,
  Edit: ({ values, setFieldValue }) => {
    const { contentRef, focusOnIndex } = useFocusOnInputElementIndex();

    const onChange = React.useCallback(() => {
      setTimeout(() => {
        setFieldValue?.('address', null);
        focusOnIndex(1);
      }, 100);
    }, []);

    return (
      <Box ref={contentRef}>
        <Grid columns={1}>
          <Field
            id='postal_code'
            name='postal_code'
            label='Postcode finder'
            optional={false}
            Input={PostcodeSelect}
            onChange={onChange}
          />
          <Field<typeof AddressLookupSelect>
            id='address'
            name='address'
            label='Address lookup'
            Input={AddressLookupSelect}
            inputProps={{
              postcode: values?.postal_code
            }}
          />
        </Grid>
      </Box>
    );
  }
};
