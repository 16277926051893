import React from 'react';
import { useQueryClient } from 'react-query';
import invariant from 'invariant';

import {
  Select,
  SelectProps,
  useFreeformDialog,
  FreeformDialogProps
} from '@rexlabs/select';

import { CreateNewAgencyFeeDialog } from 'src/modules/agency-fees/dialogs/create-new-agency-fee';
import { AgencyFee } from 'src/modules/agency-fees/types/agency-fee-types';
import { normaliseAgencyFee } from 'src/modules/agency-fees/utils/agency-fee-normaliser';
import {
  UseInactiveAgencyFeeTemplatesForRecordQueryArgs,
  getInactiveAgencyFeeTemplatesForRecordQueryKey,
  UseInactiveAgencyFeeTemplatesForRecordQuery
} from 'src/modules/agency-fees/utils/use-inactive-agency-fee-templates-for-record-query';
import { valueListFactory } from 'src/lib/testing/factories/value-list-factory';

export type InactiveAgencyFeeTemplatesForRecordProps = SelectProps<AgencyFee> &
  // The reason for the Partial is because of the typing of the field component. When we pass the component type into the
  // field component, it is expecting the select component to have all of the required props. I'm guessing this was a limitation
  // that the vivid team had, as all the select props are also optional.
  Partial<UseInactiveAgencyFeeTemplatesForRecordQueryArgs>;

export const InactiveAgencyFeeTemplatesForRecord: React.FC<InactiveAgencyFeeTemplatesForRecordProps> = ({
  recordId,
  entityFeeType,
  ...rest
}) => {
  invariant(entityFeeType, 'entityTypeId is required');
  invariant(recordId, 'recordId is required');

  const items = UseInactiveAgencyFeeTemplatesForRecordQuery({
    entityFeeType,
    recordId
  });

  const {
    getSelectProps: getFreeformDialogSelectProps
  } = useFreeformDialog<AgencyFee>({
    Dialog: FreeformCreateNewAgencyFeeDialog,
    props: {
      entityFeeType,
      recordId
    }
  });

  return (
    <Select
      {...getFreeformDialogSelectProps()}
      items={items}
      normaliser={normaliseAgencyFee}
      {...rest}
    />
  );
};

function FreeformCreateNewAgencyFeeDialog({
  selectItem,
  inputValue,
  recordId,
  entityFeeType,
  ...props
}: Partial<UseInactiveAgencyFeeTemplatesForRecordQueryArgs> &
  FreeformDialogProps<AgencyFee>) {
  invariant(entityFeeType, 'entityTypeId is required');
  invariant(recordId, 'recordId is required');

  const queryClient = useQueryClient();

  const onCreate = React.useCallback(
    (data) => {
      queryClient.invalidateQueries({
        queryKey: getInactiveAgencyFeeTemplatesForRecordQueryKey(
          entityFeeType,
          recordId
        )
      });
      selectItem(data);
    },
    [entityFeeType, queryClient, recordId, selectItem]
  );

  return (
    <CreateNewAgencyFeeDialog
      {...props}
      isFixture
      agencyFee={{
        name: inputValue,
        entity_type: valueListFactory(entityFeeType)
      }}
      entityFeeType={entityFeeType}
      onCreate={onCreate}
    />
  );
}
