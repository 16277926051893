import { BlockConfig } from 'view/components/record-screen/types';
import React from 'react';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import { TextArea } from 'view/components/inputs/text-area/text-area';
import {
  CodeInput,
  generateJavaScriptBeforeMountHandler
} from 'view/components/inputs/code-input';
import { Column, Grid } from 'view/components/@luna/form/grid/index';
import { ValueListSelect } from 'view/components/inputs/selects/value-list';
import { UserRoleSelect } from 'view/components/inputs/selects/v4-selects/user-role-select';
import { ValueListValue } from 'data/models/types';
import {
  CustomValidation,
  CustomValidationType
} from '../types/CustomValidation';

const validate = {
  definitions: {
    validation_type: { name: 'validation type', rules: 'required' },
    name: { name: 'name', rules: 'required' },
    code: { name: 'code', rules: 'required' },
    default_message: { name: 'default message', rules: 'required' }
  }
};

export const customValidationDetailsBlock: BlockConfig<
  CustomValidation,
  any,
  Omit<
    CustomValidation,
    'id' | 'created_at ' | 'updated_at' | 'created_by' | 'updated_by'
  >
> = {
  id: 'details',
  title: 'Validation Details',
  validate,
  Edit: ({ values, setFieldValue }) => {
    const tsTypes = `
    interface RexContext {
      before: Record<string, any>;
      after: Record<string, any>;
    }

    declare function module(exports: any): void;
    declare namespace module {
      var exports: {
        (context: RexContext): void;
      };
    }
    `;

    return (
      <Grid columns={4}>
        <Column width={2}>
          <Field name='name' label='Name' Input={TextInput} />
        </Column>
        <Column width={2}>
          <Field
            name='validation_type'
            label='Validation Type'
            Input={ValueListSelect}
            onChange={(e: any) => {
              const newValue = (e.target
                .value as unknown) as ValueListValue<CustomValidationType>;

              if (newValue.id === 'warning') {
                setFieldValue?.('treat_as_warning_for_roles', []);
              }
            }}
            inputProps={{
              type: 'custom_validation_type'
            }}
          />
        </Column>
        <Column width={2}>
          <Field
            name={'record_type'}
            label='Record Type'
            Input={ValueListSelect}
            inputProps={{ type: 'custom_validation_record_type' }}
          />
        </Column>
        <Column width={2}>
          <Field
            name={'trigger_type'}
            label='Trigger Type'
            Input={ValueListSelect}
            inputProps={{ type: 'custom_validation_trigger_type' }}
          />
        </Column>
        <Column width={4}>
          <Field name='description' label='Description' Input={TextArea} />
        </Column>
        <Column width={4}>
          <Field
            name={'code'}
            Input={CodeInput}
            label='Code'
            inputProps={{
              monacoProps: {
                beforeMount: generateJavaScriptBeforeMountHandler(tsTypes),
                language: 'javascript'
              }
            }}
          />
        </Column>
        <Column width={4}>
          <Field
            HelpTooltipContent={() =>
              'This is the error message shown to the user. This can be dynamically overridden by returning a custom string from the code.'
            }
            name='default_message'
            label='Default Message'
            Input={TextInput}
          />
        </Column>
        <Column width={4}>
          <Field
            HelpTooltipContent={() =>
              'Comma seperated list of API includes to be provided to the code.'
            }
            name='include'
            label='Includes'
            Input={TextInput}
          />
        </Column>
        <Column width={2}>
          <Field
            name={'ignore_for_roles'}
            label='Ignore For Roles'
            HelpTooltipContent={() =>
              'This custom validation will never trigger for users with any of these roles.'
            }
            Input={UserRoleSelect}
            inputProps={{
              multi: true,
              filter: (item) => {
                return !values?.treat_as_warning_for_roles.find(
                  (i) => i.id === item.id
                );
              }
            }}
          />
        </Column>
        {values?.validation_type.id === 'error' ? (
          <Column width={2}>
            <Field
              name={'treat_as_warning_for_roles'}
              label='Treat As Warning For Roles'
              HelpTooltipContent={() =>
                'This custom validation will be considered a warning (and will not prevent the record from being saved) for users with any of these roles.'
              }
              Input={UserRoleSelect}
              inputProps={{
                multi: true,
                filter: (item) => {
                  return !values?.ignore_for_roles.find(
                    (i) => i.id === item.id
                  );
                }
              }}
            />
          </Column>
        ) : null}
      </Grid>
    );
  }
};
