import React, { useEffect } from 'react';

import { Field } from '@rexlabs/form';
import { TextArea } from '@rexlabs/text-input';

import { SearchResultItem } from 'utils/api/get-search-results';

import { PropertyTenancy } from 'src/modules/property-tenancies/types/property-tenancy-types';

import { BlockConfig } from 'view/components/record-screen/types';
import { Value } from 'view/components/values';
import { Grid, Column } from 'view/components/@luna/form/grid';
import { PropertySelect } from 'view/components/inputs/selects/v4-selects/property-select';

import { TaskStatusSelect } from '../../common/components/task-status-select';
import { MoveOut } from '../types/MoveOut';
import { RelatedPropertyTenancySelect } from '../../common/components/related-property-tenancy-select';
import { MoveOutFormData } from '../types/MoveOutFormData';
import { onPropertyChangeHandler } from '../../common/utils/on-property-change-handler';
import { ManagedBySelect } from '../../common/components/managed-by-select';
import { getLabelForManagedBy } from '../../common/utils/get-label-for-managed-by';

const validate = {
  definitions: {
    property: { rules: 'required' },
    managed_by: { rules: 'required' },
    status: { rules: 'required' },
    'details.property_tenancy': { rules: 'required', name: 'tenancy' }
  }
};

export const moveOutDetailsBlock: BlockConfig<MoveOut, any, MoveOutFormData> = {
  id: 'details',
  title: 'Details',
  validate,

  View: ({ data }) => {
    return (
      <Grid columns={2}>
        <Column width={2}>
          <Value label='Description' value={data?.description} />
        </Column>

        <Value label='Property' value={data?.property?.address_string} />

        <Value
          label='Tenancy'
          value={data?.details?.property_tenancy?.tenancy?.display_name}
        />

        <Value
          label='Managed by'
          value={getLabelForManagedBy(data?.managed_by)}
        />
      </Grid>
    );
  },

  Edit: ({ values, data, setFieldValue, editMode }) => {
    useEffect(() => {
      onPropertyChangeHandler({
        values,
        setFieldValue,
        taskType: 'task_move_out'
      });
    }, [values?.property]);

    const setDisplayNameFromPropertyTenancy = React.useCallback(
      (propertyTenancy?: SearchResultItem<PropertyTenancy>) => {
        if (propertyTenancy != null) {
          setFieldValue?.(
            'summary',
            propertyTenancy.record.tenancy?.display_name
          );
        }
      },
      []
    );

    // Set summary value on init if initial property tenancy provided
    useEffect(() => {
      if (!editMode && values?.details?.property_tenancy) {
        setDisplayNameFromPropertyTenancy(values?.details?.property_tenancy);
      }
    }, []);

    return (
      <Grid columns={2}>
        <Column width={2}>
          <Field name='description' label='Description' Input={TextArea} />
        </Column>

        {editMode ? (
          <>
            <Value label='Property' value={values?.property?.address_string} />

            <Value
              label='Tenancy'
              value={data?.details?.property_tenancy?.tenancy?.display_name}
            />
          </>
        ) : (
          <>
            <Field
              id='property'
              name='property'
              label='Property'
              Input={PropertySelect}
            />

            <Field
              id='property_tenancy'
              name='details.property_tenancy'
              label='Tenancy'
              onBlur={(event) =>
                setDisplayNameFromPropertyTenancy(event.target.value)
              }
              Input={RelatedPropertyTenancySelect}
              inputProps={{
                propertyId: values?.property?.id,
                statusesToShow: ['active', 'outgoing']
              }}
            />
          </>
        )}

        <Grid columns={2}>
          <Field
            id='managed_by'
            name='managed_by'
            label='Managed by'
            Input={ManagedBySelect}
          />

          {!editMode && (
            <Field
              id='status'
              name='status'
              label='Progress'
              Input={TaskStatusSelect}
            />
          )}
        </Grid>
      </Grid>
    );
  }
};
