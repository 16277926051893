import React, { useMemo } from 'react';
import { lowerCase } from 'lodash';

import { DialogProps } from '@rexlabs/dialog';

import { CreateManualJournalEntryFormData } from 'src/modules/financials/types/CreateManualJournalEntryFormData';
import { FinancialEntity } from 'src/modules/financials/utils/financial-entity-action-group/use-get-financial-entity-actions';
import { getCreateManualJournalEntryBlock } from 'src/modules/financials/blocks/get-create-manual-journal-entry-block';

import { getToday } from 'utils/dates/dates';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';

import { DeferredFeeTypeId } from '../types/DeferredFeeTypeId';

interface CreateManualDeferredFeeEntryDialogProps extends DialogProps {
  folio: FinancialEntity;
  entryType: DeferredFeeTypeId;
  submitHandler: RecordSubmitHandler<CreateManualJournalEntryFormData>;
}

export function CreateManualDeferredFeeEntryDialog({
  folio,
  entryType,
  submitHandler,
  ...dialogProps
}: CreateManualDeferredFeeEntryDialogProps) {
  const content = useMemo(() => {
    return [
      {
        id: 'create-manual-deferred-fee-entry',
        label: 'Details',
        blocks: [getCreateManualJournalEntryBlock(entryType === 'agency_fee')]
      }
    ];
  }, [entryType]);

  return (
    <RecordDialog
      {...dialogProps}
      title={`Create ${lowerCase(entryType)} entry`}
      submitLabel='Create Entry'
      content={content}
      handleSubmit={submitHandler}
      initialValues={{ folio, date_of: getToday(), is_tax_included: true }}
    />
  );
}
