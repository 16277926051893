import React, { useMemo } from 'react';
import { query, useEntityQuery } from '@rexlabs/model-generator';

import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { RecordScreen } from 'view/components/record-screen';

import { useRecordScreenSubmitHandler } from 'view/hooks/use-record-screen-submit-handler';
import { set } from 'lodash';
import { useToast } from 'view/components/@luna/notifications/toast';
import { content } from '../data/content';
import { RoleTitleBlock } from '../components/role-title-block';
import { rolesModel } from '../models/roles-model';
import {
  mapRoleFormDataToRequestData,
  RoleFormData
} from '../utils/map-role-form-data-to-role-request-data';

// TODO: move this somewhere else
const getRoleQuery = (id: string) => query`{
  ${rolesModel} (id: ${id}) {
    id,
    privilege_entries
  }
}`;

interface RoleDetailsProps {
  roleId?: string;
}

export function RoleDetails({ roleId }: RoleDetailsProps) {
  const breadcrumbs = [{ type: BREADCRUMBS.ROLE }];
  const query = useMemo(() => getRoleQuery(roleId!), [roleId]);
  const { addToast } = useToast();

  const { status, data, actions } = useEntityQuery(query, {
    throwOnError: false
  });

  const handleSubmit = useRecordScreenSubmitHandler<RoleFormData>(
    async ({ changedValues }) => {
      const data = mapRoleFormDataToRequestData(changedValues);

      await addToast({
        description: 'Role successfully updated'
      });

      return actions.updateItem({
        id: roleId,
        data,
        args: {
          include: 'privilege_entries'
        }
      });
    }
  );

  const initialValues = getInitialValues(data);

  const titleBlock = <RoleTitleBlock role={data} />;

  return (
    <RecordScreen
      privilege={'roles.read'}
      isLoading={status === 'loading'}
      data={data}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      content={content}
      titleBlock={titleBlock}
      breadcrumbs={breadcrumbs}
    />
  );
}

function getInitialValues(data) {
  if (!data) return {};
  const { privilege_entries, ...role } = data;
  const privileges = privilege_entries?.data
    .filter((item) => item.privilege_set_id === null)
    .reduce((acc, { privilege_id }) => {
      return set(acc, privilege_id, true);
    }, {});

  const privilegeSets = privilege_entries?.data
    .filter((item) => item.privilege_set_id !== null)
    .reduce((acc, { privilege_set_id, privilege_set }) => {
      acc[privilege_set_id!] = privilege_set;
      return acc;
    }, {});

  return {
    ...role,
    privileges,
    privilege_sets: privilegeSets
  };
}
