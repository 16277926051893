import React, { useEffect } from 'react';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from 'react-query';
import { useInView } from 'react-intersection-observer';

import {
  mq,
  styled,
  StyleSheet,
  text,
  useStyles,
  useToken
} from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { Column, Grid } from '@rexlabs/grid';
import { useDialog } from '@rexlabs/dialog';

import { Card, CardContent } from 'view/components/@luna/card';

import RenderLoading from 'view/components/@luna/render-loading';
import LoadingState, {
  LoadingStateComponent
} from 'view/components/states/loading';
import InfoCircleIcon from 'view/components/icons/info';

import { GetActions } from 'src/modules/banking/batch-receipting/hooks/use-batch-receipting-button-config';
import { BatchReceiptingLineItem } from 'src/modules/banking/batch-receipting/components/batch-receipting-line-item';
import { StatusHelperDialog } from '../dialogs/status-helper-dialog';
import { BatchReceiptingListItem } from '../types';

export type FetchNextPage = (
  options?: FetchNextPageOptions | undefined
) => Promise<
  InfiniteQueryObserverResult<{
    data: any;
    pagination: any;
  }>
>;

interface BatchReceiptingTableProps {
  items: (BatchReceiptingListItem & { getActions: GetActions })[];
  EmptyState: React.FC;
  isLoading: boolean;
  fetchNextPage: FetchNextPage;
  isFetchingNextPage: boolean;
  testId?: string;
}

const defaultStyles = StyleSheet({
  title: {
    ...text.styles({
      fallback: 'heading.4'
    }),

    ...mq.styles({
      queries: {
        minWidth: 's'
      },
      styles: {
        ...text.styles({
          fallback: 'heading.3'
        })
      }
    })
  },
  infoIconButton: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
});

const infiniteListLoadingStateStyles = StyleSheet({
  // pass an empty style  to override the default style of the component
  container: {}
});

const InfiniteListLoadingState = styled(infiniteListLoadingStateStyles)(
  LoadingStateComponent
);

export function BatchReceiptingTable({
  EmptyState,
  items,
  isLoading,
  fetchNextPage,
  isFetchingNextPage,
  testId
}: BatchReceiptingTableProps) {
  const { ref, inView } = useInView();

  const s = useStyles(defaultStyles);
  const token = useToken();

  const { open: openStatusHelperDialog } = useDialog(StatusHelperDialog);

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  if (!isLoading && items.length === 0) {
    return <EmptyState />;
  }

  return (
    <RenderLoading isLoading={isLoading} LoadingView={Loading}>
      <Card data-testid={testId}>
        <CardContent>
          <Grid columns={16}>
            <Column width={6}>
              <Box
                flexDirection='row'
                alignItems='center'
                justifyContent='center'
                height='100%'
                {...s('title')}
              >
                Bank statement
              </Box>
            </Column>
            <Column width={4} />
            <Column width={6}>
              <Box
                flexDirection='row'
                alignItems='center'
                justifyContent='center'
                height='100%'
                sx={'1rem'}
                {...s('title')}
              >
                Rex Property Management
                <InfoCircleIcon
                  data-testid={'status-helper-icon'}
                  onClick={() => openStatusHelperDialog()}
                  {...s('infoIconButton')}
                />
              </Box>
            </Column>
          </Grid>

          <Box flexDirection={'column'} gap={token('spacing.m')}>
            {items.map((item, index) => (
              <BatchReceiptingLineItem
                key={`${item.data.reference}-${index}`}
                {...item}
              />
            ))}
            <span ref={ref}></span>
            {isFetchingNextPage ? <InfiniteListLoadingState /> : null}
          </Box>
        </CardContent>
      </Card>
    </RenderLoading>
  );
}

function Loading() {
  return <LoadingState>Loading items...</LoadingState>;
}
