import React from 'react';
import { Field } from '@rexlabs/form';

import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { UserSelect } from 'view/components/inputs/selects/v4-selects/user';
import { PortfolioRoleSelect } from 'view/components/inputs/selects/v4-selects/portfolio-role';
import { toQuri } from '@rexlabs/quri';

export const addMemberBlock: BlockConfig = {
  id: 'add-member',
  title: 'Add Member',
  validate: {
    definitions: {
      user: { rules: 'required' },
      portfolio_role: { rules: 'required' }
    }
  },
  Edit: ({ blockProps }) => (
    <Grid columns={2}>
      <Field
        name='user'
        id='user'
        label='User'
        Input={UserSelect}
        inputProps={{
          filter: toQuri([
            {
              field: 'portfolio_id',
              op: 'nin',
              value: [blockProps.portfolioId]
            }
          ])
        }}
      />
      <Field
        name='portfolio_role'
        id='portfolio_role'
        label='Role'
        Input={PortfolioRoleSelect}
      />
    </Grid>
  )
};
